
define('CheckoutOffersView',['require','jquery','backbone','GoogleAnalyticsUtils','commonutils','owlCarousel'],function (require) {
    "use strict";

    var $ = require("jquery"),
        Backbone = require("backbone"),
        GAUtils = require("GoogleAnalyticsUtils"),
        Utils = require("commonutils"),
        owlCarousel = require("owlCarousel"),
        CheckoutOffersView;

    CheckoutOffersView = Backbone.View.extend({
        events: {
            "click .offers-content .banner-link": "offerClick"
        },
        initialize: function () {
            try {
                var tilesList = [],
                    renderList = [],
                    index,
                    offersTemplate,
                    gaLabel = "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId'),
                    position, self = this;
    
                position = window.htimestamp.home.getComponentPosition({id: $(this.$el).attr("id") })
                gaLabel += " | Section position: " + (position > -1 ? position : '');


                if(isHubzu2Enabled && isHubzu2Enabled == 'Y'){
                    offersTemplate = _.template($("#felxTemplate").html());
                    $(".tiles-list .tile").each(function(){
                        tilesList.push($(this).html());
                    });
    
                    // TEST DATA
                    // tilesList = tilesList.slice(0, 12);
    
                    // $(".tiles-list").removeClass("flex-container").hide();
                    $(".tiles-list").removeClass("flex-container");
                    $(".flex-container .tile-window").empty();
                    for ( index = 0 ; index < tilesList.length ; index = index + 3) {
                        renderList = tilesList.slice(index, index + 3);
                        $(".flex-container .tile-window").append(offersTemplate({'tilesInfo': renderList}));
                        // $(".flex-container .tile-window").append(offersTemplate({tilesInfo: renderList}));
                    }
                    $(".flex-container").fadeIn();

                   // $('.cookieBanner').hide();

                    var owl = $('.tile-window');
                    owl.owlCarousel({
                        rtl: false,
                        margin: 20,
                        dots:false,
                        loop: false,
                        nav:false,
                        navigationText : ["",""],
                        responsive:{
                            0:{
                                items:1,
                                slideBy: 1,
                                nav: false,
                                autoWidth:true,
                                mouseDrag:true
                            },
                            600:{
                                items:2,
                                slideBy: 1,
                                nav: false,
                                autoWidth:true,
                                mouseDrag:true
                            },
                            768:{
                                items:3,
                                slideBy: 3,
                                nav: false,
                                stagePadding: 0,
                                mouseDrag:true
                            },
                            1024:{
                                items:3,
                                slideBy: 3,
                                nav: false,
                                stagePadding: 0,
                                mouseDrag:true
                            },
                            1200:{
                                items:3,
                                slideBy: 3,
                                nav: false,
                                stagePadding: 0,
                                mouseDrag:true
                            },
                            1240:{
                                items:4,
                                slideBy: 4,
                                nav: false,
                                stagePadding: 0,
                                mouseDrag:true
                            },
                            1440:{
                                items:4,
                                slideBy: 4,
                                nav: false,
                                stagePadding: 0,
                                mouseDrag:true
                                
                            }

                        },
                    });

                    owl.on('initialized.owl.carousel changed.owl.carousel resized.owl.carousel', function(e) {
                        $('.checkoutdeals-header .carousel-nav-wrapper .right').toggleClass('disabled', e.item.index >= e.item.count - e.page.size);
                        $('.checkoutdeals-header .carousel-nav-wrapper .left').toggleClass('disabled', e.item.index == 0);
                    });

                    $('.carousel-nav-wrapper .right').on('click', function() {
                        owl.trigger('next.owl.carousel');
                    });

                    $('.carousel-nav-wrapper .left').on('click', function() {
                        owl.trigger('prev.owl.carousel');
                    });
                } else {
                    if ($(window).width() < 767 ) {
    
                        $(".flex-container").hide();
                        $(".tiles-list").addClass("flex-container").show();
        
                        offersTemplate = _.template($("#mobileFelxTemplate").html());
                        $(".tiles-list .tile").each(function(){
                            tilesList.push($(this).html());
                        });
        
                        // TEST DATA
                        // tilesList = tilesList.slice(0, 9);
        
                        for ( index = 0 ; index < tilesList.length ; index = index + 5) {
                            renderList = tilesList.slice(index, index + 5);
                            $(".flex-container").append(offersTemplate({tiles: renderList}));
                        }
        
                        $(".tiles-list").html($(".flex-container").html());
                        $(".tile-view-container").empty();
                        
                        if ($(window).width() < 767 ) {
                            if ($(".tiles-list.flex-container .m-tile").length > 5) {
                                $(".tiles-list.flex-container").addClass("tiles-max-hieght");
                                $(".t-show-more").show();
                            }
                
                            $(".t-show-more").on("click", function(){
                                if ( $(this).data("action") === "show" ) {
                                    $(".tiles-list.flex-container").removeClass("tiles-max-hieght");
                                    $(this).data("action", "hide");
                                    $(this).text($(this).data('hide'))
                                    GAUtils.eventTracking.trackevent(null,'View additional promotions', gaLabel, "");
                                } else {
                                    $(".tiles-list.flex-container").addClass("tiles-max-hieght");
                                    $(this).data("action", "show");
                                    $(this).text($(this).data('show'))
                                    GAUtils.eventTracking.trackevent(null,'Hide additional promotions', gaLabel, "");
                                }
                            });
                        }
                        
                    } else {
                        offersTemplate = _.template($("#felxTemplate").html());
                        $(".tiles-list .tile").each(function(){
                            tilesList.push($(this).html());
                        });
        
                        // TEST DATA
                        // tilesList = tilesList.slice(0, 12);
        
                        $(".tiles-list").removeClass("flex-container").hide();
                        $(".flex-container").empty();
                        for ( index = 0 ; index < tilesList.length ; index = index + 7) {
                            renderList = tilesList.slice(index, index + 7);
                            $(".flex-container").append(offersTemplate({tiles: renderList}));
                        }
                        $(".flex-container").fadeIn();
                        $(".tiles-list").empty();
        
                        setTimeout(function(){
                            var tilesWidth = 0,
                                totalWidth = 0;
                            $(".tile-view-container .tile-window").each(function(){
                                if (tilesList.length >= 7) {
                                    tilesWidth = 0;
                                    $(this).find(".flex-item").each(function(){
                                        tilesWidth = tilesWidth + $(this).outerWidth(); 
                                    });
                                    totalWidth += tilesWidth
                                    $(this).css("width", tilesWidth + "px");
                                }
                                $(this).addClass("length-" + $(this).find('.tile').length);
                            });
                            if (tilesList.length >= 7) {
                                $(".tile-view-container").css("display", "flex"); 
                                $(".tile-view-container").css("width", totalWidth + "px"); 
                            }
                            var firstTile = $('.tile-view-container .tile').get(0);
                            var lastTile = $('.tile-view-container .tile').get($('.tile-view-container .tile').length - 1);
                            $(firstTile).css('padding-left','0px');
                            $(lastTile).css('padding-right','0px');
                            $('.offers-content').addClass('loaded');
                        }, 400);
    
                        if ($(window).width() > 991 ) {
                            setTimeout(function(){
                                if($('.flex-container').width() > $('.scrollOffer').outerWidth()) {
                                    $("#checkout-OffersSlideLeft").show();
                                    $("#checkout-OffersSlideRight").show();
                                } else {
                                    $("#checkout-OffersSlideLeft").hide();
                                    $("#checkout-OffersSlideRight").hide();
                                }
                            }, 500);
                            $("#checkout-OffersSlideLeft").on("click", function(){
                                var that = this;
                                if($(that).hasClass("disabled")) {
                                    return false;
                                } else {
                                    $('.scrollOffer').animate({scrollLeft: 0}, 800, function(){
                                        $(that).addClass("disabled");
                                        $("#checkout-OffersSlideRight").removeClass("disabled");
                                    });
                                }
                            });
                            $("#checkout-OffersSlideRight").on("click", function(){
                                var that = this;
                                if($(that).hasClass("disabled")) {
                                    return false;
                                } else {
                                    $('.scrollOffer').animate({scrollLeft: $('.flex-container').outerWidth() - $('.scrollOffer').outerWidth()}, 800, function() {
                                        $(that).addClass("disabled");
                                        $("#checkout-OffersSlideLeft").removeClass("disabled");
                                    });
                                }
                            });
                            $('.scrollOffer').on("scroll" , function() {
                                var scrollLeft = $(this)[0] ? $(this)[0].scrollLeft : 0;
                                if( scrollLeft === 0 ) {
                                    $("#checkout-OffersSlideLeft").addClass("disabled");
                                    $("#checkout-OffersSlideRight").removeClass("disabled");
                                } else if ( scrollLeft === ($('.flex-container').outerWidth() - $('.scrollOffer').outerWidth()) ) {
                                    $("#checkout-OffersSlideLeft").removeClass("disabled");
                                    $("#checkout-OffersSlideRight").addClass("disabled");
                                } else {
                                    $("#checkout-OffersSlideLeft").removeClass("disabled");
                                    $("#checkout-OffersSlideRight").removeClass("disabled");
                                }
                            });
                        }
                    }   
                }         
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Checkout offers - Home Page", "file:CheckoutOffersView.js|function:initialize|" + error, null, 1);
            }
        },
        offerClick: function(event) {
            try {
                var name = $(event.currentTarget).attr("aria-label"),
                    gaLabel = "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId'),
                    position = -1,
                    offerPosition = $(event.currentTarget).attr("data-position") || '';

                position = window.htimestamp.home.getComponentPosition({id: $(this.$el).attr("id") })
                gaLabel += " | Section position: " + (position > -1 ? position : '');
                if (offerPosition)
                    gaLabel += " | Offer position: " + offerPosition;
                gaLabel += " | name: " + name;
                
                GAUtils.eventTracking.trackevent(null,'Promotion click', gaLabel, "");

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Checkout offers - Home Page", "file:CheckoutOffersView.js|function:offerClick|" + error, null, 1);
            }
        } 

    });
    return CheckoutOffersView;
});