
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/addWallet',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "        <div class=\"add-button\">\n        <button class=\"addFundGA ga-action-button\">";
  stack1 = ((helper = (helper = helpers.button || (depth0 != null ? depth0.button : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"button","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</button>\n\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<li class=\"row ga-tuple-item ga-save-search-tuple "
    + escapeExpression(((helper = (helper = helpers.searchCriteraId || (depth0 != null ? depth0.searchCriteraId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchCriteraId","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"global-alert-wallet\">\n        <div class=\"heading\">\n            ";
  stack1 = ((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </div>\n        <div class=\"description\">\n            ";
  stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.showButton : depth0), "Y", {"name":"ifEqual","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</li>";
},"useData":true});
Handlebars.registerPartial('tpl/globalAlerts/addWallet', t);
return t;
});
/* END_TEMPLATE */
;