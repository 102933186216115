
define('googleSignInView',[
	'jquery',
	'underscore',
	'backbone',
	'GoogleAnalyticsUtils'
	], function($, _, Backbone,GAUtils){
		var googleSignInView = Backbone.View.extend({
			el: 'body',
			initialize: function(){
				console.log("google signIn");
			},
			isSignedInWithGoogle: function(){
				try{
					return GoogleAuth.isSignedIn.get();
				}catch(e){
		            GAUtils.eventTracking.trackevent("Error", "GoogleLogin", "file:googleSignInView.js|function:isSignedInWithGoogle|" + e, null, 1);
	          	}
			},
	        googleLogin: function(options){
	        	try{
		            var self = this;
		            self.callbackfunc = options.callback;
					self.callbackContextObj = options.callbackContextObj;
					GoogleAuth.signIn().then(function(currentUserObj){
						self.callbackfunc(currentUserObj.getAuthResponse().id_token,self.callbackContextObj);
					});
	            }catch(e){
		            GAUtils.eventTracking.trackevent("Error", "GoogleLogin", "file:googleSignInView.js|function:googleLogin|" + e, null, 1);
	          	}
	        }	
		});
	return googleSignInView;
});