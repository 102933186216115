
/* START_TEMPLATE */
define('hbs!tpl/buyerDashboard/editLicenseDetails',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "                    <option value='select' >Select</option>\n";
  },"3":function(depth0,helpers,partials,data) {
  return "                    <option value='select' selected>Select</option>\n";
  },"5":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depths[1] != null ? depths[1].data : depths[1])) != null ? stack1.stateId : stack1), depth0, {"name":"ifEqual","hash":{},"fn":this.program(6, data, depths),"inverse":this.program(8, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                        <option value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\" selected>"
    + escapeExpression(lambda(depth0, depth0))
    + "</option>\n";
},"8":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                        <option value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\">"
    + escapeExpression(lambda(depth0, depth0))
    + "</option>\n";
},"10":function(depth0,helpers,partials,data) {
  return "required";
  },"12":function(depth0,helpers,partials,data) {
  return "                    <option value='' >Select</option>\n";
  },"14":function(depth0,helpers,partials,data) {
  return "                    <option value='' selected>Select</option>\n";
  },"16":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depths[1] != null ? depths[1].data : depths[1])) != null ? stack1.state : stack1), depth0, {"name":"ifEqual","hash":{},"fn":this.program(6, data, depths),"inverse":this.program(8, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"modal fade\" id=\"editProfileLicenseModal\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\">\n  <div class=\"modal-dialog modal-login\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header popUpTitle hidden-xs\">\n        <h4 class=\"modal-title\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseDetailsHeading : stack1), depth0))
    + "</h4>\n       <!-- <span class=\"cross\" data-dismiss=\"modal\" area-hidden=\"true\"></span>-->\n      </div>\n      \n      \n      <div class=\"modal-body\" >\n        <div class=\"table changePasswordWidth\">\n          <form id=\"editLicense\" current-form=\"License Info Update\">\n            <div class=\"licenseValidationResponse hide\"></div>\n            <div class=\"form-group required\">\n              <label for=\"affiliatedCompany\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.issuedBy : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n                <input type=\"text\" class=\"form-control \" id=\"affiliatedCompany\"  error=\"errorIssuedBy\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.issuedBy : stack1), depth0))
    + "\" maxlength=\"70\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.company : stack1), depth0))
    + "\">\n                 \n                \n                <div class=\"errorPH hide errorIssuedBy\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorIssuedBy : stack1), depth0))
    + "</div>\n              </div>\n            </div>\n            <div class=\"form-group required\">\n              <label for=\"licenceNumber\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n                <input type=\"text\" class=\"form-control \" id=\"licenceNumber\"  error=\"errorLicenseNumber\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + "\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.licenseNumber : stack1), depth0))
    + "\" maxlength=\"40\" >\n                 \n                \n                <div class=\"errorPH hide errorLicenseNumber\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorLicenseNumber : stack1), depth0))
    + "</div>\n              </div>\n            </div>\n            <div class=\"form-group required\">\n              <label for=\"licenseStateId\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseState : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n               <!-- <input type=\"text\" class=\"form-control \" id=\"licenseState\"  error=\"errorLicenseState\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseState : stack1), depth0))
    + "\" maxlength=\"20\" >-->\n                <select class=\"form-control licenseState\" id=\"licenseStateId\" error=\"errorLicenseStateId\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.stateId : stack1), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.program(3, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.stateList : depth0), {"name":"each","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                </select>\n                 \n                \n                <div class=\"errorPH hide errorLicenseStateId\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorState : stack1), depth0))
    + "</div>\n              </div>\n            </div>\n            <div class=\"form-group required\">\n              <label for=\"licenceExpiryDate\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseExp : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n                <input type=\"text\" class=\"form-control expirationDate\" id=\"licenceExpiryDate\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseExpiryDatePlace : stack1), depth0))
    + "\" error=\"errorLicenseExpiry\" placeholder=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseStateOfficeAddress : stack1)) != null ? stack1.streetNumber1 : stack1), depth0))
    + "\"  placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseExpiryDate : stack1), depth0))
    + "\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.expiryDate : stack1), depth0))
    + "\" maxlength=\"20\">\n                <!--<span class=\"glyphicon glyphicon-calendar calendarExpiry\"></span>-->\n                <div class=\"errorPH hide errorLicenseExpiry\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorLicenseExpiry : stack1), depth0))
    + "</div>\n              </div>\n            </div>\n            \n            <div class=\"form-group  licenseHeaderBorder\">\n              <label for=\"licenseStateOfficeAddress\" class=\"col-xs-12 col-sm-12 col-md-12 control-label officeAddressHead\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseStateOfficeAddress : stack1)) != null ? stack1.Header : stack1), depth0))
    + "</label>\n             \n            </div>\n            <div class=\"form-group ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.stateMan : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n              <label for=\"agentAddress1\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseStateOfficeAddress : stack1)) != null ? stack1.streetNumber1 : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n                <input type=\"text\" class=\"form-control agentLicenseInfo\" id=\"agentAddress1\"  error=\"errorAgentStreetAddress1\" placeholder=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseStateOfficeAddress : stack1)) != null ? stack1.streetNumber1 : stack1), depth0))
    + "\" name=\"agentLicenseAddress1\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.address1 : stack1), depth0))
    + "\" maxlength=\"70\" >\n                <div class=\"errorPH hide errorAgentStreetAddress1\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorStreetAddress1 : stack1), depth0))
    + "</div>\n              </div>\n            </div>\n            <div class=\"form-group\">\n              <label for=\"agentAddress2\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseStateOfficeAddress : stack1)) != null ? stack1.streetNumber2 : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n                <input type=\"text\" class=\"form-control agentLicenseInfo\" id=\"agentAddress2\"  error=\"errorAgentStreetAddress2\" placeholder=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseStateOfficeAddress : stack1)) != null ? stack1.streetNumber2 : stack1), depth0))
    + "\" name=\"agentLicenseAddress2\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.address2 : stack1), depth0))
    + "\" maxlength=\"70\" >\n                <div class=\"errorPH hide errorAgentStreetAddress2\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorStreetAddress2 : stack1), depth0))
    + "</div>\n              </div>\n            </div>            \n            <div class=\"form-group ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.stateMan : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n              <label for=\"agentLicenseStateId\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.state : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n                <select class=\"form-control agentLicenseStateId agentLicenseInfo\" name=\"agentLicenseState\" id=\"agentLicenseStateId\" error=\"errorAgentState\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.state : stack1), {"name":"if","hash":{},"fn":this.program(12, data, depths),"inverse":this.program(14, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.stateList : depth0), {"name":"each","hash":{},"fn":this.program(16, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                </select>\n                <div class=\"errorPH hide errorAgentState\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorState : stack1), depth0))
    + "</div>\n              </div>\n            </div>\n\n            <div class=\"form-group ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.stateMan : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n              <label for=\"agentCity\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.city : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8 cityFieldContainer\" >\n                <input type=\"text\" class=\"form-control agentLicenseInfo\" id=\"agentCity\" name=\"agentLicenseCity\" error=\"errorAgentCity\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.city : stack1), depth0))
    + "\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.city : stack1), depth0))
    + "\" maxlength=\"40\" >\n                <div class=\"errorPH hide errorAgentCity\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorCity : stack1), depth0))
    + "</div>\n              </div>\n            </div>\n\n            <div class=\"form-group ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.stateMan : depth0), {"name":"if","hash":{},"fn":this.program(10, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n              <label for=\"agentZipCode\" class=\"col-xs-5 col-sm-4 col-md-4 control-label \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.zipCode : stack1), depth0))
    + "</label>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n                <input type=\"text\" class=\"form-control agentLicenseInfo\" name=\"agentLicenseZip\" id=\"agentZipCode\"  error=\"errorAgentZipCode\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.zipCode : stack1), depth0))
    + "\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.zip : stack1), depth0))
    + "\" maxlength=\"5\" >\n                <div class=\"errorPH hide errorAgentZipCode\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsgCMS : stack1)) != null ? stack1.errorPostalCode : stack1), depth0))
    + "</div>\n              </div>\n            </div>\n            <div class=\"form-group\" id=\"errorAll\">\n              <div class=\"col-xs-5 col-sm-4 col-md-4\">\n              </div>\n              <div class=\"col-xs-7 col-sm-8 col-md-8\">\n                <div class=\"errorPH hide errorPassword errorAll\"></div>\n              </div>\n            </div>\n            <div class=\"form-group\">\n              <div class=\"col-xs-offset-3 col-xs-10 col-sm-offset-4 col-sm-8 changeModalFooter\">\n              <input type=\"hidden\" name=\"primaryAgentLicenceId\" id=\"primaryAgentLicenceId\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.licenseId : stack1), depth0))
    + "\">\n                <button type=\"button\" id=\"updateLicense\" class=\"btn btn-primary\" license-type=\""
    + escapeExpression(((helper = (helper = helpers.licenseType || (depth0 != null ? depth0.licenseType : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"licenseType","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.licenseSaveButton : stack1), depth0))
    + "</button>\n                <button type=\"button\" id=\"cancelLicense\" data-dismiss=\"modal\" area-hidden=\"true\" class=\"btn btn-default\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.cancelButton : stack1), depth0))
    + "</button>\n              </div>\n            </div>\n        </div>\n        </form>\n    </div>\n  </div>\n</div>";
},"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/buyerDashboard/editLicenseDetails', t);
return t;
});
/* END_TEMPLATE */
;