
/* START_TEMPLATE */
define('hbs!templates/globalAlerts/globalAlertsLoginRegistration',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "<div id=\"globalRegisrationContainer\" class=\"registerCommon loginRegisterForm \">\n\n</div>\n\n\n\n\n\n";
  },"useData":true});
Handlebars.registerPartial('templates/globalAlerts/globalAlertsLoginRegistration', t);
Handlebars.registerPartial('templates/globalAlerts/globalAlertsLoginRegistration', t);
return t;
});
/* END_TEMPLATE */
;