
/*global define, root*/
define('proxyBiddingView',['require','jquery','underscore','backbone','jqueryUI','bootstrap','handlebars','GoogleAnalyticsUtils','commonutils','hbs!tpl/mobileVerification/mobileVeriTemp','loginRegisterView','phoneVerification','mobileVerificationView'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone"),
        jqueryUI = require("jqueryUI"),
        Bootstrap = require("bootstrap");

    var Handlebars = require("handlebars");
  //  var PhoneVerification = require('phoneVerification');

    var GAUtils = require("GoogleAnalyticsUtils");
    var Utils = require("commonutils");
   // var app = require('apputils');
    var mobileVeriTemp = require("hbs!tpl/mobileVerification/mobileVeriTemp");
    var loginRegisterView = require("loginRegisterView");
    var PhoneVerification = require("phoneVerification");
    var MobileVerificationView = require("mobileVerificationView");

    var proxyBiddingView = Backbone.View.extend({
        el: "body",

        events: {
            // "click .mv-confirm-btn": "validateVerificationCode",
            "click .pin-container .mv-confirm-btn" : "confirmProxyPin",
            "click .resend-proxy-pin, .resend-pin-click" : "resendProxyPin",
            "click .resend-profile-click" : "renderProfileScreen",
            // "click .add-funds-btn" : "renderTermsandConditionScreen",
            "click #tc-agrement-button" : "acceptTermsandCondition",
            "click .h-checkbox-container" : "acceptTermsandConditionCheckbox",
            "click .hasDatepicker" : "datepickerValidation",
            "click .add-funds-btn" : "openFundsManager",
            "click #modal-head-phone .closeButton": "logCloseEvent",
            "click #review-sign-agreement": "openDocuSignAgreement"
        },

        initialize: function () {
       	    try{

                this.isProxyBidRegEnabled = (typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true") ? true : false;
                this.fromGA = false;
                
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Initialize Proxy Bid vuew", "file:proxyBiddingView.js|function:initialize|" + e, null, 1);
            }
        },
        openDocuSignAgreement: function(e){
            var self = this;
            var today = new Date();
            var cmsContent = saveListDrawerContent.proxyBidRegister || null;
            var URLFormat = cmsContent.config.docuSign[envConf].URL;
            var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            var currentDate = today.getDate();
            var currentMonth = today.getMonth(); 
            var currentYear = today.getFullYear();
            // Add this line
            var currentyear = currentYear.toString().substr(-2);

            var address1 = (typeof userSession.user!= 'undefined' && typeof userSession.user.address1!= 'undefined' && userSession.user.address1) ? encodeURIComponent(userSession.user.address1).replace(/\./g, "%2E") : '';
            var address2 = (typeof userSession.user!= 'undefined' && typeof userSession.user.address2!= 'undefined' && userSession.user.address2) ? encodeURIComponent(userSession.user.address2).replace(/\./g, "%2E") : '';

            console.log('address1', address1);
            console.log('address2', address2);

            URLFormat = URLFormat.replace("###USERNAME###",$.cookie("loggedInFirstName") +" "+ $.cookie("loggedInLastName")).replace("###EMAIL###",$.cookie("loggedInEmailActive")).replace("###DATE###",currentDate).replace("###MONTH###",months[currentMonth]).replace("###YEAR###",currentyear).replace('###ADD###',address1 +' ' + address2).replace('###PHONE###',typeof userSession.user !='undefined' ? userSession.user.primaryPhone :'').replace('###COPY_EMAIL###',$.cookie("loggedInEmailActive"));
            
            
            var label = "Property ID: "+ window.propertyId;

            self.gaEventTracking("Sign triparty agreement click",label);

            $.ajax({
                url: contextPath + "/portal/updateDefaultDocusignTNCFlag",
                type: 'POST',
                success: function(response){}
            });
            if($('.review-docusign').hasClass('disabled')){
                return;
            }else{
                $('.review-docusign').addClass('disabled');
            }
            // URLFormat = encodeURI(URLFormat.replaceAll('%20',' '));
            // var encodedURL = encodeURI(URLFormat);

            console.log("encodedURLFormat",URLFormat);
            setTimeout(function(){
                window.open(URLFormat, "_blank");
            }, 500);
        },
        logCloseEvent: function(event) {
            try {
                var currentScreenLabel = window.currentScreenLabel || $.trim($(".h-ver-head").html()),
                    currentActionLabel = window.currentActionLabel || $.trim($(".h-ver-head").html())+' Click',
                    action, label;

                //action = 'Close ' + currentActionLabel + ' click';
                action = currentActionLabel;
                label = "Property ID: "+ window.propertyId +" | Screen name: " + currentScreenLabel;

                this.gaEventTracking(action,label);
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Initialize Proxy Bid view", "file:proxyBiddingView.js|function:logCloseEvent|" + error, null, 1);
            }
        },
        openLogin: function(user){
            try {
                var self = this;
                loginRegisterView.openLoginPopup({
                    callback: self.loginCallback,
                    viewObject: {
                        callback: self.openFundsManager.bind(self),
                        params: user || null
                    },
                    statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                });
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Initialize Proxy Bid vuew", "file:proxyBiddingView.js|function:openLogin|" + error, null, 1);
            }
        },
        openFundsManager: function(user) {
            try {
             
                user = user || {};
                
                var self = this,
                    contextPath = typeof window.springRequest !== 'undefined' ? window.springRequest : '',  
                    accessToken = $.cookie('_fcat') || '',
                    refreshToken = $.cookie('_fcrt') || '';

                if (!accessToken || !refreshToken) return self.openLogin(user);

                $('.add-funds-btn').css({
                    "opacity": 0.5,
                    "pointer-events": "none"
                })

                // Load marker cluster library
                Utils.loadScript({

                    source: contextPath + 'payment-web/build/bundle.js', 
                    charset: "utf-8",
                    callback: function(error) {

                        $('.add-funds-btn').css({
                            "opacity": 1,
                            "pointer-events": "all"
                        });

                        if( error ) {
                            GAUtils.eventTracking.trackevent("Error", "Initialize Proxy Bid vuew", "file:proxyBiddingView.js|function:openFundsManager|" + error, null, 1);
                            return;
                        }        
                           
                        /** 
                         * setting to log GA on close click  
                         */
                        window.currentScreenLabel = "Proxy bid pin confirmed";
                        window.currentActionLabel = "Close Proxy bid pin confirmed click";

                        var userId = window.userSession && window.userSession.userId || 'NA';
                        var trackGALabel = "Property Id: "+ window.propertyId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId"); 

                      //  GAUtils.eventTracking.trackevent("", "Add Funds click", trackGALabel +" | Screen name: Bidding Registration"); 
                        
                        $('.modal-content').removeClass('activeStage');

                        var confirmPinTemplateHead = $("#confirmProxyPinTemplateHead").html() || '',
                            pdpDetails = window.pdpDetails ? window.pdpDetails : {};

                        if ( !confirmPinTemplateHead.length ) return;
                        
                        confirmPinTemplateHead = Handlebars.compile(confirmPinTemplateHead);
                        confirmPinTemplateHead = confirmPinTemplateHead({
                            head: "Add Funds"
                        })
                        
                        $('#modal-head-phone').html(confirmPinTemplateHead);

                        var wallet, 
                            walletUser = user.fundStatus && user || null, //  self.fcUserWallet || null - on sign out, make this user as null 
                            cmsContent = saveListDrawerContent.proxyBidRegister || null;
                        if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                            var propertyId = loginRegisterView.proxyBidObj.propertyId;
                            var listingId = loginRegisterView.proxyBidObj.listingId;
                        } else {
                            var propertyId = pdpDetails.propertyId;
                            var listingId = window.listingId;
                        }
                        if (window.HubzuWallet) {
                            wallet = new HubzuWallet({ 
                                container: 'mobile-verification-body',
                                userId: window.userSession && window.userSession.userId || '',
                                propertyId: propertyId,
                                listingId: listingId,
                                source: 'web',
                                header: 'Add Funds',
                                initiator: (typeof loginRegisterView.proxyBidObj.source != 'undefined' && loginRegisterView.proxyBidObj.source == 'proxyDashboard') ? 'Buyer dashboard - Proxy bid' : "NEW-PDP",
                                showAddFundsAccountSelection: true,
                                walletUser: walletUser,
                                accessToken: $.cookie('_fcat'),
                                refreshToken: $.cookie('_fcrt'),
                                cmsContent: cmsContent,
                                loggedUser: Utils.getLoggedinUser(),
                                env: typeof window.envConf !== "undefined" ? window.envConf : "QE"
                            });
                            if(self.fromGA){ // For Global Alerts
                            	wallet.header= "";
                                wallet.initiator = "GLOBALALERTS";
                                wallet.showAddFundsAccountSelection = false;
                                var eventLabel =  window.propertyId  ? "Property Id : " + window.propertyId :'';
                                
                                if(walletUser.kycStatus === 'KYC_VERIFIED' || walletUser.kycStatus ==='KYB_VERIFIED' ){
                                    if(walletUser.fundStatus== "ACCOUNT_LINKING_COMPLETED"){
                                        self.gaEventTracking("Global Alerts - Add funds click",eventLabel+ " | Source : Global Alerts | Stage : "+walletUser.kycStatus);
                                    }else{
                                        self.gaEventTracking("Proxy bid popup opened",eventLabel+ " | Source : Global Alerts | Stage : "+walletUser.kycStatus);
                                    }
                                    
                                    wallet.dashboard();
                                   
                                }else{
                                    self.gaEventTracking("Proxy bid popup opened",eventLabel+ " | Source : Global Alerts | Stage : "+walletUser.kycStatus);
                                    wallet.render();
                                }
                                
                            }else{
                                wallet.render();
                            }

                            window.openHubLogin = self.openLogin;
                        }

                        $('#loginRegisterDialog').on('hidden.bs.modal', function (e) {
                            if (wallet) wallet.unmountComponent();
                            $("#mobile-verification").removeClass('fund-manager');
                        })

                        $("#mobile-verification").addClass('fund-manager');
                        $('#mobile-verification').addClass('activeStage');
                        if(self.fromGA || (typeof loginRegisterView.proxyBidObj.source && loginRegisterView.proxyBidObj.source == 'proxyDashboard')){
                            globalAlertView.hideAlertContainer();
                            $('#loginRegisterDialog').modal("show");
                            $("#mobile-verification").addClass('fund-manager-alert');


                        }
                        $("#ajax-loader-mask,#ajax-loader").hide();
                    }
                });
                 
                
                
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Initialize Proxy Bid vuew", "file:proxyBiddingView.js|function:openFundsManager|" + error, null, 1);
            }
        },
        /**
         * Render proxy bid reg screen
         */

        renderProxyBidRegister : function(){
            var self = this;
            var propertyId = propertyId;
            var bidRegUrl = contextPath + "/portal/userRegisteredForProxyBid";
            if(propertyId)
                bidRegUrl = bidRegUrl + "?propertyId="+propertyId;
                
            $.ajax({
				url: bidRegUrl,
				type: 'GET',
				success: function(response){
					response = response || {};
					if (response.status === "FAILURE" && response.errorCode && response.errorCode === "SESSION_EXPIRED") {
						// TODO : login popup
						loginRegisterView.openLoginPopup({
							callback: self.loginCallback,
							viewObject: {
                                callback: self.renderProxyBidRegister.bind(self),
								params: ''
                            },
							statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
						});
						return;
					} else if(response.status === "SUCCESS" && response.data && !response.data.isUserRegisteredForProxyBid){
                        self.proxyBidRegistration();
                    } else if (response.status === "SUCCESS" && response.data && response.data.isUserRegisteredForProxyBid  && !response.data.isRepeatBid) {
                        
                        $(".proxy-bid-button").html('Place Proxy Bid').attr('data-action', 'Place Proxy Bid');

                        self.renderConfirmProxyBidPin();
                        $("#ajax-loader-mask,#ajax-loader").hide();

                        return false;
                    }else if (response.status === "SUCCESS" && response.data && response.data && response.data.isRepeatBid){
                        $(".proxy-bid-button").html('Update Proxy Bid').attr('data-action', 'Update Proxy Bid');

                        self.renderConfirmProxyBidPin();
                        $("#ajax-loader-mask,#ajax-loader").hide();
                    }
				},
				error: function(xhr, status, error) {
					var dataObject = {
                        formObject: '#loginRegisterDialog'
                    }; 
                    Utils.showSuccessRegistration(dataObject);
                    return false;
				}
			});
        },

        proxyBidRegistration : function(){
            var self = this;
            $.ajax({
				url: contextPath + "/portal/generateProxyBidCode",
				type: 'POST',
				success: function(response){
					response = response || {};
					if (response.status === "FAILURE" && response.errorCode && response.errorCode === "SESSION_EXPIRED") {
						// TODO : login popup
						loginRegisterView.openLoginPopup({
							callback: self.loginCallback,
							viewObject: {
                                callback: self.proxyBidRegistration.bind(self),
								params: ''
                            },
							statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
						});
						return;
					} else if(response && response.status == "SUCCESS" && response.data){
                        $(".proxy-bid-button").html('Place Proxy Bid').attr('data-action', 'Place Proxy Bid');
                        self.showProxyBidRegister(response.data);

                    } else {
                        var dataObject = {
                            formObject: '#loginRegisterDialog'
                        }; 
                        Utils.showSuccessRegistration(dataObject);
                        return false;
                    }
				},
				error: function(xhr, status, error) {
					var dataObject = {
                        formObject: '#loginRegisterDialog'
                    }; 
                    Utils.showSuccessRegistration(dataObject);
                    return false;
				}
			});
        },

        loginCallback : function(user, options){
            try {
                if (options.callback) options.callback(options.params || {});
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:proxyBiddingView.js|function:loginCallback|" + error, null, 1);
            }
        },

        confirmProxyPin: function(event) {
            try {
                
                // this.renderConfirmPinSuccess();
                // return;

                window.currentScreenLabel = "Add Funds";
                window.currentActionLabel = "Add Funds screen";

                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                    var propertyId = loginRegisterView.proxyBidObj.propertyId;
                }
                
                var eventLabel =  window.propertyId  ? "Property Id : " + window.propertyId :'';

                this.gaEventTracking("Confirm Bidding Pin click",eventLabel+ " | Screen name: Place Proxy bid");

                var URL = typeof contextPath !== 'undefined' ? contextPath : '',
                    codes = [], 
                    requestBody = {},
                    self = this,
                    message = "",
                    confirmBtn = $(".pin-container .mv-confirm-btn");

                $(".pin-container .mv-input .otp-input").each(function(){
                    codes.push(this.value);
                });

                if (codes.length < 6) return;

                requestBody.proxyBidCode = codes.join('');
                URL += "/portal/isProxyBidCodeValidForUser";

                confirmBtn.addClass('h-disabled');
                $(".pin-container .mv-otp-error").addClass('hide');
                $("#ajax-loader-mask,#ajax-loader").show();

                $.ajax({
                    url: URL,
                    type: 'POST',
                    data: JSON.stringify(requestBody),
                    dataType: 'json',
                    contentType: "application/json",
                    success: function(response) {
                        response = response || {};

                        if ( response.status === "FAILURE" && response.errorCode === "SESSION_EXPIRED") {
                            loginRegisterView.openLoginPopup({
                                callback: self.loginCallback,
                                viewObject: {
                                    callback: self.renderConfirmProxyBidPin.bind(self),
                                    params: ''
                                },
                                statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                            });
                            confirmBtn.removeClass('h-disabled');
                            $("#ajax-loader-mask,#ajax-loader").hide();
                        }
                        // TODO: session handling 
                        if ( response.status === 'SUCCESS' ) {
                            if (response.data) {
                                localStorage.setItem('showPlaceBid', 'Y');
                                self.getUserWalletStatus();
                                //document.forms['pdp-bid-form'].submit();
                                // self.renderConfirmPinSuccess();
                                // $("#ajax-loader-mask,#ajax-loader").hide();
                            } else {
                                message = saveListDrawerContent.proxyBidRegister.errorMessages["INVALID_PIN"];
                                $(".pin-container .mv-otp-error").html(message).removeClass('hide');

                                confirmBtn.removeClass('h-disabled');
                                $("#ajax-loader-mask,#ajax-loader").hide();
                            }
                        }

                    },
                    error: function(xhr, error, status) {
                        $("#ajax-loader-mask,#ajax-loader").hide();
                        confirmBtn.removeClass('h-disabled');
                        message = saveListDrawerContent.proxyBidRegister.errorMessages["TECHNICAL_ERROR"];
                        self.showToastMessage({message: message, type: 'error'});
                    }
                });
                //this.renderConfirmPinSuccess();

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:confirmProxyPin|" + error, null, 1);
            }
        },
        getUserWalletStatus: function(proxyBidView) {
            try {

                var userId = window.userSession.userId || '',
                    self = proxyBidView || this;
                
                $.ajax({
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader ("Authorization", "Bearer " + $.cookie('_fcat'));
                    },                    
                    url: '/fc-service/api/getUserDetails/' + userId,
                    type: 'GET',
                    success: function(response) {
                        response = response || {};
                        var user = response.data || {};

                        if (response.status === 'SUCCESS' && 
                            (user.fundStatus === 'TRANSFER_FUND_PENDING' || 
                            user.fundStatus === 'TRANSFER_FUND_IN_PROGRESS' || 
                            user.fundStatus === 'TRANSFER_FUND_COMPLETED' || 
                            user.fundStatus === 'TRANSFER_FUND_COMPLETE' ) && 
                            ((user.userType === "INDIVIDUAL" && user.kycStatus === "KYC_VERIFIED" && !(self.fromGA)) ||
                             (user.userType === "BUSINESS" && user.kycStatus === "KYB_VERIFIED" && !(self.fromGA)))  
                            ) {
                                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                                    loginRegisterView.proxyBidObj.isProxyBiddingEnabled = false;
                                    loginRegisterView.proxyBidObj.viewObject.submitProxyBid();
                                } else {
                                    document.forms['pdp-bid-form'].submit();
                                }
                        } 
                        else {
                            //self.fcUserWallet = user;
                            //self.renderConfirmPinSuccess();
                            if(!self.fromGA)
                            user.pinConfirmed = true;
                            self.openFundsManager(user);
                            $("#ajax-loader-mask,#ajax-loader").hide();
                        }
                    },
                    error: function(xhr, status, error) {
                        $("#ajax-loader-mask,#ajax-loader").hide();
                        var message = 'We are facing technical difficulties, please try again later.';
                        $(".pin-container .mv-otp-error").html(message).removeClass('hide');
                    }
                })
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:getUserWalletStatus|" + error, null, 1);
            }
        },
        renderConfirmPinSuccess: function(){
            try {
                
                var confirmPinTemplateSuccess = $("#confirmProxyPinTemplateSuccess").html() || '';
                    

                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                    var address = typeof loginRegisterView.proxyBidObj.address  !== "undefined" ? loginRegisterView.proxyBidObj.address : {};
                    var propertyId = loginRegisterView.proxyBidObj.propertyId || '';
                } else {
                    var address = typeof pdpDetails !== "undefined" ? pdpDetails.address : {};
                }

                if ( !confirmPinTemplateSuccess.length ) return;
                confirmPinTemplateSuccess = Handlebars.compile(confirmPinTemplateSuccess);
                confirmPinTemplateSuccess = confirmPinTemplateSuccess({
                    address1: address.streetNumber,
                    address2: address.streetName || '',
                    city: address.city || '',
                    state: address.state || '',
                    zip: address.zip || '',
                    estimatedOpeningBid: $(".fcp-amount").text() || '$0',
                    imageUrl: "/Hubzu/www/images/image-not-available.jpg"
                });

                $("#mobile-verification-body").html(confirmPinTemplateSuccess);

                var eventLabel =  window.propertyId  ? "Property Id : " + window.propertyId :'';

                this.gaEventTracking("Confirm Bidding Pin click",eventLabel + " | Screen name: Place Proxy bid");

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:renderConfirmPinSuccess|" + error, null, 1);
            }
        },
        resendProxyPin: function(user,callBackParam) {
            try {
                
                var URL = typeof contextPath !== 'undefined' ? contextPath : '',
                    self = (callBackParam && callBackParam.viewObject) || this ,
                    callBackParam = (self && self.callBackParam) || callBackParam || '',
                    message = '',
                    errorCode = '';
                
                // self.renderResendProxyPin(); 
                // return;
                self.callBackParam = callBackParam;
                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                    var propertyId = loginRegisterView.proxyBidObj.propertyId || "";
                }

                var eventLabel =  window.propertyId  ? "Property Id : " + window.propertyId :'';
                if(typeof callBackParam !=  'undefined' && typeof callBackParam.callBackFunc !=  'undefined'){
                    
                }else{
                    self.gaEventTracking("Resend Bidding Pin click",eventLabel+" | Screen name: Place Proxy bid");

                }

                URL += "/portal/resendProxyBidCode";
                $("#ajax-loader-mask,#ajax-loader").show();

                $.ajax({
                    url: URL,
                    type: 'POST',
                    dataType: 'json',
                    success: function(response) {
                        response = response || {};

                        // TODO: session handling 
                        if ( response.status === 'SUCCESS' && response.data ) {
                            if(typeof callBackParam !=  'undefined' && typeof callBackParam.callBackFunc !=  'undefined'){
                                
                                callBackParam.callBackFunc();
                            }else if ($(".resend-pin-container").length){
                                self.showToastMessage({message: 'Bidding pin resent', type: 'success'});
                                $(".otp-input").val('');
                                self.attachOtpEvents();
                            } else {
                                self.renderResendProxyPin(); 
                            }
                        }else if ( response.status === 'SUCCESS' && !response.data ){
                            errorCode = response.errorCode || "TECHNICAL_ERROR";
                            self.showToastMessage({message: 'Oops! some thing went wrong, pleaes try again later', type: 'success'});
                            if(typeof callBackParam !=  'undefined' && typeof callBackParam.callBackFunc !=  'undefined'){
                                var params={};
                                params.msg = errorCode;
                                params.error = true;
                                callBackParam.callBackFunc(params);
                            }
                        }
                        if (response.status === 'FAILURE' && response.errorCode == "SESSION_EXPIRED") {
                            loginRegisterView.openLoginPopup({
                                callback: self.resendProxyPin,
                                viewObject: {viewObject: self},
                                statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                            });
                        }
                        
                        else if (response.status === 'FAILURE') {
                            errorCode = response.errorCode || "TECHNICAL_ERROR";
                            if(typeof callBackParam !=  'undefined' && typeof callBackParam.callBackFunc !=  'undefined'){
                                var params={};
                                params.msg = errorCode;
                                params.error = true;
                                callBackParam.callBackFunc(params);
                            }else{
                                message = saveListDrawerContent.proxyBidRegister.errorMessages[errorCode];

                                self.showToastMessage({message: message, type: 'error'});

                            }
                        }

                        $("#ajax-loader-mask,#ajax-loader").hide();
                    },
                    error: function(xhr, error, status) {
                        $("#ajax-loader-mask,#ajax-loader").hide();
                        if(typeof callBackParam !=  'undefined' && typeof callBackParam.callBackFunc !=  'undefined'){
                            var params={};
                            params.msg = errorCode;
                            params.error = true;
                            callBackParam.callBackFunc(params);
                        }else{
                            message = saveListDrawerContent.proxyBidRegister.errorMessages["TECHNICAL_ERROR"];
                            self.showToastMessage({message: message, type: 'error'});

                        }
                    }
                });


            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:resendProxyPin|" + error, null, 1);
            }
        },
        showToastMessage: function(params){
            try {
                
                var type = params.type,
                    message = params.message, 
                    messageInterval = null; 

                clearInterval(messageInterval);
                $(".cp-message .content").html(message);
                $(".cp-message")
                    .removeClass('hide success error')
                    .addClass(type)
                    .fadeIn('300')
                    .focus();

                messageInterval = setInterval(function(){
                    $(".cp-message").fadeOut('slow');
                }, 5000);
                
                $(".cp-message .cls-btn").off('click').on('click', function(){
                    clearInterval(messageInterval);
                    $(".cp-message").fadeOut();
                });   

                $('.loginRegisterPopup').animate({ scrollTop: 0 }, 'slow');

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:showToastMessage|" + error, null, 1);
            }
        },  
        renderTermsandConditionScreen: function(e){
            try {

                $('.modal-content').removeClass('activeStage');

                var options = options || {},self = this;


                var termsandConditionTemplateHead = $("#confirmProxyPinTemplateHead").html() || '',
                    termsandConditionTemplate = $("#termsandConditionTemplate").html() || '';
                    // address = typeof pdpDetails !== "undefined" ? pdpDetails.address : {};
                    if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                        var address = typeof loginRegisterView.proxyBidObj.address  !== "undefined" ? loginRegisterView.proxyBidObj.address : {};
                        var propertyId = loginRegisterView.proxyBidObj.propertyId || "";
                    } else {
                        var address = typeof pdpDetails !== "undefined" ? pdpDetails.address : {};
                    }

                if ( !termsandConditionTemplateHead.length || !termsandConditionTemplateHead.length ) return;
                
                termsandConditionTemplateHead = Handlebars.compile(termsandConditionTemplateHead);
                termsandConditionTemplateHead = termsandConditionTemplateHead({
                    head: "Managing Funds"
                });

                termsandConditionTemplate = Handlebars.compile(termsandConditionTemplate);

                termsandConditionTemplate = termsandConditionTemplate({
                    address1: address.streetNumber,
                    address2: address.streetName || '',
                    city: address.city || '',
                    state: address.state || '',
                    zip: address.zip || '',
                });

                
                
                $('#modal-head-phone').html(termsandConditionTemplateHead);
                $("#mobile-verification-body").html(termsandConditionTemplate);
                $('#mobile-verification').addClass('activeStage tc-agreement-active');

                var eventLabel =  window.propertyId  ? "Property Id : " + window.propertyId :'';

                self.gaEventTracking("Add Funds click",eventLabel+ " | Screen name: Bidding Registration");

                this.attachOtpEvents();
                
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:renderConfirmProxyBidPin|" + error, null, 1);
            }
        },
        renderResendProxyPin: function() {
            try {

                var resendProxyPinTemplate = $("#resendProxyPinTemplate").html() || '',
                    phoneNumber = mobileVerificationView.user.primaryPhone || userSession.phone;

                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                    var propertyId = loginRegisterView.proxyBidObj.propertyId || '';
                }

                var match = phoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
                phoneNumber = '(' + match[1] + ') ' + match[2] + '-' + match[3];
                phoneNumber = PhoneVerification.applyPhoneMaskFormat({ "phone": phoneNumber });

                if ( !resendProxyPinTemplate.length ) return;
                resendProxyPinTemplate = Handlebars.compile(resendProxyPinTemplate);
                resendProxyPinTemplate = resendProxyPinTemplate({
                    phone: phoneNumber
                });

                $("#mobile-verification-body").html(resendProxyPinTemplate);
                //var userId = window.userSession && window.userSession.userId || 'NA';
                //var trackGALabel = "Property Id: "+ window.propertyId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId")+ "| Screen name: Place Proxy bid - Bidding pin resent";

                var eventLabel =  window.propertyId  ? "Property Id : " + window.propertyId :'';
                this.gaEventTracking("Resend Bidding Pin click",eventLabel + " | Screen name: Place Proxy bid - Bidding pin resent");


                this.attachOtpEvents();

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:renderResendProxyPin|" + error, null, 1);
            }
        },
        renderConfirmProxyBidPin: function(options) {
            try {



                window.currentScreenLabel = "Proxy bid pin confirmed";
                window.currentActionLabel = "Close Proxy bid pin confirmed click";
                $('.modal-content').removeClass('activeStage');

                options = options || {};

                var confirmPinTemplate = $("#confirmProxyPinTemplate").html() || '',
                    confirmPinTemplateHead = $("#confirmProxyPinTemplateHead").html() || '';
                    // address = typeof pdpDetails !== "undefined" ? pdpDetails.address : {};

                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                    var address = typeof loginRegisterView.proxyBidObj.address  !== "undefined" ? loginRegisterView.proxyBidObj.address : {};
                } else {
                    var address = typeof pdpDetails !== "undefined" ? pdpDetails.address : {};
                }

                if ( !confirmPinTemplate.length || !confirmPinTemplateHead.length ) return;
                
                confirmPinTemplate = Handlebars.compile(confirmPinTemplate);
                confirmPinTemplate = confirmPinTemplate({
                    address1: address.streetNumber,
                    address2: address.streetName || '',
                    city: address.city || '',
                    state: address.state || '',
                    zip: address.zip || '',
                });

                confirmPinTemplateHead = Handlebars.compile(confirmPinTemplateHead);
                confirmPinTemplateHead = confirmPinTemplateHead({
                    head: "Place Proxy Bid"
                })
                
                $('#modal-head-phone').html(confirmPinTemplateHead);
                $("#mobile-verification-body").html(confirmPinTemplate);
                $('#mobile-verification').addClass('activeStage');
                $('.notifications-slider').removeClass('saved-items-active');
                $('.notifications-slider-shade').fadeOut(100);
                if(window.globalAlertView) window.globalAlertView.hideAlertContainer();
                var locationUrl = window.location.href;
                if(locationUrl.indexOf('/property/')>-1  && typeof golbalPDPView!= 'undefined') {

                    $('#lightbox, .pdp-gallery-lightbox').modal('hide');
                }
                this.attachOtpEvents();
                $("#ajax-loader-mask,#ajax-loader").hide();
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:renderConfirmProxyBidPin|" + error, null, 1);
            }
        },
        attachOtpEvents: function(params){
            try {

                params = params || {};

                var checkOtpLength, self = this;

                if (params.hasError) return;
                 
                $(".pin-container .mv-input .otp-input").on("keypress", function(event){
                    return self.isNumber(event);
                });
                checkOtpLength = function() {
                    var codes = [];
                    $(".pin-container .mv-input .otp-input").each(function(){
                        if (this.value) codes.push(this.value);
                    });
                    if (codes.length === 6) {
                        $(".pin-container .mv-confirm-btn").removeClass('h-disabled').focus();
                    } else {
                        $(".pin-container .mv-confirm-btn").addClass('h-disabled');
                    }
                }

                $(".pin-container .mv-input .otp-input").unbind('keyup').bind('keyup', function(event){
                    if (event.target.value.length > 0) {
                        $(event.target).next().focus();
                    }
                    checkOtpLength();
                });

                $('#tcCalender').datepicker({
                    maxDate : new Date(),
                    onSelect: function(dateText) {
                       $('.err_'+this.id).hide();
                       $("#tcCalender").removeClass("hasInputError");
                    }
                }).keydown(function (e) {
                    e.preventDefault();
                   /* var code = e.keyCode || e.which;
                    // And arrow keys used "for performance on other keys"
                      if (code == '37' || code == '38' || code == '39' || code == '40') {
                        // Get current date
                        var parts = $(this).val().split("/");
                        var currentDate = new Date(parts[2], parts[0] - 1, parts[1]);
                        // Show next/previous day/week 
                        switch (code) {
                            // LEFT, -1 day
                          case 37: currentDate.setDate(currentDate.getDate() - 1); break;
                            // UP, -1 week
                          case 38: currentDate.setDate(currentDate.getDate() - 7); break;
                            // RIGHT, +1 day
                          case 39: currentDate.setDate(currentDate.getDate() + 1); break;
                            // DOWN, +1 week
                          case 40: currentDate.setDate(currentDate.getDate() + 7); break;
                        }
                        // If result is ok then write it
                        if (currentDate != null) { $(this).datepicker("setDate", currentDate); }
                      } else { 
                        if (code == '9' || code == '13') { 
                            $(this).datepicker("hide");
                            $(this).blur(); 
                        } else {
                            return false;    
                        }
                        
                      } // If other keys pressed.. return false*/

                  });
                
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:attachOtpEvents|" + error, null, 1);
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                return false;
            }
            return true;
        },
        renderProfileScreen: function(){
            try {
                MobileVerificationView.srcOrigin = "bidPinConfirmation";
                MobileVerificationView.renderProfileScreen({});
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Proxy bidding", "file:proxyBiddingView.js|function:renderProfileScreen|" + error, null, 1);
            }
        },
        showProxyBidRegister : function(proxyPin){
            var self = this;
            $('.modal-content').removeClass('activeStage');
            var proxyBidRegTmpl = $("#proxy-bid-register-tmpl").html();
            proxyBidRegTmpl = Handlebars.compile(proxyBidRegTmpl);
            var proxyBidRegHead = $("#mobileVerHead").html();
            proxyBidRegHead = Handlebars.compile(proxyBidRegHead);

            var phoneNumber = userSession.phone||'';
            phoneNumber = Utils.formatPhoneNumber(phoneNumber);
            phoneNumber = PhoneVerification.applyPhoneMaskFormat({"phone":phoneNumber});

            var content = {
                popupTitle : "Bidding Registration",
                data : {
                    proxyCode:proxyPin,
                    phoneNumber:phoneNumber
                }
            };
            
            proxyBidRegTmpl = proxyBidRegTmpl({
                content : content
            });
            console.log(proxyBidRegTmpl);
            proxyBidRegHead = proxyBidRegHead({
                content : content
            });
            $('#modal-head-phone').html(proxyBidRegHead);
            $("#mobile-verification-body").html(proxyBidRegTmpl);
            $('#mobile-verification').addClass('activeStage');
            $("#ajax-loader-mask,#ajax-loader").hide();

            var trackGALabel = '';
            var propertyId = propertyId ? propertyId : '';
            if(propertyId){
                trackGALabel = "Property Id : " + propertyId + "| ";
            }
            
            this.gaEventTracking("Proxy Bidding registration successful",trackGALabel + " Screen name: Bidding Registration");

        },
        acceptTermsandCondition: function(e){
            var self = this;
            var userId = (typeof window.userSession !== "undefined") && userSession.userId || 'NA';
            if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended')){
                var propertyId = loginRegisterView.proxyBidObj.propertyId || '';
            }
            if($(".hasDatepicker").val() == ''){
                $("#tcCalender").addClass("hasInputError");
                $(".err_tcCalender").show();    
            }
            if($("#tcFullName").val() == ''){
                $("#tcFullName").blur();
            }
            if($(".tc-agreement .h-input").hasClass("hasInputError") || $("#tcFullName").val() == ''|| $("#tcCalender").val() == '' || $("#tc-agrement-button").hasClass("h-disabled")){
                return;
            }
            var tncAcceptedflag = $("#acceptTC").is(":checked");
            var fullName = $("#tcFullName").val();
            var acceptedDate = $("#tcCalender").val();
            var requestData = {
                "acceptedDate": acceptedDate,
                "fullName": fullName,
                "userId": userId,
                "tncAcceptedflag":tncAcceptedflag
            }
            $.ajax({
                url: "/fc-service/api/updateTnC/"+userId,
                type: "POST",
                dataType: 'json',
                contentType: "application/json; charset=utf-8",
                data: JSON.stringify(requestData),
                success: function(response) {
                    console.log(response);

                    var eventLabel =  window.propertyId  ? "Property Id : " + window.propertyId :'';
                    self.gaEventTracking("Accept T and C click",eventLabel+ " | Screen name: Managing Funds");
                    $("#mobile-verification-body").html("Proxy Bidding Terms and Conditions updated successfully.");

                    $("#ajax-loader-mask,#ajax-loader").hide();
                }
            })
        },
        acceptTermsandConditionCheckbox: function(event){
            if($("#acceptTC").is(":checked")){
                $("#tc-agrement-button").removeClass("h-disabled");
            }else{
                $("#tc-agrement-button").addClass("h-disabled");
            }

        },
        datepickerValidation: function(event){
            var current = $(event.currentTarget);
            if(current.val() == ""){
                $("#tcCalender").addClass("hasInputError");
                $(".err_tcCalender").show();    
            }else{
                $("#tcCalender").removeClass("hasInputError");
                $(".err_tcCalender").hide();    
            }
            
        },

        gaEventTracking: function(action,eventLabel){

            var userId = (typeof window.userSession !== "undefined") && userSession.userId || 'NA';
            var eventLabel =  'UID : '+ userId + ' | Visitor Id :'+$.cookie('visitorId')+ ' | ' + eventLabel;
            var source = (loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.source) ? loginRegisterView.proxyBidObj.source : '';
            var eventLabel =  'UID : '+ userId + ' | Visitor Id :'+$.cookie('visitorId')+ '| source : '+ source + ' | ' + eventLabel;
            GAUtils.eventTracking.trackevent("", action, eventLabel); 
        }
    });
    

    return new proxyBiddingView();
});