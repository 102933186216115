
/*jslint browser:true*/
/*global define, root*/
define('globalIdologyCollectionView',['require','jquery','underscore','bootstrap','backbone','handlebars','GoogleAnalyticsUtils','commonutils','apputils','dUtil','validator','dashboardSignInView','bidSignInView','hbs!tpl/idologyVerification/idologyVerificationParent','hbs!tpl/globalIdologyVerification/verifyIdology','hbs!tpl/globalIdologyVerification/verifyIdologyQues','hbs!tpl/globalIdologyVerification/verifyIdologyQuesDet','hbs!tpl/globalIdologyVerification/scanIdUrlLink','hbs!tpl/globalIdologyVerification/verifyIdologyQuesParent','loginRegisterView'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        _ = require("underscore"),
        Bootstrap =require('bootstrap'),
        Backbone = require("backbone");

    var Handlebars = require("handlebars");
    var GAUtils = require("GoogleAnalyticsUtils");
    var Utils = require("commonutils");
    var app = require('apputils');
    var dUtil = require("dUtil");
    var validator = require("validator");
    var dashboardSignInView = require("dashboardSignInView");
    var BidSignInView = require("bidSignInView");
    var idologyVerificationParent = require("hbs!tpl/idologyVerification/idologyVerificationParent");
    var verifyIdologyLeanTmp = require("hbs!tpl/globalIdologyVerification/verifyIdology"); 
    var verifyIdologyQuesTmp = require("hbs!tpl/globalIdologyVerification/verifyIdologyQues");
    var verifyIdologyQuesDetTmp = require("hbs!tpl/globalIdologyVerification/verifyIdologyQuesDet");
    var verifyIdologyScanIdUrlLinkRender = require("hbs!tpl/globalIdologyVerification/scanIdUrlLink");
    var verifyIdologyQuesParent =require("hbs!tpl/globalIdologyVerification/verifyIdologyQuesParent");
    var differentiatorQuestionUrl;
    var pageNameIdology;
    var dashboardSignInView = new dashboardSignInView();
    var loginRegisterView = require('loginRegisterView');
    var self;
    var globalIdologyCollectionView = Backbone.View.extend({
        
        
        events: {
            "blur .idologyFirstName" : "validateNameField",
            "blur .idologyLastName"  : "validateNameField",
            "blur .idologySsn"       : "validateSSN",
            "blur .idologyDob"       : "validateDobNew",
            "keypress .idologySsn" : "validatePhoneInput",
            "keypress .idologyDob"   : "validateDobInput",
            "click .verifyIdologyQuesNext":"showNextQues",
            "click .selectIdologyAnswer" : "selectIdologyAns",
            "click .idologyFirstName,.idologyLastName,.idologySsn,.idologyDob" : "makeTextBoxActive",
            "focus .idologyFirstName,.idologyLastName,.idologySsn,.idologyDob" : "makeTextBoxActive",
            "click .sendScanUrl" : "sendScanUrlToUser",
            "click .verifyIdologyQuesprev" : "showPrevQues",
            "click .closeButtonSubsConfirmation" : "closeToastMessage",
            "click .continueAfterError" : "renderContinueAfterError"
        },

        initialize: function () {
            try{
              this.renderDiv = '';
              this.pageNameIdology = '';
              self = this;
            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:initialize|" + e, null, 1);
           }
            
        },
        makeTextBoxActive:function(e){
          try{
            var obj = '#' + e.target.id;
            $('.'+$(obj).attr('avtarImage')).addClass('active');
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:makeTextBoxActive|" + e, null, 1);
           }
        },
        getIdologyData:function(inputData){
          try{
            self.idologyServiceurl = contextPathPortal+'/lean-regn/getUserProfileVerificationDetails?timeStamp=' + (new Date().getTime()); 
            self.showLoader();
             var callbackFun = self.populateIdologyDetails;
            /*if(inputData !==  undefined){
                callbackFun = inputData.callBackParam;
            }*/
            dUtil.jqueryGet(self.idologyServiceurl , inputData , callbackFun , self);
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + self.pageNameIdology, "file:globalIdologyCollection.js|function:getIdologyData|" + e, null, 1);
          }
       
        },
        sendScanUrlToUser : function(event){
          try{
            var sendIdologyUrl = contextPathPortal + "/sendIdScanMailToUser";          
            self.showLoader();
            GAUtils.eventTracking.trackevent(this.pageNameIdology,'Verify Idology - Send Scan URL' , window.userSession.userId);
            dUtil.jqueryGet(sendIdologyUrl , '' , this.sendEmailToUser , this);
            self.showLoader(); 
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:sendScanUrlToUser|" + e, null, 1);
           }   
        },
        showLoader: function () {
          $(".globalAlertTuples .loader").show();
          $('#'+this.renderDiv).addClass('hide');
      },

      hideLoader: function () {
          $('#'+this.renderDiv).removeClass('hide');  
          $(".globalAlertTuples .loader").hide();
      },
        validatePhoneInput : function(event){
          try
          {
            var obj = '#' + event.target.id;
            if(validator.phoneField(event , obj)){
                  return true;
              }else{
                  return false;
              }
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:validatePhoneInput|" + e, null, 1);
           }
        },
        sendEmailToUser:function(outData , inputData , self){
             try{ 
                self.hideLoader(); 
                if(outData.status === "SUCCESS"){
                   $('#'+self.renderDiv+' .idScanUrlDiv').addClass('hide');
                   $('#'+self.renderDiv+' .idScanUrlSuccess').removeClass('hide');  
                }else{
                   $('#'+self.renderDiv+' .idScanUrlSuccess').addClass('hide');
                   $('#'+self.renderDiv+' .idScanUrlDiv').removeClass('hide');
                }
              }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:sendEmailToUser|" + e, null, 1);
             }
        },
        populateIdologyDetails : function(outData , inputData , self){
            try{
              self.hideLoader();
              if(outData.status){
                  var userId = (typeof window.userSession !== "undefined") && userSession.userId || '';
                  var trackText =  'UID : '+ userId + ' | Visitor Id :'+$.cookie('visitorId');
                  
                  GAUtils.eventTracking.trackevent(self.pageNameIdology,'Verify Idology Request - Success' , trackText);
                   if(outData.data !== undefined && outData.data !== null ){
                    outData.data.errorMsgCMS=dashboardContent.myProfilePageContent.idology.config.errorMessages;
                    outData.data.content = dashboardContent.myProfilePageContent.idology;
                    outData.data.idologyVerificationHead =dashboardContent.myProfilePageContent.idology.sections.idologyVerificationHead;
                  }
                  if(outData.status === 'SESSION_REQUIRED'){
                      GAUtils.eventTracking.trackevent(self.pageNameIdology,'Verify Idology Request - Session Required' , trackText);
                      dashboardSignInView.render(self.fetchIdologyAfterSignIn , self);
                  }else{
                    
                    self.renderIdologyBlock(outData);
                  }
              }else{
                  GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + self.pageNameIdology, "file:globalIdologyCollection.js|function:populateIdologyDetails|technical error", null, 1);
              }
            }catch(e){
               GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + self.pageNameIdology, "file:globalIdologyCollection.js|function:populateIdologyDetails|" + e, null, 1);
            }
        },
        
        renderIdologyBlock:function(outData){
          try{
            if(outData.data.userIdologyDetailsDto.attempsExceeded === true){
              $('.profileIntroText').addClass('hide');
              GAUtils.eventTracking.trackevent(this.pageNameIdology,'Verification Click - Open Scan ' , window.userSession.userId);
              $('#'+this.renderDiv).html(verifyIdologyScanIdUrlLinkRender(outData.data));
            }else{
                GAUtils.eventTracking.trackevent(this.pageNameIdology,'Verification Click - Idology Screen ' , window.userSession.userId);
                $('.profileIntroText').removeClass('hide');
                $('#'+this.renderDiv).html(verifyIdologyLeanTmp(outData.data));
                $('#'+this.renderDiv+' .idologyFirstName').blur();
                $('#'+this.renderDiv+' .idologyLastName').blur();
                this.addSuccessClass($('#'+this.renderDiv+' .idologyFirstName'));
                this.addSuccessClass($('#'+this.renderDiv+' .idologyLastName'));
            }
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:renderIdologyBlock|" + e, null, 1);
           }

        },
        fetchIdologyAfterSignIn:function(self){
          try{
                self.hideLoader();
                GAUtils.eventTracking.trackevent(self.pageNameIdology,'Verification Click - Idology After signin' , window.userSession.userId);
                dUtil.jqueryGet(self.idologyServiceurl , '' , self.getIdologyData , self);
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Global Idology Verification", "file:globalIdologyCollection.js|function:fetchIdologyAfterSignIn|" + e, null, 1);
            }
        },
        addErrorClass: function(elementObject, hasValue){

            try{
                var closestNode = $(elementObject).closest('.input-box-container');
                $(closestNode).find('.input-box-error').addClass('input-label-error');
                $(closestNode).find('.input-box-success').removeClass('input-label-success');
                $('.' + $(elementObject).attr('error')).addClass('has-input-error');
                $(closestNode).find('.input-box-active').removeClass('input-box-progress');
                
                $('.'+$(elementObject).attr('iconname')).removeClass('clicked');
                $('.'+$(elementObject).attr('avtarImage')).removeClass('active');
                
                $(elementObject).addClass('hasInputError');
  
                if(hasValue){
                  $(closestNode).find('.input-box-label').addClass('input-box-filled');  
                } else {
                  $(closestNode).find('.input-box-label').removeClass('input-box-filled');  
                }
                this.networkCallInProgress = false;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Global Idology Verification", "file:globalIdologyCollection.js|function:addErrorClass|" + e, null, 1);
            }
  
          },  
          addSuccessClass: function(elementObject){
            try{
                var closestNode = $(elementObject).closest('.input-box-container');
                $(closestNode).find('.input-box-success').addClass('input-label-success');
                $(closestNode).find('.input-box-error').removeClass('input-label-error');
                $(closestNode).find('.input-box-label').addClass('input-box-filled');
                $('.' + $(elementObject).attr('error')).removeClass('has-input-error');
                $(closestNode).find('.input-box-active').removeClass('input-box-progress');
                $(elementObject).removeClass('hasInputError');
  
                $('.'+$(elementObject).attr('iconname')).removeClass('clicked');
                $('.'+$(elementObject).attr('avtarImage')).removeClass('active');
  
                this.networkCallInProgress = false;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Global Idology Verification", "file:globalIdologyCollection.js|function:addSuccessClass|" + e, null, 1);
            }
          },
          addInprogressClass: function(elementObject){
              var closestNode = $(elementObject).closest('.input-box-container');
              $(closestNode).find('.input-box-label').addClass('input-box-filled');
              $(closestNode).find('.input-box-active').addClass('input-box-progress');
  
              this.networkCallInProgress = true;
          },
          
        validateNameField : function(event){
            try{
                var obj =  event.target,
                    value = $.trim($(obj).val());

                
                    if(value === ''){
                        $('#'+this.renderDiv+' .' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.isRequiredLabel);
                        $(obj).addClass('inputError'); 
                        this.addErrorClass(obj, false); 
                    }else if(!(validator.isValidName(value, obj))){
                        $('#'+this.renderDiv+' .' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.charactersAllowedLabel);
                        $(obj).addClass('inputError');
                        this.addErrorClass(obj, false);
                    }else{
                        $('#'+this.renderDiv+' .' + $(obj).attr('error')).addClass('hide');
                        $(obj).removeClass('inputError');
                        this.addSuccessClass(obj, false);
                    }
                

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:validateNameField|" + e, null, 1);
            }
        },
        validateSSN: function(event){
          try {
            var idologyValue = event.target;
            if($.trim($(idologyValue).val()) === ''){
                $('#'+this.renderDiv+' .' + $(idologyValue).attr('error')).removeClass('hide').html(_.escape(saveListDrawerContent.errorMessages.ssnRequired));
                $(idologyValue).addClass('inputError');
                this.addErrorClass(idologyValue, false);
            } else if(!(validator.activationCode($.trim($(idologyValue).val()) , idologyValue))){
                $('#'+this.renderDiv+' .' + $(idologyValue).attr('error')).removeClass('hide').html(_.escape(dashboardContent.myProfilePageContent.idology.config.errorMessages.errorSsn));
                $(idologyValue).addClass('inputError');
                this.addErrorClass(idologyValue, true);
            } else {
                $('#'+this.renderDiv+' .' + $(idologyValue).attr('error')).addClass('hide');
                $(idologyValue).removeClass('inputError');
                this.addSuccessClass(idologyValue);
            }              
          } catch(e) {
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:validateSSN|" + e, null, 1);
          }    
        },
       validateDobNew : function(e){

          try{
            var obj = e.target;
            var element = $('#'+this.renderDiv+' .idologyDob' );
            var value = element.val();
            var date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
            //var date_regex = /^((0?[13578]|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([2-9])(\d{1})|(20)([01])(\d{1})|([8901])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([0-9])(\d{1})|(20)([01])(\d{1})|([8901])(\d{1})))$/;
            if($.trim(value) === ''){
                $('.' + element.attr('error')).text(saveListDrawerContent.errorMessages.dobRequired).removeClass('hide');
                element.addClass('inputError');
                this.addErrorClass(element, true);
            } else if(!date_regex.test(value)) {
    
                $('.' + element.attr('error')).text(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages.errorDOB).removeClass('hide');
                element.addClass('inputError');
                this.addErrorClass(element, true);
    
            } else {
                //check for leap year
                var dtArray = value.split("/");
                var dtMonth = dtArray[0];
                var dtDay= dtArray[1];
                var dtYear = dtArray[2]; 
                var flag = true;
    
                if ((dtMonth==4 || dtMonth==6 || dtMonth==9 || dtMonth==11) && dtDay ==31) {
                    $('.' + element.attr('error')).text(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages.errorLeapYear).removeClass('hide');
                    element.addClass('inputError');
                    this.addErrorClass(element, true);
                    flag = false;
                } else {
                    $('.' + element.attr('error')).addClass('hide');
                    element.removeClass('inputError');
                    this.addSuccessClass(element);
                }
                
                if (dtMonth == 2) 
                {
                    var isleap = (dtYear % 4 == 0 && (dtYear % 100 != 0 || dtYear % 400 == 0));
                    if (dtDay> 29 || (dtDay ==29 && !isleap)) {
                    $('.' + element.attr('error')).text(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages.errorLeapYear).removeClass('hide');
                    element.addClass('inputError');
                    this.addErrorClass(element);
                    flag = false;
                    } else {
                    $('.' + element.attr('error')).addClass('hide');
                    element.removeClass('inputError');
                    this.addSuccessClass(element);
                    } 
                } 
    
                if(flag) {
                var actualDate = new Date();
                var newDate = new Date(actualDate.getFullYear()-18, actualDate.getMonth(), actualDate.getDate()+1);
                var enteredDate= new Date(value);
                if(enteredDate < newDate) {
                    $('.' + element.attr('error')).addClass('hide');
                    element.removeClass('inputError');
                    this.addSuccessClass(element);
                    
                } else {
                    $('.' + element.attr('error')).text(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages.errorValidAge).removeClass('hide');
                    element.addClass('inputError');
                    this.addErrorClass(element,true);
                } 
                }  
            }

            

          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:validateDobNew|" + e, null, 1);
          }
        },
        validateDobInput : function(event){
          try
          {
            var unicode=event.charCode? event.charCode : event.keyCode;
            if (unicode!=8 && unicode != 37 && unicode != 39 && unicode != 46){
                if (unicode == 36){
                  return false;
                }else if (unicode >= 47 && unicode <= 57 ){
                  return true;
                }else if(unicode==9){
                  return true;
                }else{
                  return false; //disable key press
                }
              }
            else{
                return true;
            }
          }  
          catch(e)
           {
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:validateDobInput|" + e, null, 1);
           }
        },
        submitIdologyDetails : function(param){
          try{
            self=this;
            var submitIdologyJson={},
                obj = param.element.target;
            var funnelStart = localStorage.getItem("funnelStart") || false;
            var addGAReferrer = "";
            if(funnelStart) addGAReferrer = " | Referrer: Global Alerts";
              
            
            $('#'+this.renderDiv+' .userFormIdology .inputError').removeClass('inputError');
            
            $('#'+this.renderDiv+' .userFormIdology input').each(function(){
                  $(this).filter(':visible').blur();
            }); 
            var errorItem =  $(obj).find('input.hasInputError');
            var errorItemInputError =  $(obj).find('input.inputError');

            if(errorItem.length > 0 || errorItemInputError > 0){                
                $(obj).find('input.inputError').first().focus();
                return false;
            }else{
              self.showLoader();
              this.dob = $('#'+this.renderDiv+' .idologyDob').val();
              this.ssn = $('#'+this.renderDiv+' .idologySsn').val();
              submitIdologyJson ={userBasicDetails:{firstName: $('#'+this.renderDiv+' .idologyFirstName').val(), lastName: $('#'+this.renderDiv+' .idologyLastName').val(), emailId: $('#'+this.renderDiv+' .idologyEmailId').val()},dob: $('#'+this.renderDiv+' .idologyDob').val(),ssn:$('#'+this.renderDiv+' .idologySsn').val()};
              var idologyUrl = contextPathPortal+'/lean-regn/verifyUserProfile'; 
              
              GAUtils.eventTracking.trackevent(self.pageNameIdology,'Submit Idology' , window.userSession.userId + addGAReferrer);
              dUtil.jqueryPost(idologyUrl , submitIdologyJson , self.renderIdologyQuestioneer , this);
            }
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + self.pageNameIdology, "file:globalIdologyCollection.js|function:submitIdologyDetails|" + e, null, 1);
          }
        },
        submitIdologyAfterSignIn:function(callBackParams){
          var submitIdologyJson = callBackParams.inputData;
          var idologyUrl = contextPathPortal+'/lean-regn/verifyUserProfile'; 
          GAUtils.eventTracking.trackevent(this.pageNameIdology,'Submit Idology' , window.userSession.userId);
          dUtil.jqueryPost(idologyUrl , submitIdologyJson , callBackParams.self.renderIdologyQuestioneer , callBackParams.self);
        },
        renderIdologyQuestioneer: function(outData , inputData , self){

         //outData={"data":{"errors":["errorIdologyIncorrectAnswers"],"remainingAttemptCount":2,"maskedDuplicateEmailID":null,"questions":[{"prompt":"With which name are you associated?","type":"alternate.names.phone","answers":["PAISLEY","PATEL","PRESCOTT","None of the above"]},{"prompt":"What are the first two digits of your Social Security Number?","type":"ssn.digits.start","answers":["35","36","40","None of the above"]},{"prompt":"When did you purchase or lease your Acura 3.2 TL?","type":"vehicle.purchase.date","answers":["October 2003","June 2005","May 2008","None of the above"]},{"prompt":"What year is your Acura 3.2 TL?","type":"vehicle.year","answers":["2002","2003","2004","None of the above"]}],"differentiatorQuestion":null,"mailSent":false,"attempsExceeded":false,"resetCountExceeded":false,"scanAvailable":false},"status":"SUCCESS"};
         //outData={"data":{"errors":null,"remainingAttemptCount":2,"maskedDuplicateEmailID":null,"differentiatorQuestion":{"prompt":"When did you purchase or lease your Acura 3.2 TL?","type":"vehicle.purchase.date","answers":["October 2003","June 2005","May 2008","None of the above"]},"questions":null,"mailSent":false,"attempsExceeded":false,"resetCountExceeded":false,"scanAvailable":false},"status":"SUCCESS"};
         //outData={"data":{"errors":null,"remainingAttemptCount":2,"maskedDuplicateEmailID":null,"differentiatorQuestion":null,"questions":null,"mailSent":false,"attempsExceeded":true,"resetCountExceeded":true,"scanAvailable":true},"status":"Failure"};
         //outData = {"status":"SUCCESS","errorMessage":null,"errorTrace":null,"successMessage":null,"data":{"errors":null,"remainingAttemptCount":null,"maskedDuplicateEmailID":null,"questions":null,"differentiatorQuestion":null,"mailSent":false,"attempsExceeded":false,"resetCountExceeded":false,"scanAvailable":false}};
         try{
            if(outData.data === undefined || outData.data === null ){
              outData.data={};
            }
            outData.data.errorMsgCMS=dashboardContent.myProfilePageContent.idology.config.errorMessages;
            outData.data.idologyVerificationQuesHead =dashboardContent.myProfilePageContent.idology.sections.idologyVerificationQuesHead;
            var progressStatus=[];
            self.hideLoader();
            var data = outData.data;
            var idologyObj = this;
           
            
            if(outData.status === 'SESSION_REQUIRED'){
              var callBackParams={};
              callBackParams.self = self;
              callBackParams.inputData = inputData;

             if(window.locationUrl.indexOf('my-shopper')>-1){
                dashboardSignInView.render(self.submitIdologyAfterSignIn , callBackParams);
              }else{
                BidSignInView.render( self.submitIdologyAfterSignIn , callBackParams ) 
              }
            }
            else if(outData.status === "SUCCESS"){
              $('.idologyHeading').html(dashboardContent.myProfilePageContent.idology.sections.idologyVerificationQuesHead);
              if (outData.data.differentiatorQuestion != null){
                $('.profileProgressBar').addClass('hide');
                GAUtils.eventTracking.trackevent(self.pageNameIdology,'Idology Questions Render' , window.userSession.userId);
                outData.data.questions = [];
                outData.data.questions[0]=outData.data.differentiatorQuestion;
                
                differentiatorQuestionUrl = true;
              //  $('#verifyIdologyLean').html(verifyIdologyQuesParent(outData));
              outData.data.renderDiv = self.renderDiv;
                $('#'+self.renderDiv).html(verifyIdologyQuesTmp(outData));
                
                self.verifyIdologyQuesDetTmpRender(outData);
               /* if(locationUrl.indexOf('?')<=-1){
                    var idologyPageUrl = locationUrl+"?userType=buyr_role_agent_info&page=idologyQuestionDifferentiator";
                  }else{
                    var idologyPageUrl = locationUrl+"&userType=buyr_role_agent_info&page=idologyQuestionDifferentiator";
                  }*/
                  GAUtils.eventTracking.trackevent(self.pageNameIdology,'Idology differentiatorQuestion',window.userSession.userId);
                  GAUtils.eventTracking.processTrackPV(window.location.href,'?idologyquesRender=quesRender');
              } else if(data.questions != null && data.questions.length > 0) {
                $('.profileProgressBar').addClass('hide');
               // $('#verifyIdologyLean').html(verifyIdologyQuesParent(outData));
                GAUtils.eventTracking.trackevent(self.pageNameIdology,'Idology Questions Render' , window.userSession.userId);
                outData.data.renderDiv = self.renderDiv;
                $('#'+self.renderDiv).html(verifyIdologyQuesTmp(outData));
                
                self.verifyIdologyQuesDetTmpRender(outData);
                GAUtils.eventTracking.trackevent(self.pageNameIdology,'Idology Questions',window.userSession.userId);
               // that.trackIdologyForm();
                if(locationUrl.indexOf('?')<=-1){
                    var idologyPageName = locationUrl+"?userType=buyr_role_agent_info&page=idologyQuestion";
                  }else{
                    var idologyPageName = locationUrl+"&userType=buyr_role_agent_info&page=idologyQuestion";
                  }
                  GAUtils.eventTracking.processTrackPV(window.location.href,'?idologyquesRender=quesRender');
              } else{
                GAUtils.eventTracking.trackevent( self.pageNameIdology,"Confirm - Idology Verification Success",window.userSession.userId);
                self.renderConfirmationPage();
                if(self.callBackParams!=undefined){
                    var callbackFunc = self.callBackParams.callBackParam;
                    callbackFunc();
                }
              }
            }else {
              if(outData.data.attempsExceeded){
                var idologyData={};
                 GAUtils.eventTracking.trackevent( self.pageNameIdology,"Confirm - Idology Failure Attempts Exceeded",window.userSession.userId);
                idologyData.userIdologyDetailsDto = outData.data;
                idologyData.content = dashboardContent.myProfilePageContent.idology;
                $('#'+self.renderDiv +' .profileIntroText').addClass('hide');
                $('#'+self.renderDiv).html(verifyIdologyScanIdUrlLinkRender(idologyData));
              }else{
                GAUtils.eventTracking.trackevent(self.pageNameIdology,"Confirm - Idology Failure "+outData.errorMessage,window.userSession.userId);
                $('#'+self.renderDiv +' .errorIdologyMessages').removeClass('hide').attr('tabindex','0');
                if(data.maskedDuplicateEmailID != null){
                  $('#'+self.renderDiv +' .errorIdologyMessages').html(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages[outData.errorMessage].replace("{{email}}", data.maskedDuplicateEmailID));
                }else{
                  $('#'+self.renderDiv +' .errorIdologyMessages').html(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages[outData.errorMessage]);
                  if(data.remainingAttemptCount != null){
                    $('#'+self.renderDiv +' .errorIdologyAttemptExceeded').removeClass('hide').attr('tabindex','0');
                    $('#'+self.renderDiv +' .errorIdologyAttemptExceeded').html(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages.errorAttemptExceeded.replace("{{attempt}}", data.remainingAttemptCount)); 
                  }   
                }
                
                if ($('.tuple-container'))
                  $('.tuple-container').animate({ scrollTop: $('.errorIdologyMessages').offset().top }, 800,null);

              }
          }
        }catch(e){
          GAUtils.eventTracking.trackevent("Error", self.pageNameIdology, "file:globalIdologyCollection.js|function:renderIdologyQuestioneer|" + e, null, 1);
        }
        },
        verifyIdologyQuesDetTmpRender:function(outData){
          try{
            outData.data.renderDiv = this.renderDiv;
            $('#'+this.renderDiv +' .quesDiv').html(verifyIdologyQuesDetTmp(outData));
            this.selectIdologyAns();
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", this.pageNameIdology, "file:globalIdologyCollection.js|function:verifyIdologyQuesDetTmpRender|" + e, null, 1);
          }
        },
        showNextQues:function(e){
          try{
            var isOptionSelected = true;
            var element = e.target;
            if(!$(element).hasClass('maskButtonEvents') && $('#'+this.renderDiv +' .verifyIdologySubmit').hasClass('maskButtonEvents')){
              $('#'+this.renderDiv +' .idologyQuestions').addClass('hide');
              var nextId= parseInt($(element).attr('currQuesId'))+1;
              var currId =$(element).attr('currQuesId');
              GAUtils.eventTracking.trackevent( this.pageNameIdology,'CLick Next step'+nextId,window.userSession.userId);
              $('#'+this.renderDiv+'idologyQues'+nextId).removeClass('hide');
              $('#'+this.renderDiv +' .step'+nextId).removeClass('active').addClass('done');
              $('#'+this.renderDiv +' .step'+nextId).find('.spanContentDone').removeClass('hide').addClass('glyphicon glyphicon-ok');
              $('#'+this.renderDiv +' .step'+nextId).find('.spanContent').addClass('hide');
              $(element).attr('currQuesId', nextId);
              var nextProgressId = nextId+1;
              $('#'+this.renderDiv +' .verifyIdologyQuesprev').attr('currQuesId', nextId);
              $('.step'+nextProgressId).addClass('active');
            }
            this.selectIdologyAns();
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:showNextQues|" + e, null, 1);
           }
        },
        showPrevQues:function(e){
            try{
              var isOptionSelected = true;
              var element = e.target;
              var prevId= parseInt($(element).attr('currQuesId'))-1;
              var currId =$(element).attr('currQuesId');
              if(currId >= 1 && !$(element).hasClass('maskButtonEvents')){
                $('#'+this.renderDiv +' .idologyQuestions').addClass('hide');
                GAUtils.eventTracking.trackevent( this.pageNameIdology,'CLick Prev step'+prevId,window.userSession.userId);
                $('#'+this.renderDiv+'idologyQues'+prevId).removeClass('hide');
                $('#'+this.renderDiv +' .step'+currId).addClass('active').removeClass('done');
                $('#'+this.renderDiv +' .step'+currId).find('.spanContentDone').addClass('hide').removeClass('glyphicon glyphicon-ok');
                $('#'+this.renderDiv +' .step'+currId).find('.spanContent').removeClass('hide');
                var removeActivate = parseInt(currId)+1;
                $('#'+this.renderDiv +' .step'+removeActivate).removeClass('active');
                $(element).attr('currQuesId', prevId);
                var prevProgressId = prevId;
                $('#'+this.renderDiv +' .verifyIdologyQuesNext').attr('currQuesId', prevId);
                
                //$('#verifyIdologyQuesNext').removeClass('maskButtonEvents');
                this.selectIdologyAns();
              }
            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:showPrevQues|" + e, null, 1);
           }
        },
        selectIdologyAns:function(e){
          try{
              var currQues = $('#'+this.renderDiv +' .verifyIdologyQuesNext').attr('currQuesId');
              if(parseInt($('#'+this.renderDiv +' .verifyIdologyQuesNext').attr('currQuesId')) < parseInt($('#'+this.renderDiv +' .verifyIdologyQuesNext').attr('totalCount'))-1 && $('#'+this.renderDiv +' .selectedAnswer'+currQues+':checked').val() !='' && $('#'+this.renderDiv +' .selectedAnswer'+currQues+':checked').val() !=undefined){
                $('#'+this.renderDiv +' .verifyIdologyQuesNext').removeClass('maskButtonEvents');
                $('#'+this.renderDiv +' .verifyIdologySubmit').addClass('maskButtonEvents');
               
              }else if(!(parseInt($('#'+this.renderDiv +' .verifyIdologyQuesNext').attr('currQuesId')) < parseInt($('#'+this.renderDiv +' .verifyIdologyQuesNext').attr('totalCount'))-1) &&  $('#'+this.renderDiv +' .selectedAnswer'+currQues+':checked').val() !='' && $('#'+this.renderDiv +' .selectedAnswer'+currQues+':checked').val() !=undefined){
                $('#'+this.renderDiv +' .verifyIdologySubmit').removeClass('maskButtonEvents');
                $('#'+this.renderDiv +' .verifyIdologyQuesNext').addClass('maskButtonEvents');
               
              }else{
                $('#'+this.renderDiv +' .verifyIdologySubmit,#'+this.renderDiv +' .verifyIdologyQuesNext').addClass('maskButtonEvents');
              }
              if(parseInt($('#'+this.renderDiv +' .verifyIdologyQuesNext').attr('currQuesId'))>0){
                $('#'+this.renderDiv +' .verifyIdologyQuesprev').removeClass('maskButtonEvents');
              }else{
                $('#'+this.renderDiv +' .verifyIdologyQuesprev').addClass('maskButtonEvents');
              }
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:selectIdologyAns|" + e, null, 1);
           }
        },
        submitIdologyQuestions:function(callBackParam){
          try{
              var idologyQuestionAnswers={}
             // var differentiatorQuestionUrl=false;
              idologyQuestionAnswers.ssn=this.ssn;
              idologyQuestionAnswers.dob=this.dob;
              GAUtils.eventTracking.trackevent(this.pageNameIdology,'Idology Questions Submit',window.userSession.userId);
              $('.idologyQuestions').each(function(){
                  var currId = this.id;
                  idologyQuestionAnswers[$('#'+currId+' .questionType').attr('dataName')]=$('#'+currId+' .questionType').val();
                  idologyQuestionAnswers[$('#'+currId+ ' :checked').attr('dataName')]=$('#'+currId+ ' .selectIdologyAnswer:checked').val();
              });
              self.showLoader();
              if(differentiatorQuestionUrl === true){
                  var idologyQuestionnaries = contextPathPortal + "/idologyDifferentiation";
              }
              else{
                  var idologyQuestionnaries = contextPathPortal + "/idologyQuestionnaires";
              }
              this.callBackQuesSuccess=callBackParam;
              dUtil.jqueryPostJson(idologyQuestionnaries , idologyQuestionAnswers , this.idologyQuestionnariesCallback , this);
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:submitIdologyQuestions|" + e, null, 1);
           }
        },
        submitIdologyQuesAfterSignIn:function(callBackParams){
          var submitIdologyJson = callBackParams.inputData;
          var idologyUrl = contextPathPortal+'/lean-regn/verifyUserProfile'; 
          GAUtils.eventTracking.trackevent(this.pageNameIdology,'Submit Idology' , window.userSession.userId);
          if(differentiatorQuestionUrl === true){
              var idologyQuestionnaries = contextPathPortal + "/idologyDifferentiation";
          }
          else{
              var idologyQuestionnaries = contextPathPortal + "/idologyQuestionnaires";
          }
          this.callBackQuesSuccess=callBackParam;
          dUtil.jqueryPostJson(idologyQuestionnaries , idologyQuestionAnswers , callBackParams.self.idologyQuestionnariesCallback , callBackParams.self);
        },
        idologyQuestionnariesCallback:function(outData , inputData , self){
          try{
            self.hideLoader();

            var funnelStart = localStorage.getItem("funnelStart") || false;
            var addGAReferrer = "";
            if(funnelStart) {
              addGAReferrer = " | Referrer: Global Alerts";
              localStorage.removeItem("funnelStart");
            }
          if(outData.isLoggedOut === true){
              var callBackParams={};
              callBackParams.self = self;
              callBackParams.inputData = inputData;

              if(window.locationUrl.indexOf('my-shopper')>-1){
                dashboardSignInView.render(self.submitIdologyQuesAfterSignIn , callBackParams);
              }else{
                BidSignInView.render( self.submitIdologyQuesAfterSignIn , callBackParams ) 
              }
            }else if(outData.status === "SUCCESS"){
                self.renderConfirmationPage();
               
                  GAUtils.eventTracking.trackevent(  self.pageNameIdology,"Idology - Verification Success",outData.status,window.userSession.userId + addGAReferrer);
               

                
                
            }else if (outData.status === "errorIdologyIncorrectAnswers") {
              GAUtils.eventTracking.trackevent(  self.pageNameIdology,"Idology Question Click Response Failure  - ", dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages[outData.status].replace("{{attempt}}", outData.idology.remainingAttemptCount) ,window.userSession.userId+addGAReferrer);
              
              $('#'+self.renderDiv +' .errorIdologyMessages').removeClass('hide');
             
              $('#'+self.renderDiv +' .errorIdologyMessages').html(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages[outData.status].replace("{{attempt}}", outData.idology.remainingAttemptCount));
              outData.data=outData.idology;
              $('#'+self.renderDiv +' .quesDiv,.submitDoit').addClass('hide');
              self.verifyIdologyQuesDetTmpRender(outData);
              self.resetQuesForm();
              $('#'+self.renderDiv +' .quesContinueAfterError').removeClass('hide');

            }  else {
              if(outData.idology !=undefined && outData.status=='errorIdologyAttemptsExceeded'){
                var idologyData={};
                GAUtils.eventTracking.trackevent( self.pageNameIdology,"Idology Question Click Response Failure errorIdologyAttemptsExceeded",window.userSession.userId);
                idologyData.userIdologyDetailsDto = outData.idology;
                idologyData.content = dashboardContent.myProfilePageContent.idology;
                $('#'+self.renderDiv ).html(verifyIdologyScanIdUrlLinkRender(idologyData));
              }else{
                GAUtils.eventTracking.trackevent( self.pageNameIdology,"Idology Question Click Response Failure"+dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages[outData.status],window.userSession.userId + addGAReferrer);
                $('#'+self.renderDiv +' .errorIdologyMessages').removeClass('hide');
                $('#'+self.renderDiv +' .errorIdologyMessages').html(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages[outData.status]);
                $('#'+self.renderDiv +' .errorIdologyMessages').removeClass('hide');
                self.resetQuesForm();
                if(outData.idology !=undefined){
                  $('#'+self.renderDiv +' .errorIdologyMessages').html(dashboardContent.myProfilePageContent.idology.config.errorMessages.idologyErrorMessages[outData.status].replace("{{attempt}}", outData.idology.remainingAttemptCount));
                }
                
              }
            }
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:idologyQuestionnariesCallback|" + e, null, 1);
           }

        },
        renderContinueAfterError : function(){
          $('#'+this.renderDiv +' .quesContinueAfterError,#'+this.renderDiv +' .errorIdologyMessages').addClass('hide');
          $('#'+this.renderDiv +' .quesDiv,#'+this.renderDiv +' .submitDoit').removeClass('hide');

        },
        resetQuesForm:function(){
            try{
              $('#'+this.renderDiv +' .selectIdologyAnswer').prop('checked', false);
              $('#'+this.renderDiv +' .idologyQuestions').addClass('hide');
              $('#'+this.renderDiv +'idologyQues0').removeClass('hide');
              $('#'+this.renderDiv +' .spanContentDone').addClass('hide');
              $('#'+this.renderDiv +' .spanContent').removeClass('hide');
              $('#'+this.renderDiv +' .stepParent').removeClass('done active');
              $('#'+this.renderDiv +' .step1').addClass('active');
             /* $('#verifyIdologyQuesNext').addClass('maskButtonEvents');
              $('#verifyIdologySubmit').addClass('maskButtonEvents');*/
              $('#'+this.renderDiv +' .verifyIdologyQuesNext').attr('currquesid',0);
              $('#'+this.renderDiv +' .verifyIdologyQuesprev').attr('currquesid',1).addClass('maskButtonEvents');
            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:resetQuesForm|" + e, null, 1);
           }
        },
        closeToastMessage:function(){
          $('#profileVerificationSuccess').hide();
        },
        renderConfirmationPage:function(){
           try{
              /*$('#successContentProfileConfirmation').html(dashboardContent.myProfilePageContent.idology.config.profileConfirmationContent);
              $('#profileVerificationSuccess').fadeIn()
              $('#profileVerificationSuccess').fadeIn('300').delay(2000).fadeOut('slow');
              $('#verifyIdologyContent').remove();*/
              this.updateGlobalAlertsStatus();

              //This is required to get the idology status as part of user session 
              if(typeof golbalPDPView !== "undefined" && golbalPDPView.showAlertsContainer) {
			        	golbalPDPView.showAlertsContainer = false;
                window.globalAlertView.showAlertsDrawer();
              }

              Utils.checkIfLoggedIn();
            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "Global Idology Verification" + this.pageNameIdology, "file:globalIdologyCollection.js|function:renderConfirmationPage|" + e, null, 1);
           }
        },
        updateGlobalAlertsStatus: function () {
            try {
                /* Triggering global alert Engine */
                self.hideLoader();
                if (window.globalAlertView && window.userSession && window.userSession.userId) {
                  window.userSession.idologyStatus = true;
                  /*window.globalAlertView.listenStateChage({
                      userId: window.userSession.userId
                  });*/
                  if(firebase && firebase.apps.length){
                    window.globalAlertView.signOutFirebase();    
                  }
                  window.globalAlertView.displayAlertsView({ view: 'loader' });
                  window.globalAlertView.getPropertyDetails('', window.globalAlertView.showAlerts);

              }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "GlobalAlerts" + this.pageNameIdology, "file:globalIdologyCollection.js|function:updateGlobalAlertsStatus|" + e, null, 1);
            }
        }
    });

    return globalIdologyCollectionView;
});
