
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/whatsNewFeature',['hbs','hbs/handlebars', 'hbs!templates/globalAlerts/arrow','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <ol class=\"carousel-indicators\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.alerts : depth0), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </ol>\n";
},"2":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        <li data-target=\"#whats-new-carousel\" data-slide-to=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" class=\"indicators\" data-name=\"indicators\" data-label=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\"></li>\n";
},"4":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "        <div class=\"item ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (data && data.index), 0, {"name":"ifEqual","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n            <div class=\"carousel-caption\">\n                <div class=\"wn-head\">";
  stack1 = ((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"header","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n                <div class=\"wn-desc\">\n                    ";
  stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                </div>\n                <button class=\"btn btn-primary wn-action\" data-slide-index=\""
    + escapeExpression(lambda((data && data.index), depth0))
    + "\">";
  stack1 = lambda(((stack1 = ((stack1 = (depths[1] != null ? depths[1].content : depths[1])) != null ? stack1.labels : stack1)) != null ? stack1.readmore : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</button>\n            </div>\n        </div>\n";
},"5":function(depth0,helpers,partials,data) {
  return "active";
  },"7":function(depth0,helpers,partials,data) {
  var stack1, buffer = "    <a class=\"left carousel-control\" href=\"#whats-new-carousel\" role=\"button\" data-name=\"navigation\" data-slide=\"prev\" data-label=\"prev\">\n";
  stack1 = this.invokePartial(partials['templates/globalAlerts/arrow'], '        ', 'templates/globalAlerts/arrow', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </a>\n    <a class=\"right carousel-control\" href=\"#whats-new-carousel\" role=\"button\" data-name=\"navigation\" data-slide=\"next\" data-label=\"next\">\n";
  stack1 = this.invokePartial(partials['templates/globalAlerts/arrow'], '        ', 'templates/globalAlerts/arrow', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </a>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"feature-head\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.featureHeading : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n<div id=\"whats-new-carousel\" class=\"carousel slide whats-new-carousel\" data-ride=\"carousel\" data-interval=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.carouselRotationInterval : stack1), depth0))
    + "\">\n    \n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.enableControls : depth0), {"name":"if","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    \n    <!-- Wrapper for slides -->\n    <div class=\"carousel-inner\" role=\"listbox\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.alerts : depth0), {"name":"each","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "    </div>\n\n    <!-- Controls -->\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.enableControls : depth0), {"name":"if","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/globalAlerts/whatsNewFeature', t);
return t;
});
/* END_TEMPLATE */
;