
/* START_TEMPLATE */
define('hbs!tpl/saveDrawer/favouriteTuple',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, functionType="function", buffer = "<div class=\"row propDetails propDetailsDrawer "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\" data-property-id=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\"watch";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n	<div class=\"col-xs-12 col-sm-12 col-md-12 col-lg-12 tuple-inline-error hide\"></div> \n	<div class=\"col-md-3 col-lg-3 col-xs-3 col-sm-3 favouriteAddress\"> \n		<a href=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\" target=\"_blank\" currentpropid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"  class=\"pdpLink pdpLinkFromDrawer img\" pg=\"1\" tabindex=\"-1\">\n		<img src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.imageDetails : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.imageStoragePath : stack1), depth0))
    + "\" alt=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.state : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.zip : stack1), depth0))
    + "\" onerror=\"javascript:this.src= '"
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/www/images/no-photo.jpg'\" >\n		</a>\n	</div>\n	<div class=\"col-md-6 col-lg-6 col-xs-6 col-sm-6\"> \n		<div class=\"row addressWhole\">\n			<div class=\"col-xs-12  address \">\n				<a class=\"truncate streetAddress pdpLinkFromDrawer\" target=\"_blank\" href=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\" title=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\" currentpropid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" tabindex=\"-1\" href=\"javascript:void(0)\">\n				";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " <br>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.bulkPropertyInfo : depth0)) != null ? stack1.parentBulkPropertyFlag : stack1), "N", {"name":"ifEqual","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "				</a>\n				\n				<div class=\"favorite-cta-container ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.bulkPropertyInfo : depth0)) != null ? stack1.bulkPropertyFlag : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\" data-url=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingType : stack1), "AUCN", {"name":"ifEqual","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingType : stack1), "TRNL", {"name":"ifEqual","hash":{},"fn":this.program(14, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "				</div>\n\n			</div>\n			<div class=\"col-xs-12 attributes\">\n				<div class=\"bedsBaths\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.bedroomsNum : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.bedroomTxt : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n				</div>\n				<div class=\"bedsBaths borderLeft\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.bathsNum : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.bathsTxt : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n				</div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.detailedAttributes : stack1)) != null ? stack1.squareFeet : stack1), {"name":"if","hash":{},"fn":this.program(22, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "			</div>\n		</div>\n		\n	</div>\n	<div class=\"col-md-3 col-lg-3 col-xs-3 col-sm-3 heighest-bid-container ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.bulkPropertyInfo : depth0)) != null ? stack1.bulkPropertyFlag : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"> \n		<a class=\"closeicon deleteWatchProperty\" propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" listingId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" propStatus=\"";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" forWatch=\"Y\" aria-label=\"Delete Watch ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" href=\"javascript:void(0)\">\n			<svg class=\"delete-watch-prop\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n                    width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" enable-background=\"new 0 0 24 24\" xml:space=\"preserve\">\n                    <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" x1=\"2.9\" y1=\"21.2\" x2=\"20.7\" y2=\"3.3\"/>\n                    <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" x1=\"20.7\" y1=\"21.2\" x2=\"2.9\" y2=\"3.3\"/>\n                </svg>\n		</a>\n		<div class=\"current-bid-label\">";
  stack1 = ((helper = (helper = helpers.displayLabel || (depth0 != null ? depth0.displayLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"displayLabel","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n		<div class=\"current-bid-value\" >";
  stack1 = ((helper = (helper = helpers.displayPrice || (depth0 != null ? depth0.displayPrice : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"displayPrice","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n		<div class=\"auction-timer\"></div>\n		<div class=\"bids-placed-count\"></div>\n	</div>\n	\n</div>\n\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "					";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.state : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.zip : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"4":function(depth0,helpers,partials,data) {
  return "is-bulk-property";
  },"6":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "ACTV", {"name":"ifEqual","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "REVIEW", {"name":"ifEqual","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "SPND", {"name":"ifEqual","hash":{},"fn":this.program(9, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"7":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					   		<button data-val=\"placeBidButton\" aira-label=\"Place Bid\" class=\"favorite-cta placeBidButton\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">Place Bid</button>\n";
},"9":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isBestBidAvailable : depth0), {"name":"if","hash":{},"fn":this.program(10, data),"inverse":this.program(12, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"10":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "						   		<button aira-label=\"Place Best Bid\" class=\"favorite-cta placeBestBidButton\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">Place Best Bid</button>\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					   			<button data-val=\"placeBackUpBid\" aira-label=\"Place Backup Bid\" class=\"favorite-cta placeBidButton\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">Place Backup Bid</button>\n";
},"14":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "ACTV", {"name":"ifEqual","hash":{},"fn":this.program(15, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "SPND", {"name":"ifEqual","hash":{},"fn":this.program(20, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"15":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isHnbEnded : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.program(18, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "			   					<button data-val=\"placeOfferButton\" aira-label=\"Place Offer\" class=\"favorite-cta placeOfferButton\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\" propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">Place Backup Offer</button>\n";
},"18":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "					   			<button data-val=\"placeBackUpOffer\" aira-label=\"Place Backup Offer\" class=\"favorite-cta placeOfferButton\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\" propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">Place Offer</button>\n";
},"20":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "				   			<button data-val=\"placeBackUpOffer\" aira-label=\"Place Backup Offer\" class=\"favorite-cta placeOfferButton\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\" propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">Place Backup Offer</button>\n";
},"22":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "					<div class=\"bedsBaths borderLeft\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.detailedAttributes : stack1)) != null ? stack1.squareFeet : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + " sqft </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, blockHelperMissing=helpers.blockHelperMissing, buffer = "\n";
  stack1 = ((helper = (helper = helpers.propertyDetails || (depth0 != null ? depth0.propertyDetails : depth0)) != null ? helper : helperMissing),(options={"name":"propertyDetails","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.propertyDetails) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/saveDrawer/favouriteTuple', t);
return t;
});
/* END_TEMPLATE */
;