
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/alertTuple',['hbs','hbs/handlebars', 'hbs!templates/globalAlerts/loaderIcon','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "        <div class=\"ga-buyer-email bemail-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(((helper = (helper = helpers.buyerEmailId || (depth0 != null ? depth0.buyerEmailId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"buyerEmailId","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"3":function(depth0,helpers,partials,data) {
  return "expired-alert";
  },"5":function(depth0,helpers,partials,data) {
  return "hide";
  },"7":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					<span class=\"ga-alert\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[1] != null ? depths[1].content : depths[1])) != null ? stack1.labels : stack1)) != null ? stack1.auctionEndedLabel : stack1), depth0))
    + "</span> \n";
},"9":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "						<span class=\"ga-alert\">"
    + escapeExpression(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"message","hash":{},"data":data}) : helper)))
    + "</span> \n";
},"11":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.specialFeatures : depth0), {"name":"each","hash":{},"fn":this.program(12, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, depth0, "OWN_IT_NOW_ENABLED", {"name":"ifEqual","hash":{},"fn":this.program(13, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "						<div class=\"ga-property ga-ownitnow-container ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depths[2] != null ? depths[2].messageStatus : depths[2]), "expired", {"name":"ifEqual","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n							<div class=\"divider\">\n								<div class=\"line-divider\"></div>\n								<div class=\"or-divider\">or</div>\n							</div>\n							<div class=\"content\">\n							<button aria-label=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[3] != null ? depths[3].content : depths[3])) != null ? stack1.labels : stack1)) != null ? stack1.ownItNowLabel : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(lambda((depths[2] != null ? depths[2].messageId : depths[2]), depth0))
    + "\" data-ownitnow-amount=\""
    + escapeExpression(lambda((depths[2] != null ? depths[2].buyItNowPrice : depths[2]), depth0))
    + "\" class=\"btn btn-primary ga-place-bid\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[3] != null ? depths[3].content : depths[3])) != null ? stack1.labels : stack1)) != null ? stack1.ownItNowLabel : stack1), depth0))
    + "</button> \n							<span class=\"ownitnow-amount\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[3] != null ? depths[3].content : depths[3])) != null ? stack1.labels : stack1)) != null ? stack1.forLabel : stack1), depth0))
    + " $"
    + escapeExpression(lambda((depths[2] != null ? depths[2].buyItNowFormattedPrice : depths[2]), depth0))
    + "</span>\n							</div>\n						</div>\n";
},"15":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "				<div class=\"row black-border-bottom buyer-premium-container\">\n				<div class=\"col-md-6 col-sm-6 col-lg-6 col-xs-6  padding\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[1] != null ? depths[1].content : depths[1])) != null ? stack1.labels : stack1)) != null ? stack1.buyerPremium : stack1), depth0))
    + " <span class=\"ga-bp-percentage\"></span></div>\n					<div class=\"col-md-5 col-sm-5 col-lg-5 col-xs-5  padding ga-bp-value\"></div>\n				</div>\n";
},"17":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "			<div class=\"h-auto-bid-details pull-left auto-bid-section-global-alerts\">\n	            <div class=\"auto-bid-container\">\n	                <div class=\"auto-bid-label\">\n	                    <div class=\"head\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.headLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n	                    <div class=\"desc\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.descLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n	                </div>\n	                <div class=\"row paddingTopBottom\">\n	                    <div class=\"col-xs-12\">\n	                        <div class=\"slider-switch\">\n	                            <div class=\"auto-bid-label-text\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.autoBidLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n	                            <label class=\"switch\"> \n	                                <input type=\"checkbox\" name=\"enableAutoBidding\" aria-label=\"enable auto bid\" class=\"enable-auto-bid\" id=\"enable-auto-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n	                                <span class=\"slider round\"></span>\n	                                <span class='yes'>Yes</span>\n	                                <span class='no'>No</span>\n	                            </label>\n	                        </div>\n	                    </div>\n	                </div>\n	                <div id=\"autoBidSectionGA-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"autoBidSectionGA\">\n	                    <div class=\"row b-autobid-div paddingTopBottom\"> \n	                        <div class=\"col-xs-6 col-lg-7 col-md-7 col-sm-7 autoBidLimit bidLabelTxt\">\n	                            <label class=\"text-black\" for=\"autoBidLimit\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.autoBidLimitLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</label>\n	                        </div>\n	                        <div class=\"col-xs-6 col-lg-5 col-md-5 col-sm-5\">\n	                            <input type=\"text\" data-root=\"buyer\" data-auto-bid=\"\" aria-label= \"enable auto bid\" data-name=\"middleInitials\" rel=\"b_auto_bid_amount_error\" data-validator=\"name\"  class=\"h-textbox amountField b_autobid_amount fontsize11Mob\" id=\"b_autobid_amount-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\"  maxlength=\"50\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-state=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.state : stack1), depth0))
    + "\">\n	                            <span class=\"h-textbox-error b_auto_bid_amount_error\"></span>\n	                        </div>\n	                    </div>\n	                    <div class=\"row b-auto-bid-bp-value ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.noBuyersPremium : depth0), "Y", {"name":"ifEqual","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n	                        <div class=\"col-xs-6 col-lg-7 col-md-7 col-sm-7 paddingRight0\">\n	                        <label class=\"text-normal\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.buyerPremLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<span id=\"autoBidBPPerc-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\"></span>\n	                        </label>\n	                        </div>\n	                        <div class=\"col-xs-6 col-lg-5 col-md-5 col-sm-5 semiBold b-autobid-bp-text fontsize11Mob\" id=\"b-autobid-bp-text-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\"></div>\n	                    </div>\n	                    <div class=\"row paddingTopBottom\">\n	                        <div class=\"col-xs-6 col-lg-7 col-md-7 col-sm-7\">\n	                            <label class=\"text-normal graphik-medium\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.maximumdue : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label>\n	                        </div>\n	                        <div class=\"col-xs-6 col-lg-5 col-md-5 col-sm-5 semiBold b-autobid-total-text graphik-medium fontsize11Mob\" id=\"b-autobid-total-text-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\"></div>\n	                    </div>\n	                </div>\n	            </div>\n	        </div>\n";
},"19":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "				<input type=\"hidden\" name=\"previousBidEmailId\" value=\""
    + escapeExpression(((helper = (helper = helpers.buyerEmailId || (depth0 != null ? depth0.buyerEmailId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"buyerEmailId","hash":{},"data":data}) : helper)))
    + "\" >				\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userType : depth0), "AGENT", {"name":"ifEqual","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n<li class=\"row ga-tuple-item "
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-min-bid=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.nextpossibleBidAmount : stack1), depth0))
    + "\" data-max-bid=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.maximumPossibleBidAmount : stack1), depth0))
    + "\" data-bid-min-inc=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.bidIncrementMultiplier : stack1), depth0))
    + "\"  data-bid-inc=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.biddingIncrementalAmount : stack1), depth0))
    + "\" \ndata-highest-bid=\"$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.currentHighestBid : stack1), depth0))
    + "\" \ndata-start-bid=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.startingBidAmount : stack1), depth0))
    + "\" \ndata-list-price=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.listPrice : stack1), depth0))
    + "\" \ndata-prop-category=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.propertyCategory : stack1), depth0))
    + "\" \ndata-listing-id=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-listingUrl=\""
    + escapeExpression(((helper = (helper = helpers.listingUrl || (depth0 != null ? depth0.listingUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingUrl","hash":{},"data":data}) : helper)))
    + "\" data-no-buyers-premium=\""
    + escapeExpression(((helper = (helper = helpers.noBuyersPremium || (depth0 != null ? depth0.noBuyersPremium : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"noBuyersPremium","hash":{},"data":data}) : helper)))
    + "\"\ndata-buyer-prem-amount=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.buyerPremimum : stack1), depth0))
    + "\"\ndata-streetNumber=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetNumber : stack1), depth0))
    + "\"\ndata-streetName=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetName : stack1), depth0))
    + "\"\ndata-city=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.city : stack1), depth0))
    + "\"\ndata-county=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.county : stack1), depth0))
    + "\"\ndata-state=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.state : stack1), depth0))
    + "\" \ndata-zip=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.zip : stack1), depth0))
    + "\">\n\n		<div class=\"tuple-data-collapse pull-left\">\n\n			<div class=\"ga-bid-confirm-msg-container hide\">\n				<div class=\"ga-bid-confirm-msg \"></div>\n			</div>\n				\n			<div class=\"ga-tuple-item ga-thumb pull-left\">\n            <a href=\""
    + escapeExpression(((helper = (helper = helpers.listingUrl || (depth0 != null ? depth0.listingUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" title=\"Property image\">\n                <img src=\""
    + escapeExpression(((helper = (helper = helpers.imageUrl || (depth0 != null ? depth0.imageUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"imageUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\" alt=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetNumber : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetName : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.city : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.state : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.zip : stack1), depth0))
    + "\"  onerror=\"javascript:this.src='"
    + escapeExpression(((helper = (helper = helpers.contextPath || (depth0 != null ? depth0.contextPath : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"contextPath","hash":{},"data":data}) : helper)))
    + "/www/images/image-not-available.jpg'\" alt=\"Global Alerts Image\"/>\n                </a>\n			</div>\n			<div class=\"ga-property pull-left ga-property-address\">\n				<div class=\"ga-property-details pull-left\">\n					<div class=\"ga-address pull-left robotomedium\">\n                    <a href=\""
    + escapeExpression(((helper = (helper = helpers.listingUrl || (depth0 != null ? depth0.listingUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" title=\"Property address\">\n                            <span class=\"address\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetNumber : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetName : stack1), depth0))
    + ",</span> \n                            <span class=\"address\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.city : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.state : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.zip : stack1), depth0))
    + "</span>\n                        </a>\n					</div>\n					<div class=\"ga-bid-amount pull-left ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n					<label class=\"robotoregular hide-details\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.currentHighestBid : stack1), depth0))
    + "</label>\n						<span class=\"current-bid robotomedium hide-details\">$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedCurrentHighestBid : stack1), depth0))
    + "</span>\n						<div class=\"close-details hide\">\n                            <span class=\"glyphicon glyphicon-chevron-right hide-view-container\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" aria-hidden=\"true\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\"></span>\n                        </div>\n					</div>\n				</div>\n				<div class=\"pull-left ga-property-alerts robotomedium\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(7, data, depths),"inverse":this.program(9, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "				</div>\n\n				<div class=\"ga-cta pull-left ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n					<div class=\"ga-bid-plus-minus pull-left\" >\n						<span class=\"bid-minus glyphicon glyphicon-minus bid-minus-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\"></span>\n						<input type=\"text\" value=\"$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedNextpossibleBidAmount : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"15\" autocomplete=\"off\"  aria-label=\"bidAmount\" class=\"robotomedium ga-bid-amount-custom bid-val-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\">\n						<span class=\"bid-plus glyphicon glyphicon-plus bid-plus-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\"></span>\n					</div>\n					<div class=\"ga-button pull-left\">\n					<a href=\"javascript:void(0)\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.placeBidLabelTitleCase : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"ga-place-bid robotoregular place-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" >"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.placeBidLabelTitleCase : stack1), depth0))
    + "</a> \n					</div>\n					<div class=\"ga-view-details pull-left robotomedium hide-details\">\n                    <a href=\"javascript:void(0)\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.viewDetails : stack1), depth0))
    + "\"  class=\"ga-view-details-link \">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.viewDetails : stack1), depth0))
    + " </a><span class=\"hidden-xs glyphicon glyphicon-chevron-left ga-view-details-link\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" aria-hidden=\"true\"></span>\n                    </div>\n				</div>	\n				<div class=\"ga-error-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " ga-error-bid pull-left\"></div>\n\n			</div>\n\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.hubzuConfig : stack1)) != null ? stack1.enableOwnitnow : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(11, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n			<div class=\"confirm-bid-expand pull-left ga-cta\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n				<div class=\"close-action\">\n					<span class=\"glyphicon glyphicon-remove close-confirm-details\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" aria-hidden=\"true\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\"></span>\n				</div>\n				<div class=\"row gray-border-bottom confirm-bid-actions\">\n					<div class=\"col-md-6 col-sm-6 col-lg-6 col-xs-6 padding\">\n					<div class=\"bid-amount-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.bidAmountLabel : stack1), depth0))
    + "</div>\n					<div class=\"own-itnow-label hide\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.ownItNowAmountLabel : stack1), depth0))
    + "</div>\n					</div>\n					<div class=\"col-md-5 col-sm-5 col-lg-5 col-xs-6 padding\">\n						<div class=\"ga-bid-plus-minus pull-left normal-price\" >\n							<span class=\"confirm-bid-btn bid-minus glyphicon glyphicon-minus bid-minus-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\"></span>\n							<input type=\"text\" value=\"$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedNextpossibleBidAmount : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"15\"  aria-label=\"bidAmount\" autocomplete=\"off\" class=\"robotomedium ga-bid-amount-custom bid-val-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\">\n							<span class=\"confirm-bid-btn bid-plus glyphicon glyphicon-plus bid-plus-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\"></span>\n						</div>\n						<div class=\"ga-bid-plus-minus pull-left own-itnow-price hide\" >\n							<span class=\"robotomedium ownitnow-total ownitnow-amount-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedNextpossibleBidAmount : stack1), depth0))
    + "</span>\n						</div>	\n					</div>\n				</div>\n				<div class=\"row gray-border-bottom technology-fee-container\">\n				<div class=\"col-md-6 col-sm-6 col-lg-6 col-xs-6  padding\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.techFeeLable : stack1), depth0))
    + " <span class=\"ga-tech-percentage\"></span></div>\n					<div class=\"col-md-5 col-sm-5 col-lg-5 col-xs-5  padding ga-techfee-value\"></div>\n				</div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.noBuyersPremium : depth0), "N", {"name":"ifEqual","hash":{},"fn":this.program(15, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "				<div class=\"row total-amount\">\n				<div class=\"col-md-6 col-sm-6 col-lg-6 col-xs-6 padding \">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.totalDue : stack1), depth0))
    + "</div>\n					<div class=\"col-md-5 col-sm-5 col-lg-5 col-xs-5 padding ga-bid-totalDue\"></div>\n				</div>	\n				<div class=\"ga-error-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " ga-error-bid pull-left\"></div>\n			</div>\n\n			<div class=\"loader-inner-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " loader-icon\">\n";
  stack1 = this.invokePartial(partials['templates/globalAlerts/loaderIcon'], '				', 'templates/globalAlerts/loaderIcon', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "			</div>\n\n		</div>\n\n\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.autoBidExperimentEnable : depth0), "Y", {"name":"ifEqual","hash":{},"fn":this.program(17, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <div class=\"action-button pull-left new-confirm-edit\">\n			<button data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"ga-confirm-bid robotoregular confirm-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" >"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.confirmBidLabelTitleCase : stack1), depth0))
    + "</button> \n			<a href=\"javascript:void(0);\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.editDetails : stack1), depth0))
    + "\" class=\"ga-edit-bid-details\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.editDetails : stack1), depth0))
    + "</a>\n		</div>\n		\n\n\n		<div class=\"tuple-data-expand pull-left \">\n			\n			<div class=\"ga-bid-details robotoregular row\">\n			<div class=\"ga-bid-label col-lg-3 col-md-3 col-sm-3 col-xs-4\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.yourLastBid : stack1), depth0))
    + "</div>\n				<div class=\"ga-last-bid col-lg-4 col-md-4 col-sm-4 col-xs-3 robotomedium\">$"
    + escapeExpression(((helper = (helper = helpers.formattedLastBidAmount || (depth0 != null ? depth0.formattedLastBidAmount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"formattedLastBidAmount","hash":{},"data":data}) : helper)))
    + "</div>\n				<div class=\"gh-prop-tags reserve-met robotoregular col-lg-5 col-md-5 col-sm-5 col-xs-5 hide\">\n                    <span class=\"glyphicon glyphicon-ok-sign ga-tick-mark\" aria-hidden=\"true\"></span>\n                <span class=\"gh-reserve-met\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.reserveMetTag : stack1), depth0))
    + "</span>\n				</div>\n			</div>	\n			<div class=\"ga-bid-details robotoregular row\">\n			<div class=\"ga-bid-label col-lg-3 col-md-3 col-sm-3 col-xs-4\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.currentHighestBid : stack1), depth0))
    + "</div>\n				<div class=\"current-bid col-lg-4 col-md-4 col-sm-4 col-xs-3 robotomedium\">$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedCurrentHighestBid : stack1), depth0))
    + "</div>\n				<div class=\"gh-prop-tags robotoregular col-lg-5 col-md-5 col-sm-5 col-xs-5\">\n					<span class=\"gh-bid-count\">0</span>\n                <span>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.bids : stack1), depth0))
    + "</span> \n				</div>\n			</div>\n			<div class=\"ga-bid-attr-details robotoregular row\">\n				<div class=\"ga-time-left robotomedium col-lg-3 col-md-3 col-sm-3 col-xs-3\">\n					<span class=\"ga-time-left-counter\"></span>\n					<span class=\"ga-time-left-counter-desc\"></span>\n				</div>\n				\n				<div class=\"ga-attributes col-lg-3 col-md-3 col-sm-3 col-xs-3\">\n					<span class=\"address\">\n						<span class=\"bed-icon pdp-sprite\"></span>\n						"
    + escapeExpression(((helper = (helper = helpers.bedCount || (depth0 != null ? depth0.bedCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bedCount","hash":{},"data":data}) : helper)))
    + "\n					<span class=\"robotothin ga-attr-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.beds : stack1), depth0))
    + "</span>\n					</span> \n				</div>\n				<div class=\"ga-attributes col-lg-3 col-md-3 col-sm-3 col-xs-3\">\n					<span class=\"address\">\n						<span class=\"bath-icon pdp-sprite\"></span>\n						"
    + escapeExpression(((helper = (helper = helpers.bathCount || (depth0 != null ? depth0.bathCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bathCount","hash":{},"data":data}) : helper)))
    + "\n					<span class=\"robotothin ga-attr-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.baths : stack1), depth0))
    + "</span>\n					</span>\n				</div>\n				<div class=\"ga-attributes col-lg-3 col-md-3 col-sm-3 col-xs-3\">\n					<span class=\"address\">\n						<span class=\"size-icon pdp-sprite\"></span>\n						"
    + escapeExpression(((helper = (helper = helpers.areaSqurFeet || (depth0 != null ? depth0.areaSqurFeet : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"areaSqurFeet","hash":{},"data":data}) : helper)))
    + "\n					<span class=\"robotothin ga-attr-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.sqft : stack1), depth0))
    + "</span>\n					</span>\n				</div>\n				\n			</div>\n			\n		</div>\n\n		<form name=\"bid-form-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"bid-form-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" action=\""
    + escapeExpression(((helper = (helper = helpers.contextPath || (depth0 != null ? depth0.contextPath : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"contextPath","hash":{},"data":data}) : helper)))
    + "/portal/bid?ref=globalalerts\" method=\"post\">\n			<input type=\"hidden\" name=\"bidType\" value=\"PLACE_BID\" class=\"hidden-bid-form-bidtype-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n			<input type=\"hidden\" name=\"bidAmount\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.nextpossibleBidAmount : stack1), depth0))
    + "\" class=\"bidValue"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userType : depth0), "AGENT", {"name":"ifEqual","hash":{},"fn":this.program(19, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "			<input type=\"hidden\" name=\"listingId\" value=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" class=\"listingId"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n		</form>\n\n</li>\n";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/globalAlerts/alertTuple', t);
return t;
});
/* END_TEMPLATE */
;