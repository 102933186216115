
define('subscribeView',['require','jquery','backbone','handlebars','GoogleAnalyticsUtils','commonutils','hbs!tpl/subscribe/subscribeView','hbs!tpl/subscribe/subscribeViewNew','validator','loginRegisterView'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        //_ = require("underscore"),
        Backbone = require("backbone");

    var Handlebars = require("handlebars");
    var GAUtils = require("GoogleAnalyticsUtils");
    var Utils = require("commonutils");
    var subscribeTemplate  =  require("hbs!tpl/subscribe/subscribeView");
    var subscribeTemplateNew  =  require("hbs!tpl/subscribe/subscribeViewNew");
    var validator = require("validator");
    var loginRegisterView = require("loginRegisterView");
    var subscribeView = Backbone.View.extend({
        el : "body",
        events: {
            "click .hz-subscribe-btn" : "subscribeUser",
            "click .closeButtonPopover,#ajax-loader-mask"  : "closePopOver",
            "blur .hz-subscribe-email"  : "validateEmail",
            "click .login-opener"  : "openRegisterPopup",
            "click .heartListButton" : "toggleFavorite"
        },
        
        initialize: function () {
            var self = this;
            self.isMobile = false;
            self.ajaxReqRef = null;
            var windowWidth=$(window).width();
            if (windowWidth <= 768 ) {
                self.isMobile = true;
            }
        },
        /**
         * this.page view variable differentiate UI and page specific functionality 
         * UI layout for
         *       - SRP : Popover
         *      - PDP : Modal
         *      - HOME: Modal 
         */

        toggleFavorite: function(e){
            try {
                var self = this,
                    currentElement = $(e.currentTarget),
                    propertyId = currentElement.attr('rel'),
                    options ={};
                    options.propertyId = propertyId;
                    options.currentObj = $(currentElement);
                    self.render(options);   
            } catch(error) {
                GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:toggleFavorite|" + error, null, 1);
            }
        },
        pageView: function(){  
            if (typeof golbalSRPView !== "undefined") {
                return "srp";
            } else if (window.location.href.indexOf("/property") > -1) {
                return "pdp";
            } else {
                return "home";
            }
        },
        openRegisterPopup : function(e){

            var self = this,
                eventLabel = $.cookie('visitorId') || '',
                gaData = eventLabel + " | referrer: QuickRegister";
 
            self.closePopOver();

            GAUtils.eventTracking.trackevent("","Sign In Click", gaData);

            //Show login popup
            loginRegisterView.openLoginPopup({
                callback: self.addToFavoriteAfterLogin,
                viewObject: {
                    viewObject: self,
                    options: {
                        propertyId: self.propertyId,
                        currentObj: self.popOverObject
                    },
                } 
            });
        },
        closePopOver:function(e){

            try {
                $('#commonConfirmPopup').modal('hide');
                $(".popover").removeClass('in');
                $('#ajax-loader-mask').hide();
                $('body').removeClass('no-scroll'); 

                $(".savePopover").remove();

                // $(this.popOverObject).popover('hide');
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:closePopOver|" + error, null, 1);       }
            
            
        },
        validateEmail:function(event){
            try{
                var obj = '#' + event.target.id;
                var errorMessage = saveListDrawerContent.errorMessages.emailIsRequired;
                $(obj).removeClass('error').removeClass('filled');
                if($(obj).val() === ''){
                    $('.'+$(obj).attr('error')).html(errorMessage);
                    $.trim($(obj).val($.trim($(obj).val()).toLowerCase()));
                    $(obj).addClass('error');
                }
                else if(!(validator.isEmail($.trim($(obj).val()) , obj))){
                    errorMessage = saveListDrawerContent.errorMessages.isValidEmail;
                    $('.'+$(obj).attr('error')).html(errorMessage);
                    $(obj).addClass('error').addClass('filled');
                }else{
                    $(obj).removeClass('error');
                    $(obj).addClass('filled');
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:validateEmail|" + e, null, 1);
                     }
        },
        addActiveClassToTextBox:function(e){
			try{
				var targetItem = $(e.target);
				targetItem.addClass("filled");
			}catch(e){
				GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:addActiveClassToTextBox|" + e, null, 1);			}
        },

        removeActiveClassToTextBox:function(e){
			try{ 
				var targetItem = $(e.target);
				if(targetItem.val() != ''){
					targetItem.removeClass("filled").addClass("filled");
				}else{
					targetItem.removeClass("filled");
				}
			}catch(e){
                GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:removeActiveClassToTextBox|" + e, null, 1);        }        
        },
        
        /**
         *  Function: addToFavorite()  - Show Subscribe popup when click on favourites 
         * @param {*} options 
         */
        addToFavorite: function(options){
            
            options = options || {};
            
            try{

                var self = options.viewObject || this,
                    propertyId = options.propertyId || '',
                    path = (typeof contextPath !== 'undefined') ? contextPath : '',
                    userId = (typeof userSession !== 'undefined') ? (userSession && userSession.userId || 'NA' ): 'NA',
                    currentElement  = options.currentObj,
                    data = {},
                    gaLabel = "Property Id : " + propertyId + "|userId : "+userId + " | visitorId: " + $.cookie("visitorId");
            
                if ( window.location.pathname.indexOf('/property') > -1 ) {
                    if (typeof listingId !== 'undefined' && listingId) {
                        gaLabel += " | listingId: " + listingId
                    }
                    if (typeof propStatus !== 'undefined' && propStatus) {
                        gaLabel += " | propertyStatus: " + propStatus
                    }
                } else {
                    var listingId = currentElement.attr('relListingId') ||  '';
                    if (typeof listingId !== 'undefined' && listingId != "") {
                        gaLabel += " | listingId: " + listingId;    
                    }
                    var propertyStatus = currentElement.attr('relPropertyStatus') ||  '';
                    if (typeof propertyStatus !== 'undefined' && propertyStatus != "") {
                        gaLabel += " | propertyStatus: " + propertyStatus;    
                    }
                }
                
                GAUtils.eventTracking.trackevent("","Watch - Click Save", gaLabel);
                
                $.post(contextPath + '/portal/watchProperty/'+propertyId+'?notifyType=DAILY', function(response){

                    data = {'content':saveListDrawerContent.saveListDrawer.labels.saveConfirmation, 'className' : 'saveConfirm'};

                    currentElement.removeClass("N").addClass('Y').attr('isWatched','Y');
                
                    // Update save list drawer
                    golbalSaveListDrawerView.getWatchList();
                    golbalSaveListDrawerView.loadCount();
                    golbalSaveListDrawerView.showConfirmation(data);
                    
                    // SRP - Update Map Icon
                    if(self.pageView() === 'srp'){
                        golbalSRPView.mapView.updateWatchIcon({propertyId: propertyId, isSaved: true});
                        golbalSRPView.mapView.getUserSavedProperties({});
                        $(".watch-icon-id-" + propertyId).addClass("Y").attr('isWatched','Y');
                    }

                    // PDP - update icons 
                    if(self.pageView() === "pdp"){
                        
                        // $('.heart-icon-img').attr("src",  path + "/www/images/pdpSavedIcon.png").attr('alt','Watch - Saved').attr('isWatched','Y');
                        // $('.header-icon-dark-img').attr('src',path+'/www/images/pdpSavedIcon.png');

                        // For preview property
                        $('.previewPropertyWatch span').html($('#watchPropPreview').attr('unsavetxt'));
                        $('.previewPropertyWatch').addClass('disabled');
                        $('.previewPropertyWatch').attr('isWatched','Y');

                        // $(".heartListBtn").attr('isWatched','Y');
                        // $('.watch-icon').attr('isWatched','Y');
                        // $("#iframePopupPreLoaderImg").hide();

                        // update status in bid center
                        $(".pdp-save-icon").addClass('Y').attr('isWatched', 'Y');

                        // update status in gallary lightbox 
                        $(".lb-save-search").addClass('saved').attr('isWatched', 'Y');
                            
                    }

                    if ($(".ha-prop-" + propertyId + " .ha-favorite-btn").length)
                        $(".ha-prop-" + propertyId + " .ha-favorite-btn").addClass("Y");
     
                });
             
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:addToFavorite|" + e, null, 1);            }
        },

        /**
         *  Delete from favourite list
         * @param {*} options 
        */

        deleteFavorite: function(options){
            options = options || {};
            try{

                var self = options.viewObject || this,
                    propertyId = options.propertyId || '',
                    path = (typeof contextPath !== 'undefined') ? contextPath : '',
                    userId = (typeof userSession !== 'undefined') ? (userSession && userSession.userId || '' ): '',
                    currentElement  = options.currentObj,
                    gaLabel = "Property Id : " + propertyId + "|userId : "+userId + " | visitorId: " + $.cookie("visitorId");
            
                if ( window.location.pathname.indexOf('/property') > -1 ) {
                    if (typeof listingId !== 'undefined' && listingId) {
                        gaLabel += " | listingId: " + listingId
                    }
                    if (typeof propStatus !== 'undefined' && propStatus) {
                        gaLabel += " | propertyStatus: " + propStatus
                    }
                } else {
                    var listingId = currentElement.attr('relListingId') ||  '';
                    if (typeof listingId !== 'undefined' && listingId != "") {
                        gaLabel += " | listingId: " + listingId;    
                    }
                    var propertyStatus = currentElement.attr('relPropertyStatus') ||  '';
                    if (typeof propertyStatus !== 'undefined' && propertyStatus != "") {
                        gaLabel += " | propertyStatus: " + propertyStatus;    
                    }
                }
                
                GAUtils.eventTracking.trackevent("","Watch - Click UnSave", gaLabel);
               
                $.ajax({
                    url: path + '/portal/deleteWatch',
                    dataType: "json",
                    type: 'POST',
                    data: JSON.stringify({"propertyId":propertyId}),
                    contentType: "application/json; charset=utf-8",
                    success: function (responseData) {
                        currentElement.removeClass("Y").addClass('N').attr('isWatched','N');
                       
                        //To reset the favourite drawers watch list and notification count
                        golbalSaveListDrawerView.getWatchList();
                        golbalSaveListDrawerView.loadCount();
                    

                        if(self.pageView() === 'srp'){
                            // Update Map Icon
                            golbalSRPView.mapView.updateWatchIcon({propertyId: propertyId, isSaved: false});
                            // Update properties
                            golbalSRPView.mapView.getUserSavedProperties({});
                            $(".watch-icon-id-" + propertyId).removeClass("Y").attr('isWatched','N');
                        }


                        if(self.pageView() === 'pdp'){

                            // For preview property
                            $('.previewPropertyWatch span').html($('#watchPropPreview').attr('savetxt'));
                            $('.previewPropertyWatch').removeClass('disabled');
                            $('.previewPropertyWatch').attr('isWatched','N');
                            
                            // update status in bid center
                            $(".pdp-save-icon").removeClass('Y').removeClass('saved').attr('isWatched', 'N');

                            // update status in gallary lightbox 
                            $(".lb-save-search").removeClass('saved').attr('isWatched', 'N');
                        }

                        if ( typeof window.htimestamp !== "undefined" && window.htimestamp.outbid ) {
                            window.htimestamp.outbid.removeProperty({
                                propertyId: propertyId
                            })
                        }

                    },
                    error: function () {
                        GAUtils.eventTracking.trackevent("Error", "Subscribe Popup",  "file:subscribeView.js|function:deleteFavorite - Ajax Error: ", null, 1);                    }

                });
                
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:deleteFavorite|" + e, null, 1);           }        
        },


        /**
         * subscribeUser: Add the given email id id the registration form
         * @param {*} options 
         */

        subscribeUser: function(){
            try {
               
                var self = this,
                    fieldId = 'hz-subscribe-email',
                    errorElement = 'errorSaveEmail',
                    emailId =  $('#'+fieldId).val() || "",
                    path = (typeof contextPath !== 'undefined') ? contextPath : '',
                    currentElement = self.popOverObject || null,
                    propertyId = self.propertyId || '',
                    options = {},
                    eventLabel = $.cookie('visitorId') || '',
                    gaData = eventLabel + " | referrer: QuickRegister";
                                
                $('#'+fieldId).blur();

                if ($('#'+fieldId).hasClass('error')) {
                    $('#'+fieldId).focus();
                    return false;
                }

                // Existing register event 
                GAUtils.eventTracking.trackevent("","Quick Registration Click",gaData);


                $.ajax({
                    url: path + '/portal/lean-regn/subscribeUser',
                    type: 'POST',
                    data: {"emailId":emailId},
                    success: function (response) {
                        
                        if(response.status == 'SUCCESS') {
                            
                            options.propertyId = propertyId;
                            options.currentObj = $(currentElement);
                            options.viewObject = self;

                            self.closePopOver();
                            $(self.popOverObject).popover('destroy');

                            
                            if ( response.data === "USER_CREATION_SUCCESS" ) {

                                
                                // Existing register event 
                                GAUtils.eventTracking.trackevent("","Register Completed",gaData +" | Source: Direct");

                                // Quick register event 
                                GAUtils.eventTracking.trackevent("","quick-register", gaData);

                                Utils.setCookieDetails('subscribeEmail', window.btoa(emailId), 365);
                                $('.logged-in').show();
                                $('.logged-out').hide();
                               /// self.favOptions = options;
                                self.addToFavorite(options);
                                Utils.openSubscribePopupLink();
                            } else {

                                if(response.data == 'SUBSCRIBER') {

                                    Utils.setCookieDetails('subscribeEmail', window.btoa(emailId), 365);
                                    self.addToFavorite(options);
                                    Utils.setCookieDetails('loggedInEmail', '', '-1');

                                    
                                    //Show login popup
                                    loginRegisterView.openLoginPoqpup({
                                        openRegistration: 'Y',
                                        viewObject: {
                                            viewObject: self,
                                            options: options,
                                         },
                                        statusMessage: saveListDrawerContent.subscribeContent.warningMessage
                                    });
    
                                } else {

                                    // this will pre populate emailin in login popup
                                    Utils.setCookieDetails('loggedInEmail', emailId);
        
                                    //Show login popup
                                    loginRegisterView.openLoginPopup({
                                        callback: self.addToFavoriteAfterLogin,
                                        viewObject: {
                                            viewObject: self,
                                            options: options,
                                        },
                                        statusMessage: saveListDrawerContent.subscribeContent.warningMessage
                                    });   
                                }

                            }

                        } else if(response.status == 'FAILURE') {

                            if(response.errorMessage == 'EMPTY_EMAIL' || response.errorMessage == 'INVALID_EMAIL_ID' || response.errorMessage == 'EMAIL_WITH_FAKE_DOMAIN') {
                                $('#'+fieldId).addClass("error").addClass('filled');;
                                $("." + errorElement).html("Please enter valid Email Id");
                            } else {
                                $("." + errorElement).html("Technical error. Please try again later");
                                $('#'+fieldId).addClass("error").addClass('filled');;
                            }
                        }
                    },
                    error: function () {
                        GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:subscribeUser - Ajax Error: ", null, 1);                    }
                });

            } catch(e) {
                    GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:subscribeUser|" + e, null, 1);            }
        }, 
        showToolTIp : function(){

        },
        addToFavoriteAfterLogin: function(user, callback) {
            try {
                
                var self = callback.viewObject || {};

                self.addToFavorite(callback.options);
                if(typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y' && !(globalAlertView.submitFromAlertView)){
                    var options = {};
                    mobileVerificationView.checkMobileVerificationRequired(options);
                    
                }
               
                $("#ajax-loader").hide();
			    $("#ajax-loader-mask").hide();

            } catch (error) {
             GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:addToFavoriteAfterLogin|" + error, null, 1);         }        
        },

        /**
         * highlightSavedProperties() - Highlight saved properties for collections
         * @param {*} options 
         *          - propertyIds
         */
        highlightSavedProperties : function(options){
			try{
				options = options || {};
			    var self = this, 
                    propIdData = {"propertyIds":options.propIdJson},
                    path = (typeof contextPath !== 'undefined') ? contextPath : '';
                
                $.ajax({
                    url: path+'/portal/getPropertyWatchStatus',
                    dataType: "json",
                    type:'POST',
                    data:JSON.stringify(propIdData),
                    contentType: "application/json; charset=utf-8"
                }).done(function(data) {
                	if(data.propertyStatusList) {
	                	$.each(data.propertyStatusList,function( index, value ) {
						  	$( ".heartButtonStyle[rel='"+value+"']" ).addClass('Y').attr('iswatched','Y').attr('notifyflag','notify');
						});
	                }
                });
            
			}catch(e){
			GAUtils.eventTracking.trackevent("Error", "Subscribe Popup", "file:subscribeView.js|function:highlightSavedProperties|" + e, null, 1);            }
        },

        /**
         *  render: Render the subcribe user template when click on favorite icon
         * @param {*} options 
         */
      
        render: function (options) {
            options = options || {};
            try{
                var self = this,
                    targetElement = options.currentObj || '',
                    isSubscribe = window.atob(Utils.getCookieDetails("subscribeEmail") || ''),
                    subscribeData = {},
                    cmsContent = saveListDrawerContent,
                    body = $("html, body"), offset, top,
                    isSubscribeFromMap = options.isSubscribeFromMap || false,
                    isMapVisible = $(".map-display-viewport").is(":visible") || false,
                    watchStatus;
                    
                watchStatus = targetElement.attr('isWatched');

                if (isSubscribeFromMap) {
                    watchStatus = options.isWatched ? 'Y' : 'N';
                }

                self.popOverObject = targetElement;
                self.propertyId = options.propertyId || targetElement.attr('rel') ||  '';

                //Toggle the favourite selection
                if(watchStatus === "Y"){
                    self.deleteFavorite(options);
                } else {

                    //Load the subscribeView incase the user is not logged in
                    if( (window.userSession && window.userSession.userId !== null) || ( isSubscribe != '' && isSubscribe !== null) ){
                        self.addToFavorite(options);
                    } else {

                        subscribeData.content = cmsContent.subscribeContent;

                        // Fore mobile open popup
                        // if(self.isMobile || isSubscribeFromMap || isMapVisible || targetElement.hasClass('lb-save-search')){
                        if(self.isMobile || targetElement.hasClass('lb-save-search')){
                            
                            // close lightbox before opoening popup if already opened.
                            if (targetElement.hasClass('lb-save-search')) $('#lightbox, .pdp-gallery-lightbox').modal('hide'); 
                            if(newSkinning){
                                $('.commonConfirmPopupContent').html(subscribeTemplateNew(subscribeData));
                            }else{
                                 $('.commonConfirmPopupContent').html(subscribeTemplate(subscribeData));   
                            }
                            
                            $('#commonConfirmPopup').modal('show');

                            return;
                        } 

                        if(self.pageView() === 'srp' || self.pageView() === 'pdp' || self.pageView() === 'home'){
                            
                            // Close popups if any
                            targetElement.popover('destroy');

                            // $("body").css("position", "relative");
                            if(newSkinning){
                                var customContent = subscribeTemplateNew(subscribeData);    
                            }else{
                                var customContent = subscribeTemplate(subscribeData);
                            }
                            
                            targetElement.popover({ 
                                html : true,
                                trigger: 'manual',
                                placement : 'auto bottom',
                                container: 'body',
                                content: customContent
                            }).on('shown.bs.popover', function () {
 
                                $('#ajax-loader-mask').show();
                                $('body').addClass('no-scroll');

                            }).on('hide.bs.popover',function(){
                                $('#ajax-loader-mask').hide();
                                $('body').removeClass('no-scroll'); 
                            }).map(function(){
                                $(this).data('bs.popover')
                                .tip()
                                .addClass('savePopover')
                            });
                            
                            // $('.popover').not(this).hide(); /* optional, hide other popovers */
                            targetElement.popover('show'); /* show popover now it's setup */

                        } else {
                            if(newSkinning){
                                $('.commonConfirmPopupContent').html(subscribeTemplateNew(subscribeData));
                            }else{
                                $('.commonConfirmPopupContent').html(subscribeTemplate(subscribeData));
                            }
                            $('#commonConfirmPopup').modal('show');
                        }
                    }
                }
                
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "Subscribe Search Popup", "file:subscribeView.js|function:render|" + e, null, 1);           }         
        }
    }); 
    return new subscribeView();
}); 