
/* START_TEMPLATE */
define('hbs!tpl/globalIdologyVerification/verifyIdologyQuesParent',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "  <div class=\"modal-dialog modal-login\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <h4 class=\"modal-title\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.idologyVerificationQuesHead : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + " </h4>\n        <div class=\"closeButton\" data-dismiss=\"modal\">&nbsp;</div>\n      </div>\n      \n      \n        <div class=\"modal-body\" id=\"verifyQuesPopup\">\n      <!-- Progress Tracker v2 -->\n    <!--  <ol class=\"idologyProgress\" data-steps=\"4\">\n        <li class=\"active step0 stepParent\">\n          <span class=\"step\" attr=\"step1\"><span class=\"spanContent\">1</span><span class=\"spanContentDone hide\">&nbsp;</span></span>\n        </li>\n        <li class=\"done step1 stepParent\" attr=\"step2\">\n          <span class=\"step\"><span class=\"spanContent\">2</span><span class=\"spanContentDone hide\">&nbsp;</span></span>\n        </li>\n        <li class=\" step2 stepParent\">\n          <span class=\"step\" attr=\"step3\"><span class=\"spanContent\">3</span><span class=\"spanContentDone hide\">&nbsp;</span></span>\n        </li>\n        <li class=\"step3 stepParent\">\n          <span class=\"step\" attr=\"step4\"><span class=\"spanContent\">4</span><span class=\"spanContentDone hide\">&nbsp;</span></span>\n        </li>\n      </ol>-->\n      \n        </div>\n       </div>     \n    </div>\n  </div>\n";
},"useData":true});
Handlebars.registerPartial('tpl/globalIdologyVerification/verifyIdologyQuesParent', t);
return t;
});
/* END_TEMPLATE */
;