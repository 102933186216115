
/* START_TEMPLATE */
define('hbs!tpl/pdpBidDeposit',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "\n    \n    <div class=\"bid-deposit-tooltip text-center robotomedium\">";
  stack1 = ((helper = (helper = helpers.content || (depth0 != null ? depth0.content : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"content","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        <button class=\"btn btn-default tooltipPop badge robotolight tooltipClick\" type=\"button\">i</button>\n        <div class=\"tooltipPopdesc robotolight\">\n            <div class=\"uparrow\"></div>\n            <div class=\"popcontent \">";
  stack1 = ((helper = (helper = helpers.tooltip || (depth0 != null ? depth0.tooltip : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tooltip","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n        </div>\n    </div>\n";
},"useData":true});
Handlebars.registerPartial('tpl/pdpBidDeposit', t);
return t;
});
/* END_TEMPLATE */
;