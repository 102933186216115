
define('HSearchView',['require','jquery','backbone','GoogleAnalyticsUtils','commonutils'],function (require) {
    "use strict";

    var $ = require("jquery"),
        Backbone = require("backbone"),
        GAUtils = require("GoogleAnalyticsUtils"),
        Utils = require("commonutils"),
        SearchView;

    SearchView = Backbone.View.extend({
        events: {
            'click #search-btn,#search-button,#currSearch, .common-search-btn': 'handleClickSearchBtn',
            'click .search-btn-async': 'handleClickSearchBtn',
            'click .close-mask': 'closeSearch',
            'keypress .searchInput, #search-input': 'handleKeyboardSearch',
            'click': 'handleBodyClick',
            'keyup': 'handleEscape',
            'focus  .searchInput, #search-input': 'handleInputChangeOnFocus',
            'blur  .searchInput, #search-input' : 'handleInputChangeOnBlur',
            'click .promo-link' : 'redirectToPromotionalPage',
            'click .recentSearchLink': 'redirectToRecentSearchPage',
            'click .savedSearchLink': 'redirectToSavedSearchPage',
            'click .search-mobile-close' : 'closeSearchPopup',
			'click #sell-btn' : 'sellButtonTapped'
        },
        initialize: function () {
            //some initialization scripts
            
            var thisObj = this;
            thisObj.enableAutoSuggest = false;
            thisObj.ajaxReqRef = null;
            thisObj.ajaxReqGeoLoc = null;
            thisObj.focusFirstTime = false;
            thisObj.isMobile = $(window).width() < 768 ? true : false;
            thisObj.suggestionsIconSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40"><g fill="none" fill-rule="evenodd"><rect width="40" height="40" fill="#E2EAF2" rx="8"/><g transform="translate(10 11)"><path stroke="#2A69A3" stroke-width="1.5" d="M6 0h8c.552 0 1 .448 1 1v18h0H5V1c0-.552.448-1 1-1zM15 8h4c.552 0 1 .448 1 1v9c0 .552-.448 1-1 1h-4 0V8zM1 6h4v13H1c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1z"/><rect width="5" height="1.5" x="7.5" y="3" fill="#2A69A3" rx=".75"/><rect width="5" height="1.5" x="7.5" y="7" fill="#2A69A3" rx=".75"/><rect width="5" height="1.5" x="7.5" y="11" fill="#2A69A3" rx=".75"/><rect width="5" height="1.5" x="7.5" y="15" fill="#2A69A3" rx=".75"/></g></g></svg>';
            thisObj.suggestionsSavedIconSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill="#F25D49" fill-rule="evenodd" d="M12.696 1.8c-1.236 0-2.333.513-3.284 1.35l-.223.205-.187.187-.183-.184c-.072-.07-.146-.138-.222-.205C7.653 2.314 6.57 1.8 5.347 1.8 2.375 1.8 0 4.112 0 6.97c0 2.885 1.669 5.388 4.397 7.514.929.724 1.92 1.353 2.91 1.887.26.14.508.267.738.379l.414.195c.083.038.148.066.193.085l.33.138.336-.126c.281-.106.753-.31 1.353-.612.995-.502 1.99-1.105 2.923-1.812C16.328 12.545 18 10 18 6.969 18 4.106 15.664 1.8 12.696 1.8zm0 1.8c1.98 0 3.504 1.505 3.504 3.37 0 2.371-1.365 4.448-3.694 6.214-.839.636-1.742 1.183-2.646 1.639l-.232.115c-.152.073-.297.141-.435.204l-.178.077.02.01c-.262-.124-.556-.272-.874-.443-.907-.489-1.815-1.065-2.658-1.722C3.17 11.245 1.8 9.192 1.8 6.969 1.8 5.117 3.358 3.6 5.348 3.6c.72 0 1.411.328 2.054.898.151.135.292.276.423.421l.19.22c.098.121.29.38.303.397l.68.787.681-.785c.016-.017.208-.277.306-.396.183-.223.388-.44.617-.64.65-.573 1.353-.902 2.094-.902z"/></svg>';
            thisObj.suggestionsRecentIconSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill="#2A69A3" fill-rule="evenodd" d="M9 0c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 1.8C5.024 1.8 1.8 5.024 1.8 9s3.224 7.2 7.2 7.2 7.2-3.224 7.2-7.2S12.976 1.8 9 1.8zm.9 2.611c.462 0 .842.348.894.795l.006.105V10.8H5.4c-.497 0-.9-.403-.9-.9 0-.462.347-.842.795-.894L5.4 9H9V5.311c0-.461.347-.842.795-.894l.105-.006z"/></svg>';
            thisObj.saveSearchResponseList = {};
            thisObj.defaultPromoSectionHtml = $('.promotion-section').html();

            if(thisObj.isMobile){
                $(".searchInput").attr('value', $(".searchInput").attr('m-value'));
                $(".searchInput").attr('default', $(".searchInput").attr('m-default'));
                $(".error-box").attr('data-error', $(".error-box").attr('data-errormob'));
            }

            $(document).on("click", ".searchInput", function(evt){
                
                var target = evt.currentTarget,
                    value = $(target).val(),
                    selectedList = $(target).attr("google-list-id");

                if(thisObj.isMobile){
                    if(selectedList == 'googleAPI_result_2'){
                        $("html, body").animate({ scrollTop: 150 }, "slow");
                    }
                }
                
                var target = evt.currentTarget;
                var selectedList = $(target).attr("google-list-id");
                if(currentLocationSearchEnabled){
                    var labelTxt = currentLocationLabelTxt;
                    var targetParent = "<li class='search suggestList current-location' value='"+labelTxt+"'><a value='currentLocation' id='near-me' type='currentLocationSearch' class='suggestionURL' href='javascript:void(0);'><span class='current-location-icon'></span><span class='suggest-text'><span class='suggest-sub-text'>Use Current Location</span></span></a></li>";
                    $('#' + selectedList).html("<ul>"+targetParent+"</ul>");
                    $(".googleAPI_result").show();
                }
                
                if(value.length >= 1 && thisObj.enableAutoSuggest){
                    $("#"+selectedList).show();
                    // $(target).css("border-bottom-left-radius","0px");
                    $(target).removeClass("search-curve-radius").addClass("search-result-curve-radius");
                }else{
                   thisObj.showRecentAndSavedSearchSuggestion(evt);
                    // $(target).css("border-bottom-left-radius",$(target).css("border-top-left-radius"));
                    $(target).removeClass("search-result-curve-radius").addClass("search-curve-radius");
                }
            });
            
            $(document).on("click", ".suggestionURL", function(evt){
                var rel = $(this).attr('rel'),
                    gatLabel = "visitorId: " + $.cookie('visitorId') + " | UID : " + $.cookie('userId');

                $(".searchInput").val(decodeURIComponent(escape($(this).attr("value"))));
                gatLabel += " | Search term: " + $(".searchInput").val();

                if(rel == 'SavedAuto' || rel == 'RecentAuto'){
                    var responseURL = $(this).attr('id');
                    gatLabel += " | type: " + (rel === 'SavedAuto' ? "Saved search" : "Recent search");
                    window.location =  responseURL;
                }else{
                    gatLabel += " | type: Search"; 
                    thisObj.formSuggestionURL(this.id, this.type, rel);
                }
                if(this.type != 'currentLocationSearch')
                    GAUtils.eventTracking.trackevent(null, 'Search result click', gatLabel);
            });
            
            $(document).on("keypress", ".searchInput", function(e){
                 return thisObj.validateSearchInput(e, "01234567890abcdefghijklmnopqrstuvwxyz &.'$,;-/+.(){}[]>");
            });
            (function(){

                $(document).on( "keydown",".searchInput", function( event ) {
                   
                    var current,selected,
                        target = (event.currentTarget),
                        selectedList = $(target).attr("google-list-id");
                         var listItems = $('#'+selectedList+' .suggestList')
                        //listItems = $(target).parent().find(".googleAPI_result").find(".suggestList");
                        
                    if(event.which==40) {//down
                        
                        selected = listItems.siblings('.selected');
                        selected.removeClass('selected');
                        if(!selected.length || selected.is(':last') || selected.next().is(':last')) {
                            current = listItems.first();
                        } else {
                            if(selected.next().length > 0) {
                                if(selected.next().hasClass("suggestList")){
                                    current = selected.next();
                                }else{
                                    current = selected.next().next();
                                }
                            } else {
                                current = listItems.first();
                            }
                        }
                        $(target).val($.trim(decodeURIComponent(escape($(current).attr("value")))));
                        
                        $(current).children().focus();
                        $(current).focus();
                        $(target).focus();
                        
                    } else if(event.which==38) {//up
                        
                        selected = listItems.siblings('.selected');
                        selected.removeClass('selected');
                        if(!selected.length || selected.is(':first') || selected.prev().is(':first')) {
                            current = listItems.last();
                        } else {
                            if(selected.prev().length > 0) {
                                if(selected.prev().hasClass("suggestList")){
                                    current = selected.prev();
                                }else{
                                    current = selected.prev().prev();
                                }
                            } else {
                                current = listItems.last();
                            }
                        }
                        $(target).val($.trim(decodeURIComponent(escape($(current).attr("value")))));
                        $(current).children().focus();
                        $(current).focus();
                        $(target).focus();
                        
                    } else if(event.which == 8) {
                        var value = $(target).val();
                        if (value.length <= 2){
                            $(".googleAPI_result").hide();
                            $(".homeSearchInput").removeClass("search-result-curve-radius").addClass("search-curve-radius");
                            $('.error-box').hide();
                        }
                    }
                    
                    if(current) {
                        $(target).val($.trim(decodeURIComponent(escape(current.addClass('selected').attr("value")))));
                        
                    }
                    
                });
                thisObj.autoSuggestion();
            })($);
            $(window).click(function(e){
                if(!$(e.target).hasClass('searchInput')){
                    $('.googleAPI_result').hide();
                }
                if(!$(e.target).parents('.input-group-btn').length){
                    $('.error-box').hide();
                }
            });
        },

        closeSearchPopup: function(){
            $(".search-overlay-small").removeClass("show");
            $(".search-wrapper").removeClass("active");
            $("body").removeClass('modal-open');
            GAUtils.eventTracking.trackevent( null, 'Search Popup Close', 'Mobile Cancel Search clicked');
        },
        sellButtonTapped: function(){ 
            var gatLabel = "visitorId: " + $.cookie('visitorId') + " | UID : " + $.cookie('userId');
            GAUtils.eventTracking.trackevent(null,'Header - Sell Click',gatLabel);
            if($('#welcome-drop-down a[hashname="smallSellerDashboard"]').length){
                window.location = encodeURI(contextPath + $('.sellaHomeRedirect').attr('hrefurllogin'));
            }else{
                window.location = encodeURI(contextPath + $('.sellaHomeRedirect').attr('hrefurllogout'));
            }
        },

        handleBodyClick: function(e){
            if(!$(e.target).hasClass('searchInput')){
                $('.googleAPI_result').hide();
            }
            if(!$(e.target).parents('.input-group-btn').length){
                $('.error-box').hide();
            }
        },
        handleEscape: function(e){
            if (e.keyCode == 27){
                $('.googleAPI_result,.error-box,.didYouMean-search-mask').hide();
                $(".homeSearchInput").removeClass("search-result-curve-radius").addClass("search-curve-radius");
            }
        },
        render: function () {
            return this;
        },
        checkIfiOS8:function () { 
            if((/iphone|ipod|ipad/gi).test(navigator.platform)) { 
                var versionString = navigator.appVersion.substr(navigator.appVersion.indexOf(' OS ')+4); 
                versionString = versionString.substr(0, versionString.indexOf(' ')); 
                versionString = versionString.replace(/_/g, '.').substr(0, 1); 

                return versionString;
            } 
            return 0; 
        },
        autoSuggestion: function (){
            var thisObj = this;
            $(".googleAPI_result").hide();

            (function(){
                if(parseInt(thisObj.checkIfiOS8())>=9){
                    var keyEvent = "keyup input";
                }else{
                    var keyEvent = "keyup";
                }
                $(document).on(keyEvent,".searchInput",function(e){
                    if (e.keyCode !== 37 && e.keyCode !== 38 && e.keyCode !== 39 && e.keyCode !== 40 && e.keyCode !== 13 && e.keyCode != 27 && e.keyCode != 17 && e.keyCode != 9 && e.keyCode != 16) {
                        var target = e.currentTarget;
                        var value = $(target).val();
                        value = value.trim();

                        if(thisObj.ajaxReqRef != null) thisObj.ajaxReqRef.abort();
                        thisObj.enableAutoSuggest = false;

                        var selectedList = $(target).attr("google-list-id");
                        
                       
                        var listItems =  $('#' + selectedList+ ' .suggestList:first').children().focus();
                        var labelTxt = currentLocationLabelTxt;
                        var targetParent = "<li class='search suggestList current-location' value='"+labelTxt+"'><a value='currentLocation' id='near-me' type='currentLocationSearch' class='suggestionURL' href='javascript:void(0);'><span class='current-location-icon'></span><span class='suggest-text'><span class='suggest-sub-text'>Use Current Location</span></span></a></li>";
                        $(target).focus();
                        $('.error-box').hide();
                            
                        var url = contextPath + '/portal/v2.0/didyoumean?query='+encodeURIComponent(decodeURIComponent(value));
                            thisObj.ajaxReqRef = $.getJSON(url, null , function(response){
                                
                                        var str = "";
                                        
                                        var res = response.suggestions || {};
                                        
                                        var flag = true;
                                        
                                        var outObj = {};
                                        var outObjRecent = {};
                                        var outObjSaved = {};
                                        var isRecentSearchAvailable = false;

                                        for(var ikey in res){
                                            if(ikey == 'RECENT_SEARCH'){
                                                outObjRecent =  res["RECENT_SEARCH"]["RECENT_SEARCH"]; 
                                                isRecentSearchAvailable = true;
                                            }else if(ikey == 'SAVE_SEARCH'){
                                                outObjSaved =  res["SAVE_SEARCH"]["SAVE_SEARCH"];    
                                            }else{
                                                var innerObj = res[ikey];
                                                outObj[ikey] = [];
                                                for(var iikey in innerObj){
                                                    var innerInnerObj = innerObj[iikey];
                                                    outObj[ikey] = outObj[ikey].concat(innerInnerObj);
                                                }   
                                            }
                                        }
                                        var count = 0;
                                        if(currentLocationSearchEnabled){
                                            var list = targetParent;
                                        } else {
                                            var list = '';
                                        }
                                        
                                        var maxcountRecent = parseInt($('.h-search-container').attr("data-recentsearchsuggestion"));
                                        var countRecent = 0;
                                        var maxcountSaved = parseInt($('.h-search-container').attr("data-savesearchsuggestion"));
                                        var countSaved = 0;
                                        if(!isRecentSearchAvailable){
                                            var maxcountRecent = parseInt($('.h-search-container').attr("data-recentsearchsuggestion"));
                                            var countRecent = 0;
                                            var recentSearch = localStorage.getItem("RecentSearchList");
                                            recentSearch = JSON.parse(recentSearch);
                                            if(recentSearch!=null && recentSearch!=undefined && recentSearch!='' && recentSearch.cookieElements!=null && recentSearch.cookieElements!=undefined && recentSearch.cookieElements!=''){
                                                thisObj.getValidRecents(recentSearch);
                                                $.each(recentSearch.cookieElements, function(index, obj){
                                                    var lowerCaseVal = obj.value.toLowerCase();
                                                    var lowerCaseEnteredVal = value.toLowerCase();
                                                    if(lowerCaseVal.indexOf(lowerCaseEnteredVal) > -1 && countRecent < maxcountRecent){
                                                        if(obj.searchURI[0] != '/'){
                                                            obj.searchURI = "/"+obj.searchURI;
                                                        }
                                                        list += "<li class='search suggestList' value='"+obj.value+"'><a rel='RecentAuto' value='"+obj.value+"' id="+obj.searchURI+" type='recentsearchsuggest' class='suggestionURL' href='javascript:void(0);'><span class='recent-search-icon'>"+thisObj.suggestionsRecentIconSvg+"</span>"+obj.value+"</a></li>";
                                                        count++
                                                        thisObj.enableAutoSuggest = true;
                                                        flag = false;
                                                        countRecent++;
                                                    }
                                                });
                                            }    
                                        }
                                        $.each(outObjRecent,function(values,obj){
                                            if(countRecent < maxcountRecent){
                                                if(obj.label[0] != '/'){
                                                    obj.label = "/"+obj.label;
                                                }
                                                list += "<li class='search suggestList' value='"+obj.id+"'><a rel='RecentAuto' value='"+obj.id+"' id="+obj.label+" type='recentsearchsuggest' class='suggestionURL' href='javascript:void(0);'><span class='recent-search-icon'>"+thisObj.suggestionsRecentIconSvg+"</span>"+obj.id+"</a></li>";
                                                count++
                                                thisObj.enableAutoSuggest = true;
                                                flag = false;
                                                countRecent++;
                                            }
                                        });
                                        $.each(outObjSaved,function(values,obj){
                                            if(countSaved < maxcountSaved){
                                                var savedUrl = {};
                                                savedUrl.searchBy = obj.id;
                                                savedUrl.baseSearchUrl = obj.label;
                                                var searchCriteriaObj = JSON.parse(obj.jsonResult);
                                                var searchDetails = thisObj.formUrl(savedUrl,searchCriteriaObj);
                                                var displayTxt = searchDetails.savedFilters;
                                                var srpUrl = searchDetails.url;
                                                if(srpUrl[0] != '/'){
                                                    srpUrl = "/"+srpUrl;
                                                }
                                                list += "<li class='search suggestList' value='"+obj.id+"'><a rel='SavedAuto' value='"+obj.id+"' id="+srpUrl+" type='savedsearchsuggest' class='suggestionURL' href='javascript:void(0);'><span class='saved-search-icon'>"+thisObj.suggestionsSavedIconSvg+"</span><span class='suggest-text'>"+obj.id+"<span class='suggest-sub-text'>"+displayTxt+"</span></span></a></li>";
                                                count++
                                                thisObj.enableAutoSuggest = true;
                                                flag = false;
                                                countSaved++;
                                            }
                                        });
                                        $.each(outObj,function(values){
                                           $('#' + selectedList).show();
                                            $('.error-box').hide();
                                            if(outObj[values].length >0){
                                                $.each(outObj[values],function(inn){
                                                    if(outObj[values][inn].level2Text != ''){
                                                        var val = outObj[values][inn].level1Text+', '+outObj[values][inn].level2Text;

                                                    }else{
                                                        var val = outObj[values][inn].level1Text+' '+outObj[values][inn].level2Text;
                                                    }
                                                    var displayTxt = decodeURIComponent(escape(outObj[values][inn].level1Text));
                                                    if(outObj[values][inn].level2Text && outObj[values][inn].level2Text!== null) displayTxt += ", "+decodeURIComponent(escape(outObj[values][inn].level2Text));
                                                    list += "<li class='search suggestList' value='"+val+"'><a rel='Auto' value='"+val+"' id="+outObj[values][inn].id+" type="+outObj[values][inn].type+" class='suggestionURL' href='javascript:void(0);'><span class='suggest-icon'>"+thisObj.suggestionsIconSvg+"</span>"+displayTxt+"<span class='suggest-sub-text'></span></a></li>";
                                                count++
                                                thisObj.enableAutoSuggest = true;
                                            });
                                            flag = false;
                                        }
                                        var value = $(target).val();
                                        if (value.length < 2){
                                            $('#' + selectedList).hide();
                                            $(target).removeClass("search-result-curve-radius").addClass("search-curve-radius");
                                            $('.error-box').hide();
                                            thisObj.enableAutoSuggest = false;
                                        }
                                    });
                                    str += list;
                                    if(flag){
                                        $('#' + selectedList).hide();
                                        $(target).removeClass("search-result-curve-radius").addClass("search-curve-radius");
                                    }
                                    $('#' + selectedList).html("<ul>"+str+"</ul>");
                            
                            
                        });
                    }
                
                });
            })($);
            
        },
        removeParams: function(queryValue,queryString){
            var newparams = queryString;
            if(queryString.indexOf("&")!=-1){
                var params = queryString.split('&');
                for(var i=0; i<params.length; i++){
                    if(params[i].indexOf(queryValue) >= 0){
                        params.splice(i,1);
                        break;
                    }
                }
                newparams = (params.join('&'));
            } else {
                if(queryString.indexOf(queryValue) >=0){
                    newparams = '';
                }
            }
            return newparams;
        },

        formSuggestionURL: function(id,type, entryPoint){

            var thisObj = this;

            if(currentLocationSearchEnabled && type == "currentLocationSearch"){
                var url = contextPath + '/'+id;
                document.location = encodeURI(url);
                var gatLabel = "visitorId: " + $.cookie('visitorId') + " | UID : " + $.cookie('userId');
                GAUtils.eventTracking.trackevent(null, 'Use Current Location click', gatLabel);
                $(".searchInput").val('');
                return false;
            }

            var queryVal = _.escape($.trim($(".searchInput").val()));
            if(queryVal == "" || queryVal == $('.searchInput').attr('default') || queryVal.toLowerCase() == 'state, county, city, zip code...' || queryVal.toLowerCase() == 'state, county, city...'){
                queryVal = $("#"+id).attr("value");
            }    
           

           $(".global-Mask").show();
            
            var url = contextPath + '/portal/getSearchUrl?id='+id+'&type='+type;
            
            url = encodeURI(decodeURIComponent(url));
            
            
            $.getJSON(url, null , function(response){

                if(response.errorMessage==null){
                    var isPDP = false;
                    var view = thisObj.getQueryParam('viewType',window.location.href);
                    GAUtils.eventTracking.trackevent( null, 'Search Success',queryVal +' | Suggestion Click | '+ entryPoint);
                   
                    var searchNewUrl = '',newSearchURI='';
                    
                    if(view =="map"){
                        if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                            searchNewUrl =  contextPath+"/"+response.searchURI+"?viewType=map";    
                        }else{
                            searchNewUrl =  contextPath+"/"+response.url+"?viewType=map";
                        }
                        newSearchURI = contextPath +'/'+response.searchURI;
                        Utils.RecentSearch.save(queryVal, searchNewUrl, queryVal,newSearchURI );
                    }else{
                        if(type == 'PROPERTY_ID' || type == 'MLS_ID' || type=='PROPERTY_ID_ALIASES' || type=="ADDRESS"){
                           isPDP = true;
                           searchNewUrl =  contextPath+"/"+response.url;
                           newSearchURI = contextPath +'/'+response.searchURI;
                           Utils.RecentSearch.save(queryVal, searchNewUrl, response.defaultSearchText, searchNewUrl);
                        }else {
                           
                           if(entryPoint == 'Empty Search'){
                                thisObj.getStateBasedOnIPAndSearch();
                                return false;
                           } else {

                            if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                                searchNewUrl =  contextPath+"/"+response.searchURI;
                            }else{
                                searchNewUrl =  contextPath+"/"+response.url+"?searchBy="+queryVal;
                            }
                            
                            newSearchURI = contextPath +'/'+response.searchURI;
                            Utils.RecentSearch.save(queryVal, searchNewUrl, queryVal,newSearchURI );
                           }

                        }  
                    }

                    /*if(searchNewUrl.indexOf("/searchResult/") >=  0 || searchNewUrl.indexOf("/auctions/") >=  0){
                        Utils.RecentSearch.save(queryVal, searchNewUrl, queryVal,newSearchURI );
                    } */ 
                    
                    var URL = document.location.href;
                    if( (URL.indexOf("/searchResult") >= 0 || URL.indexOf("/auctions/") >= 0 ) && (!isPDP) && URL.indexOf("eventId=") < 0 ){
                        //URL = URL.replace("&searchBy=","searchBy=");
                        if(URL.indexOf("?")!=-1){
                            var queryString = URL.split("?")[1];
                            
                            //removing map related parms
                            queryString = queryString.replace("&viewType=map","");
                            var mapParams = ["centerPoint","neLatLng","swLatLng","zoomValue","rect"];
                            for(var mp=0; mp<mapParams.length; mp++){
                                queryString = thisObj.removeParams(mapParams[mp],queryString);
                            }

                            queryString = thisObj.removeParams("searchBy",queryString);
                            queryString = thisObj.removeParams("ip2location",queryString);
                            
                            // opt for marketing mail url contains following extra params which need to be removed 
                            queryString = thisObj.removeParams("isOptInMarketingCampaign",queryString);
                            queryString = thisObj.removeParams("campaignName",queryString);

                            if(queryString !== ""){
                                searchNewUrl = searchNewUrl + "&" +queryString;
                            } 
                            document.location = encodeURI(searchNewUrl);
                        }                        

                    } else {
                        document.location = encodeURI(searchNewUrl);
                    }
                    $('.error-box').hide();

                } else{
                    GAUtils.eventTracking.trackevent( null,'Search Error', $(target).val());
                    

                    if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                        $(".searchInput").val("");
                        $( ".searchDetailsBG" ).find('.error-box .message').html($('.error-box').attr('data-error'));
                        $( ".searchDetailsBG" ).find('.error-box').show();
                    }else{
                        document.location = encodeURI(contextPath + "/searchResult?searchBy=" + queryVal);
                    }


                    return false;
                }
                
            });
        },

        search: function (e) {
            var thisObj = this;
            $('.error-box').hide();
            var value = (thisObj.searchVal).trim();
            if(value == '' && value.length == 0 || value == $('.searchInput').attr('default') || value.toLowerCase() == 'state, county, city, zip code...' || value.toLowerCase() == 'state, county, city...') {
                $( e.target ).closest( ".searchDetailsBG" ).find('.error-box .message').html($('.error-box').attr('data-error'));
                $( e.target ).closest( ".searchDetailsBG" ).find('.error-box').show();

            }
            else if(value.toUpperCase() == 'UNITED STATES') {
                document.location = contextPath+ "/searchResult/state/0000000/allstates?searchBy=All States";
            } else {

                 thisObj.didyoumean(value);

            }
            
        },

        closeSearch: function () {
            $('body').removeClass('stop-scrolling');
        },

        handleClickSearchBtn: function(e){

            var thisObj = this,
                selectedList = $(e.currentTarget).attr("google-list-id"),
                element = $('#' + selectedList+" ul li.selected").find(".suggestionURL"),
                gatLabel = "visitorId: " + $.cookie('visitorId') + " | UID : " + $.cookie('userId');

            gatLabel += " | Search term: " + $("input[google-list-id="+selectedList+"]").val();
            GAUtils.eventTracking.trackevent(null, 'Search button click', gatLabel);

            if(element.attr("id") != undefined && element.attr("type") != undefined){
                thisObj.formSuggestionURL(element.attr("id"),element.attr("type"), 'Userfilled');
            }else{
                thisObj.searchVal = $("input[google-list-id="+selectedList+"]").val();
                thisObj.search(e);
            }
            
        },
        handleInputChangeOnFocus: function(e){
            var thisObj = this;
            var url = window.location.href;
            var target = $(e.currentTarget);
            if(!thisObj.focusFirstTime) {
                if(e.currentTarget.value == ''){
                    thisObj.selectedValue = target.defaultValue;

                } else {
                    thisObj.selectedValue = target.val();   

                }
                thisObj.focusFirstTime = true;
            }  
            if(target.val() == thisObj.selectedValue){
                $(target)[0].setSelectionRange(0, 0);
            }    
            
        },

        handleInputChangeOnBlur: function(e){
            var url = window.location.href;
             var target = $(e.currentTarget);
            if(url.indexOf('/property/') == -1) {
                var thisObj = this;
               
                if(target.val() == ''){
                    target.val(thisObj.selectedValue);
                }
            }  else {
                if(target.val() == ''){
                    target.val(target.defaultValue);
                }    
            }  

        },
        
        didyoumean: function(value){
            var thisObj = this;
                $(".googleAPI_result").hide();
                 var url = contextPath + '/portal/v2.0/didyoumean?query='+encodeURIComponent(decodeURIComponent(value));
                 var flag = false;
                 if(thisObj.ajaxReqRef != null) thisObj.ajaxReqRef.abort();
                    
                 thisObj.ajaxReqRef = $.getJSON(url, null , function(response){
                    
                    if(response.errorMessage == null)
                    {
                        //GAUtils.eventTracking.trackevent( null,'Search DYM', $( "#search-input").val());
                        $(".googleAPI_result").hide();
                        var str = "";
                        var res = response.suggestions;
                       
                        
                        var outObj = {};

                        for(var ikey in res){
                            var innerObj = res[ikey];
                            outObj[ikey] = [];
                            for(var iikey in innerObj){
                                var innerInnerObj = innerObj[iikey];
                                outObj[ikey] = outObj[ikey].concat(innerInnerObj);
                            }
                        }
                         var count = 0;
                        $.each(outObj,function(values){
                            $(".googleAPI_result").hide();
                            $('.error-box').hide();
                            var list="";
                            if(outObj[values].length >0){
                                if(typeof outObj[values][0].level2Text != 'undefined' && outObj[values][0].level2Text != ''){
                                    var val = outObj[values][0].level1Text+', '+outObj[values][0].level2Text;

                                }else{
                                    var val = outObj[values][0].level1Text+' '+outObj[values][0].level2Text;
                                }                                
                                $(".searchInput").val($.trim(decodeURIComponent(escape(val))));
                                thisObj.formSuggestionURL(outObj[values][0].id, outObj[values][0].type, 'Userfilled');
                                flag = true;
                            } 
                            $(".googleAPI_result").hide();
                            if(flag) return false;
                        });

                        if(!flag){
                            thisObj.formSuggestionURL(value,"");
                            flag = true;
                        } else {
                            $(".googleAPI_result").hide();
                            return false;
                        }
                            
                    } else {
                            $('body').css({overflow: ''}); 
                            GAUtils.eventTracking.trackevent( null,'Search Error', $( ".searchInput").val());
                            
                            if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                                $(".searchInput").val("");
                                $( ".searchDetailsBG" ).find('.error-box .message').html($('.error-box').attr('data-error'));
                                $( ".searchDetailsBG" ).find('.error-box').show();
                            }else{
                                document.location = contextPath + "/searchResult?searchBy=" + value;
                            }

                            return false;
                    }
                    $(".googleAPI_result").hide();
                    if(flag) return false;
                });
        },
        handleKeyboardSearch: function (e) {
            var thisObj = this,
                selectedList = $(e.currentTarget).attr("google-list-id");
                var target = $(e.currentTarget);
                if(target.val() == thisObj.selectedValue){
                    target.val('');
                }   
            if (e.keyCode === 13) {
                e.preventDefault();
                var element = $("#"+selectedList + " ul li.selected").find(".suggestionURL");
                var rel = element.attr('rel');
                if(rel == 'SavedAuto' || rel == 'RecentAuto'){
                    var responseURL = element.attr('id');
                    window.location =  responseURL;
                }else{
                    if(element.attr("id") != undefined && element.attr("type") != undefined){
                        thisObj.formSuggestionURL(element.attr("id"),element.attr("type"), 'Userfilled');    
                    }else{
                        thisObj.searchVal = $("input[google-list-id="+selectedList+"]").val();
                        thisObj.search(e);
                    }
                }
            }
        },

        getQueryParam : function(queryValue,queryString) {
            var params = queryString.split(queryValue + '=');
            var val = '';
            if(params.length>1)
            {
              params = params[1].split('&');
              val = params[0];
            }
            return val; 
        },

        validateSearchInput: function(e, pattern){ 
            var key;
            var keychar;
            if (window.event)
              key = window.event.keyCode;
            else if (e)
              key = e.which;
            else
              return true;
            /* key == 35 added # */
            if( key == 0 || key == 13 || key == 8 || key == 35 || key == 36  || key == 38 || key == 39 || key == 40 || key == 46) 
            {
                return true; 
            }

            keychar = String.fromCharCode(key);
            
            var allowed = pattern; //"01234567890abcdefghijklmnopqrstuvwxyz &.'!@$%,;-/+.#(){}[]<>"; 
            if( allowed.indexOf(keychar.toLowerCase()) != -1 )
            {
                return true;
            }else{  
                return false; 
            }
        },

        recentSearchSave: function(data) {
            var newRecent = {
                id : data,
                value : data,
                flag : false,
                timeStamp : (new Date()).getTime()
            };
            try{
                var recentSearch=localStorage.getItem("recentSearch");
                recentSearch = JSON.parse(recentSearch);
            }catch(e){}
            if(recentSearch && recentSearch.cookieElements) {
                recentSearch.cookieElements.unshift(newRecent);
                recentSearch = JSON.stringify(recentSearch);
                try{
                    localStorage.setItem("recentSearch", recentSearch);
                }catch(e){}
            }
        },

        getStateBasedOnIPAndSearch: function(){
            var thisObj = this;
            var dateParam = new Date().getTime();
            if(thisObj.ajaxReqGeoLoc != null) thisObj.ajaxReqGeoLoc.abort();
            thisObj.ajaxReqGeoLoc = $.get( contextPath + "/portal/geoLocation?"+dateParam, function(response){
                if(response.status == 'SUCCESS'){
                    var state = response.ipLocationDto.region;
                    //$("#search-input").val(state);
                    $(".homeSearchInput").val(state);
                    thisObj.search();
                }
                  
            });
        
        },

        displayRecentSearchHome: function(userEmail){
            try{
                var thisObj = this;
                var recentSearch=localStorage.getItem("RecentSearchList");
                recentSearch = JSON.parse(recentSearch);
                var recentSearchList="";
                var temp = 0;
                var maxcount = parseInt($('.h-search-container').attr("data-recentsearchcount"));
                if(!userEmail){
                    var searchHeading = $('.search-heading').attr('data-defaulttxt');
                    $('.search-heading').html(searchHeading);
                }
                if(recentSearch!=null && recentSearch!=undefined && recentSearch!='' && recentSearch.cookieElements!=null && recentSearch.cookieElements!=undefined && recentSearch.cookieElements!=''){
                    this.getValidRecents(recentSearch);
                    var promoTxtVer = $('.promotion-section').attr('data-promotxtver');
                    recentSearchList += "<div class='promo-heading'>"+promoTxtVer+"</div><div class='promo-content'>";
                    console.log(recentSearch.cookieElements);

                    $.each(recentSearch.cookieElements, function(index, obj){
                        if(temp<maxcount){
                            recentSearchList += "<a href='javascript:void(0);' data-default-txt = '"+_.escape(obj.defaultSearchText) +"' data-url='"+_.escape(obj.id)+"' data-url-href='"+_.escape(obj.searchURI)+"' class='textFade recentSearchLink promo-item' data-hubzu-searchBy='"+_.escape(obj.value)+"'  title='"+_.escape(obj.value)+" Search'><span class='promo-text'><span class='recent-search-icon'>"+thisObj.suggestionsRecentIconSvg+"</span>Recent Search</span><span class='promo-title'>"+ _.escape(obj.value) +"</span></a>";
                            temp++;
                        }
                    });
                    recentSearchList += '</div>';
                    $(".promotion-section").html(recentSearchList);
                    $(".promotion-section").addClass("recent-search").removeClass('saved-search');
                    thisObj.removePlaceHolderAnimation();
                }else{
                    if(!userEmail){
                        thisObj.showPromoSection();
                        return;
                    }
                    var dateParam = new Date().getTime();
                    $.post(contextPath + '/portal/userRecentSearch?'+dateParam, function(searches){
                        var validRecents = JSON.parse(searches);
                        thisObj.getValidRecents(validRecents);
                        localStorage.setItem("RecentSearchList", JSON.stringify(validRecents));
                        if(validRecents.cookieElements.length){
                            thisObj.getValidRecents(validRecents);
                            var promoTxtVer = $('.promotion-section').attr('data-promotxtver');
                            recentSearchList += "<div class='promo-heading'>"+promoTxtVer+"</div><div class='promo-content'>";
                            $.each(validRecents.cookieElements, function(index, obj){
                                if(temp<maxcount){
                                    recentSearchList += "<a href='javascript:void(0);' data-default-txt = '"+_.escape(obj.defaultSearchText) +"' data-url='"+_.escape(obj.id)+"' data-url-href='"+_.escape(obj.searchURI)+"' class='textFade recentSearchLink promo-item' data-hubzu-searchBy='"+_.escape(obj.value)+"'  title='"+_.escape(obj.value)+" Search'><span class='promo-text'><span class='recent-search-icon'>"+thisObj.suggestionsRecentIconSvg+"</span>RECENT SEARCH</span><span class='promo-title'>"+ _.escape(obj.value) +"</span></a>";
                                    temp++;
                                }
                            });
                            recentSearchList += '</div>';
                            $(".promotion-section").html(recentSearchList);
                            $(".promotion-section").addClass("recent-search").removeClass('saved-search');
                            thisObj.removePlaceHolderAnimation();
                        }else{
                            thisObj.showPromoSection();   
                        }
                    });
                }
                
            }catch(e){}
        },

        showPromoSection: function(){
            var thisObj = this;
            $(".promotion-section").html(thisObj.defaultPromoSectionHtml);
            $(".promotion-section").removeClass("recent-search").removeClass('saved-search');
            this.removePlaceHolderAnimation();
        },

        removePlaceHolderAnimation: function(){
            var thisObj = this;
            $('.p-promo-section').addClass('hide');
            $('.promotion-section').removeClass('hide');
            if(thisObj.isMobile && isHubzu2Enabled !='Y'){
                var promoWidth = $(window).width() - $('.promo-content').offset().left + 'px';
                $('.promo-content').css('width',promoWidth);
            }
        },

        getValidRecents: function(validRecents) {
            var cookies = validRecents.cookieElements;
            var validCookies = [];
            var isDuplicate = false;
            for(var outerCount = 0; outerCount < cookies.length; outerCount++) {
                isDuplicate = false;
                for(var innerCount = 0; innerCount < outerCount; innerCount++) {
                    if(typeof cookies[outerCount].value != 'undefined' && typeof cookies[innerCount].value != 'undefined' && cookies[outerCount].value.trim().toUpperCase() === cookies[innerCount].value.trim().toUpperCase()) {
                        isDuplicate = true;
                    }
                }
                if(!isDuplicate) {
                    validCookies.push(cookies[outerCount]);
                }
            }
            validRecents.cookieElements = validCookies;
        },

        getSavedSearchesData: function (options){
            
            options = options || {};

            var thisObj = this,
                userEmail = options.userEmailId || '',
                userFirstName = options.userFirstName || '';

            if (!userEmail) {
                thisObj.saveSearchResponseList = {};
                thisObj.displayRecentSearchHome(userEmail);
                return;
            }

            if(thisObj.requestPending) {
                return;
            }else{
                thisObj.requestPending = true;
            }

            var saveSearchCountInSuggest = parseInt($('.h-search-container').attr("data-savesearchsuggestion"));
            var saveSearchCountInPromo = parseInt($('.h-search-container').attr("data-savesearchcount"));
            var maxSaveSearch = Math.max(saveSearchCountInSuggest,saveSearchCountInPromo);
            var data = {'emailId':userEmail, 'pageNumber':0, 'pageSize':maxSaveSearch};

            if($("body").hasClass("h-home-page")){
                var searchHeading = $('.search-heading').attr('data-loggedintxt').replace('#NAME#',userFirstName);
                $('.search-heading').html(searchHeading);    
            }
            

            $.ajax({
                url: contextPath+'/portal/drawer/getUserSavedSearch',
                type: 'POST',
                data: JSON.stringify(data),
                dataType: "json",
                contentType: "application/json; charset=utf-8"
            }).done(function(data) {
                if(data.saveSearchResponseList && data.saveSearchResponseList.length){
                    thisObj.saveSearchResponseList = data.saveSearchResponseList;
                    var promoTxtVer = $('.promotion-section').attr('data-promotxtver');
                    var savedSearchList = "<div class='promo-heading'>"+promoTxtVer+"</div><div class='promo-content'>";
                    var count = 0;
                    $.each(data.saveSearchResponseList, function(index, obj){
                        if(count < saveSearchCountInPromo){
                            var savedUrl = {};
                            savedUrl.searchBy = obj.searchBy;
                            savedUrl.baseSearchUrl = obj.baseSearchUrl;
                            var searchDetails = thisObj.formUrl(savedUrl,obj.searchCriteriaList);
                            var displayTxt = searchDetails.savedFilters;
                            var srpUrl = searchDetails.url;
                            savedSearchList += "<a href='javascript:void(0);' data-default-txt = '"+_.escape(obj.searchName) +"' data-url='"+srpUrl+"' data-url-href='"+srpUrl+"' class='textFade savedSearchLink promo-item' data-hubzu-searchBy='"+_.escape(obj.searchBy)+"'  title='"+_.escape(obj.searchName)+" Search'><span class='promo-text'><span class='saved-search-icon'>"+thisObj.suggestionsSavedIconSvg+"</span>Saved Search</span><span class='promo-title'>"+ _.escape(obj.searchName) +"</span><span class='promo-sub-title'>"+ displayTxt +"</span></a>";
                            count++;
                        }
                    });
                    savedSearchList += '</div>';
                    if($("body").hasClass("h-home-page")){
                        $(".promotion-section").html(savedSearchList);
                        $(".promotion-section").removeClass("recent-search").addClass('saved-search');
                        thisObj.removePlaceHolderAnimation();
                    }
                }else{
                    thisObj.displayRecentSearchHome(userEmail);
                }    
            }).fail(function(e) {   
                thisObj.displayRecentSearchHome(userEmail); 
            }).complete(function() {
                thisObj.requestPending = false;
            });
                
        },

        formUrl: function(savedUrl,searchCriteria)
        {
            try{
                var url = [];
                var savedFilters = [];
                var minPrice = '';
                var maxPrice = '';
                var minYearBuilt = '';
                var maxYearBuilt = '';
                var minSqrFt = '';
                var maxSqrFt = '';
                var bathRoom = '';
                var bedRoom = '';
                var propType = '';
                var propStatus = '';
                var showmoreStatus = '';
                var occupyStatus = '';
                var category = '';
                var showMore = '';
                var ownItNow = '';
                var listingType = '';
                var sortBy = '';
                var propStatusDesc='';
                var propTypeDesc='';
                var listingTypeDesc='';
                var propertyShowMoreStatusDesc ='';
                var ownItNowDesc = ''; 
                var previewProperty ='';
                var previewPropertyDesc ='';
                var noBuyerPremiumFilter ='';
                var noBuyerPremiumFilterDesc ='';
                var reservePriceFilter ='';
                var reservePriceFilterDesc ='';
                var selfShowingFilter ='';
                var selfShowingFilterDesc ='';
                var brokerCoOpFilter ='';
                var brokerCoOpFilterDesc ='';
                var newToHubzuFilter='';
                var newToHubzuFilterDesc='';
                var auctionEndingTodayFilter='';
                var auctionEndingTodayFilterDesc='';
                var cashOnlyFilter='';
                 var cashOnlyFilterDesc='';
                var rectSrchFlag ='';
                var view ='';
                var neLatLng ='';
                var swLatLng ='';
                var centerLatLng ='';
                var zoomValue ='';
                var occupancyStatus='';
                var latitude, longitude;
               /* if(savedUrl.searchBy!=null)
                {
                    url.push('searchBy=' + savedUrl.searchBy);
                }
                else
                {
                    url.push('searchBy=All States');
                }*/
                _.each(searchCriteria , function(criteria){

                        switch(criteria.criteriaKey){
                            case "minPriceValue" :
                                    minPrice = criteria.criteriaValue;
                                    
                                break;
                            case "maxPriceValue" :
                                    maxPrice = criteria.criteriaValue;
                                    
                                break;
                            case "minYearBuilt" :
                                    minYearBuilt = criteria.criteriaValue;
                                    
                                break;
                            case "maxYearBuilt" :
                                    maxYearBuilt = criteria.criteriaValue;
                                  
                                break;
                            case "minPropertySize" :
                                    minSqrFt = criteria.criteriaValue;
                                break;
                            case "maxPropertySize" :
                                    maxSqrFt = criteria.criteriaValue;
                                break;
                            case "bath" :
                                    bathRoom = parseInt(criteria.criteriaValue);
                                break;
                            case "bed" :
                                    bedRoom = parseInt(criteria.criteriaValue);
                                break;
                            case "propertySubType":
                                    propType = criteria.criteriaValue;
                                    propTypeDesc = criteria.criteriaValueDescription;
                                break;
                            case "propertyStatus":
                                    propStatus = criteria.criteriaValue;
                                    propStatusDesc = criteria.criteriaValueDescription;
                                   
                                break;
                            case "propertyShowMoreStatus":
                                    showmoreStatus = criteria.criteriaValue;
                                    propertyShowMoreStatusDesc = criteria.criteriaValueDescription;
                                break;
                            
                            case "category":
                                    category = criteria.criteriaValue;
                                  
                                break;
                            case "ownItNow":
                                    ownItNow = criteria.criteriaValue;
                                    ownItNowDesc = criteria.criteriaValueDescription;
                                break;
                            case "listingType":
                                    listingType = criteria.criteriaValue;
                                    listingTypeDesc = criteria.criteriaValueDescription;
                                 
                                break;
                            case "previewProperty": 
                                previewProperty = criteria.criteriaValue;
                                previewPropertyDesc = criteria.criteriaValueDescription;
                                break;
                                
                            case "noBuyerPremiumFilter": 
                                noBuyerPremiumFilter=criteria.criteriaValue;
                                noBuyerPremiumFilterDesc=criteria.criteriaValueDescription;
                                break;

                            case "reservepricerevealed": 
                                reservePriceFilter=criteria.criteriaValue;
                                reservePriceFilterDesc=criteria.criteriaValueDescription;
                                break;
                            
                            case "selfshowingenabled": 
                                selfShowingFilter=criteria.criteriaValue;
                                selfShowingFilterDesc=criteria.criteriaValueDescription;
                                break;

                            case "brokerCoOp": 
                                brokerCoOpFilter=criteria.criteriaValue;
                                brokerCoOpFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "newToHubzu": 
                                newToHubzuFilter=criteria.criteriaValue;
                                newToHubzuFilterDesc=criteria.criteriaValueDescription;
                                break;
                            
                            case "auctionEndingToday": 
                                auctionEndingTodayFilter=criteria.criteriaValue;
                                auctionEndingTodayFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "cashOnly": 
                                cashOnlyFilter=criteria.criteriaValue;
                                cashOnlyFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "sortBy":
                                sortBy = criteria.criteriaValue;
                                break;
                            
                            case "rectSrchFlag": 
                                rectSrchFlag=criteria.criteriaValue;
                                break;
                            case "neLatLng": 
                                neLatLng=criteria.criteriaValue;
                                break;
                            case "swLatLng": 
                                swLatLng=criteria.criteriaValue;
                                break;
                            case "centerLatLng": 
                                centerLatLng=criteria.criteriaValue;
                                break;
                            case "zoomValue": 
                                zoomValue=criteria.criteriaValue;
                                break;
                            case "view": 
                                view=criteria.criteriaValue;
                                break;
                            case "occupancyStatus":
                                occupancyStatus = criteria.criteriaValue;
                                break;
                            case "latitude":
                                latitude = criteria.criteriaValue;
                                break;
                            case "longitude":
                                longitude = criteria.criteriaValue;
                                break;
                        }
                });

                if(category!=''){
                    var catTypes = category.split(',');
                    var enterREO = false;
                    _.each(catTypes , function(val){

                        switch(val)
                        {
                            case "SHRT_SALE": case "NON_REO":
                                url.push('category=' + val);
                                savedFilters.push("Short Sale"); 
                            break;
                            case "bankowned(occupied)": 
                                url.push('category=bankowned(occupied)');
                                savedFilters.push("Bank Owned (Occupied)");
                            break;
                            case "bankowned(nonoccupied)": 
                                url.push('category=bankowned(nonoccupied)');
                                savedFilters.push("Bank Owned (Non Occupied)");
                            break;
                            case "nonbankowned(occupied)": 
                                url.push('category=nonbankowned(occupied)');
                                savedFilters.push("Non-Bank Owned (Occupied)");
                            break;
                            case "nonbankowned(nonoccupied)": 
                                url.push('category=nonbankowned(nonoccupied)');
                                savedFilters.push("Non-Bank Owned (Non Occupied)");
                            break;
                            case "foreclosure":
                                url.push('category=foreclosure');
                                savedFilters.push("Foreclosure"); 
                            break;
                            case "REO":
                                if(enterREO==false)
                                {
                                    enterREO = true;
                                    if(occupyStatus.indexOf('Y')>-1)
                                    {
                                        url.push('category=bankowned(occupied)');
                                        savedFilters.push("Bankowned(occupied)"); 
                                    }
                                    if(occupyStatus.indexOf('N')>-1)
                                    {
                                        url.push('category=bankowned(nonoccupied)');
                                        savedFilters.push("Bankowned(Non-occupied)"); 
                                    }
                                }
                            break;
                            case "bankowned":
                                url.push('category=bankowned');
                                savedFilters.push("Bank Owned"); 

                            break;
                            case "nonbankowned":
                                url.push('category=nonbankowned');
                                savedFilters.push("Non-Bank Owned");

                            break;
                        }

                    });
                }

                if(propType!=''){
                    var proparr = propType.split(',');
                    var propTypeDescArr = propTypeDesc.split(',');
                     _.each(proparr , function(val){
                        url.push('propertySubType=' + val);
                         
                         
                    });
                     _.each(propTypeDescArr,function(valDesc){
                         savedFilters.push(valDesc); 
                     });
                }

                if(minPrice !== '' || maxPrice !== ''){
                    if(minPrice === '') {
                        url.push('priceRange=0-' + maxPrice);
                        savedFilters.push("$0 - $"+maxPrice);
                    } else if(maxPrice === '') {
                        url.push('priceRange=' + minPrice + '-1000000plus' );
                        savedFilters.push("$" + minPrice + "+"); 
                    } else {
                        url.push('priceRange=' + minPrice + '-' + maxPrice);
                        savedFilters.push("$" + minPrice + '-$' + maxPrice ); 
                    }
                }
                
                if(bathRoom!='')
                {
                    url.push('bath=' + bathRoom);
                    savedFilters.push(bathRoom + "+ Baths"); 
                }

                if(bedRoom!='')
                {
                    url.push('bed=' + bedRoom);
                    savedFilters.push(bedRoom + "+ Beds");
                }

                if(minSqrFt !== '' || maxSqrFt !== ''){
                    if(minSqrFt === '') {
                        url.push('squareFeetRange=0-' + maxSqrFt);
                        savedFilters.push("$0 - $"+maxSqrFt);
                    } else if(maxSqrFt === '') {
                        url.push('squareFeetRange=' + minSqrFt + '-10000plus' );
                        savedFilters.push(minSqrFt + "+ Sq Ft"); 
                    } else {
                        url.push('squareFeetRange=' + minSqrFt + '-' + maxSqrFt);
                        savedFilters.push( minSqrFt + 'Sq Ft-' + maxSqrFt+'Sq Ft' ); 
                    }
                }

                if(minYearBuilt !== '' || maxYearBuilt !== ''){
                    if(minYearBuilt === '') {
                        url.push('yearBuiltRange=Before 1800-' + maxYearBuilt);
                        savedFilters.push("Year:0-"+maxYearBuilt);
                    } else if(maxYearBuilt === '') {
                        url.push('yearBuiltRange=' + minYearBuilt + '-After 2010' );
                        savedFilters.push("Year:" + minYearBuilt + "+"); 
                    } else {
                        url.push('yearBuiltRange=' + minYearBuilt + '-' + maxYearBuilt);
                        savedFilters.push("Year:" + minYearBuilt + '-' + maxYearBuilt ); 
                    }
                }

                if(propStatus!='')
                {
                    var proparr = propStatus.split(',');
                    var propTypeDescArr = propStatusDesc.split(',');
                     _.each(proparr , function(val){
                            url.push('propertyStatus=' + val);
                    });
                    _.each(propTypeDescArr , function(valDesc){
                        savedFilters.push(valDesc);
                    });


                }

                    

                if(showmoreStatus!='')
                {
                     var proparr = showmoreStatus.split(',');
                     var propertyShowMoreStatusDescArr = propertyShowMoreStatusDesc.split(',');
                     _.each(proparr , function(val){
                        url.push('propertyShowMoreStatus=' + val);
                    });
                     _.each(propertyShowMoreStatusDescArr , function(valDesc){
                         savedFilters.push(valDesc); 
                    });
                }

                if(occupancyStatus !=''){
                    var occupancyStatusTypes = occupancyStatus.split(',');
                    _.each(occupancyStatusTypes , function(val){
                        switch(val){
                            case "Y":
                                url.push('occupancyStatus=Y');
                                savedFilters.push("Occupied"); 
                            break;
                            case "N":
                                url.push('occupancyStatus=N');
                                savedFilters.push("Non Occupied"); 

                            break;
                        }
                    });
                }
                if(ownItNow!='')
                {
                    url.push('listingType=AUCN');
                    url.push('ownItNow=Y');
                    if(url.indexOf('propertyStatus=ACTIVE')==-1)
                    {
                        url.push('propertyStatus=ACTIVE');

                    }
                    savedFilters.push("Auction"); 
                    savedFilters.push(ownItNowDesc); 
                }
                else if(listingType!='')
                {
                     var arr = listingType.split(',');
                     var listingTypeDescArr = listingTypeDesc.split(',');
                     _.each(arr , function(val){
                        url.push('listingType=' + val);
                    });
                     _.each(listingTypeDescArr , function(valDesc){
                        savedFilters.push(valDesc); 
                    });
                }
                if(previewProperty!=''){
                    url.push('marketingPhase=' + previewProperty);
                    savedFilters.push(previewPropertyDesc); 
                }
                if(noBuyerPremiumFilter!=''){
                    url.push('nobuyerpremium=' + noBuyerPremiumFilter);
                    savedFilters.push(noBuyerPremiumFilterDesc); 
                }
                if(reservePriceFilter!=''){
                    url.push('reservepricerevealed=' + reservePriceFilter);
                    savedFilters.push(reservePriceFilterDesc); 
                }
                if(selfShowingFilter!=''){
                    url.push('selfshowingenabled=' + selfShowingFilter);
                    savedFilters.push(selfShowingFilterDesc); 
                }
                if(brokerCoOpFilter!=''){
                    url.push('brokerCoOp=' + brokerCoOpFilter);
                    savedFilters.push(brokerCoOpFilterDesc); 
                }
                if(newToHubzuFilter!=''){
                    url.push('newToHubzu=' + newToHubzuFilter);
                    savedFilters.push(newToHubzuFilterDesc); 
                }
                if(auctionEndingTodayFilter!=''){
                    url.push('auctionEndingToday=' + auctionEndingTodayFilter);
                    savedFilters.push(auctionEndingTodayFilterDesc); 
                }
                if(cashOnlyFilter!=''){
                    url.push('cashOnly=' + cashOnlyFilter);
                    savedFilters.push(cashOnlyFilterDesc); 
                }
                
                if (latitude) {
                    url.push('latitude=' + latitude);
                }
                if (longitude) {
                    url.push('longitude=' + longitude);
                }

                if(rectSrchFlag!=''){
                    url.push('rect=true');
                }
                if(neLatLng!=''){
                    url.push('neLatLng=' + neLatLng);
                }
                if(swLatLng!=''){
                    url.push('swLatLng=' + swLatLng);
                }
                if(centerLatLng!=''){
                    url.push('centerPoint=' + centerLatLng);
                }
                if(zoomValue!=''){
                    url.push('zoomValue=' + zoomValue);
                }
                if(view!=''){
                    url.push('view=' + view); 
                }
                
                
                if(sortBy!='')
                {
                    url.push(sortBy);
                }
                if(savedUrl.baseSearchUrl!=null){
                    if(url.length > 0){
                        url = contextPath + '/' + savedUrl.baseSearchUrl + '?' + url.join('&');    
                    }else{
                        url = contextPath + '/' + savedUrl.baseSearchUrl;
                    }
                }
                else{
                    if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                        url = contextPath + '/auctions?' + url.join('&');
                    }else{
                        url = contextPath + '/searchResult?' + url.join('&');
                    }
                }
                var data = {};
                data.url = url;
                data.savedFiltersGA = savedFilters.join(',');
                if(savedFilters.length > 3){
                    data.savedFilters = savedFilters[0] + ", " + savedFilters[1]+ ", " + savedFilters[2] + ", +" + (parseInt(savedFilters.length) - 3) + " More";
                }else{ 
                    data.savedFilters = savedFilters.join(", ");
                }    

                return data;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "SearchView", "file:SearchView.js|function:formUrl|" + e, null, 1);
            }
        },
        showRecentAndSavedSearchSuggestion: function(e){
            var thisObj = this; 
            var selectedList = $(e.currentTarget).attr("google-list-id");
            var list = '';
            if(currentLocationSearchEnabled){
                var labelTxt = currentLocationLabelTxt;
                var targetParent = "<li class='search suggestList current-location' value='"+labelTxt+"'><a value='currentLocation' id='near-me' type='currentLocationSearch' class='suggestionURL' href='javascript:void(0);'><span class='current-location-icon'></span><span class='suggest-text'><span class='suggest-sub-text'>Use Current Location</span></span></a></li>";
                list = targetParent;
            } else {
                $("#"+selectedList).hide();
            }
            list += thisObj.getLatestRecentSearchSuggest();
            list += thisObj.getLatestSavedSearchSuggest();
            if(list != ''){
                $('#' + selectedList).html("<ul>"+list+"</ul>");
                $('#' + selectedList).show();
            }
        },
        getLatestRecentSearchSuggest: function(){
            var thisObj = this;
            var recentSearch = localStorage.getItem("RecentSearchList");
            var count = 0;
            var maxcount = parseInt($('.h-search-container').attr("data-recentsearchsuggestion"));
            recentSearch = JSON.parse(recentSearch);
            var list = '';
            if(recentSearch!=null && recentSearch!=undefined && recentSearch!='' && recentSearch.cookieElements!=null && recentSearch.cookieElements!=undefined && recentSearch.cookieElements!=''){
                thisObj.getValidRecents(recentSearch);
                $.each(recentSearch.cookieElements, function(index, obj){
                    if(count < maxcount){
                        if(obj.searchURI[0] != '/'){
                            obj.searchURI = "/"+obj.searchURI;
                        }
                        list += "<li class='search suggestList' value='"+obj.value+"'><a rel='RecentAuto' value='"+obj.value+"' id="+obj.searchURI+" type='recentsearchsuggest' class='suggestionURL' href='javascript:void(0);'><span class='recent-search-icon'>"+thisObj.suggestionsRecentIconSvg+"</span>"+obj.value+"</a></li>";
                        count++;
                    }
                });
            }
            return list;
        },

        getLatestSavedSearchSuggest: function(){
            var thisObj = this;
            var count = 0;
            var maxcount = parseInt($('.h-search-container').attr("data-savesearchsuggestion"));
            var list = '';
            var saveSearchResponseList = thisObj.saveSearchResponseList;
            if(saveSearchResponseList.length){
                $.each(saveSearchResponseList, function(index, obj){
                    if(count < maxcount){
                        var savedUrl = {};
                        savedUrl.searchBy = obj.searchBy;
                        savedUrl.baseSearchUrl = obj.baseSearchUrl;
                        var searchCriteriaObj = obj.searchCriteriaList;
                        var searchDetails = thisObj.formUrl(savedUrl,searchCriteriaObj);
                        var displayTxt = searchDetails.savedFilters;
                        var srpUrl = searchDetails.url;
                        if(srpUrl[0] != '/'){
                            srpUrl = "/"+srpUrl;
                        }
                        list += "<li class='search suggestList' value='"+obj.searchName+"'><a rel='SavedAuto' value='"+obj.searchName+"' id='"+srpUrl+"' type='savedsearchsuggest' class='suggestionURL' href='javascript:void(0);'><span class='saved-search-icon'>"+thisObj.suggestionsSavedIconSvg+"</span><span class='suggest-text'>"+obj.searchName+"<span class='suggest-sub-text'>"+displayTxt+"</span></span></a></li>";
                        count++;
                    }
                });
            }
            return list;
        },

        redirectToPromotionalPage: function(e){ 
            
            var responseURL = $(e.currentTarget).attr("data-url"),
                gatLabel = "visitorId: " + $.cookie('visitorId');

                gatLabel += " | type: Promotion | name: " + $(e.currentTarget).find(".promo-title").text();
            GAUtils.eventTracking.trackevent("","Search Promotion Click", gatLabel, '');
            window.location =  responseURL;
        },
        redirectToRecentSearchPage: function(e){
            var queryVal = $(e.currentTarget).attr("data-hubzu-searchBy"),
                defaultText = $(e.currentTarget).attr("data-default-txt"), 
                responseURL = $(e.currentTarget).attr("data-url"),
                responseURLHref = $(e.currentTarget).attr("data-url-href"),
                gatLabel = "visitorId: " + $.cookie('visitorId');  

            gatLabel += " | type: Recent Search | name: " + queryVal;

            Utils.RecentSearch.save(queryVal, responseURL, defaultText,responseURLHref);
            GAUtils.eventTracking.trackevent("","Recent Search Click", gatLabel, '');
            window.location =  responseURL;
        },

        redirectToSavedSearchPage: function(e){
            var queryVal = $(e.currentTarget).attr("data-hubzu-searchBy"),
                defaultText = $(e.currentTarget).attr("data-default-txt"), 
                responseURL = $(e.currentTarget).attr("data-url"),
                responseURLHref = $(e.currentTarget).attr("data-url-href"),
                gatLabel = "visitorId: " + $.cookie('visitorId') + " | UID : " + $.cookie('userId');  

            gatLabel += " | type: Saved Search | name: " + queryVal;

            Utils.RecentSearch.save(queryVal, responseURL, defaultText,responseURLHref);
            GAUtils.eventTracking.trackevent("","Saved Search Click", gatLabel, '');
            window.location =  responseURL;
        }
    });

    return SearchView
});