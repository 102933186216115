
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/licenseTemplate',['hbs','hbs/handlebars','templates/helpers/ifEqual','templates/helpers/getKey'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div class=\"row licenseDetRow \" data-id=\""
    + escapeExpression(((helper = (helper = helpers.agentLicenseId || (depth0 != null ? depth0.agentLicenseId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"agentLicenseId","hash":{},"data":data}) : helper)))
    + "\" id=\"updateLicense"
    + escapeExpression(((helper = (helper = helpers.agentLicenseId || (depth0 != null ? depth0.agentLicenseId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"agentLicenseId","hash":{},"data":data}) : helper)))
    + "\">\n\n    <div class=\"row update-license-actions\">\n        <div class=\"license-error-message license-error-"
    + escapeExpression(((helper = (helper = helpers.agentLicenseId || (depth0 != null ? depth0.agentLicenseId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"agentLicenseId","hash":{},"data":data}) : helper)))
    + "\">\n\n        </div>\n        <div class=\"editDeleteMobile\">\n            <span class=\"glyphicon glyphicon-pencil edit-license\" data-action=\"edit\" id=\"gaLicenseEdit"
    + escapeExpression(((helper = (helper = helpers.agentLicenseId || (depth0 != null ? depth0.agentLicenseId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"agentLicenseId","hash":{},"data":data}) : helper)))
    + "\"\n                data-toggle=\"modal\" data-target=\"#deleteLicenseModal\" data-license-type=\""
    + escapeExpression(((helper = (helper = helpers.licenseType || (depth0 != null ? depth0.licenseType : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"licenseType","hash":{},"data":data}) : helper)))
    + "\" data-license-number=\""
    + escapeExpression(((helper = (helper = helpers.agentLicenseId || (depth0 != null ? depth0.agentLicenseId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"agentLicenseId","hash":{},"data":data}) : helper)))
    + "\"></span>\n            <span class=\"glyphicon glyphicon-trash delete-license\" id=\"gaLicenseDelete"
    + escapeExpression(((helper = (helper = helpers.agentLicenseId || (depth0 != null ? depth0.agentLicenseId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"agentLicenseId","hash":{},"data":data}) : helper)))
    + "\" tabindex=\"0\"\n                data-toggle=\"modal\" data-target=\"#deleteLicenseModal\" data-license-number=\""
    + escapeExpression(((helper = (helper = helpers.agentLicenseId || (depth0 != null ? depth0.agentLicenseId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"agentLicenseId","hash":{},"data":data}) : helper)))
    + "\"\n                license-type=\""
    + escapeExpression(((helper = (helper = helpers.licenseType || (depth0 != null ? depth0.licenseType : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"licenseType","hash":{},"data":data}) : helper)))
    + "\">\n        </div>\n    </div>\n    <div class=\"row\">\n        <div class=\"col-xs-6\">\n            <div class=\"licenseSubHead\">"
    + escapeExpression(lambda(((stack1 = (depths[1] != null ? depths[1].dashboardContent : depths[1])) != null ? stack1.licenseNumber : stack1), depth0))
    + " : </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"licenseSubTitle licenseNumberDisplay\">"
    + escapeExpression(((helper = (helper = helpers.licenseNumber || (depth0 != null ? depth0.licenseNumber : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"licenseNumber","hash":{},"data":data}) : helper)))
    + "</div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-xs-6 \">\n            <div class=\"licenseSubHead\">"
    + escapeExpression(lambda(((stack1 = (depths[1] != null ? depths[1].dashboardContent : depths[1])) != null ? stack1.licenseState : stack1), depth0))
    + " : </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"licenseSubTitle\">"
    + escapeExpression(((helper = (helper = helpers.stateId || (depth0 != null ? depth0.stateId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"stateId","hash":{},"data":data}) : helper)))
    + "</div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-xs-6 \">\n            <div class=\"licenseSubHead\">"
    + escapeExpression(lambda(((stack1 = (depths[1] != null ? depths[1].dashboardContent : depths[1])) != null ? stack1.licenseExp : stack1), depth0))
    + " : </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"licenseSubHead\">"
    + escapeExpression(((helper = (helper = helpers.licenseExpiryDate || (depth0 != null ? depth0.licenseExpiryDate : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"licenseExpiryDate","hash":{},"data":data}) : helper)))
    + "</div>\n        </div>\n    </div>\n\n    <div class=\"row\">\n        <div class=\"col-xs-6\">\n            <div class=\"licenseSubHead\">"
    + escapeExpression(lambda(((stack1 = (depths[1] != null ? depths[1].dashboardContent : depths[1])) != null ? stack1.licenseStateOfficeAddressLabel : stack1), depth0))
    + " : </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"licenseSubTitle\">"
    + escapeExpression(((helper = (helper = helpers.address1 || (depth0 != null ? depth0.address1 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"address1","hash":{},"data":data}) : helper)))
    + "&nbsp;"
    + escapeExpression(((helper = (helper = helpers.address2 || (depth0 != null ? depth0.address2 : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"address2","hash":{},"data":data}) : helper)))
    + " ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.city : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.state : depth0), {"name":"if","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += escapeExpression(((helper = (helper = helpers.zip || (depth0 != null ? depth0.zip : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"zip","hash":{},"data":data}) : helper)))
    + "</div>\n        </div>\n    </div>\n\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.licenseType : depth0), "addLicense", {"name":"ifEqual","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "\n                "
    + escapeExpression(((helper = (helper = helpers.city || (depth0 != null ? depth0.city : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"city","hash":{},"data":data}) : helper)))
    + ",";
},"4":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return escapeExpression(((helper = (helper = helpers.state || (depth0 != null ? depth0.state : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"state","hash":{},"data":data}) : helper)))
    + ",";
},"6":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "    <div class=\"row\">\n        <div class=\"col-xs-6\">\n            <div class=\"licenseSubHead\">"
    + escapeExpression(lambda(((stack1 = (depths[2] != null ? depths[2].dashboardContent : depths[2])) != null ? stack1.licenseStatusDisp : stack1), depth0))
    + " : </div>\n        </div>\n        <div class=\"col-xs-6\">\n            <div class=\"licenseSubTitle \">";
  stack1 = ((helpers.getKey || (depth0 && depth0.getKey) || helperMissing).call(depth0, ((stack1 = (depths[2] != null ? depths[2].dashboardContent : depths[2])) != null ? stack1.licenseStatus : stack1), (depth0 != null ? depth0.status : depth0), {"name":"getKey","hash":{},"fn":this.program(7, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n        </div>\n    </div>\n";
},"7":function(depth0,helpers,partials,data) {
  return "";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1;
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.licenseList : depth0), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/globalAlerts/licenseTemplate', t);
return t;
});
/* END_TEMPLATE */
;