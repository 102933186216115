
define('appleSignInView',[
	'jquery',
	'underscore',
	'backbone',
	'GoogleAnalyticsUtils',
	'handlebars',
	'validator'
], function ($, _, Backbone, GAUtils, Handlebars, validator) {
	var appleSignInView = Backbone.View.extend({
		el: 'body',
		appleLoginObj: {
			authorization: '',
			user: {
				email: '',
				name: {
					firstName: '',
					lastName: ''
				}
			}
		},
		componentsCmsNode: {},
		networkCallInProgress: false,
		events: {
			"click #appleSocialLoginSubmit": "submitAppleLoginEmail",
			"blur #appleSocialLoginEmailId": "validateEmailField",
			"blur .name": "validateNameField"
		},

		initialize: function () {
			console.log("Apple signIn");
			if (typeof dashboardContent === "object" && dashboardContent.registration) {
				this.componentsCmsNode = dashboardContent;
			} else {
				this.componentsCmsNode = saveListDrawerContent;
			}
		},

		validateEmailField: function (event, callback) {
			try {
				var obj = '#appleSocialLoginEmailId';
				var emailUrl = contextPath + '/portal/user/isValidEmailAddress',
					self = this;

				/*If element is not visible, then return; If user saved his email/passwords that will prepopulate and trigger focusout event*/
				if (!$(obj).is(":visible")) { $(obj).val(""); return; }

				$.trim($(obj).val($.trim($(obj).val()).toLowerCase()));

				if ($.trim($(obj).val()) == '') {

					$('.' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.emailIsRequired);
					this.addErrorClass(obj, false);
					if (callback) callback();

				} else if (!(validator.isEmail($.trim($(obj).val()), obj))) {

					$('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.errorEmailId);
					this.addErrorClass(obj, true);
					if (callback) callback();

				} else {

					this.addInprogressClass(obj);

					$.ajax({
						type: "POST",
						url: emailUrl,
						data: { 'email': $.trim($(obj).val()) },
						dataType: 'json',
						success: function (data) {

							if (data.status == "SUCCESS") {

								// $('#leanPasswordToolTip').attr('data-content',data.passwordToolTip);
								self.addSuccessClass(obj);

								// var currentElement = $('#leanRegPassword'),
								// 	passwordValue = $.trim($(currentElement).val()),
								// 	closestNode = $(currentElement).closest('.input-box-container');

							} else {

								$('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.emailErrorMessages[data.status]);
								self.addErrorClass(obj, true);
							}
							if (callback) callback();
						},
						error: function (xhr, ajaxOptions, thrownError) {
							$('#ajax-loader-mask').css('display', 'none');
							$('#ajax-loader').css('display', 'none');
							GAUtils.eventTracking.trackevent("Error", "New Registration", "validate Email|" + xhr.status, null, 1);
							if (callback) callback();
						}
					});

				}
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "New Registration", "file:appleSignInView.js|function:validateEmailField|" + e, null, 1);
			}
		},

		validateNameField: function (event) {
			try {
				var obj = '#' + event.target.id,
					value = $.trim($(obj).val()),
					errorMessage = (event.target.id === 'leanRegFirstName' || event.target.id === 'leanRegFirstName')
						? this.componentsCmsNode.errorMessages.firstNameIsRequired
						: this.componentsCmsNode.errorMessages.lastNameIsRequired;

				if (value === '') {

					$('.' + $(obj).attr('error')).html(errorMessage);
					this.addErrorClass(obj, false);

				} else if (!(validator.isValidName(value, obj))) {

					$('.' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.charactersAllowedLabel);
					this.addErrorClass(obj, true);

				} else {
					this.addSuccessClass(obj);
				}
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "New Registration", "file:appleSigninView.js|function:validateNameField|" + e, null, 1);
			}
		},

		addErrorClass: function (elementObject, hasValue) {
			try {
				var closestNode = $(elementObject).closest('.input-box-container');
				$(closestNode).find('.input-box-error').addClass('input-label-error');
				$(closestNode).find('.input-box-success').removeClass('input-label-success');
				$('.' + $(elementObject).attr('error')).addClass('has-input-error');
				$(closestNode).find('.input-box-active').removeClass('input-box-progress');

				$('.' + $(elementObject).attr('iconname')).removeClass('clicked');
				$('.' + $(elementObject).attr('avtarImage')).removeClass('active');

				$(elementObject).addClass('hasInputError');

				if (hasValue) {
					$(closestNode).find('.input-box-label').addClass('input-box-filled');
				} else {
					$(closestNode).find('.input-box-label').removeClass('input-box-filled');
				}
				$(elementObject).removeClass("h-filled");
				this.networkCallInProgress = false;
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:addErrorClass|" + e, null, 1);
			}

		},

		addSuccessClass: function (elementObject) {
			try {
				var closestNode = $(elementObject).closest('.input-box-container');
				$(closestNode).find('.input-box-success').addClass('input-label-success');
				$(closestNode).find('.input-box-error').removeClass('input-label-error');
				$(closestNode).find('.input-box-label').addClass('input-box-filled');
				$('.' + $(elementObject).attr('error')).removeClass('has-input-error');
				$(closestNode).find('.input-box-active').removeClass('input-box-progress');
				$(elementObject).removeClass('hasInputError');

				$('.' + $(elementObject).attr('iconname')).removeClass('clicked');
				$('.' + $(elementObject).attr('avtarImage')).removeClass('active');

				$(elementObject).addClass("h-filled");

				this.networkCallInProgress = false;
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:addSuccessClass|" + e, null, 1);
			}
		},

		addInprogressClass: function (elementObject) {
			var closestNode = $(elementObject).closest('.input-box-container');
			$(closestNode).find('.input-box-label').addClass('input-box-filled');
			$(closestNode).find('.input-box-active').addClass('input-box-progress');

			this.networkCallInProgress = true;
		},

		isSignedInWithApple: function () {
			try {
				return AppleID.auth.isSignedIn.get();
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "AppleLogin", "file:appleSignInView.js|function:isSignedInWithApple|" + e, null, 1);
			}
		},

		submitAppleLoginEmail: function () {
			try {
				var self = this;
				$('#appleSocialLoginSubmit').attr('disabled', 'disabled');
				$('#socialLoginRegFirstName').blur();
				$('#socialLoginRegLastName').blur();
				self.validateEmailField(null, function () {
					if ($('#appleSocialLoginForm input.hasInputError').length > 0) {
						$('#appleSocialLoginForm .hasInputError').first().focus();
						$('#appleSocialLoginSubmit').prop('disabled', false);
					} else {
						self.appleLoginObj['user'].email = $.trim($('#appleSocialLoginEmailId').val());
						self.appleLoginObj['user']['name'].firstName = $.trim($('#socialLoginRegFirstName').val());
						self.appleLoginObj['user']['name'].lastName = $.trim($('#socialLoginRegLastName').val());
						var gaLabel = 'visitorId: ' + $.cookie('visitorId');
						var eventLabel = gaLabel + '| Social Login: Apple Screen 2';
						GAUtils.eventTracking.trackevent("Link click", "Linking Apple id with Hubzu", eventLabel);
						self.callbackfunc(self.appleLoginObj, self.callbackContextObj);
					}
				});
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "Subit Apple login Email", "file:appleSigninView.js|function:initialize|" + e, null, 1);
			}
		},

		showAppleLoginModal: function () {
			var self = this;
			if (document.body.classList.contains('register-page-container')) {
				$('#leanLoginFrom').hide();
				$('#registrationForm').hide();
				$('.social-login-section').hide();
			}
			$('.modal-content').removeClass('activeStage');
			var socialLoginTmpl = $("#appleSocialLoginModal").html();
			socialLoginTmpl = Handlebars.compile(socialLoginTmpl);
			//var socialLoginHead = $("#mobileVerHead").html();
			//socialLoginHead = Handlebars.compile(socialLoginHead);

			// var phoneNumber = userSession.phone||'';
			// phoneNumber = Utils.formatPhoneNumber(phoneNumber);
			// phoneNumber = PhoneVerification.applyPhoneMaskFormat({"phone":phoneNumber});

			var content = {
				popupTitle: "Please enter your details",
				// data : {
				// 	proxyCode:proxyPin,
				// 	phoneNumber:phoneNumber
				// }
			};

			socialLoginTmpl = socialLoginTmpl({
				content: content
			});
			//console.log(socialLoginTmpl);
			// socialLoginHead = socialLoginHead({
			// 	content: content
			// });
			if (document.body.classList.contains('register-page-container')) {
				$('#modal-head-phone .h-ver-head').text('Please enter your details');
				$("#mobile-verification-body").hide();
				$('#appleSocialLoginModal').show(); // loading apple form 
				$('#mobile-verification').show();
			} else {
				$('#modal-head-phone').html(socialLoginHead);
				$("#mobile-verification-body").html(socialLoginTmpl);
				$('#mobile-verification').addClass('activeStage');
			}
			var fName = (self.appleLoginObj.user && self.appleLoginObj.user && self.appleLoginObj.user.name) ? self.appleLoginObj.user.name.firstName : '';
			var lName = (self.appleLoginObj.user && self.appleLoginObj.user && self.appleLoginObj.user.name) ? self.appleLoginObj.user.name.lastName : '';
			var emailId = '';
			if (self.appleLoginObj && self.appleLoginObj.user && self.appleLoginObj.user.email && self.appleLoginObj.user.email.indexOf('privaterelay.appleid.com') === -1) {
				emailId = self.appleLoginObj.user.email;
			}
			// var emailId = (self.appleLoginObj && self.appleLoginObj.user && self.appleLoginObj.user.email) ? self.appleLoginObj.user.email : '';
			$('#appleSocialLoginEmailId').val(emailId);
			$('#socialLoginRegFirstName').val(fName);
			$('#socialLoginRegLastName').val(lName);
			$("#ajax-loader-mask,#ajax-loader").hide();

			// var trackGALabel = '';
			// var propertyId = propertyId ? propertyId : '';
			// if(propertyId){
			// 	trackGALabel = "Property Id : " + propertyId + "| ";
			// }

			// this.gaEventTracking("Proxy Bidding registration successful",trackGALabel + " Screen name: Bidding Registration");

		},

		getRandomString: function () {
			let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			let result = '';
			for (let i = 0; i < length; i++) {
				result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
			}
			return result;
		},

		appleLogin: function (options) {
			try {
				var self = this;
				self.callbackfunc = options.callback;
				self.callbackContextObj = options.callbackContextObj;

				var rawNonce = JSON.stringify(self.getRandomString(20));
				var state = JSON.stringify(self.getRandomString(20));

				var SOCIAL_LOGIN_CONFIG = saveListDrawerContent.socialLoginConfig[envConf];

				if (!SOCIAL_LOGIN_CONFIG) {
					SOCIAL_LOGIN_CONFIG = saveListDrawerContent.socialLoginConfig["DEFAULT"];
				}
				AppleID.auth.init({
					clientId: SOCIAL_LOGIN_CONFIG.appleSigninConfig.clientId,
					scope: 'name email',
					redirectURI: SOCIAL_LOGIN_CONFIG.appleSigninConfig.redirectURL,
					state: state,
					nonce: rawNonce,
					usePopup: true //or false defaults to false
				});

				AppleID.auth.signIn().then(function (currentUserObj) {
					console.log('Apple response', currentUserObj);
					if (currentUserObj && currentUserObj.authorization && currentUserObj.authorization.code) {
						self.checkRegistrationStatus(currentUserObj.authorization).then(function (response) {
							self.appleLoginObj = {
								authorization: '',
								user: {
									email: '',
									name: {
										firstName: '',
										lastName: ''
									}
								}
							};
							self.appleLoginObj.authorization = currentUserObj.authorization ? currentUserObj.authorization : '';
							self.appleLoginObj.user = currentUserObj.user ? currentUserObj.user : self.appleLoginObj.user;
							if (response && response.data.isRegistered) {
								// currentUserObj.socialLoginEmail = $.trim($('#appleSocialLoginEmailId').val());
								self.callbackfunc(self.appleLoginObj, self.callbackContextObj);
							} else {
								$('#ajax-loader-mask').css('display', 'none');
								$('#ajax-loader').css('display', 'none');
								if (($('.gobal-alerts-drawer-slider').hasClass('is-visible')) || ($("body").hasClass("h-srp-page") && ($(".popover").hasClass("in") || $("#commonConfirmPopup").hasClass("in")))) {
									$(".popover,#commonConfirmPopup").remove();
									$(".modal-backdrop").removeClass("in");
									$('#loginRegisterDialog .modal-content').removeClass('activeStage');
									$('#loginRegisterDialog').modal("show");
								}
								self.showAppleLoginModal();
							}
						}).catch(function (error) {
							$('#ajax-loader-mask').css('display', 'none');
							$('#ajax-loader').css('display', 'none');
							$('#loginRegisterDialog .login-form-error.responseError').html('Internal server error, please try again later');
							$('#loginRegisterDialog .login-form-error.responseError').show();
							GAUtils.eventTracking.trackevent("Error", "New apple Registration", "error:Internal server error|" + xhr.status, null, 1);
						});
					} else {
						$('#ajax-loader-mask').css('display', 'none');
						$('#ajax-loader').css('display', 'none');
						$('#loginRegisterDialog .login-form-error.responseError').html('Internal server error, please try again later');
						$('#loginRegisterDialog .login-form-error.responseError').show();
						GAUtils.eventTracking.trackevent("Error", "New apple Registration", "error:Internal server error|" + xhr.status, null, 1);
					}
				}).catch(function (error) {
					console.log('error', error);
				});
			} catch (e) {
				GAUtils.eventTracking.trackevent("Error", "AppleLogin", "file:appleSignInView.js|function:appleLogin|" + e, null, 1);
			}
		},

		checkRegistrationStatus: function (userDetails) {
			return new Promise(function (resolve, reject) {
				var requestUrl = (contextPath.indexOf('portal') > -1) ? contextPath + '/socialSignIn/isAppleRegisteredUser' : contextPath + '/portal/socialSignIn/isAppleRegisteredUser';
				var requestData = {
					"jwtToken": userDetails.id_token ? userDetails.id_token : '',
					"encAlgVersion" :"V4"
				};
				$("#ajax-loader").show();
				$("#ajax-loader-mask").show();
				$.ajax({
					type: "POST",
					url: requestUrl,
					data: JSON.stringify(requestData),
					dataType: 'json',
					contentType: 'application/json',
					success: function (data) {
						resolve(data);
					},
					error: function (xhr, ajaxOptions, thrownError) {
						reject(thrownError);
					}
				});
			})
		}
	});
	return appleSignInView;
});