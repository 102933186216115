
/* START_TEMPLATE */
define('hbs!tpl/loginRegister/loginRegister',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                                 <li >"
    + escapeExpression(lambda(depth0, depth0))
    + "</li>\n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "                        <div class=\"form-group formFieldParent   registrationPhoneConsent\" >\n                            <div class=\"col-xs-12 col-md-12 col-lg-12 required  paddingLeftRight10 inputBoxHeight\">\n                                <div class='input-box-container required'>\n                                    <span class=\"avtarImage phoneIcon glyphicon glyphicon-phone\" ></span>\n                                    <input type=\"text\" maxlength=\"10\" class=\"input-box-editor leanPhoneField\" name=\"phoneNumber\" maxlength=\"50\" id=\"leanPhoneNo\" iconName=\"phoneIcon\" autocomplete=\"new-phone\" error=\"errorLeanPhoneNo\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.phone : stack1), depth0))
    + "\"/>\n                                    <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.phone : stack1), depth0))
    + "</span>\n                                    <span class=\"input-box-active\"></span>\n                                    <span class=\"input-box-error\"></span>\n                                    <span class=\"input-box-success\"></span>\n                                    <div class=\"errorPH errorLeanPhoneNo\"></div> \n                                </div>\n                                \n                            </div>\n                        </div>\n                        <div class=\"form-group formFieldParent registrationPhoneConsent registrationPhoneConsentOption\">\n                            <div class=\"col-xs-12 col-md-12 col-lg-12 registrationPhoneConsent\">\n                                <div class=\"slider-switch \">\n                                    <div class=\"slider-status-text col-xs-12 col-md-12 col-lg-12\">\n                                    <input type=\"checkbox\" class=\"optInsms\" id=\"optInSMsRegistration\" name=\"optInSMsRegistration\" aria-label=\"optInSMsRegistration\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.consentLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                                    </div>\n                                   <!-- <label class=\"switch col-xs-6 col-md-7 col-lg-6\">\n                                        <span class=\"slider round\"></span>\n                                        <span class=\"no\">No</span>\n                                        <span class=\"yes\">Yes</span>\n                                    </label>-->\n                                </div>\n                            </div>    \n                        </div>\n                        <div class=\"registrationPhoneBlock\" style=\"display:none\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.consentTerms : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>                        \n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"modal-dialog \">\n    <div class=\"modal-content\">\n        <div class=\"modal-header popUpTitle\">\n            <div class=\"width85per robotoregular\"></div>\n            <div class=\"closeButton hidden-xs\" id=\"closeRegisterLogin\">&nbsp;</div>\n        </div>\n        <div class=\"modal-body robotoregular signupDivParent\">\n            <div class=\"container-fluid\" id=\"containerSignup\">\n                <div class=\"signupHead hidden-xs\">\n                    \n                    <ul>\n                        <li id=\"loginDiv\">\n                            ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.signin : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                        </li>\n                        <li class=\"active\" id=\"registerationDiv\">\n                            ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.register : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                        </li>\n                    </ul>\n                </div>\n                <div id=\"regLoginContent\">\n                    <button class=\"closeButton hidden-md hidden-sm hidden-lg closebuttonMobile\" title=\"close\" aria-label=\"close\" >\n                        <svg class=\"\" version=\"1.1\"  xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" enable-background=\"new 0 0 24 24\" xml:space=\"preserve\">\n                            <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" x1=\"2.9\" y1=\"21.2\" x2=\"20.7\" y2=\"3.3\"></line>\n                            <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" x1=\"20.7\" y1=\"21.2\" x2=\"2.9\" y2=\"3.3\"></line>\n                        </svg>\n                    </button>\n\n                    <div class=\"container-fluid registrationSec\" id=\"registrationForm\">\n                        <form class=\"form-horizontal userRegisterClass\" id=\"userLeanRegistration\" autocomplete=\"off\" onsubmit=\"return false\">\n\n                        <div class=\"form-group leanHeadParentAnonym hide\">\n                            ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.headStaticContent : depth0)) != null ? stack1.headingAnonym : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                        </div>\n                        <div class=\"form-group leanHeadParent\">\n                            <div class=\"col-xs-4 col-sm-4 col-md-4 registerHead\" >\n                              ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.headStaticContent : depth0)) != null ? stack1.heading1 : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                            </div>\n                            <div class=\"col-xs-8 col-sm-8 col-md-8 staticContent\">\n                            <ul>\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.headStaticContent : depth0)) != null ? stack1.content : stack1), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                            </div>\n                            </ul>\n                        </div>\n                        <div id=\"inputFormParent\">\n                        <div class=\"formFieldsParent\">\n                        <div class=\"form-group \" id=\"errorAll\">\n                            <div class=\"col-xs-12 col-sm-12 col-md-12\">\n                              <div class=\"errorPH  errorAll\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.errorMessages : depth0)) != null ? stack1.errorCommonTechnical : stack1), depth0))
    + "</div>\n                            </div>\n                        </div>\n                        <div class=\"form-group formFieldParent\">\n                            <div class=\"col-xs-12 col-md-6 col-lg-6 required errorLeanRegFirstNameParent paddingLeftRight10 inputBoxHeight\">\n                                <div class='input-box-container required'>\n                                    <span class=\"avtarImage nameIcon\"></span>\n                                    <input type=\"text\" class=\"input-box-editor name\" iconName=\"nameIcon\" name=\"firstName\" id=\"leanRegFirstName\" maxlength=\"40\" error=\"errorLeanRegFirstName\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\"/>\n                                    <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "</span>\n                                    <span class=\"input-box-active\"></span>\n                                    <span class=\"input-box-error\"></span>\n                                    <span class=\"input-box-success\"></span>\n                                    <div class=\"errorPH  errorLeanRegFirstName\"></div>\n                                </div>\n                            </div>\n                            <div class=\"col-xs-12 col-md-6 col-lg-6 required errorLeanRegLastNameParent paddingLeftRight10 inputBoxHeight\">\n                                 <div class='input-box-container required'>\n                                    <span class=\"avtarImage lastNameIcon\"></span>\n                                    <input type=\"text\" class=\"input-box-editor name\" iconName=\"lastNameIcon\"  id=\"leanRegLastName\" name=\"lastName\" maxlength=\"40\" error=\"errorLeanRegLastName\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\" />\n                                    <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</span>\n                                    <span class=\"input-box-active\"></span>\n                                    <span class=\"input-box-error\"></span>\n                                    <span class=\"input-box-success\"></span>\n                                    <div class=\"errorPH  errorLeanRegLastName\"></div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"form-group formFieldParent\">\n                            <div class=\"col-xs-12 col-md-12 col-lg-12 required errorLeanRegEmailIdParent paddingLeftRight10 inputBoxHeight\">\n                            \n                                <div class='input-box-container required'>\n                                    <span class=\"avtarImage emailIdIcon\"></span>\n                                    <input type=\"text\" class=\"input-box-editor\" id=\"emailId\" name=\"emailId\" iconName=\"emailIdIcon\"  maxlength=\"50\" error=\"errorLeanRegEmailId\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "\" title=\"Email\"/>\n                                    <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "</span>\n                                    <span class=\"input-box-active\"></span>\n                                    <span class=\"input-box-error\"></span>\n                                    <span class=\"input-box-success\"></span>\n                                    <div class=\"errorPH  errorLeanRegEmailId\"></div>\n                                    <div class=\"btn btn-default badge infotooltipPop \" id=\"emailToolTip\" data-container=\"body\" data-toggle=\"popover\" data-content=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.toolTipMessages : depth0)) != null ? stack1.emailToolTip : stack1), depth0))
    + "\" data-original-title=\"\" title=\"\">i</div>\n                                </div>\n                            </div>\n                        </div>\n                        <div class=\"form-group formFieldParent\">\n                            <div class=\"col-xs-12 col-md-12 col-lg-12 required errorLeanRegPasswordParent paddingLeftRight10 inputBoxHeight\">\n\n                                <div class='input-box-container required'>\n                                    <span class=\"avtarImage passwordIcon\"></span>\n                                    <input type=\"password\" class=\"input-box-editor\" name=\"password\" maxlength=\"50\" id=\"leanRegPassword\" iconName=\"passwordIcon\" autocomplete=\"new-password\" error=\"errorLeanRegPassword\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "\"/>\n                                    <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "</span>\n                                    <span class=\"input-box-active\"></span>\n                                    <span class=\"input-box-error\"></span>\n                                    <span class=\"input-box-success\"></span>\n                                    <div class=\"errorPH errorLeanRegPassword\"></div>\n                                    <div class=\"btn btn-default badge infotooltipPop lean-password-tooltip\" id=\"leanPasswordToolTip\" data-container=\"body\" data-toggle=\"popover\" data-content=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.toolTipMessages : depth0)) != null ? stack1.passWordToolTip : stack1), depth0))
    + "\" data-original-title=\"\" title=\"\">i</div>\n                                </div>\n                            </div>\n                        </div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.showConsentForm : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                        <div class=\"form-group submitRegisterButton\">\n                            \n                            <div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n                                <button type=\"button\" id=\"mobileSignInLabel\" class=\"btn btn-default hidden-lg hidden-md hidden-sm mobileSignInLabel btn-navigation\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.signin : stack1), depth0))
    + "</button>\n                                <button type=\"submit\" id=\"confirmLeanRegistration\" class=\"btn btn-primary\" >"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.register : stack1), depth0))
    + "</button>\n                            </div>\n                        </div>\n                         <div class=\"form-group termsAndCond\">\n                            \n                            <div class=\"col-xs-12 col-md-12 col-lg-12 \" >\n                                 ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.regTermsAndConditions : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                            </div>\n                        </div>\n                        </div>\n                        </div>\n                        </form>\n                        <div id=\"leanRegTooltip\"></div>\n                    </div>\n\n                    <div class=\"container-fluid registrationSec\" id=\"leanLoginFrom\">\n                        <form class=\"form-horizontal userRegisterClass\" id=\"userNewLogin\" onsubmit=\"return false\">\n                            <div class=\"login-default-message\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.loginTopContent : stack1), depth0))
    + "</div> \n                            <div class=\"login-form-error responseError\" ></div>\n                            <div class=\"login-form-Warning responseWarning\" id=\"loginFormWarning\"></div>\n                            \n                            <div class=\"formFieldsParent\">\n                                \n                                <div class=\"form-group formFieldParent\">\n                                    <div class=\"col-xs-12 col-md-12 col-lg-12 inputBoxHeight\">\n\n                                        <div class='input-box-container required'>\n                                            <span class=\"avtarImage emailIdIcon\"></span>\n                                            <input type=\"text\" class=\"input-box-editor\" id=\"loginEmailAddress\" name=\"emailId\" iconName=\"emailIdIcon\"  maxlength=\"50\" error=\"erroremailAddress\" title=\"Email\" autocomplete=\"off\"/>\n                                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "</span>\n                                            <span class=\"input-box-active\"></span>\n                                            <span class=\"input-box-error\"></span>\n                                            <span class=\"input-box-success\"></span>\n                                            <div class=\"errorPH  erroremailAddress\"></div>\n                                        </div>\n                                    </div>\n                                </div>\n                                <div class=\"form-group formFieldParent\">\n                                    <div class=\"col-xs-12 col-md-12 col-lg-12 inputBoxHeight\">\n                                        \n                                        <div class='input-box-container required'>\n                                            <span class=\"avtarImage passwordIcon\"></span>\n                                            <input type=\"password\" class=\"input-box-editor\" id=\"loginPassword\" name=\"password\" iconName=\"passwordIcon\"  maxlength=\"50\" error=\"errorpassword\" title=\"Password\" autocomplete=\"off\"/>\n                                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "</span>\n                                            <span class=\"input-box-active\"></span>\n                                            <span class=\"input-box-error\"></span>\n                                            <span class=\"input-box-success\"></span>\n                                            <span class=\"forgotPasswordLink\">\n                                                <a id=\"newForgotPassword\" href=\"javascript:void(0);\" class=\"newForgotPasswordLink\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.forgotPasswordLink : stack1), depth0))
    + "</a>\n                                            </span>\n                                            <div class=\"errorPH errorpassword\"></div>\n                                        </div>\n                                        \n                                    </div>\n                                </div>\n                                 <div class=\"form-group signinActions\">\n                                    <div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n                                        <button type=\"submit\" id=\"gobalSignInSubmit\" class=\"btn btn-primary\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.signin : stack1), depth0))
    + "</button>\n                                        <button type=\"button\" id=\"mobileRegisterLabel\" class=\"btn btn-default hidden-lg hidden-md hidden-sm mobileRegisterLabel btn-navigation\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.register : stack1), depth0))
    + "</button>\n                                    </div>\n                                </div>\n                            </div>\n                        </form>\n                    </div>\n\n                    <div class=\"container-fluid registrationSec\" id=\"leanForgotPassword\">\n                        <div class=\"forgotPasswordScreen\" id=\"forgotPasswordScreen\">\n                        <form class=\"form-horizontal userRegisterClass\" id=\"userNewPassword\" autocomplete=\"off\" onsubmit=\"return false\">\n                            \n                            <div class=\"formFieldsParent\">\n                                \n                                <div class=\"form-group \">\n                                    <div class=\"col-xs-12 col-md-12 col-lg-12  zeroPadding \">\n                                        <div class=\"form-group formTitle\">\n                                            <span>";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.changePasswordHead : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n                                        </div>\n                                    </div>\n                                </div>\n\n                                <div class=\"form-group alignLeft DescSection\">\n                                    <div class=\"col-xs-12 col-md-12 col-lg-12  paddingTopBottom15\">\n                                        <div class=\"form-group\">\n                                            <span>";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.changePasswordDescription : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</span>\n                                        </div>\n                                    </div>\n                                </div>\n\n                                <div class=\"password-form-error responseError\" id=\"password-form-error\"></div>\n                                    \n\n                                <div class=\"form-group formFieldParent paddingTopBottom15\">\n                                    <div class=\"col-xs-12 col-md-12 col-lg-12 inputBoxHeight\">\n\n                                        <div class='input-box-container required'>\n                                            <span class=\"avtarImage emailIdIcon\"></span>\n                                            <input type=\"text\" class=\"input-box-editor\" id=\"forgotPasswordEmailId\" name=\"emailId\" iconName=\"emailIdIcon\"  maxlength=\"50\" error=\"errorForgotPasswordEmailAddress\" title=\"Email\" autocomplete=\"off\"/>\n                                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "</span>\n                                            <span class=\"input-box-active\"></span>\n                                            <span class=\"input-box-error\"></span>\n                                            <span class=\"input-box-success\"></span>\n                                            <div class=\"errorPH  errorForgotPasswordEmailAddress\"></div>\n                                        </div>\n                                    </div>\n                                </div>\n                                 <div class=\"form-group signinActions paddingTopBottom15\">\n                                 \n                                    <div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n                                         <button type=\"submit\" id=\"globalPasswordSubmit\" class=\"btn btn-primary\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.submitButton : stack1), depth0))
    + "</button>\n                                    </div>\n                                </div>\n                            </div>\n                        </form>\n                        </div>\n\n                        <div id=\"forgotPasswordScreenConfirmation\" class=\"formFieldsParent\">\n                            \n                            <div class=\"form-group \">\n                            <div class=\"col-xs-12 col-md-12 col-lg-12  zeroPadding \">\n                                <div class=\"form-group formTitle\">\n                                    <span>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.changePasswordConfirmationHead : stack1), depth0))
    + "</span>\n                                </div>\n                            </div>\n                            </div>\n\n                            <div class=\"form-group \">\n                            <div class=\"col-xs-12 col-md-12 col-lg-12  paddingTopBottom15\">\n                                <div class=\"form-group\">\n                                    <span class=\"user-confirmation-message\" id=\"passwordConfirmationMessage\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.changePasswordConfirmationDescription : stack1), depth0))
    + "</span>\n                                </div>\n                            </div>\n                            </div>\n\n\n                            \n                             <div class=\"form-group signinActions paddingTopBottom15\">\n                                <div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n                                     <button type=\"submit\" id=\"globalPasswordClose\" class=\"btn btn-primary\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.closeButton : stack1), depth0))
    + "</button>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                    <div class=\"container-fluid registrationSec\"  id=\"mobile-verification\"></div>\n\n\n                </div>\n            </div>\n        </div>\n        \n    </div>\n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/loginRegister/loginRegister', t);
return t;
});
/* END_TEMPLATE */
;