
define('loginView',['require','jquery','underscore','backbone','GoogleAnalyticsUtils','commonutils','validator','loginRegisterView','newRegistrationView','newRegistrationView'],function (require) {
    'use strict';
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone");

    var GAUtils = require("GoogleAnalyticsUtils"),
        Utils = require("commonutils"),
        eventLabel = $.cookie('visitorId'),
        validator = require("validator");

    var loginRegisterView = require('loginRegisterView');

    var loginView = Backbone.View.extend({
        events: {
            "click .ga-login-email, .ga-login-password, .ga-forgot-email": "changeIcon",
            "keyPress .ga-login-email, .ga-login-password, .ga-forgot-email": "changeIcon",
            "focus .ga-login-email, .ga-login-password, .ga-forgot-email": "changeIcon",
            "blur .ga-login-email": "validateEmailField",
            "blur .ga-login-password": "validatePasswordField",
            "blur .ga-forgot-email": "validateForgotEmailField",
            "click .ga-login-submit": "login",
            "click .ga-forgot-password-link": 'showForgotPassword',
            "click .forgot-password-submit": "submitForgotPassword",
            "click .close-forgot-password": "showLogin",
            "click .back-to-login button": "showLogin"
        },
        initialize: function (optoins) {
            try{
                this.componentsCmsNode = saveListDrawerContent;
                this.networkCallInProgress = false;
                
            }catch(e){
                
            }
           
        },

        createCaptch: function() {
            try{
                if( this.componentsCmsNode.captchaEnable.enabled === "yes") {
                      if(!this.captchaAvailable){
                          try { 
                              var script = document.createElement('script');
                              script.type = 'text/javascript';
                              script.src = 'https://www.google.com/recaptcha/api.js?onload=captchaGCallback&render=explicit';
                              document.body.appendChild(script);
                          } catch (e) { 
                          }
                          this.captchaAvailable = true;
                      } else{
                          captchaGCallback();
                      }
                }
            }catch(e){}
        },

        showForgotPassword: function (e) {
            $(".ga-login-container").removeClass('active');
            $(".ga-register-container").removeClass('active');
            $(".ga-forgot-password-container").addClass('active');
            $(".ga-forgot-password-container .forgot-password-form").addClass('active');
            $(".ga-forgot-password-container .forgot-password-confirmation").removeClass('active');
            var registerView = require("newRegistrationView");
            registerView.clearValidationMessages(e);
        },
        showLogin: function(e){
            $(".ga-login-container").addClass('active');
            $(".ga-register-container").removeClass('active');
            $(".ga-forgot-password-container").removeClass('active');
            var registerView = require("newRegistrationView");
            registerView.clearValidationMessages(e);
        },
        changeIcon: function (e) {
            try {
                var iconName = $(e.target).attr('iconName');
                $('.' + iconName).addClass('clicked');
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Login", "file:loginView.js|function:changeIcon|" + e, null, 1);
            }
        },
        addErrorClass: function (elementObject, hasValue) {

            try {
                var closestNode = $(elementObject).closest('.input-box-container');
                $(closestNode).find('.input-box-error').addClass('input-label-error');
                $(closestNode).find('.input-box-success').removeClass('input-label-success');
                $('.' + $(elementObject).attr('error')).addClass('has-input-error');
                $(closestNode).find('.input-box-active').removeClass('input-box-progress');

                $('.' + $(elementObject).attr('iconname')).removeClass('clicked');
                $('.' + $(elementObject).attr('avtarImage')).removeClass('active');

                $(elementObject).addClass('hasInputError');

                if (hasValue) {
                    $(closestNode).find('.input-box-label').addClass('input-box-filled');
                } else {
                    $(closestNode).find('.input-box-label').removeClass('input-box-filled');
                }
                this.networkCallInProgress = false;
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Login", "file:loginView.js|function:addErrorClass|" + e, null, 1);
            }

        },
        addSuccessClass: function (elementObject) {
            try {
                var closestNode = $(elementObject).closest('.input-box-container');
                $(closestNode).find('.input-box-success').addClass('input-label-success');
                $(closestNode).find('.input-box-error').removeClass('input-label-error');
                $(closestNode).find('.input-box-label').addClass('input-box-filled');
                $('.' + $(elementObject).attr('error')).removeClass('has-input-error');
                $(closestNode).find('.input-box-active').removeClass('input-box-progress');
                $(elementObject).removeClass('hasInputError');

                $('.' + $(elementObject).attr('iconname')).removeClass('clicked');
                $('.' + $(elementObject).attr('avtarImage')).removeClass('active');

                this.networkCallInProgress = false;
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Login", "file:loginView.js|function:addSuccessClass|" + e, null, 1);
            }
        },
        addInprogressClass: function (elementObject) {
            var closestNode = $(elementObject).closest('.input-box-container');
            $(closestNode).find('.input-box-label').addClass('input-box-filled');
            $(closestNode).find('.input-box-active').addClass('input-box-progress');

            this.networkCallInProgress = true;
        },
        validateForgotEmailField: function (event) {
            try {
                var emailObject = $(event.target),
                    emailValue = $.trim(emailObject.val());

                emailObject.val(emailValue.toLowerCase());

                if (emailValue == '') {
                    $('.' + emailObject.attr('error')).html(this.componentsCmsNode.registration.errorMessages.loginEmailError);
                    this.addErrorClass(emailObject, false);
                } else if (!(validator.isEmail(emailValue, emailObject))) {
                    $('.' + emailObject.attr('error')).html(this.componentsCmsNode.registration.errorMessages.errorEmailId);
                    this.addErrorClass(emailObject, true);
                } else if (Utils.isDeletedUser(emailValue)) {
                    $('.' + emailObject.attr('error')).html(saveListDrawerContent.errorMessages.isDeletedEmailIdMessage);
                    this.addErrorClass(emailObject, true);
                } else {
                    this.addSuccessClass(emailObject);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Login", "file:loginView.js|function:validateForgotEmailField|" + e, null, 1);
            }
        },
        submitForgotPassword: function (e) {

            var self = this,
                emailObject = self.$el.find('.ga-forgot-email'),
                email = $.trim(emailObject.val()),
                forgotPasswordContainer = self.$el.find('.ga-forgot-password-container'),
                delayTime, showPasswordStatusLoader,
                resetPasswordUrl = (contextPath.indexOf('portal') > -1) ? contextPath + '/lean-regn/sendResetPasswordLink' : ctxPath + '/portal/lean-regn/sendResetPasswordLink';

            forgotPasswordContainer.find('.password-form-error').addClass('hide').removeClass('validationError');

            GAUtils.eventTracking.trackevent("New-Login", "Forgot Password Submit Click", eventLabel);

            emailObject.blur();

            if ( emailObject.hasClass('hasInputError') ) {
                emailObject.focus();
            } else {

                forgotPasswordContainer.find('.forgot-password-submit').attr('disabled', 'disabled');

                // Start loading after specified time
                delayTime = saveListDrawerContent.GlobalConfig.loaderDelay || 3000;
                showPasswordStatusLoader = setTimeout(function () {
                    $('#ajax-loader-mask').css('display', 'block');
                    $('#ajax-loader').css('display', 'block');
                }, delayTime);

                $.ajax({
                    type: "POST",
                    url: resetPasswordUrl,
                    data: JSON.stringify({
                        emailId: email
                    }),
                    dataType: 'json',
                    contentType: "application/json; charset=utf-8",
                    success: function (data) {

                        // Clear time outs - remove showStatusLoader from window
                        clearTimeout(showPasswordStatusLoader);

                        $('#ajax-loader-mask').css('display', 'none');
                        $('#ajax-loader').css('display', 'none');

                        if (data.status == "SUCCESS") {

                            GAUtils.eventTracking.trackevent("New-Login", "Forgot Password Click Success", eventLabel);
                            
                            forgotPasswordContainer.find('.forgot-password-form').removeClass('active');
                            var passwordConfirmationMessage = self.componentsCmsNode.registration.passwordLabel.changePasswordConfirmationDescription;
                            // var passwordConfigMessage = _.escape(passwordConfirmationMessage.replace('###emailId###', email));
                            forgotPasswordContainer.find('.passwordConfirmationMessage').text(passwordConfirmationMessage.replace('###emailId###', email));
                            forgotPasswordContainer.find('.forgot-password-confirmation').addClass('active');

                        } else {

                            GAUtils.eventTracking.trackevent("New-Login", "Forgot Password  Click Failure " + data.errorMessage, eventLabel);
                            var emailValidateErrorMsg = self.componentsCmsNode.registration.errorMessages.emailIdValidationError[data.errorMessage];
                            forgotPasswordContainer.find('.password-form-error').text(emailValidateErrorMsg);
                            forgotPasswordContainer.find('.password-form-error').removeClass('hide').addClass('validationError');

                        }
                        forgotPasswordContainer.find('.forgot-password-submit').prop('disabled', false);
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        $('#ajax-loader-mask').css('display', 'none');
                        $('#ajax-loader').css('display', 'none');
                        forgotPasswordContainer.find('.forgot-password-submit').prop('disabled', false);
                        GAUtils.eventTracking.trackevent("Error", "New-Login", "error:Sibmit forgot password|" + xhr.status, null, 1);
                    }
                });
            }

        },
        validateEmailField: function (e, callback) {
            try {
                var self = this,
                    emailObject = e && $(e.target) || self.$el.find('.ga-login-email'),
                    emailValue = $.trim(emailObject.val()),
                    postData = {
                        'email': emailValue
                    },
                    responseData,
                    emailUrl = ctxPath + '/portal/lean-regn/validateEmail';

                if (!(validator.isEmail(emailValue, emailObject))) {
                    self.$el.find('.ga-forgot-email').val('');
                } else {
                    self.$el.find('.ga-forgot-email').val(emailValue);
                    self.addSuccessClass(self.$el.find('.ga-forgot-email'))
                }

                /*If element is not visible, then return; If user saved his email/passwords that will prepopulate and trigger focusout event*/
                if (!emailObject.is(":visible")) {
                    if (emailValue) self.addSuccessClass(emailObject);
                    return;
                }
                
                if (emailValue == '') {

                    $('.' + $(emailObject).attr('error')).html(this.componentsCmsNode.registration.errorMessages.loginEmailError);
                    this.addErrorClass(emailObject, false);
                    if (callback) callback();

                } else {

                    this.addInprogressClass(emailObject);

                    $.ajax({
                        type: "POST",
                        url: emailUrl,
                        data: postData,
                        dataType: 'json',
                        success: function (data) {
                            if (data.status == "SUCCESS") {
                                self.addSuccessClass(emailObject);
                            } else {
                                self.addErrorClass(emailObject, true);
                                $('.' + $(emailObject).attr('error')).html(self.componentsCmsNode.errorMessages.loginErrors[data.errorMessage]);

                                /* Prefill email id in Registration */
                                if (data.errorMessage === 'NO_RECORDS_FROM_DB') {
                                    responseData = typeof data.data !== 'undefined' ? data.data : null;
                                    if (validator.isEmail($.trim($(emailObject).val()), emailObject)) {
                                        $('.ga-register-container .registerEmailId').val(emailValue);
                                        self.addSuccessClass($('.ga-register-container .registerEmailId'))
                                    }
                                    /* If user is subscribed user, prefill his details*/
                                    if (responseData && responseData.firstName && responseData.lastName) {
                                        $('.ga-register-container').find('input[name="lean-firstName"]').val(responseData.firstName);
                                        $('.ga-register-container').find('input[name="lean-lastName"]').val(responseData.lastName);
                                        self.addSuccessClass($('.ga-register-container').find('input[name="lean-firstName"]'))
                                        self.addSuccessClass($('.ga-register-container').find('input[name="lean-lastName"]'))
                                    }
                                }
                            }
                            if (callback) callback();
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            GAUtils.eventTracking.trackevent("Error", "New Lean Registration", "error:validate Password|" + xhr.status, null, 1);
                            if (callback) callback();
                        }
                    });

                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Login", "file:loginView.js|function:validateEmailField|" + e, null, 1);
            }
        },
        validatePasswordField: function (e) {
            try {
                var currentObject = e && $(e.target) || this.$el.find('.ga-login-password');

                if ($.trim(currentObject.val()) == '') {
                    $('.' + $(currentObject).attr('error')).html(this.componentsCmsNode.registration.errorMessages.errorLogin);
                    this.addErrorClass(currentObject, false);
                } else {
                    this.addSuccessClass(currentObject);
                }

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "New Login", "file:loginView.js|function:validatePasswordField|" + exception, null, 1);
            }
        },
        login: function (e) {
            try {

                var self = this;

                $('#globalRegisrationContainer .login-form-error.responseError').html('');
                $('#globalRegisrationContainer .login-form-error.responseError').hide();

                self.validateEmailField(null, function (response) {
                    self.validatePasswordField();
                    var passwordElem = self.$el.find('.ga-login-password'),
                        emailElem = self.$el.find('.ga-login-email'),
                        email = $.trim(emailElem.val()),
                        password = $.trim(passwordElem.val()),
                        dataObject = {
                            formObject: self.$el.selector,
                            email: email,
                            password: password,
                            viewObject: self,
                            callback: self.updateLoggedInStatus || null,
                            funnelStart: self.funnelStart || false,
                            srcName: self.srcName || false
                        };

                    if (!passwordElem.hasClass('hasInputError') && !emailElem.hasClass('hasInputError')) {
                        if( self.componentsCmsNode.captchaEnable.enabled === "yes") {
                            if(self.captchaAvailable) {
                              try{
                                var response = grecaptcha.getResponse(widgetId2);
                                if(response.length == 0) {
                                  $('#globalRegisrationContainer .login-form-error.responseError').html('An error has occurred, please try again.');
                                  $('#globalRegisrationContainer .login-form-error.responseError').show();
                                  $('#gobalSignInSubmit').prop('disabled', false);
                                  return false;
                                }
                              }catch(e){}
                            }
                        }
                        Utils.validateSigninData(dataObject);
                    } else {
                        self.$el.find('.hasInputError').first().focus();
                    }
                });
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "New Login", "file:loginView.js|function:login|" + exception, null, 1);
            }
        },
        updateLoggedInStatus: function (userResponse, viewObject) {

            userResponse = userResponse || {};
            var self = viewObject || this;

            if( userResponse.passwordExpired === 'Y' ){
                Utils.redirect("/portal/lean-regn/getChangeExpiredPasswordPage");
            } else if(userResponse.updatePassword === 'Y' ){
                Utils.redirect("/changePwd");
            } else {
                self.parentView.renderGlobalAlerts();
            }
        },
        render: function (options) {
            try {

                options = options || {};

                $(this.$el.selector + ' .input-box-editor').val('');

                var loginEmail = Utils.getCookieDetails('loggedInEmail') || null;
                if (loginEmail) this.showLogin({view: 'login'});

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Login", "file:loginView.js|function:render|" + e, null, 1);
            }
        }
    });


    return new loginView;
});

var widgetId2;
function captchaGCallback() {
  try {
      widgetId2 = grecaptcha.render('recaptchaGlobalPH', {
        'sitekey' : '6Lex9CIaAAAAADhemTu4p3PC0qRA1kcvGi7rz8R8',
        'theme' : 'light'
      });
  } catch(e) {}
}
;