
/* START_TEMPLATE */
define('hbs!tpl/globalIdologyVerification/verifyIdologyQues',['hbs','hbs/handlebars','templates/helpers/simpleFor','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "            <li class=\"";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, depth0, 1, {"name":"ifEqual","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + " step"
    + escapeExpression(lambda(depth0, depth0))
    + " stepParent\">\n              <span class=\"step\" attr=\"step1\"><span class=\"spanContent\">"
    + escapeExpression(lambda(depth0, depth0))
    + "</span><span class=\"spanContentDone hide\">&nbsp;</span></span>\n            </li>\n";
},"2":function(depth0,helpers,partials,data) {
  return "active ";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"verifyQuesPopup\"> \n    <div class=\"listItems\">\n      <ol class=\"idologyProgress\" data-steps=\"4\">\n";
  stack1 = ((helpers.simpleFor || (depth0 && depth0.simpleFor) || helperMissing).call(depth0, 1, ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.questions : stack1)) != null ? stack1.length : stack1), 1, {"name":"simpleFor","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      </ol>\n    </div>\n    <div class=\"quesAnsGroup\">      \n    <form id=\"idologyQuestion\">\n        <div class=\"form-group  hide errorAll\" >\n          <div class=\"col-xs-12 col-sm-12 col-md-12\">\n            <div class=\"errorPH  errorAll errorMobile\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.errorMsgCMS : depth0)) != null ? stack1.errorCommonTechnical : stack1), depth0))
    + "</div>\n          </div>\n        </div>\n         <div class=\"form-group\">\n          <div class=\"errorPH hide errorIdologyMessages errorMobile\"></div>\n          <div class=\"errorPH hide errorIdologyQuestionUnchecked errorMobile\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.errorMsgCMS : depth0)) != null ? stack1.idologyErrorMessages : stack1)) != null ? stack1.errorIdologyQuestionUnchecked : stack1), depth0))
    + "</div>\n          \n        </div>\n        <div  class=\"hide quesContinueAfterError\"><button  class=\"btn btn-primary continueAfterError\" type=\"button\">Continue</button> </div>\n        <div class=\"quesDiv\">\n        \n\n       </div>\n    \n    </form>\n</div>\n<div class=\"form-group submitDoit\">\n    <div class=\"col-xs-4 col-sm-4  quesPrev alignCenter\">\n        <a href=\"javascript:void(0)\" class=\"maskButtonEvents verifyIdologyQuesprev\" currQuesId=\"1\" currProgressId=\"1\" totalCount=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.questions : stack1)) != null ? stack1.length : stack1), depth0))
    + "\"> &lt;Previous </a>\n      </div>\n      <div class=\" col-xs-4 col-sm-4  quesSubmit alignCenter\">\n          <button type=\"button\"  class=\"btn btn-primary maskButtonEvents verifyIdologySubmit\" currQuesId=\"0\" currProgressId=\"1\" totalCount=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.questions : stack1)) != null ? stack1.length : stack1), depth0))
    + "\">Submit</button>\n      </div>\n      <div class=\"col-xs-4 col-sm-4 quesNext alignCenter\">\n        <a href=\"javascript:void(0)\"  class=\"maskButtonEvents verifyIdologyQuesNext\" currQuesId=\"0\" currProgressId=\"1\" totalCount=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.questions : stack1)) != null ? stack1.length : stack1), depth0))
    + "\"> Next &gt; </a>\n      </div>\n      \n    </div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('tpl/globalIdologyVerification/verifyIdologyQues', t);
return t;
});
/* END_TEMPLATE */
;