
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/successTuple',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<div class=\"placebid-success\">\n	<span class=\"glyphicon glyphicon-remove remove-success-pane\" data-open-tuple=\""
    + escapeExpression(((helper = (helper = helpers.tupleStatus || (depth0 != null ? depth0.tupleStatus : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"tupleStatus","hash":{},"data":data}) : helper)))
    + "\"  data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" aria-hidden=\"true\"></span>\n	<div class=\"placebid-success-icon\"></div>\n	<div class=\"placebid-success-head\">\n		"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.globalAlertsCmsNode : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.successNotificationLabel : stack1), depth0))
    + "\n	</div>\n	<div class=\"placebid-success-desc\">\n		";
  stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n	</div>\n	<div class=\"placebid-error-desc\">\n		";
  stack1 = ((helper = (helper = helpers.errorMessage || (depth0 != null ? depth0.errorMessage : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"errorMessage","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n	</div>\n</div>\n\n";
},"useData":true});
Handlebars.registerPartial('tpl/globalAlerts/successTuple', t);
return t;
});
/* END_TEMPLATE */
;