
/* START_TEMPLATE */
define('hbs!tpl/buyerDashboard/errorMessagePopup',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "<div class=\"modal fade mobileFullBleed\" id=\"signInModal\" role=\"dialog\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header popUpTitle\">\n                <div id=\"modalHeader\" class=\"width85per\">Error</div>\n            </div>\n            <div class=\"modal-body\">\n                <div id=\"unAuthAccess\" class=\"marginB10 hide\">\n                    <div class=\"col-xs-12\">You are not authorized to view this page. You will be redirected to home page in 20 seconds. Click Ok to go to home page.</div>\n                </div>\n                <div id=\"profileInc\" class=\"marginB10 hide\">\n                    <div class=\"col-xs-12\">Your profile is not complete. You will be redirected to your dashboard home page in 20 seconds. Click Ok to go to dashboard page.</div>\n                </div>\n                <div id=\"otherUser\" class=\"marginB10 hide\">\n                    <div class=\"col-xs-12\">It seems you have logged in with a different user credentials hence we are redirecting you to your dashboard home page in 20 seconds. Click ok to go to your dashboard page.</div>\n                </div>\n            </div>\n            <div class=\"clearfix\"></div>\n            <div class=\"modal-footer marginT10\">\n                <button type=\"button\" id=\"goToPage\" class=\"btn btn-default\">Ok</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
  },"useData":true});
Handlebars.registerPartial('tpl/buyerDashboard/errorMessagePopup', t);
return t;
});
/* END_TEMPLATE */
;