
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/saveSearchTuple',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<li class=\"row ga-tuple-item ga-save-search-tuple "
    + escapeExpression(((helper = (helper = helpers.searchCriteraId || (depth0 != null ? depth0.searchCriteraId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchCriteraId","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"col-xs-2 col-sm-2 col-md-2 col-lg-2\">\n        <div class=\"save-search-icon\"></div>\n    </div>\n    \n    <div class=\"col-xs-10 col-sm-10 col-md-10 col-lg-10\" data-search-name="
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.saveSearchResponse : depth0)) != null ? stack1.searchName : stack1), depth0))
    + " data-criteria-id=\""
    + escapeExpression(((helper = (helper = helpers.searchCriteraId || (depth0 != null ? depth0.searchCriteraId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchCriteraId","hash":{},"data":data}) : helper)))
    + "\">\n        <a href=\"";
  stack1 = ((helper = (helper = helpers.saveSearchUrl || (depth0 != null ? depth0.saveSearchUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchUrl","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" aria-label=\"Save Search "
    + escapeExpression(((helper = (helper = helpers.saveSearchTitle || (depth0 != null ? depth0.saveSearchTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchTitle","hash":{},"data":data}) : helper)))
    + "\" data-search-name=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.saveSearchResponse : depth0)) != null ? stack1.searchName : stack1), depth0))
    + "\" class=\"save-seach-link save-search-container\" data-criteria-id=\""
    + escapeExpression(((helper = (helper = helpers.searchCriteraId || (depth0 != null ? depth0.searchCriteraId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchCriteraId","hash":{},"data":data}) : helper)))
    + "\">\n\n            <div class=\"save-search-head\" data-search-name="
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.saveSearchResponse : depth0)) != null ? stack1.searchName : stack1), depth0))
    + " data-criteria-id=\""
    + escapeExpression(((helper = (helper = helpers.searchCriteraId || (depth0 != null ? depth0.searchCriteraId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchCriteraId","hash":{},"data":data}) : helper)))
    + "\">\n                "
    + escapeExpression(((helper = (helper = helpers.saveSearchTitle || (depth0 != null ? depth0.saveSearchTitle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchTitle","hash":{},"data":data}) : helper)))
    + "\n            </div>\n            <div class=\"save-search-desc\" data-search-name="
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.saveSearchResponse : depth0)) != null ? stack1.searchName : stack1), depth0))
    + " data-criteria-id=\""
    + escapeExpression(((helper = (helper = helpers.searchCriteraId || (depth0 != null ? depth0.searchCriteraId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchCriteraId","hash":{},"data":data}) : helper)))
    + "\">\n                ";
  stack1 = ((helper = (helper = helpers.saveSearchDesc || (depth0 != null ? depth0.saveSearchDesc : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchDesc","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n            </div>\n        </a>\n    </div>\n    \n</li>";
},"useData":true});
Handlebars.registerPartial('tpl/globalAlerts/saveSearchTuple', t);
return t;
});
/* END_TEMPLATE */
;