
define('GoogleAnalyticsUtils',['jquery'],function($) {
	'use strict';
	var GAUtils = {};
	GAUtils.eventTracking = {
			trackevent: function(category, action, optional_label, callback,opt_noninteraction){
				/*
				 * Simply checks that the loaded property exists 
				 * and that it has a value of true. 
				 */
				
		        if (category == null || category == ''){
					category = this.recognizePageType();
				}
				/**
				 * pass event details to Native
				 */
				// if (window.notifyNativeApp && typeof allowedGaEvents !== "undefined" && allowedGaEvents.length) {
				// 	if (allowedGaEvents.indexOf(action) > -1 ) {
				// 		window.notifyNativeApp(JSON.stringify({
				// 			type: "action",
				// 			message: "logGaEvent",
				// 			category: category,
				// 			action: action,
				// 			label: optional_label
				// 		}), '*');
				// 	}
				// }
				
				if(checkGaLoaded()){
					if(isUaEnabled!=undefined && isUaEnabled){
						ga('set', 'hitCallback', function(){
							if (callback!=undefined && callback!=null && $.trim(callback)!='' ){
								if(callback instanceof Function) {
									callback();
									callback = null;
								}
							}
						});
					}else{
						_gaq.push(['_set', 'hitCallback', function(){
							if (callback!=undefined && callback!=null && $.trim(callback)!='' ){
								if(callback instanceof Function) {
									callback();
									callback = null;
								}
							}
						}]);
					}
					var params = params || '';
					optional_label = optional_label + params;
						if(opt_noninteraction!=undefined && opt_noninteraction!=null && opt_noninteraction!=''&& opt_noninteraction==1){
							if(isUaEnabled!=undefined && isUaEnabled){
								ga('send', 'event', category, action, optional_label,{nonInteraction: true});
								this.logWebViewEvent(category, action, optional_label);
							}else{
								_gaq.push(['_trackEvent', category, action, optional_label,null,true]);
							}
							
						}else{
							if(isUaEnabled!=undefined && isUaEnabled){
								ga('send', 'event', category, action, optional_label);
								this.logWebViewEvent(category, action, optional_label);

							}else{
								_gaq.push(['_trackEvent', category, action, optional_label]);
							}
							
						}
					} else {
						if (callback!=null && callback!=undefined && $.trim(callback)!=''){
							if(callback instanceof Function) {
								callback();
								callback = null;
							}
						}
					}	
			    if (this.logGAEvents && window.console && window.console.log){
		    		// print to console
				    window.console.log("GA._trackEvent('"+category+"','"+action+"','"+optional_label+"')");
			    	
			    }
				
			return true;
			},
		logWebViewEvent : function(category, action, optional_label){

			try{
			
			if (window.logWebviewEvent && !(category.toLowerCase().replaceAll(' ','').indexOf('globalalert')>-1 && category.toLowerCase().replaceAll(' ','').indexOf('savelist') && category.toLowerCase() == 'error')) {
									
									
				const userPostData = {
					type: "logEvent",
					data: {
						category: category,
						action: action,
						label: optional_label,
					}
				};
				console.log("user Post",userPostData)
				window.logWebviewEvent(JSON.stringify(userPostData), "*");

			}
		}catch(e){
			console.log('webview');
			return;
		}
		},
		recognizePageType : function(){
			var pageType =  (typeof isHubzu2Enabled != 'undefined' && isHubzu2Enabled === 'Y') ? "Home Page 2.0" : "New-Homepage"
			var currentPath = window.location.pathname;
			var currentParamSearch = window.location.search;
			if(currentPath.match(".*/property/.*") && (globalParam.config.isAllowedPreSale)){
		        pageType = "NEW-PDP";
		    }else if (currentPath.match(".*/property/.*")){
				pageType = "NEW-PDP";
				
			}else if((currentPath.match(".*/searchResult")) && (currentParamSearch.toLowerCase().match(".*marketingphase=prelist"))){
				pageType = "SRP-WFCDOCS";
			}else if((currentPath.match(".*/searchResult")) || (currentPath.match(".*/luxury-homes-for-sale"))){
				pageType = "NEW-SRP";
			}else if (currentPath.match(".*/auctions")){
				pageType = "NEW-SRP";
			}else if (currentPath.match("\.+com(/)?$") || currentPath.match(".*/home") || currentPath == '/'){
				pageType = "New-Homepage";
			}else if (currentPath.match("\.+com(/)?$") || currentPath.match(".*/bid") || currentPath == '/'){
				pageType = "New-Bid Wizard";
			}else if (currentPath.match("\.+com(/)?$") || currentPath.match(".*/offer") || currentPath == '/'){
				pageType = "New-Offer Wizard";
			}else if (currentPath.match("\.+com(/)?$") || currentPath.match(".*/create-account") || currentPath == '/'){
				pageType = "New-Registration";
			}else if (currentPath.match("\.+com(/)?$") || currentPath.match(".*/user-profile") || currentPath == '/'){
				pageType = "New-Registration";
			}else{
				var ind = currentPath.lastIndexOf("/");
				if (ind > 0){
					if(currentPath.substring(ind + 1)!=''){
						pageType = currentPath.substring(ind + 1);
					}
				}else{
					if(currentPath.substring(ind + 1)){
						pageType = currentPath;
					}
				}
				
			}
			if($("body").hasClass("h-srp-page")){
				pageType = "NEW-SRP";
			}
			return pageType;
		},
		processLinkAndTrackPVandEvent : function(linkData, pageview, category, action, optional_label, http_config) {
		
			var callback = function() {
				if(checkGaLoaded()){
					_gaq.push(['_set', 'hitCallback', function() {callback1();}]);
					_gaq.push(['_trackPageview', pageview]);
				} else {
					callback1();
				}
			}
			
			var callback1 = function() {
	
				if(http_config != '')
					window.location = http_config + this.resolveLink(linkData);	
				else
					window.location = this.resolveLink(linkData);	
			}
			if(isUaEnabled){
						
			}else{
				this.trackEvent(category, action, optional_label, function(){
					callback();
				});
			}
				
			return false;
		},
		processTrackPV : function(linkData, pageview, category, action, optional_label, http_config) {
		
			if(checkGaLoaded()){
					linkData = linkData.replace(window.location.host,'');
					linkData=	 linkData.replace(window.location.protocol+"//", "");
					if(isUaEnabled){
						ga('send', 'pageview', linkData+pageview);
					}else{
						_gaq.push(['_trackPageview', linkData+pageview]);
					}
				}	
			return false;
		},
		processTrackVPV : function( pageview) {
			if(checkGaLoaded()){
					if(isUaEnabled){
						ga('send', 'pageview', pageview);
					}else{
						_gaq.push(['_trackPageview', pageview]);
					}
				}	
			return false;
		},
		processLinkAndTrackEvent : function(linkData, category, action, optional_label, http_config) {
			var obj=this;
			this.trackevent(category, action, optional_label, function(){obj.callBack(linkData,http_config);});
			return false;
		},
	
		preventLinkAndTrackEvent : function(event, linkData, category, action, optional_label, http_config) {
			if(event.ctrlKey) {
				return false;//Ctrl+Click
			}
			if(event.which === 2) {
				return false;
			}
			event.preventDefault();
			this.trackEvent(category, action, optional_label, function(){this.callBack(linkData,http_config);});
			return false;
		},
		callBack : function(linkData,http_config){
			if(http_config != ''){
				window.location = http_config + this.resolveLink(linkData);	
			}else{
				window.location = this.resolveLink(linkData);
			}
		},
		resolveLink : function(linkData){
			var link = linkData; 
			var appendContextPath = false;
			if (linkData!=null && linkData.linkValue){
				link = linkData.linkValue;
			}
			if (linkData!=null && linkData.appendContextPath){
				appendContextPath = linkData.appendContextPath;
			}	
			var linkValue = link;
			if (appendContextPath == true){
				linkValue = contextPath +  link;
			}
			return linkValue;
		}	
	};
	var checkGaLoaded = function() {
		if(typeof isUaEnabled!='undefined' && isUaEnabled){
			return (typeof ga!=='undefined' && ga!==undefined && ga.hasOwnProperty('loaded') && ga.loaded === true);
		}else{
			return (typeof ga!=='undefined' && ga!==undefined && ga.hasOwnProperty('loaded') && ga.loaded === true && window._gat);
		}
		
	}
	
	return GAUtils;
});

