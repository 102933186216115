
define('Footerview',['require','jquery','backbone'],function (require) {
    "use strict";

    var $ = require("jquery"),
        Backbone = require("backbone"),
        FooterView;

        FooterView = Backbone.View.extend({
        events: {
            'click .view-more':'showHide',
            
        },
        initialize: function () {
            //some initialization scripts
            var thisObj = this;
        },
        showHide:function(e){
            var event = e.currentTarget,
                parentId= $(event).attr('referAttr');
            if($(event).hasClass('open')){
                $(event).removeClass('open');
                $('.'+parentId).find('.hide-block').removeClass('show-it').addClass('hide-it');
            }else{
                $('.'+parentId).find('.hide-block').removeClass('hide-it').addClass('show-it');
                $(event).addClass('open');

            }
        }
    });
    return FooterView;
});