
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/alertTupleNewSkin',['hbs','hbs/handlebars', 'hbs!templates/globalAlerts/loaderIcon','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "expired-alert";
  },"3":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "			    <div class=\"ga-buyer-email bemail-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUPBID", {"name":"ifEqual","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">"
    + escapeExpression(((helper = (helper = helpers.buyerEmailId || (depth0 != null ? depth0.buyerEmailId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"buyerEmailId","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"4":function(depth0,helpers,partials,data) {
  return " hide ";
  },"6":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					<span class=\"ga-alert\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[1] != null ? depths[1].content : depths[1])) != null ? stack1.labels : stack1)) != null ? stack1.auctionEndedLabel : stack1), depth0))
    + "</span> \n";
},"8":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "					<span class=\"ga-alert\">"
    + escapeExpression(((helper = (helper = helpers.message || (depth0 != null ? depth0.message : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"message","hash":{},"data":data}) : helper)))
    + "</span>\n";
},"10":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "				Your most recent bid was $"
    + escapeExpression(((helper = (helper = helpers.formattedLastBidAmount || (depth0 != null ? depth0.formattedLastBidAmount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"formattedLastBidAmount","hash":{},"data":data}) : helper)))
    + ". "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.currentHighestBid : stack1), depth0))
    + " $"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedCurrentHighestBid : stack1), depth0))
    + "\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "DRIVEN_BID_WATCHER_ALERT", {"name":"ifEqual","hash":{},"fn":this.program(13, data),"inverse":this.program(15, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "						"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.drivenBidDescription : stack1), depth0))
    + "\n";
},"15":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(16, data),"inverse":this.program(18, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "							"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.backupBidExpiryDescription : stack1), depth0))
    + "\n";
},"18":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "							Your most recent bid was $"
    + escapeExpression(((helper = (helper = helpers.formattedLastBidAmount || (depth0 != null ? depth0.formattedLastBidAmount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"formattedLastBidAmount","hash":{},"data":data}) : helper)))
    + "\n";
},"20":function(depth0,helpers,partials,data) {
  return "	            	<svg width=\"28px\" height=\"24px\" viewBox=\"0 0 28 24\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n					    <g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n					        <g transform=\"translate(-1096.000000, -325.000000)\" fill=\"#F25D49\" fill-rule=\"nonzero\" stroke=\"#FFFFFF\" stroke-width=\"2.4\">\n					            <g id=\"icon/heart/empty\" transform=\"translate(1098.000000, 327.000000)\">\n					                <path d=\"M12,20 C12,20 2.54165691e-16,14.9733526 0,6.32492594 C2.54165689e-16,2.88140419 2.86628215,3.92113855e-16 6.58932599,0 C9.80453838,0 11.8776186,3.18814787 12,3.33005567 C12.1234274,3.18814787 14.1997792,1.14491746e-15 17.475898,0 C21.1989419,1.14491749e-15 24,2.88140419 24,6.32492594 C24.0000002,15.4749297 12,20 12,20 Z\" id=\"Favourite-Copy\"></path>\n					            </g>\n					        </g>\n					    </g>\n					</svg>\n";
  },"22":function(depth0,helpers,partials,data) {
  return "						<div class=\"ga-bid-amount pull-left\"></div>\n";
  },"24":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(25, data),"inverse":this.program(27, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  return "							\n";
  },"27":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "							<div class=\"ga-bid-amount pull-left ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(28, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n								<span class=\"current-bid robotomedium\">$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedCurrentHighestBid : stack1), depth0))
    + "</span>\n\n								<label class=\"robotoregular hide-details hide\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.currentHighestBid : stack1), depth0))
    + "</label>\n							</div>\n";
},"28":function(depth0,helpers,partials,data) {
  return "hide";
  },"30":function(depth0,helpers,partials,data) {
  return "";
},"32":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(25, data),"inverse":this.program(33, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"33":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "								<div class=\"timer hide-details\" data-timeLeft=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.auctionTimeLeft : stack1), depth0))
    + "\">\n									<svg width=\"14px\" height=\"14px\" viewBox=\"0 0 14 14\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n										<g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n											<g transform=\"translate(-1301.000000, -326.000000)\" fill=\"#2A69A3\">\n												<g id=\"label/countdown\" transform=\"translate(1295.000000, 321.000000)\">\n													<g id=\"icon/clock\" transform=\"translate(6.000000, 5.000000)\">\n														<path d=\"M7,0 C10.8659932,0 14,3.13400675 14,7 C14,10.8659932 10.8659932,14 7,14 C3.13400675,14 0,10.8659932 0,7 C0,3.13400675 3.13400675,0 7,0 Z M7,1.4 C3.9072054,1.4 1.4,3.9072054 1.4,7 C1.4,10.0927946 3.9072054,12.6 7,12.6 C10.0927946,12.6 12.6,10.0927946 12.6,7 C12.6,3.9072054 10.0927946,1.4 7,1.4 Z M7.7,3.43106321 C8.05898509,3.43106321 8.35485501,3.70129135 8.39529059,4.04942843 L8.4,4.13106321 L8.4,8.4 L4.2,8.4 C3.81340068,8.4 3.5,8.08659932 3.5,7.7 C3.5,7.34101491 3.77022813,7.04514499 4.11836521,7.00470941 L4.2,7 L7,7 L7,4.13106321 C7,3.77207813 7.27022813,3.4762082 7.61836521,3.43577263 L7.7,3.43106321 Z\" id=\"Combined-Shape\"></path>\n													</g>\n												</g>\n											</g>\n										</g>\n									</svg>\n									<span class=\"timer-value\">"
    + escapeExpression(((helper = (helper = helpers.timer || (depth0 != null ? depth0.timer : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"timer","hash":{},"data":data}) : helper)))
    + "</span>\n								</div>\n";
},"35":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "						<div class=\"ga-button\">\n							<a data-bidtype=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" data-listingurl=\""
    + escapeExpression(((helper = (helper = helpers.listingUrl || (depth0 != null ? depth0.listingUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingUrl","hash":{},"data":data}) : helper)))
    + "\"  data-listingid=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" href=\"javascript:void(0)\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.placeBidLabelTitleCase : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"ga-place-bid robotoregular place-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" >"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.placeBidLabelTitleCase : stack1), depth0))
    + "</a> \n						</div>\n";
},"37":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUPBID", {"name":"ifEqual","hash":{},"fn":this.program(38, data),"inverse":this.program(40, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"38":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "							<div class=\"ga-button\">\n								<a data-bidtype=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" href=\"javascript:void(0)\" title=\"Update Validity\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"ga-place-bid ga-place-backup-bid robotoregular place-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" >"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.upadteValidityCTA : stack1), depth0))
    + "</a> \n							</div>\n";
},"40":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(41, data),"inverse":this.program(43, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"41":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "								<div class=\"ga-button\">\n									<a data-bidtype=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" href=\"javascript:void(0)\" title=\"Update Validity\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"ga-place-bid ga-place-backup-bid robotoregular place-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" >"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.upadteValidityCTA : stack1), depth0))
    + "</a> \n								</div>\n";
},"43":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "								<div class=\"ga-bid-plus-minus pull-left hide\">\n									<span class=\"bid-minus glyphicon glyphicon-minus bid-minus-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-auto-bid=\""
    + escapeExpression(((helper = (helper = helpers.autoBidExperimentEnable || (depth0 != null ? depth0.autoBidExperimentEnable : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"autoBidExperimentEnable","hash":{},"data":data}) : helper)))
    + "\"></span>\n									<input type=\"text\" value=\"$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedNextpossibleBidAmount : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"15\" autocomplete=\"off\"  aria-label=\"bidAmount\" title=\"Bid Amount\" class=\"robotomedium ga-bid-amount-custom bid-val-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\">\n									<span class=\"bid-plus glyphicon glyphicon-plus bid-plus-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-auto-bid=\""
    + escapeExpression(((helper = (helper = helpers.autoBidExperimentEnable || (depth0 != null ? depth0.autoBidExperimentEnable : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"autoBidExperimentEnable","hash":{},"data":data}) : helper)))
    + "\"></span>\n								</div>\n								<div class=\"ga-button\">\n									<a href=\"javascript:void(0)\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.placeBidLabelTitleCase : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"ga-place-bid robotoregular place-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-auto-bid=\""
    + escapeExpression(((helper = (helper = helpers.autoBidExperimentEnable || (depth0 != null ? depth0.autoBidExperimentEnable : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"autoBidExperimentEnable","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.placeBidLabelTitleCase : stack1), depth0))
    + "</a> \n								</div>\n";
},"45":function(depth0,helpers,partials,data) {
  return "backup-view-details";
  },"47":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.specialFeatures : depth0), {"name":"each","hash":{},"fn":this.program(48, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"48":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, depth0, "OWN_IT_NOW_ENABLED", {"name":"ifEqual","hash":{},"fn":this.program(49, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"49":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "						<div class=\"ga-property ga-ownitnow-container ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depths[2] != null ? depths[2].messageStatus : depths[2]), "expired", {"name":"ifEqual","hash":{},"fn":this.program(28, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n							<div class=\"divider\">\n								<div class=\"line-divider\"></div>\n								<div class=\"or-divider\">or</div>\n							</div>\n							<div class=\"content\">\n							<button aria-label=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[3] != null ? depths[3].content : depths[3])) != null ? stack1.labels : stack1)) != null ? stack1.ownItNowLabel : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(lambda((depths[2] != null ? depths[2].messageId : depths[2]), depth0))
    + "\" data-ownitnow-amount=\""
    + escapeExpression(lambda((depths[2] != null ? depths[2].buyItNowPrice : depths[2]), depth0))
    + "\" class=\"btn btn-primary ga-place-bid\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[3] != null ? depths[3].content : depths[3])) != null ? stack1.labels : stack1)) != null ? stack1.ownItNowLabel : stack1), depth0))
    + "</button> \n							<span class=\"ownitnow-amount\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[3] != null ? depths[3].content : depths[3])) != null ? stack1.labels : stack1)) != null ? stack1.forLabel : stack1), depth0))
    + " $"
    + escapeExpression(lambda((depths[2] != null ? depths[2].buyItNowFormattedPrice : depths[2]), depth0))
    + "</span>\n							</div>\n						</div>\n";
},"51":function(depth0,helpers,partials,data) {
  return " ";
  },"53":function(depth0,helpers,partials,data) {
  return " gray-border-bottom ";
  },"55":function(depth0,helpers,partials,data) {
  var stack1, buffer = "						<div class=\"padding\">\n							<div class=\"required\">\n								<select class=\"selectBox paymentSelect h-select-box\" id=\"backupBidDaysList\" aria-label=\"If I'm not the selected bidder, hold my bid as a backup for\" error=\"backupBidDayError\">\n									<option value=\"\">Select duration</option>\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.backUpBidExpiryDaysList : depth0), {"name":"each","hash":{},"fn":this.program(56, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "								</select>\n								<span class=\"backupBidDayError h-select-box-error\">Please select an option</span>\n							</div>\n						</div>\n";
},"56":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "										<option value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\"> "
    + escapeExpression(lambda(depth0, depth0))
    + " Days</option>\n";
},"58":function(depth0,helpers,partials,data) {
  var stack1, helper, helperMissing=helpers.helperMissing, functionType="function", escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "						<div class=\"col-md-5 col-sm-5 col-lg-5 col-xs-6 padding\">\n							<div class=\"ga-bid-plus-minus pull-left normal-price\" >\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUPBID", {"name":"ifEqual","hash":{},"fn":this.program(59, data),"inverse":this.program(61, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "							</div>\n							<div class=\"ga-bid-plus-minus pull-left own-itnow-price hide ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" >\n								<span class=\"robotomedium ownitnow-total ownitnow-amount-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedNextpossibleBidAmount : stack1), depth0))
    + "</span>\n							</div>	\n						</div>\n";
},"59":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "									<input type=\"text\" value=\"\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"12\"  aria-label=\"bidAmount\" title=\"Bid amount\" autocomplete=\"off\" class=\"robotomedium ga-bid-amount-custom bid-val-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " backup-bid-input\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\">\n";
},"61":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "									<span class=\"confirm-bid-btn bid-minus glyphicon glyphicon-minus bid-minus-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-auto-bid=\""
    + escapeExpression(((helper = (helper = helpers.autoBidExperimentEnable || (depth0 != null ? depth0.autoBidExperimentEnable : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"autoBidExperimentEnable","hash":{},"data":data}) : helper)))
    + "\"></span>\n									<input type=\"text\" value=\"$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedNextpossibleBidAmount : stack1), depth0))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" maxlength=\"15\"  aria-label=\"bidAmount\" title=\"Bid amount\" autocomplete=\"off\" class=\"robotomedium ga-bid-amount-custom bid-val-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\">\n									<span class=\"confirm-bid-btn bid-plus glyphicon glyphicon-plus bid-plus-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-auto-bid=\""
    + escapeExpression(((helper = (helper = helpers.autoBidExperimentEnable || (depth0 != null ? depth0.autoBidExperimentEnable : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"autoBidExperimentEnable","hash":{},"data":data}) : helper)))
    + "\"></span>\n";
},"63":function(depth0,helpers,partials,data) {
  return "						<div class=\"backup-bid-label\"></div>\n";
  },"65":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "				<div class=\"row black-border-bottom buyer-premium-container ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n				<div class=\"col-md-6 col-sm-6 col-lg-6 col-xs-6  padding\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depths[1] != null ? depths[1].content : depths[1])) != null ? stack1.labels : stack1)) != null ? stack1.buyerPremium : stack1), depth0))
    + " <span class=\"ga-bp-percentage\"></span></div>\n					<div class=\"col-md-5 col-sm-5 col-lg-5 col-xs-5  padding ga-bp-value\"></div>\n				</div>\n";
},"67":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "			<div class=\"h-auto-bid-details pull-left auto-bid-section-global-alerts\">\n	            <div class=\"auto-bid-container\">\n	                <div class=\"auto-bid-label\">\n	                    <div class=\"head\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.headLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n	                    <div class=\"desc\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.descLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n	                </div>\n	                <div class=\"row paddingTopBottom\">\n	                    <div class=\"col-xs-12\">\n	                        <div class=\"slider-switch\">\n	                            <div class=\"auto-bid-label-text\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.autoBidLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n	                            <label class=\"switch\"> \n	                                <input type=\"checkbox\" name=\"enableAutoBidding\" aria-label=\"enable auto bid\" class=\"enable-auto-bid\" id=\"enable-auto-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n	                                <span class=\"slider round\"></span>\n	                                <span class='yes'>Yes</span>\n	                                <span class='no'>No</span>\n	                            </label>\n	                        </div>\n	                    </div>\n	                </div>\n	                <div id=\"autoBidSectionGA-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"autoBidSectionGA\">\n	                    <div class=\"row b-autobid-div paddingTopBottom\"> \n	                        <div class=\"col-xs-6 col-lg-7 col-md-7 col-sm-7 autoBidLimit bidLabelTxt\">\n	                            <label class=\"text-black\" for=\"autoBidLimit\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.autoBidLimitLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</label>\n	                        </div>\n	                        <div class=\"col-xs-6 col-lg-5 col-md-5 col-sm-5\">\n	                            <input type=\"text\" data-root=\"buyer\" data-auto-bid=\"\" aria-label= \"enable auto bid\" title=\"enable auto bid\" data-name=\"middleInitials\" rel=\"b_auto_bid_amount_error\" data-validator=\"name\" class=\"h-textbox amountField b_autobid_amount fontsize11Mob\" id=\"b_autobid_amount-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\"  maxlength=\"50\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-state=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.state : stack1), depth0))
    + "\">\n	                            <span class=\"h-textbox-error b_auto_bid_amount_error\"></span>\n	                        </div>\n	                    </div>\n	                    <div class=\"row b-auto-bid-bp-value ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.noBuyersPremium : depth0), "Y", {"name":"ifEqual","hash":{},"fn":this.program(28, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n	                        <div class=\"col-xs-6 col-lg-7 col-md-7 col-sm-7 paddingRight0\">\n	                        <label class=\"text-normal\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.buyerPremLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "<span id=\"autoBidBPPerc-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\"></span>\n	                        </label>\n	                        </div>\n	                        <div class=\"col-xs-6 col-lg-5 col-md-5 col-sm-5 semiBold b-autobid-bp-text fontsize11Mob\" id=\"b-autobid-bp-text-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\"></div>\n	                    </div>\n	                    <div class=\"row paddingTopBottom\">\n	                        <div class=\"col-xs-6 col-lg-7 col-md-7 col-sm-7\">\n	                            <label class=\"text-normal graphik-medium\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.autoBid : stack1)) != null ? stack1.maximumdue : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</label>\n	                        </div>\n	                        <div class=\"col-xs-6 col-lg-5 col-md-5 col-sm-5 semiBold b-autobid-total-text graphik-medium fontsize11Mob\" id=\"b-autobid-total-text-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\"></div>\n	                    </div>\n	                </div>\n	            </div>\n	        </div>\n";
},"69":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "				<button data-bidtype=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"ga-confirm-bid robotoregular confirm-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" >"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.upadteValidityCTA : stack1), depth0))
    + "</button>\n";
},"71":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda;
  return "				<button data-bidtype=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"ga-confirm-bid robotoregular confirm-bid-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" >"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.confirmBidLabelTitleCase : stack1), depth0))
    + "</button>\n";
},"73":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "				<input type=\"hidden\" name=\"previousBidEmailId\" value=\""
    + escapeExpression(((helper = (helper = helpers.buyerEmailId || (depth0 != null ? depth0.buyerEmailId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"buyerEmailId","hash":{},"data":data}) : helper)))
    + "\" >				\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<li class=\"row ga-tuple-item "
    + escapeExpression(((helper = (helper = helpers.propertyId || (depth0 != null ? depth0.propertyId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"propertyId","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " "
    + escapeExpression(((helper = (helper = helpers.messageStatus || (depth0 != null ? depth0.messageStatus : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageStatus","hash":{},"data":data}) : helper)))
    + " ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-min-bid=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.nextpossibleBidAmount : stack1), depth0))
    + "\" data-isactivebidrefinementenabled = \""
    + escapeExpression(((helper = (helper = helpers.isActiveBidRefinementEnabled || (depth0 != null ? depth0.isActiveBidRefinementEnabled : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"isActiveBidRefinementEnabled","hash":{},"data":data}) : helper)))
    + "\" data-lowerThresholdAmount = \""
    + escapeExpression(((helper = (helper = helpers.lowerThresholdAmount || (depth0 != null ? depth0.lowerThresholdAmount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"lowerThresholdAmount","hash":{},"data":data}) : helper)))
    + "\" data-lowerThresholdIncrementMultiplier = \""
    + escapeExpression(((helper = (helper = helpers.lowerThresholdIncrementMultiplier || (depth0 != null ? depth0.lowerThresholdIncrementMultiplier : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"lowerThresholdIncrementMultiplier","hash":{},"data":data}) : helper)))
    + "\" data-upperThresholdAmount = \""
    + escapeExpression(((helper = (helper = helpers.upperThresholdAmount || (depth0 != null ? depth0.upperThresholdAmount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"upperThresholdAmount","hash":{},"data":data}) : helper)))
    + "\" data-upperThresholdIncrementMultiplier = \""
    + escapeExpression(((helper = (helper = helpers.upperThresholdIncrementMultiplier || (depth0 != null ? depth0.upperThresholdIncrementMultiplier : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"upperThresholdIncrementMultiplier","hash":{},"data":data}) : helper)))
    + "\" data-max-bid=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.maximumPossibleBidAmount : stack1), depth0))
    + "\" data-bid-min-inc=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.bidIncrementMultiplier : stack1), depth0))
    + "\"  data-bid-inc=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.biddingIncrementalAmount : stack1), depth0))
    + "\" \ndata-highest-bid=\"$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.currentHighestBid : stack1), depth0))
    + "\" \ndata-start-bid=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.startingBidAmount : stack1), depth0))
    + "\" \ndata-list-price=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.listPrice : stack1), depth0))
    + "\" \ndata-prop-category=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.propertyCategory : stack1), depth0))
    + "\" \ndata-listing-id=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-listingUrl=\""
    + escapeExpression(((helper = (helper = helpers.listingUrl || (depth0 != null ? depth0.listingUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingUrl","hash":{},"data":data}) : helper)))
    + "\" data-no-buyers-premium=\""
    + escapeExpression(((helper = (helper = helpers.noBuyersPremium || (depth0 != null ? depth0.noBuyersPremium : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"noBuyersPremium","hash":{},"data":data}) : helper)))
    + "\"\ndata-buyer-prem-amount=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.buyerPremimum : stack1), depth0))
    + "\"\ndata-streetNumber=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetNumber : stack1), depth0))
    + "\"\ndata-streetName=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetName : stack1), depth0))
    + "\"\ndata-city=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.city : stack1), depth0))
    + "\"\ndata-county=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.county : stack1), depth0))
    + "\"\ndata-state=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.state : stack1), depth0))
    + "\" \ndata-zip=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.zip : stack1), depth0))
    + "\"\ndata-last-bid=\""
    + escapeExpression(((helper = (helper = helpers.lastBidAmount || (depth0 != null ? depth0.lastBidAmount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"lastBidAmount","hash":{},"data":data}) : helper)))
    + "\"\ndata-next-bid-amount=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.nextpossibleBidAmount : stack1), depth0))
    + "\"\ndata-bid-submitter-role=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.bidSubmitterRole : stack1), depth0))
    + "\" data-buyer-email=\""
    + escapeExpression(((helper = (helper = helpers.buyerEmailId || (depth0 != null ? depth0.buyerEmailId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"buyerEmailId","hash":{},"data":data}) : helper)))
    + "\">\n\n\n		<div class=\"tuple-data-collapse pull-left alertTuple\">\n\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userType : depth0), "AGENT", {"name":"ifEqual","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "			<div class=\"ga-bid-confirm-msg-container hide\">\n				<div class=\"ga-bid-confirm-msg\"></div>\n			</div>\n			<div class=\"ga-property-alerts\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(6, data, depths),"inverse":this.program(8, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "			</div>\n			<div class=\"recent-bid-text\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(10, data, depths),"inverse":this.program(12, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "			</div>\n			<div class=\"ga-tuple-item ga-thumb pull-left\">\n            <a href=\""
    + escapeExpression(((helper = (helper = helpers.listingUrl || (depth0 != null ? depth0.listingUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingUrl","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" title=\"Property image\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.favouritedProperty : depth0), true, {"name":"ifEqual","hash":{},"fn":this.program(20, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                <img src=\""
    + escapeExpression(((helper = (helper = helpers.imageUrl || (depth0 != null ? depth0.imageUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"imageUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"img-responsive\" alt=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetNumber : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetName : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.city : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.state : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.zip : stack1), depth0))
    + "\"  onerror=\"javascript:this.src='"
    + escapeExpression(((helper = (helper = helpers.contextPath || (depth0 != null ? depth0.contextPath : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"contextPath","hash":{},"data":data}) : helper)))
    + "/www/images/image-not-available.jpg'\" alt=\"Global Alerts Image\"/>\n                </a>\n			</div>\n			<div class=\"ga-property pull-left ga-property-address\">\n				<div class=\"ga-property-details pull-left\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUPBID", {"name":"ifEqual","hash":{},"fn":this.program(22, data, depths),"inverse":this.program(24, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "					<div class=\"ga-timer pull-right robotomedium\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUPBID", {"name":"ifEqual","hash":{},"fn":this.program(30, data, depths),"inverse":this.program(32, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "						<div class=\"close-details hide\">\n                            <span class=\"hide-view-container\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" aria-hidden=\"true\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\">\n                            	<svg class=\"drop-down-icon\" width=\"12px\" height=\"6px\" viewBox=\"0 0 12 6\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n\n			                    <g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n            		              <g transform=\"translate(-860.000000, -50.000000)\" fill=\"#464646\">\n			                            <g>\n			                                <g transform=\"translate(823.000000, 44.000000)\">\n			                                    <polygon id=\"Triangle\" transform=\"translate(43.000000, 9.000000) scale(1, -1) translate(-43.000000, -9.000000) \" points=\"43 6 49 12 37 12\"></polygon>\n			                                </g>\n			                            </g>\n			                        </g>\n			                    </g>\n			                </svg>\n			              </span>\n\n                        </div>\n					</div>\n					<div class=\"ga-address robotomedium\">\n                    	<a href=\""
    + escapeExpression(((helper = (helper = helpers.listingUrl || (depth0 != null ? depth0.listingUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingUrl","hash":{},"data":data}) : helper)))
    + "\" class=\"full-address\" target=\"_blank\" title=\"Property address\">\n                            "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetNumber : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.streetName : stack1), depth0))
    + ","
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.city : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.state : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyAddress : depth0)) != null ? stack1.zip : stack1), depth0))
    + "\n                        </a>\n					</div>\n				</div>\n				\n\n				<div class=\"ga-cta ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.messageStatus : depth0), "expired", {"name":"ifEqual","hash":{},"fn":this.program(28, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "DRIVEN_BID_WATCHER_ALERT", {"name":"ifEqual","hash":{},"fn":this.program(35, data, depths),"inverse":this.program(37, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "					<div class=\"ga-view-details hide-details\">\n                    	<a href=\"javascript:void(0)\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.viewDetails : stack1), depth0))
    + "\"  class=\"ga-view-details-link ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUPBID", {"name":"ifEqual","hash":{},"fn":this.program(45, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(45, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.viewDetails : stack1), depth0))
    + "</a>\n                    </div>\n				</div>	\n				<div class=\"ga-error-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " ga-error-bid\"></div>\n\n			</div>\n\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.hubzuConfig : stack1)) != null ? stack1.enableOwnitnow : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(47, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n			<div class=\"confirm-bid-expand pull-left ga-cta\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n				<div class=\"close-action\">\n					<span class=\"glyphicon glyphicon-remove close-confirm-details\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" aria-hidden=\"true\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\"></span>\n				</div>\n				<div class=\"row ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(51, data, depths),"inverse":this.program(53, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += " confirm-bid-actions\">\n					<div class=\"col-md-6 col-sm-6 col-lg-6 col-xs-6 padding ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n						<div class=\"bid-amount-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.bidAmountLabel : stack1), depth0))
    + "</div>\n						<div class=\"own-itnow-label hide\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.ownItNowAmountLabel : stack1), depth0))
    + "</div>\n					</div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(55, data, depths),"inverse":this.program(58, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUPBID", {"name":"ifEqual","hash":{},"fn":this.program(63, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "				</div>\n				<div class=\"row gray-border-bottom technology-fee-container ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n				<div class=\"col-md-6 col-sm-6 col-lg-6 col-xs-6  padding\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.techFeeLable : stack1), depth0))
    + " <span class=\"ga-tech-percentage\"></span></div>\n					<div class=\"col-md-5 col-sm-5 col-lg-5 col-xs-5  padding ga-techfee-value\"></div>\n				</div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.noBuyersPremium : depth0), "N", {"name":"ifEqual","hash":{},"fn":this.program(65, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "				<div class=\"row total-amount ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n					<div class=\"col-md-6 col-sm-6 col-lg-6 col-xs-6 padding \">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.totalDue : stack1), depth0))
    + "</div>\n					<div class=\"col-md-5 col-sm-5 col-lg-5 col-xs-5 padding ga-bid-totalDue\"></div>\n				</div>	\n				<div class=\"backup-no-buyers-premium-msg\"></div>\n				<div class=\"ga-error-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " ga-error-bid pull-left\"></div>\n			</div>\n\n			<div class=\"loader-inner-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + " loader-icon\">\n";
  stack1 = this.invokePartial(partials['templates/globalAlerts/loaderIcon'], '				', 'templates/globalAlerts/loaderIcon', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "			</div>\n\n		</div>\n\n\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.autoBidExperimentEnable : depth0), "Y", {"name":"ifEqual","hash":{},"fn":this.program(67, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <div class=\"action-button pull-left new-confirm-edit\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "BACKUP_BID_EXPIRY", {"name":"ifEqual","hash":{},"fn":this.program(69, data, depths),"inverse":this.program(71, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "			<a href=\"javascript:void(0);\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.editDetails : stack1), depth0))
    + "\" class=\"ga-edit-bid-details\" data-message=\""
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" data-listingId=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.editDetails : stack1), depth0))
    + "</a>\n		</div>\n		\n\n\n		<div class=\"tuple-data-expand pull-left\">\n			\n			<div class=\"ga-bid-details robotoregular row ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.type : depth0), "DRIVEN_BID_WATCHER_ALERT", {"name":"ifEqual","hash":{},"fn":this.program(4, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n				<div class=\"ga-bid-label col-lg-3 col-md-3 col-sm-3 col-xs-4\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.yourLastBid : stack1), depth0))
    + "</div>\n				<div class=\"ga-last-bid col-lg-4 col-md-4 col-sm-4 col-xs-3 robotomedium\">$"
    + escapeExpression(((helper = (helper = helpers.formattedLastBidAmount || (depth0 != null ? depth0.formattedLastBidAmount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"formattedLastBidAmount","hash":{},"data":data}) : helper)))
    + "</div>\n				<div class=\"gh-prop-tags reserve-met robotoregular col-lg-5 col-md-5 col-sm-5 col-xs-5 hide\">\n                    <span class=\"glyphicon glyphicon-ok-sign ga-tick-mark\" aria-hidden=\"true\"></span>\n                <span class=\"gh-reserve-met\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.reserveMetTag : stack1), depth0))
    + "</span>\n				</div>\n			</div>	\n			<div class=\"ga-bid-details robotoregular row\">\n			<div class=\"ga-bid-label col-lg-3 col-md-3 col-sm-3 col-xs-4\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.currentHighestBid : stack1), depth0))
    + "</div>\n				<div class=\"current-bid col-lg-4 col-md-4 col-sm-4 col-xs-3 robotomedium\">$"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.formattedCurrentHighestBid : stack1), depth0))
    + "</div>\n				<div class=\"gh-prop-tags robotoregular col-lg-5 col-md-5 col-sm-5 col-xs-5\">\n					<span class=\"gh-bid-count\">0</span>\n                <span>"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.bids : stack1), depth0))
    + "</span> \n				</div>\n			</div>\n			<div class=\"ga-bid-attr-details robotoregular row\">\n				<div class=\"ga-time-left robotomedium col-lg-3 col-md-3 col-sm-3 col-xs-3\">\n					<span class=\"ga-time-left-counter\"></span>\n					<span class=\"ga-time-left-counter-desc\"></span>\n				</div>\n				\n				<div class=\"ga-attributes col-lg-3 col-md-3 col-sm-3 col-xs-3\">\n					<span class=\"address\">\n						<span class=\"bed-icon pdp-sprite\"></span>\n						"
    + escapeExpression(((helper = (helper = helpers.bedCount || (depth0 != null ? depth0.bedCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bedCount","hash":{},"data":data}) : helper)))
    + "\n					<span class=\"robotothin ga-attr-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.beds : stack1), depth0))
    + "</span>\n					</span> \n				</div>\n				<div class=\"ga-attributes col-lg-3 col-md-3 col-sm-3 col-xs-3\">\n					<span class=\"address\">\n						<span class=\"bath-icon pdp-sprite\"></span>\n						"
    + escapeExpression(((helper = (helper = helpers.bathCount || (depth0 != null ? depth0.bathCount : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"bathCount","hash":{},"data":data}) : helper)))
    + "\n					<span class=\"robotothin ga-attr-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.baths : stack1), depth0))
    + "</span>\n					</span>\n				</div>\n				<div class=\"ga-attributes col-lg-3 col-md-3 col-sm-3 col-xs-3\">\n					<span class=\"address\">\n						<span class=\"size-icon pdp-sprite\"></span>\n						"
    + escapeExpression(((helper = (helper = helpers.areaSqurFeet || (depth0 != null ? depth0.areaSqurFeet : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"areaSqurFeet","hash":{},"data":data}) : helper)))
    + "\n					<span class=\"robotothin ga-attr-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.sqft : stack1), depth0))
    + "</span>\n					</span>\n				</div>\n				\n			</div>\n			\n		</div>\n\n		<form name=\"bid-form-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" class=\"bid-form-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\" action=\""
    + escapeExpression(((helper = (helper = helpers.contextPath || (depth0 != null ? depth0.contextPath : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"contextPath","hash":{},"data":data}) : helper)))
    + "/portal/bid?ref=globalalerts\" method=\"post\">\n			<input type=\"hidden\" name=\"bidType\" value=\"PLACE_BID\" class=\"hidden-bid-form-bidtype-"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n			<input type=\"hidden\" name=\"bidAmount\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.bidCenterAlertDetails : depth0)) != null ? stack1.nextpossibleBidAmount : stack1), depth0))
    + "\" class=\"bidValue"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userType : depth0), "AGENT", {"name":"ifEqual","hash":{},"fn":this.program(73, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "			<input type=\"hidden\" name=\"listingId\" value=\""
    + escapeExpression(((helper = (helper = helpers.listingId || (depth0 != null ? depth0.listingId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"listingId","hash":{},"data":data}) : helper)))
    + "\" class=\"listingId"
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n		</form>\n\n</li>";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/globalAlerts/alertTupleNewSkin', t);
return t;
});
/* END_TEMPLATE */
;