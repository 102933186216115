
/* START_TEMPLATE */
define('hbs!tpl/saveDrawer/watchedPropTemp',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"row propDetails propDetailsDrawer\" id=\"watch";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n	<div class=\"col-md-4 col-lg-4 col-xs-4 col-sm-4 img\">\n		<a href=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\" target=\"_blank\" currentpropid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"  class=\"pdpLink pdpLinkFromDrawer\" pg=\"1\" tabindex=\"-1\">\n		<img src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.imageDetails : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.imageStoragePath : stack1), depth0))
    + "\" alt=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.state : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.zip : stack1), depth0))
    + "\" onerror=\"javascript:this.src= '"
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/www/images/no-photo.jpg'\" width=\"100%\" height=\"100%\">\n		</a>\n		\n		<div class=\"abs home-sprite noPhotos\">";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.imageDetails : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.imageCount : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n	</div>\n	<div class=\"col-md-8 col-lg-8 col-xs-8 col-sm-8\">\n		<div class=\"row addressWhole\">\n			<div class=\"col-xs-12  address \">\n			<a class=\"truncate streetAddress pdpLinkFromDrawer\" target=\"_blank\" href=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\" currentpropid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" tabindex=\"-1\" href=\"javascript:void(0)\">\n				";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " <br>\n					<span class=\"stateCity\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.state : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.zip : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n			</a>\n			</div>\n			<a class=\"closeicon deleteWatchProperty\" propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" forWatch=\"Y\" tabindex=\"-1\" aria-label=\"Delete Watch ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" href=\"javascript:void(0)\"></a>\n		</div>\n		<div class=\"row propTypeDiv\">\n			<div class=\"col-xs-6 propType\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.subType : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n			</div>\n			<!--<div class=\"col-xs-6 timeLeft ";
  stack1 = ((helper = (helper = helpers.fontColorStyle || (depth0 != null ? depth0.fontColorStyle : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fontColorStyle","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">";
  stack1 = ((helper = (helper = helpers.dispTimeLeft || (depth0 != null ? depth0.dispTimeLeft : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"dispTimeLeft","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n			</div>-->\n		</div>\n		<div class=\"row\">\n			<div class=\"col-xs-4 bedsBaths\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.bedroomsNum : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " Beds\n			</div>\n			<div class=\"col-xs-4 bedsBaths borderLeft\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.bathsNum : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " Baths\n			</div>\n			<div class=\"col-xs-4 bedsBaths borderLeft\">";
  stack1 = ((helper = (helper = helpers.displayPrice || (depth0 != null ? depth0.displayPrice : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"displayPrice","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n			</div>\n		</div>\n	</div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, blockHelperMissing=helpers.blockHelperMissing;
  stack1 = ((helper = (helper = helpers.propertyDetails || (depth0 != null ? depth0.propertyDetails : depth0)) != null ? helper : helperMissing),(options={"name":"propertyDetails","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.propertyDetails) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/saveDrawer/watchedPropTemp', t);
return t;
});
/* END_TEMPLATE */
;