
/* START_TEMPLATE */
define('hbs!tpl/globalIdologyVerification/verifyIdology',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "\n<div class=\"form-group\">\n<div class=\"showToast col-xs-12 col-sm-12 col-md-12\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.errorMsgCMS : depth0)) != null ? stack1.IdologyVerificationRequiredMsg : stack1), depth0))
    + "</div>\n</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"form-group profileIntroMessage robotomedium  col-xs-12 col-sm-12 col-md-12 col-lg-12\">\n    <p class=\"profileIntroText robotoregular description alert alert-warning\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.idologyDisplayContentPopup : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</p>\n  </div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.userBasicDetails : depth0)) != null ? stack1.showToast : stack1), "true", {"name":"ifEqual","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n<div class=\"verifyIdologyDiv\">\n<form class=\"form-horizontal userFormIdology userIdology\"  onsubmit=\"return false\">\n\n    <div class=\"form-group\">\n        <div class=\"profile-head\">\n            Idology Verification\n        </div>\n    </div>\n\n    <div class=\"form-group  hide errorAllParent\" >\n      <div class=\"col-xs-12 col-sm-12 col-md-12\">\n        <div class=\"errorPH  errorAll\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.errorMsgCMS : depth0)) != null ? stack1.errorCommonTechnical : stack1), depth0))
    + "</div>\n      </div>\n    </div>\n\n    <div class=\"form-group required\">\n      <div class=\"col-xs-12 col-sm-12 col-md-12 divHeightPopup\">\n        <div class='input-box-container required'>\n          <span class=\"avtarImage nameIcon glyphicon glyphicon-user\"></span>\n          <input type=\"text\" class=\"input-box-editor idologyFirstName\" maxlength=\"40\"  avtarImage=\"nameIcon\" postId=\"IdologyFirstName\" error=\"errorfirstName\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.userBasicDetails : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologyFirstName : stack1), depth0))
    + "\" />\n          <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologyFirstName : stack1), depth0))
    + "</span>\n          <span class=\"input-box-active\"></span>\n          <span class=\"input-box-error\"></span>\n          <span class=\"input-box-success\"></span>\n          <div class=\"errorPH hide errorfirstName\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.errorMsgCMS : depth0)) != null ? stack1.errorFirstName : stack1), depth0))
    + "</div>\n          <input type=\"hidden\" class=\"form-control emailId \" maxlength=\"50\" id=\"idologyEmailId\" postId=\"IdologyEmailId\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.userBasicDetails : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "\">\n        </div>\n      </div>\n    </div>\n\n    <div class=\"form-group required\">\n      <div class=\"col-xs-12 col-sm-12 col-md-12 divHeightPopup\">\n        <div class='input-box-container required'>\n          <span class=\"avtarImage lastNameIcon glyphicon glyphicon-user\"></span>\n          <input type=\"text\" class=\"input-box-editor idologyLastName\" maxlength=\"40\"  avtarImage=\"lastNameIcon\" postId=\"IdologyLastName\" error=\"errorlastName\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.userBasicDetails : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologyLastName : stack1), depth0))
    + "\"/>\n          <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologyLastName : stack1), depth0))
    + "</span>\n          <span class=\"input-box-active\"></span>\n          <span class=\"input-box-error\"></span>\n          <span class=\"input-box-success\"></span>\n          <div class=\"errorPH hide errorlastName\"></div>\n        </div>\n      </div>\n    </div>\n\n    <div class=\"form-group required\">\n      <div class=\"col-xs-12 col-sm-12 col-md-12 divHeightPopup\">\n        <div class='input-box-container required'>\n          <span class=\"avtarImage dobIcon glyphicon glyphicon-user\"></span>\n          <input type=\"text\" class=\"input-box-editor dob idologyDob\" error=\"errorDob\" avtarImage=\"dobIcon\" postId=\"IdologyDOB\" value=\"\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologyDob : stack1), depth0))
    + "\"/>\n          <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologyDob : stack1), depth0))
    + "</span>\n          <span class=\"input-box-active\"></span>\n          <span class=\"input-box-error\"></span>\n          <span class=\"input-box-success\"></span>\n          <div class=\"errorPH hide errorDob\"></div>\n        </div>\n      </div>\n    </div>\n\n    <div class=\"form-group required\">\n      <div class=\"col-xs-12 col-sm-12 col-md-12 divHeightPopup\">\n        <div class='input-box-container required'>\n          <span class=\"avtarImage ssnIcon glyphicon glyphicon-lock\"></span>\n          <input type=\"text\" class=\"input-box-editor ssn idologySsn\" maxlength=\"4\"  avtarImage=\"ssnIcon\" postId=\"IdologySSN\" error=\"errorSsn\" value=\"\" title=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologySSN : stack1), depth0))
    + "\"/>\n          <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologySSN : stack1), depth0))
    + "</span>\n          <span class=\"input-box-active\"></span>\n          <span class=\"input-box-error\"></span>\n          <span class=\"input-box-success\"></span>\n          <div class=\"errorPH hide errorSsn\"></div>\n        </div>\n      </div>\n    </div>\n\n          <div class=\"form-group\">\n            <div class=\"col-xs-12 col-sm-12 col-md-12\">\n              <div class=\"errorPH hide errorIdologyMessages\"></div>\n            </div>\n          </div>\n          <div class=\"form-group\">\n            <div class=\"col-xs-12 col-sm-12 col-md-12\">\n              <div class=\"errorPH hide errorIdologyAttemptExceeded\"></div>\n            </div>\n          </div>\n          <div class=\"form-group submitDoit\">\n            <div class=\"col-md-4 col-md-offset-4 submitButtonIdology\">\n              <button type=\"submit\" class=\"btn btn-primary confirmBuyerIdologyDetails\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idologyVerify : stack1), depth0))
    + "</button>\n            </div>\n        </form>\n      </div>\n    </div>\n  </div>\n</div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('tpl/globalIdologyVerification/verifyIdology', t);
return t;
});
/* END_TEMPLATE */
;