
/* START_TEMPLATE */
define('hbs!templates/loginRegister/forgotPasswordNewSkin',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"container-fluid padding_zero\">\n    <div class=\"forgotPasswordScreen forgot-password-form\">\n        <div class=\"back-to-login\">\n            <button title=\"Back to login screen\">\n                <svg version=\"1.1\" class=\"goto-login-button\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" viewBox=\"0 0 9 24\" style=\"rotate: -180deg;height: 15px;width: 15px;\" xml:space=\"preserve\">\n                <style type=\"text/css\">\n                    .back-btn-svg { fill:#2f86f3; }\n                </style>\n                <polygon class=\"back-btn-svg\" points=\"1.1,0 8.9,11.9 1.1,24 0,24 7.9,12 0.2,0 \"></polygon>\n                </svg>\n                Back\n            </button>\n        </div>\n        <form class=\"form-horizontal\" autocomplete=\"off\" onsubmit=\"return false\">\n            <div class=\"formFieldsParent \">\n                <div class=\"form-group \">\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 zeroPadding padding_zero\">\n                        <div class=\"form-group ga-head\">\n                            <span>";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.changePasswordHead : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"form-group\">\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 paddingTopBottom15 forgot-password-desc\">\n                            ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.changePasswordDescription : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                    </div>\n                </div>\n                <div class=\"form-group error-text\">\n                    <div class=\"password-form-error responseError\"></div>\n                </div>\n                <div class=\"form-group formFieldParent paddingTopBottom15\">\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 inputBoxHeight\">\n\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage emailIdIcon\"></span>\n                            <input type=\"text\" class=\"input-box-editor ga-forgot-email\" name=\"emailId\"\n                                iconName=\"emailIdIcon\" maxlength=\"50\" error=\"errorForgotPasswordEmailAddress\" title=\"Email\"\n                                autocomplete=\"off\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "\"/>\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH  errorForgotPasswordEmailAddress\"></div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"form-group signinActions paddingTopBottom15\">\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n                        <button type=\"submit\"  class=\"btn btn-primary forgot-password-submit\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.submitButton : stack1), depth0))
    + "</button>\n                    </div>\n                </div>\n            </div>\n        </form>\n    </div>\n    <div class=\"formFieldsParent  forgot-password-confirmation\">\n        <div class=\"form-group \">\n            <div class=\"col-xs-12 col-md-12 col-lg-12  zeroPadding \">\n                <div class=\"form-group ga-head\">\n                    <span>"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.changePasswordConfirmationHead : stack1), depth0))
    + "</span>\n                </div>\n            </div>\n        </div>\n\n        <div class=\"form-group \">\n            <div class=\"col-xs-12 col-md-12 col-lg-12  paddingTopBottom15\">\n                <div class=\"form-group\">\n                    <span class=\"passwordConfirmationMessage\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.changePasswordConfirmationDescription : stack1), depth0))
    + "</span>\n                </div>\n            </div>\n        </div>\n        <div class=\"form-group signinActions paddingTopBottom15\">\n            <div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n                <button type=\"submit\" class=\"btn btn-primary close-forgot-password\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.closeButton : stack1), depth0))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('templates/loginRegister/forgotPasswordNewSkin', t);
Handlebars.registerPartial('templates/loginRegister/forgotPasswordNewSkin', t);
return t;
});
/* END_TEMPLATE */
;