
/* START_TEMPLATE */
define('hbs!tpl/confirmNegotiationModal',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "     <div class=\"row buyerPremium buyerPremiumRow\">\n          <div class=\"col-xs-6 col-sm-4 property\">\n              "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.buyersPremium : stack1), depth0))
    + " <span class=\"buyerPremiumPercentage\"></span>\n          </div>\n          <div class=\"col-xs-6 col-sm-8 propertyValue\" id=\"buyerPremium\">\n              \n          </div>\n     </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"popUpInformation\">    \n  <div class=\"bestBidDiv\">\n\n      \n     <div class=\"row bestbidhint\">\n        "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.bestbidhint : stack1), depth0))
    + "\n     </div> \n     \n     <div class=\"row buyerPremiumRow bidAmount\">\n          <div class=\"col-xs-5 col-sm-4 property\">\n              "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.bidLimit : stack1), depth0))
    + "\n          </div>\n          <div class=\"col-xs-7 col-sm-8\">\n              <div class=\"bid-plus-minus\">\n                  <span class=\"bid-minus disable\">-</span>\n                  <input type=\"text\" id=\"bestBid-amount-custom\" aria-label=\"Place Offer Negotiation\" autocomplete=\"off\" maxlength=\"9\" value=\"\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.bidLimit : stack1), depth0))
    + "\">\n                  <span class=\"bid-plus disable\">+</span>\n              </div>\n              \n          </div>\n     </div>\n     \n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.nobuyerPremium : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "     <div class=\"row totalDue robotoregular\">\n          <div class=\"col-xs-6 col-sm-4 property\">\n              "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.totalDue : stack1), depth0))
    + "\n          </div>\n          <div class=\"col-xs-6 col-sm-8 propertyValue\" id=\"totalDue\"></div>\n     </div>\n      <div id=\"somethingWrong\" class=\"somethingWrong\"></div>\n      <div class=\"row submitButtons\">\n       <input type=\"hidden\" id=\"bid-id\" aria-label=\"Bid ID\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.bidId : stack1), depth0))
    + "\">\n        <button class=\"saveAutoBid btn btn-primary textFade\" id=\"submitPlaceBestBid\" type=\"button\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.submit : stack1), depth0))
    + "</button>\n        <button class=\"btn btn-default cancelButton hidden-xs\" type=\"button\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.close : stack1), depth0))
    + "</button>\n      </div>\n    </div>\n    <div class=\"thankyou hide\">\n      <div class=\"row borderBottomRemove text-center \">\n          <div class=\"col-xs-12 \">\n             "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.thankyou : stack1), depth0))
    + "\n          </div>\n      </div>\n      <div class=\"row text-center\">\n        <button class=\" btn btn-primary textFade \" id=\"thankyouOk\" type=\"button\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.close : stack1), depth0))
    + "</button>\n      </div>\n    </div>\n           \n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/confirmNegotiationModal', t);
return t;
});
/* END_TEMPLATE */
;