
define('dashboardSignInView',['require','jquery','underscore','backbone','commonutils','dUtil','GoogleAnalyticsUtils','loginRegisterView','hbs!tpl/buyerDashboard/dashboardSignInModal','hbs!tpl/buyerDashboard/errorMessagePopup'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone");
    	var CommonUtils = require("commonutils");
    	var Utils = require("dUtil");
    	var GAUtils = require("GoogleAnalyticsUtils");
    	var loginRegisterView = require('loginRegisterView');
    	

    var callBackMethod;
    var callBackDataObj;
    var redirectTo;
    var redirectToHome = '';

    var dashboardSignInTemplate = require("hbs!tpl/buyerDashboard/dashboardSignInModal");
    var dashboardErrorMessagePopupTemplate = require("hbs!tpl/buyerDashboard/errorMessagePopup");
    
    var DashboardSignInView = Backbone.View.extend({
        el : "#dashboardModal",
        callback: null,
        viewObject: null,
        initialize: function () {
        },
        signInSubmit : function(){
            try{
	        	if($('#loginBody').is(':visible')){
	                this.checkAndSignIn();
	            }else{
	                if(redirectTo === 'home'){
	                    window.location.href = contextPath+"/home";
	                }else if(redirectTo === 'refresh'){
	                	window.location.href = contextPathPortal+'/my-shopper';
	                }else{
	                    window.location.href = contextPathPortal+'/my-shopper';
	                }
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:signInSubmit|" + e, null, 1);
            }
        },

        checkAndSignIn : function() {
            try{
	        	var email = $("#emailAddressDB").val();
	            var pswd = $("#passwordDB").val();
	            var invalid = false;
	            if(!email) {
	                invalid = true;
	                $('#signInModal .login-form-error.email').text('Email/User Name is required');
	                $('#signInModal .login-form-error.email').show();
	            }
	            if(!pswd) {
	                invalid = true;
	                $('#signInModal .login-form-error.password').text('Password is required');
	                $('#signInModal .login-form-error.password').show();
	            }
	            if(!invalid) {
	                this.signIn();
	            }else{
	                GAUtils.eventTracking.trackevent("New-Sign In","Sign In Click","LoginFailed"); 
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:checkAndSignIn|" + e, null, 1);
            }
        },

        signIn : function() {
            try{
	        	Utils.showLoader();
	            $('#signInModal .login-form-error').hide();
	            var email = $.trim($("#emailAddressDB").val());
	            if(email.indexOf('@') == -1){
	                var dataParams = {username : email};
	                Utils.postUrlCall(contextPathPortal + "/user/isDuplicateUser" , dataParams , this.isDuplicateUser , this)
	            } else {
	                this.doLogin();
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:signIn|" + e, null, 1);
            }
        },

        isDuplicateUser : function(response , obj){
            try{
	        	if(response.isDuplicateUser == 'Y'){
	                $('#signInModal .login-form-error').text(response.message);
	                $('#signInModal .login-form-error').show();
	                util.hideLoader();
	                Utils.hideLoaderOnLoad();
	                return false;
	            } else {
	                obj.doLogin();
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:isDuplicateUser|" + e, null, 1);
            }
        },

        doLogin : function(){
            try{
	        	var email = $.trim($("#emailAddressDB").val());
	            var pswd = $.trim($("#passwordDB").val());
	            Utils.postUrlCall(contextPathPortal + '/signIn' ,  {username:email,password:pswd}, this.onSignIn , this);
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:doLogin|" + e, null, 1);
            }
        },

        onSignIn : function(response , obj){
            try{
            	Utils.hideLoaderOnLoad();
            	Utils.hideLoader();
	        	if(response.user) { 
	                GAUtils.eventTracking.trackevent("New-Sign In","Sign In Click","Registration-SignIn"); 
	                if(typeof globalParam.config.userId != 'undefined'){
		                if(globalParam.config.userId !== response.user.realbidUserId){
		                	$('#loginBody').hide();
		                    $('#otherUser').show();
		                    $('#signInSubmit').html('Ok');
		                    $('#modalHeader').html('Logged in as other user');
		                    redirectTo = 'refresh';
		                    obj.redirectToHome();
		                    Utils.hideLoader();
		                   
		                    return false;
		                }
		            }
	                CommonUtils.dashboardLogin(response);
	                if(_.contains(response.user.roleIdList, 'AUTH_USER') || _.contains(response.user.roleIdList, 'PRIM_AUTH_USER') || _.contains(response.user.roleIdList, 'CUST_CARE') ){
	                    $('#loginBody').hide();
	                    $('#unAuthAccess').show();
	                    $('#signInSubmit').html('Ok');
	                    $('#modalHeader').html('Unauthorized Access');
	                    redirectTo = 'home';
	                    obj.redirectToHome();
	                    Utils.hideLoader();
	                    
	                    return false;
	                }
	                if(response.user.userStatus=='PROFILE'){
	                    $('#loginBody').hide();
	                    $('#profileInc').show();
	                    $('#signInSubmit').html('Ok');
	                    $('#modalHeader').html('Incomplete Profile');
	                    redirectTo = 'dashboard';
	                    obj.redirectToHome();
	                    Utils.hideLoader();
	                   
	                    return false;
	                }
	                //Utils.hideLoader();
	                $('#signInModal').modal('hide');
	                callBackMethod(callBackDataObj);

	            } else if(response.error) {

					if(response.redirectToRegistration == 'Y'){
						var redirectionUrl =  "/create-account?emailOrScreenName="+response.emailOrScreenName;
						 setTimeout(function () {
							 CommonUtils.redirectToRegistration(redirectionUrl);
					 	},10000);
						
					}
	                GAUtils.eventTracking.trackevent("New-Sign In","Sign In Click",response.error); 
	                $('#signInModal .globalSignInError').html(response.error);
	                $('#signInModal .globalSignInError').show();
	                Utils.hideLoader();
	                
	            }

            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:onSignIn|" + e, null, 1);
            }
        },

        onSigninComplete : function(response, loginViewObject){
            try{

            	Utils.hideLoaderOnLoad();
            	Utils.hideLoader();

	        	if(response.user) { 

	                GAUtils.eventTracking.trackevent("New-Sign In","Sign In Click","Registration-SignIn"); 
	                if(typeof globalParam.config.userId != 'undefined'){
		                if(globalParam.config.userId !== response.user.realbidUserId){
		                	loginViewObject.showErrorMessagePopup();
		                    $('#otherUser').removeClass('hide');
		                    $('#modalHeader').html('Logged in as other user');
		                    redirectTo = 'refresh';
		                    loginViewObject.redirectToHome();
		                    return false;
		                }
		            }
	                
	                if(_.contains(response.user.roleIdList, 'AUTH_USER') || _.contains(response.user.roleIdList, 'PRIM_AUTH_USER') || _.contains(response.user.roleIdList, 'CUST_CARE') ){
	                    loginViewObject.showErrorMessagePopup();
	                    $('#unAuthAccess').removeClass('hide');
	                    $('#modalHeader').html('Unauthorized Access');
	                    redirectTo = 'home';
	                    loginViewObject.redirectToHome();
	                    return false;
	                }
	               
	                CommonUtils.dashboardLogin(response);
	                
	                $('#signInModal').modal('hide');
	                loginViewObject.callback(loginViewObject.viewObject);
	            } 

            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:onSigninComplete|" + e, null, 1);
            }
        },
        showErrorMessagePopup: function(){
        	try{
	        	var _this = this;
	        	_this.$el.html(dashboardErrorMessagePopupTemplate());
	            $('#signInModal').modal({
	                show: true,
	                keyboard: false
	            });
	            $('#goToPage').unbind('click').bind('click' , function(e) { _this.signInSubmit();});
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:showErrorMessagePopup|" + e, null, 1);
            }
        },
        redirectToHome : function(){
            try{
	        	clearTimeout(redirectToHome);
	            if(redirectTo === 'home'){
	                redirectToHome = setTimeout(function(){ window.location.href=contextPath + "/home"; }, 20000);
	            }else if(redirectTo === 'refresh'){
	            	redirectToHome = setTimeout(function(){ window.location.href = contextPathPortal+'/my-shopper' }, 20000);
	            }else{
	                redirectToHome = setTimeout(function(){ window.location.href= contextPathPortal+'/my-shopper'; }, 20000);
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:redirectToHome|" + e, null, 1);
            }
        },
        

        render : function (callback , viewObject) {
            try{

            	this.callback = callback;
            	this.viewObject = viewObject;

            	loginRegisterView.openLoginPopup({
            		callback: this.onSigninComplete,
            		viewObject: this,
            		statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
            	});

	            Utils.hideLoader();
	            Utils.hideLoaderOnLoad();
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Dashboard Sign in modal", "file:dashboardSignInView.js|function:render|" + e, null, 1);
            }
        }
        
    });
    return DashboardSignInView;
});