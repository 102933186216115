
define('googleOneTapSignInView',[
	'jquery',
	'underscore',
	'backbone',
	'commonutils',
	'GoogleAnalyticsUtils'
	], function($, _, Backbone,Utils,GAUtils){

		var gaLabel = 'visitorId: ' + $.cookie('visitorId');
	    var userSession = window.userSession || {};
	    var userId = userSession.userId || null;
	    if(userId){
	         gaLabel += '|userId: '+ userId;
	    }
	    var eventLabel = gaLabel;
	    
		var googleOneTapSignInView = Backbone.View.extend({
			el: 'body',
			initialize: function(){
				console.log("google One Tap signIn");
			},
			googleOneTapLogin: function(options){
	        	try{
		            var self = this;
		            self.callbackfunc = options.callback;
					self.callbackContextObj = options.callbackContextObj;
					var SOCIAL_LOGIN_CONFIG = saveListDrawerContent.socialLoginConfig[envConf];
					if(!SOCIAL_LOGIN_CONFIG){
						SOCIAL_LOGIN_CONFIG = saveListDrawerContent.socialLoginConfig["DEFAULT"];
					}
					self.handleCredentialResponse = self.handleCredentialResponse.bind(self);
					var googleOneTapConfig = JSON.parse(SOCIAL_LOGIN_CONFIG.googleConfig.keyObj);
					googleOneTapConfig["callback"] = self.handleCredentialResponse;
				    google.accounts.id.initialize(googleOneTapConfig);
					google.accounts.id.prompt( function(notification) {
				      if (notification.isSkippedMoment()) {
				        var reason = notification.getSkippedReason();
				        var cancelLabel = eventLabel;
				        if( reason === "user_cancel") {
				        	cancelLabel = cancelLabel + "|Source: Button"; 
				        } else if(reason === "tap_outside") {
				        	cancelLabel = cancelLabel + "|Source: Screen";
				        } else {
				        	cancelLabel = cancelLabel + "|Source: Other";
				        }
				        GAUtils.eventTracking.trackevent("","Close social login suggestion",cancelLabel);
				      }
				    });
	            }catch(e){
		            GAUtils.eventTracking.trackevent("Error", "GoogleOneTapLogin", "file:googleOneTapSignInView.js|function:googleOneTapLogin|" + e, null, 1);
	          	}
	        },
			handleCredentialResponse: function(response){
				try{
					var self = this;
					GAUtils.eventTracking.trackevent("","Suggested Gmail accounts click",eventLabel);
					self.callbackfunc(response.credential,self.callbackContextObj);
				}catch(e){
		            GAUtils.eventTracking.trackevent("Error", "GoogleOneTapLogin", "file:googleOneTapSignInView.js|function:handleCredentialResponse|" + e, null, 1);
	          	}
			}	
		});
	return googleOneTapSignInView;
});