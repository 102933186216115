
/* START_TEMPLATE */
define('hbs!tpl/loginRegister/loginRegisterConfirmation',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "\n    <div class=\"registerConfirmation\" id=\"registerConfirmation\">\n        <div class=\"regConMessage\">";
  stack1 = ((helper = (helper = helpers.registrationConfirmation || (depth0 != null ? depth0.registrationConfirmation : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"registrationConfirmation","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n    </div>\n\n";
},"useData":true});
Handlebars.registerPartial('tpl/loginRegister/loginRegisterConfirmation', t);
return t;
});
/* END_TEMPLATE */
;