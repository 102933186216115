
/* START_TEMPLATE */
define('hbs!templates/globalAlerts/globalAlertsProfileView',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "<div id=\"globalProfileContainer\">\n    \n</div>";
  },"useData":true});
Handlebars.registerPartial('templates/globalAlerts/globalAlertsProfileView', t);
Handlebars.registerPartial('templates/globalAlerts/globalAlertsProfileView', t);
return t;
});
/* END_TEMPLATE */
;