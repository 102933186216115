
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/whatsNewFeatureModal',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "                <div class=\"wn-modal-item\">\n                    <div class=\"wn-head\">\n                        ";
  stack1 = ((helper = (helper = helpers.header || (depth0 != null ? depth0.header : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"header","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                    </div>\n                    <div class=\"wn-desc\">\n                        ";
  stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                    </div>\n                </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<div class=\"modal fade whats-new-modal\" id=\"\" role=\"dialog\" aria-hidden=\"false\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content \">\n            <div class=\"modal-heading\">\n                <div class=\"wn-title\">";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.labels : stack1)) != null ? stack1.featureHeading : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n                <button class=\"\" data-dismiss=\"modal\">\n                    <svg class=\"\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n                        x=\"0px\" y=\"0px\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" enable-background=\"new 0 0 24 24\"\n                        xml:space=\"preserve\">\n                        <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\"\n                            x1=\"2.9\" y1=\"21.2\" x2=\"20.7\" y2=\"3.3\"></line>\n                        <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\"\n                            x1=\"20.7\" y1=\"21.2\" x2=\"2.9\" y2=\"3.3\"></line>\n                    </svg>\n                </button>\n            </div>\n            <div class=\"modal-body wn-modal-container\">\n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.alerts : depth0), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/globalAlerts/whatsNewFeatureModal', t);
return t;
});
/* END_TEMPLATE */
;