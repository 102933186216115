
require(["jquery", "HSearchView", "stickyHeaderView","Footerview", "commonutils",'handlebars', "stickyNavigationView", "requireLib","alertsView","saveListDrawerView", "GoogleAnalyticsUtils",'owlCarousel','subscribeView','propertyCarouselView', "CheckoutOffersView", "OutbidAlertsView"], function ($, SearchView, StickyHeaderView,Footerview, CommonUtils,Handlebars, stickyNavigationView,requireLib,alertsView,saveListDrawerView, GAUtils,owlCarousel,subscribeView,propertyCarouselView, CheckoutOffersView, OutbidAlertsView) {
    "use strict";

    var HomePage,
        search,
        home,
        header,
        footer,
        propertyCarouselView,
        mobileNavigationView;

    HomePage = function (params) {
        this.contextPath = typeof window.contextPath !== "undefined" ? window.contextPath : "";
    };
    HomePage.prototype.init = function () {


        try {
            var self = this, 
                gaAction, 
                refText, 
                position, 
                userDetails = " | visitorId: " + $.cookie('visitorId') + " | userId: " + ($.cookie('userId') || 'NA');

            self.onLoadAjaxCall = true;
            self.onload = true;
            search = new SearchView({ el: $('.h-search-container') });
            header = new StickyHeaderView({ el: $('.h-header-container') });
            footer = new Footerview({ el: $('.h-footer-container') });

            propertyCarouselView = new propertyCarouselView({ el: $('.propertyListSliderHome') });
    
    

            /**
             * static images and dynamic moduls load - after scroll 60 pixcel
             */
           // self.lazyLoad();
             // Render Dynamic Moduls
             self.getHomePageModules();
             self.onload = false;
    
            CommonUtils.checkIfLoggedIn({
                callback: function (response) {
                    response = response || {};
                    self.updateUserComponents({userEmailId: response.userEmailId || '',userFirstName: response.userFirstName || ''});
                }
            });
            if ($(window).width() < 767) {
                mobileNavigationView = new stickyNavigationView();
                mobileNavigationView.render();
            }
            
            window.globalAlertView = new alertsView();
            window.globalAlertView.render();
    
            window.golbalSaveListDrawerView = new saveListDrawerView();
            window.golbalSaveListDrawerView.render();
            CommonUtils.Header.initialize();
    
            $("#ajax-loader").hide();

            $(".link").on('click', function(event){
                var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
                GAUtils.eventTracking.trackevent("click", homePage, $(event.currentTarget).data('ga-event'),"Home Page Discover section");

            });

            $(".checkoutBlogList").on('click', function(event){
                var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
                GAUtils.eventTracking.trackevent("click", homePage, $(event.currentTarget).data('ga-event'),"Checkout Blog Section");

            });

            $("#viewAllArticles").on('click', function(event){
                var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
                GAUtils.eventTracking.trackevent("click", homePage, "View All Articles" ,"Checkout Blog Section");

            });
            /*// Common event listener 
            $(".ha-ga-event").click(function(){
                gaAction = $(this).attr("data-ga-event");
                refText = $(this).attr("aria-label") + userDetails;
                if ($(this).attr("data-section-id")) {
                    position = self.getComponentPosition({id: $(this).attr("data-section-id") });
                    refText += " | Section position: " + position;
                }
                if ($(this).attr("data-position")) {
                    refText += " | link position: " + $(this).attr("data-position");
                }
                GAUtils.eventTracking.trackevent(null, gaAction, refText, "");
            });*/
           
        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:initialize|" + error, null, 1);
        }
    };
    HomePage.prototype.lazyLoad = function() {
        try {
            var self = this;
            var yPos = ( $(window).scrollTop() || $("body").scrollTop() );
            
            var elHeight = $('.recommended-section').outerHeight(),
            viewportHeight   = $(window).height(),
            clientRect   = $('.recommended-section')[0].getBoundingClientRect(), clientTop=clientRect.top, clientBottom=clientRect.bottom;
            var extraHeight = Math.max(0, clientTop>0? Math.min(elHeight, viewportHeight-clientTop) : Math.min(clientBottom, viewportHeight));

            if(self.onload == true && (yPos >= 0 || extraHeight >= 0)){
                $.each($('img'), function() {
                  if ( $(this).attr('lazy-src') ) {
                      var source = $(this).attr('lazy-src');
                      $(this).attr('src', source);
                      $(this).removeAttr('lazy-src');
                  }
                });
                // Render Dynamic Moduls
                self.getHomePageModules();
                self.onload = false;
            }
            
            $(window).scroll(function() {
                var yPos = ( $(window).scrollTop() || $("body").scrollTop() );
                if(self.onload == true && yPos >= 0){
                    $.each($('img'), function() {
                      if ( $(this).attr('lazy-src') ) {
                          var source = $(this).attr('lazy-src');
                          $(this).attr('src', source);
                          $(this).removeAttr('lazy-src');
                      }
                    });
                    // Render Dynamic Moduls
                    self.getHomePageModules();
                    self.onload = false;
                }
                       
            });
        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:lazyLoad|" + error, null, 1);
        }
    };
    /**
     * This function will be called 
     *      - after user logged in successfully o
     *      - after /isLogin api callback
     */
    HomePage.prototype.updateUserComponents = function(options) {
        try {
         
            options = options || {};

            // Update Search Components 
            search.getSavedSearchesData({
                userEmailId: options.userEmailId || '',
                userFirstName: options.userFirstName || ''
            });
    
            // User stats - not logged in 
            if (options.userEmailId) {
                $(".why-hubzu").hide();
                $(".h-hot-markets-container").remove();

                // Update components order - add if any new sections 
                this.updateComponentsOrder({userType: "loggedInUser"});
            }
            
        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:updateUserComponents|" + error, null, 1);
        }
    };

    /**
     * This function will be called after user logged out 
     */
    HomePage.prototype.updateUserComponnetsOnLogout = function(options) {
        try {

            options = options || {};

            // Update Search Components 
            search.getSavedSearchesData({
                userEmailId: ''
            });
    
            $('.menu-modal').slideUp();
            $(".why-hubzu").show();
            
            // Hot markets are not available for repeated user
            // $(".h-hot-markets-container").show();

            // Update components order  
            this.updateComponentsOrder({userType: "repeatedUser"});
            
        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:updateUserComponnetsOnLogout|" + error, null, 1);
        }
    }
 
    HomePage.prototype.getHomePageModules = function() {
        try {

            var self = this;
            /**
             * Fetch dynamic moduels and append to home page 
             */
            $(".sections-container section.dynamic-modules").each(function(){
                self.renderCmsComponent({
                    url: $(this).attr('data-url'),
                    el: $(this).attr('data-el')
                })
            });
            
        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:getHomePageModules|" + error, null, 1);
        }
    };

    HomePage.prototype.renderCmsComponent = function(params) {

        try {

            params = params || {};

            var self = params.viewObject || this;

            // If dynamic module already rendered, then return;
            if (!($(params.el).hasClass("re-render")) && $(params.el).hasClass("rendered")) return;

            $.ajax({
                url: self.contextPath + params.url,
                type: "GET",
                success: function (response) {
                    response = $.trim(response) || '';
                    
                    if ($(params.el).length && response.length) {
                        $(params.el).html(response).slideDown("slow");
                        $(params.el).addClass("rendered");
                    } 
                    // if response empty hide that section, this will be helpfull while calculating section position
                    if ( $(params.el).length && !response.length ) {
                        $(params.el).hide();
                    }
                    
                    // Initialise Component views
                    self.initComponentViews(params);
                }, 
                error: function(xhr, status, erro) {
                    var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
                    GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:renderCmsComponent|Failed to fetch dynamic component - error:" + status, null, 1);
                }
            });

        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:renderCmsComponent|" + error, null, 1);
        }
    };

    HomePage.prototype.initComponentViews = function(params) {
        try {
            
            params = params || {};
            var view;

            switch (params.url) {
                case '/portal/v2.0/checkout-offers':        new CheckoutOffersView({ el: $(params.el) });
                                                            break;
                case '/portal/v2.0/alertsAndFavorites':     view = new OutbidAlertsView({ el: $(params.el) });
                                                            window.htimestamp.outbid = view
                                                            break;
                case '/portal/v2.0/recommended-property':   propertyCarouselView.propCarouselList();
                                                            break;
                case '/portal/v2.0/saved-search':           propertyCarouselView.undelegateEvents();
                                                            propertyCarouselView.$el = $('.propertyListSliderHome');
                                                            propertyCarouselView.delegateEvents();
                                                            propertyCarouselView.propMultiCarousel();
                                                            break;
                case '/portal/v2.0/hot-market':             this.initHotMarketEvents();
                                                            break;
                default:                                    break;
            }

        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:initComponentViews|" + error, null, 1);
        }
    };

    HomePage.prototype.updateComponentsOrder = function(params) {
        try {

            params = params || {};

            var componentClassesOrder = [];

            /**
             * loggedInUserOrder, repeatedUserOrder - Global variables from CMS
             */
            if ( params.userType === "loggedInUser" && typeof loggedInUserOrder !== "undefined" && loggedInUserOrder.length) {
                componentClassesOrder = loggedInUserOrder;
            } else if (params.userType === "repeatedUser" && typeof repeatedUserOrder !== "undefined" && repeatedUserOrder.length ) {
                componentClassesOrder = repeatedUserOrder;
            }

            if ( !componentClassesOrder.length )
                return;
            
            var componentIds = _.pluck(componentClassesOrder, 'id'),       
                container = $(".sections-container"),
                sections = $(".sections-container section"),
                section,
                isExistingElemAvailable = true,
                tempSection,
                tempId;

            /**
             *  NOTE: check if all existing sections classes are part of componentOrder
             *  - iretate all existing section classes
             *  - check if it is available in componentOrder list, if not available, update flag and retrun
             *  - check flag, if its false, don't perform sort
             *  - WHY: if existing section is not available in new list, that section will be added on top.
             */

            sections.each(function () {
                if (this.id && this.id !== "hubHeaderSection" && this.id !== "hubSearchSection" && componentIds.indexOf(this.id) === -1 ) {
                    if (params.userType === 'repeatedUser') {
                        /**
                         * this will be logged out scenario, 
                         * remove existing sections which are not requied for repeated user 
                         */
                        // $(this).remove();
                        $(this).slideUp(400, function() {
                            $(this).remove();
                        });
                    } else {
                        isExistingElemAvailable = false;
                        tempId = this.id;
                        return false;
                    }
                }
            });

            if (!isExistingElemAvailable) {
                var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
                GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:updateComponentsOrder|Existing section is not available in list - id: " + tempId, null, 1);
                return;
            }

            for (var index in componentClassesOrder) {
                section = componentClassesOrder[index];
                tempSection = $("#" + section.id);
                if (!tempSection.length && section.url && section.el) {
                    tempSection = document.createElement("section");
                    tempSection.classList = section.class + " dynamic-modules";
                    tempSection.id = section.id;
                    tempSection.setAttribute("data-url", section.url);
                    tempSection.setAttribute("data-el", section.el);
                    if(section.ajaxUrl){
                        tempSection.setAttribute("data-ajax-url", section.ajaxUrl);

                    }
                }
                container.append(tempSection);
            }
            
            // after re order, render dynamic modules
            this.getHomePageModules();

        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:updateComponentsOrder|" + error, null, 1);
        }
    };
    /**
     * This is function will return position of section with ID
     * TODO: As of now position is based on rendering order. 
     */
    HomePage.prototype.getComponentPosition = function(params) {
        try {
            
            params = params || {};

            if ( !params.id ) return -1;

            var sections = $(".sections-container section:visible"),
                sectionIds =  []; // sections.map(function(index, section){ return section.id; });

            sections.each(function(){
                sectionIds.push(this.id);
            })
            
            return sectionIds.indexOf(params.id) + 1;
            
        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:getComponentPosition|" + error, null, 1);
        }
    };
    HomePage.prototype.initHotMarketEvents = function() {
        try {
            var gaLabel = "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId'),
                position;
    
            position = this.getComponentPosition({id: "hubHotMarketSection" })
            gaLabel += " | Section position: " + (position > -1 ? position : '');

            $(".hot-markets .state-list").unbind("click").bind("click", function(){
                gaLabel += " | name: " + $(this).attr("aria-label");
                gaLabel += " | hot market position: " + $(this).attr("data-pos");
                GAUtils.eventTracking.trackevent(null,"Hot Market - Click", gaLabel, "");
            });
        } catch (error) {
            var homePage =  isHubzu2Enabled === 'Y' ? "Home Page 2.0" : "New Home Page"
            GAUtils.eventTracking.trackevent("Error", homePage, "file:HomePage.js|function:initHotMarketEvents|" + error, null, 1);
        }
    };

    home = new HomePage();
    home.init();

    // TODO: htimestamp to be replaced with h_timestamp 
    window.htimestamp = {
        home: home,
        mStickyView: mobileNavigationView
    };

    return home;
});
define("../../hubzu-web/js/pages/HomePage", function(){});
