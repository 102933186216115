
/* START_TEMPLATE */
define('hbs!tpl/buyerDashboard/confirmationModal',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;
  return "<div class=\"modal fade\" id=\"confirmationModal\" tabindex=\"-1\" role=\"dialog\" aria-hidden=\"true\" data-backdrop=\"static\" data-keyboard=\"false\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header popUpTitle\">\n                <div class=\"width85per robotoregular\">"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\n                <span class=\"cross\" data-dismiss=\"modal\"></span>\n            </div>\n            <div class=\"modal-body robotoregular\">\n                <div class=\"row confirmMessage\">\n                    <div class=\"col-xs-12\">"
    + escapeExpression(((helper = (helper = helpers.heading || (depth0 != null ? depth0.heading : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"heading","hash":{},"data":data}) : helper)))
    + " <span class=\"offeramount-popup\"></span></div>\n                </div>\n            </div>\n            <div class=\"modal-footer robotolight\">\n                <button type=\"button\" class=\"watchDeleteConfirm h-button-1\">"
    + escapeExpression(((helper = (helper = helpers.yes || (depth0 != null ? depth0.yes : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"yes","hash":{},"data":data}) : helper)))
    + "</button>\n                <button type=\"button\" class=\"h-button-2 cancelButton confirmCancelButton\" data-dismiss=\"modal\">"
    + escapeExpression(((helper = (helper = helpers.no || (depth0 != null ? depth0.no : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"no","hash":{},"data":data}) : helper)))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('tpl/buyerDashboard/confirmationModal', t);
return t;
});
/* END_TEMPLATE */
;