
define('phoneVerification',['jquery', 'GoogleAnalyticsUtils', 'loginRegisterView', 'commonutils'], function ($, GAUtils, loginRegisterView, CommonUtils) {

	'use strict';

	var PhoneVerification = function () { };

	PhoneVerification.prototype.getPhoneNumberStatus = function (params) {
		try {

			params = params || {};
			
			var self = this,
				callback = params.callback || null,
				phoneNumber = params.phoneNumber || null;

			if (!phoneNumber) {
				callback({
					status: 'FAILURE',
					errorCode: 'Please provide required details',
					isValid: false
				});
				return;
			}

			phoneNumber = phoneNumber.replace(/\D/g, '');

			if (self.statusFetch) self.statusFetch.abort();

			self.statusFetch = $.ajax({
				url: contextPath + "/portal/isPhoneNumberVerified?phoneNumber=" + phoneNumber,
				type: 'GET',
				success: function(response){
					response = response || {};
					if (response.status === "FAILURE" && response.errorCode && response.errorCode === "SESSION_EXPIRED") {
						// TODO : login popup
						loginRegisterView.openLoginPopup({
							callback: self.loginCallback,
							viewObject: {
								callback: self.getPhoneNumberStatus.bind(self),
								params: params
							},
							statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
						});
						return;
					}

					callback({
						isValid: (response.data || false),
						status: response.status,
						errorCode: response.errorCode,
					})
				},
				error: function(xhr, status, error) {
					callback({
						status: 'FAILURE',
						errorCode: error,
						isValid: false
					})
				}
			});

		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:PhoneVerification.js|function:getPhoneNumberStatus|" + e, null, 1);
		}
	};

	PhoneVerification.prototype.sendVerificationCode = function (params) {
		try {

			params = params || {};

			var self = this,
				callback = params.callback || null;

			if (!params.phoneNumber || !params.verificationType) {
				callback({
					status: 'FAILURE',
					errorCode: 'Please provide required details'
				});
				return;
			}
			$("#ajax-loader-mask,#ajax-loader").show();
			CommonUtils.fetch({
				type: "POST",
				data: {
					phoneNumber: params.phoneNumber,
					verificationType: params.verificationType
				},
				url: contextPath + "/portal/sendVerificationCode",
				callback: function (options) {

					var response = options.response || {},
						apiStatus = options.status || 'ERROR',
						status = response.status || 'ERROR';

					if (apiStatus !== "SUCCESS") {
						callback({
							status: 'FAILURE',
							errorCode: 'We are facing technical difficulties, please try again'
						});
						return;
					} 
					/*if(status === "FAILURE"){
						var errorCode = saveListDrawerContent.errorMessages.technicalErrorCode;
						if(options.errorCode == "PHONE_NUMBER_ALREADY_IN_USE"){
							errorCode = saveListDrawerContent.errorMessages.phoneNumberUsed;
						}
						$("#error-ver").html(errorCode);
					}*/

					if (status === "FAILURE" && response.errorCode && response.errorCode === "SESSION_EXPIRED") {
						// TODO : login popup
						loginRegisterView.openLoginPopup({
							callback: self.loginCallback,
							viewObject: {
								callback: self.sendVerificationCode.bind(self),
								params: params
							},
							statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
						});
						return;
					}
					$("#ajax-loader-mask,#ajax-loader").hide();
					callback(response);

				}
			});
		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:PhoneVerification.js|function:sendVerificationCode|" + e, null, 1);
		}
	};

	PhoneVerification.prototype.validateVerificationCode = function (params) {
		try {

			params = params || {};

			var self = this,
				callback = params.callback || null;

			if (!params.phoneNumber || !params.verificationCode) {
				callback({
					status: 'FAILURE',
					errorCode: 'Please provide required details'
				});
				return;
			}

			CommonUtils.fetch({
				type: "POST",
				data: {
					phoneNumber: params.phoneNumber,
					verificationCode: params.verificationCode
				},
				url: contextPath + "/portal/validateVerificationCode",
				callback: function (options) {

					var response = options.response || {},
						apiStatus = options.status || 'ERROR',
						status = response.status || 'ERROR';

					if (apiStatus !== "SUCCESS") {
						callback({
							status: 'FAILURE',
							errorCode: 'We are facing technical difficulties, please try again'
						});
						return;
					}

					if (status === "FAILURE" && response.errorCode && response.errorCode === "SESSION_EXPIRED") {
						// TODO : login popup
						loginRegisterView.openLoginPopup({
							callback: self.loginCallback,
							viewObject: {
								callback: self.validateVerificationCode.bind(self),
								params: params
							},
							statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
						});
						return;
					}

					callback(response);
				}
			});

		} catch (e) {
			GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:PhoneVerification.js|function:validateVerificationCode|" + e, null, 1);
		}
	};

	PhoneVerification.prototype.loginCallback = function (user, options) {
		try {
			if (options.callback) options.callback(options.params || {});
		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:PhoneVerification.js|function:loginCallback|" + error, null, 1);
		}
	}
	PhoneVerification.prototype.applyPhoneMaskFormat = function(params){
		try {
			var mobileNumber = params.phone || '';
			var mobNum = mobileNumber.replace(/^(\D*\d\D*){6}/gm, function(match) {
				return match.replace(/\d/g, '*');
			})
			return mobNum;
		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:PhoneVerification.js|function:applyPhoneMaskFormat|" + error, null, 1);
		}
	},
	PhoneVerification.prototype.applyPhoneFormat = function (params) {
		try {

			var self = this,
				element = params.element,
				charecter,
				currentValue,
				value = $(element).val();

			if (value) self.updatePhoneFormat({element: element});

			$(element).unbind("keypress").bind("keypress", function (event) {
				if (event.which != 8 && event.which != 0 && (event.which < 48 || event.which > 57)) {
					return false;
				}
				charecter = this.value.length;
				currentValue = $(this).val();
				if (charecter == 3 && currentValue.indexOf("(") <= -1) {
					$(this).val("(" + currentValue + ")" + "-");
				} else if (charecter == 4 && currentValue.indexOf("(") > -1) {
					$(this).val(currentValue + ")-");
				} else if (charecter == 5 && currentValue.indexOf(")") > -1) {
					$(this).val(currentValue + "-");
				} else if (charecter == 9) {
					$(this).val(currentValue + "-");
					$(this).attr('maxlength', '14');
				} else {
					$(this).val(currentValue);
					$(this).attr('maxlength', '14');
				}
			});

		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:PhoneVerification.js|function:applyPhoneFormat|" + error, null, 1);
		}
	}

	PhoneVerification.prototype.updatePhoneFormat = function (params) {
		try {
			params = params || {};
			var element = $(params.element),
				value = element.val(),
				cleaned, match;

			if (!value) return;

			cleaned = ('' + value).replace(/\D/g, '')

			match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
			if (match) {
				element.val('(' + match[1] + ')-' + match[2] + '-' + match[3])
			}
		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:PhoneVerification.js|function:updatePhoneFormat|" + error, null, 1);
		}
	},	
	PhoneVerification.prototype.formatPhoneNumber = function (params) {
		try {
			params = params || {};
			var element = $(params.element),
				value = element.val(),
				v, match;

			if (!value) return;

			v = ('' + value).replace(/\D/g, '');

			var zip = v.substring(0,3);
			var  middle = v.substring(3,6);
			var last = v.substring(6,10);
		
			if(v.length > 6){v= '('+ zip+')'+'-'+middle+'-'+last;}
			else if(v.length > 3){v= '('+ zip+')'+'-'+middle;}
			else if(v.length > 0){v = '('+ zip+')';}
			element.val(v)
			
		} catch (error) {
			GAUtils.eventTracking.trackevent("Error", "Phone Verification", "file:PhoneVerification.js|function:updatePhoneFormat|" + error, null, 1);
		}
	}

	return new PhoneVerification();
});	 