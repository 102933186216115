
/* START_TEMPLATE */
define('hbs!tpl/bidSignInModal',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "offer";
  },"3":function(depth0,helpers,partials,data) {
  return "bid";
  },"5":function(depth0,helpers,partials,data) {
  return "place an offer";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, buffer = "<div class=\"modal fade\" id=\"signInModal\" role=\"dialog\"  aria-hidden=\"true\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header popUpTitle\">\n                <div id=\"modalHeader\" class=\"width85per\">Sign In</div>\n            </div>\n            <div class=\"modal-body\">\n                <div id=\"loginBody\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"errorPH login-form-error globalSignInError\"></div>\n                    <div class=\"form-group col-xs-12 required\">\n                        <label for=\"emailAddress\" class=\"control-label\">Email Address / Screen Name</label>\n                        <input maxlength=\"50\" size=\"25\" name=\"userEmailId\" id=\"emailAddress\" class=\"form-control\" type=\"text\" value=\"\">\n                        <div class=\"errorPH login-form-error email\"></div>\n                    </div>\n                    <div class=\"form-group col-xs-12 required\">\n                        <label for=\"password\" class=\"control-label\">Password</label>\n                        <input maxlength=\"20\" size=\"25\" name=\"userPassword\" id=\"password\" class=\"form-control\" type=\"password\" value=\"\" autocomplete=\"off\">\n                        <div class=\"errorPH login-form-error password\"></div>\n                    </div>\n                </div>\n                <div id=\"unAuthAccess\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"col-xs-12\">You are not authorized to view this page. You will be redirected to home page in 20 seconds. Click Ok to go to home page.</div>\n                </div>\n                <div id=\"profileInc\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"col-xs-12\">Your profile is not complete in order to place ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.offerMode : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += ". You will be redirected to your dashboard page in 20 seconds. Click Ok to go to dashboard page.</div>\n                </div>\n                <div id=\"bkupBidByHstBidderByBuyr\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"col-xs-12\">You currently have the selected bid on this property, so there's no need for you to place a backup bid. Click Ok to go back to the property details page.</div>\n                </div>\n                <div id=\"bkupBidByHstBidderByAgnt\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"col-xs-12\">You currently have the selected bid for this buyer on this property, so there's no need for you to place a backup bid. Click Ok to go back to the property details page.</div>\n                </div>\n                <div id=\"technicalError\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"col-xs-12\">We're sorry! There was a technical issue with your ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.offerMode : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " submission and it didn't go through. We're addressing the issue as fast as we can. Click Ok to go back to property details page.</div>\n                </div>\n                <div id=\"listAgentOcwn\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"col-xs-12\">You are not authorized to place ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.offerMode : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " on this property as you are the listing agent for this property. You will be redirected to the property details page in 20 seconds. Click Ok to go to property details page.</div>\n                </div>\n                <div id=\"rhssAgentOcwn\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"col-xs-12\">Agents can't ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.offerMode : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += " on this property. You will be redirected to the property details page in 20 seconds. Click Ok to go to property details page.</div>\n                </div>\n                <div id=\"rhssOcwnBuyer\" class=\"marginB10\" style=\"display:none;\">\n                    <div class=\"col-xs-12\">Buyers can't ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.offerMode : depth0), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.program(3, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + " on this property. You will be redirected to the property details page in 20 seconds. Click Ok to go to property details page.</div>\n                </div>\n                <div id=\"validateLicensePopUp\" class=\"marginB10\" style=\"display:none;\">\n                    <div id=\"licenseErrorResponse\" class=\"col-xs-12\"></div>\n                </div>\n            </div>\n            <div class=\"clearfix\"></div>\n            <div class=\"modal-footer marginT10\">\n                <button type=\"button\" id=\"signInSubmit\" class=\"btn btn-default\">Sign In</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('tpl/bidSignInModal', t);
return t;
});
/* END_TEMPLATE */
;