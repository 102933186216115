
define('propertyCarouselView',['require','jquery','backbone','userCheckView','GoogleAnalyticsUtils','commonutils','owlCarousel','handlebars','loginRegisterView','subscribeView','HomeUtils'],function (require) {
    "use strict";

    var $ = require("jquery"),
        Backbone = require("backbone"),
        userCheckView = require('userCheckView'),
        GAUtils = require("GoogleAnalyticsUtils"),
        Utils = require("commonutils"),
        owlCarousel = require("owlCarousel"),
        Handlebars = require("handlebars"),
        loginRegisterView = require("loginRegisterView"),
        subscribeView = require("subscribeView"),
        HomeUtils = require("HomeUtils");

    var propertyCarouselView = Backbone.View.extend({
        events: {
            "mouseenter .propertyIconFont" :"gaTrackPorpertyIcon",
            "mouseenter .property-carousel .owlRow" : "showPropertyCarousel",
            "mouseout .property-carousel .owlRow" : "hidePropertyCarousel",
            "click .imageCarouselRight" : "slideImageNext",
            "click .imageCarouselLeft " : "slideImagePrev",    
            "click .place-bid-button " : "handlePlaceBid", 
            "click .view-details"  : "openPdp",   
            "click .pdpLinkHome"  : "openProperty"   
        },
        initialize: function () {
            try{
            var self = this,
                searchText;
            self.onLoadAjaxCall = true;
            self.propertyImageList = [];
            self.proplistMultiAjax = true;
            self.timerIntiated =false;
            //some initialization scripts
           /* $(window).scroll(function() {
                var yPos = ( $(window).scrollTop() || $("body").scrollTop() );
                if( parseInt(yPos) > 200 && self.onLoadAjaxCall == true){
                    console.log("scroll - property Carousel:"+yPos);
                    self.onLoadAjaxCall = false;
                    self.proplistMultiAjax = false;
                    self.propCarouselList();
                    self.propMultiCarousel();
                }
            });*/
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:initialize|" + e, null, 1);

            }

        },
        propCarouselList: function(){
            try{
                var self = this;
                $('.propertySliderHome').each(function(){
                    var options = {};
                    options.currentModule = this.id;
                    var searchText = self.findLatestRecentText();
                    if(typeof searchText == 'undefined'){
                        searchText="''";
                    }
                    options.url = ($('#'+this.id).attr('data-ajax-url')).replace('###SEARCH_TEXT###',searchText);
                    //'/portal/favoritedRecmdProperties?searchText=Florida&_=1588686761390';
                    options.callback = self.addEventsForCollection;
                    self.getPropertyCarouselList(options);
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:propCarouselList|" + e, null, 1);
            }
        },
        propMultiCarousel : function(){
            var self = this;
            try{
                $('.proplist-multi-carousel').each(function(){
                    var options = {};
                    options.currentModule = this.id;
                    options.url = ($('#'+this.id).attr('data-ajax-url'));
                    //'/portal/favoritedRecmdProperties?searchText=Florida&_=1588686761390';
                    options.callback = self.addEventsForCollection;
                    self.getCarouselList(options);
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:propMultiCarousel|" + e, null, 1);
            }
        },
        addEventsForCollection : function(options) {
            try{
            var options = options || {};
            var self = this;
            subscribeView.highlightSavedProperties(options);
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:addEventsForCollection|" + e, null, 1);
            }
        },
        logGa: function(params){
            try {
                params = params || {};
                 
                var collection = params.collection || '',
                    source = params.source || '',
                    gaParams = params.gaParams || '',
                    gaLabel = "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId'),
                    position,
                    id = params.id;

                id = $("#"+ id).hasClass('dynamic-modules') ? id : $("#" + id).parents('section').attr('id');
    
                position = window.htimestamp.home.getComponentPosition({id: id });
                gaLabel += " | Section position: " + (position > -1 ? position : '');
                
                if (gaParams)
                    gaLabel += gaParams;
                
                GAUtils.eventTracking.trackevent(null, collection + ' - ' + source, gaLabel, "");

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:logGa|" + error, null, 1);
            }
        },
        openProperty: function(event) {
            try {

                var parent = $(event.currentTarget);
                this.logGa({
                    source: 'property click',
                    collection: parent.attr("componentName") || '',
                    id: parent.attr("collectionname") || '',
                    gaParams: " | Property position: " + (parseInt(parent.attr("position")) + 1 || '') + " | Property id: " + parent.attr("propId")
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:openProperty|" + error, null, 1);
            }
        },
        openPdp : function(e){
            try{
                var event = e,
                element = $(e.target),
                pdpUrl = element.attr('data-listingurl');
                e.preventDefault();
                e.stopImmediatePropagation();

                var parent = $(e.target).parents(".pdpLinkHome");
                this.logGa({
                    source: 'View details click',
                    collection: parent.attr("componentName") || '',
                    id: parent.attr("collectionname") || '',
                    gaParams: " | Property position: " + (parseInt(parent.attr("position")) + 1 || '') + " | Property id: " + parent.attr("propId")
                });

                window.open(pdpUrl, '_blank');
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:openPdp|" + e, null, 1);
            }
        },

        submitProxyBid : function(){
            var self = this;
            self.goToBidwizard();
        },

        handlePlaceBid : function(e){
            try{
                var event = e,
                    self = this,
                    placeBidEvent = $(event.target);
                    self.placeBidOfferEvent = e;
                
                e.preventDefault();
                e.stopImmediatePropagation();

                loginRegisterView.proxyBidObj = {
                    action : $(e.target).data('action') || '',
                    isProxyBiddingEnabled : $(e.target).data('proxybidenabled') || false,
                    propertyId:$(e.target).data('propertyid') || '',
                    listingId:$(e.target).data('listingid') || '',
                    source:'recommended',
                    address:{
                        'streetName' : $(e.target).data('streetname') || '',
                        'streetNumber' : $(e.target).data('streetnumber')  || '',
                        'city' : $(e.target).data('city') || '',
                        'state' : $(e.target).data('state')  || '',
                        'zip' : $(e.target).data('zip') || ''
                    },
                    viewObject:self
                };

                // var action = $(e.target).data('action');
                // loginRegisterView.isProxyBiddingEnabled = $(e.target).data('proxybidenabled');
                var parent = $(e.target).parents(".pdpLinkHome");
                this.logGa({
                    source: $.trim(placeBidEvent.text()),
                    collection: parent.attr("componentName") || '',
                    id: parent.attr("collectionname") || '',
                    gaParams: " | Property position: " + (parseInt(parent.attr("position")) + 1 || '')  + " | Property id: " + parent.attr("propId")
                });

                $.get(contextPath + "/portal/isLogin", function(response){
                    if (!response.userEmailId) {
                        self.isHomeSubmit = true;
                        $('#lightbox, .pdp-gallery-lightbox').modal('hide');

                        // If user already registered while subscribe, open register 
                        var callbackOptions = null;
                        
                        if ( Utils.getCookieDetails('subscribeEmail') ) {
                            callbackOptions = {
                                openAnonymRegister: 'Y',
                                callback : self.goToBidwizard(),
                            }
                        }
                        loginRegisterView.openLoginPopup({
                            callback: self.goToBidwizard,
                            viewObject: {
                                viewObject: self,
                            },
                        });

                        $('#closeRegisterLogin').on('click', function(){
                            setTimeout(function(){
                            that.ispdpBidCenterSubmit = false;
                            },1000);
                        });

                    // } else if(!response.phoneNumberVerified && mobileVerificationView.mobileVerificationCheckVal && mobileVerificationView.mobileVerificationCheckVal == 'Y' && loginRegisterView.proxyBidObj.isProxyBiddingEnabled){
                    } else if(!response.phoneNumberVerified && mobileVerificationView.mobileVerificationCheckVal && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                        var callbackOptions = null;
                        callbackOptions = {
                            openPhoneVerification: true
                        }   
                        $('#loginRegisterDialog .modal-content').removeClass('activeStage');
    
                        loginRegisterView.showPhoneVerification(callbackOptions, loginRegisterView.proxyBidObj.isProxyBiddingEnabled);
                    } else if(response.phoneNumberVerified && loginRegisterView.proxyBidObj.isProxyBiddingEnabled){
                    
                        var callbackOptions = {};
                        if (loginRegisterView.proxyBidObj.action === "place-bid")
                            callbackOptions.confirmProxyBidPin = true;
                        else 
                            callbackOptions.openProxyBidRegister = true;
    
                        loginRegisterView.openLoginPopup(callbackOptions, loginRegisterView.proxyBidObj.isProxyBiddingEnabled);
                    } else {
                        
                        var callbackOptions = {
                            openPhoneVerification: true,
                            callBackFunc: self.goToBidwizard,
                            openPopup : true,
                            callBackObj:self,
                            callBackType : 'saveList'
        
                            }
                        if(typeof mobileVerificationView.mobileVerificationCheckVal != undefined && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                            mobileVerificationView.checkMobileVerificationRequired(callbackOptions);
                              return;
                         }
                        if($(placeBidEvent).attr('data-bidtype') == 'PLACE_BACKUP_BID' || $(placeBidEvent).attr('data-bidtype') == 'PLACE_BACKUP_OFFER'){
                            var listingId = $(placeBidEvent).attr('data-listingid');
                            var options = {'listingId' : listingId,'callbackFunc' :self.decideTargetAfterStatuCTA ,'self':self};
                            self.getListingStatusCTA(options)
                            
                        }else{
                            self.goToBidwizard('',self);
                        }
                    }
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:openPdp|" + e, null, 1);
            }
        },
        decideTargetAfterStatuCTA : function(params){
            try{
            var showCTA = params.catVal,
                self = params.self,
                placeBidEvent = (self.placeBidOfferEvent).target;
               
                var pdpUrl = $(placeBidEvent).attr('data-listingurl'),
                listingId = $(placeBidEvent).attr('data-listingid'),
                userId = window.userSession.userId || null,
                trackText = userId + '|' + listingId;
            if(showCTA == 'isBestBid'){
                
                GAUtils.eventTracking.trackevent("","Place Best Bid Click", trackText);

                window.location = pdpUrl + "?openBstBid";
            }else if (showCTA == 'isConfirmOffer'){
               
                GAUtils.eventTracking.trackevent("","Place Best Offer Click", trackText);

                window.location = pdpUrl + "?openConfirmOffer";
            }else{
                self.goToBidwizard('',self);
            }
        }catch(e){
            GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:decideTargetAfterStatuCTA|" + e, null, 1);
        }
        },
        getListingStatusCTA: function(options){
            try {
                // check if BESTBID is there for listing
                var isBestBid = false;
                var isConfirmOffer = false;
                var listingId = options.listingId || '',
                    self = options.self || this,
                callBackFun = options.callbackFunc || '';
                $.ajax({
                    url: (contextPath.indexOf('portal') > -1) ? contextPath + '/isBestBidAvailable' : contextPath + '/portal/isBestBidAvailable',
                    type:'GET',
                    data: {
                        listingId: listingId
                    },
                    success: function(response) { 

                        var response = response || {};
                        var catVal;
                        //response = {"isBestBidAvailable":"N","isSellerFinalNegotiationAvailable":"Y","isSellerFinalNegotiationAcceptedAlready":"N","validationStatus":null};

                        //TODO:  Logic to find out CTA
                        if(response.isBestBidAvailable == 'Y') {        
                            catVal =  'isBestBid';
                        } else if(response.isSellerFinalNegotiationAvailable === 'Y' && response.isSellerFinalNegotiationAcceptedAlready !== 'Y' ){
                            catVal =  'isConfirmOffer';
                        }else{
                            catVal =  false;
                        }
                        if(typeof callBackFun != 'undefined' &&   callBackFun !=''){
                            var callParam = {'catVal': catVal, 'listingId' :listingId,'self':self }
                            callBackFun(callParam);
                        }else{
                            return catVal;
                        }
                       
                    },
                    error: function(xmlhttprequest, textstatus, message) {
                        errorMessage = (textstatus === "timeout") ? "Network timeout" : message;
                        
                        GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarouselView.js|function:initiateFirebaseListeners|AJAX error - " + errorMessage, null, 1);
                    }
                });
            } catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:getListingStatusCTA|" + e, null, 1);
            }
        },
        goToBidwizard: function (options,self) {
            try {
                var isFormCrated = false,
                    event = self.placeBidOfferEvent ||  self.viewObject.placeBidOfferEvent,
                    bidType = $(event.target).attr("data-bidtype"),
                    listingId = $(event.target).attr("data-listingid"),
                    amount = 0,
                    cPath = typeof contextPath !== "undefined" ? contextPath : '',
                    url = cPath + $(event.target).attr("data-bidoffer-url");
                if(bidType == 'PLACE_OFFER' || bidType == 'PLACE_BACKUP_OFFER'){
                    amount  = $(event.target).attr("data-amount") || 0;
                }

                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && loginRegisterView.proxyBidObj.source == 'recommended'){
                    isFormCrated = HomeUtils.createForm({
                        bidType: bidType,
                        amount: amount,
                        url: url,
                        listingId: loginRegisterView.proxyBidObj.propertyId,
                        isProxyBiddingEnabled : true
                    });
                } else {
                    isFormCrated = HomeUtils.createForm({
                        bidType: bidType,
                        amount: amount,
                        url: url,
                        listingId: listingId,
                        isProxyBiddingEnabled : false
                    });
                }
                

                if (isFormCrated){
                    var callbackOptions = {
                        listingId : listingId,
                        callBack : self.submitBidForm || self.viewObject.submitBidForm,
                    }
                    userCheckView.userTypeCheck(callbackOptions);
                } 
                    

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:goToBidwizard|" + error, null, 1);
            }
        },
        submitBidForm: function(){
            $("#submitBidForm").submit();
        },
        findLatestRecentText: function(){
            try{
            var thisObj = this;
            var searchTxt = "";
            var recentSearch = localStorage.getItem("RecentSearchList");
            recentSearch = JSON.parse(recentSearch);
            if(recentSearch!=null && recentSearch!=undefined && recentSearch!='' && recentSearch.cookieElements!=null && recentSearch.cookieElements!=undefined && recentSearch.cookieElements!=''){
                thisObj.getValidRecents(recentSearch);
                if(recentSearch.cookieElements.length){
                    if(recentSearch.cookieElements[0].defaultSearchText != null && recentSearch.cookieElements[0].defaultSearchText != ""){
                        searchTxt =  recentSearch.cookieElements[0].defaultSearchText;
                    }else{
                        searchTxt =  recentSearch.cookieElements[0].value;
                    }    
                }
            }
            return searchTxt;
        }catch(e){
            GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:findLatestRecentText|" + e, null, 1);
        }
        },
        getValidRecents: function(validRecents) {
            try{
                var cookies = validRecents.cookieElements;
                var validCookies = [];
                var isDuplicate = false;
                for(var outerCount = 0; outerCount < cookies.length; outerCount++) {
                    isDuplicate = false;
                    for(var innerCount = 0; innerCount < outerCount; innerCount++) {
                        if(typeof cookies[outerCount].value != 'undefined' && typeof cookies[innerCount].value != 'undefined' && cookies[outerCount].value.trim().toUpperCase() === cookies[innerCount].value.trim().toUpperCase()) {
                            isDuplicate = true;
                        }
                    }
                    if(!isDuplicate) {
                        validCookies.push(cookies[outerCount]);
                    }
                }
                validRecents.cookieElements = validCookies;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:getValidRecents|" + e, null, 1);
            }
        },
        render: function () {
            try{
                return this;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:getValidRecents|" + e, null, 1);
            }
            
        },
        getCarouselList:function(params){
            try{
            var self = this,
                params= params,
                currentModule = params.currentModule,
                data = {};
            $.ajax({
                url: contextPath + params.url,
                type: "GET",
                success: function (response) {
                    var response = response || '';
                    
                    if(response != undefined && response != null && response.status != '' && response.status == 'SUCCESS'  && typeof response.newPropertiesDtoList != 'undefined' && (response.newPropertiesDtoList).length > 0 ){

                    data.response = response;
                    data.params = params;

                    self.populateCarouselList(data);
                    }else{
                        var currModule = params.currentModule
                        $('#'+currentModule).addClass('hide');
                    }
                }, 
                error: function(xhr, status, erro) {
                    $('#'+currentModule).addClass('hide');
                    console.log("Failed to fetch dynamic component - url: ", params.url);
                }
            });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:getCarouselList|" + e, null, 1);
            }
        },
        populateCarouselList : function(data){
            try{
            var theTemplateHtml = $('#savedSection').html(),
                self= this,
                params= data.params || {},
                response=data.response;
                 var theTemplate = Handlebars.compile(theTemplateHtml);
                $('#propCarouselList').html('');
                $('#propCarouselList').append(theTemplate(data.response));
                data.params = params;
                
                $('.propCarouselSection').each(function(){
                    var options = {};
                    params.currentModule = this.id;
                    params.componentName = 'Saved Search Properties section: ' + (parseInt($(this).attr('data-id') || 0) + 1)
                    options.params = params;
                    
                    options.response = response.newPropertiesDtoList[$('#'+this.id).attr('data-id')];
                    console.log(options);
                    self.populatePropertyTupple(options);
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:populateCarouselList|" + e, null, 1);
            }
        },
        getPropertyCarouselList : function(params){
            try{
            var self = this,
                params= params,
                currentModule = params.currentModule;
            $.ajax({
                url: contextPath + params.url,
                type: "GET",
                success: function (response) {
                    var response = response || '',
                        data = {};

                    // GAUtils.eventTracking.trackevent('Homepage','Home page Property Carousel', "visitorId: " + $.cookie('visitorId') + " | Module url: " + params.url );
                    if(response != undefined && response != null && response.status != '' && response.status == 'SUCCESS' && parseInt(response.totalResultCount) > 0){
                        data.response = response;
                        data.params = params;
                        data.params.componentName = "Recomended Properties section"
                        self.populatePropertyTupple(data);
                        $('#'+currentModule).removeClass('hide');
                    }else{
                        $('#'+currentModule).addClass('hide');
                    }

                    
                }, 
                error: function(xhr, status, erro) {
                    console.log("Failed to fetch dynamic component - url: ", params.url);
                }
            });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:getPropertyCarouselList|" + e, null, 1);
            }
        },
        buildHtml : function(options){
            try{
            var formattedTime = options.formattedTime;
            var timerHtml = $('#timeLeftBlock').html();
            var timerTemplate = Handlebars.compile(timerHtml);
            if(formattedTime.days.value >= 1){
                if(formattedTime.days.value < 100){
                    formattedTime.days.value = ("00"+formattedTime.days.value).substr(-2);
                }else{
                    formattedTime.days.value = ("000"+formattedTime.days.value).substr(-3);
    
                }
                formattedTime.showHour = true;
                formattedTime.showSeconds = false;
            }else{
                formattedTime.showHour = false;
                formattedTime.showSeconds = true;
    
            }
            
            formattedTime.hours.value = ("00"+formattedTime.hours.value).substr(-2);
            formattedTime.minutes.value = ("00"+formattedTime.minutes.value).substr(-2);
            formattedTime.days.label = 'Days';
            formattedTime.hours.label = 'Hours';
            formattedTime.minutes.label = 'minutes';
            formattedTime.seconds.label = 'Secs';
            
            formattedTime.seconds.value = ("00"+formattedTime.seconds.value).substr(-2);
    
           // ("00"+formattedTime.days.value).substr(-2) + ':'+ ("00"+formattedTime.hours.value).substr(-2)+ ':'+ ("00"+formattedTime.minutes.value).substr(-2)+ ':'+ ("00"+formattedTime.seconds.value).substr(-2)
           var htmlData = {};
           htmlData.formattedTime = formattedTime;
           return timerTemplate(htmlData);
        }catch(e){
            GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:buildHtml|" + e, null, 1);
        }
        },
        initiateTimer :  function(){
            
            try {
            
                var self = this;
                self.timerIntiated = true;
                clearInterval(self.timer);
                self.timer = setInterval(function(){
    
                    
                    $( ".prop-timer-home" ).each(function( ) {
                        var timeListingId=$(this).attr('listingid');
                        if(typeof timeListingId != 'undefined' && timeListingId != ''){
                            var timeLeft = parseInt($(this).attr('timeLeft')) - 1000 || 0;
                            $(this).attr('timeLeft',timeLeft);
                            timeLeft = timeLeft < 0 ? 0 : timeLeft;
                            if(timeLeft >= 0){
                                var formattedTime = window.globalAlertView.calculateTimeLeft({ millseconds: timeLeft, sendIndividualUnits: true }) || '';
                                var htmlOptions = {};
                                htmlOptions.formattedTime  = formattedTime;
                                var compiledHtml = self.buildHtml(htmlOptions);
                                $(this).html(compiledHtml);
                                $(this).prev().removeClass('hide');
                            }
                        }
                    });
    
    
                }, 1000);
    
                
    
            } catch(e) {
                
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:initiateTimer|" + e, null, 1);
            }
        },
        populatePropertyTupple : function(params){
            try{
            var response = params.response,
                self = this,
                targetId = params.params.currentModule,
                viewOptions = {},
                data = {};
            var theTemplateHtml = $('.propertyCarousel:first').html();
            var theTemplate = Handlebars.compile(theTemplateHtml);
            response.collectionName = _.escape(targetId);
            
            if (params.params.componentName) {
                response.componentName = params.params.componentName;
            }

            if(typeof response.recommendedPropertyList !='undefined'){
                var propList = response.recommendedPropertyList;
            }else{
                var propList = response.propertyList;
                if(response.saveSearchResponse.searchCriteriaList.length){
                    var savedUrl = {};
                    savedUrl.searchBy = response.saveSearchResponse.searchBy;
                    savedUrl.baseSearchUrl = response.saveSearchResponse.baseSearchUrl;
                    var searchCriteriaObj = response.saveSearchResponse.searchCriteriaList;
                    var searchDetails = self.formUrl(savedUrl,searchCriteriaObj);
                    response["searchText"] = searchDetails.savedFilters;
                }else{
                    response["searchText"] = "";
                }    
            }
            for (var item in propList) 
            {
                /*if(($.inArray( propList[item]['propertyId'], self.propertyImageList ) < 0)){
                    var pId = propList[item]['propertyId'];
                    self.propertyImageList.push(propList[item]['propertyId']);
                    //self.propertyImageList[pId] = [];
                    
                }*/
                
                propList[item]['showPriceText'] = true;
                propList[item]['showViewDetails'] = true;

                if(propList[item]['listingUrl'] !== undefined && propList[item]['listingUrl'] !== null) {
                    propList[item]['listingUrl'] = contextPath + propList[item]['listingUrl'];
                } 
                if(propList[item]['bedCount'] != '' && propList[item]['bedCount'] != 0 && propList[item]['bedCount'] != '0'){
                    
                    propList[item]['bedExists'] = true;
                }else{
                    propList[item]['bedExists'] = false;
                }
                if(propList[item]['bathCount'] != '' && propList[item]['bathCount'] != 0 && propList[item]['bathCount'] != '0'){
                   
                    propList[item]['bathExists'] = true;
                }else{
                    propList[item]['bathExists'] = false;
                }
                if(propList[item]['size'] != '' && propList[item]['size'] != 0 && propList[item]['size'] != '0'){
                    propList[item]['lotSizeExists'] = true;
                }else if (propList[item]['lotSize'] != '' && propList[item]['lotSize'] != 0 && propList[item]['lotSize'] != '0'){
                    propList[item]['lotSizeExists'] = true;
    
                }
    
                else{
                    propList[item]['lotSizeExists'] = false;
                }
                if(propList[item]['photoCount'] != '' && propList[item]['photoCount'] != 0 && propList[item]['photoCount'] != '0'){
                    propList[item]['photoExists'] = true;
                }else{
                    propList[item]['photoExists'] = false;
                }
                if(propList[item]['listingType'] == 'TRNL' && (propList[item]['currentBid'] != '' && propList[item]['currentBid'] != 'NA' && propList[item]['currentBid'] != '0')){
                    propList[item]['priceText'] = priceText['currentOffer'];
                    propList[item]['priceAmount'] = '$'+propList[item]['currentBid'];
                    propList[item]['buttonType'] = "placeBid";
    
                }else if (propList[item]['listingType'] == 'TRNL' && (propList[item]['currentBid'] == '' || propList[item]['currentBid'] == 'NA' || propList[item]['currentBid'] == '0')){
                    propList[item]['priceText'] = priceText['listingPrice'];
                    propList[item]['priceAmount'] = '$'+propList[item]['listingPrice'];
    
                }else if(propList[item]['listingType'] == 'AUCN' && (propList[item]['currentBid'] != '' && propList[item]['currentBid'] != 'NA' && propList[item]['currentBid'] != '0')){
                    propList[item]['priceText'] = priceText['currentBid'];
                    propList[item]['priceAmount'] = '$'+propList[item]['currentBid'];
    
                }else if(propList[item]['listingType'] == 'AUCN' && (propList[item]['currentBid'] == '' || propList[item]['currentBid'] == 'NA' || propList[item]['currentBid'] == '0')){
                    propList[item]['priceText'] = priceText['startingBid'];
                    propList[item]['priceAmount'] = '$'+propList[item]['startingBid'];
    
                }
                if(propList[item]['listingType'] == 'AUCN' && propList[item]['displayListPriceForAuction'] == 'Y'){
                    propList[item]['priceText'] = priceText['listingPrice'];
                    propList[item]['priceAmount'] = '$'+propList[item]['listingPrice'];                            
                }

                if(propList[item]['catgeory'] == 'NPL'){
                    propList[item]['priceText'] = priceText['listingPrice'];
                    propList[item]['priceAmount'] = '$'+propList[item]['listingPrice'];                            
                }

                propList[item]['priceTagName'] ='';
                if(propList[item]['tagName'] == 'UnderContract'  || propList[item]['tagName'] == 'UnderReview'){
                    propList[item]['priceText'] = priceText[propList[item]['tagName']];
                    propList[item]['priceAmount'] = '';  
                    propList[item]['showPriceText'] = false;

                    if(propList[item]['listingType'] == 'AUCN'){
                        propList[item]['buttonType'] = "placeBackupBid";
                        propList[item]['priceTagName'] = priceTagName[propList[item]['tagName']];
                    }   else{
                        propList[item]['buttonType'] = "placeBackupOffer";
                        propList[item]['priceTagName'] = priceTagName['foreclosureSPND'];

                    }
                    
                    
                   
                }
                if( (propList[item]['listingType'] == 'TRNL' || propList[item]['listingType'] == 'AUCN')){
                    propList[item]['showViewDetails'] = false;
                }
                if((propList[item]['category'] == 'FORECLOSURE' && propList[item]['fcOnlineFlag'] == 'Y' && propList[item]['propertyStatus'] != 'ACTIVE')){
                    propList[item]['showViewDetails'] = true;
                }
                propList[item]['isFCProperty'] = false;
                if(propList[item]['category'] == 'FORECLOSURE'){
                    if(propList[item]['estimatedBidPrice'] != undefined && propList[item]['estimatedBidPrice'] != '' && propList[item]['estimatedBidPrice'] !=0 ){
                        propList[item]['priceText'] = priceText["estOpeningBid"];
                        propList[item]['priceAmount'] = '$'+propList[item]['estimatedBidPrice'];                            
                    }else if(propList[item]['fcOnlineFlag'] != 'Y'){
                        propList[item]['priceText'] = priceText["estOpeningBid"];
                        propList[item]['priceAmount'] = "TBD";                            
                    }
                    propList[item]['fcpropertyStatus'] = fcPropertyStatus[propList[item]['propertyStatus']] || propList[item]['propertyStatus'];

                    if(propList[item]['propertyStatus'] == 'ACTIVE' && propList[item]['fcOnlineFlag'] == 'Y'){
                        propList[item]['fcpropertyStatus'] = '';
                    }
                    propList[item]['isFCProperty'] = true;
                }
                propList[item]['showTimeLeft'] = false;
                if(propList[item]['auctionTimeLeft'] != '' && propList[item]['auctionTimeLeft']>0  ){
                    propList[item]['showTimeLeft'] = true;

                }
                if( propList[item]['propAddress']['streetNumber'].length >20 ) {
                    propList[item]['streetNameDisp'] = (propList[item]['propAddress']['streetNumber']+' '+propList[item]['propAddress']['streetName']).substr(0,19) + '...';
                } else {
                    propList[item]['streetNameDisp'] = propList[item]['propAddress']['streetNumber']+' '+propList[item]['propAddress']['streetName'];
                }
                if( propList[item]['agentCompanyName'] != null && propList[item]['agentCompanyName'].length >32 ) {
                    propList[item]['agentCompanyNameDisp'] = propList[item]['agentCompanyName'].substr(0,31) + '...';
                } else {
                    propList[item]['agentCompanyNameDisp'] = propList[item]['agentCompanyName'];
                }
                
                
                /* property Ids to be collated to highlight the watched properties */
                if(viewOptions.propIdJson){
                    viewOptions.propIdJson += "," + propList[item]['propertyId'];
                }
                else{
                    viewOptions.propIdJson = propList[item]['propertyId'];
                }
    
            }
    
    
    
    
            data.params = params.params;
            response.propList = propList;
            data.response = response;
            
            $('#'+targetId).find('.property-carousel').html(theTemplate(data));
    
            self.initOwlCarousel('#'+targetId);
            if(!self.timerIntiated)
            self.initiateTimer();
            self.updateButtonStatus(propList);
            if(params.params && params.params.callback){
                params.params.callback(viewOptions);
            }
        }catch(e){
            

            GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:populatePropertyTupple|" + e, null, 1);
        }
           
        },
        updateButtonStatus : function(propList){
            try{
                for (var item in propList) {
                    if(propList[item]['listingId'] != '' && (propList[item]['tagName'] == 'UnderContract'  || propList[item]['tagName'] == 'UnderReview')){
                        var buttonOpt = {'listingId' : propList[item]['listingId'],'callbackFunc' :this.updateButtonStatusCB };
                        this.getListingStatusCTA(buttonOpt);
                    }
                    
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:updateButtonStatus|" + e, null, 1);
            }
        },
        updateButtonStatusCB : function(params){
            try{
                var showCTA = params.catVal;
                var listingId = params.listingId;
                if(showCTA){
                    if(showCTA == 'isBestBid'){
                        $('.bidButton'+listingId).html('Place Best Bid');
                    }else if(showCTA == 'isConfirmOffer'){
                        $('.bidButton'+listingId).html('Place H&B');
                    }
                }
            }catch(e){
                
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:updateButtonStatusCB|" + e, null, 1);
            }
        },
        
        initOwlCarousel : function(currentModuleId){
            try{
            if(currentModuleId != undefined && currentModuleId != ''){
                var owl = $('.owl-carousel');
            }else{
                var owl = $(currentModuleId+' .owl-carousel');
            }
             
              owl.owlCarousel({
                rtl: false,
                margin: 15,
                dots:false,
                loop: false,
                
                responsive:{
                    0:{
                        items:2,
                        slideBy: 1,
                        nav: false,
                        autoWidth:true,
                        mouseDrag:true
                    },
                    600:{
                        items:2,
                        slideBy: 1,
                        nav: false,
                        autoWidth:true,
                        mouseDrag:true
                    },
                    769:{
                        items:3,
                        slideBy: 3,
                        nav: false,
                        stagePadding: 0,
                        mouseDrag:true
                    },
                    900:{
                        items:3,
                        slideBy: 3,
                        nav: false,
                        stagePadding: 0,
                        mouseDrag:true
                    },
                    1200:{
                        items:4,
                        slideBy: 4,
                        nav: false,
                        stagePadding: 0,
                        mouseDrag:true
                        
                    }
                },
              });
              var that = this;
                owl.on('dragged.owl.carousel', function (e) {
                    /*console.log(e);
                    console.log(e.relatedTarget.normalize(e.page.value, true) > e.item.index);
                    e.relatedTarget._drag.direction*/
                    var eventAction='';
                    if(e.relatedTarget._drag.direction == 'right'){
                        eventAction = 'Slide Left';
                    }else{
                        eventAction = 'Slide Right';
                    }
                    
                    that.checkItemPosition(currentModuleId.replace('#',''));
                    
                    that.logGa({
                        source: 'Slider Navigation ' + eventAction,
                        collection: $(e.target).attr("componentname"),
                        id: $(e.target).attr("modulename"),
                        gaParams: ""
                    });
                });
                $(currentModuleId + ' .carousel-control.collectionNavigation').click(function(e){
                      var event = e.target;
                      if($(event).closest('.collectionNavigation').hasClass('disabled')){
                          return;
                      }
                      var eventActionModule = $(event).closest('.collectionNavigation').attr('modulename');
                      var eventAction = $(event).closest('.collectionNavigation').attr('aria-label');
                       
                        that.logGa({
                            source: 'Slider Navigation ' + eventAction,
                            collection: $(event).closest('.collectionNavigation').attr('componentname'),
                            id: eventActionModule,
                            gaParams: ""
                        });

                        var currOwlModule = $('#'+eventActionModule+' .owl-carousel');
                        if(eventAction == 'Slide Left'){
                            currOwlModule.trigger('prev.owl');
                        }else{
                            currOwlModule.trigger('next.owl');
                        }
                        that.checkItemPosition(eventActionModule);
                });
                that.checkItemPosition(currentModuleId.replace('#',''));
            }catch(e){
                
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:initOwlCarousel|" + e, null, 1);
            }
        },
        checkItemPosition : function(currEleId) {
            try{
            var currEle;
            currEle = $("#"+currEleId);
            if ($("#"+currEleId+" .owl-item:first").hasClass("active")) {
                currEle.find(".left").addClass('disabled');
                currEle.find(".right").removeClass('disabled');
            } else if ($("#"+currEleId+" .owl-item:last").hasClass("active")) {
                currEle.find(".right").addClass('disabled');
                currEle.find(".left").removeClass('disabled');
            } else {
                currEle.find(".carousel-control").removeClass('disabled');
            }
        }catch(e){
            
            GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:checkItemPosition|" + e, null, 1);
        }
        },
        showPropertyCarousel:function(e){
            try{
            var event = $(e.currentTarget),
            self = this,
            propId=event.find('.imageCarouselLeft').attr('propid'),
            url = contextPath+'/portal/api/getPropertyImages/',
            collectionName = event.find('.imageCarouselLeft').attr('collectionName'),
            photoCount = event.find('.imageCarouselLeft').attr('photoCount');
            if(typeof propId !== 'undefined' && typeof photoCount !== 'undefined' && parseInt(photoCount)>1){
                $('#imageCarouselRight'+$.trim(collectionName)+$.trim(propId)).removeClass('hideImgArrow');
                $('#imageCarouselLeft'+$.trim(collectionName)+$.trim(propId)).removeClass('hideImgArrow');

            }else{
                $('#imageCarouselRight'+$.trim(collectionName)+$.trim(propId)).addClass('disable');
                $('#imageCarouselLeft'+$.trim(collectionName)+$.trim(propId)).addClass('disable');
            }
            if(typeof self.propertyImageList[propId] == 'undefined'){
                self.propertyImageList[propId]=[];
            }
            if(typeof self.propertyImageList[propId] !== 'undefined' && self.propertyImageList[propId].length <=0 ){
                $.ajax({
                    url: url+propId,
                    dataType:'json',              
                    type: "GET",
                    success : function(response){
                        for (var item in response.data) {
                            self.propertyImageList[propId][item] = response.data[item]['pdpBigImage'];
                        }

                    }   
                });
            }
            }catch(e){
                
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:showPropertyCarousel|" + e, null, 1);
            }
        },
        hidePropertyCarousel:function(){
            try{
                this.winWidth = $(window).width();
                if( !(this.winWidth<768)){
                    $('.imageCarousel').addClass('hideImgArrow');
                }
            }catch(e){
                
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:hidePropertyCarousel|" + e, null, 1);
            }
        },
        slideImageNext:function(e){
            try{
                var event = $(e.currentTarget),
                currId = event.attr('id'),
                currPropId = $('#'+currId).attr('propId'),
                currposition = parseInt($('#'+currId).attr('currposition')),
                photoCount = parseInt($('#'+currId).attr('photocount')),
                collectionName = $.trim($('#'+currId).attr('collectionname')),
                leftId = 'imageCarouselLeft'+collectionName+currPropId,
                userId= (typeof window.userSession.userId !== 'undefined' && window.userSession.userId !== '' && window.userSession.userId !== null )? window.userSession.userId :'',
                imgId = $('#'+currId).attr('imgId'),
                trackTextFinal ='',
                self= this;
                if(currposition >= photoCount-1 ){
                    //$('#'+currId).addClass('disable');
                    currposition =  -1;
                }
                if(currposition < photoCount ){
                    currposition = currposition + 1;
                    $('#'+currId).attr('currposition',currposition);
                    $('#'+leftId).attr('currposition',currposition);
                    $('#'+imgId+' img').attr('src',self.propertyImageList[currPropId][currposition]);
                    $('#'+leftId).removeClass('disable');
                }else if(currposition >= photoCount ){
                    $('#'+currId).addClass('disable');
                }
                $('#'+imgId+' .selected-number').html(currposition+1);

                this.logGa({
                    source: 'Image Carousel Scroll Next',
                    collection: $.trim($('#'+currId).attr('componentname')),
                    id: collectionName,
                    gaParams: " | Scroll next to: " + currposition + " | Photo count: " + photoCount + " | Property id: " + currPropId
                });

            }catch(e){
                
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:slideImageNext|" + e, null, 1);
            }

        },
        slideImagePrev:function(e){
            try{
                var event = $(e.currentTarget),
                currId = event.attr('id'),
                currPropId = $('#'+currId).attr('propId'),
                currposition = parseInt($('#'+currId).attr('currposition')),
                photoCount = parseInt($('#'+currId).attr('photocount')),
                imgId = $('#'+currId).attr('imgId'),
                collectionName = $.trim($('#'+currId).attr('collectionname')),
                rightId = 'imageCarouselRight'+collectionName+currPropId,
                userId= (typeof window.userSession.userId !== 'undefined' && window.userSession.userId !== '' && window.userSession.userId !== null )? window.userSession.userId :'',
                trackTextFinal ='',
                self= this;
                if(currposition <= 0 ){
                    // $('#'+currId).addClass('disable'); 
                    currposition =  photoCount;
                }
                if(currposition <= photoCount ){
                    currposition = currposition - 1;
                    $('#'+currId).attr('currposition',currposition);
                    $('#'+rightId).attr('currposition',currposition);
                    $('#'+imgId+' img').attr('src',self.propertyImageList[currPropId][currposition]);
                    
                    $('#'+rightId).removeClass('disable');
                    $('#'+rightId).attr('currposition',currposition);
                    $('#'+imgId+' img').attr('src',self.propertyImageList[currPropId][currposition]);
                }else if(currposition <= photoCount ){
                    $('#'+currId).addClass('disable');
                }
                $('#'+imgId+' .selected-number').html(currposition+1);

                this.logGa({
                    source: 'Image Carousel Scroll Prev',
                    collection: $.trim($('#'+currId).attr('componentname')),
                    id: collectionName,
                    gaParams: " | Scroll prev: " + currposition + " | Photo count: " + photoCount + " | Property id: " + currPropId
                });

            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:slideImagePrev|" + e, null, 1);
            }
        },
        gaTrackPorpertyIcon :function(e){
            try{
            var event = e.target;
            GAUtils.eventTracking.trackevent('', 'Hover - '+$(event).closest('.propertyIconFont').attr('icon-name'), 'Hover on '+$(event).closest('.propertyIconFont').attr('icon-name')+' Icon | '+$(event).closest('.propSliderSection').attr('id') );
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:slideImagePrev|" + e, null, 1);
            }
        },
        formUrl: function(savedUrl,searchCriteria)
        {
            try{
                var url = [];
                var savedFilters = [];
                var minPrice = '';
                var maxPrice = '';
                var minYearBuilt = '';
                var maxYearBuilt = '';
                var minSqrFt = '';
                var maxSqrFt = '';
                var bathRoom = '';
                var bedRoom = '';
                var propType = '';
                var propStatus = '';
                var showmoreStatus = '';
                var occupyStatus = '';
                var category = '';
                var showMore = '';
                var ownItNow = '';
                var listingType = '';
                var sortBy = '';
                var propStatusDesc='';
                var propTypeDesc='';
                var listingTypeDesc='';
                var propertyShowMoreStatusDesc ='';
                var ownItNowDesc = ''; 
                var previewProperty ='';
                var previewPropertyDesc ='';
                var noBuyerPremiumFilter ='';
                var noBuyerPremiumFilterDesc ='';
                var reservePriceFilter ='';
                var reservePriceFilterDesc ='';
                var selfShowingFilter ='';
                var selfShowingFilterDesc ='';
                var brokerCoOpFilter ='';
                var brokerCoOpFilterDesc ='';
                var newToHubzuFilter='';
                var newToHubzuFilterDesc='';
                var auctionEndingTodayFilter='';
                var auctionEndingTodayFilterDesc='';
                var cashOnlyFilter='';
                 var cashOnlyFilterDesc='';
                var rectSrchFlag ='';
                var view ='';
                var neLatLng ='';
                var swLatLng ='';
                var centerLatLng ='';
                var zoomValue ='';
                var occupancyStatus='';
                var latitude, longitude;
                /*if(savedUrl.searchBy!=null)
                {
                    url.push('searchBy=' + savedUrl.searchBy);
                }
                else
                {
                    url.push('searchBy=All States');
                }*/
                _.each(searchCriteria , function(criteria){

                        switch(criteria.criteriaKey){
                            case "minPriceValue" :
                                    minPrice = criteria.criteriaValue;
                                    
                                break;
                            case "maxPriceValue" :
                                    maxPrice = criteria.criteriaValue;
                                    
                                break;
                            case "minYearBuilt" :
                                    minYearBuilt = criteria.criteriaValue;
                                    
                                break;
                            case "maxYearBuilt" :
                                    maxYearBuilt = criteria.criteriaValue;
                                  
                                break;
                            case "minPropertySize" :
                                    minSqrFt = criteria.criteriaValue;
                                break;
                            case "maxPropertySize" :
                                    maxSqrFt = criteria.criteriaValue;
                                break;
                            case "bath" :
                                    bathRoom = parseInt(criteria.criteriaValue);
                                break;
                            case "bed" :
                                    bedRoom = parseInt(criteria.criteriaValue);
                                break;
                            case "propertySubType":
                                    propType = criteria.criteriaValue;
                                    propTypeDesc = criteria.criteriaValueDescription;
                                break;
                            case "propertyStatus":
                                    propStatus = criteria.criteriaValue;
                                    propStatusDesc = criteria.criteriaValueDescription;
                                   
                                break;
                            case "propertyShowMoreStatus":
                                    showmoreStatus = criteria.criteriaValue;
                                    propertyShowMoreStatusDesc = criteria.criteriaValueDescription;
                                break;
                            
                            case "category":
                                    category = criteria.criteriaValue;
                                  
                                break;
                            case "ownItNow":
                                    ownItNow = criteria.criteriaValue;
                                    ownItNowDesc = criteria.criteriaValueDescription;
                                break;
                            case "listingType":
                                    listingType = criteria.criteriaValue;
                                    listingTypeDesc = criteria.criteriaValueDescription;
                                 
                                break;
                            case "previewProperty": 
                                previewProperty = criteria.criteriaValue;
                                previewPropertyDesc = criteria.criteriaValueDescription;
                                break;
                                
                            case "noBuyerPremiumFilter": 
                                noBuyerPremiumFilter=criteria.criteriaValue;
                                noBuyerPremiumFilterDesc=criteria.criteriaValueDescription;
                                break;

                            case "reservepricerevealed": 
                                reservePriceFilter=criteria.criteriaValue;
                                reservePriceFilterDesc=criteria.criteriaValueDescription;
                                break;
                            
                            case "selfshowingenabled": 
                                selfShowingFilter=criteria.criteriaValue;
                                selfShowingFilterDesc=criteria.criteriaValueDescription;
                                break;

                            case "brokerCoOp": 
                                brokerCoOpFilter=criteria.criteriaValue;
                                brokerCoOpFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "newToHubzu": 
                                newToHubzuFilter=criteria.criteriaValue;
                                newToHubzuFilterDesc=criteria.criteriaValueDescription;
                                break;
                            
                            case "auctionEndingToday": 
                                auctionEndingTodayFilter=criteria.criteriaValue;
                                auctionEndingTodayFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "cashOnly": 
                                cashOnlyFilter=criteria.criteriaValue;
                                cashOnlyFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "sortBy":
                                sortBy = criteria.criteriaValue;
                                break;
                            
                            case "rectSrchFlag": 
                                rectSrchFlag=criteria.criteriaValue;
                                break;
                            case "neLatLng": 
                                neLatLng=criteria.criteriaValue;
                                break;
                            case "swLatLng": 
                                swLatLng=criteria.criteriaValue;
                                break;
                            case "centerLatLng": 
                                centerLatLng=criteria.criteriaValue;
                                break;
                            case "zoomValue": 
                                zoomValue=criteria.criteriaValue;
                                break;
                            case "view": 
                                view=criteria.criteriaValue;
                                break;
                            case "occupancyStatus":
                                occupancyStatus = criteria.criteriaValue;
                                break;
                            case "latitude":
                                latitude = criteria.criteriaValue;
                                break;
                            case "longitude":
                                longitude = criteria.criteriaValue;
                                break;
                        }
                });

                if(category!=''){
                    var catTypes = category.split(',');
                    var enterREO = false;
                    _.each(catTypes , function(val){

                        switch(val)
                        {
                            case "SHRT_SALE": case "NON_REO":
                                url.push('category=' + val);
                                savedFilters.push("Short Sale"); 
                            break;
                            case "bankowned(occupied)": 
                                url.push('category=bankowned(occupied)');
                                savedFilters.push("Bank Owned (Occupied)");
                            break;
                            case "bankowned(nonoccupied)": 
                                url.push('category=bankowned(nonoccupied)');
                                savedFilters.push("Bank Owned (Non Occupied)");
                            break;
                            case "nonbankowned(occupied)": 
                                url.push('category=nonbankowned(occupied)');
                                savedFilters.push("Non-Bank Owned (Occupied)");
                            break;
                            case "nonbankowned(nonoccupied)": 
                                url.push('category=nonbankowned(nonoccupied)');
                                savedFilters.push("Non-Bank Owned (Non Occupied)");
                            break;
                            case "foreclosure":
                                url.push('category=foreclosure');
                                savedFilters.push("Foreclosure"); 
                            break;
                            case "REO":
                                if(enterREO==false)
                                {
                                    enterREO = true;
                                    if(occupyStatus.indexOf('Y')>-1)
                                    {
                                        url.push('category=bankowned(occupied)');
                                        savedFilters.push("Bankowned(occupied)"); 
                                    }
                                    if(occupyStatus.indexOf('N')>-1)
                                    {
                                        url.push('category=bankowned(nonoccupied)');
                                        savedFilters.push("Bankowned(Non-occupied)"); 
                                    }
                                }
                            break;
                            case "bankowned":
                                url.push('category=bankowned');
                                savedFilters.push("Bank Owned"); 

                            break;
                            case "nonbankowned":
                                url.push('category=nonbankowned');
                                savedFilters.push("Non-Bank Owned");

                            break;
                        }

                    });
                }

                if(propType!=''){
                    var proparr = propType.split(',');
                    var propTypeDescArr = propTypeDesc.split(',');
                     _.each(proparr , function(val){
                        url.push('propertySubType=' + val);
                         
                         
                    });
                     _.each(propTypeDescArr,function(valDesc){
                         savedFilters.push(valDesc); 
                     });
                }

                if(minPrice !== '' || maxPrice !== ''){
                    if(minPrice === '') {
                        url.push('priceRange=0-' + maxPrice);
                        savedFilters.push("$0 - $"+maxPrice);
                    } else if(maxPrice === '') {
                        url.push('priceRange=' + minPrice + '-1000000plus' );
                        savedFilters.push("$" + minPrice + "+"); 
                    } else {
                        url.push('priceRange=' + minPrice + '-' + maxPrice);
                        savedFilters.push("$" + minPrice + '-$' + maxPrice ); 
                    }
                }
                
                if(bathRoom!='')
                {
                    url.push('bath=' + bathRoom);
                    savedFilters.push(bathRoom + "+ Baths"); 
                }

                if(bedRoom!='')
                {
                    url.push('bed=' + bedRoom);
                    savedFilters.push(bedRoom + "+ Beds");
                }

                if(minSqrFt !== '' || maxSqrFt !== ''){
                    if(minSqrFt === '') {
                        url.push('squareFeetRange=0-' + maxSqrFt);
                        savedFilters.push("0 - "+maxSqrFt+"Sq Ft");
                    } else if(maxSqrFt === '') {
                        url.push('squareFeetRange=' + minSqrFt + '-10000plus' );
                        savedFilters.push(minSqrFt + "Sq Ft"); 
                    } else {
                        url.push('squareFeetRange=' + minSqrFt + '-' + maxSqrFt);
                        savedFilters.push( minSqrFt + 'Sq Ft-' + maxSqrFt+'Sq Ft' ); 
                    }
                }

                if(minYearBuilt !== '' || maxYearBuilt !== ''){
                    if(minYearBuilt === '') {
                        url.push('Year:yearBuiltRange=Before 1800-' + maxYearBuilt);
                        savedFilters.push("Year:0-"+maxYearBuilt);
                    } else if(maxYearBuilt === '') {
                        url.push('yearBuiltRange=' + minYearBuilt + '-After 2010' );
                        savedFilters.push("Year:" + minYearBuilt + "+"); 
                    } else {
                        url.push('yearBuiltRange=' + minYearBuilt + '-' + maxYearBuilt);
                        savedFilters.push("Year:" + minYearBuilt + '-' + maxYearBuilt ); 
                    }
                }

                if(propStatus!='')
                {
                    var proparr = propStatus.split(',');
                    var propTypeDescArr = propStatusDesc.split(',');
                     _.each(proparr , function(val){
                            url.push('propertyStatus=' + val);
                    });
                    _.each(propTypeDescArr , function(valDesc){
                        savedFilters.push(valDesc);
                    });


                }

                    

                if(showmoreStatus!='')
                {
                     var proparr = showmoreStatus.split(',');
                     var propertyShowMoreStatusDescArr = propertyShowMoreStatusDesc.split(',');
                     _.each(proparr , function(val){
                        url.push('propertyShowMoreStatus=' + val);
                    });
                     _.each(propertyShowMoreStatusDescArr , function(valDesc){
                         savedFilters.push(valDesc); 
                    });
                }

                if(occupancyStatus !=''){
                    var occupancyStatusTypes = occupancyStatus.split(',');
                    _.each(occupancyStatusTypes , function(val){
                        switch(val){
                            case "Y":
                                url.push('occupancyStatus=Y');
                                savedFilters.push("Occupied"); 
                            break;
                            case "N":
                                url.push('occupancyStatus=N');
                                savedFilters.push("Non Occupied"); 

                            break;
                        }
                    });
                }
                if(ownItNow!='')
                {
                    url.push('listingType=AUCN');
                    url.push('ownItNow=Y');
                    if(url.indexOf('propertyStatus=ACTIVE')==-1)
                    {
                        url.push('propertyStatus=ACTIVE');

                    }
                    savedFilters.push("Auction"); 
                    savedFilters.push(ownItNowDesc); 
                }
                else if(listingType!='')
                {
                     var arr = listingType.split(',');
                     var listingTypeDescArr = listingTypeDesc.split(',');
                     _.each(arr , function(val){
                        url.push('listingType=' + val);
                    });
                     _.each(listingTypeDescArr , function(valDesc){
                        savedFilters.push(valDesc); 
                    });
                }
                if(previewProperty!=''){
                    url.push('marketingPhase=' + previewProperty);
                    savedFilters.push(previewPropertyDesc); 
                }
                if(noBuyerPremiumFilter!=''){
                    url.push('nobuyerpremium=' + noBuyerPremiumFilter);
                    savedFilters.push(noBuyerPremiumFilterDesc); 
                }
                if(reservePriceFilter!=''){
                    url.push('reservepricerevealed=' + reservePriceFilter);
                    savedFilters.push(reservePriceFilterDesc); 
                }
                if(selfShowingFilter!=''){
                    url.push('selfshowingenabled=' + selfShowingFilter);
                    savedFilters.push(selfShowingFilterDesc); 
                }
                if(brokerCoOpFilter!=''){
                    url.push('brokerCoOp=' + brokerCoOpFilter);
                    savedFilters.push(brokerCoOpFilterDesc); 
                }
                if(newToHubzuFilter!=''){
                    url.push('newToHubzu=' + newToHubzuFilter);
                    savedFilters.push(newToHubzuFilterDesc); 
                }
                if(auctionEndingTodayFilter!=''){
                    url.push('auctionEndingToday=' + auctionEndingTodayFilter);
                    savedFilters.push(auctionEndingTodayFilterDesc); 
                }
                if(cashOnlyFilter!=''){
                    url.push('cashOnly=' + cashOnlyFilter);
                    savedFilters.push(cashOnlyFilterDesc); 
                }
                
                if (latitude) {
                    url.push('latitude=' + latitude);
                }
                if (longitude) {
                    url.push('longitude=' + longitude);
                }

                if(rectSrchFlag!=''){
                    url.push('rect=true');
                }
                if(neLatLng!=''){
                    url.push('neLatLng=' + neLatLng);
                }
                if(swLatLng!=''){
                    url.push('swLatLng=' + swLatLng);
                }
                if(centerLatLng!=''){
                    url.push('centerPoint=' + centerLatLng);
                }
                if(zoomValue!=''){
                    url.push('zoomValue=' + zoomValue);
                }
                if(view!=''){
                    url.push('view=' + view); 
                }
                
                
                if(sortBy!='')
                {
                    url.push(sortBy);
                }
                if(savedUrl.baseSearchUrl!=null)
                    url = contextPath + '/' + savedUrl.baseSearchUrl + '?' + url.join('&');
                else
                    url = contextPath + '/searchResult?' + url.join('&');

                
                
                
                var  data = {};
                data.url = url;
                data.savedFiltersGA = savedFilters.join(',');
                if(savedFilters.length > 3){
                    data.savedFilters = savedFilters[0] + ", " + savedFilters[1]+ ", " + savedFilters[2] + ", +" + (parseInt(savedFilters.length) - 3) + " More";
                }else{ 
                    data.savedFilters = savedFilters.join(", ");
                }    

                return data;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Property Carousel - Home Page", "file:propertyCarousel.js|function:formUrl|" + e, null, 1);
            }
        }
    
    });
    return propertyCarouselView;
});