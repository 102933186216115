
/* START_TEMPLATE */
define('hbs!tpl/saveDrawer/favouriteTupleNew',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"propDetails propDetailsDrawer "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\"\n	data-property-id=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\"watch";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n	<div class=\"tuple-inline-error hide\"></div>\n	<div class=\"property-wrapper\">\n		<div class=\"image-wrapper favouriteAddress\">\n			<a href=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\" target=\"_blank\"\n				currentpropid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" class=\"pdpLink pdpLinkFromDrawer img\" pg=\"1\"\n				tabindex=\"-1\">\n				<img src=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.imageDetails : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.imageStoragePath : stack1), depth0))
    + "\"\n					alt=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.state : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.zip : stack1), depth0))
    + "\"\n					onerror=\"javascript:this.src= '"
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/www/images/no-photo.jpg'\">\n			</a>\n			<a class=\"closeicon deleteWatchProperty\" propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n				listingId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n				propStatus=\"";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" forWatch=\"Y\"\n				aria-label=\"Delete Watch ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\n				href=\"javascript:void(0)\">\n				<svg width=\"28px\" height=\"24px\" viewBox=\"0 0 28 24\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\"\n					xmlns:xlink=\"http://www.w3.org/1999/xlink\">\n					<g id=\"Designs-v2\" stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n						<g transform=\"translate(-1096.000000, -325.000000)\" fill=\"#F25D49\" fill-rule=\"nonzero\"\n							stroke=\"#FFFFFF\" stroke-width=\"2.4\">\n							<g id=\"icon/heart/empty\" transform=\"translate(1098.000000, 327.000000)\">\n								<path\n									d=\"M12,20 C12,20 2.54165691e-16,14.9733526 0,6.32492594 C2.54165689e-16,2.88140419 2.86628215,3.92113855e-16 6.58932599,0 C9.80453838,0 11.8776186,3.18814787 12,3.33005567 C12.1234274,3.18814787 14.1997792,1.14491746e-15 17.475898,0 C21.1989419,1.14491749e-15 24,2.88140419 24,6.32492594 C24.0000002,15.4749297 12,20 12,20 Z\"\n									id=\"Favourite-Copy\"></path>\n							</g>\n						</g>\n					</g>\n				</svg>\n			</a>\n		</div>\n		<div class=\"details-wrapper addressWhole\">\n			<div class=\"heighest-bid-container ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.bulkPropertyInfo : depth0)) != null ? stack1.bulkPropertyFlag : stack1), "\n				Y", {"name":"ifEqual","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isUnderContract : depth0), {"name":"if","hash":{},"fn":this.program(4, data, depths),"inverse":this.program(6, data, depths),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n			</div>\n\n			<div class=\"address\">\n				<a class=\"truncate streetAddress pdpLinkFromDrawer\" target=\"_blank\"\n					href=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\"\n					title=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\"\n					currentpropid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" tabindex=\"-1\" href=\"javascript:void(0)\">\n					";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.bulkPropertyInfo : depth0)) != null ? stack1.parentBulkPropertyFlag : stack1), "N", {"name":"ifEqual","hash":{},"fn":this.program(9, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "				</a>\n			</div>\n\n			<div class=\"favorite-cta-container ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.bulkPropertyInfo : depth0)) != null ? stack1.bulkPropertyFlag : stack1), "\n				Y", {"name":"ifEqual","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\"\n				data-url=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.propCategory : stack1), "FORECLOSURE", {"name":"ifEqual","hash":{},"fn":this.program(11, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingType : stack1), "AUCN", {"name":"ifEqual","hash":{},"fn":this.program(21, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingType : stack1), "TRNL", {"name":"ifEqual","hash":{},"fn":this.program(35, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "				<a href=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].contextPath : depths[1]), depth0))
    + "/property/"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingUrl : stack1), depth0))
    + "\" target=\"_blank\"\n					currentpropid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" class=\"drawer-view-details\">View Details</a>\n			</div>\n\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.ownItNowFlag : stack1), {"name":"if","hash":{},"fn":this.program(43, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n		</div>\n	</div>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  return "is-bulk-property";
  },"4":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "				<div class=\"prop-type-label\">";
  stack1 = ((helper = (helper = helpers.underContractLabel || (depth0 != null ? depth0.underContractLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"underContractLabel","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>\n";
},"6":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "				<div class=\"current-bid-label\">";
  stack1 = ((helper = (helper = helpers.displayLabel || (depth0 != null ? depth0.displayLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"displayLabel","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n				<div class=\"current-bid-value\">";
  stack1 = ((helper = (helper = helpers.displayPrice || (depth0 != null ? depth0.displayPrice : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"displayPrice","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isTimerApplicable : depth0), {"name":"if","hash":{},"fn":this.program(7, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "				<div class=\"bids-placed-count\"></div>\n";
},"7":function(depth0,helpers,partials,data) {
  return "				<div class=\"timer-wrapper\">\n					<div class=\"timeLeftIcon\">\n						<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"14\" viewBox=\"0 0 14 14\">\n							<path fill=\"#2A69A3\" fill-rule=\"evenodd\"\n								d=\"M7 0c3.866 0 7 3.134 7 7s-3.134 7-7 7-7-3.134-7-7 3.134-7 7-7zm0 1.4C3.907 1.4 1.4 3.907 1.4 7c0 3.093 2.507 5.6 5.6 5.6 3.093 0 5.6-2.507 5.6-5.6 0-3.093-2.507-5.6-5.6-5.6zm.7 2.031c.359 0 .655.27.695.618l.005.082V8.4H4.2c-.387 0-.7-.313-.7-.7 0-.359.27-.655.618-.695L4.2 7H7V4.131c0-.359.27-.655.618-.695L7.7 3.43z\" />\n						</svg>\n					</div>\n					<div class=\"auction-timer\"></div>\n				</div>\n";
  },"9":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "					, ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.state : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n					";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.zip : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n";
},"11":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.fcProxyBidEnabled : depth0), {"name":"if","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"12":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "AUCTION_EVENT_ENDED", {"name":"ifEqual","hash":{},"fn":this.program(13, data),"inverse":this.program(15, data),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"13":function(depth0,helpers,partials,data) {
  return "\n";
  },"15":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.fcProxyBidCutOffAvailable : depth0), {"name":"if","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"16":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.userRegisteredForFcProxyBid : depth0), {"name":"if","hash":{},"fn":this.program(17, data),"inverse":this.program(19, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"17":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "				<button data-val=\"placeBidButton\" aira-label=\"Place Proxy Bid\" class=\"favorite-cta placeBidButton hide\"\n					listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\" data-action=\"place-bid\"\n					propertyid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-proxybidenabled="
    + escapeExpression(((helper = (helper = helpers.fcProxyBidEnabled || (depth0 != null ? depth0.fcProxyBidEnabled : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fcProxyBidEnabled","hash":{},"data":data}) : helper)))
    + "\n					data-streetnumber=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0))
    + "\"\n					data-streetname=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0))
    + "\"\n					data-city=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0))
    + "\" data-state=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.state : stack1), depth0))
    + "\"\n					data-zip=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.zip : stack1), depth0))
    + "\">\n					Place Proxy Bid\n				</button>\n";
},"19":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing, buffer = "				<button data-val=\"placeBidButton\" aira-label=\"Register Proxy Bid\"\n					class=\"favorite-cta placeBidButton proxy-cta hide\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\"\n					data-action=\"register-bid\" propertyid=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"\n					data-proxybidenabled="
    + escapeExpression(((helper = (helper = helpers.fcProxyBidEnabled || (depth0 != null ? depth0.fcProxyBidEnabled : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"fcProxyBidEnabled","hash":{},"data":data}) : helper)))
    + "\n					data-streetnumber=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetNumber : stack1), depth0))
    + "\"\n					data-streetname=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.streetName : stack1), depth0))
    + "\"\n					data-city=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.city : stack1), depth0))
    + "\" data-state=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.state : stack1), depth0))
    + "\"\n					data-zip=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.address : stack1)) != null ? stack1.zip : stack1), depth0))
    + "\">\n					Register to Proxy Bid\n				</button>\n";
},"21":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "ACTV", {"name":"ifEqual","hash":{},"fn":this.program(22, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "REVIEW", {"name":"ifEqual","hash":{},"fn":this.program(24, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "SPND", {"name":"ifEqual","hash":{},"fn":this.program(32, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"22":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "				<button data-val=\"placeBidButton\" aira-label=\"Place Bid\" class=\"favorite-cta placeBidButton\"\n					listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">Place Bid</button>\n\n";
},"24":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isBestBidAvailable : depth0), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.program(27, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"25":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "				<button aira-label=\"Place Best Bid\" class=\"favorite-cta placeBestBidButton\"\n					listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">Place Best Bid</button>\n";
},"27":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.sellerFinalNegotiationResponse : depth0)) != null ? stack1.sellerFinalNegotiationAvailable : stack1), {"name":"if","hash":{},"fn":this.program(28, data),"inverse":this.program(30, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"28":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "					   <button data-val=\"placeBackUpBid\" aira-label=\"Confirm Offer\" class=\"favorite-cta placeBidButton\"\n						listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">Confirm Offer for $"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.sellerFinalNegotiationResponse : depth0)) != null ? stack1.solicitationAmount : stack1), depth0))
    + "</button>\n";
},"30":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "						<button data-val=\"placeBackUpBid\" aira-label=\"Confirm Offer\" class=\"favorite-cta placeBidButton\"\n						listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">Confirm Offer</button>\n";
},"32":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isBestBidAvailable : depth0), {"name":"if","hash":{},"fn":this.program(25, data),"inverse":this.program(33, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"33":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "				<button data-val=\"placeBackUpBid\" aira-label=\"Place Backup Bid\" class=\"favorite-cta placeBidButton\"\n					listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">Place Backup Bid</button>\n";
},"35":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "ACTV", {"name":"ifEqual","hash":{},"fn":this.program(36, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = ((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.commonAttributes : stack1)) != null ? stack1.status : stack1), "SPND", {"name":"ifEqual","hash":{},"fn":this.program(41, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"36":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isHnbEnded : depth0), {"name":"if","hash":{},"fn":this.program(37, data),"inverse":this.program(39, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"37":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "				<button data-val=\"placeOfferButton\" aira-label=\"Place Offer\" class=\"favorite-cta placeOfferButton\"\n					listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\" propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">Place\n					Backup Offer</button>\n";
},"39":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "				<button data-val=\"placeBackUpOffer\" aira-label=\"Place Backup Offer\"\n					class=\"favorite-cta placeOfferButton\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\"\n					propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">Place Offer</button>\n";
},"41":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "				<button data-val=\"placeBackUpOffer\" aira-label=\"Place Backup Offer\"\n					class=\"favorite-cta placeOfferButton\" listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\"\n					propId=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.propertyDetails : depth0)) != null ? stack1.propertyId : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">Place Backup Offer</button>\n";
},"43":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "			<div class=\"bid-or-divider own-it-now-enabled\">\n				<div class=\"bid-divider\"></div>\n				<div class=\"bid-or\">Or</div>\n			</div>\n			<div class=\"oin\" style=\"display:flex\">\n				<button data-val=\"ownItNow\" aira-label=\"Own It Now\" class=\"oinButton\"\n					listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">End the Auction Now</button>\n				<span style=\"margin:8px 5px;font-family: graphikmedium;\"><span>For&nbsp;</span>\n				<span class=\"oin-price\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.propertyListingDetails : depth0)) != null ? stack1.ownItNowPrice : stack1), depth0))
    + "</span>\n				</span>\n			</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, blockHelperMissing=helpers.blockHelperMissing;
  stack1 = ((helper = (helper = helpers.propertyDetails || (depth0 != null ? depth0.propertyDetails : depth0)) != null ? helper : helperMissing),(options={"name":"propertyDetails","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.propertyDetails) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/saveDrawer/favouriteTupleNew', t);
return t;
});
/* END_TEMPLATE */
;