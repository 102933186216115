
define('validator',['jquery'], function($) {
    'use strict';

    $.fn.scrollToView = function (topOffset) {
      return this.each(function () {
        var _this = this;
        $('html, body').animate({
          scrollTop: $(_this).offset().top - (topOffset || 65)
        }, 300, function(){
            $(_this).focus();
        });
      });
    }
 
    var validator = {
    	
    	amountField : function(event , obj){
			var unicode=event.charCode? event.charCode : event.keyCode;
        	if (unicode!=8  && unicode != 37 && unicode != 39){
        		if (unicode == 36){
        			var amount = $(obj).val();
        			if(amount.indexOf('$') == -1 && amount.length == 0){
        				return true;
        			}else{return false;}
        		}else if (unicode >= 48 && unicode <= 57  ){
        			return true;
        		}else if(unicode==9){
        			return true;
        		}else{
        			return false; 
        		}
        	}else{
        		return true;
        	}
    	},
    	dob: function(value,obj){
        var result = false;
        if($(obj).parents('.required').length > 0){
             if(value == "") {
                 result = false;
             }else if(value == $(obj).attr('placeholder')){
                 result = false;
             }

             else{
                var actualDate = new Date();
                var newDate = new Date(actualDate.getFullYear()-18, actualDate.getMonth(), actualDate.getDate()+1);
                var enteredDate= new Date(value);
                return enteredDate < newDate;
             }

         }else{

            result = true;
         }
         
        return result;
      },
      dateRangeValidation : function(value,obj){
        var result = false;
        var currDate = new Date();
        var diffYear=currDate.getFullYear()-value.getFullYear(); 
        if(diffYear<=18){
          var dobDateTemp=value.setYear(currDate.getYear());
          if(dobDateTemp>currDate){
            return false;
          }
        }
      },
      isValidDate: function (value, obj) {
        var GivenDate = value,
            CurrentDate = new Date();
  
        GivenDate = new Date(GivenDate);
        if (GivenDate > CurrentDate) {
          return true;
        } else {
          return false;
        }
      },
      activationCode : function(value,obj){
        if(value == "") {
          if($(obj).parents('.required').length > 0){
            return false;
          }else{
            return true;
          }
        }else if(value == $(obj).attr('placeholder')){
          if($(obj).parents('.required').length > 0){
            return false;
          }else{
            return true;
          }
        }else{
          return /^\d{4}$/.test(value);
        }
      },
      state: function(value,obj){
        var result = false;
        if($(obj).parents('.required').length > 0){
             if(value == "") {
                 result = false;
             }else if(value == $(obj).attr('placeholder')){
                 result = false;
             }else{
                 result = true;
             }
         }else{
             result = true;
         }
         
        return result;
      },
    	name : function(value , obj){
    		  var result = false,
          commonCompsCMS = saveListDrawerContent,
          regExpression = "^[a-zA-Z0-9 \'\&\’\,\-\.x`'‘’`()]*$", 
          regexValidator = new RegExp(regExpression, 'g');
          if($(obj).parents('.required').length > 0){
               if(value == "") {
                   result = false;
               }else if(value == $(obj).attr('placeholder')){
                   result = false;
               }else{
                   result = true;
               }
          }else{
               result = true;
          }
          if(regexValidator.test(value) == false) {
    	       result = false;
    	    }
          return result;
          /*regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.nameField || "^[a-zA-Z0-9 \'\&\’\,\-\.x`'‘’`()]*$",*/
    	},
      isValidName : function(value , obj){
          var result = false,
              commonCompsCMS = saveListDrawerContent,
              regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.nameField || '^[a-zA-Z0-9 \'\-\.\_]*$',
              regexValidator = new RegExp(regExpression, 'g');

          if($(obj).parents('.required').length > 0){
               if(value == "") {
                   result = false;
               }else if(value == $(obj).attr('placeholder')){
                   result = false;
               }else{
                   result = true;
               }
          }else{
               result = true;
          }
          if(regexValidator.test(value) == false) {
             result = false;
          }
          return result;
      },
        companyName : function(value , obj){
            var result = false;
           if($(obj).parents('.required').length > 0){
               if(value == "") {
                   result = false;
               }else if(value == $(obj).attr('placeholder')){
                   result = false;
               }else{
                   result = true;
               }
           }else{
               result = true;
           }
           if(/^(?!.*--)[a-zA-Z0-9 \'\@\#\_\;\&\,\|\-\\\/\.x`'‘’`]*$/.test(value) == false) {
              result = false;
           }
           return result;
        },

        licence : function(value){
          var result = false,
            commonCompsCMS = saveListDrawerContent,
            regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.licenseField,
            regexValidator = new RegExp(regExpression, 'g');

            value = this.removeUnicodeChars(value) || value;
            return regexValidator.test(value);
        },
        removeUnicodeChars: function(value) {

            if(!value) return false;
            var index;
            for(index = 0 ; index < value.length; index++) {
                if(value.charCodeAt(index) > 122) {
                  value = value.substr(0, index) + value.substr(index + 1); 
                } 
            }
            return value;
        },
        screenName : function(value,obj){
            var result = false;
           if($(obj).parents('.required').length > 0){
               if(value == "") {
                   result = false;
               }else if(value == $(obj).attr('placeholder') || value == $(obj).attr('placeholder').toLowerCase()){
                   result = false;
               }else if(value.length<6){
                  result = false;
               }else if(value.length>15){
                  result = false;
               }else{
                   result = true;
               }
           }else{
               result = true;
           }
           if(/^(?!-)[a-z0-9-| |&|.|,|'|.x`'‘’`]+$/.test(value) == false) {
            result = false;
           }
           return result;
        },
        passwordCharacterCheck : function(value,obj){
            if (value.length < 8 || value.length > 20){
              return false;
            }
            return true;
           
        },

        
        streetNumber : function(value,obj){

          var commonCompsCMS = saveListDrawerContent,              
              regExpression = "^[a-zA-Z0-9 \\'\\-\\&\\.\\_\\#\\,x`'‘’`()]*$",
              regexValidator = new RegExp(regExpression, 'g');

          if (value.length < 1 && $(obj).parents('.required').length > 0) {
            return false;
          } else if ( $(obj).attr('placeholder') && value == $(obj).attr('placeholder')) {
            return false;
          } else {
            if(regexValidator.test(value) == false) {
              return false;
            }
          }
          return true;
          /*regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.streetNumberField || '^[a-zA-Z0-9 \'\-\.\_\#]*$',*/
        },
        streetName: function (value, obj) {
          var commonCompsCMS = saveListDrawerContent,
            regExpression = "^[a-zA-Z0-9 \\'\\-\\&\\.\\_\\#\\,x`'‘’`()]*$",
            regexValidator = new RegExp(regExpression, 'g');

          if (value.length < 1 && $(obj).parents('.required').length > 0) {
            return false;
          } else if ($(obj).attr('placeholder') && $.trim(value) == $.trim($(obj).attr('placeholder'))) {
            return false;
          } else {
            if(regexValidator.test(value) == false) {
              return false;
            }
          }
          return true;
          /*regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.streetNameField || '^[a-zA-Z0-9 \'\-\.\_\#]*$',*/
        },
        city  : function(value,obj){
          var commonCompsCMS = saveListDrawerContent,
            regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.cityField || '^[a-zA-Z0-9 \'\&\,\-\.]*$',
            regexValidator = new RegExp(regExpression, 'g');

          if (value.length < 1  && $(obj).parents('.required').length > 0){
              return false;
            }else if( $(obj).attr('placeholder') && $.trim(value) == $.trim($(obj).attr('placeholder'))){
                   return false;
               }else{
              if(regexValidator.test(value) == false) {
                return false;
              }
            }
            return true;

        },
        isValidCity: function(value, obj){
          
          var commonCompsCMS = saveListDrawerContent,
              regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.cityField || '^[a-zA-Z0-9 \'\&\,\-\.]*$',
              regexValidator = new RegExp(regExpression, 'g');

            if (value.length < 1  && $(obj).parents('.required').length > 0){
              return false;
            }else if($(obj).attr('placeholder') && $.trim(value) == $.trim($(obj).attr('placeholder'))){
                   return false;
               }else{
              if(regexValidator.test(value) == false) {
                return false;
              }
            }
            return true;
        },
        taxId : function(value,obj){
          var commonCompsCMS = saveListDrawerContent,
              regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.taxField || "^(?!-)[a-zA-Z0-9-| |&|.|,|'|]+$",
              regexValidator = new RegExp(regExpression, 'g');
          if(value == "") {
            if($(obj).parents('.required').length > 0){
              return false;
            
            }
          }else if($.trim(value) == $.trim($(obj).attr('placeholder'))){
               if($(obj).parents('.required').length > 0){
                return false;
              }
            }else{ 
              if(value.length < 4  || value.length > 10 || !(regexValidator.test(value))){
                return false;
              }else{
                return true;
              }
            }
          return true;
        },
        zip : function(value,obj){
          if(value != "") {
            return /^\d{5}$/.test(value);
          }else if($(obj).attr('placeholder') && value == $(obj).attr('placeholder')){
             result = false;
         }
          return true;
        },
        passwordNumericCheck : function(value,obj){
          return /[0-9]+/.test(value);
        },
       isValidPassword: function(value){
          // return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!#@%$;^]).{8,20}$/.test(value);
          var commonCompsCMS = typeof saveListDrawerContent !== "undefined" ? saveListDrawerContent : {},
              regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.strongLabelValidation || '^[a-zA-Z0-9!#@%\$;\^]{8,20}$',
              allowedChars = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.allowedChars || '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!#@%$;^]).{8,20}$',
              allowedChars = new RegExp(allowedChars, 'g'),
              regexValidator = new RegExp(regExpression, 'g');
          return regexValidator.test(value) && allowedChars.test(value);
       }, 
    	 phone : function(value , obj){
        var commonCompsCMS = saveListDrawerContent,
              regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.phoneField || '^\d{10}$',
              regexValidator = new RegExp(regExpression, 'g');
    		if(value == "") {
    			if($(obj).parents('.required').length > 0){
    				return false;
    			}else{
    				return true;
    			}
    		}else if(value == $(obj).attr('placeholder')){
    			if($(obj).parents('.required').length > 0){
    				return false;
    			}else{
    				return true;
    			}
    		}else if(parseInt(value) <1){
          if($(obj).parents('.required').length > 0){
            return false;
          }else{
            return true;
          }
        }else{
    			return regexValidator.test(value);
    		}
      },
    	usphone: function(value , obj){

        // reset phone number
        $(obj).siblings('.h-textbox-error').text('Invalid Phone Number');

        if(value == "") {
    			if($(obj).parents('.required').length > 0){
    				return false;
    			}else{
    				return true;
    			}
    		}else if(value == $(obj).attr('placeholder')){
    			if($(obj).parents('.required').length > 0){
    				return false;
    			}else{
    				return true;
    			}
    		}else if(parseInt(value) <1){
          if($(obj).parents('.required').length > 0){
            return false;
          }else{
            return true;
          }
        }else{
    			return /^\([0-9]{3}\)-[0-9]{3}-[0-9]{4}$/.test(value);
    		}
      },
    	phoneField : function(event , obj){
    		var unicode=event.charCode? event.charCode : event.keyCode;
    		if (unicode!=8  && unicode != 39 && unicode != 46){
        		if (unicode == 36){
        			return false;
        		}else if (unicode >= 48 && unicode <= 57  ){
        			return true;
        		}else if(unicode==9){
        			return true;
        		}else{
        			return false; //disable key press
        		}
        	}else{
        		return true;
        	}
    	},
      validateSearchField: function(e){
        var key;
        var keychar;
        if (window.event)
          key = window.event.keyCode;
        else if (e)
          key = e.which;
        else
          return true;
        var pattern = "01234567890abcdefghijklmnopqrstuvwxyz &.'!@$%,;-/+.(){}[]>";
        /* key == 35 removed # */
        if (key == 0 || key == 13 || key == 8 || key == 46 || key == 36 || key == 37 || key == 38 || key == 39 || key == 40) { return true; }

        keychar = String.fromCharCode(key);
        
        var allowed = pattern; //"01234567890abcdefghijklmnopqrstuvwxyz &.'!@$%,;-/+.#(){}[]<>"; 
        if (allowed.indexOf(keychar.toLowerCase()) != -1) { 
          return true; 
        } else { 
          return false; 
        }
      },
    	taxField : function(event , obj){
        var unicode=event.charCode? event.charCode : event.keyCode;
        var pattern = "01234567890abcdefghijklmnopqrstuvwxyz &.'!@$%,;-/+.(){}[]>";
        if (unicode!=8 && unicode != 37 && unicode != 39 && unicode != 46){
            if (unicode == 36){
              return false;
            }else if ((unicode >= 48 && unicode <= 57)||(unicode==45)){
              return true;
            }else if(unicode==9){
              return true;
            }else{
              return false; 
            }
          }else{
            return true;
          }
      },
    	email : function(value , obj){
        var commonCompsCMS = saveListDrawerContent,
            regExpression = commonCompsCMS && commonCompsCMS.regularExpressions && commonCompsCMS.regularExpressions.emailField || '^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$',
            regexValidator = new RegExp(regExpression, 'g');
    		if(value == "") {
    			if($(obj).parents('.required').length > 0){
    				return false;
    			}else{
    				return true;
    			}
    		}
    		if( $(obj).attr('placeholder') && (value == $(obj).attr('placeholder') || value == $(obj).attr('placeholder').toLowerCase()) ){
    			if($(obj).parents('.required').length > 0){
    				return false;
    			}else{
    				return true;
    			}
    		}else{
    			return regexValidator.test(value);
    		}
      },
      isValidDateFormat: function (inputDate, obj) {

        var currentDate = new Date(),
            dateArray = inputDate.split('/'),
            newDate = new Date(dateArray[2] + '/' + dateArray[0] + '/' + dateArray[1]);

        // it has to be in valid format - MM/DD/YYYY
        if (!(/^([1-9]|((0)[0-9])|((1)[0-2]))(\/)([1-9]|[0-2][0-9]|(3)[0-1])(\/)\d{4}$/.test(inputDate))) return false; 

        inputDate = new Date(inputDate);

        if (inputDate > currentDate) {
          return (newDate && (newDate.getMonth() + 1) == dateArray[0] && newDate.getDate() == Number(dateArray[1]) && newDate.getFullYear() == Number(dateArray[2]));
        } else {
          return false;
        }
        
      },
      isValidFormatDate: function (inputDate, obj) {

        var currentDate = new Date(),
            dateArray = inputDate.split('/'),
            newDate = new Date(dateArray[2] + '/' + dateArray[0] + '/' + dateArray[1]),
            currDate = new Date (currentDate.getMonth() +'/'+ currentDate.getDate() +'/'+currentDate.getYear());
            

        // it has to be in valid format - MM/DD/YYYY
        if (!(/^([1-9]|((0)[0-9])|((1)[0-2]))(\/)([1-9]|[0-2][0-9]|(3)[0-1])(\/)\d{4}$/.test(inputDate))) return false; 

        inputDate = new Date(inputDate);
        if (inputDate >= currDate ) {
          return (newDate && (newDate.getMonth() + 1) == dateArray[0] && newDate.getDate() == Number(dateArray[1]) && newDate.getFullYear() == Number(dateArray[2]));
        } else {
          return false;
        }
        
      },
      isEmail : function(value , obj){
        if(value == "") {
          if($(obj).parents('.required').length > 0){
            return false;
          }else{
            return true;
          }
        } else {
          if(obj == "#emailId"){
            return /^([\w-+]+(?:\.[\w-+]+)*)@((?:[a-zA-Z0-9.-]+\.)*[a-zA-Z0-9.-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$/.test(value); 
          } else if($(obj).hasClass("nplLeadEmailId") == true){
            return /^([\w-+]+(?:\.[\w-+]+)*)@((?:[a-zA-Z0-9.-]+\.)*[a-zA-Z0-9.-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$/.test(value);  
          } else {
            return /^([\w-+]+(?:\.[\w-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,15}(?:\.[a-z]{2})?)$/.test(value);  
           }
          }
      },
     
      formatInputDate: function (value, obj) {
        var key = obj.keyCode;
        key = parseInt(key);
        if (!(key >= 0 && key <= 9)) $(obj).val($(obj).val().substr(0, $(obj).val().length - 1));
        var value = $(obj).val();
        if (value.length == 2 || value.length == 5) $(obj).val($(obj).val() + '/');
      },
      applyDateFormat: function (options) {
        try {
            var element = options.element,
                thisValue, numChars, thisCharCode, thisV, position, previousValue, output;
                
            //Bind keyup/keydown to the input
            $(element).unbind("keyup");
            $(element).unbind("keydown");
            $(element).bind('keyup', 'keydown', function (e) {
                thisValue = $(this).val();
                thisValue = thisValue.replace(/[^0-9\//]/g, '');
                $(this).val(thisValue);
                thisValue = $(this).val();
                numChars = thisValue.length;
                thisCharCode = thisValue.charCodeAt(thisValue.length - 1);
                if (e.which !== 8) {
                    if (numChars === 2) {
                        if (thisCharCode == 47) {
                            thisV = '0' + thisValue;
                            $(this).val(thisV);
                        } else {
                            thisValue += '/';
                            $(this).val(thisValue);
                        }
                    }
                    if (numChars === 5) {
                        if (thisCharCode == 47) {
                            previousValue = thisValue;
                            position = 3;
                            output = [previousValue.slice(0, position), '0', previousValue.slice(position)].join('');
                            $(this).val(output);
                        } else {
                            thisValue += '/';
                            $(this).val(thisValue);
                        }
                    }
                    if (numChars > 10) {
                        var output2 = thisValue.slice(0, 10);
                        $(this).val(output2);
                    }
                }
            });

        } catch (error) {
            console.debug("Error while applying date format ::", error);
        }
      }
    };
    return validator;
});
