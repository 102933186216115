
/* START_TEMPLATE */
define('hbs!templates/loginRegister/registrationFooterCommon',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<div class=\"form-group termsAndCond\">\n        \n        <div class=\"col-xs-12 col-md-12 col-lg-12 \" >\n                ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.regTermsAndConditions : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        </div>\n        <div class=\"col-xs-12 col-md-12 col-lg-12 ga-footer\">\n                Already registered? <a class=\"open-login-view\" data-view=\"login\">Sign In</a>\n        </div>\n</div>";
},"useData":true});
Handlebars.registerPartial('templates/loginRegister/registrationFooterCommon', t);
Handlebars.registerPartial('templates/loginRegister/registrationFooterCommon', t);
return t;
});
/* END_TEMPLATE */
;