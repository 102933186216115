
define('HomeUtils',['require','jquery','GoogleAnalyticsUtils','handlebars'],function (require) {
    "use strict";

    var $ = require("jquery"),
        GAUtils = require("GoogleAnalyticsUtils"),
        Handlebars = require("handlebars"),
        HomeUtils = {};

    HomeUtils.createForm = function (params) {

        try {

            params = params || {};

            var bidType = params.bidType || "",
                listingId = params.listingId || "",
                isProxtBiddingEnabled = params.isProxyBiddingEnabled || null,
                amount = params.amount || "",
                url = params.url || "",
                bidForm;

            if (!params.bidType || !params.listingId || !params.url || (!params.amount && params.amount !=0)) return false;

            if (typeof amount === "string")
                amount = amount.replace(/[\$\,]/g, '');
            if(amount == ''){
                amount = 0;
            }
            var bidAmountName = 'bidAmount';
            var bidTypeName = 'bidType';

            if (bidType == 'PLACE_OFFER' || bidType == 'PLACE_BACKUP_OFFER'){
                bidAmountName = 'offerAmount';
                bidTypeName = 'offerType';
            }
            
            // Remove if already exists 
            $("#submitBidForm").remove();
            if(isProxtBiddingEnabled){
                bidForm = $('<form>', {
                    'id': 'submitBidForm',
                    'action': url,
                    'method': 'POST',
                    'target': '_top'
                }).append($('<input>', {
                    'name': bidTypeName,
                    'value': bidType,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'propertyId',
                    'value': listingId,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': bidAmountName,
                    'value': amount,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'tempUserId',
                    'value': '',
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'previousBidEmailId',
                    'value': '',
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'isEditMode',
                    'value': 'false',
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'fcProxyBidAuction',
                    'value': 'true',
                    'type': 'hidden'
                }));
            } else {
                bidForm = $('<form>', {
                    'id': 'submitBidForm',
                    'action': url,
                    'method': 'POST',
                    'target': '_top'
                }).append($('<input>', {
                    'name': bidTypeName,
                    'value': bidType,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'listingId',
                    'value': listingId,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': bidAmountName,
                    'value': amount,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'tempUserId',
                    'value': '',
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'previousBidEmailId',
                    'value': '',
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'isEditMode',
                    'value': 'false',
                    'type': 'hidden'
                }));
            }

            $('body').append(bidForm);

            return true;

        } catch (error) {
            GAUtils.eventTracking.trackevent("Error", "Homepage", "file:HomeUtils.js|function:createForm|" + error, null, 1);
        }
    };

    HomeUtils.buildTimer = function (params) {
        try {

            params = params || {};

            var formattedTime = params.formattedTime,
                timerHtml = $('#timerTemplate').html(),
                timerTemplate;

            if (!timerHtml) return {
                formattedTime: null
            };

            timerTemplate = Handlebars.compile(timerHtml);

            if (formattedTime.days.value >= 1) {
                if (formattedTime.days.value < 100) {
                    formattedTime.days.value = ("00" + formattedTime.days.value).substr(-2);
                } else {
                    formattedTime.days.value = ("000" + formattedTime.days.value).substr(-3);
                }
                formattedTime.showHour = true;
                formattedTime.showSeconds = false;
            } else {
                formattedTime.showHour = false;
                formattedTime.showSeconds = true;
            }

            formattedTime.hours.value = ("00" + formattedTime.hours.value).substr(-2);
            formattedTime.minutes.value = ("00" + formattedTime.minutes.value).substr(-2);
            formattedTime.days.label = 'Days';
            formattedTime.hours.label = 'Hours';
            formattedTime.minutes.label = 'Mins';
            formattedTime.seconds.label = 'Secs';

            formattedTime.seconds.value = ("00" + formattedTime.seconds.value).substr(-2);

            return timerTemplate({
                formattedTime: formattedTime
            });

        } catch (error) {
            GAUtils.eventTracking.trackevent("Error", "Homepage", "file:HomeUtils.js|function:buildTimer|" + error, null, 1);
        }
    };
    return HomeUtils;

});
