
/*jslint browser:true*/
/*global define, root*/
define('mobileVerificationView',['require','jquery','underscore','backbone','jqueryUI','bootstrap','pdpBidCenter','handlebars','GoogleAnalyticsUtils','commonutils','hbs!tpl/mobileVerification/mobileVeriTemp','loginRegisterView','phoneVerification'],function (require) {
    'use strict';
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone"),
        jqueryUI = require("jqueryUI"),
        Bootstrap = require("bootstrap"),
        pdpBidCenter = require("pdpBidCenter");


    var Handlebars = require("handlebars");
    //  var PhoneVerification = require('phoneVerification');

    var GAUtils = require("GoogleAnalyticsUtils");
    var Utils = require("commonutils");
    // var app = require('apputils');
    var mobileVeriTemp = require("hbs!tpl/mobileVerification/mobileVeriTemp");
    var loginRegisterView = require("loginRegisterView");
    var PhoneVerification = require("phoneVerification");
    var bidCenterView;

    var mobileVerificationView = Backbone.View.extend({
        el: "body",

        events: {
            "click .confirm-registration-sec .mv-confirm-btn": "validateVerificationCode",
            "click .mv-regen-btn": "resendVerificationCode",
            "click .profile-link": "renderProfileScreen",
            "click .save-continue": "updateProfileDetails",
            "click #modal-head-phone .closeButton": "removePopups"
        },


        initialize: function () {
            try {
                $('.mv-confirm-btn-later').click(function () {
                    Utils.redirect('/') // redirect to home when user clicks do it later
                });
                this.mobileVerificationCheck = false;
                this.mobileVerificationCheckVal = saveListDrawerContent.mobileVerificationContent.config.mobileVerificationEnabled || 'N';
                this.user = {};
                this.srcOrigin = false;
                this.profileUpdateType = "updateProfile";
				this.isProxyBidRegEnabled = 1;
                if (this.mobileVerificationCheckVal == 'Y') {
                    this.mobileVerificationCheck = true;
                }
                this.isMobile = $(window).width() < 768 ? true : false;

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Mobile Verification", "file:mobileVerificationView.js|function:initialize|" + e, null, 1);
            }
        },
        phoneNumberFormat: function (phoneNumber) {
            try {
                params = params || {};
                var value = phoneNumber,
                    cleaned, match;

                if (!value) return;

                cleaned = ('' + value).replace(/\D/g, '')

                match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Mobile Verification", "file:mobileVerificationView.js|function:phoneNumberFormat|" + e, null, 1);
            }
        },
        renderProfileScreen: function (options) {
            try {
                var self = this;
                window.currentActionLabel = "Confirm your profile popup close click";
                window.currentScreenLabel = "Update your profile";
                var phoneNumber = this.user.primaryPhone || userSession.phone || '';
                var firstName = this.user.userFirstName || userSession.firstName || '';
                var lastName = this.user.userLastName || userSession.lastName || '';
                var emailId = this.user.userEmailId || userSession.emailId || '';
                var scrType = options.updateType || 'confirmProfile', param = {};
                if (typeof options !== "undefined" && options.updateType == "phoneUpdate") {
                    saveListDrawerContent.mobileVerificationContent.showProfile = false;
                    this.profileUpdateType = "phoneUpdate";
                    $('#leanLoginFrom').hide();
                    $('#registrationForm').hide();
                    $('.social-login-section').hide();
                } else {
                    saveListDrawerContent.mobileVerificationContent.showProfile = true;
                }
                param = { "firstName": firstName, "lastName": lastName, "phoneNumber": self.phoneNumberFormat(phoneNumber), "emailId": emailId };
                if (document.body.classList.contains('register-page-container')) {
                    $('#mobile-verification-body').hide();
                    $('#modal-head-phone .h-ver-head').text('Confirm Your Profile Information');
                    if (saveListDrawerContent.mobileVerificationContent.showProfile) {

                        $('.row-phone-txt').text('Make sure your profile information is correct.');
                        $('#mobile-first-name').val(param.firstName);
                        $('#mobile-last-name').val(param.lastName);
                        $('#mobile-email').val(param.emailId);
                        $('#mobile-phone-number').val(param.phoneNumber);
                    } else {
                        $('#mobile-verification').show(); // 
                        $('#modal-head-phone .h-ver-head').text('Add your phone number');
                        $('.row-phone-txt').text("To bid on Hubzu properties you'll need to add your phone number to your profile.");
                        $('#mobile-first-name').hide();
                        $('#mobile-last-name').hide();
                        $('#mobile-email').hide();
                        $('#mobile-phone-number').val(param.phoneNumber);
                    }
                } else {
                    $('.modal-content').removeClass('activeStage');
                    var mobileVerHead = $("#mobileVerHead").html();
                    var mobileVerHead = Handlebars.compile(mobileVerHead);
                    saveListDrawerContent.mobileVerificationContent.popupTitle = saveListDrawerContent.mobileVerificationContent.phoneVerification.phoneVerTitles[scrType] || "Confirm Your Profile Information";
                    mobileVerHead = mobileVerHead({
                        content: saveListDrawerContent.mobileVerificationContent,
                        values: param
                    });

                    $('#modal-head-phone').html(mobileVerHead);
                    var mobileVerTemp = $("#userdetSrc").html();
                    var mobileVerTemp = Handlebars.compile(mobileVerTemp);
                }
                var propertyId = typeof window.propertyId != 'undefined' ? window.propertyId : '';
                var listingId = listingId ? "Listing Id: " + listingId : 'Property Id:' + propertyId;
                var userId = (typeof window.userSession !== "undefined") && userSession.userId || '';
                var eventLabel = 'UID : ' + userId + ' | Visitor Id :' + $.cookie('visitorId') + ' | ' + listingId + "| Screen name: Phone verification";
                GAUtils.eventTracking.trackevent("", "View Profile click", eventLabel);

                var val_data = {};
                val_data.firstName = "";

                if (Object.keys(options).length > 0 && typeof options.error != "undefined" && options.error !== '' && options.error !== null) {
                    var errorMessage = saveListDrawerContent.registration.errorMessages.phoneErrorMessages[options.error]
                    $('#error-ver-phone').html(errorMessage).removeClass('hide');
                }
                if (document.body.classList.contains('register-page-container')) {
                    $('#appleSocialLoginModal').hide(); // hide apple form
                    $('.update-profile').show(); // on click of update profile
                } else {
                    $('#mobile-verification').addClass('activeStage');
                    mobileVerTemp = mobileVerTemp({
                        content: saveListDrawerContent.mobileVerificationContent,
                        values: param
                    });
                    $("#mobile-verification-body").html(mobileVerTemp);
                }
                $("#ajax-loader,#ajax-loader-mask").hide();
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Mobile Verification", "file:mobileVerificationView.js|function:renderProfileScreen|" + e, null, 1);
            }
        },
        updateProfileDetails: function (e) {
            try {
                var elem = e.target,
                    formClassName = $(elem).attr('formClassName'),
                    data = {},
                    self = this;
                data.firstName = $('.' + formClassName + ' .first-name').val() || this.user.userFirstName || userSession.firstName || '';
                data.lastName = $('.' + formClassName + ' .last-name').val() || this.user.userLastName || userSession.lastName || '';
                data.phoneNumber = $('.' + formClassName + ' .phoneNumber').val() || this.user.primaryPhone || userSession.phone || '';
                data.phoneNumber = Utils.unformatPhoneNumber(data.phoneNumber);

                $("#ajax-loader-mask,#ajax-loader").show();
                Utils.fetch({
                    type: "POST",
                    data: data,
                    url: contextPath + "/portal/lean-regn/updateUserDetails",
                    callback: function (options) {

                        var response = options.response || {},
                            apiStatus = options.status || 'ERROR',
                            status = response.status || 'ERROR';

                        if (apiStatus !== "SUCCESS") {
                            callback({
                                status: 'FAILURE',
                                errorCode: 'We are facing technical difficulties, please try again'
                            });
                            return;
                        }

                        if (status === "FAILURE" && response.errorCode && response.errorCode === "SESSION_EXPIRED") {
                            // TODO : login popup
                            loginRegisterView.openLoginPopup({
                                callback: self.loginCallback,
                                viewObject: {
                                    callback: self.sendVerificationCode.bind(self),
                                    params: params
                                },
                                statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                            });
                            return;
                        }
                        if (status == "SUCCESS") {
                            self.user.userFirstName = data.firstName;
                            self.user.userLastName = data.lastName;
                            self.user.primaryPhone = data.phoneNumber;
                            var propertyId = typeof propertyId != 'undefined' ? window.propertyId : '';
                            var listingId = listingId ? "Listing Id: " + listingId : 'Property Id:' + propertyId;

                            self.checkMobileVerificationRequired({},'fromUpdate');

                            var userId = (typeof window.userSession !== "undefined") && userSession.userId || '';
                            var eventLabel = 'UID : ' + userId + ' | Visitor Id :' + $.cookie('visitorId') + ' | Property Id:' + window.propertyId;
                            if ($("#mobile-verification-body .h-input-section").hasClass("row-email-input")) {
                                GAUtils.eventTracking.trackevent("", "Update profile click", eventLabel + " | Screen name: Update your profile");
                            } else {
                                GAUtils.eventTracking.trackevent("", "Save and Continue click", eventLabel + " | Screen name: Add your phone number");
                            }

                        }
                        if (typeof callback != 'undefined')
                            callback(response);
                        $("#ajax-loader-mask,#ajax-loader").hide();

                    }
                });
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Mobile Verification", "file:mobileVerificationView.js|function:updateProfileDetails|" + e, null, 1);
            }
        },

        removePopups: function() {
            $('#loaderBG').hide();
        },

        checkMobileVerificationRequired: function (options, flag) {
            try {
                var phoneNumber = this.user.primaryPhone || userSession.phone || '';
                var self = this, callbackOptions = options, param = {};
                self.fromMobileVerification = false;
                this.callbackOptionsView = callbackOptions;
                if (!phoneNumber) {
                    var options = { "updateType": "phoneUpdate" };
                    self.renderProfileScreen(options);
                    if (window.globalAlertView && window.globalAlertView.submitFromAlertView) {
                        window.globalAlertView.renderGlobalAlerts();
                    }
                    return;
                }
                var propertyId = typeof window.propertyId != 'undefined' ? " | Property Id: " + window.propertyId : '';
                var userId = (typeof window.userSession !== "undefined") && window.userSession.userId || 'NA';
                var trackText = 'UID : ' + userId + ' | Visitor Id :' + $.cookie('visitorId');

                var eventLabel = trackText + propertyId + "| Screen name: Phone verification";


                //GAUtils.eventTracking.trackevent("", "Phone update Successful", eventLabel); 
                var dataObject = {
                    formObject: '#loginRegisterDialog'
                };
                PhoneVerification.getPhoneNumberStatus({
                    phoneNumber: phoneNumber,
                    callback: function (response) {
                        if (!response.isValid) {
                            self.mobileVerificationCheck = false;
                            phoneNumber = self.phoneNumberFormat(phoneNumber);
                            param.phoneNumber = PhoneVerification.applyPhoneMaskFormat({ "phone": phoneNumber });
                            if (document.body.classList.contains('register-page-container')) {
                                $('#leanLoginFrom').hide();
                                $('#registrationForm').hide();
                                $('.social-login-section').hide(); // hide scocial login for phone verification
                            } else {
                                $('#loginRegisterDialog .modal-content').removeClass('activeStage');
                                var mobileVerTemp = $("#mobileVerTemp").html();
                                var mobileVerTemp = Handlebars.compile(mobileVerTemp);
                                saveListDrawerContent.mobileVerificationContent.popupTitle = saveListDrawerContent.mobileVerificationContent.phoneVerification.phoneVerTitles.phoneVerification || "Phone Number Verification";
                                mobileVerTemp = mobileVerTemp({
                                    content: saveListDrawerContent.mobileVerificationContent,
                                    values: param
                                });
                                var mobileVerHead = $("#mobileVerHead").html();
                                var mobileVerHead = Handlebars.compile(mobileVerHead);
                                mobileVerHead = mobileVerHead({
                                    content: saveListDrawerContent.mobileVerificationContent,
                                    values: param
                                });
                                $('#modal-head-phone').html(mobileVerHead);
                            }

                            window.currentScreenLabel = "Phone verification";
                            window.currentActionLabel = "Phone verification popup close click";
                            //hide register form and add phone verification form
                            if (document.body.classList.contains('register-page-container')) {
                                if(flag === 'fromUpdate') {
                                    $('.update-profile').hide(); // hide update profile form 
                                    $('#appleSocialLoginModal').hide(); // hide apple form
                                }
                                $('#mobile-verification').show();
                                $('#mobile-verification-body').show();
                                $('#modal-head-phone .h-ver-head').text(saveListDrawerContent.mobileVerificationContent.popupTitle);
                                $('.secure-no').text(param.phoneNumber); // updating masked phone number
                            } else {
                                $('#mobile-verification').addClass('activeStage');
                                $("#mobile-verification-body").html(mobileVerTemp);
                            }
                            $("#ajax-loader,#ajax-loader-mask").hide();
                            var openPopup = (typeof self.callbackOptionsView != 'undefined' && (self.callbackOptionsView.fromGlobalAlert || self.callbackOptionsView.openPopup)) || false;
                            if (openPopup) {
                                $('#loginRegisterDialog').modal("show");
                            }

                            $('.notifications-slider').css('z-index', 1003);
                            $('.notifications-slider-shade').css('z-index', 1002);

                            var locationUrl = window.location.href;
                            if (locationUrl.indexOf('/property/') > -1 && typeof golbalPDPView != 'undefined') {

                                $('#lightbox, .pdp-gallery-lightbox').modal('hide');
                            }

                            self.resendVerificationCode();
                        } else {
                            self.fromMobileVerification = false;
                            if (typeof self.callbackOptionsView != 'undefined' && typeof self.callbackOptionsView.callBackFunc != 'undefined') {
                                if (typeof self.callbackOptionsView.callBackType != 'undefined' && (self.callbackOptionsView.callBackType == 'saveList' || self.callbackOptionsView.callBackType == 'searchresult')) {
                                    self.callbackOptionsView.callBackFunc(null, self.callbackOptionsView.callBackObj);
                                } else if (typeof self.callbackOptionsView.placeBidEvent != 'undefined') {
                                    self.fromMobileVerification = true;
                                    self.callbackOptionsView.callBackFunc(self.callbackOptionsView.placeBidEvent);
                                } else {
                                    self.callbackOptionsView.callBackFunc();
                                    Utils.showSuccessRegistration(dataObject);
                                    if (window.globalAlertView) window.globalAlertView.hideAlertContainer();

                                    return false;
                                }
                            } else {
                                if (proxyBidView.isProxyBidRegEnabled && self.srcOrigin == 'bidPinConfirmation') {
                                    proxyBidView.renderProxyBidRegister();

                                } else {
                                    $("#ajax-loader").hide();
                                    $("#ajax-loader-mask").hide();
                                    $('#loaderBG').hide();
                                    Utils.showSuccessRegistration(dataObject);
                                    if (window.globalAlertView && !(globalAlertView.submitFromAlertView)) window.globalAlertView.hideAlertContainer();
                                }


                                return false;
                            }
                        }
                        $(".otp-input").on("keypress", function (event) {
                            return self.isNumber(event);
                        });
                        if (self.isMobile) {
                            $("#loginRegisterDialog").css("z-index", "9999999");
                        }

                    }
                });

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Mobile Verification", "file:mobileVerificationView.js|function:checkMobileVerificationRequired|" + e, null, 1);

            }
        },
        isNumber: function (evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                return false;
            }
            return true;
        },
        validateVerificationCode: function (event) {
            try {

                var
                    codes = [],
                    phoneNumber = this.user.primaryPhone || userSession.phone,
                    errorMessage,
                    self = this,
                    propertyId = typeof propertyId != 'undefined' ? propertyId : '',
                    listingId = typeof listingId != 'undefined' ? listingId : '';


                listingId = listingId ? "Listing Id: " + listingId : 'Property Id:' + window.propertyId;
                // remove special chars
                phoneNumber = phoneNumber.replace(/\D/g, '');

                $(".mv-otp-error").addClass('hide');

                $(".mv-input .otp-input").each(function () {
                    codes.push(this.value);
                });

                if (codes.length < 4) {
                    errorMessage = saveListDrawerContent.mobileVerificationContent.phoneVerification.errorMessages['OTP_ERROR'];
                    $(".mv-otp-error").text(errorMessage).removeClass('hide');
                    return;
                }

                var userId = (typeof window.userSession !== "undefined") && userSession.userId || '';
                var eventLabel = 'UID : ' + userId + ' | Visitor Id :' + $.cookie('visitorId') + ' | ' + listingId + "| Screen name: Phone verification";

                GAUtils.eventTracking.trackevent("", "Verify code click", eventLabel);
                if ($(".mv-confirm-btn").hasClass('h-disabled')) {
                    return;
                }
                $(".mv-confirm-btn").addClass('h-disabled');

                PhoneVerification.validateVerificationCode({
                    phoneNumber: phoneNumber,
                    verificationCode: codes.join(''),
                    callback: function (response) {
                        $(".mv-confirm-btn").removeClass('h-disabled');
                        $("#ajax-loader-mask,#ajax-loader").hide();

                        window.currentScreenLabel = "Bidding Registration";
                        window.currentActionLabel = "Bidding Registration popup close click";

                        if (response.status === "SUCCESS" && response.data && response.data.verificationStatus === "approved") {
                            if (typeof pageName != "undefined" && pageName == "pdp") {
                                $(".fcp-action-btn").html(saveListDrawerContent.mobileVerificationContent.placeProxyBidTxt);
                            }

                            GAUtils.eventTracking.trackevent("", "Phone verification successful", eventLabel);
                            if ((loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && (loginRegisterView.proxyBidObj.source == 'searchresult' || loginRegisterView.proxyBidObj.source == 'savelist' || loginRegisterView.proxyBidObj.source == 'recommended' || loginRegisterView.proxyBidObj.source == 'proxyDashboard')) || (proxyBidView.isProxyBidRegEnabled)) {
                                proxyBidView.renderProxyBidRegister();
                            } else {
                                var dataObject = {
                                    formObject: '#loginRegisterDialog'
                                };
                                $('#successContent').html(saveListDrawerContent.mobileVerificationContent.phoneVerification.successMessage).focus();
                                $('#leanRegistrationSuccess').fadeIn();
                                $('#leanRegistrationSuccess').fadeIn('300').delay(5000).fadeOut('slow');

                                if (typeof mobileVerificationView.callbackOptionsView != "undefined" && typeof mobileVerificationView.callbackOptionsView.callBackAfterVerificationFunc != "undefined")
                                    mobileVerificationView.callbackOptionsView.callBackAfterVerificationFunc();
                                if (document.body.classList.contains('register-page-container')) {
                                    Utils.redirect('/') // redirect to home when verify is success
                                }
                                Utils.showSuccessRegistration(dataObject);

                                if (typeof pageName != "undefined" && pageName == "pdp") {
                                    bidCenterView = new pdpBidCenter();
                                    bidCenterView.decideTarget();
                                }

                            }
                            return false;
                        } else {

                            GAUtils.eventTracking.trackevent("", "Phone verification failure", eventLabel);

                            if (response.data && response.data.verificationStatus === "denied")
                                errorMessage = "Denied";

                            if (response.errorCode)
                                errorMessage = saveListDrawerContent.mobileVerificationContent.phoneVerification.errorMessages[response.errorCode] || saveListDrawerContent.mobileVerificationContent.phoneVerification.errorMessages['OTP_ERROR'];

                            errorMessage = errorMessage || saveListDrawerContent.mobileVerificationContent.phoneVerification.errorMessages['OTP_ERROR'];

                            $(".mv-otp-error").text(errorMessage).removeClass('hide');
                            //element.removeClass("hm-verified").addClass("hm-pending");
                        }
                    }
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Mobile Verification", "file:mobileVerificationView.js|function:validateVerificationCode|" + error, null, 1);
            }
        },

        resendVerificationCode: function (event) {
            try {

                //var elementName = $(event.target).attr('data-input-element'), 
                //element = $(elementName),
                var phoneNumber = this.user.primaryPhone || userSession.phone,
                    self = this,
                    data, errorMessage,
                    mode = 'sms',
                    gaAction = "action",//$(event.target).attr("data-ga-action"),
                    hasError = false;
                var propertyId = typeof window.propertyId != 'undefined' ? window.propertyId : '';
                var listingId = listingId ? "Listing Id: " + listingId : 'Property Id:' + propertyId;


                var userId = (typeof window.userSession !== "undefined") && userSession.userId || '';
                var eventLabel = 'UID : ' + userId + ' | Visitor Id :' + $.cookie('visitorId') + ' | ' + listingId + " | Screen name: Phone verification";
                errorMessage = saveListDrawerContent.registration.errorMessages.phoneErrorMessages['techError'];

                if (event)
                    GAUtils.eventTracking.trackevent("", "Resend SMS click", eventLabel);



                // remove special chars
                phoneNumber = phoneNumber.replace(/\D/g, '');

                //GAUtils.eventTracking.trackevent("", gaAction, gaLabel); 

                //$(event.target).addClass('h-disabled')

                PhoneVerification.sendVerificationCode({
                    phoneNumber: phoneNumber,
                    verificationType: mode,
                    callback: function (response) {

                        //    $(event.target).removeClass('h-disabled')

                        $('.otp-input').prop('disabled', false);
                        $('#error-ver').addClass('hide');
                        $('#error-ver-phone').addClass('hide');
                        if (response.status === "FAILURE" && response.errorCode && response.errorCode === "PHONE_NUMBER_ALREADY_VERIFIED") {
                            errorMessage = saveListDrawerContent.registration.errorMessages.phoneErrorMessages[response.errorCode] || errorMessage;

                            $('.otp-input').attr('disabled', 'disabled');
                            $('#error-ver').html(errorMessage).removeClass('hide');
                            $('#error-ver-phone').html(errorMessage).removeClass('hide');
                            if (proxyBidView.isProxyBidRegEnabled) {
                                proxyBidView.renderProxyBidRegister();
                            } else {
                                $("#ajax-loader").hide();
                                $("#ajax-loader-mask").hide();
                                $('#loaderBG').hide();
                                var dataObject = {
                                    formObject: '#loginRegisterDialog'
                                };
                                Utils.showSuccessRegistration(dataObject);

                            }

                            return;

                        } else if (response.status === "FAILURE" && response.errorCode && response.errorCode === "PHONE_NUMBER_ALREADY_IN_USE") {
                            errorMessage = saveListDrawerContent.registration.errorMessages.phoneErrorMessages[response.errorCode]
                           // $('#error-ver-phone').html(errorMessage).removeClass('hide');
                            self.renderProfileScreen({ error: response.errorCode });
                            return;
                        }

                        if (response.status === "FAILURE" &&
                            response.errorCode &&
                            (response.errorCode === "INVALID_PHONE_NUMBER" || response.errorCode === "VERIFICATION_CODE_LIMIT_EXEEDED" || response.errorCode === "LANDLINE_NOT_SUPPORTED")) {
                            errorMessage = saveListDrawerContent.mobileVerificationContent.phoneVerification.errorMessages[response.errorCode] || saveListDrawerContent.mobileVerificationContent.phoneVerification.errorMessages['DEFAULT_ERROR'];
                            $('#error-ver').html(errorMessage).removeClass('hide');
                           // $('#error-ver-phone').html(errorMessage).removeClass('hide');
                            $('.otp-input').attr('disabled', 'disabled');


                        }

                        if (response.status === "SUCCESS" && response.data && response.data.verificationStatus === "pending") {
                            data = {
                                phoneNumber: phoneNumber.substr(6),
                                hasError: false
                            }
                            $('.otp-input').prop('disabled', false);

                        } else {
                            errorMessage = saveListDrawerContent.mobileVerificationContent.phoneVerification.errorMessages[response.errorCode] || saveListDrawerContent.mobileVerificationContent.phoneVerification.errorMessages['DEFAULT_ERROR'];
                            data = {
                                hasError: true,
                                errorMessage: errorMessage
                            }
                            hasError = true;
                            $('#error-ver').html(errorMessage).removeClass('hide');
                           // $('#error-ver-phone').html(errorMessage).removeClass('hide');
                            $('.otp-input').attr('disabled', 'disabled');

                        }

                        //    data.content = _.clone(cifContent.purchaseProfile.phoneVerification);
                        //  data.content.retryOptionsLabel = data.content.retryOptionsLabel.replace(/(###INPUT###)/g, elementName);
                        //data.content.subHeading = data.content.subHeading.replace(/(###PHONE###)/g, phoneNumber.substr(6));

                        // phoneVerificationPopup
                        // $(".cif-popup-container")
                        //   .addClass('h-mobile-verification-modal')
                        // .html(phoneVerificationPopup(data));

                        //$(".cif-popup-container").modal("show");

                        self.attachOtpEvents({ hasError: hasError });
                    }
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Mobile Verification", "file:mobileVerificationView.js|function:validateVerificationCode|" + error, null, 1);
            }
        },
        attachOtpEvents: function (params) {
            try {

                params = params || {};

                var checkOtpLength,
                    self = this,
                    gaLabel = ($.cookie('visitorId') || 'NA') + ' | ' + ($.cookie('userId') || 'NA');



                if (params.hasError) return;

                checkOtpLength = function () {
                    var codes = [];
                    $(".mv-input .otp-input").each(function () {
                        if (this.value) codes.push(this.value);
                    });
                    if (codes.length === 4) {
                        $(".mv-confirm-btn").removeClass('h-disabled').focus();
                    } else {
                        $(".mv-confirm-btn").addClass('h-disabled');
                    }
                }

                $(".mv-input .otp-input").unbind('keyup').bind('keyup', function (event) {
                    if (event.target.value.length > 0) {
                        $(event.target).next().focus();
                    }
                    checkOtpLength();
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Mobile Verification View", "file:mobileVerificationView.js|function:attachOtpEvents|" + error, null, 1);
            }
        },

        verifyDuplicatePhoneNumber: function (params) {
            try {
                params = params || {};
                var callbackObj = params.compObj || null;
                var callback = params.callback || null;
                var event = (params.options && params.options.event) ? params.options.event : null;
                var element = (params.options && params.options.element) ? params.options.element : null;
                var value = params.phoneNumber.replace(/\D/g, '');
                var phoneDuplicateURI = contextPathPortal + "/isPhoneNumberInUse?phoneNumber=" + value;

                $.ajax({
                    type: "GET",
                    url: phoneDuplicateURI,
                    success: function (result) {
                        if (result.status == "SUCCESS" && !result.data) {
                            callbackObj.duplicatePhoneSucessCallback(callback, params.options);
                        } else {
                            result.event = event;
                            result.element = element;
                            callbackObj.duplicatePhoneErrorCallback(result);
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        var data = {
                            status: 'techError',
                            event: event,
                            element: element
                        };
                        callbackObj.duplicatePhoneErrorCallback(data);
                    }
                });
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Mobile Verification View", "file:mobileVerificationView.js|function:verifyDuplicatePhoneNumber|" + error, null, 1);
            }
        }
    });


    return new mobileVerificationView();
});