
define('firebaseApp',['jquery', 'GoogleAnalyticsUtils'],function($, GAUtils) {
	
	'use strict';
	
	var Firebase = function(){
		this.appInstance = null;
	};

	Firebase.prototype.init = function(options) {

		var config = options || {},
			firebaseInstance = typeof firebase !== 'undefined' && firebase || null;
	
		if(firebaseInstance) {

			if(!this.appInstance) {
				this.appInstance = firebaseInstance.initializeApp(options);
			}

			return this.appInstance;  	

		} else {
			return null; 
		}
		
	};

	Firebase.prototype.authenticate = function(options, callback) {
        try {
            var data = options || {},
                token = options.token || null,
                email = options.email || null,
                password = options.password || null,
                self = this;

            self.appInstance.auth().signInWithCustomToken(token)
            .then(function(user){
                callback(null, user);
            })
            .catch(function(error) {
                callback(error);
            });   
        } catch (e) {
            GAUtils.eventTracking.trackevent("Error", "GlobalAlerts", "file:firebaseApp.js|function:authenticate|" + e, null, 1);
        }
	};

	return new Firebase();
});	 