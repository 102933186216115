
/* START_TEMPLATE */
define('hbs!tpl/globalIdologyVerification/scanIdUrlLink',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.userIdologyDetailsDto : depth0)) != null ? stack1.mailSent : stack1), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "			<div class=\"form-group\">\n		    	<div class=\"col-xs-12 col-sm-12 col-md-12 font12\">  \n		   			";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.authFailMax : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n		   		</div>   \n			</div>\n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "			<div class=\"form-group\">\n		    	<div class=\"col-xs-12 col-sm-12 col-md-12 font12\">  \n		   			";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.authAccountLock : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n		   		</div>   \n			</div>\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.userIdologyDetailsDto : depth0)) != null ? stack1.resetCountExceeded : stack1), {"name":"if","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "				<div class=\"form-group\">\n			    	<div class=\"col-xs-12 col-sm-12 col-md-12 font12\" >  \n			   			";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.scanUrlDesc : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n			   		</div>   \n				</div>\n				<div class=\"form-group\">\n					<div class=\"col-xs-12 col-sm-12 col-md-12 font12\" style=\"text-align: center\">\n				    	<button  type=\"button\" class=\"btn btn-primary font12 sendScanUrl\">		\n			   				"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.scanLink : stack1), depth0))
    + "\n			   			</button>\n		   			</div>   \n				</div>\n";
},"7":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "		<div class=\"form-group\">\n	    	<div class=\"col-xs-12 col-sm-12 col-md-12 font12\">  \n	   			";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.hardFail : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n	   		</div>   \n		</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "\n<div class=\"form-horizontal idScanUrlDiv\"  >\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.userIdologyDetailsDto : depth0)) != null ? stack1.scanAvailable : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(7, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n<div class=\"form-horizontal  hide idScanUrlSuccess\" >\n	";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.config : stack1)) != null ? stack1.idScanSuccess : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n</div> \n";
},"useData":true});
Handlebars.registerPartial('tpl/globalIdologyVerification/scanIdUrlLink', t);
return t;
});
/* END_TEMPLATE */
;