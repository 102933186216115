
/*jslint browser:true*/
/*global define, root*/
define('loginRegisterView',['require','jquery','underscore','backbone','jqueryUI','bootstrap','handlebars','GoogleAnalyticsUtils','commonutils','apputils','validator','hbs!tpl/loginRegister/loginRegister','hbs!tpl/loginRegister/loginRegisterConfirmation','hbs!tpl/loginRegister/newRegisterationView','forgeCryptoJs','socialLoginView'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone"),
        jqueryUI = require("jqueryUI"),
        Bootstrap = require("bootstrap");
        //MobileVerificationView = require("mobileVerificationView");

    var Handlebars = require("handlebars");
    var GAUtils = require("GoogleAnalyticsUtils");
    var Utils = require("commonutils");
    var app = require('apputils');
    var validator = require("validator");
    var loginRegisterTemp  =  require("hbs!tpl/loginRegister/loginRegister");
    var loginRegisterConfirmationTemp  =  require("hbs!tpl/loginRegister/loginRegisterConfirmation");
    var newregistrationTemp = require("hbs!tpl/loginRegister/newRegisterationView");
    //var MobileVerificationView = require("hbs!tpl/loginRegister/MobileVerificationView");
    var forge = require("forgeCryptoJs");
    var isSocialLoginEnabled = "N";
    var isInternalUser = 'N';
    var isEnableAppleLogin = 'N';
    var isIntroduceHubzuEnabled = "N";
    if(typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.socialLoginConfig != 'undefined' && typeof saveListDrawerContent.socialLoginConfig.isSocialLoginEnable != 'undefined' && saveListDrawerContent.socialLoginConfig.isSocialLoginEnable == "Y"){
      isSocialLoginEnabled = "Y";
      isEnableAppleLogin = saveListDrawerContent.socialLoginConfig.isEnableAppleLogin == 'Y' ? 'Y' : 'N';
      var socialLoginView = require('socialLoginView');  
    }
    if(typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.introduceHubzuConfig != 'undefined' && saveListDrawerContent.introduceHubzuConfig.enableIntroducePopup == 'Y'){
        if ((typeof introduceHubzuEnabled != "undefined" && introduceHubzuEnabled )){
            isIntroduceHubzuEnabled = "Y";
        }
    }
    
    var gaLabel = 'visitorId: ' + $.cookie('visitorId');
    var userSession = window.userSession || {};
    var userId = userSession.userId || null;
    if(userId){
         gaLabel += '|userId: '+ userId;
    }
    var eventLabel = gaLabel; 

    var loginRegisterView = Backbone.View.extend({
        
        el: $('body'),
        callback: null,
        viewObject: null,
        componentsCmsNode: {},
        networkCallInProgress: false,
        proxyBidObj : {},
        events: {
            'click .register-free-btn' : "openRegistrationFromHome",
            'click #loginRegisterPopup,#loginRegisterPopupDrawer' : "openLoginRegister",
            'click #loginNewPopup,#loginNewPopupDrawer' : "openLoginRegister",
            'submit #userLeanRegistration':"submitRegistrationData",
            "blur .name" : "validateNameField",
            "blur #emailId" : "validateEmailField",
            "blur #leanRegPassword" : "validatePasswordField",
            "blur #leanRegRePassword" : "validateRePasswordField",
            // "blur #leanPhoneNo" : "validatePhoneField",
            "click #loginEmailAddress, #loginPassword,#leanRegFirstName,#leanRegLastName,#emailId,#leanRegPassword,#forgotPasswordEmailId,#leanPhoneNo" : "changeIcon",
            "keyPress #loginEmailAddress, #loginPassword,#leanRegFirstName,#leanRegLastName,#emailId,#leanRegPassword,#forgotPasswordEmailId,#leanPhoneNo" : "changeIcon",
            "focus #loginEmailAddress, #loginPassword,#leanRegFirstName,#leanRegLastName,#emailId,#leanRegPassword,#forgotPasswordEmailId,#leanPhoneNo" : "changeIcon",
            "blur #loginEmailAddress" : "validateLoginEmailFieldBlur",
            "blur .loginEmailField" : "validateLoginEmailField",
            "blur #leanPhoneNo,.phoneNumber" : "validatePhoneNumber",
            "keypress .leanPhoneField,.phoneNumber" : "checkPhoneKeyPress",

            "blur #forgotPasswordEmailId" : "validateForgotEmailField",
            "blur #loginPassword" : "validateLoginPasswordField",
            "click .showRegisterView" : "showRegistration",
            "click #registerationDiv,.mobileRegisterLabel, .h-reg-link" : "showRegistration",
            "click #loginDiv,.mobileSignInLabel" : "showLogin",
            'click #backToSignIn' : "backToSignIn",
            "click #leanRegNotifyBuyerAgentFlag" : "updateNotifyAgent",
            "click #closeRegisterLogin, .closeButton" : "hideLoginRegisterPopup",
            "submit #userNewLogin" : "login",
            "click .newForgotPasswordLink" : "showForgotPassword",
            "submit #userNewPassword" : "submitForgotPassword",
            "click #globalPasswordClose" : "hideLoginRegisterPopup",
            "click #openForgotPasswordView": "forgotPasswordViewForLoggedUser",
            "click .showLoginModal" : "showLoginModal",
            "click #optInSMsRegistration":"showHidePhoneConsentForm",
            "click .h-eye-button" : "togglePasswordText",
            "click .social-login-action": "addGoogleSignInLib",
            "click .apple-social-login-action": "addAppleSignInLib",
           // "click #mobileVerification" : "renderMobileVerification"
           
        },

        initialize: function () {
       	    try{
                var thisObj=this;
                thisObj.mobileVerificationEnabled = false;
                $('.closeButtonSubsConfirmation').click(function(e){
                    $('#leanRegistrationSuccess').css('display','none');
                    $('#errorToastMessage').css('display','none');
                });
                $(window).click(function(){
                    $('.anonymousLimitError').fadeOut();
                });
                if(typeof dashboardContent === "object" && dashboardContent.registration) {
                    this.componentsCmsNode = dashboardContent;
                } else {
                    this.componentsCmsNode = saveListDrawerContent;
                }
                this.isPlaceBidClicked = false;
                this.proxyBidObj.isProxyBiddingEnabled = false;
                
                this.render();
                
                $('#loginRegisterDialog').on('hidden.bs.modal', function (e) {
                    $('.loginRegisterPopup').find('input[type="text"], input[type="password"]').val('');
                    $('.account-icon').removeClass('active');
                }); 
                this.isMobile = $(window).width() < 768 ? true : false;

                // on sign in click
                $('#signInNew').click(function() {
                  $('#userLeanRegistration').hide();
                  $('#userNewLogin').show();
                });

                // on register link click 
                $('.h-reg-link').click(function(){
                  $('#userNewLogin').hide();
                  $('#userLeanRegistration').show();
                });
                // this code is for user when clicked on register other than from header in home and landing in register tab
                var userNavigatedFromHome = localStorage.getItem('isNavFromTourpage');
                if(userNavigatedFromHome =='Y') {
                  $('#userLeanRegistration').show();
                  $('#userNewLogin').hide();
                  localStorage.removeItem('isNavFromTourpage'); // clearing storage.
                }
                this.showLogin('onload');
                Utils.checkIfUserLoggedInFlag(this.processAfterUserstatus, this);
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:initialize|" + e, null, 1);
            }
        },
        openRegistrationFromHome: function() {
          localStorage.setItem('isNavFromTourpage', 'Y');
          Utils.redirect('/register-sign-in');
        },
        processAfterUserstatus: function(isLoggedIn,conTxtObj){
          try{
            var self = conTxtObj;
            var alertUrlParams = saveListDrawerContent.globalAlerts.labels.alertUrlParam;
            var alertUrlPropParams = saveListDrawerContent.globalAlerts.labels.alertUrlPropParams;
            if(!isLoggedIn && isIntroduceHubzuEnabled == "N" && window.location.search.indexOf(alertUrlParams) == -1 && window.location.search.indexOf(alertUrlPropParams) == -1){
                self.addGoogleOneTapLib();
            }
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:processAfterUserstatus|" + e, null, 1);
          }  
        },
        addGoogleSignInLib: function(e){
          try{
              if(globalParam.config.isLoggedin != true && isSocialLoginEnabled == "Y"){
                  var self = this;
                  Utils.loadScript({
                      source: '//apis.google.com/js/client:platform.js',
                      
                      callback: function(error) {
                          if( error ) return;
                          
                          var SOCIAL_LOGIN_CONFIG = saveListDrawerContent.socialLoginConfig[envConf];
                          
                          if(!SOCIAL_LOGIN_CONFIG){
                            SOCIAL_LOGIN_CONFIG = saveListDrawerContent.socialLoginConfig["DEFAULT"];
                          }
                          gapi.load('auth2', function(){
                                gapi.auth2.init(JSON.parse(SOCIAL_LOGIN_CONFIG.googleConfig.keyObj)).then(function () {
                                  
                                  GoogleAuth = gapi.auth2.getAuthInstance();
                                  
                                  self.socialLoginView = new socialLoginView("google");
                                  
                                  self.continueWithSocialLogin(null,'google');  
                              });
                          });
                      }
                  });
              }
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:addGoogleSignInLib|" + e, null, 1);
          }  
        },
        addGoogleOneTapLib: function(e){
          try{
            if(globalParam.config.isLoggedin != true && isSocialLoginEnabled == "Y"){
              var self = this;
              Utils.loadScript({
                  source: '//accounts.google.com/gsi/client',
                  
                  callback: function(error) {

                      if( error ) return;
                      
                      self.socialLoginView = new socialLoginView("googleonetap");
                      
                      self.continueWithSocialLogin(null,'googleonetap');  
                  }
              });
            }
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:addGoogleOneTapLib|" + e, null, 1);
          }
        },

        addAppleSignInLib: function(e){
          try{
            if(globalParam.config.isLoggedin != true && isSocialLoginEnabled == "Y" && isEnableAppleLogin && isEnableAppleLogin == 'Y'){
              var self = this;
              
              // if(!self.socialLoginView){
                self.socialLoginView = new socialLoginView("apple");
              // }
                        
              self.continueWithSocialLogin(null,'apple');

              // Utils.loadScript({
              //     source: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
                  
              //     callback: function(error) {
              //         if(error) return;
                      
              //         var SOCIAL_LOGIN_CONFIG = saveListDrawerContent.socialLoginConfig[envConf];
                      
              //         if(!SOCIAL_LOGIN_CONFIG){
              //           SOCIAL_LOGIN_CONFIG = saveListDrawerContent.socialLoginConfig["DEFAULT"];
              //         }
              //         var rawNonce = JSON.stringify(self.getRandomString(20));
              //         var state = JSON.stringify(self.getRandomString(20));
              //         AppleID.auth.init({
              //           clientId : 'com.hubzu.internal',
              //           scope : 'name email',
              //           redirectURI : 'http://buy-bid-participation-feature-web-qe.internal.hubzu.com/',
              //           state : state,
              //           nonce : rawNonce,
              //           usePopup : false //or false defaults to false
              //         }).then(function () {
              //           console.log('welcome to apple login');
              //           self.socialLoginView = new socialLoginView("apple");
                        
              //           self.continueWithSocialLogin(null,'apple');
              //       });
              //     }
              // });
            }
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:addGoogleSignInLib|" + e, null, 1);
          }  
        },

        getRandomString: function () {
          let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          let result = '';
          for (let i = 0; i < length; i++) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length))
          }
          return  result;
        },
        
        continueWithSocialLogin: function(event,source){
          try{
            var self =  this
            var socialSource = source || $(event.currentTarget).attr("data-source");
            var options = {
              socialSource: socialSource,
              viewObject: self.viewObject || self,
              callback: self.callback || null,
              funnelStart: self.funnelStart || false,
              srcName : self.srcName || false
            }
            
            /**
             * If social login clicked from global alerts, refresh the alert drawer after login
             */
            if ($('.gobal-alerts-drawer-slider').hasClass('is-visible')) {
              options.callback = function(){
                if(window.globalAlertView) window.globalAlertView.renderGlobalAlerts();
              }
            }

            self.socialLoginView.doSocialLogin(options);

            if(socialSource != 'googleonetap'){
              var eventLabelSocial = eventLabel;
              if(socialSource ==  'google'){
                eventLabelSocial += '|Social Login: Google';
              } else if(socialSource == 'apple'){
                eventLabelSocial += '|Social Login: Apple';
              }
              var eventAction = '';
              if($('.gobal-alerts-drawer-slider').hasClass('is-visible')){
                eventAction = 'Global Alerts Social Login Sign in click';
                if($('.ga-register-container').hasClass('active')){
                  eventAction =  'Global Alerts Social Login registration click';
                }
              }else{
                if($('body').hasClass("h-srp-page") && ($('.popover').hasClass('in') || $('#commonConfirmPopup').hasClass('in'))){
                  eventAction = 'Quick Register Google click';
                }else{
                  eventAction = 'Sign in Social Login click';
                  if($('#registerationDiv').hasClass('liActive')){
                    eventAction =  'Registration Social Login click';
                  }
                }
              }
              GAUtils.eventTracking.trackevent("",eventAction,eventLabelSocial);  
            }
            
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:continueWithSocialLogin|" + e, null, 1);
          }
        },
        togglePasswordText: function(event) {
          try {
            var target = $(event.currentTarget).attr("data-rel");

            if (!target) return;

            target = $(target);

            if (target.attr('type') === "password") {
              target.attr('type', 'text'); 
            } else {
              target.attr('type', 'password'); 
            }

          } catch (error) {
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:togglePasswordText|" + error, null, 1);
          }
        },
        backToSignIn : function(){
            GAUtils.eventTracking.trackevent("New Registration","Back button Click", eventLabel+"|Login");
            this.showLogin();
        },
        changeDisableIcon:function(){
            try{
                $('.form-control').removeClass('clicked');
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:changeDisableIcon|" + e, null, 1);
            }
        },
        changeIcon:function(e){
          try{
            var iconName = $(e.target).attr('iconName');
            $('.'+iconName).addClass('clicked');
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:changeIcon|" + e, null, 1);
          }
        },  
        hideLoginRegisterPopup:function(e){
            try{
              $('body').removeClass('leanLoginRegisterBody');
              $('#loginRegisterDialog').modal('hide');
              $(".modal-backdrop").remove();
              $("#ajax-loader-mask").hide();
              this.openAnonymRegister = 'N';
              this.funnelStart = false;
              var resetPopup = Utils.getCookieDetails('resetPopup') || '';
              var userId = (typeof window.userSession !== "undefined") && window.userSession.userId || 'NA';
              var trackText =  'UID : '+ userId + ' | Visitor Id :'+$.cookie('visitorId');
              if(e.originalEvent && $("#mobile-verification").is(":visible") && !$("#mobile-verification-body").hasClass("fund-manager")){

                if(typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true"){
                  // window.currentActionLabel = "Phone verification popup close click";
                  // window.currentScreenLabel = "Phone verification";
                }else{
                  window.currentActionLabel = "Phone verification popup close click";
                  window.currentScreenLabel = "Phone verification";
                  //GAUtils.eventTracking.trackevent("", "", trackText+" | Screen name: ");       
                }
                
                $('.notifications-slider').css('z-index',10003);
                $('.notifications-slider-shade').css('z-index',10002);
              }else{
                
                GAUtils.eventTracking.trackevent("", "Close register popup click", trackText + " | Screen name: Register popup");     
              }
              // Notify Native app with message 
              if (window.notifyNativeApp) {
                var userPostData = {
                  "message":"LoginPopupClose"
                };
                window.notifyNativeApp(JSON.stringify(userPostData),'*');
              }
              if ( (typeof golbalSRPView !== "undefined" || typeof golbalPDPView !== "undefined" ) && !this.isMobile) {
                    if(resetPopup == ''){
                      Utils.setIntervalToShowRegister();
                    }
              }
              // in case of registration page navigate user to home page
              if (document.body.classList.contains('register-page-container')) {
                 Utils.redirect('/');
              }

            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:hideLoginRegisterPopup|" + e, null, 1);
            }  
        },
        validateNameField : function(event){
            try {
              var obj = '#' + event.target.id,
                  value = $.trim($(obj).val()),
                  errorMessage = (event.target.id === 'leanRegFirstName' || event.target.id === 'leanRegFirstName')
                                  ?this.componentsCmsNode.errorMessages.firstNameIsRequired
                                  :this.componentsCmsNode.errorMessages.lastNameIsRequired;

              if(value === ''){

                $('.' + $(obj).attr('error')).html(errorMessage);
                this.addErrorClass(obj, false);

              } else if(!(validator.isValidName(value, obj))){

                $('.' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.charactersAllowedLabel);
                this.addErrorClass(obj, true);

              }else{
                this.addSuccessClass(obj);
              }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:validateNameField|" + e, null, 1);
            }
        },
        addErrorClass: function(elementObject, hasValue){

          try{
              var closestNode = $(elementObject).closest('.input-box-container');
              $(closestNode).find('.input-box-error').addClass('input-label-error');
              $(closestNode).find('.input-box-success').removeClass('input-label-success');
              $('.' + $(elementObject).attr('error')).addClass('has-input-error');
              $(closestNode).find('.input-box-active').removeClass('input-box-progress');
              
              $('.'+$(elementObject).attr('iconname')).removeClass('clicked');
              $('.'+$(elementObject).attr('avtarImage')).removeClass('active');
              
              $(elementObject).addClass('hasInputError');

              if(hasValue){
                $(closestNode).find('.input-box-label').addClass('input-box-filled');  
              } else {
                $(closestNode).find('.input-box-label').removeClass('input-box-filled');  
              }
              $(elementObject).removeClass("h-filled");
              this.networkCallInProgress = false;
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:addErrorClass|" + e, null, 1);
          }

        },  
        addSuccessClass: function(elementObject){
          try{
              var closestNode = $(elementObject).closest('.input-box-container');
              $(closestNode).find('.input-box-success').addClass('input-label-success');
              $(closestNode).find('.input-box-error').removeClass('input-label-error');
              $(closestNode).find('.input-box-label').addClass('input-box-filled');
              $('.' + $(elementObject).attr('error')).removeClass('has-input-error');
              $(closestNode).find('.input-box-active').removeClass('input-box-progress');
              $(elementObject).removeClass('hasInputError');

              $('.'+$(elementObject).attr('iconname')).removeClass('clicked');
              $('.'+$(elementObject).attr('avtarImage')).removeClass('active');

              $(elementObject).addClass("h-filled");

              this.networkCallInProgress = false;
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:addSuccessClass|" + e, null, 1);
          }
        },
        addInprogressClass: function(elementObject){
            var closestNode = $(elementObject).closest('.input-box-container');
            $(closestNode).find('.input-box-label').addClass('input-box-filled');
            $(closestNode).find('.input-box-active').addClass('input-box-progress');

            this.networkCallInProgress = true;
        },
        updateNotifyAgent:function(event){
          try {
            var obj = '#' + event.target.id;
            if($(".leanRegNotifyBuyerAgentFlag").is(":checked")){
                $('#notifyBuyerAgent').val('Y');
            }else{
                $('#notifyBuyerAgent').val('N');
            }
          } catch(e) {
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:updateNotifyAgent|" + e, null, 1);
          }
        },
        validateRePasswordField: function(event) {
          try {
            
              var obj = '#' + event.target.id;

              if($.trim($(obj).val()) === ''){
                $('.' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.fieldRequired);
                $(obj).addClass("hasInputError");
              } else if ( $.trim($(obj).val()) !== $.trim($("#leanRegPassword").val()) ) {
                $(obj).addClass("hasInputError");
                $('.' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.errorLabelMatch);
              } else {
                $(obj).removeClass("hasInputError");
              }
          } catch (error) {
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:validateRePasswordField|" + error, null, 1);
          }
        },

        validatePasswordField : function(event, callback){
          try{
              var obj = '#leanRegPassword',
                  self = this;

              /*If element is not visible, then return; If user saved his email/passwords that will prepopulate and trigger focusout event*/
              if(!$(obj).is(":visible")) { $(obj).val(""); return; }
              
              // if(!($("#loginRegisterDialog").data('bs.modal') && $("#loginRegisterDialog").data('bs.modal').isShown)){
              //     if(callback) callback();
              //    // return;
              // }
              if($.trim($(obj).val()) ==''){

                  $('.' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.fieldRequired);
                  this.addErrorClass(obj, false);
                  if(callback) callback();

              } else if( !validator.isValidPassword($.trim($(obj).val())) ){
                  
                  $('.' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.strongErrorLabel);
                  this.addErrorClass(obj, true);
                  if(callback) callback();

              }else{
                  
                  this.addInprogressClass(obj);

                  var passwordUrl = contextPath+'/portal/lean-regn/isValidPassword';
                  var leanRegEmailId=$('#emailId').val();
                  var leanRegPassword=$('#leanRegPassword').val();
                  var leanRegRetypePassword=$('#leanRegRePassword').val();
                  var postData = {'emailId':leanRegEmailId,'passCode':leanRegPassword, 'retypePassCode':leanRegRetypePassword,'userType':'NEWUSER'};
                
                  // Get access token 
                  Utils.getAccessToken({viewObject: self, callback: executeTokenCallback});
                            
                  function executeTokenCallback(accessData) {
                        postData.cryptoAlgVersion = 'V1';
                        if(forge){
                            postData.cryptoAlgVersion = 'V2';
                            postData.passCode = Utils.forgeEncryptPassword({ password: postData.passCode, salt: accessData.salt});
                            postData.retypePassCode = Utils.forgeEncryptPassword({ retypePassword: postData.retypePassCode, salt: accessData.salt});
                        } else {
                            postData.passCode = Utils.encryptPassword({ password: postData.passCode, salt: accessData.salt});
                            postData.retypePassCode = Utils.encryptPassword({ retypePassword: postData.retypePassCode, salt: accessData.salt});
                        }
                      
                      postData.requestId = accessData.requestId;

                      $.ajax({
                          type: "POST",
                          url: passwordUrl,
                          data: postData,
                          dataType: 'json',
                          success: function(data){
                            if(data.status=="SUCCESS"){
                                self.addSuccessClass(obj);
                            }else{

                                self.addErrorClass(obj, true);
                                var errorTmp = $(obj).attr('error'),
                                    tooltipTarget = $('.lean-password-tooltip');
                                $('.' + errorTmp).html(_.escape(tooltipTarget.attr('data-content')));
                                if ( data.errorMessage === "INVALID_FIELD_DETAILS" ) {
                                    $('.' + $(obj).attr('error')).html("Please enter email address to proceed further");
                                }
                            }
                            if(callback) callback();
                          },
                          error: function(xhr, ajaxOptions, thrownError){
                                $('#ajax-loader-mask').css('display','none');
                                $('#ajax-loader').css('display','none');
                                GAUtils.eventTracking.trackevent("Error", "New Lean Registration", "error:validate Password|" + xhr.status, null, 1);
                                if(callback) callback();
                              }
                      });
                  }
              }
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:validatePasswordField|" + e, null, 1);
          }
        },
        validateForgotEmailField : function(event){
          try{
            var obj = '#' + event.target.id;

            $.trim($(obj).val($.trim($(obj).val()).toLowerCase()));

            if($.trim($(obj).val()) == ''){

                $('.' + $(obj).attr('error')).html(this.componentsCmsNode.registration.errorMessages.loginEmailError);
                this.addErrorClass(obj, false);

            }else if(!(validator.isEmail($.trim($(obj).val()) , obj))){

                $('.' + $(obj).attr('error')).html(this.componentsCmsNode.registration.errorMessages.errorEmailId);
                this.addErrorClass(obj, true);

            }else if(Utils.isDeletedUser($.trim($(obj).val()))){
                $('.' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.isDeletedEmailIdMessage);
                this.addErrorClass(obj, true);
            }else{
                this.addSuccessClass(obj);
            } 
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:validateForgotEmailField|" + e, null, 1);
          }
        },

        validateEmailField : function(event, callback){
          try
          {
            var obj = '#emailId';
            var emailUrl = contextPath+'/portal/user/isValidEmailAddress',
                self = this;

            /*If element is not visible, then return; If user saved his email/passwords that will prepopulate and trigger focusout event*/
            if(!$(obj).is(":visible")) { $(obj).val(""); return; }
              
            $.trim($(obj).val($.trim($(obj).val()).toLowerCase()));

            if($.trim($(obj).val()) == '') {

                $('.' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.emailIsRequired);
                this.addErrorClass(obj, false);
                if(callback) callback();

            } else if(!(validator.isEmail($.trim($(obj).val()) , obj))){

                $('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.errorEmailId);
                this.addErrorClass(obj, true);
                if(callback) callback();

            }else{

              this.addInprogressClass(obj);

              $.ajax({
                  type: "POST",
                  url: emailUrl,
                  data: {'email':$.trim($(obj).val())},
                  dataType: 'json',
                  success: function(data){

                      if(data.status=="SUCCESS"){
    
                          $('#leanPasswordToolTip').attr('data-content',data.passwordToolTip);
                          self.addSuccessClass(obj);

                          var currentElement = $('#leanRegPassword'),
                              passwordValue = $.trim($(currentElement).val()),
                              closestNode = $(currentElement).closest('.input-box-container');

                          // if(passwordValue && !$(closestNode).find('.passwordIcon').hasClass('clicked')){
                          //     self.validatePasswordField();
                          // }

                      }else{
                        
                          $('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.emailErrorMessages[data.status]);
                          self.addErrorClass(obj, true);
                      }
                      if(callback) callback();
                  },
                  error: function(xhr, ajaxOptions, thrownError){
                        $('#ajax-loader-mask').css('display','none');
                        $('#ajax-loader').css('display','none');
                        GAUtils.eventTracking.trackevent("Error", "New Registration", "validate Email|" + xhr.status, null, 1);
                        if(callback) callback();
                  }
              });
              
            }
          }
           catch(e)
           {
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:validateEmailField|" + e, null, 1);
           }
        },
        submitForgotPassword:function(){
                var form = $("form#userLeanRegistration");
                
                var data = {'emailId':$('#forgotPasswordEmailId').val()};
                var resetPasswordUrl =  (contextPath.indexOf('portal') > -1) ? contextPath + '/lean-regn/sendResetPasswordLink' : contextPath + '/portal/lean-regn/sendResetPasswordLink';
                var thisObj = this;
                $('#password-form-error').addClass('hide').removeClass('validationError').hide();
                GAUtils.eventTracking.trackevent("New-Registration","Forgot Password Submit Click",eventLabel);
                var options={};
                var forgotEmailId = $('#forgotPasswordEmailId').val();
                $('#userNewPassword input').each(function(){
                  $(this).filter(':visible').blur();

                }); 

                if($('#userNewPassword input.hasInputError').length > 0){
                  $('#userNewPassword .hasInputError').first().focus();       
                }else{

                  $('#globalPasswordSubmit').attr('disabled','disabled');
                  
                  // Start loading after specified time
                  var delayTime = saveListDrawerContent.GlobalConfig.loaderDelay || 3000;
                  var showPasswordStatusLoader = setTimeout(function() {
                      $('#ajax-loader-mask').css('display','block');
                      $('#ajax-loader').css('display','block');
                  }, delayTime);

                    $.ajax({
                    type: "POST",
                    url: resetPasswordUrl,
                    data: JSON.stringify(data),
                    dataType: 'json',
                    contentType: "application/json; charset=utf-8",
                    success: function(data){

                      // Clear time outs - remove showStatusLoader from window
                      clearTimeout(showPasswordStatusLoader);

                      $('#ajax-loader-mask').css('display','none');
                      $('#ajax-loader').css('display','none');
                        if(data.status=="SUCCESS"){
                          GAUtils.eventTracking.trackevent("New-Registration","Forgot Password Click Success",eventLabel);
                          var confirmationMessage = thisObj.componentsCmsNode.registration.toolTipMessages.userInfoLabel;
                          if (document.body.classList.contains('register-page-container')) { 
                            $('#forgotPasswordScreen').hide();
                            $('.user-confirmation-message').html(confirmationMessage.replace('###emailId###',forgotEmailId));
                            $('#forgotPasswordScreenConfirmation').show();
                          } else {
                            $('#forgotPasswordScreen').removeClass('activeTab');
                            $('.user-confirmation-message').html(confirmationMessage.replace('###emailId###',forgotEmailId));
                            $('#forgotPasswordScreenConfirmation').addClass('activeTab');
                          }
                        }else{
                          GAUtils.eventTracking.trackevent("New-Registration","Forgot Password  Click Failure "+data.errorMessage,eventLabel);
                          $('#password-form-error').html(thisObj.componentsCmsNode.registration.errorMessages.emailIdValidationError[data.errorMessage]);
                          $('#password-form-error').removeClass('hide').addClass('validationError').show();
                        }
                      $('#globalPasswordSubmit').prop('disabled', false);
                    },
                  error: function(xhr, ajaxOptions, thrownError){
                    $('#ajax-loader-mask').css('display','none');
                    $('#ajax-loader').css('display','none');
                  $('#globalPasswordSubmit').prop('disabled', false);
                    GAUtils.eventTracking.trackevent("Error", "New Lean Registration", "error:Submit Registration|" + xhr.status, null, 1);
                  }
                });
                }
                 
        },
        submitRegistrationData:function(){
          try{
            // $("#ajax-loader").show();
			      // $("#ajax-loader-mask").show();
            var formDataRegisterJson={};
            var userRegister=[];
            var gaData = eventLabel; 
            
            if(this.funnelStart) {
              gaData = eventLabel + " | referrer: GlobalAlerts";
            } 
            if(this.srcName){
              gaData = eventLabel + " | referrer: "+this.srcName;
            }

            if ($(window).width() < 768) {
              if (typeof golbalPDPView !== "undefined" && golbalPDPView.showAlertsContainer ) {
                  gaData = eventLabel + " | referrer: GlobalAlerts | source: LockboxCode" ;
              }
            }

            $('.register-form-error').html('').hide();
            $('#confirmLeanRegistration').attr('disabled','disabled');

            var isProxyBiddingEnabled = (typeof proxyBiddingEnabled !== 'undefined' && proxyBiddingEnabled === "true") ? true : false;
            if(isProxyBiddingEnabled){
              var userId = window.userSession && window.userSession.userId || 'NA';
              var trackGALabel = "Property Id: "+ window.propertyId + "|userID: " + userId + "|visitorId:" + $.cookie("visitorId"); 

              GAUtils.eventTracking.trackevent("","Submit Registration Click",trackGALabel+"| Screen Name: Proxy Bidding Yes");  
            }else{
              GAUtils.eventTracking.trackevent("","Submit Registration Click",eventLabel+"|Proxy Bidding: No");  
            }

            
            var that = this;
            $('#errorAll').addClass('hide');
            
            $('#leanRegFirstName').blur();
            $('#leanRegLastName').blur();
            if($("#optInSMsRegistration").is(":visible")){
                $('#leanPhoneNo').blur();
            }
            if ($("#leanRegRePassword").length)
              $("#leanRegRePassword").blur();

            that.validateEmailField(null, function(){
                that.validatePasswordField(null, function(){
                    that.validatePhoneNumber(null, function(){
                        if($('#userLeanRegistration input.hasInputError').length > 0){
                            $('#userLeanRegistration .hasInputError').first().focus();
                          $('#confirmLeanRegistration').prop('disabled', false);
                          $("#ajax-loader").hide();
                          $("#ajax-loader-mask").hide();
                        }else{
                            var form = $("form#userLeanRegistration");
                            var data = that.ConvertFormToJSON(form);
                            data.notifyBuyerAgent = $('#notifyBuyerAgent').val();
                            var registrationUrl = ( contextPath.indexOf('portal') > -1 ) ?  contextPath + '/lean-regn/registerUser' : contextPath + '/portal/lean-regn/registerUser';
                            var thisObj = that;
    
                            // Start loading after specified time
                            var delayTime = saveListDrawerContent.GlobalConfig.loaderDelay || 3000;
                            //var showRegStatusLoader = setTimeout(function() {
                                $('#ajax-loader-mask').css('display','block');
                                $('#ajax-loader').css('display','block');
                            //}, delayTime);
                            
                            $('#errorAll').addClass('hide').removeClass('validationError');
                            
                            var options={};
    
                            if($("#optInSMsRegistration").is(":visible")){
                                if($('#optInSMsRegistration').is(":checked")){
                                    data.phoneNumber = $('#leanPhoneNo').val();
                                    data.smsOptInFlag = 'Y';
    
                                }
                            }
    
                            // Get access token 
                            Utils.getAccessToken({viewObject: that, callback: executeTokenCallback});
                            
                            function executeTokenCallback(accessData) {
                                data.cryptoAlgVersion = 'V1';
                               // if(isEnableNewEncryption && forge){
                                  if(forge){
                                    data.cryptoAlgVersion = 'V2';
                                    data.password = Utils.forgeEncryptPassword({ password: data.password, salt: accessData.salt});
                                } else {
                                    data.password = Utils.encryptPassword({ password: data.password, salt: accessData.salt});
                                }
                                data.requestId = accessData.requestId;
    
                                $.ajax({
                                    type: "POST",
                                    url: registrationUrl,
                                    data: JSON.stringify(data),
                                    dataType: 'json',
                                    contentType: "application/json; charset=utf-8",
                                    success: function(data){
    
                                        // Clear time outs - remove showRegStatusLoader from window
                                        //clearTimeout(showRegStatusLoader);
    
                                        if(data.status=="SUCCESS"){
                                            
    
                                            // FOR APP - firebase logging
                                            var visitor_id = $.cookie('visitorId') || '';
                                            if (window.notifyNativeApp) {
                                                window.notifyNativeApp(JSON.stringify({
                                                    message: "logGaEvent",
                                                    category: "event",
                                                    type: "register",
                                                    action: "Register Completed",
                                                    source: visitor_id
                                                }), '*');
                                            }
    
                                            // If subscribed user completes his registration, then delete his session
                                            if ( Utils.getCookieDetails('subscribeEmail') && window.atob(Utils.getCookieDetails('subscribeEmail')) ===  $.trim($('#emailId').val()) ){
                                                Utils.deleteCookie('subscribeEmail');
    
                                            }
    
    
                                            if(Utils.getCookieDetails('subscribeEmail')){
                                                // GAUtils.eventTracking.trackevent("","Register Completed",gaData + " SRC : Subscriber Registration | Source: Direct");
                                                options.gaLabel = gaData + " SRC : Subscriber Registration | Source: Direct";
                                            }
                                            else{
                                                // GAUtils.eventTracking.trackevent("","Register Completed",gaData +"| Source: Direct");
                                                options.gaLabel = gaData +"| Source: Direct";
    
                                            }
    
                                            options.trackRegistrationEvent = true;
                                            that.funnelStart = false;
                                            options.email = $.trim($('#emailId').val());
                                            options.password = $("#leanRegPassword").val();
                                            if(!that.isPlaceBidClicked){
                                                options.callback=that.afterRegistrationCallBack;
                                                options.viewObject=that; 
                                                //that.afterRegistrationCallBack();
                                            }
                                            
                                        Utils.userLogin(options); // sign In call after registration 
                                        }else{
                                          GAUtils.eventTracking.trackevent("","Submit Registration Click Failure",gaData);
                                          $('#ajax-loader-mask').css('display','none');
                                          $('#ajax-loader').css('display','none');
                                          $('#errorAll').removeClass('hide').addClass('validationError');
                                          var errorMessages = "An error has occurred, please try again.";
                                          if(data && data.errorMessage){
                                            errorMessages = that.componentsCmsNode.errorMessages.loginErrors[data.errorMessage] || errorMessages;
                                          }
                                          $('.register-form-error').html(errorMessages).show();
                                        }
                                      $('#confirmLeanRegistration').prop('disabled', false);
                                    },
                                  error: function(xhr, ajaxOptions, thrownError){
                                    $('#ajax-loader-mask').css('display','none');
                                    $('#ajax-loader').css('display','none');
                                    GAUtils.eventTracking.trackevent("Error", "New Lean Registration", "error:Submit Registration|" + xhr.status, null, 1);
                                  $('#confirmLeanRegistration').prop('disabled', false);
                                  }
                                });
                            }
                        }
                    })
                });
            });
            
           }catch(e){
            $("#ajax-loader").hide();
			      $("#ajax-loader-mask").hide();
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:submitRegistrationData|" + e, null, 1);
          } 
        }, 

        afterRegistrationCallBack: function(response,thisObj){

            var resetPopup = Utils.getCookieDetails('resetPopup') || '';
             
              $('#errorAll').addClass('hide').removeClass('validationError');
              var options = {};
              if(thisObj.mobileVerificationEnabled){
                mobileVerificationView.checkMobileVerificationRequired(options);
                return;
              }
              else if(!thisObj.isPlaceBidClicked){  
                 
                if(typeof window.golbalPDPView != 'undefined'){
                    if( window.golbalPDPView.onSmsLoggedin ) {
                      window.golbalPDPView.onSmsLoggedin = false;
                      window.golbalPDPView.updateOptInStatusForFC();
                    }
                  }

                  $('#successContent').html(thisObj.componentsCmsNode.registration.registrationConfirmation).focus();
                  $('#leanRegistrationSuccess').fadeIn();
                  $('#leanRegistrationSuccess').fadeIn('300').delay(5000).fadeOut('slow');
              }

              //hack added to hide the register popup only in case of lock box access code register cal back
              if(typeof golbalPDPView !== 'undefined' && golbalPDPView && golbalPDPView.showAlertsContainer && thisObj.isMobile) {
                  //golbalPDPView.showAlertsContainer = false;	
                  nView.openAlertsDrawer();
              } else {
                  thisObj.hideLoginRegisterPopup();
              }
              

             // thisObj.hideLoginRegisterPopup();
              
              $('#ajax-loader-mask').css('display','none');
              $('#ajax-loader').css('display','none');
              if(typeof thisObj.callback != 'undefined' && thisObj.callback != null && thisObj.callback != ''){
                thisObj.callback(null,thisObj.viewObject);
              }

              // If any callbacks to be triggered after Register
              // Example: Save Search on SRP
              if (thisObj.callback) thisObj.callback(response, thisObj.viewObject);

              var locationUrl = window.location.href;
              if( locationUrl.indexOf('/portal/bid') > -1 ) {
                  window.location.reload();              
              }

              /**
               * check to destroy the automatic registration popup on timeinterval
               */
              if ( (typeof golbalSRPView !== "undefined" || typeof golbalPDPView !== "undefined" ) && !thisObj.isMobile) {
								if(resetPopup == '' && typeof golbalSRPView !== "undefined"){
                  golbalSRPView.filterView.setIntervalToShowRegister();
								}
							}


        },
        ConvertFormToJSON: function(form){
          try{
            var array = jQuery(form).serializeArray();
            var json = {};  
            
            jQuery.each(array, function() {     
                json[this.name] = (this.value).trim() || '';    
            });
           
            return json;
            }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:ConvertFormToJSON|" + e, null, 1);
          }
        },
        
        initPopOver : function(){
            try{

              $('#registrationForm .infotooltipPop').popover('destroy');
              if(this.isMobile){
                $('#registrationForm .infotooltipPop').popover({
                    trigger: 'hover',
                    html: true,
                    placement: 'left',
                    container: '#leanRegTooltip'
                });
              }else{

                $('#registrationForm .infotooltipPop').popover({
                    trigger: 'manual',
                    html: true,
                    placement: 'bottom',
                    container: '#leanRegTooltip'
                });
                $('#registrationForm .infotooltipPop').on("click mouseover", function() {
                  $('#registrationForm .infotooltipPop').not(this).popover('hide');
                  $(this).popover('show');
                });

                $(window).on('click touchstart',function(e) {
                    $('#registrationForm .infotooltipPop').popover('hide');
                });
              }
            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:initPopOver|" + e, null, 1);
            }
        },
        
        checkPhoneKeyPress : function(event){
        	try{
	            var fieldId = '#'+event.target.id
	            if(validator.phoneField(event , fieldId)){
	                return true;
	            }else{
	                return false;
	            }
        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:checkPhoneKeyPress|" + e, null, 1);
        	}
        },
        
        validatePhoneNumber : function(event, callback){
            var self = this;
            var errorMessage = this.componentsCmsNode.errorMessages.phoneNumberRequired;
            // var obj = '#' + event.target.id,
            var obj = '#leanPhoneNo',
            value = $.trim($(obj).val());
            if(value === ''){
               $('.' + $(obj).attr('error')).html(errorMessage);
                this.addErrorClass(obj, false);
            }else if(!(validator.phone($.trim($(obj).val()) , obj)) || (value == "0000000000")){
                var errorMessage = this.componentsCmsNode.errorMessages.errorPhoneno;
                $('.' + $(obj).attr('error')).html(errorMessage);

                $('.' + $(obj).attr('error')).removeClass('hide');
                this.addErrorClass(obj, true);

            }else{
                mobileVerificationView.mobileVerificationCheckVal = saveListDrawerContent.mobileVerificationContent.config.mobileVerificationEnabled;
                if(typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                    var params = {},options = options || null;
                    params.callback = callback;
                    params.phoneNumber = value;
                    params.compObj = self;
                    params.options = options || null;
                    mobileVerificationView.verifyDuplicatePhoneNumber(params);
                } else {
                    self.duplicatePhoneSucessCallback();
                }
            }
        },

        duplicatePhoneSucessCallback : function(callback, options){
            var obj = '#leanPhoneNo';
            var self = this;
            $('.' + $(obj).attr('error')).addClass('hide');
            $('.' + $(obj).attr('error')).html('');
            self.addSuccessClass(obj);
            if(callback) callback(options);
        },

        duplicatePhoneErrorCallback : function(data){
            var obj = '#leanPhoneNo';
            var self = this;
            $('#ajax-loader-mask').css('display','none');
            $('#ajax-loader').css('display','none');
            $('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.phoneErrorMessages[data.status]);
            $('.' + $(obj).attr('error')).removeClass('hide');
            self.addErrorClass(obj, true);
        },

        validateLoginEmailFieldBlur: function(e, callback) {
          try{
              var emailObject = $('#loginEmailAddress');
              var self = this;

              /*If element is not visible, then return; If user saved his email/passwords that will prepopulate and trigger focusout event*/
              if(!emailObject.is(":visible")) { return; }
              
              if(!(validator.isEmail($.trim($(emailObject).val()) , emailObject))){
                $('#forgotPasswordEmailId').val('');
              }else{
                $('#forgotPasswordEmailId').val($.trim(emailObject.val()));
              }

              if($.trim(emailObject.val()) == ''){

                  $('.' + $(emailObject).attr('error')).html(this.componentsCmsNode.registration.errorMessages.loginEmailError);
                  this.addErrorClass(emailObject, false);
                  if(callback) callback();
              
              } else{
                this.addSuccessClass(emailObject, false);
              }

              if(callback) callback();
            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:validateLoginEmailFieldBlur|" + e, null, 1);
          }
        },

        validateLoginEmailField: function(e, callback){
          try{
              var emailObject = $('#loginEmailAddress');
              var self = this;

              /*If element is not visible, then return; If user saved his email/passwords that will prepopulate and trigger focusout event*/
              if(!emailObject.is(":visible")) { return; }
              
              if(!(validator.isEmail($.trim($(emailObject).val()) , emailObject))){
                $('#forgotPasswordEmailId').val('');
              }else{
                $('#forgotPasswordEmailId').val($.trim(emailObject.val()));
              }

              if($.trim(emailObject.val()) == ''){

                  $('.' + $(emailObject).attr('error')).html(this.componentsCmsNode.registration.errorMessages.loginEmailError);
                  this.addErrorClass(emailObject, false);
                  if(callback) callback();
              
              } else{

                  this.addInprogressClass(emailObject);

                  var emailUrl = contextPath+'/portal/lean-regn/validateEmail';
                  var email=$.trim(emailObject.val());
                  var postData = {'email':email},
                      errorTmp,
                      responseData;
                  $.ajax({
                    type: "POST",
                    url: emailUrl,
                    data: postData,
                    dataType: 'json',
                    success: function(data){
                      if(data.status=="SUCCESS"){
                          isInternalUser = data.data.internalUser;
                           if(typeof isInternalUser !== 'undefined' && isInternalUser === 'Y')
                              {
                                $('#recaptchaPH').hide();
                              }
                              else{
                                $('#recaptchaPH').show();
                              }
                          self.addSuccessClass(emailObject);
                      }else{
                          self.addErrorClass(emailObject, true);
                          errorTmp = $(emailObject).attr('error');
                          $('.' + $(emailObject).attr('error')).html(self.componentsCmsNode.errorMessages.loginErrors[data.errorMessage]);
                          
                          /* Prefill email id in Registration */
                          if(data.errorMessage === 'NO_RECORDS_FROM_DB') {
                              responseData = typeof data.data !== 'undefined' ? data.data : null;
                              if(validator.isEmail($.trim($(emailObject).val()) , emailObject)){
                                  $('#emailId').val(email);
                              }
                              /* If user is subscribed user, prefill his details*/
                              if(responseData && responseData.firstName && responseData.lastName) {
                                  $('#leanRegLastName').val(responseData.lastName);
                                  $('#leanRegFirstName').val(responseData.firstName);
                                  $('#emailId').val(responseData.emailId);
                              }                              
                          }
                      }
                      if(callback) callback();
                    },
                    error: function(xhr, ajaxOptions, thrownError){     
                        GAUtils.eventTracking.trackevent("Error", "New Lean Registration", "error:validate Password|" + xhr.status, null, 1);
                        if(callback) callback();
                    }
                  }); 
                  
              } 
              
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:validateLoginEmailField|" + e, null, 1);
          }
        },
        validateLoginPasswordField: function(){
          try{
              var currentObject = $('#loginPassword');
              
              if($.trim(currentObject.val()) == ''){
                  $('.' + $(currentObject).attr('error')).html(this.componentsCmsNode.registration.errorMessages.errorLogin);
                  this.addErrorClass(currentObject, false);
              }else{
                  this.addSuccessClass(currentObject);
              }

          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:validateLoginPasswordField|" + e, null, 1);
          }
        },
        login: function(e){
            try{
                
                var self = this;
                $('#gobalSignInSubmit').attr('disabled','disabled');
                self.validateLoginEmailFieldBlur(null, function(response){
                    self.validateLoginPasswordField();
                      var passwordElem = $('#loginPassword'),
                          emailElem = $('#loginEmailAddress'),
                          email = $.trim(emailElem.val()),
                          password = $.trim(passwordElem.val()),
                          dataObject = {
                            formObject: '#loginRegisterDialog',
                            email: email,
                            password: password,
                            viewObject: self.viewObject || self,
                            callback: self.callback || null,
                            funnelStart: self.funnelStart || false,
                            srcName : self.srcName || false
                          }; 

                      if(!passwordElem.hasClass('hasInputError') && !emailElem.hasClass('hasInputError')) {
                          //Utils.checkAndSignIn();
                        if( self.componentsCmsNode.captchaEnable.enabled === "yes" && isInternalUser === 'N') {
                            if(self.captchaAvailable) {
                              try{
                                var response = grecaptcha.getResponse(widgetId1);
                                if(response.length == 0) {
                                  $('#loginRegisterDialog .login-form-error.responseError').html('An error has occurred, please try again.');
                                  $('#loginRegisterDialog .login-form-error.responseError').show();
                                  $('#gobalSignInSubmit').prop('disabled', false);
                                  return false;
                                }
                              }catch(e){}
                            }
                        }
                          Utils.validateSigninData(dataObject);
                          
                      } else {
                        $('#userNewLogin .hasInputError').first().focus();
                      $('#gobalSignInSubmit').prop('disabled', false);
                      }   
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:login|" + e, null, 1);
            }
        },
        render: function(options){
            
            try{

                options = options || {};
                // Registration render
                var subscribeUser;
                if(Utils.getCookieDetails('subscribeUserDetails')){
                      subscribeUser = JSON.parse(Utils.getCookieDetails('subscribeUserDetails'));
                }
                this.componentsCmsNode.registration['subscribeUser']=subscribeUser;
                this.componentsCmsNode.registration.registrationLabel.regTermsAndConditions = this.componentsCmsNode.registration.registrationLabel.regTermsAndConditions.replace('###contextPath###',contextPath);
                this.componentsCmsNode.phoneConsentSection.consentTerms = this.componentsCmsNode.phoneConsentSection.consentTerms.replace('##PATH##', contextPath);

                this.componentsCmsNode.registration.phoneConsentSection =  this.componentsCmsNode.phoneConsentSection;

                if(this.componentsCmsNode.phoneConsentSection.enableInRegistration == 'Y' ){
                    this.componentsCmsNode.phoneConsentSection.showConsentForm = 'Y';
                }

                if (typeof newSkinning !== "undefined" && newSkinning ) {
                  // todo
                  if ( $(".h-sigin-popup-content").length ) {
                    console.log(' $(".h-sigin-popup-content").length ::', $(".h-sigin-popup-content").length);
                    $('#loginRegisterDialog').addClass("h-signin-container").html($(".h-sigin-popup-content").html());
                    $('#loginRegisterDialog').attr("data-active", "login");
                    $(".h-sigin-popup-content").remove();
                  }

                } else {
                    $('#loginRegisterDialog').html(loginRegisterTemp(this.componentsCmsNode.registration));
                    $('#registerView').html(newregistrationTemp(this.componentsCmsNode.registration));
                }

                if (typeof renderParentObj !== "undefined") {
                  $('#'+renderParentObj).html(registerTemp(this.componentsCmsNode.registration));
                }
                if(typeof window.golbalPDPView !='undefined' && window.golbalPDPView.onSmsLoggedin){
                    $('#optInSMsRegistration').click();
                }
                return this;
                
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:render|" + e, null, 1);
            }
        },
        openRegistration:function(e){
            this.openLoginPopup({
                openRegistration: 'Y'
            });
        },
        openLoginPopup:function(callbackOptions,isPlaceBidClicked){
            try{
                if(isPlaceBidClicked != undefined && isPlaceBidClicked != null &&  isPlaceBidClicked != ''){
                  this.isPlaceBidClicked = isPlaceBidClicked;
                }
                $('.modal-content').removeClass('activeStage');
                $('#loginRegisterBlock').addClass('activeStage');
                if (document.body.classList.contains('h-home-page')) {
                  Utils.redirect('/register-sign-in');
                } else {
                  $("#header-navigation").removeClass("in");
                  $('#notificationMobilePopup').modal('hide');
                  $('#loginRegisterDialog').modal("show");

                  $('#loginRegisterDialog').on('shown.bs.modal', function (e) {
                      if(!$('body').hasClass('modal-open')){
                          $('body').addClass('modal-open');
                      }
                  }); 

                }
               
                //GAUtils.eventTracking.processTrackPV(window.location.href,"signin-");
                /**** Condition check to disable registration in login popup */
                if(callbackOptions && (callbackOptions.callback || callbackOptions.viewObject)){
                    this.callback = callbackOptions.callback || null;
                    this.viewObject = callbackOptions.viewObject || null;
                    // if(!callbackOptions.isRegister) {
                    //   $('#registerationDiv').css('pointer-events', 'none');
                    //   $('#registerationDiv').css('color', '#ccc');
                    //   $('#mobileRegisterLabel').addClass('hide');
                    // }
                } 

                if(this.callback || this.viewObject){
                    $('.forgotPassword').empty();
                    // $('.registerLink').empty(); // This will hide the register link in header Register link
                }

                // NOTE: If user subscribed session is available, then always force user to complete his registration 
                if(callbackOptions && callbackOptions.openPhoneVerification){
                  
                    $('#loginRegisterDialog .modal-content').removeClass('activeStage');
                    this.showPhoneVerification({
                      openPhoneVerification: true
                    });

                } else if(callbackOptions && callbackOptions.openProxyBidRegister){
                  
                    this.showPhoneVerification({
                      openProxyBidPinRegistration: true
                    });

                } else if(callbackOptions && callbackOptions.confirmProxyBidPin){
                  
                    this.showPhoneVerification({
                      confirmProxyBidPin: true
                    }); 

                } else if(callbackOptions && callbackOptions.openProxyBidManagerRegister){
                  this.showPhoneVerification({
                    openProxyBidManagerRegister: true
                  });
                } else if(callbackOptions && callbackOptions.confirmProxyBidManagerPin){
                  this.showPhoneVerification({
                    confirmProxyBidManagerPin: true
                  });
                } else if ( Utils.getCookieDetails('subscribeEmail') ) {
                  this.showRegistration();  
                } else if(callbackOptions && callbackOptions.openRegistration && callbackOptions.openRegistration === 'Y'){
                  this.showRegistration();  
                } else if(callbackOptions && callbackOptions.openAnonymRegister && callbackOptions.openAnonymRegister === 'Y'){
                  this.openAnonymRegister = 'Y';
                  this.showRegistration();  
                } else if(callbackOptions && callbackOptions.openForgotPassword && callbackOptions.openForgotPassword === 'Y'){
                  this.showForgotPassword();  
                } else if(callbackOptions && callbackOptions.openRegister && callbackOptions.openRegister === 'Y'){
                  this.showRegistration();  
                } else {
                  if(callbackOptions && callbackOptions.srcName){
                    this.srcName = callbackOptions.srcName;
                  }else{
                    this.srcName = false;
                  }
                  this.showLogin();
                }

                if(typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                  mobileVerificationView.mobileVerificationCheck = true;
                  if(typeof mobileVerificationView.mobileVerificationCheck!= 'undefined'){
                    this.mobileVerificationEnabled = mobileVerificationView .mobileVerificationCheck;
                  }else{
                    this.mobileVerificationEnabled = false;
                  }
                }
                

                // Notify Native app with message 
                if (window.notifyNativeApp) {
                  setTimeout(function(){
                    var userPostData = {
                      "message":"LoginPopupOpen"
                    };
                    window.notifyNativeApp(JSON.stringify(userPostData),'*');
                  }, 1000);
                }

                var self = this;
                setTimeout(function () {
                    self.initPopOver();

                    if(callbackOptions && callbackOptions.statusMessage){
                      $('.login-form-error').html(callbackOptions.statusMessage).show();
                    }
                    
                }, 500);
                
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:openLoginPopup|" + e, null, 1);
            }
        },

       /* anonymousRegistraion: function(e) {
            e.preventDefault();
            GAUtils.eventTracking.trackevent("","Register Click for Restrict Anonymous Count", eventLabel+"|Sign Up");
            $(".header-tooltipPopdesc").fadeOut(); 
            this.openLoginRegister(e);
        }, */

        /**
         * Options - Event object 
         *             Element attributes: 
         *                  view - view you want to display (login/register) 
         *                  source - from where it is triggered (hamberger menu)
         *         - Custom object 
         *             Contains two params: 
         *                  view - view you want to display (login/register)
         *                  source - from where it is triggered (hamberger menu)
         */
        showLoginModal: function (options) {

            try {

                options = options || {};
                var self = this,
                    view = options.target && $(options.target).attr('data-view') || options.view || 'login',
                    source = options.target && $(options.target).attr('data-source') || options.view || 'Hamberger-menu',
                    currPageName = GAUtils.eventTracking.recognizePageType();

                // hide global alert view
                if (window.globalAlertView) window.globalAlertView.hideAlertContainer();

                // hide save list drawer view
                $('.notifications-slider').removeClass('saved-items-active');
                $('.notifications-slider-shade').fadeOut(100);

                if(typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                  mobileVerificationView.mobileVerificationCheck = true;
                  if(typeof mobileVerificationView.mobileVerificationCheck!= 'undefined'){
                    this.mobileVerificationEnabled = mobileVerificationView .mobileVerificationCheck;
                  }else{
                    this.mobileVerificationEnabled = false;
                  }
                }
                
                if (view === 'login') {
                    self.showLogin();
                   // GAUtils.eventTracking.trackevent("", source + "Sign In Click", currPageName + '-SignIn',' | visitorId :' + $.cookie('visitorId'));
                    GAUtils.eventTracking.trackevent(currPageName + '-SignIn', ' | visitorId :' + $.cookie('visitorId'), "Sign In Click"); 
                } else if (view === 'register') {
                   // GAUtils.eventTracking.trackevent("", source + " Register Click", currPageName + '-Register');
                    GAUtils.eventTracking.trackevent("", " Register Click", ' | visitorId :' + $.cookie('visitorId')); 
                } else if (view === 'register') {
                    
                    self.showRegistration();
                } else {
                    GAUtils.eventTracking.trackevent("", "Login/Register Toggle click", eventLabel);
                    self.showLogin();
                }

                $("#header-navigation").removeClass("in");
                $('#loginRegisterDialog').modal("show");
                $('#notificationMobilePopup').modal('hide');
                if ($('#subscribePopups')) $('#subscribePopups').modal('hide');

                setTimeout(function () {
                    self.initPopOver();
                }, 500);

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:showLoginModal|" + e, null, 1);
            }

        },

        showPhoneVerification : function(options){
            try{
              var options = options || {},self=this;
              
                  options.fromPhoneVerfication = true;
                  $('#mobile-verification').html();

                  $(".h-signin-container").attr("data-active", "verify");

                  if(options.openPhoneVerification){
                    mobileVerificationView.checkMobileVerificationRequired(options);
                  } else if(options.openProxyBidPinRegistration){
                    proxyBidView.renderProxyBidRegister();
                  } else if (options.confirmProxyBidPin) {
                    proxyBidView.renderConfirmProxyBidPin();
                  } else if(options.openProxyBidManagerRegister){
                    proxyBidView.renderProxyBidRegister();
                  } else if(options.confirmProxyBidManagerPin){
                    proxyBidView.renderConfirmProxyBidPin();
                  }

                  /*var eventLabel =  propertyId ? "propertyId : " + propertyId:'' + " | Screen name: proxy bid click";
                  
                  if(typeof window.userSession != 'undefined' && typeof window.userSession.user != 'undefined' && typeof window.userSession.user.loggedIn != 'undefined' && window.userSession.user.loggedIn){
                      self.gaEventTracking("Place proxy bid click",eventLabel);
                  }else{
                      self.gaEventTracking("Register to proxy bid click",eventLabel);    
                  }*/

                  $("#header-navigation").removeClass("in");
                  $('#loginRegisterDialog').modal("show");
                  $('#notificationMobilePopup').modal('hide');
                  if ($('#subscribePopups')) $('#subscribePopups').modal('hide');
                  // hide global alert view
                  if (window.globalAlertView) window.globalAlertView.hideAlertContainer();
            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:showPhoneVerification|" + e, null, 1);

            }
        },
        gaEventTracking: function(action,eventLabel){

            var userId = (typeof window.userSession !== "undefined") && userSession.userId || '';
            var eventLabel =  'UID : '+ userId + ' | Visitor Id :'+$.cookie('visitorId')+ ' | ' + eventLabel;

            GAUtils.eventTracking.trackevent("", action, eventLabel); 
        },
        openLoginRegister:function(e){

            try{
                var self = this;
                // hide global alert view
                if(window.globalAlertView) window.globalAlertView.hideAlertContainer();

                // hide save list drawer view
                $('.notifications-slider').removeClass('saved-items-active');
                $('.notifications-slider-shade').fadeOut(100);

                // loginNewPopup - Login
                // loginRegisterPopup - Register
                // GAUtils.eventTracking.processTrackPV(window.location.href,"?signin-");
                $('.modal-content').removeClass('activeStage');

                $('#loginRegisterBlock').addClass('activeStage');

                if(e.target.id === 'loginNewPopup') {
                    this.showLogin();
                    var currPageName = GAUtils.eventTracking.recognizePageType();
                    GAUtils.eventTracking.trackevent("New-Sign In",'Sign In Click | visitorId :' + $.cookie('visitorId'), currPageName +'-SignIn');
                } else if (e.target.id === 'loginRegisterPopup' || e.target.id === 'anonymReg' || e.target.id === 'loginRegisterPopupDrawer' || $(e.currentTarget).hasClass('account-icon') ) {

                    var currPageName = GAUtils.eventTracking.recognizePageType();
                    if(e.target.id === 'loginRegisterPopup'){
                        GAUtils.eventTracking.trackevent("","Header click","Register Click");
                    }else{
                      GAUtils.eventTracking.trackevent("",e.target.id +"Button click","Register Click");
                    }
                    this.showRegistration();
                    
                    
                } else {
                  if(e.target.id =='loginDiv'){
                    GAUtils.eventTracking.trackevent("","Register Popup - Register Click",eventLabel);
                  }else{
                    GAUtils.eventTracking.trackevent("","Register Popup - Sign in click",eventLabel);
                  }
                    this.showLogin();
                }
                if(typeof mobileVerificationView.mobileVerificationCheckVal != undefined && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                  mobileVerificationView.mobileVerificationCheck = true;
                  if(typeof mobileVerificationView.mobileVerificationCheck!= 'undefined'){
                  this.mobileVerificationEnabled = mobileVerificationView .mobileVerificationCheck;
                }else{
                  this.mobileVerificationEnabled = false;
                }
                }
                // if user is from home page redirect to new register page else load th regular popup
                if (document.body.classList.contains('h-home-page')) {
                    Utils.redirect('/register-sign-in');
                } else {
                  $("#header-navigation").removeClass("in");
                  $('#loginRegisterDialog').modal("show");
                  $('#notificationMobilePopup').modal('hide');
                  if($('#subscribePopups')) $('#subscribePopups').modal('hide');
                }                
                var self = this;
                setTimeout(function(){
                  self.initPopOver();
                }, 500);
                
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:openLoginRegister|" + e, null, 1);
            }

        },
        showRegistration: function(){          
              try{

                  GAUtils.eventTracking.processTrackVPV("/Register Popup");

                  GAUtils.eventTracking.trackevent("","Register Popup - Register Click",eventLabel);
                  $('.register-form-error').html('').hide();
                  if ( !this.componentsCmsNode.phoneConsentSection ) {
                    this.componentsCmsNode = saveListDrawerContent;
                  }
                  
                  //663
                  if(this.componentsCmsNode.phoneConsentSection.enableInRegistration == 'Y' && this.isMobile){
                    this.updateHeight({height: '100%'});
                  }else{
                    this.updateHeight({height: '620px'});
                  }

                  if(this.isMobile){
                    $('.closeButton').addClass('closeButtonRegister');
                    $('#loginRegisterDialog .modal-body').removeClass('loginModalDiv').removeClass('forgotPasswordDiv');
                    $('#loginRegisterDialog .modal-content').addClass('registrationModalContent');
                  }
                  if(this.openAnonymRegister == 'Y') {
                    $(".leanHeadParentAnonym").removeClass('hide');
                    $(".leanHeadParent").addClass('hide');

                  } else {
                    $(".leanHeadParentAnonym").addClass('hide');
                    $(".leanHeadParent").removeClass('hide');
                  }
                  if(this.componentsCmsNode.phoneConsentSection.enableInRegistration == 'Y' ){
                      if(typeof window.golbalPDPView !='undefined' && !window.golbalPDPView.onSmsLoggedin){
                        $('.registrationPhoneBlock').slideUp();
                      $('#optInSMsRegistration').prop('checked', false);
                      }else if(typeof window.golbalPDPView !='undefined' && window.golbalPDPView.onSmsLoggedin){
                        $('.registrationPhoneBlock').slideDown();
                        $('#optInSMsRegistration').attr('checked',true);
                      }else{
                        $('.registrationPhoneBlock').slideUp();
                      $('#optInSMsRegistration').prop('checked', false);
                      }
                      
                      $('#leanPhoneNo').val('');
                  }else{
                      $('.registrationPhoneConsent').hide();
                  }
                  if(this.mobileVerificationEnabled){
                      $('#mobileVerification').removeClass('hide');
                      $('#confirmLeanRegistration').addClass('hide');
                  }else{
                      $('#mobileVerification').addClass('hide');
                      $('#confirmLeanRegistration').removeClass('hide');

                  }
                  this.clearValidationMessages({clearValues: true, targetDiv: 'registration'});
                  $('#loginDiv').removeClass();
                  $('#registerationDiv').addClass('active liActive');
                  $('#leanLoginFrom').removeClass('activeTab');
                  $('#leanForgotPassword').removeClass('activeTab');
                  $('#registrationForm').addClass('activeTab');
                  $('.inputSuccess').removeClass('inputSuccess');
                  if(typeof $.cookie('subscribeEmail') != 'undefined' && $.cookie('subscribeEmail') != ''){
                    $('#userLeanRegistration .leanHeadParent').html("<div class='completeProfile'>"+saveListDrawerContent.alertViewSec.favDrawer.title+"</div>");
                    $('.signupHead,#mobileSignInLabel').addClass('hide');
                    $('#confirmLeanRegistration').html(saveListDrawerContent.alertViewSec.favDrawer.buttonName);
                 }

                 if ($(".h-signin-container").length) {
                   $(".h-signin-container").attr("data-active", "register");
                 }

              }catch(e){
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:showRegistration|" + e, null, 1);
              }
        },
        showForgotPassword: function(){

              try{
                  // 525px
                
                  if ( !this.componentsCmsNode.phoneConsentSection ) {
                    this.componentsCmsNode = saveListDrawerContent;
                  }

                  this.updateHeight({height: '550px'});
                  if(this.isMobile){
                    $('.closeButton').removeClass('closeButtonRegister');
                    $('#loginRegisterDialog .modal-body').addClass('forgotPasswordDiv').removeClass('loginModalDiv');
                    $('#loginRegisterDialog .modal-content').removeClass('registrationModalContent');

                    }
                  this.clearValidationMessages({clearValues: true, targetDiv: 'forgotPassword'});
                  // this block of code is from registration page
                  if (document.body.classList.contains('register-page-container')) {
                    $('#leanLoginFrom').hide();
                    $('#registrationForm').hide();
                    $('#forgotPasswordScreenConfirmation').hide();
                    $('#leanForgotPassword,#forgotPasswordScreen').show();
                  } else {
                    $('#registerationDiv').removeClass();
                    $('#loginDiv').addClass('active liActive');
                    $('#leanLoginFrom').removeClass('activeTab');
                    $('#registrationForm').removeClass('activeTab');
                    $('#leanForgotPassword,#forgotPasswordScreen').addClass('activeTab');
                    $('#forgotPasswordScreenConfirmation').removeClass('activeTab');
                  }
                 
                  GAUtils.eventTracking.trackevent("New-Registration","Forgot Password Link Click",eventLabel);
                  this.changeDisableIcon();
              } catch(e) {
                  GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:showForgotPassword|" + e, null, 1);
              }
        },
        showLogin: function(e){
            try {
                var self = this;
                
                if ( !this.componentsCmsNode.phoneConsentSection ) {
                  this.componentsCmsNode = saveListDrawerContent;
                }

              $('#loginRegisterDialog .login-form-error.responseError').html('');
              $('#loginRegisterDialog .login-form-error.responseError').hide();

              if( self.componentsCmsNode.captchaEnable.enabled === "yes" &&  isInternalUser === 'N') {
                  if(!self.captchaAvailable){
                      try { 
                          var script = document.createElement('script');
                          script.type = 'text/javascript';
                          script.src = 'https://www.google.com/recaptcha/api.js?onload=captchaCallback&render=explicit';
                          document.body.appendChild(script);
                      } catch (e) { 
                          GAUtils.eventTracking.trackevent("Error", "loginRegisterView", "file:loginRegisterView.js|function:loadCaptcha|" + e, null, 1);
                      }
                      self.captchaAvailable = true;
                  } else{
                      captchaCallback();
                  }
              }
                
                GAUtils.eventTracking.processTrackVPV("/Login Popup");
                if(e != undefined && e.target.id =='loginDiv'){
                  GAUtils.eventTracking.trackevent("","Register Popup - Sign in click",eventLabel);
                }
                
                var currPageName = GAUtils.eventTracking.recognizePageType();
                
                // 427 px
                if(self.isMobile){
                    self.updateHeight({height: '430px'});
                    $('.closeButton').removeClass('closeButtonRegister');
                    $('#loginRegisterDialog .modal-body').addClass('loginModalDiv').removeClass('forgoPasswordDiv');
                    $('#loginRegisterDialog .modal-content').removeClass('registrationModalContent');

                }else{
                    self.updateHeight({height: '495px'});
                }
                $(".login-default-message").removeClass("hide");
                $('#registerationDiv').removeClass();
                $('#loginDiv').addClass('active liActive');
                $('#leanLoginFrom').addClass('activeTab');
                $('#leanForgotPassword').removeClass('activeTab');
                $('#registrationForm').removeClass('activeTab');
              $("#gobalSignInSubmit").prop("disabled", false);

                if ($(".h-signin-container").length) {
                  $(".h-signin-container").attr("data-active", "login");
                }

                self.clearValidationMessages({clearValues: true, targetDiv: 'login'});
                
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:showLogin|" + e, null, 1);
            }
        },
        clearValidationMessages: function(options) {
            
            try {
                options = options || {};
                var clearValues = options.clearValues || true,
                    targetDiv = options.targetDiv || 'login',
                    $el = $('.loginRegisterPopup'),
                    subscribeUser,
                    loginEmail = Utils.getCookieDetails('loggedInEmail') || null,
                    passwordObject = $("#loginPassword"),
                    emailObject = $('#loginEmailAddress'),
                    self = this;
                
                if (clearValues) {
                    $el.find('input[type="password"]').val('');
                }
                if(targetDiv === 'registration'){
                    if(targetDiv === 'registration' && ($('#emailId').val() === "" ) && Utils.getCookieDetails('subscribeUserDetails')){
                        subscribeUser = JSON.parse(Utils.getCookieDetails('subscribeUserDetails')) || {};
                        if(subscribeUser.lastName && subscribeUser.firstName && subscribeUser.emailId) {
                            $('#leanRegLastName').val(subscribeUser.lastName);
                            $('#leanRegFirstName').val(subscribeUser.firstName);
                            $('#emailId').val(subscribeUser.emailId); 
                        }                        
                    } else if(targetDiv === 'registration' && ($('#emailId').val() === "" ) && Utils.getCookieDetails('subscribeEmail')){
                      $('#emailId').val(window.atob(Utils.getCookieDetails('subscribeEmail')));  
                    }  
                }
                
                if(targetDiv === 'login' && loginEmail){
                    self.addInprogressClass(emailObject);
                    if(Utils.getCookieDetails('subscribeEmail')){
                        emailObject.val(window.atob(Utils.getCookieDetails('subscribeEmail')));  
                    }else{
                        emailObject.val(loginEmail);  
                    }
                                                        
                    setTimeout(function(){
                        self.validateLoginEmailFieldBlur(null, function(response){
                            setTimeout(function(){
                               self.addInprogressClass(passwordObject);
                                if ( typeof newSkinning !== "undefined" && newSkinning) {
                                } else {
                                  passwordObject.focus();                
                                }                             
                            }, 300);
                        });
                    }, 300);
                }

                if (typeof newSkinning !== "undefined" && newSkinning ) {
                } else {
                    setTimeout(function(){
                        $el.find('input[type="text"], input[type="password"]').each(function(){
                            if($.trim($(this).val())){
                                $(this).blur();
                            }
                        });
                    }, 300);
                }
                
                $el.find('input[type="text"], input[type="password"]').each(function(){
                  
                  $(this).removeClass('hasInputError');

                  var objectClass = $(this).attr('error');
                  $('.' + objectClass + 'Parent').removeClass('validationError');
                  $('.' + objectClass).removeClass('has-input-error')

                });

                $('.input-box-error').removeClass('input-label-error')
                $('.input-box-success').removeClass('input-label-success')
                $('.input-box-label').removeClass('input-box-filled')
                $(".h-popup-warning").hide();

                $('.avtarImage').removeClass('clicked');
                $('.responseError').empty().hide();
                $('.errorAll').hide();
                if($('#loginFormWarning')) $('#loginFormWarning').empty();

                //reset password tooltip content
                $('#leanPasswordToolTip').attr('data-content', this.componentsCmsNode.registration.errorMessages.errorPasswordLength);

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error","New Registration", "file:loginRegisterView.js|function:clearValidationMessages|" + e, null, 1);
            }

        },
        showHidePhoneConsentForm:function(e){
            var focusElement = $(e.target);
            var isOptIn = focusElement.is(":checked");
            var userId = (typeof window.userSession !== "undefined") && userSession.userId || '';
            var trackText =  'UID : '+ userId + ' | Visitor Id :'+$.cookie('visitorId');

            if(isOptIn){
                $('.registrationPhoneBlock').slideDown();
                GAUtils.eventTracking.trackevent("", "Opt in For SMS", trackText +"|" + isOptIn+ " | Referrer: Registration");
                $('#optInSmsPdp').prop('checked',true);
                $('.pdpSmsConsent').removeClass('hide');
            }else{
                $('.registrationPhoneBlock').slideUp();
                $('#optInSmsPdp').prop('checked',false);
                $('.pdpSmsConsent').addClass('hide');
                GAUtils.eventTracking.trackevent("", "Opt in For SMS", trackText +"|" + isOptIn+ " | Referrer: Registration");

            }
        },
        updateHeight: function(options){
          try {
            
            var height = options.height || '';

            if (!height) return;

            if (typeof newSkinning !== "undefined" && newSkinning) {
            }else {
              $('.loginRegisterPopup .modal-content').animate({'height': height});
            }

          } catch (error) {
            
          }
        },
        forgotPasswordViewForLoggedUser: function(e) {
            try{
                
                var userEmail = $(e.target).attr('data-userEmail') || '',
                    emailObject = $("#forgotPasswordEmailId");

                $('.modal-content').removeClass('activeStage');
                $('#loginRegisterBlock').addClass('activeStage');
                    
                $("#header-navigation").removeClass("in");
                $('#notificationMobilePopup').modal('hide');
                $('#loginRegisterDialog').modal("show");

                $('#loginRegisterDialog').on('shown.bs.modal', function (e) {
                    if(!$('body').hasClass('modal-open')){
                        $('body').addClass('modal-open');
                    }
                });

                $('#loginRegisterDialog').on('hidden.bs.modal', function (e) {
                      $('#registerationDiv, #loginDiv').css('pointer-events', 'auto');
                      $('#registerationDiv, #loginDiv').css('color', '#333');
                }); 

                $('#registerationDiv, #loginDiv').css('pointer-events', 'none');
                $('#registerationDiv, #loginDiv').css('color', '#ccc');

                this.showForgotPassword();  
                
                if(userEmail) {
                  this.addInprogressClass(emailObject);
                  emailObject.val(userEmail).blur();
                }
                
            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:loginRegisterView.js|function:forgotPasswordViewForLoggedUser|" + e, null, 1);
            }
        }
      
    });

    return new loginRegisterView;
});

var widgetId1;
function captchaCallback() {
  try {
    widgetId1 = grecaptcha.render('recaptchaPH', {
      'sitekey' : '6Lex9CIaAAAAADhemTu4p3PC0qRA1kcvGi7rz8R8',
      'theme' : 'light'
    });
  } catch(e){}
}
;