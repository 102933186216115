
/*jslint browser:true*/
/*global define, root*/
define('saveListDrawerView',['require','jquery','underscore','backbone','dUtil','bootstrap-editable','handlebars','ajaxUtil','GoogleAnalyticsUtils','commonutils','apputils','userCheckView','hbs!tpl/saveDrawer/favourite-slider','hbs!tpl/saveDrawer/saveListDrawer','hbs!tpl/saveDrawer/watchedPropTemp','hbs!tpl/saveDrawer/savedSearcheTemp','hbs!tpl/saveDrawer/favouriteTuple','hbs!tpl/saveDrawer/favourite-slider-new','hbs!tpl/saveDrawer/savedSearcheTempNew','hbs!tpl/saveDrawer/favouriteTupleNew','placeBidView','loginRegisterView'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone");

    var dUtil = require("dUtil");
    var bootstrapEditable = require("bootstrap-editable");
    var Handlebars = require("handlebars");
    var AjaxUtil = require("ajaxUtil");
    var GAUtils = require("GoogleAnalyticsUtils");
    var Utils = require("commonutils");
    var app = require('apputils');
    var userCheckView = require('userCheckView');
    var favouriteSliderTemplate  =  require("hbs!tpl/saveDrawer/favourite-slider");
    var saveListDrawerT  =  require("hbs!tpl/saveDrawer/saveListDrawer");	
    var watchListT  =  require("hbs!tpl/saveDrawer/watchedPropTemp");
    var saveSearchListT  =  require("hbs!tpl/saveDrawer/savedSearcheTemp");
    var favouriteTuple  =  require("hbs!tpl/saveDrawer/favouriteTuple");
    var favouriteSliderTemplateNew  =  require("hbs!tpl/saveDrawer/favourite-slider-new");
    var saveSearchListTNew  =  require("hbs!tpl/saveDrawer/savedSearcheTempNew");
    var favouriteTupleNew  =  require("hbs!tpl/saveDrawer/favouriteTupleNew");
    var PlaceBidView = require("placeBidView");
    var placeBidView =  new PlaceBidView();
    var loginRegisterView = require('loginRegisterView');
    var loggedIn = true;
    var hasWatchedList = false;
    var saveListDrawerView = Backbone.View.extend({
        
        el: $('.js-saved-drawer'),
        listingIds: [],
        timerObjects: [],
        listingId: null,
        bidType: null,
        events: {
           // 'click .toggleSaveList, .notifications-slider-shade': 'toggleSaveListDrawer',
            'click .login-opener' : 'showLogin',
            'click .js-accordion-head' : 'toggleAccordion',
            'click .savedSearches-item-delete,.deleteWatchProperty' : 'deleteSaves',
            'click #closeDrawerMobile,#saveListCloseIcon' : 'backToPreviousPage',
            "click .oinButton" : "placeBidAction",
            "click .placeBestBidButton" : 'openBestBidPopup',
            "click .placeOfferButton" : 'placeOfferAction', 
            "click .start-searching" : "startSearching",
            "click .confirmOfferNegotiation": 'offerNegotiation',
            "click .drawer-saved-cancel": 'closeModelView',
            //"click .drawer-view-details": 'viewDetails',  
            
        },

        initialize: function () {
            try{
                var thisObj = this;
                thisObj.ajaxReqRef= null;
                thisObj.attachEvents();
                this.isUserLoggedIn = false;
                this.emailId = '';
                this.watchOffSet=-1;
                this.watchMoreClicked = false;
                
                this.watchShowMoreClickedFlag = false;
                this.watchPageSize = paginationWatchNum;
                this.savedSearchParams = {clickedcount:0,
                                        pageNumber:0,
                                        pageSize:4,
                                        totalCount:0,
                                        results:null
                                        }; 
                this.currPageSave = GAUtils.eventTracking.recognizePageType();
                $('#closeDrawerMobile,#saveListCloseIcon').click(
                        function(){
                                thisObj.backToPreviousPage();
                        }); 
                $('.login-opener').click(
                        function(){
                                thisObj.showLogin();
                        }); 

                this.listingIds = [];
            }catch(e){
                
            }
        },
        viewDetails: function(evt){
            var userId = (typeof window.userSession !== "undefined") && userSession.userId || 'NA';
            var propertyId = $(evt.currentTarget).attr('currentpropid');
            var eventLabel =  'UID : ' + userId + ' | Visitor Id : ' +$.cookie('visitorId')+ ' | Property Id: ' + propertyId + " | Source: Save List Drawer";
            
            GAUtils.eventTracking.trackevent("", "View Details", eventLabel);
        },
        backToPreviousPage:function(e){ 
            //window.location.href = document.referrer;
            GAUtils.eventTracking.trackevent(this.currPageSave+" - Save List Slider","Save List Slider - Close",$.cookie('visitorId')); 
            $(window).scrollTop( "0" );
            $('#notificationMobilePopup').modal('hide');
        },
        closeModelView: function(e){
            GAUtils.eventTracking.trackevent(this.currPageSave+" - Save List Slider","Save List Slider - Close",$.cookie('visitorId')); 
            $(window).scrollTop( "0" );
            $(".h-save-search-icon").removeClass("active");
            $('#notificationMobilePopup').modal('hide');
        },
        placeBidAction : function(loginInfo,thisObj){
            
            try{
                var evt = typeof thisObj.viewObject !='undefined'?thisObj.viewObject.placeBidEventParam : thisObj.placeBidEventParam,
                self=typeof thisObj.viewObject !='undefined'?thisObj.viewObject : thisObj;
                evt.stopPropagation();
                evt.preventDefault();
                var listingId = $(evt.target).attr('listingId'),
                    bidType = $(evt.target).attr('data-val'),
                    bidAmount, 
                    userTypeOptions = {},
                    bidIncrement;
                    userTypeOptions.listingId = listingId;
                    userTypeOptions.thisObj = self;
                    userTypeOptions.callBack = self.placeBidAfterUserCheck;
                    userTypeOptions.event = evt;
                    userCheckView.userTypeCheck(userTypeOptions);
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:placeBidAction|" + e, null, 1);
            }

        },
        placeBidAfterUserCheck : function(options){
            var self = options.thisObj,
                evt = options.event,
                bidAmount, 
                userTypeOptions = {},
                bidIncrement;
                var listingId = $(evt.target).attr('listingId');
                var bidType = $(evt.target).attr('data-val');
                
            if (bidType === 'placeBidButton') {

                bidAmount = $.trim($('.' + listingId + ' .heighest-bid-container .current-bid-value').text() || ''); 
                bidAmount = bidAmount && bidAmount.replace('$','').replace(/,/g,'') || 0;
                bidAmount = Number(bidAmount);
                bidIncrement = $.trim($('.' + listingId + ' .heighest-bid-container .current-bid-value').attr('data-bid-increment') || ''); 
                bidIncrement = bidIncrement && bidIncrement.replace('$','').replace(/,/g,'') || 0;
                bidIncrement = Number(bidIncrement); 
                bidAmount = bidAmount + bidIncrement;

                // Add validation API (Status check, Amount check) before moving to bidwizard 
                placeBidView.validateBidAmount({
                    listingId: listingId,
                    bidAmount: bidAmount,
                    userId: userSession.userId,
                    viewObject: self,
                    bidType: bidType,
                    callback: self.validateBidDetails
                });
            } else if(bidType === 'ownItNow') {
                
                bidAmount = $.trim($('.oin .oin-price').text() || ''); 
                bidAmount = bidAmount && bidAmount.replace('$','').replace(/,/g,'') || 0;
                bidAmount = Number(bidAmount);
                bidIncrement = $.trim($('.oin .current-bid-value').attr('data-bid-increment') || ''); 
                bidIncrement = bidIncrement && bidIncrement.replace('$','').replace(/,/g,'') || 0;
                bidIncrement = Number(bidIncrement); 
                bidAmount = bidAmount + bidIncrement;

                // Add validation API (Status check, Amount check) before moving to bidwizard 
                placeBidView.validateBidAmount({
                    listingId: listingId,
                    bidAmount: bidAmount,
                    userId: userSession.userId,
                    viewObject: self,
                    bidType: bidType,
                    callback: self.validateBidDetails
                });
            } else {
                self.proceedToBidwizard({
                    listingId: listingId,
                    bidType: bidType,
                    bidAmount : bidAmount
                });
            }

        },
        validateBidDetails: function(options){
            try {

                options = options || {};

                var callbackOptions = options.callbackOptions || {},
                    response = options.responseData || {},
                    status = response.status || 'ERROR',
                    listingId = callbackOptions.listingId || null, 
                    bidType = callbackOptions.bidType || null, 
                    self = callbackOptions.viewObject || this, 
                    bidAmount = Number(callbackOptions.bidAmount) || null,
                    trackText,
                    errorCode,
                    errorMessage;

                // TODO: remove test data
                // status = 'FAILURE';
                //AUTO_OUTBID_ERROR,AUCTON_HAS_ENDED, OUTBID_ERROR
                // response.errorMessage = "AUCTON_HAS_ENDED";

                if (status === 'SUCCESS') {
                    
                    self.proceedToBidwizard({
                        listingId: listingId,
                        bidType: bidType,
                        viewObject: self,
                        bidAmount : bidAmount
                    });
                    
                } else {
                    
                    errorCode = response.errorMessage || '';

                    if(errorCode === 'SESSION_REQUIRED') {
                        self.listingId = listingId;
                        self.bidType = bidType;
                        loginRegisterView.openLoginPopup({
                            callback: self.proceedToBidwizard,
                            viewObject: self,
                            isRegister : true
                        });                                
                    } else if (errorCode === 'USER_IS_NOT_AUTHORIZED_TO_PLACE_BID'){
                        // IF USER STATUS IS PROFILE or IDOLOGY, let them land on Bidwizard.
                        self.proceedToBidwizard({
                            listingId: listingId,
                            bidType: bidType,
                            viewObject: self,
                            bidAmount : bidAmount
                        });
                    } else {
                        errorMessage = saveListDrawerContent.globalAlerts.errorMessages[errorCode] || saveListDrawerContent.globalAlerts.errorMessages['GENERIC_ERROR'];
                        errorMessage = errorMessage.replace("###BID_AMOUNT###", ( '$' + bidAmount.formatMoney() || ''));
                        $('.' + listingId + " .tuple-inline-error").removeClass("hide").html(errorMessage).show("slow");      
                        $('.' + listingId + " .closeicon").addClass('closeicon-top');  
                    }
                } 

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:validateBidDetails|" + e, null, 1);
            }
        },

        placeOfferAction : function(evt){
            
            try{
                evt.stopPropagation();
                evt.preventDefault();

                var self = this,
                    listingId = $(evt.target).attr('listingId'),
                    bidType = $(evt.target).attr('data-val'),
                    propertyId = $(evt.target).attr('propId');


                if (jQuery.hasPendingRequest()) { 
                    return; 
                }
                jQuery.httpRequest({
                    url: contextPath+'/portal/buyerdashboard/getHighestOfferAmountForLatestListing/'+propertyId,
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (responseData) {
                        self.listingId = listingId;
                        self.bidType = bidType;
                        if(responseData && responseData.STATUS == 'SESSION_REQUIRED') {
                            
                            loginRegisterView.openLoginPopup({
                                callback: self.proceedToBidwizard,
                                viewObject: self,
                                isRegister : true
                            });    
                        } else if(responseData) {
                            self.offerAmount  = responseData.minimumOfferAmount || 0;
                            self.proceedToBidwizard({
                                listingId: listingId,
                                bidType: bidType,
                                offerAmount: self.offerAmount,
                                viewObject: self
                            });
                        }
                     },
                    error: function (error) {
                        GAUtils.eventTracking.trackevent("Ajax Error: saveListDrawer", "file:saveListDrawerView.js,function:placeOfferAction", error);
                    }
                });

            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:placeOfferAction|" + e, null, 1);
            }
        },


        proceedToBidwizard: function(options, viewObject) {
            try {
                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && loginRegisterView.proxyBidObj.source == 'savelist'){
                    options = loginRegisterView.proxyBidObj  || {};
                } else {
                    options = options || {};
                }

                var self = viewObject || options.viewObject || this,
                    listingId = options.listingId || self.listingId || null,
                    propertyId = options.propertyId || null,
                    bidType = options.bidType || self.bidType || null,
                    offerAmount = options.offerAmount || self.offerAmount || 0,
                    bidAmount = 0, //options.bidAmount || self.bidAmount || 0,
                    bidAmount = options.bidAmount || self.bidAmount || 0,
                    trackText,
                    buttonLabel;

                if(!listingId || !bidType) {
                    GAUtils.eventTracking.trackevent("Error", "saveListDrawer", "file:saveListDrawerView.js|function:proceedToBidwizard|listingId/bidType not available", null, 1);
                    return; 
                }

                // invoke submit bid 
                trackText = userSession.userId + '|' + listingId;
                if($.trim(bidType) == 'placeBidButton' || $.trim(bidType) == 'placeBackUpBid'){
                    buttonLabel = (loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled) ? "Place Proxy Bid" : "Place Bid";
                 }else if($.trim(bidType) == 'ownItNow') {
                    buttonLabel = "Own It Now";
                 } 
                else {
                    buttonLabel = "Place Offer";
                }
                GAUtils.eventTracking.trackevent("", buttonLabel, " userId: " + userSession.userId + " | listingId: " + listingId + " | Source: Save List Drawer");

                if($.trim(bidType) == 'placeBidButton'){
                    GAUtils.eventTracking.trackevent(self.currPageSave,"Place Bid Click", trackText);
                    if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && loginRegisterView.proxyBidObj.source == 'savelist'){
                        self.createForm('PLACE_BID' , bidAmount , propertyId);
                    } else {
                        self.createForm('PLACE_BID' , bidAmount , listingId);
                    }
                }else if ($.trim(bidType) == 'placeBackUpBid'){
                    GAUtils.eventTracking.trackevent(self.currPageSave,"Place Backup Bid Click", trackText);
                    self.createForm('PLACE_BACKUP_BID' , bidAmount , listingId);
                }else if($.trim(bidType) == 'placeOfferButton'){
                    GAUtils.eventTracking.trackevent(self.currPageSave,"Place Offer Click", trackText);
                    self.createForm('PLACE_OFFER' , offerAmount , listingId);
                }else if ($.trim(bidType) == 'placeBackUpOffer'){
                    GAUtils.eventTracking.trackevent(self.currPageSave,"Place Backup Offer Click", trackText);
                    self.createForm('PLACE_BACKUP_OFFER' , offerAmount , listingId);
                } else if($.trim(bidType) == 'ownItNow'){
                    // for bitType OIN
                    GAUtils.eventTracking.trackevent(self.currPageSave,"OIN Click", trackText);
                    self.createForm('OWN_IT_NOW' , bidAmount , listingId);
                }
                $('#bidForm').submit();

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:proceedToBidwizard|" + e, null, 1);
            }
        },
        createForm : function(bidType , amount , listingId) {
            try{

                var url = ($.trim(bidType) == 'PLACE_BID' || $.trim(bidType) == 'PLACE_BACKUP_BID'|| $.trim(bidType) == 'OWN_IT_NOW') ? contextPath + '/portal/bid?ref=saveListDrawer' : url = contextPath + '/portal/offer?ref=saveListDrawer',
                    bidTypeLabel = ($.trim(bidType) == 'PLACE_BID' || $.trim(bidType) == 'PLACE_BACKUP_BID' || $.trim(bidType) == 'OWN_IT_NOW') ? 'bidType': 'offerType',
                    bidAmountLabel = ($.trim(bidType) == 'PLACE_BID' || $.trim(bidType) == 'PLACE_BACKUP_BID' || $.trim(bidType) == 'OWN_IT_NOW') ? 'bidAmount' : 'offerAmount';


                if(typeof amount === 'undefined'){
                    amount=0;
                }
                if(loginRegisterView.proxyBidObj && loginRegisterView.proxyBidObj.isProxyBiddingEnabled && loginRegisterView.proxyBidObj.source == 'savelist'){
                    var newForm = $('<form>', {
                        'id' : 'bidForm',
                        'action': url,
                        'method' : 'POST',
                        'target': '_top'
                    }).append($('<input>', {
                        'name':  bidTypeLabel,
                        'value': bidType,
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'propertyId',
                        'value': listingId,
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name':  bidAmountLabel,
                        'value': amount,
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'tempUserId',
                        'value': '',
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'previousBidEmailId',
                        'value': '',
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'isEditMode',
                        'value': 'false',
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'fcProxyBidAuction',
                        'value': 'true',
                        'type': 'hidden'
                    }));
                } else {
                    var newForm = $('<form>', {
                        'id' : 'bidForm',
                        'action': url,
                        'method' : 'POST',
                        'target': '_top'
                    }).append($('<input>', {
                        'name':  bidTypeLabel,
                        'value': bidType,
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'listingId',
                        'value': listingId,
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name':  bidAmountLabel,
                        'value': amount,
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'tempUserId',
                        'value': '',
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'previousBidEmailId',
                        'value': '',
                        'type': 'hidden'
                    })).append($('<input>', {
                        'name': 'isEditMode',
                        'value': 'false',
                        'type': 'hidden'
                    }));
                }
                

                $('body').append(newForm);

                return true;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:createForm|" + e, null, 1);
            }
        },
        toggleSaveListDrawer: function(e) {
            
            var thisObj = this,
                loginStatus = false;

            thisObj.savedDrawer = $('.notifications-slider');
            if(thisObj.savedDrawer.hasClass('saved-items-active')) {

                $('.savedSearches-item-delete,.deleteWatchProperty,.login-opener,.register-opener,.linkToSrpFromDrawer,.pdpLinkFromDrawer').attr('tabindex',-1);

                $('.notifications-slider-shade').fadeOut(500);
                thisObj.savedDrawer.removeClass('saved-items-active');
                $('body').removeClass('no-scroll');
                GAUtils.eventTracking.trackevent(this.currPageSave+" - Save List Slider","Save List Slider - Close",$.cookie('visitorId'));

            } else {

                if(window.globalAlertView) window.globalAlertView.hideAlertContainer();

                loginStatus = globalParam.config.isLoggedin || false;
                thisObj.loadSaveListDrawer(loginStatus);

                $('.login-opener').removeAttr('tabindex');
                $('.register-opener').removeAttr('tabindex');                                
                $('.savedSearches-item-delete,.deleteWatchProperty,.pdpLinkFromDrawer,.linkToSrpFromDrawer').attr('tabindex',0);

                $('.notifications-slider-shade').fadeIn(500);
                thisObj.savedDrawer.addClass('saved-items-active');
                $('body').addClass('no-scroll');
                GAUtils.eventTracking.trackevent(this.currPageSave + " - Save List Slider","Save List Slider - Open",$.cookie('visitorId')); 

                // remove padding top for close icon 
                $(".closeicon").removeClass('closeicon-top');  
                $(".tuple-inline-error").addClass("hide").html("");      
            } 

        },

        toggleAccordion: function(e){
            var thisObj = this;
            var obj = e.currentTarget;
            if($(obj).parent().hasClass('closed')) {
                $(obj).parent().removeClass('closed');
            } else {
                $(obj).parent().addClass('closed');
            }    
        },

        showLogin:  function(){
            if($(window).width()<768){
                $('#notificationMobilePopup').modal('hide');
            }
            $('#loginModal').modal('show');
            //this.toggleSaveListDrawer();
        },

        loadCount: function(isloginStatus) {
            
            var thisObj = this;
            var dateParam = new Date().getTime();
            var data = {};
            data.emailId  = thisObj.emailId;  
            $.ajax({
                url: contextPath+'/portal/getSavedSearchAndPropertyCount',
                dataType: "json",
                type: 'POST',
                data: data,
                contentType: "application/json; charset=utf-8"
            }).done(function(data) {
                if(data.status == 'SUCCESS'){
                    var saveCount = parseInt(data.SAVED_SEARCH_AND_PROPERTY_COUNT);
                    
                    if(saveCount > 99) {
                        $(".saveListCount, .saved-items-count").text("99+").removeClass('hide');
                        
                        $('.savesearch-link').removeClass('hide-item');
                    }else if(saveCount > 0) {
                        $(".saveListCount, .saved-items-count").text(data.SAVED_SEARCH_AND_PROPERTY_COUNT).removeClass('hide');
                        $('.savesearch-link').removeClass('hide-item');
                        if(isloginStatus == false) { 
                            $('.savesearch-link').addClass('hide-item');
                        }
                    } else {
                         $(".saveListCount, .saved-items-count").text('').addClass('hide');
                    }

                } else {
                     $(".saveListCount, .saved-items-count").text('').addClass('hide');
                }
            }).fail(function(e) {   
                $(".saveListCount, .saved-items-count").text('').addClass('hide');
            });
        },

        loadSaveListDrawer: function(isLoggedIn){
            
            if(saveListDrawerContent!=undefined && saveListDrawerContent.GlobalConfig!=undefined && saveListDrawerContent.GlobalConfig.enableGlobalSaveHeader != undefined && saveListDrawerContent.GlobalConfig.enableGlobalSaveHeader == 'Y'){
                if(saveListDrawerContent.saveListDrawer!=undefined){
                    var thisObj = this;
                    var savedDrawerContent = saveListDrawerContent.saveListDrawer;
                
                    var ifSubscribe = Utils.getCookieDetails("openSubscribe");
                    savedDrawerContent.ifSubscribe = false;
                    savedDrawerContent.loggedIn = isLoggedIn;
                    if(isLoggedIn) {                
                        hasWatchedList=true;
                        this.emailId = window.userSession.user.userEmailId;
                        this.isUserLoggedIn = true;
                    } else if(ifSubscribe){
                        this.isUserLoggedIn = false;
                        savedDrawerContent.ifSubscribe = true;
                        hasWatchedList=true;
                        var user=JSON.parse(Utils.getCookieDetails('subscribeUserDetails'));
                        this.emailId =(user && user.emailId)?user.emailId:'';
                    }   else {
                        this.emailId = '';
                    }
        
                    var currLocation= window.location.href;
                    savedDrawerContent.contextPath = contextPath;
                    savedDrawerContent.isLeanRegistrationEnabled = isLeanRegistrationEnabled;
                    
                    // Check for the version and assign template 
                    if(newSkinning){
                        var sourceTemplate = favouriteSliderTemplateNew;   
                    }else{
                        var sourceTemplate = favouriteSliderTemplate;
                    } 
                    
                    if($(window).width()<768){
                        $('#notificationMobilePopup').html(sourceTemplate(savedDrawerContent));
                    }else{
                        $('#notificationPopup').html(sourceTemplate(savedDrawerContent));
                    }
                    if(typeof $.cookie('subscribeEmail') != 'undefined' && $.cookie('subscribeEmail') !='' && !(typeof window.userSession != 'undefined' && typeof window.userSession.user != 'undefined' && typeof window.userSession.user.loggedIn != 'undefined' && window.userSession.user.loggedIn)){
                        $('.drawer-register-links').removeClass('hidden-xs');
                        $('.drawer-register-links .heading,.drawer-register-links .ul-saved-items,.drawer-register-links .login-button').addClass('hide');
                        $('#loginRegisterPopupDrawer').addClass('completeProfile').html(saveListDrawerContent.alertViewSec.favDrawer.title);
                            
                    } else {
                        $('.drawer-register-links').addClass('hidden-xs');
                    }
                    
                    $('#saveListDrawerDiv .js-accordion-head').on('click',function(e){
                        thisObj.toggleAccordion(e);
                    });
        
                    $('#watchPropertyShowMore').on('click',function(e){
                        thisObj.watchpropertyShowMore(e);
                            GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Show More - Watch List",$.cookie('visitorId')); 
                    });
                        $('#savedSearchShowMore, .searched-suggestion--show-all').on('click',function(e){
                            thisObj.setSavedSearchParams(e);
                            GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Show More - Save Search List",$.cookie('visitorId')); 
                        });
                    thisObj.getSavedSearchesData();
                    thisObj.getWatchList();
                    thisObj.loadCount();
                    
                    $('.favorites-drawer-container .drawer-cancel').off('click').on('click', function(e){
                        if($(window).width()<768){
                            thisObj.backToPreviousPage();
                        } else {
                            thisObj.toggleSaveListDrawer(e);
                        }
                    });

                }
            }            
        },
        showLoader: function(){
            $('.favorites-drawer-container .favourites-user-data').addClass('hide');
            $('.favorites-drawer-container .favorites-loader').removeClass('hide');
        },
        hideLoader: function(){
            if(newSkinning){
                if((typeof window.userSession != 'undefined' && typeof window.userSession.user != 'undefined' && typeof window.userSession.user.loggedIn != 'undefined' && window.userSession.user.loggedIn) || $('.propDetailsDrawer').length || $('.savedSearches-container').length){
                    $('.favorites-drawer-container .favourites-user-data').removeClass('hide');
                }
            }else{
                $('.favorites-drawer-container .favourites-user-data').removeClass('hide');   
            }
            $('.favorites-drawer-container .favorites-loader').addClass('hide');
        },
        attachEvents: function(){
            var thisObj = this;
            //$('#headerHeartIconMobile').click(function(){
            $('#headerHeartIconMobile, .headerHeartIconMobile, .saveListCountMob').click(function(e){   

                e.stopPropagation();
                if(window.globalAlertView) window.globalAlertView.hideAlertContainer();
                GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Save List Slider - Open",$.cookie('visitorId')); 
                $('#notificationMobilePopup').modal('show');
                thisObj.reInitializeSavedSearchParams(0, 4);
                thisObj.toggleSaveListDrawer(e);

            });
            
            //$('.headerHeart, .notifications-slider-shade, .saveListCount').off('click').on('click', function(e){
            $('.headerHeart, .saveListCountDesktop, .notifications-slider-shade').off('click').on('click', function(e){
                e.stopPropagation();
                thisObj.reInitializeSavedSearchParams(0, 4);
                thisObj.toggleSaveListDrawer(e);
            });
        },

        getSavedSearchesData: function () {
            var thisObj = this;
            if (thisObj.requestPending) {
                return;
            } else {
            thisObj.requestPending = true;
            }
            $('.searched-suggestion--show-all').css("display","none");
            $('#savedSearchLoadingIcon').removeClass("hide");
             
            var pageCount = thisObj.savedSearchParams.pageNumber;
            if(thisObj.savedSearchParams && thisObj.savedSearchParams.pageNumber > 0){
                pageCount = (parseInt(thisObj.savedSearchParams.pageNumber) * parseInt(thisObj.savedSearchParams.pageSize));
            }
             
            var data = {'emailId':thisObj.emailId, 'pageNumber':pageCount, 'pageSize':thisObj.savedSearchParams.pageSize};

            $.ajax({
               /* headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' 
                }, */
                url: contextPath+'/portal/drawer/getUserSavedSearch',
                type: 'POST',
                data: JSON.stringify(data),
                dataType: "json",
                contentType: "application/json; charset=utf-8"
            }).done(function(data) {
                thisObj.requestPending = false;
                $('#savedSearchLoadingIcon').addClass("hide");
                thisObj.savedSearchesdataReceived(data);
            }).fail(function(e) {   
                thisObj.requestPending = false;
                $('#savedSearchLoadingIcon').addClass("hide");                 
               // $.error('ajax error', e);
            // }).complete(function() {
            //     thisObj.requestPending = false;
            //     $('#savedSearchLoadingIcon').addClass("hide");
            });
        },
        savedSearchesdataReceived: function (data){
           var thisObj = this,
                itemsData = data.saveSearchResponseList,
                count = data.saveSearchCount,
                saveSearchDetails = [],
                updatedResponseData = {};
            this.savedSearchParams.totalCount = count;
            $('.searched-suggestion--show-all').css("display","none");
            $("#savedSearch-default-text, .savedSearch-default-text").css("display","none");
            if(count === 0 || count==null){
                $("#savedSearch-default-text, .savedSearch-default-text").css("display","block");
                $('.savedSearchesContainer .noResultContainer').css('display','block');
                $('.dashTabHeader,#saveSearchView').css('display','none');
                 $(".save-searches-count").html("");
                return false;
            }
            $(itemsData).each(function(index, element) {
                var searchC = element.searchCriteriaList;
                searchC = searchC.filter(function( obj ) { return obj.criteriaKey !== 'SEARCH_BY'; });
                var savedUrl = {};
                savedUrl.searchBy = element.searchBy;
                savedUrl.baseSearchUrl = element.baseSearchUrl;
                if(newSkinning){
                    var searchFields = thisObj.formUrlNew(savedUrl,searchC);
                }else{
                    var searchFields = thisObj.formUrl(savedUrl,searchC);
                }

                element.searchUrl = searchFields.url;
                element.searchValues = searchFields.savedFilters;
                element.searchValueGA = searchFields.savedFiltersGA;
                saveSearchDetails.push(element);
            });

            if(thisObj.savedSearchParams.results === null || thisObj.savedSearchParams.pageNumber == 0){
                this.savedSearchParams.results = saveSearchDetails;
            }else{
                this.savedSearchParams.results = _.union(thisObj.savedSearchParams.results, saveSearchDetails);
            }

            //isUserLoggedIn
            for(var param in this.savedSearchParams.results) {
                if(thisObj.isUserLoggedIn) {
                    thisObj.savedSearchParams.results[param].isLoggedIn = true;
                } else {
                    thisObj.savedSearchParams.results[param].isLoggedIn = false;
                }
            }
            updatedResponseData["saveSearchDetails"] = thisObj.savedSearchParams.results;

            if(count > thisObj.savedSearchParams.pageSize && this.savedSearchParams.results.length < count){
                $('.searched-suggestion--show-all').css("display","block");
            }
            if(newSkinning){
                $('#savedSearchDiv, .save-searches-list').html(saveSearchListTNew(updatedResponseData));
            }else{
                $('#savedSearchDiv, .save-searches-list').html(saveSearchListT(updatedResponseData));
            }

            // update the count 
            if(count > 0) {
                $(".save-searches-count").html(count + " saved " + ((count > 1 ) ? "seaches" : "search"));
            } else {
                $(".save-searches-count").html("");
            }

            // Apply editable
            thisObj.applyEditable();

            $('.savedSearches-item-delete').on('click',function(e){
                thisObj.deleteSaves(e);
            });
            $('.linkToSrpFromDrawer').click(function(e){
                thisObj.trackGAToSRP(e);
            });
            
        },
        trackGAToSRP : function(evt){
            try{
                evt.preventDefault();
                var trackText = $.cookie('visitorId')+'|'+$(evt.currentTarget).attr('filterVal');
                var urlHref = $(evt.currentTarget).attr('href');
                GAUtils.eventTracking.processLinkAndTrackEvent({linkValue:urlHref, appendContextPath:false}, this.currPageSave+' - Save List Slider','FromSaveListSliderToSRP',trackText, ""); 
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:trackGAToSRP|" + e, null, 1);   
            }
        },
        trackGAToPDP : function(evt){
            try{
                
               
                var trackText = $.cookie('visitorId')+'|'+$(evt.currentTarget).attr('currentpropid');
                var urlHref = $(evt.currentTarget).attr('href');

                GAUtils.eventTracking.trackevent(this.currPageSave+" - Save List Slider",'FromSaveListSliderToPDP',trackText);

               // GAUtils.eventTracking.processLinkAndTrackEvent({linkValue:urlHref, appendContextPath:false}, this.currPageSave+" - Save List Slider",'FromSaveListSliderToPDP',trackText, ""); 
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:trackGAToPDP|" + e, null, 1);   
            }
        },

        formUrl: function(savedUrl,searchCriteria)
        {
            try{
                var url = [];
                var savedFilters = [];
                var minPrice = '';
                var maxPrice = '';
                var minYearBuilt = '';
                var maxYearBuilt = '';
                var minSqrFt = '';
                var maxSqrFt = '';
                var bathRoom = '';
                var bedRoom = '';
                var propType = '';
                var propStatus = '';
                var showmoreStatus = '';
                var occupyStatus = '';
                var category = '';
                var showMore = '';
                var ownItNow = '';
                var listingType = '';
                var sortBy = '';
                var propStatusDesc='';
                var propTypeDesc='';
                var listingTypeDesc='';
                var propertyShowMoreStatusDesc ='';
                var ownItNowDesc = ''; 
                var previewProperty ='';
                var previewPropertyDesc ='';
                var noBuyerPremiumFilter ='';
                var noBuyerPremiumFilterDesc ='';
                var reservePriceFilter ='';
                var reservePriceFilterDesc ='';
                var selfShowingFilter ='';
                var selfShowingFilterDesc ='';
                var brokerCoOpFilter ='';
                var brokerCoOpFilterDesc ='';
                var newToHubzuFilter='';
                var newToHubzuFilterDesc='';
                var auctionEndingTodayFilter='';
                var auctionEndingTodayFilterDesc='';
                var cashOnlyFilter='';
                var cashOnlyFilterDesc='';
                var rectSrchFlag ='';
                var view ='';
                var neLatLng ='';
                var swLatLng ='';
                var centerLatLng ='';
                var zoomValue ='';
                var occupancyStatus='';
                var latitude, longitude;
                if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                }else{
                    if(savedUrl.searchBy!=null)
                    {
                        url.push('searchBy=' + savedUrl.searchBy);
                    }
                    else
                    {
                        url.push('searchBy=All States');
                    }
                }
                _.each(searchCriteria , function(criteria){

                        switch(criteria.criteriaKey){
                            case "minPriceValue" :
                                    minPrice = criteria.criteriaValue;
                                    
                                break;
                            case "maxPriceValue" :
                                    maxPrice = criteria.criteriaValue;
                                    
                                break;
                            case "minYearBuilt" :
                                    minYearBuilt = criteria.criteriaValue;
                                    
                                break;
                            case "maxYearBuilt" :
                                    maxYearBuilt = criteria.criteriaValue;
                                  
                                break;
                            case "minPropertySize" :
                                    minSqrFt = criteria.criteriaValue;
                                break;
                            case "maxPropertySize" :
                                    maxSqrFt = criteria.criteriaValue;
                                break;
                            case "bath" :
                                    bathRoom = parseInt(criteria.criteriaValue);
                                break;
                            case "bed" :
                                    bedRoom = parseInt(criteria.criteriaValue);
                                break;
                            case "propertySubType":
                                    propType = criteria.criteriaValue;
                                    propTypeDesc = criteria.criteriaValueDescription;
                                break;
                            case "propertyStatus":
                                    propStatus = criteria.criteriaValue;
                                    propStatusDesc = criteria.criteriaValueDescription;
                                   
                                break;
                            case "propertyShowMoreStatus":
                                    showmoreStatus = criteria.criteriaValue;
                                    propertyShowMoreStatusDesc = criteria.criteriaValueDescription;
                                break;
                            
                            case "category":
                                    category = criteria.criteriaValue;
                                  
                                break;
                            case "ownItNow":
                                    ownItNow = criteria.criteriaValue;
                                    ownItNowDesc = criteria.criteriaValueDescription;
                                break;
                            case "listingType":
                                    listingType = criteria.criteriaValue;
                                    listingTypeDesc = criteria.criteriaValueDescription;
                                 
                                break;
                            case "previewProperty": 
                                previewProperty = criteria.criteriaValue;
                                previewPropertyDesc = criteria.criteriaValueDescription;
                                break;
                                
                            case "noBuyerPremiumFilter": 
                                noBuyerPremiumFilter=criteria.criteriaValue;
                                noBuyerPremiumFilterDesc=criteria.criteriaValueDescription;
                                break;

                            case "reservepricerevealed": 
                                reservePriceFilter=criteria.criteriaValue;
                                reservePriceFilterDesc=criteria.criteriaValueDescription;
                                break;
                            
                            case "selfshowingenabled": 
                                selfShowingFilter=criteria.criteriaValue;
                                selfShowingFilterDesc=criteria.criteriaValueDescription;
                                break;

                            case "brokerCoOp": 
                                brokerCoOpFilter=criteria.criteriaValue;
                                brokerCoOpFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "newToHubzu": 
                                newToHubzuFilter=criteria.criteriaValue;
                                newToHubzuFilterDesc=criteria.criteriaValueDescription;
                                break;
                            
                            case "auctionEndingToday": 
                                auctionEndingTodayFilter=criteria.criteriaValue;
                                auctionEndingTodayFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "cashOnly": 
                                cashOnlyFilter=criteria.criteriaValue;
                                cashOnlyFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "sortBy":
                                sortBy = criteria.criteriaValue;
                                break;
                            
                            case "rectSrchFlag": 
                                rectSrchFlag=criteria.criteriaValue;
                                break;
                            case "neLatLng": 
                                neLatLng=criteria.criteriaValue;
                                break;
                            case "swLatLng": 
                                swLatLng=criteria.criteriaValue;
                                break;
                            case "centerLatLng": 
                                centerLatLng=criteria.criteriaValue;
                                break;
                            case "zoomValue": 
                                zoomValue=criteria.criteriaValue;
                                break;
                            case "view": 
                                view=criteria.criteriaValue;
                                break;
                            case "occupancyStatus":
                                occupancyStatus = criteria.criteriaValue;
                                break;
                            case "latitude":
                                latitude = criteria.criteriaValue;
                                break;
                            case "longitude":
                                longitude = criteria.criteriaValue;
                                break;
                        }
                });
                
                if(minPrice !== '' || maxPrice !== ''){
                    if(minPrice === '') {
                        url.push('priceRange=0-' + maxPrice);
                        savedFilters.push("Price : $0 - $"+maxPrice);
                    } else if(maxPrice === '') {
                        url.push('priceRange=' + minPrice + '-1000000plus' );
                        savedFilters.push("Price : $" + minPrice + "+"); 
                    } else {
                        url.push('priceRange=' + minPrice + '-' + maxPrice);
                        savedFilters.push("Price : $" + minPrice + '-$' + maxPrice ); 
                    }
                }
               
                if(minSqrFt !== '' || maxSqrFt !== ''){
                    if(minSqrFt === '') {
                        url.push('squareFeetRange=0-' + maxSqrFt);
                        savedFilters.push("Area: $0 - $"+maxSqrFt);
                    } else if(maxSqrFt === '') {
                        url.push('squareFeetRange=' + minSqrFt + '-10000plus' );
                        savedFilters.push("Area: $" + minSqrFt + "+"); 
                    } else {
                        url.push('squareFeetRange=' + minSqrFt + '-' + maxSqrFt);
                        savedFilters.push("Area: $" + minSqrFt + '-$' + maxSqrFt ); 
                    }
                }

                if(minYearBuilt !== '' || maxYearBuilt !== ''){
                    if(minYearBuilt === '') {
                        url.push('yearBuiltRange=Before 1800-' + maxYearBuilt);
                        savedFilters.push("Year Built: $0 - $"+maxYearBuilt);
                    } else if(maxYearBuilt === '') {
                        url.push('yearBuiltRange=' + minYearBuilt + '-After 2010' );
                        savedFilters.push("Year Built: $" + minYearBuilt + "+"); 
                    } else {
                        url.push('yearBuiltRange=' + minYearBuilt + '-' + maxYearBuilt);
                        savedFilters.push("Year Built: $" + minYearBuilt + '-$' + maxYearBuilt ); 
                    }
                }
            
                if(bathRoom!='')
                {
                    url.push('bath=' + bathRoom);
                    savedFilters.push('Baths : '+bathRoom + "+"); 
                }

                if(bedRoom!='')
                {
                    url.push('bed=' + bedRoom);
                    savedFilters.push('Beds : '+bedRoom + "+"); 
                }

                if(propType!='')
                {
                    var proparr = propType.split(',');
                    var propTypeDescArr = propTypeDesc.split(',');
                     _.each(proparr , function(val){
                        url.push('propertySubType=' + val);
                         
                         
                    });
                     _.each(propTypeDescArr,function(valDesc){
                         savedFilters.push("Property Type: "+valDesc); 
                     });
                }

                if(propStatus!='')
                {
                    var proparr = propStatus.split(',');
                    var propTypeDescArr = propStatusDesc.split(',');
                     _.each(proparr , function(val){
                            url.push('propertyStatus=' + val);
                    });
                    _.each(propTypeDescArr , function(valDesc){
                        savedFilters.push("Property Status: "+valDesc); 
                    });


                }

                    

                if(showmoreStatus!='')
                {
                     var proparr = showmoreStatus.split(',');
                     var propertyShowMoreStatusDescArr = propertyShowMoreStatusDesc.split(',');
                     _.each(proparr , function(val){
                        url.push('propertyShowMoreStatus=' + val);
                    });
                     _.each(propertyShowMoreStatusDescArr , function(valDesc){
                         savedFilters.push("Featured: "+valDesc); 
                    });
                }

                if(category!='')
                {
                    var catTypes = category.split(',');
                    var enterREO = false;
                    _.each(catTypes , function(val){

                        switch(val)
                        {
                            case "SHRT_SALE": case "NON_REO":
                                url.push('category=' + val);
                                savedFilters.push("Asset Type: Short Sale"); 
                            break;
                            case "bankowned(occupied)": 
                                url.push('category=bankowned(occupied)');
                                savedFilters.push("Asset Type: Bank Owned (Occupied)");
                            break;
                            case "bankowned(nonoccupied)": 
                                url.push('category=bankowned(nonoccupied)');
                                savedFilters.push("Asset Type: Bank Owned (Non Occupied)");
                            break;
                            case "nonbankowned(occupied)": 
                                url.push('category=nonbankowned(occupied)');
                                savedFilters.push("Asset Type: Non-Bank Owned (Occupied)");
                            break;
                            case "nonbankowned(nonoccupied)": 
                                url.push('category=nonbankowned(nonoccupied)');
                                savedFilters.push("Asset Type: Non-Bank Owned (Non Occupied)");
                            break;
                            case "foreclosure":
                                url.push('category=foreclosure');
                                savedFilters.push("Asset Type: Foreclosure"); 
                            break;
                            case "REO":
                                if(enterREO==false)
                                {
                                    enterREO = true;
                                    if(occupyStatus.indexOf('Y')>-1)
                                    {
                                        url.push('category=bankowned(occupied)');
                                        savedFilters.push("Asset Type: Bankowned(occupied)"); 
                                    }
                                    if(occupyStatus.indexOf('N')>-1)
                                    {
                                        url.push('category=bankowned(nonoccupied)');
                                        savedFilters.push("Asset Type: Bankowned(Non-occupied)"); 
                                    }
                                }
                            break;
                            case "bankowned":
                                url.push('category=bankowned');
                                savedFilters.push("Asset Type: "+saveListDrawerContent.saveListDrawer.saveSearchLabels.searchCriteria.bankOwned); 

                            break;
                            case "nonbankowned":
                                url.push('category=nonbankowned');
                                savedFilters.push("Asset Type: "+saveListDrawerContent.saveListDrawer.saveSearchLabels.searchCriteria.nonBankOwned); 

                            break;
                            case "npl":
                                url.push('category=npl');
                                savedFilters.push("Asset Type: "+saveListDrawerContent.saveListDrawer.saveSearchLabels.searchCriteria.npl); 

                            break;
                        }

                    });
                }
                if(occupancyStatus !=''){
                    var occupancyStatusTypes = occupancyStatus.split(',');
                    _.each(occupancyStatusTypes , function(val){
                        switch(val){
                            case "Y":
                                url.push('occupancyStatus=Y');
                                savedFilters.push("Occupancy Status: "+saveListDrawerContent.saveListDrawer.saveSearchLabels.searchCriteria.occupied); 
                            break;
                            case "N":
                                url.push('occupancyStatus=N');
                                savedFilters.push("Occupancy Status: "+saveListDrawerContent.saveListDrawer.saveSearchLabels.searchCriteria.nonOccupied); 

                            break;
                        }
                    });
                }
                if(ownItNow!='')
                {
                    url.push('listingType=AUCN');
                    url.push('ownItNow=Y');
                    if(url.indexOf('propertyStatus=ACTIVE')==-1)
                    {
                        url.push('propertyStatus=ACTIVE');

                    }
                    savedFilters.push("Listing Type: Auction"); 
                    savedFilters.push("Featured: "+ownItNowDesc); 
                }
                else if(listingType!='')
                {
                     var arr = listingType.split(',');
                     var listingTypeDescArr = listingTypeDesc.split(',');
                     _.each(arr , function(val){
                        url.push('listingType=' + val);
                    });
                     _.each(listingTypeDescArr , function(valDesc){
                        savedFilters.push("Listing Type: "+valDesc); 
                    });
                }
                if(previewProperty!=''){
                    url.push('marketingPhase=' + previewProperty);
                    savedFilters.push("Listing Type: "+previewPropertyDesc); 
                }
                if(noBuyerPremiumFilter!=''){
                    url.push('nobuyerpremium=' + noBuyerPremiumFilter);
                    savedFilters.push("Featured: "+noBuyerPremiumFilterDesc); 
                }
                if(reservePriceFilter!=''){
                    url.push('reservepricerevealed=' + reservePriceFilter);
                    savedFilters.push("Featured: "+reservePriceFilterDesc); 
                }
                if(selfShowingFilter!=''){
                    url.push('selfshowingenabled=' + selfShowingFilter);
                    savedFilters.push("Featured: "+selfShowingFilterDesc); 
                }
                if(brokerCoOpFilter!=''){
                    url.push('brokerCoOp=' + brokerCoOpFilter);
                    savedFilters.push("Featured: "+brokerCoOpFilterDesc); 
                }
                if(newToHubzuFilter!=''){
                    url.push('newToHubzu=' + newToHubzuFilter);
                    savedFilters.push("Property Status: "+newToHubzuFilterDesc); 
                }
                if(auctionEndingTodayFilter!=''){
                    url.push('auctionEndingToday=' + auctionEndingTodayFilter);
                    savedFilters.push("Property Status: "+auctionEndingTodayFilterDesc); 
                }
                if(cashOnlyFilter!=''){
                    url.push('cashOnly=' + cashOnlyFilter);
                    savedFilters.push("Featured: "+cashOnlyFilterDesc); 
                }
                
                if (latitude) {
                    url.push('latitude=' + latitude);
                }
                if (longitude) {
                    url.push('longitude=' + longitude);
                }

                if(rectSrchFlag!=''){
                    url.push('rect=true');
                }
                if(neLatLng!=''){
                    url.push('neLatLng=' + neLatLng);
                }
                if(swLatLng!=''){
                    url.push('swLatLng=' + swLatLng);
                }
                if(centerLatLng!=''){
                    url.push('centerPoint=' + centerLatLng);
                }
                if(zoomValue!=''){
                    url.push('zoomValue=' + zoomValue);
                }
                if(view!=''){
                    url.push('view=' + view); 
                }
                
                
                if(sortBy!='')
                {
                    url.push(sortBy);
                }
                if(savedUrl.baseSearchUrl!=null){
                    if(url.length > 0){
                        url = contextPath + '/' + savedUrl.baseSearchUrl + '?' + url.join('&');    
                    }else{
                        url = contextPath + '/' + savedUrl.baseSearchUrl;
                    }
                }
                else{
                    if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                        url = contextPath + '/auctions?' + url.join('&');
                    }else{
                        url = contextPath + '/searchResult?' + url.join('&');
                    }
                }
                
                
                var  data = {};
                data.url = url;
                data.savedFiltersGA = savedFilters.join(',');
                if(savedFilters.length > 1){
                    data.savedFilters = savedFilters[0] + "<span class='sep-search-fields'> | </span>+" + (parseInt(savedFilters.length) - 1) + " filters";
                } else if(data.savedFilters == 0){
                    data.savedFilters = savedFilters;
                } else { 
                    data.savedFilters = savedFilters.join("<span class='sep-search-fields'> | </span>");
                }    

                return data;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:formUrl|" + e, null, 1);
            }

        },

        formUrlNew: function(savedUrl,searchCriteria)
        {
            try{
                var url = [];
                var savedFilters = [];
                var minPrice = '';
                var maxPrice = '';
                var minYearBuilt = '';
                var maxYearBuilt = '';
                var minSqrFt = '';
                var maxSqrFt = '';
                var bathRoom = '';
                var bedRoom = '';
                var propType = '';
                var propStatus = '';
                var showmoreStatus = '';
                var occupyStatus = '';
                var category = '';
                var showMore = '';
                var ownItNow = '';
                var listingType = '';
                var sortBy = '';
                var propStatusDesc='';
                var propTypeDesc='';
                var listingTypeDesc='';
                var propertyShowMoreStatusDesc ='';
                var ownItNowDesc = ''; 
                var previewProperty ='';
                var previewPropertyDesc ='';
                var noBuyerPremiumFilter ='';
                var noBuyerPremiumFilterDesc ='';
                var reservePriceFilter ='';
                var reservePriceFilterDesc ='';
                var selfShowingFilter ='';
                var selfShowingFilterDesc ='';
                var brokerCoOpFilter ='';
                var brokerCoOpFilterDesc ='';
                var newToHubzuFilter='';
                var newToHubzuFilterDesc='';
                var auctionEndingTodayFilter='';
                var auctionEndingTodayFilterDesc='';
                var cashOnlyFilter='';
                 var cashOnlyFilterDesc='';
                var rectSrchFlag ='';
                var view ='';
                var neLatLng ='';
                var swLatLng ='';
                var centerLatLng ='';
                var zoomValue ='';
                var occupancyStatus='';
                var sid='';
                var sidDesc='';
                var latitude, longitude;
                if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                }else{
                    if(savedUrl.searchBy!=null)
                    {
                        url.push('searchBy=' + savedUrl.searchBy);
                    }
                    else
                    {
                        url.push('searchBy=All States');
                    }
                }
                _.each(searchCriteria , function(criteria){

                        switch(criteria.criteriaKey){
                            case "minPriceValue" :
                                    minPrice = criteria.criteriaValue;
                                    
                                break;
                            case "maxPriceValue" :
                                    maxPrice = criteria.criteriaValue;
                                    
                                break;
                            case "minYearBuilt" :
                                    minYearBuilt = criteria.criteriaValue;
                                    
                                break;
                            case "maxYearBuilt" :
                                    maxYearBuilt = criteria.criteriaValue;
                                  
                                break;
                            case "minPropertySize" :
                                    minSqrFt = criteria.criteriaValue;
                                break;
                            case "maxPropertySize" :
                                    maxSqrFt = criteria.criteriaValue;
                                break;
                            case "bath" :
                                    bathRoom = parseInt(criteria.criteriaValue);
                                break;
                            case "bed" :
                                    bedRoom = parseInt(criteria.criteriaValue);
                                break;
                            case "propertySubType":
                                    propType = criteria.criteriaValue;
                                    propTypeDesc = criteria.criteriaValueDescription;
                                break;
                            case "propertyStatus":
                                    propStatus = criteria.criteriaValue;
                                    propStatusDesc = criteria.criteriaValueDescription;
                                   
                                break;
                            case "propertyShowMoreStatus":
                                    showmoreStatus = criteria.criteriaValue;
                                    propertyShowMoreStatusDesc = criteria.criteriaValueDescription;
                                break;
                            
                            case "category":
                                    category = criteria.criteriaValue;
                                  
                                break;
                            case "ownItNow":
                                    ownItNow = criteria.criteriaValue;
                                    ownItNowDesc = criteria.criteriaValueDescription;
                                break;
                            case "listingType":
                                    listingType = criteria.criteriaValue;
                                    listingTypeDesc = criteria.criteriaValueDescription;
                                 
                                break;
                                case "sid":
                                    sid = criteria.criteriaValue;
                                    sidDesc = criteria.criteriaValueDescription;
                                break;
                            case "previewProperty": 
                                previewProperty = criteria.criteriaValue;
                                previewPropertyDesc = criteria.criteriaValueDescription;
                                break;
                                
                            case "noBuyerPremiumFilter": 
                                noBuyerPremiumFilter=criteria.criteriaValue;
                                noBuyerPremiumFilterDesc=criteria.criteriaValueDescription;
                                break;

                            case "reservepricerevealed": 
                                reservePriceFilter=criteria.criteriaValue;
                                reservePriceFilterDesc=criteria.criteriaValueDescription;
                                break;
                            
                            case "selfshowingenabled": 
                                selfShowingFilter=criteria.criteriaValue;
                                selfShowingFilterDesc=criteria.criteriaValueDescription;
                                break;

                            case "brokerCoOp": 
                                brokerCoOpFilter=criteria.criteriaValue;
                                brokerCoOpFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "newToHubzu": 
                                newToHubzuFilter=criteria.criteriaValue;
                                newToHubzuFilterDesc=criteria.criteriaValueDescription;
                                break;
                            
                            case "auctionEndingToday": 
                                auctionEndingTodayFilter=criteria.criteriaValue;
                                auctionEndingTodayFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "cashOnly": 
                                cashOnlyFilter=criteria.criteriaValue;
                                cashOnlyFilterDesc=criteria.criteriaValueDescription;
                                break;
                                
                            case "sortBy":
                                sortBy = criteria.criteriaValue;
                                break;
                            
                            case "rectSrchFlag": 
                                rectSrchFlag=criteria.criteriaValue;
                                break;
                            case "neLatLng": 
                                neLatLng=criteria.criteriaValue;
                                break;
                            case "swLatLng": 
                                swLatLng=criteria.criteriaValue;
                                break;
                            case "centerLatLng": 
                                centerLatLng=criteria.criteriaValue;
                                break;
                            case "zoomValue": 
                                zoomValue=criteria.criteriaValue;
                                break;
                            case "view": 
                                view=criteria.criteriaValue;
                                break;
                            case "occupancyStatus":
                                occupancyStatus = criteria.criteriaValue;
                                break;
                            case "latitude":
                                latitude = criteria.criteriaValue;
                                break;
                            case "longitude":
                                longitude = criteria.criteriaValue;
                                break;
                        }
                });

                if(category!=''){
                    var catTypes = category.split(',');
                    var enterREO = false;
                    _.each(catTypes , function(val){

                        switch(val)
                        {
                            case "SHRT_SALE": case "NON_REO":
                                url.push('category=' + val);
                                savedFilters.push("Short Sale"); 
                            break;
                            case "bankowned(occupied)": 
                                url.push('category=bankowned(occupied)');
                                savedFilters.push("Bank Owned (Occupied)");
                            break;
                            case "bankowned(nonoccupied)": 
                                url.push('category=bankowned(nonoccupied)');
                                savedFilters.push("Bank Owned (Non Occupied)");
                            break;
                            case "nonbankowned(occupied)": 
                                url.push('category=nonbankowned(occupied)');
                                savedFilters.push("Non-Bank Owned (Occupied)");
                            break;
                            case "nonbankowned(nonoccupied)": 
                                url.push('category=nonbankowned(nonoccupied)');
                                savedFilters.push("Non-Bank Owned (Non Occupied)");
                            break;
                            case "foreclosure":
                                url.push('category=foreclosure');
                                savedFilters.push("Foreclosure"); 
                            break;
                            case "npl":
                                url.push('category=npl');
                                savedFilters.push("Non Performing Loan"); 
                            break;
                            case "REO":
                                if(enterREO==false)
                                {
                                    enterREO = true;
                                    if(occupyStatus.indexOf('Y')>-1)
                                    {
                                        url.push('category=bankowned(occupied)');
                                        savedFilters.push("Bankowned(occupied)"); 
                                    }
                                    if(occupyStatus.indexOf('N')>-1)
                                    {
                                        url.push('category=bankowned(nonoccupied)');
                                        savedFilters.push("Bankowned(Non-occupied)"); 
                                    }
                                }
                            break;
                            case "bankowned":
                                url.push('category=bankowned');
                                savedFilters.push("Bank Owned"); 
                            break;
                            case "sid":
                                url.push('category=sid');
                                savedFilters.push("sid"); 
                            break;
                            case "nonbankowned":
                                url.push('category=nonbankowned');
                                savedFilters.push("Non-Bank Owned");

                            break;
                        }

                    });
                }

                if(propType!=''){
                    var proparr = propType.split(',');
                    var propTypeDescArr = propTypeDesc.split(',');
                     _.each(proparr , function(val){
                        url.push('propertySubType=' + val);
                         
                         
                    });
                     _.each(propTypeDescArr,function(valDesc){
                         savedFilters.push(valDesc); 
                     });
                }

                if(minPrice !== '' || maxPrice !== ''){
                    if(minPrice === '') {
                        url.push('priceRange=0-' + maxPrice);
                        savedFilters.push("$0 - $"+maxPrice);
                    } else if(maxPrice === '') {
                        url.push('priceRange=' + minPrice + '-1000000plus' );
                        savedFilters.push("$" + minPrice + "+"); 
                    } else {
                        url.push('priceRange=' + minPrice + '-' + maxPrice);
                        savedFilters.push("$" + minPrice + '-$' + maxPrice ); 
                    }
                }
                
                if(bathRoom!='')
                {
                    url.push('bath=' + bathRoom);
                    savedFilters.push(bathRoom + "+ Baths"); 
                }

                if(bedRoom!='')
                {
                    url.push('bed=' + bedRoom);
                    savedFilters.push(bedRoom + "+ Beds");
                }

                if(minSqrFt !== '' || maxSqrFt !== ''){
                    if(minSqrFt === '') {
                        url.push('squareFeetRange=0-' + maxSqrFt);
                        savedFilters.push("0 - "+maxSqrFt+"Sq Ft");
                    } else if(maxSqrFt === '') {
                        url.push('squareFeetRange=' + minSqrFt + '-10000plus' );
                        savedFilters.push(minSqrFt + "Sq Ft"); 
                    } else {
                        url.push('squareFeetRange=' + minSqrFt + '-' + maxSqrFt);
                        savedFilters.push( minSqrFt + 'Sq Ft-' + maxSqrFt+'Sq Ft' ); 
                    }
                }

                if(minYearBuilt !== '' || maxYearBuilt !== ''){
                    if(minYearBuilt === '') {
                        url.push('Year:yearBuiltRange=Before 1800-' + maxYearBuilt);
                        savedFilters.push("Year:0-"+maxYearBuilt);
                    } else if(maxYearBuilt === '') {
                        url.push('yearBuiltRange=' + minYearBuilt + '-After 2010' );
                        savedFilters.push("Year:" + minYearBuilt + "+"); 
                    } else {
                        url.push('yearBuiltRange=' + minYearBuilt + '-' + maxYearBuilt);
                        savedFilters.push("Year:" + minYearBuilt + '-' + maxYearBuilt ); 
                    }
                }

                if(propStatus!='')
                {
                    var proparr = propStatus.split(',');
                    var propTypeDescArr = propStatusDesc.split(',');
                     _.each(proparr , function(val){
                            url.push('propertyStatus=' + val);
                    });
                    _.each(propTypeDescArr , function(valDesc){
                        savedFilters.push(valDesc);
                    });
                }

                if(sid!='')
                {
                    var sidArr = sid.split(',');
                    var sidDescArr = sidDesc.split(',');
                     _.each(sidArr , function(val){
                            url.push('sid=' + val);
                    });
                    _.each(sidDescArr , function(valDesc){
                        savedFilters.push(valDesc);
                    });
                }

                    

                if(showmoreStatus!='')
                {
                     var proparr = showmoreStatus.split(',');
                     var propertyShowMoreStatusDescArr = propertyShowMoreStatusDesc.split(',');
                     _.each(proparr , function(val){
                        url.push('propertyShowMoreStatus=' + val);
                    });
                     _.each(propertyShowMoreStatusDescArr , function(valDesc){
                         savedFilters.push(valDesc); 
                    });
                }

                if(occupancyStatus !=''){
                    var occupancyStatusTypes = occupancyStatus.split(',');
                    _.each(occupancyStatusTypes , function(val){
                        switch(val){
                            case "Y":
                                url.push('occupancyStatus=Y');
                                savedFilters.push("Occupied"); 
                            break;
                            case "N":
                                url.push('occupancyStatus=N');
                                savedFilters.push("Non Occupied"); 

                            break;
                        }
                    });
                }
                if(ownItNow!='')
                {
                    url.push('listingType=AUCN');
                    url.push('ownItNow=Y');
                    if(url.indexOf('propertyStatus=ACTIVE')==-1)
                    {
                        url.push('propertyStatus=ACTIVE');

                    }
                    savedFilters.push("Auction"); 
                    savedFilters.push(ownItNowDesc); 
                }
                else if(listingType!='')
                {
                     var arr = listingType.split(',');
                     var listingTypeDescArr = listingTypeDesc.split(',');
                     _.each(arr , function(val){
                        url.push('listingType=' + val);
                    });
                     _.each(listingTypeDescArr , function(valDesc){
                        savedFilters.push(valDesc); 
                    });
                }
                if(previewProperty!=''){
                    url.push('marketingPhase=' + previewProperty);
                    savedFilters.push(previewPropertyDesc); 
                }
                if(noBuyerPremiumFilter!=''){
                    url.push('nobuyerpremium=' + noBuyerPremiumFilter);
                    savedFilters.push(noBuyerPremiumFilterDesc); 
                }
                if(reservePriceFilter!=''){
                    url.push('reservepricerevealed=' + reservePriceFilter);
                    savedFilters.push(reservePriceFilterDesc); 
                }
                if(selfShowingFilter!=''){
                    url.push('selfshowingenabled=' + selfShowingFilter);
                    savedFilters.push(selfShowingFilterDesc); 
                }
                if(brokerCoOpFilter!=''){
                    url.push('brokerCoOp=' + brokerCoOpFilter);
                    savedFilters.push(brokerCoOpFilterDesc); 
                }
                if(newToHubzuFilter!=''){
                    url.push('newToHubzu=' + newToHubzuFilter);
                    savedFilters.push(newToHubzuFilterDesc); 
                }
                if(auctionEndingTodayFilter!=''){
                    url.push('auctionEndingToday=' + auctionEndingTodayFilter);
                    savedFilters.push(auctionEndingTodayFilterDesc); 
                }
                if(cashOnlyFilter!=''){
                    url.push('cashOnly=' + cashOnlyFilter);
                    savedFilters.push(cashOnlyFilterDesc); 
                }
                
                if (latitude) {
                    url.push('latitude=' + latitude);
                }
                if (longitude) {
                    url.push('longitude=' + longitude);
                }

                if(rectSrchFlag!=''){
                    url.push('rect=true');
                }
                if(neLatLng!=''){
                    url.push('neLatLng=' + neLatLng);
                }
                if(swLatLng!=''){
                    url.push('swLatLng=' + swLatLng);
                }
                if(centerLatLng!=''){
                    url.push('centerPoint=' + centerLatLng);
                }
                if(zoomValue!=''){
                    url.push('zoomValue=' + zoomValue);
                }
                if(view!=''){
                    url.push('view=' + view); 
                }
                
                
                if(sortBy!='')
                {
                    url.push(sortBy);
                }
                if(savedUrl.baseSearchUrl!=null){
                    if(url.length > 0){
                        url = contextPath + '/' + savedUrl.baseSearchUrl + '?' + url.join('&');    
                    }else{
                        url = contextPath + '/' + savedUrl.baseSearchUrl;
                    }
                }
				else{
                    if(typeof isEnableNewSrp != 'undefined' && isEnableNewSrp == "Y"){
                        url = contextPath + '/auctions?' + url.join('&');
                    }else{
                        url = contextPath + '/searchResult?' + url.join('&');
                    }
                }
                var  data = {};
                data.url = url;
                data.savedFiltersGA = savedFilters.join(',');
                if(savedFilters.length > 3){
                    data.savedFilters = savedFilters[0] + ", " + savedFilters[1]+ ", " + savedFilters[2] + ", +" + (parseInt(savedFilters.length) - 3) + " More";
                }else{ 
                    data.savedFilters = savedFilters.join(", ");
                }    

                return data;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:formUrlNew|" + e, null, 1);
            }
        },

        deleteSaves: function(e){

            e.stopPropagation();
            var thisObj = this;
            
            var isWatchDel = false;
             if($(e.currentTarget).hasClass('deleteWatchProperty')){
                isWatchDel=true;
                var propertyId = $(e.currentTarget).attr('propid');
                var listingId = $(e.currentTarget).attr('listingId');
                var propertyStatus = $(e.currentTarget).attr('propStatus');
                var userId = window.userSession.userId || null;
                var eventLabel = 'visitorId : ' + $.cookie('visitorId') + ' | userId : ' + userId + ' | Property Id : ' + propertyId + ' | listingId : ' + listingId + ' | propertyStatus : ' + propertyStatus;
                GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Delete - Watch",eventLabel);
             }else{
                var searchId = $(e.currentTarget).parent().attr('data-currentSearchid');
                var eventLabel = $.cookie('visitorId') +'| Search Id : '+searchId +'|'+ $(e.currentTarget).parent().attr('data-searchParam');
                GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Delete - Save Search",eventLabel);
             }

            if(isWatchDel){
               
                thisObj.deleteWatchFromList(propertyId);
            }else{
                
                thisObj.deleteSaveFromList(searchId);
            }
                
        },
        deleteWatchFromList: function(propertyId){
            var thisObj = this;
            try{

                //this delete code needs to be optimized ...
                $('#watch'+propertyId).fadeOut();
                $("button[propRefId ='watch"+propertyId+"']").removeClass("Y").addClass('N').attr('iswatched','N').attr('notifyflag','');
                
                if(window.location.pathname.indexOf('/property/')>-1){
                    $('.previewPropertyWatch span').html($('#watchPropPreview').attr('savetxt'));
                    $('.previewPropertyWatch').removeClass('disabled');
                    $('.heart-icon-img').attr("src",  contextPath + "/www/images/pdpSaveIcon.png").attr('alt','Watch - Unsaved');
                    $('.header-icon-dark-img').attr('src',contextPath+'/www/images/headerHeartSelect.png').attr('isWatched','N');
                    $(".heartListBtn").attr('isWatched','N');
                    $('.watch-icon').attr('isWatched','N');
                }
                thisObj.watchShowMoreClickedFlag = false;
                thisObj.watchMoreClicked =false;
                var deleteSaveUrl = contextPath + "/portal/drawer/deleteSavedProperty";
               // $('#savedWatchLoadingIcon').removeClass("hide");
               var propertyDetailsJson= {'propertyId':propertyId,'emailId':this.emailId};
              /* var propDetails={};
               propDetails.propertyId=propertyDetailsJson;
               propDetails.emailId=this.emailId;*/
               $.ajax({
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' 
                },
                'type': 'POST',
                url: deleteSaveUrl,
                dataType: "json",
                data: JSON.stringify(propertyDetailsJson),
                contentType: "application/json; charset=utf-8"
            }).done(function(data) {
                var response = data;
                if(response.response && response.response == "SUCCESS") {
                        var eventLabel = $.cookie('visitorId') +'| Property Id : '+propertyId ;
                        GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Delete - Watch Success",eventLabel);
                       $('#watch'+propertyId).fadeOut();
                       $('#watch'+propertyId).remove();
                      if(thisObj.watchOffSet == -1){
                            thisObj.watchOffSet = -1;
                            //thisObj.getWatchList();

                        }
                        if ($('.propDetailsDrawer').length<=0){
                           thisObj.watchOffSet = -1;
                        }
                        else if($('.propDetailsDrawer').length <= parseInt(defaultWatchNum)+1){
                            $('#watchedPropertyShowMoreDiv').addClass('hide');
                        } 
                       thisObj.getWatchList();
                       thisObj.loadCount();
                       if((window.location.hash=='#watchProperties' || window.location.hash=='#watchProperty') && window.location.href.indexOf('my-shopper')>-1){
                            $('#'+propertyId).fadeOut();
                       }
                       if($("body").hasClass(".h-srp-page")){
                            Utils.highlightSavedProperties();
                       }
                        // Update map icons with saved properties. 
                        if (window.htimestamp && window.htimestamp.mapView) {
                            window.htimestamp.mapView.updateWatchIcon({propertyId: propertyId, isSaved: false});
                            window.htimestamp.mapView.getUserSavedProperties({});
                        }

                        if ( window.htimestamp && window.htimestamp.outbid ) {
                            window.htimestamp.outbid.removeProperty({
                                propertyId: propertyId
                            })
                        }
                    }  
            });
                        
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:deleteWatchFromList|" + e, null, 1);
            } 
        },
        
        deleteSaveFromList: function(searchId){
            try{
                var thisObj = this;
                $('#'+searchId).fadeOut().remove();
                var searchParamGa =$('#saveSearch'+searchId).attr('searchParam');
               
                $('#saveSearch'+searchId).fadeOut().remove();
                $('.saveSearchHeartButton').removeClass('savedSearchHeart').addClass('saveSearchHeartIcon').html(saveSearchText);
               
                
                var deleteSaveUrl = contextPath + "/portal/drawer/deleteUserSavedSearch";

                var propertyDetailsJson= {'searchId':searchId,'emailId':this.emailId};
                $.ajax({
                    headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json' 
                    },
                    type: 'POST',
                    url: deleteSaveUrl,
                    dataType: "json",

                    data: JSON.stringify(propertyDetailsJson),

                    contentType: "application/json; charset=utf-8"
                }).done(function(data) {
                        var eventLabel = $.cookie('visitorId') +'| Search Id : '+searchId +'|'+ searchParamGa;
                        GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Delete - Save Search Success",eventLabel);
                        $('#saveSearch'+searchId).fadeOut();
                        thisObj.loadCount();
                        thisObj.getSavedSearchesData();
                         if(window.location.hash=='#savedSearches' && window.location.href.indexOf('my-shopper')>-1){
                            $('#'+searchId).fadeOut();
                        }
                         $('.notifySuccessMessage').addClass("hide");
                         $('#notifyMeSection').removeClass("hide");

                    // For new SRP filters
                    if($(".save-search-icon").length && $(".save-search-icon").attr('save-search-id') == searchId) {
                        if(isEnableNewSrp && isEnableNewSrp == 'Y'){
                            $('.save-search-icon').find(".inner-outer-layer").css({"fill": "#ADC4DA"});
                            $('.save-search-icon').removeClass('search-results-saved').animate(200, function(){
                                $(this).find(".ss-title").text('Save Search');
                            });
                        } else {
                            $('.save-search-icon').removeClass('search-results-saved').animate({"width":"107"}, 200, function(){
                                $(this).find(".ss-title").text('Save Search');
                            });
                        }
                    }
                        
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:deleteSaveFromList|" + e, null, 1);
            } 
        },

        getWatchList:function(){

            var thisObj = this,
                data = {};

            if(this.watchOffSet<0){
                
                data = {'emailId':this.emailId, 'pageNumber':0, 'pageSize':saveListDrawerContent.GlobalConfig.recordsPerPageWatchDrawer};
            } else {
                 data = {'emailId':this.emailId, 'pageNumber':this.watchOffSet, 'pageSize':this.watchPageSize};
                $('#watchedPropertyShowMoreDiv').addClass('hide');
            }
            $('#savedWatchLoadingIcon').removeClass("hide");
            $('#unWatchedDiv, .favourite-default').addClass('hide');
            
            $.ajax({
                url: contextPath + '/portal/drawer/getUserSavedProperties',
                type: 'POST',
                data: JSON.stringify(data),
                dataType: "json",
                contentType: "application/json; charset=utf-8"
            }).done(function(response) {
                thisObj.requestPending = false;
                thisObj.hideLoader();
                thisObj.formatWatchList(response);
            }).fail(function(e) {                    
                // $.error('ajax error', e);
                thisObj.requestPending = false;
                thisObj.hideLoader();
            });

        },
        initiateFirebaseListeners: function(options) {

            try {
                options = options || {};
                var properties = options.watchList || [],
                    self = options.viewObject || this,
                    alertView = window.globalAlertView || null,
                    listingNodeReference, databaseUrl, index, listingId, listingReference, listingType, isBulkProperty, isFcProxyBidEnabled;
                
                // Check if Alerts view is available or not
                if (alertView) {
              
                    // Initialize firebase app 
                    if(!alertView.firebaseApp)
                        alertView.initFirebase();

                    for (index = 0; index < properties.length; index++) {
                        
                        listingId = properties[index].propertyListingDetails.listingId || null;
                        listingType = properties[index].propertyListingDetails.listingType || null;
                        isBulkProperty = (properties[index].bulkPropertyInfo && properties[index].bulkPropertyInfo.bulkPropertyFlag === 'Y') ? true : false;
                        isFcProxyBidEnabled = properties[index].fcProxyBidEnabled || false;
                        // If listener is already initiated on specific listing id, then skip.  
                        // if(self.listingIds.indexOf(listingId) > -1) continue;

                        // Forclosure properties doesn't have listing details, skip it
                        // Attach listeners only for AUCN ptopreties
                        // Don't attach firebase for bulk child properties 
                        if(!listingId || !listingType || listingType !== 'AUCN' || isBulkProperty || isFcProxyBidEnabled) continue;

                        databaseUrl = 'listings/' + listingId;

                        if(alertView.tenantId) { 
                            databaseUrl = alertView.tenantId + "/" + databaseUrl;
                        }
                        self.listingIds.push(listingId);

                        listingNodeReference = alertView.firebaseApp.database().ref(databaseUrl);
                        listingNodeReference.on('value', function (snapshot) {

                            // NOTE: Event listener to udpate property
                            self.updatePropertyDetails({
                                snapshot: snapshot,
                                viewObject: self,
                                alertViewObject: alertView
                            });
                            
                        }, function (error) {
                            alertView.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'value'
                            });
                        });

                        /* 
                        - Event will be triggered when listing got delete 
                        - When listing got removed, delete listing tuple in favorites drawer 
                        - In order to get Listing id, we have attached child_removed
                        */
                        listingNodeReference.on('child_removed', function (snapshot) {
                            self.removeProperty({
                                listingId: snapshot.val().id,
                                viewObject: self,
                                alertViewObject: alertView
                            });
                            
                        }, function (error) {
                            alertView.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'child_removed'
                            });
                        });
                    }
                }


            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:initiateFirebaseListeners|" + e, null, 1);
            }

        },
        removeProperty: function(options) {
            try{
                options = options || {};
                var listingId = options.listingId || null,
                    self = options.viewObject || this,
                    propertyId, 
                    elementContainer;

                elementContainer = $('.favorites-drawer-container #favouritesTab .' + listingId);
                propertyId = elementContainer.attr('data-property-id') || null;

                if(propertyId && listingId) {

                    $.ajax({
                        url: (contextPath.indexOf('portal') > -1) ? (contextPath + '/isValidSavedProperty/' + propertyId) : (contextPath + '/portal/isValidSavedProperty/' + propertyId),
                        type:'GET',
                        success: function(response) { 

                            response = response || {};
                            var isValidSaveSearch = response.data || null;

                            if(response.status === 'SUCCESS'){
                                
                                if(isValidSaveSearch) {

                                    // In case WLST, remove CTAs
                                    elementContainer.find('.bids-placed-count').html("");
                                    elementContainer.find('.favorite-cta').addClass('hide');
                                    elementContainer.find('.current-bid-value').text("");
                                    elementContainer.find('.current-bid-label').text("");
                                    elementContainer.find('.auction-timer').html("");

                                    window.globalAlertView.removeTimerObject({
                                        viewObject: self,
                                        listingIds: [listingId]
                                    });
                                } else {
                                    // In case of Move to off market, sold, then remove tuple 
                                    elementContainer.remove();
                                }
                            } 
                        },
                        error: function(xmlhttprequest, textstatus, message) {
                            errorMessage = (textstatus === "timeout") ? "Network timeout" : message;
                            GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:removeProperty|AJAX error - " + errorMessage, null, 1);
                        }
                    });
                } else {
                    GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:removeProperty|Inconsistant data issue - listingId/propertyId is not available", null, 1);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:removeProperty|" + e, null, 1);
            }
        },
        getListingStatusCTA: function(options, callback){
            try {
                options = options || {};
                var status = options.status || null, 
                    listingId = options.listingId || null,
                    ctaText, isBestBid = false, isConfirmOffer = false;

                if( !listingId || !status ) {
                    callback({
                        callbackOptions: options,
                        ctaText: "",
                        status: 'success'
                    }); 
                    return;
                }

                if(status === "ACTIVE") {
                    callback({
                        callbackOptions: options,
                        ctaText: "Place Bid",
                        status: 'success'
                    }); 
                } else {

                    // check if BESTBID is there for listing
                    $.ajax({
                        url: (contextPath.indexOf('portal') > -1) ? contextPath + '/isBestBidAvailable' : contextPath + '/portal/isBestBidAvailable',
                        type:'GET',
                        data: {
                            listingId: listingId
                        },
                        success: function(response) { 

                            response = response || {};
                            //response = {"isBestBidAvailable":"N","isSellerFinalNegotiationAvailable":"Y","isSellerFinalNegotiationAcceptedAlready":"N","validationStatus":null};

                            //TODO:  Logic to find out CTA
                            if(response.isBestBidAvailable == 'Y') {        
                                ctaText = "Place Best Bid";
                                isBestBid = true;
                            } else if(response.isSellerFinalNegotiationAvailable === 'Y' && response.isSellerFinalNegotiationAcceptedAlready !== 'Y' ){
                                ctaText = saveListDrawerContent.saveListDrawer.labels.confirmOfferLabel; 
                                isConfirmOffer = true;
                            }else {
                                ctaText = "Place Backup Bid";
                            }

                            callback({
                                callbackOptions: options,
                                ctaText: ctaText,
                                isBestBid: isBestBid,
                                isConfirmOffer: isConfirmOffer,
                                status: 'success'
                            }); 
                        },
                        error: function(xmlhttprequest, textstatus, message) {
                            errorMessage = (textstatus === "timeout") ? "Network timeout" : message;
                            if(callback) { 
                                callback({
                                    callbackOptions: options,
                                    status: 'error',
                                    errorMessage: errorMessage
                                }); 
                            }
                            GAUtils.eventTracking.trackevent("Error","saveListDrawerView","file:saveListDrawerView.js|function:getListingStatusCTA|AJAX error - " + errorMessage, null, 1);
                        }
                    });

                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView","file:saveListDrawerView.js|function:getListingStatusCTA|" + e, null, 1);
            }
        },

        updatePropertyDetails: function(options) {
            try {

                options = options || {};

                var snapshot = options.snapshot || null,
                    self = options.viewObject || this;

                if(!snapshot && !snapshot.val()) {
                    return false;    
                }
                
                var auctionDetails = snapshot && snapshot.child('auctionDetails') || null,
                    numberOfBids = auctionDetails && auctionDetails.child('numberOfBids').val() || null,
                    highestBidAmount = auctionDetails && auctionDetails.child('highestBidAmount').val() || null,
                    bidIncrement = auctionDetails && auctionDetails.child('bidIncrement').val() || null,
                    listingStatus = auctionDetails && auctionDetails.child('listingStatus').val() || null,
                    auctionTimeLeft = auctionDetails && Number(auctionDetails.child('auctionTimeLeft').val()) || 0,
                    listingId = auctionDetails && auctionDetails.child('id').val() || null,
                    alertViewObject = options.alertViewObject || null,
                    targetElement, ctaText, dataValue = '', bidsText = '';

                if(listingId) {
                    targetElement = $('.favorites-drawer-container #favouritesTab .' + listingId);

                    // Update number of bids
                    if(numberOfBids && listingStatus && listingStatus === 'ACTIVE') {
                        numberOfBids = Number(numberOfBids) || '';
                        if(numberOfBids) {
                            bidsText = (numberOfBids > 1) ?  numberOfBids + ' bids placed' : numberOfBids + ' bid placed';
                        }
                        $(targetElement).find('.bids-placed-count').html(bidsText);
                    } else {
                        $(targetElement).find('.bids-placed-count').html("");
                    }
                    
                    // Update CTA
                    if(listingStatus) {
                        self.getListingStatusCTA({status: listingStatus, listingId: listingId}, function(ctaResponse){

                            ctaResponse = ctaResponse || {};
                            ctaText = ctaResponse.ctaText || "";

                            if(ctaText) {

                                if($('.favorites-drawer-container #favouritesTab .' + listingId + ' .favorite-cta').length === 0){
                                    $('.favorites-drawer-container #favouritesTab .' + listingId +' .favorite-cta-container').append('<button aira-label="Place Bid" class="favorite-cta" listingId=""></button>');
                                }

                                $(targetElement).find('.favorite-cta').text(ctaText);
                                $(targetElement).find('.favorite-cta').attr('listingId', listingId);
                                
                                if(ctaResponse && ctaResponse.isBestBid) {
                                    // Best bid functionality
                                    $(targetElement).find('.favorite-cta').removeClass('hide placeBidButton').addClass('placeBestBidButton');
                                } else if (ctaResponse.isConfirmOffer){
                                    // Confirm offer negotiation functionality
                                    $(targetElement).find('.favorite-cta').removeClass('hide placeBidButton').addClass('confirmOfferNegotiation');
                                }else {
                                    $(targetElement).find('.favorite-cta').removeClass('hide placeBestBidButton').addClass('placeBidButton');
                                }

                                if(listingStatus === 'ACTIVE') {
                                    dataValue = 'placeBidButton';
                                } else if (listingStatus === 'SPND' || listingStatus === 'REVIEW') {
                                    dataValue = 'placeBackUpBid';
                                }
                                $(targetElement).find('.favorite-cta').attr('data-val', dataValue);
                                
                            } else {
                                // If property moved to OFF market
                                $(targetElement).find('.favorite-cta').addClass('hide');
                            }
                        });
                    }

                    // Update current heighest bid amount
                    if(listingStatus && listingStatus === 'ACTIVE') {

                        highestBidAmount = Number(highestBidAmount);

                        if(highestBidAmount > 0) {
                            $(targetElement).find('.current-bid-value').text( "$" + highestBidAmount.formatMoney());
                            $(targetElement).find('.current-bid-value').attr('data-bid-increment', (bidIncrement || 0));
                            $(targetElement).find('.current-bid-label').text( (( (numberOfBids || 0 ) > 0) ? "Current Highest Bid" : "Starting Bid" ));
                        }

                    } else {
                        $(targetElement).find('.current-bid-value').text("");
                        $(targetElement).find('.current-bid-label').text("");
                    }


                    if(listingStatus && listingStatus !== 'ACTIVE') {
                        // listingId
                        alertViewObject.removeTimerObject({
                            viewObject: self,
                            listingIds: [listingId]
                        });
                        $('.favorites-drawer-container #favouritesTab .' + listingId).find('.auction-timer').html("");
                    }

                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:updatePropertyDetails|" + e, null, 1);
            }
        },
        initateListingTimer: function(options) {
            try{
                options = options || {};
                var properties = options.watchList || [],
                    self = options.viewObject || this,
                    alertView = window.globalAlertView || null,
                    listingTimeLeftNodeReference, databaseUrl, index, listingId, auctionTimeLeft, listingStatus, listingType, isFcProxyBidEnabled;
                
                // Check if Alerts view is available or not
                if (alertView) {
              
                    // Initialize firebase app 
                    if(!alertView.firebaseApp)
                        alertView.initFirebase();

                    for (index = 0; index < properties.length; index++) {
                        

                        listingStatus = properties[index].propertyListingDetails.listingStatus;
                        listingType = properties[index].propertyListingDetails.listingType || null;
                        listingId = properties[index].propertyListingDetails.listingId;
                        auctionTimeLeft = properties[index].propertyListingDetails.auctionTimeLeft || 0;
                        isFcProxyBidEnabled = properties[index].fcProxyBidEnabled || false;

                        // Forclosure properties doesn't have listing details, skip it
                        // If listing status is not active, then skip execution
                        if(!listingId || listingStatus !== 'Active' || listingType !== 'AUCN'){
                            if(!(newSkinning && listingStatus == 'Active' && listingType == 'TRNL' && auctionTimeLeft > 0))
                            continue;
                        }

                        if (isFcProxyBidEnabled) {
                            $('.favorites-drawer-container #favouritesTab .' + listingId).find('.timer-wrapper').hide();
                            continue;
                        }

                        databaseUrl = 'listings/' + listingId + "/auctionDetails/auctionTimeLeft";

                        if(alertView.tenantId) {
                            databaseUrl = alertView.tenantId + "/" + databaseUrl;
                        }

                        listingTimeLeftNodeReference = alertView.firebaseApp.database().ref(databaseUrl);
                        listingTimeLeftNodeReference.on('value', function (snapshot) {

                             // TODO: Event listener Timer
                            self.getUpdatedTimeLeft({
                                snapshot: snapshot,
                                viewObject: self,
                                alertViewObject: alertView
                            });
                            
                        }, function (error) {
                            alertView.notificationError({
                                error: error,
                                viewObject: self,
                                databaseUrl: databaseUrl,
                                event: 'value'
                            });
                        });

                        if (auctionTimeLeft && auctionTimeLeft > 0) {

                            // Remove timer object if its already initiated on 'listingId'
                            alertView.removeTimerObject({
                                viewObject: self,
                                listingIds: [listingId]
                            });
        
                            self.initiateTimer({
                                timeLeft: auctionTimeLeft,
                                listingId: listingId,
                                viewObject: self,
                                alertViewObject: alertView
                            });
                        }
                    }
                


                    /* Docu Sign Firebase URL Config */
                    // if(pageName == "buyerDashboard" || pageName == "pdp"){
                    //     var userID = (typeof userSession !== 'undefined') ? (userSession && userSession.userId || '' ): '';
                    //     var docuSignDatabaseUrl = 'userDetails/' + $.cookie('userId') + "/userDetailMessage";
                    //     if(alertView.tenantId) { 
                    //         docuSignDatabaseUrl = alertView.tenantId + "/" + docuSignDatabaseUrl;
                    //     }
                        
                    //     var listingNodeReference = alertView.firebaseApp.database().ref(docuSignDatabaseUrl);
                    //     listingNodeReference.on('value', function (snapshot) {
                    //         if(snapshot !=null && snapshot.val()!=null && snapshot.val().fcDocuSignFlag && snapshot.val().fcDocuSignFlag =="Y"){
                    //             $(".docuSignNotVerified").addClass("hide");
                    //             $(".docuSignVerified").removeClass("hide");
                    //         }else{
                    //             $(".docuSignNotVerified").removeClass("hide");
                    //             $(".docuSignVerified").addClass("hide");
                    //         }
                    //     }, function (error) {
                    //           alertView.notificationError({
                    //             error: error,
                    //             databaseUrl: docuSignDatabaseUrl,
                    //             event: 'value'
                    //           });
                    //     });
                    // }
                }


            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:initateListingTimer|" + e, null, 1);
            }
        },
        getUpdatedTimeLeft: function(options) {
            try {
                options = options || {};
                var self = options.viewObject || this,
                    snapshot = options.snapshot || null,
                    alertView = options.alertViewObject,
                    listingId, timeLeft;

                if(!snapshot) return;

                listingId = snapshot.ref.parent.parent.key;

                // Get time left from databse
                $.ajax({
                    url: (contextPath.indexOf('portal') > -1) ? contextPath + '/auctionTimeLeft' : contextPath + '/portal/auctionTimeLeft',
                    dataType: "json",
                    type:'POST',
                    data:JSON.stringify([listingId]),
                    contentType: "application/json; charset=utf-8",
                    success: function(response) { 

                        response = response || {};

                        if(response && response.status === "SUCCESS") {
                            timeLeft = response.data[listingId] || null;
                            timeLeft = Number(timeLeft) || 0;

                            if (timeLeft > 0 ) {

                                // Remove timer object if its already initiated on 'listingId'
                                alertView.removeTimerObject({
                                    viewObject: self,
                                    listingIds: [listingId]
                                });
            
                                self.initiateTimer({
                                    timeLeft: timeLeft,
                                    listingId: listingId,
                                    viewObject: self,
                                    alertViewObject: alertView
                                });
                            }
                        }
                    },
                    error: function(xmlhttprequest, textstatus, message) {
                        errorMessage = (textstatus === "timeout") ? "Network timeout" : message;
                        
                        // TODO: Log GA event 
                        GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:getUpdatedTimeLeft|AJAX error - " + errorMessage, null, 1);
                    }
                });

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:getUpdatedTimeLeft|" + e, null, 1);
            }
        },
        initiateTimer: function(options){
            
            try {
                options = options || {};
                var timeLeft = options.timeLeft || 0,
                    listingId = options.listingId || null,
                    alertViewObject = options.alertViewObject || null,
                    self = options.viewObject || this,
                    timer,
                    calculatedTimeLeft;

                if(!alertViewObject || !listingId || timeLeft <= 0 ) return;

                timer = setInterval(function(){

                    timeLeft = timeLeft - 1000;
                    if(newSkinning){
                        calculatedTimeLeft = self.calculateTimeLeft({ millseconds: timeLeft });   
                    }else{
                        calculatedTimeLeft = alertViewObject.calculateTimeLeft({ millseconds: timeLeft });
                    }
                                     
                    $('.favorites-drawer-container #favouritesTab .' + listingId).find('.auction-timer').html(calculatedTimeLeft || '');
                    
                    // Remove setInterval if time left becomes null
                    if(!calculatedTimeLeft) clearInterval(timer);

                }, 1000);

                self.timerObjects.push({
                    listingId: listingId,
                    timerObject: timer
                });

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:initiateTimer|" + e, null, 1);
            }
        },
        calculateTimeLeft: function (options) {

            try {
                options = options || {};
                var millseconds = options.millseconds || 0,
                    sendIndividualUnits = options.sendIndividualUnits || null,
                    sendAllUnits = options.sendAllUnits || null,
                    seconds,
                    minutes,
                    hours,
                    days;

                millseconds = millseconds / 1000;

                seconds = Math.floor((millseconds) % 60);
                minutes = Math.floor((millseconds / 60) % 60);
                hours = Math.floor((millseconds / ( 60 * 60)) % 24);
                days = Math.floor(millseconds / ( 60 * 60 * 24));

                var arr = []
                if (days >= 0) {
                    arr.push(days + 'd');
                }
                if(hours >= 0) {
                    arr.push(hours + 'h');
                } 
                if(minutes >= 0) {
                    arr.push(minutes + 'm');
                }

                if(arr.length === 0){
                    return null;
                } else {
                    return arr.join(" ");
                }         

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:calculateTimeLeft|" + e, null, 1);
            }
        },
        openBestBidPopup : function(e){
            try {

                var self = this,
                    element = $(e.target),
                    pdpUrl = element.parent('.favorite-cta-container').attr('data-url'),
                    listingId = element.parent('.favorite-cta-container').attr('listingId'),
                    userId = window.userSession.userId || null,
                    trackText = userId + '|' + listingId;
                    GAUtils.eventTracking.trackevent(self.currPageSave,"Place Best Bid Click", trackText);

                    window.location = pdpUrl + "?openBstBid";

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:openBestBidPopup|" + e, null, 1);
            }
            
        },
        offerNegotiation: function(e) {
            try {

                var self = this,
                    element = $(e.target),
                    pdpUrl = element.parent('.favorite-cta-container').attr('data-url'),
                    listingId = element.parent('.favorite-cta-container').attr('listingId'),
                    userId = window.userSession.userId || null,
                    trackText = userId + '|' + listingId;
                    GAUtils.eventTracking.trackevent(self.currPageSave,"Confirm Offer Negotiation Click", trackText);

                    window.location = pdpUrl + "?openConfirmOffer";

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:offerNegotiation|" + e, null, 1);
            }
        },
        formatWatchList : function(response){
            var thisObj = this;
            if(response && response.responseStatus && response.responseStatus == "SUCCESS") {

                $('#unWatchedDiv, .favourite-default').addClass('hide');
                $('#watchedDiv, #watchedDivMain, .favourite-default').addClass('hide'); 
                 $(response.propertyWatchResponseList).each(function(index,propertyDetails){ 

                        response.propertyWatchResponseList[index].displayPrice = '';
                        response.propertyWatchResponseList[index].displayLabel = '';
                        response.propertyWatchResponseList[index].isUnderContract = false;
                        
                        
                        if(response.propertyWatchResponseList[index].propertyDetails 
                            && response.propertyWatchResponseList[index].propertyDetails.commonAttributes 
                            && response.propertyWatchResponseList[index].propertyDetails.commonAttributes.propCategory
                            && response.propertyWatchResponseList[index].propertyDetails.commonAttributes.propCategory == 'NPL' 
                            && response.propertyWatchResponseList[index].propertyListingDetails.listPrice 
                            && response.propertyWatchResponseList[index].propertyListingDetails.listPrice != 0){
                            response.propertyWatchResponseList[index].displayPrice=thisObj.formatCurrencyLabel(response.propertyWatchResponseList[index].propertyListingDetails.listPrice);
                            response.propertyWatchResponseList[index].displayLabel = "List Price";
                        } else if ( response.propertyWatchResponseList[index].fcProxyBidEnabled ) {
                            response.propertyWatchResponseList[index].displayLabel = 'Est Opening Bid';
                            var amount = Number(response.propertyWatchResponseList[index].proxyBidEstimatedOpeningAmt || '');
                            amount = amount ? amount.formatMoney() : amount;
                            response.propertyWatchResponseList[index].displayPrice = amount ? '$' + amount : "TBD";
                        } else if(response.propertyWatchResponseList[index].propertyListingDetails.listingStatus!== null && (response.propertyWatchResponseList[index].propertyListingDetails.listingStatus).toUpperCase() !== 'CLOSED' ) {
                            if(response.propertyWatchResponseList[index].isForeclosureAsset ){
                                if(response.propertyWatchResponseList[index].propertyDetails.detailedAttributes.estimatedOpeningBid!=0){
                                    response.propertyWatchResponseList[index].displayPrice=thisObj.formatCurrencyLabel(response.propertyWatchResponseList[index].propertyDetails.detailedAttributes.estimatedOpeningBid);
                                    response.propertyWatchResponseList[index].displayLabel = "Est Opening Bid";
                                }else{
                                    response.propertyWatchResponseList[index].displayPrice=saveListDrawerContent.saveListDrawer.labels.defaultAmtFC;
                                    response.propertyWatchResponseList[index].displayLabel = "Est Opening Bid";
                                }
                            }else{
                               
                                if(response.propertyWatchResponseList[index].propertyListingDetails!=null) {
                                    response.propertyWatchResponseList[index].propertyListingDetails.ownItNowPrice = (response.propertyWatchResponseList[index].propertyListingDetails.ownItNowFlag) ? thisObj.formatCurrencyLabel(response.propertyWatchResponseList[index].propertyListingDetails.ownItNowPrice) : null;
                                    if(response.propertyWatchResponseList[index].propertyListingDetails.currentHighestPrice!=null && response.propertyWatchResponseList[index].propertyListingDetails.currentHighestPrice!=0){
                                        response.propertyWatchResponseList[index].displayPrice=thisObj.formatCurrencyLabel(response.propertyWatchResponseList[index].propertyListingDetails.currentHighestPrice);
                                        if(response.propertyWatchResponseList[index].propertyListingDetails.listingType == 'AUCN') {
                                            response.propertyWatchResponseList[index].displayLabel = "Current Highest Bid";
                                        } else {
                                            response.propertyWatchResponseList[index].displayLabel = "Current Highest Offer";
                                        }
                                    } else if(response.propertyWatchResponseList[index].propertyListingDetails.startingPrice 
                                            && response.propertyWatchResponseList[index].propertyListingDetails.startingPrice != 0 
                                            && response.propertyWatchResponseList[index].propertyListingDetails.listingType == 'AUCN'){
                                        response.propertyWatchResponseList[index].displayPrice=thisObj.formatCurrencyLabel(response.propertyWatchResponseList[index].propertyListingDetails.startingPrice);
                                        response.propertyWatchResponseList[index].displayLabel = "Starting Bid";
                                    } else if(response.propertyWatchResponseList[index].propertyListingDetails.listPrice 
                                            && response.propertyWatchResponseList[index].propertyListingDetails.listPrice != 0 
                                            && response.propertyWatchResponseList[index].propertyListingDetails.listingType == 'TRNL' ){
                                        response.propertyWatchResponseList[index].displayPrice=thisObj.formatCurrencyLabel(response.propertyWatchResponseList[index].propertyListingDetails.listPrice);
                                        response.propertyWatchResponseList[index].displayLabel = "List Price";
                                    }
                                }
                            }
                        }else{
                            if(response.propertyWatchResponseList[index].propertyListingDetails.listingType == 'AUCN' && response.propertyWatchResponseList[index].propertyListingDetails.startingPrice == null && response.propertyWatchResponseList[index].propertyListingDetails.listPrice == null) { 
                                response.propertyWatchResponseList[index].isUnderContract = true;
                                response.propertyWatchResponseList[index].underContractLabel = "Under Contract";
                            }  
                        } 

                        var timeLeftJson = {};
                        var showAuctionEndingInfo = false;
                        var showTimeLeft=false;
                        if(response.propertyWatchResponseList[index].isForeclosureAsset && response.propertyWatchResponseList[index].event !=null){
                            timeLeftJson = response.propertyWatchResponseList[index].event.auctionEndingInfo;
                            if(response.propertyWatchResponseList[index].event.showTimeLeftForAuction && response.propertyWatchResponseList[index].event.showAuctionEndingInfo){
                                showAuctionEndingInfo = true;
                                showTimeLeft=true;
                            }else if(response.propertyWatchResponseList[index].event.showTimeLeftForAuction){
                                 showAuctionEndingInfo = false;
                                 showTimeLeft=true;
                            }else{
                                showAuctionEndingInfo = false;
                                 showTimeLeft=false;
                            }
                        }
                        else if(response.propertyWatchResponseList[index].propertyListingDetails !=null && response.propertyWatchResponseList[index].propertyListingDetails.listingEndingInfo!=null && response.propertyWatchResponseList[index].propertyListingDetails.listingEndingInfo!=undefined ){
                                timeLeftJson = response.propertyWatchResponseList[index].propertyListingDetails.listingEndingInfo;
                        }
                        var timeLeft ='';
                        var timeLeftContent = saveListDrawerContent.saveListDrawer.labels.timeLeftContent;
                        response.propertyWatchResponseList[index].fontColorStyle = '';
                        if(timeLeftJson!=null){
                            if(showAuctionEndingInfo && showTimeLeft){
                                timeLeft=timeLeftJson.days +timeLeftContent.days+' ';
                                timeLeft+=timeLeftJson.hrs+timeLeftContent.hours +' ';
                                timeLeft+=timeLeftJson.mins +timeLeftContent.minutes+' ';
                                
                            }else if (showTimeLeft) {
                                timeLeft = timeLeftJson.days +' ' + timeLeftContent.daysLeftText;
                                response.propertyWatchResponseList[index].fontColorStyle =  'greenText';
                            }
                            response.propertyWatchResponseList[index].dispTimeLeft =  timeLeft;
                            
                        }
                        if(response.propertyWatchResponseList[index].propertyDetails.imageDetails[0].imageStoragePath =='' || response.propertyWatchResponseList[index].propertyDetails.imageDetails[0].imageStoragePath == null || response.propertyWatchResponseList[index].propertyDetails.imageDetails[0].imageStoragePath == undefined){
                            response.propertyWatchResponseList[index].propertyDetails.imageDetails[0].imageStoragePath = '/www/images/no-photo.jpg';
                        }    
                        if(response.propertyWatchResponseList[index].propertyDetails.commonAttributes.bedroomsNum !='' && response.propertyWatchResponseList[index].propertyDetails.commonAttributes.bedroomsNum == 1 ){  response.propertyWatchResponseList[index].propertyDetails.commonAttributes.bedroomTxt = 'Bed'; 
                        }   else{
                            response.propertyWatchResponseList[index].propertyDetails.commonAttributes.bedroomTxt = 'Beds'; 
                        }
                        if(response.propertyWatchResponseList[index].propertyDetails.commonAttributes.bathsNum !='' && response.propertyWatchResponseList[index].propertyDetails.commonAttributes.bathsNum == 1 ){  response.propertyWatchResponseList[index].propertyDetails.commonAttributes.bathsTxt = 'Bath'; 
                        }   else{
                            response.propertyWatchResponseList[index].propertyDetails.commonAttributes.bathsTxt = 'Baths'; 
                        }


                        if(response.propertyWatchResponseList[index].propertyDetails.detailedAttributes != "undefined" && response.propertyWatchResponseList[index].propertyDetails.detailedAttributes.squareFeet != "undefined" && response.propertyWatchResponseList[index].propertyDetails.detailedAttributes.squareFeet != '' && response.propertyWatchResponseList[index].propertyDetails.detailedAttributes.squareFeet != null ){

                            response.propertyWatchResponseList[index].propertyDetails.detailedAttributes.squareFeet = Number(response.propertyWatchResponseList[index].propertyDetails.detailedAttributes.squareFeet).formatMoney();
                        }
                        response.propertyWatchResponseList[index].isTimerApplicable = false;
                        if((response.propertyWatchResponseList[index].propertyListingDetails.auctionTimeLeft == null || response.propertyWatchResponseList[index].propertyListingDetails.auctionTimeLeft >= 0) && (response.propertyWatchResponseList[index].propertyListingDetails.listingStatus != null && response.propertyWatchResponseList[index].propertyListingDetails.listingStatus.toUpperCase() === 'ACTIVE')){
                            response.propertyWatchResponseList[index].isTimerApplicable = true;
                        }
                    
                 });
                var data ={'propertyDetails':response.propertyWatchResponseList,'saveListDrawerContent':saveListDrawerContent.saveListDrawer};
                data.contextPath = contextPath;
               
                if(newSkinning){
                    var sourceTemplate = favouriteTupleNew;
                }else{
                    var sourceTemplate = favouriteTuple; 
                }
               

                if(!thisObj.watchMoreClicked){
                    $('#watchedDiv, .favourites-list').html(sourceTemplate(data));
                   
                }else{
                    if(thisObj.watchOffSet<=0){
                        $('#watchedDiv, .favourites-list').html(sourceTemplate(data));
                    } else {
                        $('#watchedDiv, .favourites-list').append(sourceTemplate(data));
                    } 
                    thisObj.watchMoreClicked = false;
                }

                $('#savedWatchLoadingIcon').addClass("hide");
                $('#watchedDiv,#watchedDivMain, .favourites-list').removeClass('hide'); 
                 var watchTotal = $('#favouritesTab .favourites-list .propDetailsDrawer').length;
                if(response.watchCount != undefined && response.watchCount !='' && parseInt(response.watchCount)>0) {
                    if(response.watchCount > parseInt(watchTotal) ){
                        $('#watchedPropertyShowMoreDiv').removeClass('hide');
                    }else{
                        $('#watchedPropertyShowMoreDiv').addClass('hide');
                    }
                }else{
                    $('#unWatchedDiv, .favourite-default').removeClass("hide");
                    $('#watchedPropertyShowMoreDiv').addClass('hide');
                    $('.watchPropertyContainer .noResultContainer').css('display','block');
                    $('.dashTabHeader,#watchView').css('display','none');
                }

                if (response.watchCount > 0) {
                    $(".favourites-count").html(response.watchCount + " favorite " + ((response.watchCount > 1) ? "properties" :"property" ));
                } else {
                    $(".favourites-count").html("");
                }
                if(newSkinning && $(window).width()<768){
                    $('.favorites-drawer-container').css('width',$(window).width()+'px');
                }
                
            } else if(response && response.responseStatus == null && response.watchCount==0){
                    $('#savedWatchLoadingIcon').addClass("hide");
                    $('#watchedDiv, #watchedDivMain, .favourites-list').addClass('hide');
                    $('#unWatchedDiv, .favourite-default').removeClass("hide");
                    $('.watchPropertyContainer .noResultContainer').css('display','block');
                    $('.dashTabHeader,#watchView').css('display','none');
                    $(".favourites-count").html("");
            }
            $('.deleteWatchProperty').on('click',function(e){
                thisObj.deleteSaves(e);
            });
            $('.placeBidButton').on('click',function(e){
               var obj = e.currentTarget;
               if($(obj).attr("data-proxybidenabled") == "true"){
                    var userId = (typeof window.userSession !== "undefined") && userSession.userId || 'NA';
                    var eventLabel =  'UID : ' + userId + ' | Visitor Id : ' +$.cookie('visitorId')+'| Property Id:'+$(obj).attr("propertyid");
                    GAUtils.eventTracking.trackevent("", "Place Proxy Bid", eventLabel); 
               }
               thisObj.placeBidEventParam = e;
               Utils.checkIfUserLoggedInFlag(thisObj.showLoginPopup, thisObj);
            });
            $('.oinButton').on('click',function(e){
                thisObj.placeBidEventParam = e;
                Utils.checkIfUserLoggedInFlag(thisObj.showLoginPopup, thisObj);
             });
            $('.pdpLinkFromDrawer').click(function(e){
                thisObj.trackGAToPDP(e);
            });
            $('.drawer-view-details').click(function(e){
                thisObj.viewDetails(e);
            });

            // Initiate firebase listeners on watched properties
            thisObj.initiateFirebaseListeners({
                viewObject: thisObj,
                watchList: response.propertyWatchResponseList
            });

            // Initiate timer for listings 
            thisObj.initateListingTimer({
                viewObject: thisObj,
                watchList: response.propertyWatchResponseList
            });

        },

        submitProxyBid : function(){
            var self = this;
            self.proceedToBidwizard(loginRegisterView.proxyBidObj);
        },

        showLoginPopup:function(isLoggedIn,thisObj){

            loginRegisterView.proxyBidObj = {
                action : $(thisObj.placeBidEventParam.target).data('action') || '',
                isProxyBiddingEnabled : $(thisObj.placeBidEventParam.target).data('proxybidenabled') || false,
                propertyId:$(thisObj.placeBidEventParam.target).attr('propertyid') || '',
                listingId:$(thisObj.placeBidEventParam.target).attr('listingid') || '',
                source:'savelist',
                address:{
                    'streetName' : $(thisObj.placeBidEventParam.target).data('streetname')  || '',
                    'streetNumber' : $(thisObj.placeBidEventParam.target).data('streetnumber')  || '',
                    'city' : $(thisObj.placeBidEventParam.target).data('city')  || '',
                    'state' : $(thisObj.placeBidEventParam.target).data('state')  || '',
                    'zip' : $(thisObj.placeBidEventParam.target).data('zip')  || ''
                },
                viewObject:thisObj
            };
            
            if(!isLoggedIn){
                loginRegisterView.openLoginPopup({
                    callback: thisObj.placeBidAction,
                    viewObject: {
                        viewObject: thisObj,
                    },
                });
            } else if(loginRegisterView.proxyBidObj.isProxyBiddingEnabled && !thisObj.userDetails.phoneNumberVerified && mobileVerificationView.mobileVerificationCheckVal && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                var callbackOptions = null;
                callbackOptions = {
                    openPhoneVerification: true
                }   
                $('#loginRegisterDialog .modal-content').removeClass('activeStage');
                
                loginRegisterView.showPhoneVerification(callbackOptions, loginRegisterView.proxyBidObj.isProxyBiddingEnabled);
            } else if(thisObj.userDetails.phoneNumberVerified && loginRegisterView.proxyBidObj.isProxyBiddingEnabled){
                var callbackOptions = {};
                if (loginRegisterView.proxyBidObj.action === "place-bid") 
                    callbackOptions.confirmProxyBidPin = true;
                else 
                    callbackOptions.openProxyBidRegister = true;

                loginRegisterView.openLoginPopup(callbackOptions, loginRegisterView.proxyBidObj.isProxyBiddingEnabled);
            } else {
                // loginRegisterView.proxyBidObj.isProxyBiddingEnabled = false;
                var callOpt = {
                    openPhoneVerification: true,
                    callBackFunc: thisObj.placeBidAction,
                    openPopup : true,
                    callBackObj : thisObj,
                    callBackType : "saveList"
                }
                if(typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                    mobileVerificationView.checkMobileVerificationRequired(callOpt);
                      return;
                }
                thisObj.placeBidAction(null,thisObj);
            }
            
        },
        reInitializeSavedSearchParams: function(pageNumber){
            this.savedSearchParams.clickedcount = 0;
            this.savedSearchParams.pageNumber = pageNumber;
            this.savedSearchParams.pageSize = saveListDrawerContent.GlobalConfig.recordsPerPageSaveDrawer;
            this.savedSearchParams.results = null;
        },
       setSavedSearchParams: function (){
            var thisObj = this;
            if(thisObj.savedSearchParams.clickedcount == 0){
                this.savedSearchParams.pageNumber = 0;
            }else if(thisObj.savedSearchParams.clickedcount > 0){
                this.savedSearchParams.pageNumber++;
            }
            this.savedSearchParams.pageSize = 20;
            this.savedSearchParams.clickedcount++;

            thisObj.getSavedSearchesData();
       },
       watchpropertyShowMore : function(e){
            var thisObj = this;
            
            thisObj.watchMoreClicked = true;

            
            this.watchOffSet = this.watchOffSet + 1;
            thisObj.getWatchList();
            
       },
        formatCurrencyLabel : function(num){
            try{
                if(num != undefined){
                    num = num.toString().replace(/\$|\,/g,'');
                    if(isNaN(num)){
                        num = "0";
                        return num;
                    }else{
                        for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
                            num = num.substring(0,num.length-(4*i+3))+','+num.substring(    num.length-(4*i+3));
                        }
                        return ('$' + num);
                    }
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:formatCurrencyLabel|" + e, null, 1);
            }
        },

        showConfirmation: function(options){
            var thisObj = this;
            $(".header-popcontent").html(options.content);
            $(".header-tooltipPopdesc").removeClass('anonymousLimitError');
            $(".header-tooltipPopdesc").addClass(options.className).fadeIn(); 
            if(options.className == "saveConfirm") {
                setTimeout(function(){
                  $(".header-tooltipPopdesc").removeClass(options.className).fadeOut(); 
                },2000);
            }
        },

        startSearching: function(event){
            var thisObj = this;
            event.preventDefault();
            $('.savedSearches-item-delete,.deleteWatchProperty,.login-opener,.register-opener,.linkToSrpFromDrawer,.pdpLinkFromDrawer').attr('tabindex',-1);
            $('.notifications-slider-shade').fadeOut(500);
            $('.js-saved-drawer').removeClass('saved-items-active');
            $('body').removeClass('no-scroll');
            $("html, body").animate({ scrollTop: 0 }, "slow");
        },

        applyEditable: function(){

            try{
                
                $.fn.editable.defaults.mode = 'inline';
                
                var thisObj = this;

                $('.ssd-search-editable').editable({
                    type: 'text',
                    toggle: 'manual',
                    clear: false,
                    onblur: 'cancel',
                    validate: function(value) {

                        value = $.trim(value);

                        if(value === '') {
                            return saveListDrawerContent.errorMessages.savedSearchErrors['SAVE_SEARCH_NAME_REQUIRED'] || saveListDrawerContent.errorMessages.genericError;
                        }

                        var regExpression = saveListDrawerContent.regularExpressions.saveSearchName,
                            regexValidator = new RegExp(regExpression, 'g'),
                            isValid = regexValidator.test(value);

                        if(!isValid) {
                            return saveListDrawerContent.errorMessages.savedSearchErrors['VALIDATION_REQUEST_PARAMETER_FAILED'] || saveListDrawerContent.errorMessages.genericError;
                        } else if(value.length > 40){
                            return saveListDrawerContent.errorMessages.savedSearchErrors['SAVE_SEARCH_NAME_MAX_LENGTH'] || saveListDrawerContent.errorMessages.genericError;
                        }
                    },      
                    url: function(params){

                        params = params || {}

                        var d = new $.Deferred(),
                            saveNameUrl = contextPath+'/portal/saveSearch/editSaveSearchName',
                            inputData = {
                                searchId: params.name.replace('SSD', '') || '',
                                searchName: $.trim(params.value) || ''
                            };

                        if(params.value === '') {
                            return d.reject(''); //returning error via deferred object
                        } else {

                            dUtil.showLoader();

                            $.ajax ({
                                url: saveNameUrl,
                                type: "POST",
                                data: JSON.stringify(inputData),
                                dataType: "json",
                                contentType: "application/json; charset=utf-8",
                                success: function(data){

                                    var responseData = data || {},
                                        status = responseData.status && responseData.status || 'FAILURE';

                                    if(status === "SUCCESS") {
                                        d.resolve({ response: responseData, inputData: inputData})
                                    } else if(status === "FAILURE") {
                                        d.reject({ response: responseData, inputData: inputData});
                                    } else {
                                        d.reject({ response: responseData, inputData: inputData});
                                    }
                                },
                                error: function(xhrObject, textStatus, errorThrown){
                                    d.reject({ response: xhrObject, inputData: inputData});
                                }
                            });

                            return d.promise();
                        }
                    },  
                    success: function(data) {     

                        dUtil.hideLoader();
                        var response = data || {};

                        var eventLabel = $.cookie('visitorId') +'| Search Id : '+ response.inputData.searchId  || '' +'|'+ response.inputData.searchName || '';
                        GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Edit - Save Search Name Success", eventLabel);

                        if(window.location.hash=='#savedSearches' && window.location.href.indexOf('my-shopper')>-1){
                            if(response.inputData && response.inputData.searchId && response.inputData.searchName) {
                                $("#BD" + response.inputData.searchId).text(response.inputData.searchName);    
                            }
                        }
                        
                    },
                    error: function(errorData) {

                        dUtil.hideLoader();
                        
                        var responseData = errorData || {},
                            errorMessage = responseData.response && saveListDrawerContent.errorMessages.savedSearchErrors[responseData.response.errorMessage] || saveListDrawerContent.errorMessages.genericError;

                        var eventLabel = $.cookie('visitorId') +'| Search Id: '+ responseData.inputData.searchId + ' |Search Name: ' + responseData.inputData.searchName + ' | Error :' + errorMessage;

                        if(responseData.response.statusText) {
                            eventLabel += ' | API Response - ' + responseData.response.statusText;
                        }

                        GAUtils.eventTracking.trackevent(thisObj.currPageSave+" - Save List Slider","Edit - Save Search Name Failure", eventLabel);

                        return errorMessage;

                    }
                });

                 
                $('.ssd-name-editable').click(function(e){    

                    e.stopPropagation();

                    var id = $(e.target).attr('data-prop');

                    $('.ssd-saveSearchNameLink_' + id).editable('toggle');
                    $('.ssd-saveSearchNameLink_' + id).on('hidden', function(e, reason) {
                        
                        /*
                            reason - onblur, cancel, nochange, save
                        */

                        e.stopPropagation();

                        $('#ssd-editSearchName_'+ id).css('display', 'inline-block');
                        $('#ssd-editSearchName_'+ id).show();

                    });
                    $(this).hide();
                });

            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawerView", "file:saveListDrawerView.js|function:applyEditable|" + e, null, 1);
            }

        } 
    });

    return saveListDrawerView;
});
