
define('userCheckView',['jquery', 'backbone', 'jqueryMobile', 'GoogleAnalyticsUtils', 'bootstrap', 'hbs!tpl/popups/userError'], 
function ($,Backbone,jqueryMobile,GAUtils, bootstrap,userErrorTpl) {
    'use strict';
    var $request;	
    var userCheckView = Backbone.View.extend({
        
        el: $("#page-top"),
        
        events: {
           
        },

        initialize: function () {
            
            
            
        },
        userTypeCheck : function(userTypeOptions){
            try{
                var url = contextPath + "/portal/lean-regn/isUserAuthorized/"+userTypeOptions.listingId;
                var tempData = {},options={},
                callBack = userTypeOptions.callBack;
                options = userTypeOptions;
                
                GAUtils.eventTracking.trackevent("","User Type Check",$.cookie('visitorId'),'',1); 
                
               
                $request = $.ajax({
                    url: url,
                    success: function(data){  
                        //GAUtils.eventTracking.trackevent("","User Type Check Success",$.cookie('visitorId'),'',1); 

                        $(".global-Mask").hide();
                        if(data.status == 'SUCCESS' || (data.status=="FAILURE" && (data.message == "SESSION_REQUIRED" || data.message == "IDOLOGY_NOT_VERIFIED" || data.message == "PROFILE"))){
                            GAUtils.eventTracking.trackevent("","User Type Check Success : true",$.cookie('visitorId'),'',1);
                            callBack(options);
                        }else{
                            GAUtils.eventTracking.trackevent("","User Type Check Success : false",$.cookie('visitorId'),'',1); 
                            tempData.errorHead = saveListDrawerContent.placeBidContent.placeBidHeader;
                            tempData.errorContent = (typeof(saveListDrawerContent.placeBidContent.errorContent.errorMessages[data.message]) != 'undefined' && (saveListDrawerContent.placeBidContent.errorContent.errorMessages[data.message]) != '')? saveListDrawerContent.placeBidContent.errorContent.errorMessages[data.message] : saveListDrawerContent.placeBidContent.errorContent.errorMessages['default'];
                            tempData.closeButton =  saveListDrawerContent.placeBidContent.errorContent['CLOSE_BUTTON'];
                            $("#commonModal").html(userErrorTpl(tempData));
                            if(!$("#userErrorPopupDiv").is(':visible')){
                                if(typeof pageName !== "undefined" && pageName == 'pdp' && $("#pdp-gallery-lightbox").is(':visible')){
                                    $("#pdp-gallery-lightbox").modal('hide');
                                }
                                $('.notifications-slider').removeClass('saved-items-active');
                                $('.notifications-slider-shade').fadeOut(100);

                                $(".h-save-search-icon").removeClass("active");
                                $("body").removeClass("no-scroll");
                                $('#notificationMobilePopup').modal('hide');

                                $('#userErrorPopupDiv').modal("show");
                            }
                            
                            return false;
                        }
                        
                    },
                    error: function(){
                        GAUtils.eventTracking.trackevent("","User Type Check Success : false",$.cookie('visitorId')); 
                        callBack(options);
                    }
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "saveListDrawer", "file:userCheck.js|function:userTypeCheck|" + e, null, 1);
            }
        },
        render:function(){

        }
    });
    return new userCheckView;
});