
/** Place Bid Common utils
    * @var sourcePageName : the current Module -> used for GA tracking 
*/
define('placeBidView',['jquery', 'backbone','GoogleAnalyticsUtils','commonutils','bidModel','bidSignInView','loginRegisterView','monitoringView'], function ($,Backbone,GAUtils,commonutils,BidModel,BidSignInView,loginRegisterView, monitoringView) {
    'use strict';
    var sourcePageName;

    var placeBidView = Backbone.View.extend({
        initialize: function () {
            var self = this;            
        },
        /**
         *Sets the bid Model which will be submitted to place the bid 
         *@param responseData : response data of the bid page json        
         **/
        initBidModel : function(responseData){
            try{
                var trackText =  this.getUserDetails();
                GAUtils.eventTracking.trackevent("", sourcePageName+"- Bid Model initialize", trackText);
                BidModel.set(responseData);
                if(BidModel.attributes.isRepeatBid && BidModel.attributes.previousBidDetail){
                    GAUtils.eventTracking.trackevent("", sourcePageName+"- Is Repeat Bid Reset", trackText);
                    BidModel.attributes.bidDetail = BidModel.attributes.previousBidDetail;
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:initBidModel|" + e, null, 1);
            }
        },
        getUserDetails: function() {
            try{
                var userSession = window.userSession || {},
                userId = userSession.userId || null,
                userStatus = userSession.userStatus || null;
                return "userID: "+ userId + "| User Status: " + userStatus;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:getUserDetails|" + e, null, 1);
            }
        },
        resetAutoBidModel : function(){
            try{
                BidModel.attributes.bidType = 'PLACE_BID';
                BidModel.attributes.autoBidEnabled = false;
                BidModel.attributes.bidDetail.isAutoBidSelected = false;
                BidModel.attributes.bidDetail.autoBidAmountDetail = null;
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:resetAutoBidModel|" + e, null, 1);
            }
        },
        /**
         * Fetches the bid detials of the specific lisiting Id 
         * @param callbackOptions Json Object.
         *        {sourcePageName}* : For GA Tracking
         *        {callback}*       : Callback to be executed
         *        {inputJson}*      : listingId,bidAmount,previousBidEmailId,isEditMode,bidType
         * @returns responseObject Json Array 
         *        {callbackOptions} : CallbackOptions object provided as input
         *        {responseData}    : Ajax response data
         */
        getRepeatBidDetails: function(callbackOptions){
            try{

                sourcePageName = callbackOptions.sourcePageName || 'GlobalAlerts';

                var self = this,
                    callback = callbackOptions.callback,
                    trackText =  this.getUserDetails(),
                    callbackOptions = callbackOptions,
                    errormsg,
                    autoBidAmount,
                    loginCallBackOptions = {},
                    responseObject = {
                        callbackOptions: callbackOptions
                    };
                    

                jQuery.httpRequest({
                    url: contextPath + '/portal/bidPageJson',
                    type: "POST",
                    data: JSON.stringify(callbackOptions.inputJson),
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (responseData) {    
                        if(responseData.errorMessage !== undefined && responseData.errorMessage === 'SESSION_INVALID'){
                            GAUtils.eventTracking.trackevent("", sourcePageName+"- Get Bid Details Session Time out", trackText);
                            loginCallBackOptions = { 
                                parentCallBackOptions: callbackOptions,
                                parentViewObject:self
                            }; 
                            loginCallBackOptions = {parentCallBackOptions:callbackOptions,parentViewObject:self}; 
                            loginRegisterView.openLoginPopup({
                                callback: self.getRepeatBidDetailsAfterLogin,
                                viewObject: loginCallBackOptions,
                                statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessageForPlaceBid
                            });  
                            responseObject.status = 'RESPONSE_ERROR';
                            callback(responseObject);   
                        }else{
                            responseObject.status = 'RESPONSE_SUCCESS';
                            self.initBidModel(responseData);
                            GAUtils.eventTracking.trackevent("", sourcePageName+"- Get Bid Details Success", trackText);
                            if(BidModel.attributes.previousBidDetail.isAutoBidSelected && BidModel.attributes.previousBidDetail.autoBidAmountDetail != null){
                                autoBidAmount = parseInt(BidModel.attributes.previousBidDetail.autoBidAmountDetail.bidAmount);
                                
                            }
                            if(BidModel.attributes.previousBidDetail != undefined && BidModel.attributes.previousBidDetail.isAutoBidSelected && BidModel.attributes.previousBidDetail.autoBidAmountDetail != null && parseInt(autoBidAmount) >= parseInt(BidModel.attributes.currentBidAmountDetail.bidAmount)){
                                errormsg = placeBidContent.placeBidError['OWN_OUTBID_ERROR'].replace('###BID_AMOUNT###',parseInt(BidModel.attributes.currentBidAmountDetail.bidAmount).formatMoney()).replace('###POSSIBLE_BID###',BidModel.attributes.previousBidDetail.autoBidAmountDetail.bidAmountFormatted);
                                responseData.autoBidErrorMsg = errormsg;
                            }
                            responseObject.responseData = responseData;
                            if(callbackOptions.isPopulateBidDetails){
                                callback(responseObject); 
                            } 
                        }
                    },
                    error: function (error) { 
                        GAUtils.eventTracking.trackevent("Ajax Error:" + sourcePageName, "file:placeBidView.js,function:getRepeatBidDetails", error);
                        responseObject.status = 'RESPONSE_ERROR';
                        callback(responseObject);             
                    }
                }); 
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:getRepeatBidDetails|" + e, null, 1);
            }
        },
        getRepeatBidDetailsAfterLogin:function(response,loginCallBackOptions){
            try{
                var self = loginCallBackOptions.parentViewObject,
                    trackText =  self.getUserDetails(),
                callback = loginCallBackOptions.parentCallBackOptions.callback;
                GAUtils.eventTracking.trackevent("", sourcePageName+"- Get Bid Details Session Time out", trackText);
                if(response != undefined  && response.idologyStatus && response.user.userStatus == 'ACTIVE'){
                    self.getRepeatBidDetails(loginCallBackOptions.parentCallBackOptions);
                } else {
                    // User has given different credentials which was not active 
                    callback({
                        status: 'ERROR',
                        response: response
                    });
                    GAUtils.eventTracking.trackevent("", sourcePageName+"- Get Bid Details Session Login different User", trackText);
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:getRepeatBidDetailsAfterLogin|" + e, null, 1);
            }
        },
        /**
         * Fetches the bid detials of the specific lisiting Id 
         * @param callbackOptions json array.
         *        {currEle}      : The current target
         *        {callBackFunc} : The parent callback funtion to be called after exceution of the function
         *        {viewObject}         : The object of the view from which the function was called 
         *        {bidAmountCalVal}    : bidIncrement,bidAmount,minBidAmount,maxBidAmount,bidAmount
         * @returns viewObject Json Array 
         *        {currEle}     : current target
         *        {self}        : The parent view Object
         *        {bidAmountCalVal} : bidIncrement,bidAmount,minBidAmount,maxBidAmount,bidAmount,enableDisableAction
         */
        calculateBidIncrement: function (callBackParam) {
            try{
                var ele = callBackParam.currEle;
                if(ele.hasClass('disable')){
                    return;
                }else{
                    var bidvalue,
                    bidAmountValue ,
                    bidValDiff,
                    bidAmountCalVal ={};
                    bidAmountCalVal = callBackParam.bidAmountCalVal;
                    bidAmountValue = bidAmountCalVal.bidAmount;
                    bidvalue = parseInt(bidAmountValue) + parseInt(bidAmountCalVal.bidIncrement);
                    bidvalue = isNaN(bidvalue) ? Number(bidAmountCalVal.minBidAmount) : bidvalue;
                    if (bidvalue >= parseInt(bidAmountCalVal.minBidAmount) && bidvalue <= parseInt(bidAmountCalVal.maxBidAmount)) {
                        bidAmountCalVal.enableDisableAction = {'minus':'enabled','plus':'enabled'};
                    }
                    if (bidvalue >= parseInt(bidAmountCalVal.maxBidAmount)) {
                        bidAmountCalVal.enableDisableAction = {'plus': 'disabled'};
                    }

                    var slabAmount =0,
						highSlabAmount =0;
					if(bidAmountCalVal.isActiveBidRefinementEnabled === 'Y'){
						slabAmount = (bidAmountCalVal.currentHighestBid > parseInt(bidAmountCalVal.lowerThresholdAmount))? (bidAmountCalVal.currentHighestBid) : parseInt(bidAmountCalVal.lowerThresholdAmount);
                        highSlabAmount = (bidAmountCalVal.currentHighestBid > parseInt(bidAmountCalVal.upperThresholdAmount))? (bidAmountCalVal.currentHighestBid ) : parseInt(bidAmountCalVal.upperThresholdAmount);
                        if((bidvalue > (slabAmount + (parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.lowTreshInc))) && bidvalue < parseInt(bidAmountCalVal.upperThresholdAmount))){
                            bidAmountCalVal.enableDisableAction = {'minus':'enabled','plus':'disabled'};
                        } else if(bidAmountCalVal.nextBidAmount < parseInt(bidAmountCalVal.lowerThresholdAmount) && bidvalue > ((parseInt(bidAmountCalVal.lowerThresholdAmount) + (parseInt(bidAmountCalVal.nextBidAmount) % parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier))) - bidAmountCalVal.bidIncrement) && bidAmountCalVal.nextBidAmount < parseInt(bidAmountCalVal.upperThresholdAmount)){
                            bidAmountCalVal.enableDisableAction = {'minus':'enabled','plus':'disabled'};
                        } else if((bidvalue > (highSlabAmount + (parseInt(bidAmountCalVal.upperThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.highTreshInc))))){
                            bidAmountCalVal.enableDisableAction = {'minus':'enabled','plus':'disabled'};
                        }
                    }

                    
                    if(bidvalue <= bidAmountCalVal.minBidAmount){
                        bidvalue = bidAmountCalVal.minBidAmount; 
                    }else if(bidvalue >= bidAmountCalVal.maxBidAmount){
                        bidvalue = bidAmountCalVal.maxBidAmount; 
                        bidAmountCalVal.isGreaterThanBidAmount = 'Y';
                    }else{
                        bidValDiff =  bidvalue - bidAmountCalVal.minBidAmount;
                        if((bidValDiff)%bidAmountCalVal.bidIncrement!=0)
                        {
                            bidvalue = parseInt(bidAmountCalVal.minBidAmount) + Math.floor((bidvalue - parseInt(bidAmountCalVal.minBidAmount))/parseInt(bidAmountCalVal.bidIncrement))  * parseInt(bidAmountCalVal.bidIncrement);

                        }
                    }
                    
                    if(bidAmountCalVal.isActiveBidRefinementEnabled === 'Y') {
                        if(parseInt(bidAmountValue) >= parseInt(bidAmountCalVal.lowerThresholdAmount) && parseInt(bidAmountValue < bidAmountCalVal.upperThresholdAmount)){
                            bidvalue = parseInt(bidAmountValue) + parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier);
                        } else if(parseInt(bidAmountValue) >= parseInt(bidAmountCalVal.upperThresholdAmount)){
                            bidvalue = parseInt(bidAmountValue) + parseInt(bidAmountCalVal.upperThresholdIncrementMultiplier);
                        }

                        var upperIncConfig = saveListDrawerContent.placeBidContent.bidConfig.highTreshInc;
                        if(bidvalue >= (parseInt(highSlabAmount) + (parseInt(bidAmountCalVal.upperThresholdIncrementMultiplier) * parseInt(upperIncConfig)))){
                            bidvalue = bidAmountCalVal.nextBidAmount;
                            bidAmountCalVal.enableDisableAction = {'minus':'enabled','plus':'disabled'};
                        }
                    }

                    callBackParam.bidAmountCalVal.bidAmount = bidvalue;
                    var parentCallBackJson = {currEle:callBackParam.currEle,self:callBackParam.viewObject,bidAmountCalVal:bidAmountCalVal};
                    var callback = callBackParam.callback;
                    callback(parentCallBackJson);
                }
                
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:calculateBidIncrement|" + e, null, 1);
            }
        },
        /**
         * Fetches the bid detials of the specific lisiting Id 
         * @param callbackOptions json array.
         *        {currEle}      : The current target
         *        {callBackFunc} : The parent callback funtion to be called after exceution of the function
         *        {viewObject}         : The object of the view from which the function was called 
         *        {bidAmountCalVal}    : bidIncrement,bidAmount,minBidAmount,maxBidAmount,bidAmount
         * @returns viewObject Json Array 
         *        {currEle}     : current target
         *        {self}        : The parent view Object
         *        {bidAmountCalVal} : bidIncrement,bidAmount,minBidAmount,maxBidAmount,bidAmount,enableDisableAction
         */
        calculateBidReduction: function (callBackParam) {
            try{
                var ele = callBackParam.currEle;
                if(ele.hasClass('disable')){
                    return;
                }else{
                    var bidvalue,
                        bidAmountValue,
                        bidAmountCalVal={},
                        bidAmountCalVal = callBackParam.bidAmountCalVal;
                        bidAmountValue = bidAmountCalVal.bidAmount;

                    bidvalue = parseInt(bidAmountValue) - parseInt(bidAmountCalVal.bidIncrement);
                    bidvalue = isNaN(bidvalue) ? (bidAmountCalVal.minBidAmount) : bidvalue;
                    
                    if (bidvalue >= parseInt(bidAmountCalVal.minBidAmount) && bidvalue <= parseInt(bidAmountCalVal.maxBidAmount)) {
                        bidAmountCalVal.enableDisableAction = {'minus':'enabled','plus':'enabled'};    
                    }
                    if (bidvalue <= parseInt(bidAmountCalVal.minBidAmount)) {
                        bidvalue = bidAmountCalVal.minBidAmount;
                        bidAmountCalVal.enableDisableAction = {'minus':'disabled'};
                    } 
                    if (bidvalue > parseInt(bidAmountCalVal.maxBidAmount)) {
                        bidvalue = Math.floor(parseInt(bidAmountCalVal.maxBidAmount) / parseInt(bidAmountCalVal.bidIncrement)) * parseInt(bidAmountCalVal.bidIncrement);
                        if(bidvalue > parseInt(bidAmountCalVal.maxBidAmount)){
                            bidvalue = parseInt(bidAmountCalVal.maxBidAmount);
                        }
                        bidAmountCalVal.enableDisableAction = {'plus':'disabled'};                        
                        bidAmountCalVal.isGreaterThanBidAmount = 'Y';
                    }
                    if (!(bidvalue >= parseInt(bidAmountCalVal.maxBidAmount))) {
                        if((bidvalue - parseInt(bidAmountCalVal.minBidAmount)) % parseInt(bidAmountCalVal.bidIncrement) !=0)
                        {
                            bidvalue = parseInt(bidAmountCalVal.minBidAmount) + Math.floor((bidvalue - parseInt(bidAmountCalVal.minBidAmount))/parseInt(bidAmountCalVal.bidIncrement)+1)  * parseInt(bidAmountCalVal.bidIncrement);
                            //bidval = that.bidMinLimit + (Math.floor((bidval-that.bidMinLimit)/that.bidIncrementRange)+1) * that.bidIncrementRange;
                        }
                    } else if(bidAmountCalVal.isActiveBidRefinementEnabled === 'Y' && (bidvalue > (bidAmountCalVal.currentHighestBid + (bidAmountCalVal.upperThresholdIncrementMultiplier * saveListDrawerContent.placeBidContent.bidConfig.highTreshInc)))){
                        bidAmountCalVal.enableDisableAction = {'plus':'enabled'};
                    }
                    if(bidAmountCalVal.isActiveBidRefinementEnabled === 'Y' && parseInt(bidAmountValue) >= parseInt(bidAmountCalVal.lowerThresholdAmount) && parseInt(bidAmountValue < bidAmountCalVal.upperThresholdAmount)){
                        bidvalue = parseInt(bidAmountValue) - parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier);
                    }else if(bidAmountCalVal.isActiveBidRefinementEnabled === 'Y' && parseInt(bidAmountValue) >= parseInt(bidAmountCalVal.upperThresholdAmount)){
                        bidvalue = parseInt(bidAmountValue) - parseInt(bidAmountCalVal.upperThresholdIncrementMultiplier);
                    }

                    var slabAmount = (bidAmountCalVal.currentHighestBid > parseInt(bidAmountCalVal.lowerThresholdAmount))? (bidAmountCalVal.currentHighestBid) : parseInt(bidAmountCalVal.lowerThresholdAmount);
                    var highSlabAmount = (bidAmountCalVal.currentHighestBid > parseInt(bidAmountCalVal.upperThresholdAmount))? (bidAmountCalVal.currentHighestBid ) : parseInt(bidAmountCalVal.upperThresholdAmount);

                    var lowerIncConfig = saveListDrawerContent.placeBidContent.bidConfig.lowTreshInc;
                    var upperIncConfig = saveListDrawerContent.placeBidContent.bidConfig.highTreshInc;
                    if(bidAmountCalVal.isActiveBidRefinementEnabled && bidAmountCalVal.isActiveBidRefinementEnabled === 'Y'){
                        if(bidvalue > (parseInt(highSlabAmount) + (parseInt(bidAmountCalVal.upperThresholdIncrementMultiplier) * parseInt(upperIncConfig)))){
                            bidvalue = bidAmountCalVal.nextBidAmount;
                            bidAmountCalVal.enableDisableAction = {'minus':'disabled'};
                        } else if(bidAmountCalVal.currentHighestBid >= parseInt(bidAmountCalVal.upperThresholdAmount) && bidvalue < (parseInt(highSlabAmount) + (parseInt(bidAmountCalVal.upperThresholdIncrementMultiplier) * parseInt(upperIncConfig)))){
                            bidvalue = bidAmountCalVal.nextBidAmount;
                            bidAmountCalVal.enableDisableAction = {'minus':'disabled'};   
                        } else if(parseInt(bidAmountCalVal.nextBidAmount) <= (parseInt(bidAmountCalVal.lowerThresholdAmount) + (parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier) * parseInt(lowerIncConfig))) && bidvalue > (parseInt(slabAmount) + (parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier) * parseInt(lowerIncConfig)))){
                            bidvalue = bidAmountCalVal.nextBidAmount;
                            bidAmountCalVal.enableDisableAction = {'minus':'disabled','plus':'enabled'}; 
                        } else if(bidvalue >= (parseInt(slabAmount) + parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier) * parseInt(saveListDrawerContent.placeBidContent.bidConfig.lowTreshInc)) && parseInt(bidAmountCalVal.currentHighestBid) < parseInt(bidAmountCalVal.upperThresholdAmount)){
                            bidvalue = bidAmountCalVal.nextBidAmount;
                            bidAmountCalVal.enableDisableAction = {'minus':'disabled','plus':'enabled'}; 
                        } else if(bidAmountValue > (parseInt(bidAmountCalVal.lowerThresholdAmount) + (parseInt(bidAmountCalVal.nextBidAmount) % parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier)))  && parseInt(bidAmountCalVal.nextBidAmount) <= (parseInt(bidAmountCalVal.lowerThresholdAmount) + (parseInt(bidAmountCalVal.nextBidAmount) % parseInt(bidAmountCalVal.lowerThresholdIncrementMultiplier))) && parseInt(bidAmountCalVal.currentHighestBid) < parseInt(bidAmountCalVal.upperThresholdAmount)){
                            bidvalue = bidAmountCalVal.nextBidAmount;
                            bidAmountCalVal.enableDisableAction = {'minus':'disabled','plus':'enabled'}; 
                        } else if(bidvalue <= parseInt(bidAmountCalVal.nextBidAmount)){  /* Add equal for plus icon disable after decrement - HUBZ-3004 */
                            bidvalue = bidAmountCalVal.nextBidAmount;
                            bidAmountCalVal.enableDisableAction = {'minus':'disabled','plus':'enabled'}; 
                        }
                    }

                     callBackParam.bidAmountCalVal.bidAmount = bidvalue;
                    var parentCallBackJson = {currEle:callBackParam.currEle,self:callBackParam.viewObject,bidAmountCalVal:bidAmountCalVal};
                    var callback = callBackParam.callback;
                    callback(parentCallBackJson);
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:calculateBidReduction|" + e, null, 1);
            }
        },

        submitVirtualForm : function(inputJson){
            try{
                if(BidModel.attributes.isQuickBid != undefined && BidModel.attributes.isQuickBid !=''){
                  inputJson.isQuickBid = BidModel.attributes.isQuickBid;
                }
                if(BidModel.attributes.quickBidIncrement != undefined && BidModel.attributes.quickBidIncrement !=''){
                  inputJson.quickBidIncrement = BidModel.attributes.quickBidIncrement;
                }
                var trackText =  this.getUserDetails();
                GAUtils.eventTracking.trackevent("", sourcePageName+" - Generate Virtual Form", trackText);
                var newForm = $('<form>', {
                'action': inputJson.bidUrl,
                'target': '_top',
                'method' : 'POST',
                'id' : "virtualBidSubmit"
                }).append($('<input>', {
                    'name': 'listingId',
                    'value': inputJson.listingId,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'bidAmount',
                    'value': inputJson.bidAmount,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'previousBidEmailId',
                    'value': inputJson.previousBidEmailId,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'isEditMode',
                    'value': inputJson.isEditMode,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'bidType',
                    'value': inputJson.bidType,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'isQuickBid',
                    'value': inputJson.isQuickBid,
                    'type': 'hidden'
                })).append($('<input>', {
                    'name': 'quickBidIncrement',
                    'value': inputJson.quickBidIncrement,
                    'type': 'hidden'
                }));
                //jQuery('#myform').append(newForm);
                $('body').append(newForm);
                GAUtils.eventTracking.trackevent("", sourcePageName+" - Virtual Form Submit", trackText);
                $('#virtualBidSubmit').submit();
                $('#virtualBidSubmit').remove();
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:submitVirtualForm|" + e, null, 1);

            }
        },
        /**
         * Calculate buyers premium
         * @param callbackOptions Json Object.
         *        {sourcePageName}* : The parent callback funtion to be called after exceution of the function
         *        {minBidAmount}*   : Min bid amount
         *        {bidAmount}*      : Bid amount
         *        {callback}*       : Callback to be executed 
         * @returns responseObject Json Array 
         *        {callbackOptions} : CallbackOptions object provided as input
         *        {responseData}    : Ajax response data
         */
        calculateBuyersPremium: function(callbackOptions){
            try {
                
                sourcePageName = callbackOptions.sourcePageName || 'GlobalAlerts';

                var self = this,
                    bidvalue = parseInt(callbackOptions.bidAmount) || null,
                    bidvalue = bidvalue ? bidvalue : parseInt(callbackOptions.minBidAmount),
                    callback = callbackOptions.callback,
                    trackText =  self.getUserDetails(),
                    responseObject = {
                        callbackOptions: callbackOptions
                    };
                GAUtils.eventTracking.trackevent("", sourcePageName+"- Calculate Buyers Premium", trackText);

                jQuery.httpRequest({
                    url: contextPath + '/portal/buyerPremium/'+BidModel.attributes.listingId +'/'+ bidvalue,
                    type: "GET",
                    success: function (data) {
                        BidModel.attributes.currentBidAmountDetail.bidAmount = data.bidAmount;
                        BidModel.attributes.currentBidAmountDetail.bidAmountFormatted = data.bidAmountFormatted;
                        BidModel.attributes.currentBidAmountDetail.technologyFeeFormatted = data.technologyFeeFormatted;
                        BidModel.attributes.currentBidAmountDetail.buyerPremiumFormatted = data.buyerPremiumFormatted;
                        if(BidModel.attributes.sellerParameters.sellerHasPermission){
                            BidModel.attributes.currentBidAmountDetail.totalDueFormatted = data.totalDueFormatted;
                        }
                        BidModel.attributes.currentBidAmountDetail.percentageFormatted = data.percentageFormatted;
                        BidModel.attributes.currentBidAmountDetail.earnestMoneyFormatted = data.earnestMoneyFormatted;
                        BidModel.attributes.currentBidAmountDetail.earnestMoneyMessage = data.earnestMoneyMessage;
                        responseObject.data = data;
                        GAUtils.eventTracking.trackevent("", sourcePageName+"- Calculate Buyers Premium Success", trackText);
                        callback(responseObject);
                    },
                    error: function (exception) {
                        callback(responseObject);
                        GAUtils.eventTracking.trackevent("Ajax Error:" + sourcePageName, "file:placeBidView.js,function:calculateBuyersPremium", exception);
                    }  
                });
                        
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:calculateBuyersPremium|" + e, null, 1);
            }
        },
        /**
         * Check the place bid validation
         * @param callbackOptions json array.
         *        {currEle}      : The current target
         *        {callBackFunc} : The parent callback funtion to be called after exceution of the function
         *        {viewObject}         : The object of the view from which the function was called 
         *        {bidAmountCalVal}    : bidIncrement,bidAmount,minBidAmount,maxBidAmount,bidAmount
         * @returns viewObject Json Array 
         *        {currEle}     : current target
         *        {self}        : The parent view Object
         *        {bidAmountCalVal} : bidIncrement,bidAmount,minBidAmount,maxBidAmount,bidAmount,enableDisableAction
         */
        placeBidValidation: function (callbackOptions) {
            try{
            var errormsg = '',
                bidval,
                bidValDiff,
                autoBidAmount,
                bidAmountCalVal={},
                responseObject = callbackOptions,
                trackText =  this.getUserDetails(),
                bidAmountCalVal = callbackOptions.bidAmountCalVal,
                
            bidval = callbackOptions.bidAmountCalVal.bidAmount;
            bidValDiff = parseInt(bidval) - parseInt(bidAmountCalVal.minBidAmount);
            if(BidModel.attributes.previousBidDetail.isAutoBidSelected && BidModel.attributes.previousBidDetail.autoBidAmountDetail != null){
                autoBidAmount = parseInt(BidModel.attributes.previousBidDetail.autoBidAmountDetail.bidAmount);
                
            }
            var isPlaceBidValid = true;
            bidAmountCalVal.enableDisableAction = {'minus':'enabled','plus':'enabled'};
            if((parseInt(bidval) != 0 ) && (bidval == undefined || bidval == null || bidval == '')){
                errormsg = placeBidContent.placeBidError['EMPTY_BID_AMOUNT'];
                isPlaceBidValid = false;
            }
            else if (isNaN(bidval)) {
                errormsg = placeBidContent.placeBidError['INVALID_BID_AMOUNT'];
                isPlaceBidValid = false;
            } else if (parseInt(bidval) < parseInt(bidAmountCalVal.minBidAmount)) {
                errormsg = placeBidContent.placeBidError['MIN_BID_AMOUNT'].replace('###MIN_BID_AMOUNT###',parseInt(bidAmountCalVal.minBidAmount).formatMoney());
                bidAmountCalVal.enableDisableAction = {'minus':'disabled','plus':'enabled'};
                isPlaceBidValid = false;

            } else if (parseInt(bidval) > parseInt(bidAmountCalVal.maxBidAmount)) {
                errormsg = placeBidContent.placeBidError['MAX_BID_AMOUNT'].replace('###MAX_BID_AMOUNT###',parseInt(bidAmountCalVal.maxBidAmount).formatMoney());
                isPlaceBidValid = false;
                bidAmountCalVal.enableDisableAction = {'plus':'disabled','minus':'enabled'};
            } else if (parseInt(bidval) == parseInt(bidAmountCalVal.maxBidAmount)) {
                errormsg = placeBidContent.placeBidError['MAX_LIMIT_REACHED'].replace('###MAX_BID_AMOUNT###',parseInt(bidAmountCalVal.maxBidAmount).formatMoney());
                isPlaceBidValid = true;
                bidAmountCalVal.enableDisableAction = {'plus':'disabled','minus':'enabled'};
            }else if ((bidValDiff) % parseInt(bidAmountCalVal.bidIncrement) != 0) {
                isPlaceBidValid = false;
                errormsg = placeBidContent.placeBidError['MIN_BID_INCREMENT'].replace('###MIN_BID_INCREMENT###',bidAmountCalVal.bidIncrement.formatMoney());
            }else if(BidModel.attributes.previousBidDetail != undefined && BidModel.attributes.previousBidDetail.isAutoBidSelected && BidModel.attributes.previousBidDetail.autoBidAmountDetail != null && parseInt(autoBidAmount) >= parseInt(bidAmountCalVal.bidAmount)){
                isPlaceBidValid = false;    
                errormsg = placeBidContent.placeBidError['OWN_OUTBID_ERROR'].replace('###BID_AMOUNT###',parseInt(bidAmountCalVal.bidAmount).formatMoney()).replace('###POSSIBLE_BID###',BidModel.attributes.previousBidDetail.autoBidAmountDetail.bidAmountFormatted);
            }
            callbackOptions.bidAmountCalVal = bidAmountCalVal;
            var callBack = callbackOptions.callback;
            responseObject.isPlaceBidValid = isPlaceBidValid;
            responseObject.errormsgPlaceBid = errormsg;
                GAUtils.eventTracking.trackevent("", sourcePageName+"- Place Bid Validation" + isPlaceBidValid, trackText);
                callBack(responseObject);
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:placeBidValidation|" + e, null, 1);
            }
        },

        /**
         * Validate bid amount and Auction status
         * @param callbackOptions Json Object.
         *        {sourcePageName}* : The parent callback funtion to be called after exceution of the function
         *        {callback}*       : Callback to be executed 
         *        {userId}*         : User id 
         * @returns responseObject Json Array 
         *        {callbackOptions} : CallbackOptions object provided as input
         *        {responseData}    : Ajax response data
         */
        validateBidAmount : function(callbackOptions){
            try{
                
                sourcePageName = callbackOptions.sourcePageName || 'GlobalAlerts';

                var self = this,
                    bidAmount = callbackOptions.bidAmount || BidModel.attributes.currentBidAmountDetail.bidAmount,
                    listingId = callbackOptions.listingId || BidModel.attributes.listingId,
                    data,
                    responseObject = {
                        callbackOptions: callbackOptions
                    },
                    callback = callbackOptions.callback;

                data = {
                    listingId: listingId,
                    submittedBy: callbackOptions.userId,
                    bidAmount: Number(bidAmount)
                };

                if(window.globalAlertView && (window.globalAlertView.backupBidAlert || window.globalAlertView.backupBidExpiryAlert)){
                    data.bidType = callbackOptions.bidType;
                }

                if (callbackOptions.isOwnitNow === 'Y') {
                    data.ownItNowFlag = true
                }

                if (self.validBidDetailsCall && jQuery.hasPendingRequest()) { 
                    return; 
                }
                self.validBidDetailsCall = jQuery.httpRequest({
                    url: contextPath + '/portal/globalalerts/validateBidDetails',
                    type: 'POST',
                    data: JSON.stringify(data),
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    success: function (responseData) {
                        responseObject.responseData = responseData;
                        callback(responseObject);
                     },
                    error: function (error) {
                        callback(responseObject)
                        GAUtils.eventTracking.trackevent("Ajax Error:" + sourcePageName, "file:placeBidView.js,function:validateBidAmount", error);
                    }
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:validateBidAmount|" + e, null, 1);
            }
        },


        /**
         * Fetches the bid detials of the specific lisiting Id 
         * @param callbackOptions json array.
         *        {currEle}      : The current target
         *        {callBackFunc} : The parent callback funtion to be called after exceution of the function
         *        {viewObject}         : The object of the view from which the function was called 
         *        {bidAmountCalVal}    : bidIncrement,bidAmount,minBidAmount,maxBidAmount,bidAmount
         * @returns viewObject Json Array 
         *        {currEle}     : current target
         *        {self}        : The parent view Object
         *        {bidAmountCalVal} : bidIncrement,bidAmount,minBidAmount,maxBidAmount,bidAmount,enableDisableAction
         */
        submitBidJson : function(callbackOptions){
            try{
                var trackText =  this.getUserDetails();
                callbackOptions.parentCallBack = callbackOptions.callBack; 
                callbackOptions.callBackFunc = this.submitBid;
                callbackOptions.parentCallBackJson =callbackOptions;
                callbackOptions.parentViewObject = this;
                this.resetAutoBidModel();
                GAUtils.eventTracking.trackevent("", sourcePageName+"- Submit Bid Json", trackText);
               // BidModel.attributes.currentBidAmountDetail.bidAmount = 97180;
                BidModel.confirm(callbackOptions.callBackFunc, callbackOptions);
            }catch(e){

                var option = {};
				option = {"component" : "Submit_Bid_UI", "failJson" : "Source Page: " +sourcePageName+" | file:placeBidView.js | function: SubmitbidJson | " + e };
				monitoringView.logUIErrors(option);
                
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:submitBidJson|" + e, null, 1);
            }
        },
        submitBidJsonAfterLogin : function(response,loginCallBackOptions){
            try{
                var self = loginCallBackOptions.parentViewObject,
                    trackText =  self.getUserDetails();
                GAUtils.eventTracking.trackevent("", sourcePageName+"- Submit Bid Json After Login", trackText);
                if(response != undefined  && response.idologyStatus && response.user.userStatus == 'ACTIVE'){
                    self.submitBidJson(loginCallBackOptions.parentCallBackOptions);
                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:submitBidJsonAfterLogin|" + e, null, 1);
            }
        },
        submitBid : function(status, message, options){
            try{
                var callBack = options.parentCallBack,
                    bidSubmitResponse = options.bidSubmitResponse,
                    currentBidStatus,
                    bidResponseData={},
                    bidOutcomeStatus,
                    loginCallBackOptions={},
                    bidSubmitOutcomeMessage,
                    self = options.parentViewObject,
                    trackText =  self.getUserDetails(),
                    statusCode =  '';

                if (bidSubmitResponse === null) {
                    bidOutcomeStatus = "EMPTY_RESPONSE";
                } else {
                    bidOutcomeStatus = bidSubmitResponse.status;
                    if(bidSubmitResponse.statusCodes != undefined && bidSubmitResponse.statusCodes != ''){
                        statusCode = bidSubmitResponse.statusCodes[0];
                    }
                }
                switch(bidOutcomeStatus) {
                    case "BID_SUCCESS": 
                        bidSubmitOutcomeMessage = placeBidContent.placeBidError.placeBidSuccess;
                        BidModel.attributes.submitBidResponse = bidSubmitResponse;
                        if (statusCode === "OUTBID" || statusCode ==='REJECTED') {
                           // errorMessage = globalAlertsCmsNode.errorMessages['AUTO_OUTBID_ERROR'] || 'Another buyer already placed a bid for the same amount or greater';
                            bidOutcomeStatus = "BID_SUCCESS_OUTBID";
                            bidSubmitOutcomeMessage = (placeBidContent.placeBidError['AUTO_'+statusCode+'_ERROR']).replace('###BID_AMOUNT###',BidModel.attributes.currentBidAmountDetail.bidAmountFormatted);
                        }else{
                            bidOutcomeStatus = "BID_SUCCESS";
                            bidSubmitOutcomeMessage = (placeBidContent.labels.placeBidSuccess).replace('###BID_AMOUNT###',BidModel.attributes.currentBidAmountDetail.bidAmountFormatted);
                        }
                        break;
                    
                    case  "ERROR" :

                        if(bidSubmitResponse.errorMessage != undefined && bidSubmitResponse.errorMessage == 'SESSION_INVALID' ){
                            bidOutcomeStatus = 'SESSION_INVALID';
                            loginCallBackOptions = {parentCallBackOptions:options.parentCallBackJson,parentViewObject:self}; 
                            $('.pdpQuickBidPopup').modal('hide');
                            loginRegisterView.openLoginPopup({
                                callback: self.submitBidJsonAfterLogin,
                                viewObject: loginCallBackOptions,
                                statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessageForPlaceBid
                            });   
                            bidResponseData = {'self':options.viewObject};
                            bidResponseData.responseStatus = 'RESPONSE_ERROR';   
                            callBack(bidResponseData); 
                            return;
                        }else{
                            bidSubmitOutcomeMessage = placeBidContent.placeBidError['UNKNOWN_ERROR'];
                        } 
                        bidSubmitOutcomeMessage = bidSubmitResponse.errorMessage;
                        bidOutcomeStatus = 'BID_FAILURE';
                        break;
                    case  "EMPTY_RESPONSE" :
                        bidSubmitOutcomeMessage = placeBidContent.placeBidError['COMMON_ERROR_MESSAGE'];
                        bidOutcomeStatus = 'VALIDATIONEMPTY_RESPONSE_ERROR';
                        break;
                    default:
                        if(placeBidContent.placeBidError[statusCode] != undefined && placeBidContent.placeBidError[statusCode] !='' && placeBidContent.placeBidError[statusCode] != null){
                            bidSubmitOutcomeMessage = (placeBidContent.placeBidError[statusCode]).replace('###BID_AMOUNT###',BidModel.attributes.currentBidAmountDetail.bidAmountFormatted).replace('###NEXT_BID_AMOUNT###',bidSubmitResponse.bidPricingInformation.nextPossibleBidFormatted).replace('###CURR_BID_AMOUNT###',bidSubmitResponse.bidPricingInformation.currentHighestBidFormatted);
                        }else{
                            bidOutcomeStatus = "UNKNOWN_ERROR";
                            bidSubmitOutcomeMessage = placeBidContent.placeBidError['UNKNOWN_ERROR'];            
                        }
                            
                }
                GAUtils.eventTracking.trackevent("", sourcePageName+" - Submit Bid Success", trackText);
                if(bidOutcomeStatus != 'SESSION_INVALID'){
                    bidResponseData = {'bidOutcomeStatus':bidOutcomeStatus,'bidSubmitOutcomeMessage':bidSubmitOutcomeMessage,'self':options.viewObject, 'bidId': BidModel.attributes.submitBidResponse.bidId,'bidResponse' :BidModel.attributes.submitBidResponse };
                    bidResponseData.responseStatus="RESPONSE_SUCCESS";
                    callBack(bidResponseData);
                }
                
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", sourcePageName, "file:placeBidView.js|function:submitBid|" + e, null, 1);

            }
        },

    });  
    return placeBidView;
});