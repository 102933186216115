
/* START_TEMPLATE */
define('hbs!tpl/loginRegister/registrationNewSkin',['hbs','hbs/handlebars', 'hbs!templates/loginRegister/registrationFooterCommon', 'hbs!templates/loginRegister/loginNewSkin', 'hbs!templates/loginRegister/forgotPasswordNewSkin','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "                        <div class=\"form-group formFieldParent   registrationPhoneConsent\" >\n                            <div class=\"col-xs-12 col-md-12 col-lg-12 required  paddingLeftRight10 inputBoxHeight\">\n                                <div class='input-box-container required'>\n                                    <span class=\"avtarImage phoneIcon glyphicon glyphicon-phone\" ></span>\n                                    <input type=\"text\" maxlength=\"10\" class=\"input-box-editor leanPhoneField leanPhoneNo avtarIcon\" name=\"lean-phoneNumber\" maxlength=\"50\"  iconName=\"phoneIcon\" autocomplete=\"new-phone\" error=\"errorLeanPhoneNo\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.phone : stack1), depth0))
    + "\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.phone : stack1), depth0))
    + "\"/>\n                                    <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.phone : stack1), depth0))
    + "</span>\n                                    <span class=\"input-box-active\"></span>\n                                    <span class=\"input-box-error\"></span>\n                                    <span class=\"input-box-success\"></span>\n                                    <div class=\"errorPH errorLeanPhoneNo\"></div> \n                                </div>\n                                \n                            </div>\n                        </div>\n                        <div class=\"form-group formFieldParent registrationPhoneConsent registrationPhoneConsentOption\">\n                            <div class=\"col-xs-12 col-md-12 col-lg-12 registrationPhoneConsent\">\n                                <div class=\"slider-switch \">\n                                    <div class=\"slider-status-text col-xs-12 col-md-12 col-lg-12\">\n                                    <input type=\"checkbox\" class=\"optInsms optInSMsRegistration\" name=\"optInSMsRegistrationNew\" aria-label=\"optInSMsRegistration\" title=\"optInSMsRegistration\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.consentLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                                    </div>\n                                </div>\n                            </div>    \n                        </div>\n                        <div class=\"registrationPhoneBlock\" style=\"display:none\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.consentTerms : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>                        \n";
},"3":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "        <div class=\"social-login-section\">\n            <div class=\"social-login-desc\">Or, sign in with your Google Account:</div>\n            <div class=\"social-login-action\" data-source=\"google\">\n                <div class=\"google-image\">\n                    <svg version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" width=\"25px\" height=\"25px\" viewBox=\"0 0 48 48\" class=\"abcRioButtonSvg\"><g><path fill=\"#EA4335\" d=\"M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z\"></path><path fill=\"#4285F4\" d=\"M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z\"></path><path fill=\"#FBBC05\" d=\"M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z\"></path><path fill=\"#34A853\" d=\"M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z\"></path><path fill=\"none\" d=\"M0 0h48v48H0z\"></path></g></svg>\n                </div>\n                <div class=\"google-btn-text\">Continue with Google</div>\n            </div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.isEnableAppleLogin : depth0), "Y", {"name":"ifEqual","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            <div class=\"social-login-terms\">By clicking on Register, you accept the Hubzu <a href=\"/about/terms\" target=\"_blank\">Terms &amp; Conditions</a> and <a href=\"https://www.altisource.com/Privacy-Policy\" target=\"_blank\" rel=\"noopener\">Privacy Policy</a></div>\n        </div>\n";
},"4":function(depth0,helpers,partials,data) {
  return "                <div class=\"apple-social-login-section\">\n                    <div class=\"social-login-desc\">Or, sign in with your Apple account:</div>\n                    <button type=\"button\" class=\"apple-social-login-action appleSignIn\" data-source=\"apple\"tabindex=\"0\" role=\"button\">\n                        <span>\n                            <svg class=\"SvgIcon appleIcon\"><svg viewBox=\"0 0 30 37\">\n                                <g fill-rule=\"nonzero\">\n                                    <path d=\"M25.086 19.558c0-2.894 1.525-5.618 4.067-7.15-1.525-2.213-4.067-3.575-6.779-3.745-2.88-.34-5.592 1.703-7.117 1.703-1.525 0-3.728-1.703-6.1-1.703-3.22.17-6.1 1.873-7.626 4.596-3.22 5.618-.847 14.129 2.373 18.725 1.525 2.213 3.389 4.766 5.761 4.766 2.373-.17 3.22-1.532 6.1-1.532 2.881 0 3.56 1.532 6.101 1.532 2.542 0 4.067-2.213 5.592-4.596 1.186-1.532 2.034-3.404 2.542-5.277-3.05-1.021-4.914-4.085-4.914-7.32z\"></path>\n                                    <path d=\"M20.194 5.92c1.385-1.692 2.078-3.721 1.905-5.92-2.079.17-4.157 1.184-5.543 2.875-1.385 1.522-2.078 3.551-1.905 5.75 2.252 0 4.157-1.015 5.543-2.706z\"></path>\n                                </g>\n                            </svg>\n                        </span>\n                        <span class=\"signInText\">Continue with Apple</span>\n                    </button>\n                </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"container-fluid loginRegisterForm  ga-login-register-container\">\n\n    <div class=\"ga-register-container\">\n        <form class=\"form-horizontal userRegisterClass userLeanRegistration\" autocomplete=\"off\" onsubmit=\"return false\">\n            <div class=\"form-group leanHeadParent\">\n                <div class=\"register-title\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.headStaticContent : depth0)) != null ? stack1.heading2 : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n                <div class=\"register-sub-txt\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.headStaticContent : depth0)) != null ? stack1.registrationTitle : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n            </div>\n            <div class=\"formFieldsParent\">\n                <div class=\"form-group \">\n                    <div class=\"col-xs-12 col-sm-12 col-md-12\">\n                        <div class=\"hide  errorAll\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.errorMessages : depth0)) != null ? stack1.errorCommonTechnical : stack1), depth0))
    + "</div>\n                    </div>\n                </div>\n                <div class=\"form-group formFieldParent container-fluid\">\n                    <div class=\"col-xs-12 col-md-6 col-lg-6 required errorLeanRegFirstNameParent paddingLeftRight10 inputBoxHeight\">\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage registerNameIcon\"></span>\n                            <input type=\"text\" class=\"input-box-editor name registerFirstName avtarIcon\" iconName=\"registerNameIcon\" name=\"lean-firstName\"\n                                maxlength=\"40\" error=\"errorLeanRegFirstName\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\" />\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH  errorLeanRegFirstName\"></div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-12 col-md-6 col-lg-6 required errorLeanRegLastNameParent paddingLeftRight10 inputBoxHeight\">\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage registerLastNameIcon\"></span>\n                            <input type=\"text\" class=\"input-box-editor name avtarIcon registerLastName\" iconName=\"registerLastNameIcon\"\n                                name=\"lean-lastName\" maxlength=\"40\" error=\"errorLeanRegLastName\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\"\n                                title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\" />\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH  errorLeanRegLastName\"></div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"form-group formFieldParent\">\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 required errorLeanRegEmailIdParent paddingLeftRight10 inputBoxHeight\">\n\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage registerEmailIdIcon\"></span>\n                            <input type=\"text\" class=\"input-box-editor avtarIcon registerEmailId\" name=\"lean-emailId\"\n                                iconName=\"registerEmailIdIcon\" maxlength=\"50\" error=\"errorLeanRegEmailId\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "\"\n                                title=\"Email\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "\"/>\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH  errorLeanRegEmailId\"></div>\n                            <div class=\"btn btn-default badge infotooltipPop popOverInfo\" data-container=\"body\"\n                                data-toggle=\"popover\" data-content=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.toolTipMessages : depth0)) != null ? stack1.emailToolTip : stack1), depth0))
    + "\"\n                                data-original-title=\"\" title=\"\">i</div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"form-group formFieldParent\">\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 required errorLeanRegPasswordParent paddingLeftRight10 inputBoxHeight\">\n\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage registerPasswordIcon\"></span>\n                            <input type=\"password\" class=\"input-box-editor avtarIcon registerPassword\" name=\"lean-password\"\n                                maxlength=\"20\" iconName=\"registerPasswordIcon\" autocomplete=\"new-password\" error=\"errorLeanRegPassword\"\n                                title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "\" />\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH errorLeanRegPassword\"></div>\n                            <div class=\"btn btn-default badge infotooltipPop popOverInfo leanPasswordToolTip lean-info-tooltip\"\n                                data-container=\"body\" data-toggle=\"popover\" data-content=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.toolTipMessages : depth0)) != null ? stack1.passWordToolTip : stack1), depth0))
    + "\"\n                                data-original-title=\"\" title=\"\">i</div>\n                        </div>\n                    </div>\n                </div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.showConsentForm : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                <div class=\"form-group submitRegisterButton\">\n\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n                        <button type=\"submit\" class=\"btn btn-primary \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.register : stack1), depth0))
    + "</button>\n                    </div>\n                </div>\n";
  stack1 = this.invokePartial(partials['templates/loginRegister/registrationFooterCommon'], '                ', 'templates/loginRegister/registrationFooterCommon', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </div>\n        </form>\n        <div class=\"leanRegTooltip\"></div>\n    </div>\n    \n    <div class=\"ga-user-login-container\">\n\n        <div class=\"ga-login-container\">\n            <div class=\"ga-head\">Sign In</div>\n";
  stack1 = this.invokePartial(partials['templates/loginRegister/loginNewSkin'], '            ', 'templates/loginRegister/loginNewSkin', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <div class=\"ga-footer\">New user? <a class=\"open-register-view\" data-view=\"register\">Register</a></div>\n        </div>\n\n        <div class=\"ga-forgot-password-container\">\n";
  stack1 = this.invokePartial(partials['templates/loginRegister/forgotPasswordNewSkin'], '            ', 'templates/loginRegister/forgotPasswordNewSkin', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n    </div>\n    \n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.socialLoginView : depth0), "Y", {"name":"ifEqual","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('tpl/loginRegister/registrationNewSkin', t);
return t;
});
/* END_TEMPLATE */
;