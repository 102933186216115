
define('pdpcommon',['jquery'], function($) {

	var bidTimeLeft = function(timeLeft)
	{
	
		this.bidLeftTime = timeLeft;
	/*	this.curDate = new Date();
		this.curTime = this.curDate.getTime();*/
		this.days = 0;
		this.hours = 0;
		this.mins = 0;
		this.secs = 0;
		this.expired = false;
		this.daysClass = '';
		this.hoursClass = '';
		this.minsClass = '';
		this.secsClass = '';
		this.timeClass = '';
		this.time15Class = '';
		this.context = '';
		
		this.before = new Date();
		var that = this;
		
		//callback function
		this.onexpired = '';
		
		
		this.init = function(){

			$('.' + that.timeClass).removeClass(that.time15Class);
			$('.last-15-min-rule').hide();
			
			that.interval = setInterval(function(){that.bidLeft()},1000);
			
		}

		this.singularChange = function(time,label)
		{
			if( $('.bid-' + label + '-label').length > 0 ){
				var timelabel = $('.bid-' + label + '-label').html();
				var timeSposition = timelabel.lastIndexOf('s');

				if(time==1 && timeSposition>1)
				{
					timelabel = timelabel.substring(0, timelabel.length - 1);
					$('.bid-' + label + '-label').html(timelabel);
				}
				else if(time!=1 && timeSposition<1)
				{
					$('.bid-' + label + '-label').html(timelabel + 's');
				}
			}
		}
	
		this.bidLeft = function()
		{
			
			that.bidLeftTime = that.bidLeftTime - 1;

		    that.before = new Date();

			if(that.bidLeftTime<900)
			{
				$('.' + that.timeClass).addClass(that.time15Class);
				$('.last-15-min-rule').show();
			}

			that.secs = String(that.bidLeftTime % 60);
			that.mins = String((Math.floor(that.bidLeftTime/60)) % 60);
			that.hours = String((Math.floor(that.bidLeftTime/60/60)) % 24);
			that.days = String((Math.floor(that.bidLeftTime/60/60/24)));
			
			var highestClass = '';
			
			if(that.secs<=0)
			{
				that.secs = 0;
			}
			else
			{
				highestClass = that.secsClass;
			}
			
			if(that.mins<=0)
			{
				that.mins = 0;
			}
			else
			{
				highestClass = that.minsClass;
			}
			
			if(that.hours<=0)
			{
				that.hours = 0;
			}
			else
			{
				highestClass = that.hoursClass;
			}
			
			if(that.days<=0)
			{
				that.days = 0;
			}
			else
			{
				highestClass = that.daysClass;
			}

			that.singularChange(that.secs,'sec');
			that.singularChange(that.mins,'min');
			that.singularChange(that.hours,'hour');
			that.singularChange(that.days,'day');
			
			that.secs = String(that.secs);
			that.mins = String(that.mins);
			that.hours = String(that.hours);
			that.days = String(that.days);
			
			if(that.secs.length==1)
			{
				that.secs = '0' + that.secs;
			}
			
			if(that.mins.length==1)
			{
				that.mins = '0' + that.mins;
			}
			
			if(that.hours.length==1)
			{
				that.hours = '0' + that.hours;
			}
			
			if(that.days.length==1)
			{
				that.days = '0' + that.days;
			}
			
			if(that.daysClass!=''){
				$('.' + that.daysClass).html(that.days);
			}
			if(that.hoursClass!=''){
				$('.' + that.hoursClass).html(that.hours);	
			}
			
			if(that.minsClass!=''){
				$('.' + that.minsClass).html(that.mins);
			}
			
			if(that.secsClass!=''){
				$('.' + that.secsClass).html(that.secs);	
			}
			
			
			
			$('.highest-time').removeClass('highest-time');
			
			if(highestClass!='')
			{
				$('.' + highestClass).parent().addClass('highest-time');
				if($('.time-left-value').length>0){
					
					var dateValue = "";
					if(highestClass == "bid-days"){
						dateValue = " day";
						if(that.days != 1){
							dateValue = dateValue+"s";
						}
						$('.time-left-value').html(that.days + dateValue);	
					}else if(highestClass == "bid-hours"){
						dateValue = " hour";
						if(that.hours != 1){
							dateValue = dateValue+"s";
						}
						$('.time-left-value').html(that.hours + dateValue);	
					}
					else if(highestClass == "bid-mins"){
						dateValue = " min";
						if(that.mins != 1){
							dateValue = dateValue+"s";
						}
						$('.time-left-value').html(that.mins + dateValue);	
					}
					else if(highestClass == "bid-secs"){
						dateValue = " sec";
						if(that.secs != 1){
							dateValue = dateValue+"s";
						}
						$('.time-left-value').html(that.secs + dateValue);	
					}
				}
				
			}
			
			
			if(that.days==0 && that.hours==0 && that.mins==0 && that.secs==0)
			{
				that.expired = true;
				if(that.onexpired!='')
				{
					if(that.context!='')
					{
						that.onexpired.call(that.context,that);
					}
					else
					{
						that.onexpired(that);
					}
				}
				
				clearInterval(that.interval);
			}
			
	
		}
		
	}

	return bidTimeLeft;

});