
define('licenseView',['require','handlebars','jquery','underscore','backbone','GoogleAnalyticsUtils','validator','commonutils','dUtil','loginRegisterView','hbs!tpl/globalAlerts/loaderIcon'],function (require) {
    'use strict';

    var Handlebars = require("handlebars");

    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone"),
        GAUtils = require("GoogleAnalyticsUtils"),
        validator = require("validator"),
        Utils = require("commonutils"),
        dUtil = require("dUtil"),
        loginRegisterView = require('loginRegisterView'),
        loaderIcon = require("hbs!tpl/globalAlerts/loaderIcon");

    var licenseView = Backbone.View.extend({

        events: {
            "blur #licenseStateId, #agentLicenseStateId": "validateLicenseState",
            "change #licenseStateId, #agentLicenseStateId": "clearCityValue",
            'blur #affiliatedCompany' : 'validateAffliateCompany',
            'blur #licenceNumber' : 'validateLicenseNumber',
            'blur #licenceExpiryDate, .ui-datepicker': 'validateExpiryDate',
            'click #updateLicense' : 'saveLicenseDetails',
            "blur #agentAddress1" : "validateStreetNumber",
            "blur #agentAddress2" : "validateStreetName",
            "blur #agentCity" : "validateCity",
            "keypress #agentZipCode" : "validatePhoneInput",
            "blur #agentZipCode" : "validatePostalCode", 
        },

        initialize: function () {
            
        },
        validatePhoneInput: function(event) {
            return this.parentView.validatePhoneInput(event);
        },  
        validateStreetNumber: function (event) {
            try {

                var obj = '#' + event.target.id;

                if (!(validator.streetNumber($.trim($(obj).val()), obj))) {
                    $('.' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.addressRequired).removeClass('hide');
                    $(obj).addClass('inputError');
                } else {
                    $('.' + $(obj).attr('error')).addClass('hide');
                    $(obj).removeClass('inputError');

                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts License Details", "file:licenseView.js|function:validateStreetNumber|" + event, null, 1);
            }
        },
        validateStreetName: function (event) {
            try {
                var obj = '#' + event.target.id;
                if (!(validator.streetName($.trim($(obj).val()), obj))) {
                    $('.' + $(obj).attr('error')).removeClass('hide');
                    $(obj).addClass('inputError');

                } else {
                    $('.' + $(obj).attr('error')).addClass('hide');
                    $(obj).removeClass('inputError');

                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts License Details", "file:licenseView.js|function:validateStreetName|" + event, null, 1);
            }
        },
        validateCity: function (event) {
            try {
                var obj = '#' + event.target.id;
                if ($(obj).parents('.required').length > 0 && $.trim($(obj).val()) === '') {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.cityRequired);
                    $(obj).addClass('inputError');
                } else if (!(validator.isValidCity($.trim($(obj).val()), obj))) {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.cityAllowedChars);
                    $(obj).addClass('inputError');
                } else {
                    $('.' + $(obj).attr('error')).addClass('hide');
                    $(obj).removeClass('inputError');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts License Details", "file:licenseView.js|function:validateCity|" + event, null, 1);
            }
        },
        validatePostalCode: function (event) {
            try {
                var postalCode = '#' + event.target.id;

                if ($.trim($(postalCode).val()) == '' && $(postalCode).parent().parent().hasClass('required')) {
                    $('.' + $(postalCode).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.zipCodeRequired);
                    $(postalCode).addClass('inputError');
                } else if (!(validator.zip($.trim($(postalCode).val()), postalCode))) {
                    $('.' + $(postalCode).attr('error')).removeClass('hide').html(dashboardContent.myProfilePageContent.errorMsgCMS.errorPostalCode);
                    $(postalCode).addClass('inputError');
                } else if (($.trim($(postalCode).val()).length != 5 && $.trim($(postalCode).val()).length > 0) || (parseInt($.trim($(postalCode).val())) < 1)) {
                    $('.' + $(postalCode).attr('error')).removeClass('hide').html(dashboardContent.myProfilePageContent.errorMsgCMS.errorPostalCode);
                    $(postalCode).addClass('inputError');
                } else {
                    $('.' + $(postalCode).attr('error')).addClass('hide').html(dashboardContent.myProfilePageContent.errorMsgCMS.errorPostalCode);
                    $(postalCode).removeClass('inputError');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts License Details", "file:licenseView.js|function:validatePostalCode|" + event, null, 1);
            }
        },
        saveLicenseDetails: function(event) {

            var self = this;

            self.$el.find('#affiliatedCompany').blur();
            self.$el.find('#licenseStateId').blur();
            self.$el.find('#licenceNumber').blur();
            self.$el.find('#licenceExpiryDate').blur();

            $('.agentLicenseInfo').each(function () {
                $(this).filter(':visible').blur();
                if (this.id == "agentLicenseId") {
                    this.validateLicenseState(event);
                }
            });

            if($('#editLicense input.inputError').length > 0 || $('#editLicense select.inputError').length > 0) { 
                $(window).scrollTop(self.$el.find('#editLicense .inputError').first().focus());
                return false;
            }else{
                var saveLicenseData = {},
                    citySelected = self.$el.find('#agentCity').attr('data-city-from-dropdown') === 'Y' ? true : false;

                self.$el.find('.agentLicenseInfo').each(function () {
                    if ($(this).attr('id') != undefined && $.trim($(this).val()) != $.trim($(this).attr('placeholder'))) {
                        saveLicenseData[$(this).attr('name')] = $.trim($(this).val());
                    }
                });
                saveLicenseData.affiliatedCompany = self.$el.find('#affiliatedCompany').val();
                saveLicenseData.licenceNumber = self.$el.find('#licenceNumber').val();
                saveLicenseData.stateId = self.$el.find('#licenseStateId').val();
                saveLicenseData.agentLicenseState = self.$el.find('#agentLicenseStateId').val();
                saveLicenseData.licenceExpiryDate = self.$el.find('#licenceExpiryDate').val();
                saveLicenseData.cityFromDropDown = citySelected;

                if (this.parentView.licenseAction == 'edit') {
                    saveLicenseData.primaryAgentLicenceId = self.$el.find('#primaryAgentLicenceId').val();
                }
                var callBackParam = {
                    inputData: saveLicenseData,
                    self: this
                };
                var validateLicenseData = {};
                validateLicenseData['licenseNumber'] = self.$el.find('#licenceNumber').val();
                validateLicenseData['stateId'] = self.$el.find('#licenseStateId').val();
                var validateLicenceUrl = contextPathPortal + '/verifyLicenseDetails';
                
                self.$el.find("#updateLicense").addClass('btn-disable');
                self.$el.find("#updateLicense").append(loaderIcon());
                self.$el.find("#updateLicense").css('pointer-events', 'none')

                $.ajax({
                    type: "GET",
                    url: validateLicenceUrl,
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    data: validateLicenseData,
                    success: function (data) {

                        if (data.verificationStatus == "LICENSE_FORMAT_NOT_OK" && callBackParam.self.parentView.licenseType == 'addLicense') {
                            self.$el.find('.licenseValidationResponse').html(dashboardContent.myProfilePageContent.errorMsgCMS.licenseFormatError[data.jurisdictName]);
                            self.$el.find('.licenseValidationResponse').removeClass('hide');

                            self.$el.find("#updateLicense").removeClass('btn-disable');
                            self.$el.find("#updateLicense .lds-spinner").remove();
                            self.$el.find("#updateLicense").css('pointer-events', 'all');

                            self.$el.find('.modal').animate({
                                scrollTop: '0px'
                            }, 500);

                        } else {
                            self.updateLicenseDetails({}, callBackParam);
                        }
                    }
                });
            }
        },
        updateLicenseDetails: function (userResponse, callBackParam) {
            if (callBackParam.self.parentView.licenseType == 'addLicense') {
                if (callBackParam.self.parentView.licenseAction == 'edit') {
                    var url = contextPathPortal + '/user/updateLicense';
                } else {
                    var url = contextPathPortal + '/user/insertLicense';
                }
            } else {
                if (callBackParam.self.parentView.licenseAction == 'edit') {
                    var url = contextPathPortal + '/lean-regn/updateCorpLicense';
                } else {
                    var url = contextPathPortal + '/lean-regn/insertCorpLicense';
                }
            }
            dUtil.jqueryPost(url, callBackParam.inputData, callBackParam.self.updateEditLicenseCallback, callBackParam.self);
        },
        updateEditLicenseCallback: function (outData, inputData, self) {

            var message = '',
                parent;

            self.$el.find("#updateLicense").removeClass('btn-disable');
            self.$el.find("#updateLicense .lds-spinner").remove();
            self.$el.find("#updateLicense").css('pointer-events', 'all');

            if (outData.status == 'SUCCESS') {

                $('#editProfileLicenseModal').modal("hide");
                self.parentView.refreshLicenseList({viewObject: self.parentView});
                
                if (self.parentView.licenseAction == 'edit') {
                    GAUtils.eventTracking.trackevent("Profile Forms Global Alerts", "Update Button Click Success", window.userSession.userId + " | " + inputData.primaryAgentLicenceId + "|Agent");
                    message = dashboardContent.myProfilePageContent.confirmUpdateLicense.successMessageEdit;
                } else {
                    GAUtils.eventTracking.trackevent("Profile Forms Global Alerts", "Add Button Click Success", window.userSession.userId + "|Agent");
                    message = dashboardContent.myProfilePageContent.confirmUpdateLicense.successMessageAdd;
                }

                parent = self.parentView.licenseType == 'addLicense' ? '.agent-license-list' : '.agent-corp-license-list'
                $(parent).find('.common-license-message').removeClass('hide').addClass('successMessageStyle').html(message);    
                self.parentView.hideErrorMessage({element: $(parent).find('.common-license-message'), time: 5000});

                if (window.location.href.indexOf('/my-shopper') > -1) {
                    window.location.reload();
                }

            } else if (outData.isLoggedOut || (outData.status === 'FAILURE' && outData.errorMessage === 'SESSION_REQUIRED') ) {
                
                $('#editProfileLicenseModal').modal("hide");
                var callBackParam = {
                    "inputData": inputData,
                    "self": self
                };

                loginRegisterView.openLoginPopup({
                    callback: self.updateLicenseDetails,
                    viewObject: callBackParam,
                    statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                });

            } else {
                
                if (self.parentView.licenseType == 'addLicense') {
                    var errorMessage = outData.ERROR;
                } else {
                    var errorMessage = outData.errorMessage;
                }

                if (errorMessage === 'INVALID_MANDATORY_PARAMETER_VALUE' ||
                    errorMessage === 'VALIDATION_STATE_ZIP_FAILURE' ||
                    errorMessage === 'REGISTRATION_CITY_FAILURE' ||
                    errorMessage === 'VALIDATION_STATE_CITY_FAILURE' ||
                    errorMessage === 'AGENT_LICENSE_ALREADY_EXIST' ||
                    errorMessage === 'STATE_LICENSE_ALREADY_EXIST') {


                    errorMessage = dashboardContent.myProfilePageContent.errorMsgCMS.profileUpdateErrors[errorMessage] ||
                        dashboardContent.myProfilePageContent.errorMsgCMS.genericError;

                    if (self.$el.find('.licenseValidationResponse')) {
                        self.$el.find('.licenseValidationResponse').html(errorMessage);
                        self.$el.find('.licenseValidationResponse').removeClass('hide');
                        self.$el.find('.modal').animate({
                            scrollTop: '0px'
                        }, 500);
                    } else {
                        $('#editProfileLicenseModal').modal("hide");
                        var parent = self.parentView.licenseType == 'addLicense' ? '.agent-license-list' : '.agent-corp-license-list'
                        $(parent).find('.common-license-message').removeClass('hide').addClass('errorMessageStyle').html(errorMessage);    
                        self.parentView.hideErrorMessage({element: $(parent).find('.common-license-message'), time: 5000});
                    }

                } else {

                    $('#editProfileLicenseModal').modal("hide");
                    var parent = self.parentView.licenseType == 'addLicense' ? '.agent-license-list' : '.agent-corp-license-list'
                    $(parent).find('.common-license-message').removeClass('hide').addClass('errorMessageStyle').html(errorMessage);    
                    self.parentView.hideErrorMessage({element: $(parent).find('.common-license-message'), time: 5000});
                }

            }
            
        },
        clearCityValue: function(event){

            var obj = '#' + event.target.id,
            stateCode = $.trim($(obj).val());

            if(obj === '#agentLicenseStateId'){
                this.parentView.fetchCities({
                    stateCode: stateCode,
                    sourceObj: obj
                });                    
            }
            
            this.validateLicenseState(event);

            if(obj === '#agentLicenseStateId'){
                $('#editLicense #agentCity').val('');
            }
        },
        validateLicenseState: function (event) {
            try {
                var obj = '#' + event.target.id,
                    stateCode = $.trim($(obj).val());

                if ($(obj).parents('.required').length > 0) {
                    if ($(obj).val() == undefined || $(obj).val() == '' || $(obj).val() == 'select') {
                        $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.stateRequired);
                        $(obj).addClass('inputError');
                    } else {
                        $('.' + $(obj).attr('error')).addClass('hide');
                        $(obj).removeClass('inputError');
                    }
                } else {
                    $('.' + $(obj).attr('error')).addClass('hide');
                    $(obj).removeClass('inputError');
                }

                if (event.target.id == 'licenseStateId' && $(obj).val() != '') {
                    if ($.inArray($(obj).val(), this.parentView.mandatoryStateArray) !== -1) {
                        $('.agentLicenseInfo').parent().parent().addClass('required');
                        $('#agentAddress2').parent().parent().removeClass('required');
                    } else {
                        $('.agentLicenseInfo').parent().parent().removeClass('required');
                        $('.agentLicenseInfo').removeClass('inputError');
                        $('.agentLicenseInfo').parent().parent().find('.errorPH').addClass('hide');
                    }
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts License Details", "file:licenseView.js|function:validateLicenseState|" + e, null, 1);
            }
        },

        dateDifference: function (schdate) {
            var currentDate = new Date();
            var day = currentDate.getDate();
            var month = currentDate.getMonth() + 1;
            var year = currentDate.getFullYear();

            var today = month + "/" + day + "/" + year;

            var date1 = new Date(today);
            var date2 = new Date(schdate);

            var one_day = 1000 * 60 * 60 * 24
            var days = Math.ceil((date2.getTime() - date1.getTime()) / (one_day));
            return days;
        },
        /**
         * 
         * @param {*} event 
         */
        validateAffliateCompany: function (event) {
            try {
                var obj = '#' + event.target.id,
                    value = $.trim($(obj).val());

                if (value === '') {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.issuerRequired);
                    $(obj).addClass('inputError');
                } else if (!(validator.companyName($.trim($(obj).val()), obj))) {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.issuerAllowedChars);
                    $(obj).addClass('inputError');
                } else {
                    $('.' + $(obj).attr('error')).addClass('hide');
                    $(obj).removeClass('inputError');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:licenseView.js|function:validateAffliateCompany|" + e, null, 1);
            }
        },
        validateLicenseNumber: function (event, obj) {
            try {
                var obj = '#' + event.target.id;

                this.parentView.removeUnicodes(obj);
                if ($.trim($(obj).val()) === '') {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.licenseRequired);
                    $(obj).addClass('inputError');
                } else if (!(validator.licence($.trim($(obj).val()), obj))) {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.licenseAllowedChars);
                    $(obj).addClass('inputError');
                } else {
                    $('.' + $(obj).attr('error')).addClass('hide');
                    $(obj).removeClass('inputError');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:licenseView.js|function:validateLicenseNumber|" + e, null, 1);
            }
        },
        validateExpiryDate: function (event) {
            try {

                var obj = '#' + event.target.id

                if ($(obj).val() == '' || $('#licenceExpiryDate').val() == undefined || this.dateDifference($('#licenceExpiryDate').val()) < 0) {
                    $('#licenceExpiryDate').addClass('inputError');
                    $('.errorLicenseExpiry').removeClass('hide').html(saveListDrawerContent.errorMessages.expiryDateRequired);
                } else {
                    $('#licenceExpiryDate').removeClass('inputError');
                    $('.errorLicenseExpiry').addClass('hide');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:licenseView.js|function:validateExpiryDate|" + e, null, 1);
            }
        },

        /**
         * Enables date picker for given element
         * @param {*} options 
         */
        attachDatePicker: function(options) {
            try {
                options = options || {};
                var element = options.element || '';

                if(!element) return;

                $(element).datepicker({
                    changeYear: true,
                    changeMonth: true,
                    minDate: 1,
                    defaultDate: new Date(),
                    beforeShow: function (input, inst) {
                        $(document).off('focusin.bs.modal');
                    },
                    onClose: function () {
                        $(document).on('focusin.bs.modal');
                        $(this).blur();
                    },
                }).keydown(function (e) {
                    e.preventDefault();
                    var code = e.keyCode || e.which;
                    // And arrow keys used "for performance on other keys"
                    if (code == '37' || code == '38' || code == '39' || code == '40') {
                        // Get current date
                        var parts = $(this).val().split("/");
                        var currentDate = new Date(parts[2], parts[0] - 1, parts[1]);
                        // Show next/previous day/week 
                        switch (code) {
                            // LEFT, -1 day
                            case 37:
                                currentDate.setDate(currentDate.getDate() - 1);
                                break;
                                // UP, -1 week
                            case 38:
                                currentDate.setDate(currentDate.getDate() - 7);
                                break;
                                // RIGHT, +1 day
                            case 39:
                                currentDate.setDate(currentDate.getDate() + 1);
                                break;
                                // DOWN, +1 week
                            case 40:
                                currentDate.setDate(currentDate.getDate() + 7);
                                break;
                        }
                        // If result is ok then write it
                        if (currentDate != null) {
                            $(this).datepicker("setDate", currentDate);
                        }
                    } else {
                        if (code == '9' || code == '13') {
                            $(this).datepicker("hide");
                            $(this).blur();
                        } else {
                            return false;
                        }

                    } // If other keys pressed.. return false

                });
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:licenseView.js|function:attachDatePicker|" + exception, null, 1);
            }
        },

         /**
         * Fucntion: Render 
         * @param {*} options 
         */
        render: function (options) {
            try {
                options = options || {};

                return true;

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:licenseView.js|function:render|" + e, null, 1);
            }
        }
    });
    return new licenseView;
});