
define('bidModel',['underscore','backbone','bidUpdater','GoogleAnalyticsUtils','monitoringView','hbs!tpl/globalAlerts/fraudulentErrorModal' 
,'util'], function (_,Backbone,bidUpdater,GAUtils, monitoringView,fraudulentErrorModalTemplate,util) {
   'use strict';

    var bidDefaults = {
         "listingId": "",
         "preBidCifId" : "",
         "listingPrice" : "",
         "bidQuestionariesEnabled": 'N',
         "isAgent": false,
         "isCCValidation" : true,
         "repeatAutoBidNotknow" : 0,
         "currentBidAmountDetail": {
           "id": null,
           "value": null,
           "bidAmount": null,
           "bidAmountFormatted": "",
           "technologyFeeFormatted": "",
           "buyerPremiumFormatted": "",
           "totalDueFormatted": "",
           "percentageFormatted": "",
           "earnestMoneyFormatted": "",
           "earnestMoneyMessage": ""
         },
         "widgetState": {
          "isAutoBid" : true,
          "isBackupBid" : true,
          "showCHB" : true,
          "showContingencies" : true,
          "showEarnestMoney" : true,
          "showClosingDays" : true,
          "showBidDeposit" : true,
          "showPOF" : true,
          "previousPOF" : false,
          "backupDays" : 0,
          "isBackupBidSelected" : false,
          "isBackupBidDisabled" : false,
          "disableFinancingContingency" : false,
          "isAgent" : false,
          "extendTime" : false,
          "statusCodes" : '',
          "dashboardUrl" : '',
          "auctionExpired" : false,
          "thumbnailUrl" : '/www/images/no-photo.jpg',
          "contextPath" : '',
          "pdpUrl" : '',
          "isPofEnable" : false,
          "disableUserRole" : false
         },
         "licenceInfo" : {},
         "bidContigencyMasterList" : [],
         "bidContigencyBuyerChosen" : [],
         "bidDetail": {
           "id": null,
           "value": null,
           "previousBidId": null,
           "buyerEmailId": "",
           "previousBidAmountFormatted": null,
           "isBackupBidSelected": false,
           "backupBidDaysSelected": 15,
           "propertyTouredSelected": "Y",
           "isAutoBidSelected": false,
           "isLibertySelected" : false,
           "intendToOccupy": null,
           "holdActiveLicense": null,
           "autoBidAmountDetail": {
             "id": null,
             "value": null,
             "bidAmount": null,
             "bidAmountFormatted": "",
             "technologyFeeFormatted": "",
             "buyerPremiumFormatted": "",
             "totalDueFormatted": "",
             "percentageFormatted": "",
             "earnestMoneyFormatted": "",
             "earnestMoneyMessage": ""
           },
           "finacingAndClosingDetail": {
             "id": null,
             "value": null,
             "paymentTypeSelected": [
               "MORTGAGE"
             ],
             "paymentOptionsSelected": [
               "FHA"
             ],
             "loanAmount": null,
             "loanAmountPercentage": null,
             "closingOptionDetailSelected": {
               "closingOption": "SELLER_PAYS",
               "closingOptionLabel": null,
               "isClosingAgentRequired": null,
               "isTitleInsuranceProviderRequired": null,
               "closingAgentDetail": null,
               "titleInsuranceProviderDetail": null
             },
             "closingDaysSelected": 40,
             "loanPreApprovalDetail": {
               "id": null,
               "value": null,
               "isPreApprovedForFinance": false,
               "institutionName": "",
               "preApprovedAmount": 0,
               "preApprovedAmountFormatted": null,
               "preApprovalVendorKeySelected": null
             }
           }
         },
         "bidUserDetail": {
           "id": null,
           "value": null,
           "isRepresentedByAgent":null,
           "buyerDetail": {
             "id": null,
             "value": null,
             "firstName": "",
             "lastName": "",
             "emailId": "",
             "phone": ""
           },
           "agentDetail": {
             "id": null,
             "value": null,
             "firstName": "",
             "lastName": "",
             "emailId": "",
             "phone": "",
             "brokerageName": "",
             "licenceNumber": "",
             "state": "",
             "licenseStatus": null,
             "licenseExpirationDate": null
           }
         },
         "visitorInfo": null,
         "agentOptionalProgramEnabled":false
    };

    var offerDefaults = {
         "widgetState": {
          "sellerConcessionCount" : 0,
          "sellerConcessionNextRow":0,
          "showContingencies" : true,
          "selectedSellerConcessionTypes" :[],
          "showPOF" : true,
          "disableFinancingContingency" : false,
          "isAgent" : false,
          "thumbnailUrl" : '/www/images/no-photo.jpg',
          "contextPath" : '',
          "pdpUrl" : '',
          "isPofEnable" : false,
          "disableUserRole" : false
         },
         "licenceInfo" : {},
         "bidQuestionariesEnabled": '',
         "bidContigencyMasterList" : [],
         "bidContigencyBuyerChosen" : [],
         "bidDetail": {
           "id": null,
           "value": null,
           "previousBidId": null,
           "buyerEmailId": "",
           "previousBidAmountFormatted": null,
           "isBackupOfferSelected" : false,
           "backupOfferDaysSelected": 15,
           "propertyTouredSelected": "Y",
           "isLibertySelected" : false,
           "intendToOccupy": null,
           "holdActiveLicense": null,
           "finacingAndClosingDetail": {
             "id": null,
             "value": null,
             "paymentTypeSelected": [
               "MORTGAGE"
             ],
             "paymentOptionsSelected": [
               "FHA"
             ],
             "loanAmount": null,
             "loanAmountPercentage": null,
             "closingOptionDetailSelected": {
               "closingOption": "BUYER_PAYS",
               "closingOptionLabel": null,
               "isClosingAgentRequired": null,
               "isTitleInsuranceProviderRequired": null,
               "closingAgentDetail": null,
               "titleInsuranceProviderDetail": null
             },
             "closingDaysSelected": 40,
             "loanPreApprovalDetail": {
               "id": null,
               "value": null,
               "isPreApprovedForFinance": false,
               "institutionName": "",
               "preApprovedAmount": 0,
               "preApprovedAmountFormatted": null,
               "preApprovalVendorKeySelected": null
             }
           }
        },
        "bidUserDetail": {
           "id": null,
           "value": null,
           "isRepresentedByAgent":null,
           "buyerDetail": {
             "id": null,
             "value": null,
             "firstName": "",
             "lastName": "",
             "emailId": "",
             "phone": ""
           },
           "agentDetail": {
             "id": null,
             "value": null,
             "firstName": "",
             "lastName": "",
             "emailId": "",
             "phone": "",
             "brokerageName": "",
             "licenceNumber": "",
             "state": "",
             "licenseStatus": null,
             "licenseExpirationDate": null
           }
         },
         "sellerConcession" : [],
         "sellerParameters" : {
          "allowedMaximumClosingCost" : 100,
          "allowedMaximumRepairCost" : 200
         },
         "agentOptionalProgramEnabled": false
    };

    var BidModel = Backbone.Model.extend({
        //defaults is superset of all attributes, 
        //but only items necessary to submit the bid/offer are taken to 'BidSubmissionModel' / 'OfferSubmissionModel'
        initialize : function(){
          this.isMobile = $(window).width() < 768 ? true : false;
        },
        //bidPageJSON OR offerPageJSON service gives the initial larger BidModel
        confirmOffer : function (callback) {
            try{
	        	var offerSubmissionModel = {};
	
	            // @ todo:
	            // is it not good to do a test case and check if all defaults are 'done ???'
	            offerSubmissionModel.listingId = this.get("listingId");
	            if($("input[name='role']:checked").val() === 'BUYER'){
	              offerSubmissionModel.isAgent   = false;
	            }else{
	              offerSubmissionModel.isAgent   = true;
              }
              if(BidModel.attributes.agentOptionalProgramEnabled  !== undefined && BidModel.attributes.agentOptionalProgramEnabled ){
                var isRepresentedByAgent = _.escape($('#isRepresentedByAgent').val());
                if(isAgent){
                  if(isRepresentedByAgent == 'Y') {
                    offerSubmissionModel.isAgent = false;
                  } else {
                    offerSubmissionModel.isAgent = true;
                  }
                }else{
                  offerSubmissionModel.isAgent = false;
                }
              }
              
              	var offerPricingInformation = BidModel.attributes.offerPricingInformation;
	            offerSubmissionModel.currentOfferAmountDetail = Object.assign({}, offerPricingInformation);
              	offerSubmissionModel.currentOfferAmountDetail.earnestMoneyMessage = "";
	            offerSubmissionModel.currentOfferAmountDetail.totalSellerContribution = BidModel.get("totalSellerContribution");
	            offerSubmissionModel.offerDetail = this.get("bidDetail");
	            // for liberty program
	            if($("#chkLegalLiberty").prop('checked') != true){
	                offerSubmissionModel.offerDetail.isLibertySelected   = false;
                }
	            else{
	                offerSubmissionModel.offerDetail.isLibertySelected   = true;
	            }
	            //@todo: clarify need in wrapper
	            offerSubmissionModel.propertyId  = BidModel.attributes.propertyId;
	            offerSubmissionModel.listingStatus = BidModel.attributes.listingStatus;
	            offerSubmissionModel.listingStartDate  = BidModel.attributes.listingStartDate;
	            offerSubmissionModel.listingEndDate  =BidModel.attributes.listingEndDate;
	
	            //@todo clarify
	            offerSubmissionModel.offerDetail.userId = BidModel.attributes.userDetails.userInfo.userId;
	            offerSubmissionModel.offerDetail.submittedByUserId = offerSubmissionModel.offerDetail.userId;
              if($('.buyerPremiumCash').is(':visible')){
                  offerSubmissionModel.offerDetail.finacingAndClosingDetail.isBPFinanced=false;
              }else if($('.buyerPremiumFinance').is(':visible')){
                if($("input[name='buyerPremiumOption']:checked").val()!=undefined && $("input[name='buyerPremiumOption']:checked").val()!=''){
                  if($("input[name='buyerPremiumOption']:checked").val()=='Y'){
                    offerSubmissionModel.offerDetail.finacingAndClosingDetail.isBPFinanced=true;
                  }else{
                    offerSubmissionModel.offerDetail.finacingAndClosingDetail.isBPFinanced=false;
                  }
                }
              }
	            
	            offerSubmissionModel.sellerConcession =  BidModel.get("sellerConcession");
	
	            if(this.get("tempBidUserDetail")){
	              this.set("offerUserDetail",this.get("tempBidUserDetail"));
	            } else {
	              this.set("offerUserDetail",this.get("bidUserDetail"));
	            }
	
	            offerSubmissionModel.offerUserDetail = this.get("offerUserDetail");
	            //date format fix for core db
	            if (offerSubmissionModel.offerUserDetail.agentDetail !== null
	              && offerSubmissionModel.offerUserDetail.agentDetail.licenseExpirationDate !== null ) {
	              offerSubmissionModel.offerUserDetail.agentDetail.licenseExpirationDateString = $("#agentLicenseExpiry").val();
	            }
	
	            offerSubmissionModel.bidContigencyMasterList = this.get("bidContigencyMasterList");
	            offerSubmissionModel.bidContigencyBuyerChosen = this.get("bidContigencyBuyerChosen");
	            
	            offerSubmissionModel.visitorInfo      =  this.get("visitorInfo");
	            //pdp pass,populate checkbox
	            offerSubmissionModel.offerType        =  this.get("offerType");
	            
	            //taking email id as ?required? in server side dto for validation
	            //debug tip:deactivate this line to introduce an error scenario
	            //@todo: clarify if required for offer
	            offerSubmissionModel.offerDetail.buyerEmailId = offerSubmissionModel.offerUserDetail.buyerDetail.emailId;
	
	            //@todo Delete this line of code after UMS Integration
	            offerSubmissionModel.userId = this.get("userId");
	            
	            offerSubmissionModel.afBaAccepted =  $('#afBANotice').is(":visible") && $('#afBANotice').is(':checked');
              offerSubmissionModel.cifId = '';
              if(typeof cifWizardView != 'undefined' && typeof cifWizardView != undefined && cifWizardView.cifResponse != undefined && cifWizardView.cifResponse.cifId != undefined && cifWizardView.cifResponse.cifId !=''){
                offerSubmissionModel.cifId = cifWizardView.cifResponse.cifId;
              }
	            var offerSubmitOutcomeMessage = "";
	            var offerOutcomeStatus = "";
	            var url = contextPath+ "/submitOffer/complete";
	
	            try {
	                jQuery.ajax ({
	                    context: this,
	                    url: url,
	                    type: "POST",
	                    data: JSON.stringify(offerSubmissionModel),
	                    dataType: "json",
	                    contentType: "application/json; charset=utf-8",
	                    success: function(response){
	                        if (response === null) {
	                            offerOutcomeStatus = "EMPTY_RESPONSE";
	                        } else {
	                          offerOutcomeStatus = response.status;
	                        }
	
	                        //@todo get messaging, including worse case scenarios
	
	                        switch(offerOutcomeStatus) {
	                            case "OFFER_SUCCESS": 
	                                offerSubmitOutcomeMessage = "Offer Placed Successfully!";
	                                this.attributes.offerPlaceTime = response.offerPlaceTime;
	                                this.attributes.offerPricingInformation = response.offerPricingInformation;
	                                this.attributes.listingOfferPriceDetails = response.listingOfferPriceDetails;
	                                this.attributes.offerId = response.offerId;
	                                break;
	                            case "OFFER_UNSUCCESS":
	                                offerSubmitOutcomeMessage = "Oops! There was a technical issue in placing your offer!. The issue is notified, we are working hard to fix as soon as possible. Please try again.";
	                                break;
	                            case  "EMPTY_RESPONSE" :
	                                offerSubmitOutcomeMessage = "Empty response from server for submitOffer/complete";
	                                break;
	                            case  "ERROR" :
	                                offerSubmitOutcomeMessage = response.errorMessage;
	                                break;
	                            case  "VALIDATION_ERROR" :
	                            	offerSubmitOutcomeMessage = response.errorMessage;
	                                break;
	                            default:
	                                offerOutcomeStatus = "UNKNOWN_ERROR";
	                                offerSubmitOutcomeMessage = "Server returned an unrecognized response"
	                        }
	                        callback(offerOutcomeStatus,offerSubmitOutcomeMessage);
	                    }
	                });
	            } catch(err) {
	              offerOutcomeStatus = "UNKNOWN_EXCEPTION";
	              offerSubmitOutcomeMessage = "Unknown Exception while calling submitOffer/complete !";
	              GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bid-model.js|function:confirmOffer-ajax|" + err, null, 1);
	              callback(offerOutcomeStatus,offerSubmitOutcomeMessage);
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bid-model.js|function:confirmOffer|" + e, null, 1);
            }
        },

        confirm : function (callback, options) {
        	try{
              var bidSubmissionModel = {},
                  self = this;
	
	            // @ todo:
	            // is it not good to do a test case and check if all defaults are 'done ???'
	            bidSubmissionModel.listingId = this.get("listingId");
	            if(typeof $("input[name='role']:checked").val() === 'undefined'){
	              bidSubmissionModel.isAgent   = this.get("isAgent");
	            }else{
	              if($("input[name='role']:checked").val() === 'BUYER'){
	                bidSubmissionModel.isAgent   = false;
	              }else{
	                bidSubmissionModel.isAgent   = true;
	              }
              }
              if(BidModel.attributes.agentOptionalProgramEnabled  !== undefined && BidModel.attributes.agentOptionalProgramEnabled ){
                var isRepresentedByAgent = _.escape($('#isRepresentedByAgent').val());
                if(BidModel.attributes.isAgent){
                  if(isRepresentedByAgent == 'Y') {
                    bidSubmissionModel.isAgent = false;
                  } else {
                    bidSubmissionModel.isAgent = true;
                  }
                }else{
                  bidSubmissionModel.isAgent = false;
                }
              }
	            bidSubmissionModel.currentBidAmountDetail = this.get("currentBidAmountDetail");
	            bidSubmissionModel.bidDetail = this.get("bidDetail");
	            if(this.get("tempBidUserDetail")){
	              this.set("bidUserDetail",this.get("tempBidUserDetail"));
	            }
	            //for liberty program
	            if($("#chkLegalLiberty").prop('checked') != true){
	                bidSubmissionModel.bidDetail.isLibertySelected   = false;
	            }
	            else{
	                bidSubmissionModel.bidDetail.isLibertySelected   = true;
	            }
	            bidSubmissionModel.bidUserDetail = this.get("bidUserDetail");
	
	            bidSubmissionModel.visitorInfo = this.get("visitorInfo");
	            bidSubmissionModel.bidType = this.get("bidType"); 
	            bidSubmissionModel.bidContigencyMasterList = this.get("bidContigencyMasterList");
	            bidSubmissionModel.bidContigencyBuyerChosen = this.get("bidContigencyBuyerChosen");
	            bidSubmissionModel.propertyStateId = BidModel.attributes.propertyDetails.address.state;
	            bidSubmissionModel.sellerId = BidModel.attributes.sellerParameters.sellerId;
	            bidSubmissionModel.isCCValidation = BidModel.attributes.isCCValidation;
	            if(BidModel.attributes.isRepeatBid && BidModel.attributes.previousBidDetail && BidModel.attributes.previousBidDetail.documentDetails){
	              if( $("input[name='bidFinancingContingent']:checked").val() === 'N' && $("input[name='paymentTypeSel']:checked").val() === 'MORTGAGE' ){
	                bidSubmissionModel.documentDetails = null
	              }else{
	                bidSubmissionModel.documentDetails = BidModel.attributes.previousBidDetail.documentDetails;
	              }
	              
	            }
              if($('.buyerPremiumCash').is(':visible')){
                  bidSubmissionModel.bidDetail.finacingAndClosingDetail.isBPFinanced=false;
              }else if($('.buyerPremiumFinance').is(':visible')){
                if($("input[name='buyerPremiumOption']:checked").val()!=undefined && $("input[name='buyerPremiumOption']:checked").val()!=''){
                  if($("input[name='buyerPremiumOption']:checked").val()=='Y'){
                     bidSubmissionModel.bidDetail.finacingAndClosingDetail.isBPFinanced=true;
                  }else{
                     bidSubmissionModel.bidDetail.finacingAndClosingDetail.isBPFinanced=false;
                  }
                }
              }else if(BidModel.attributes.isRepeatBid && BidModel.attributes.previousBidDetail && BidModel.attributes.previousBidDetail.finacingAndClosingDetail.isBPFinanced!=null){
                bidSubmissionModel.bidDetail.finacingAndClosingDetail.isBPFinanced =BidModel.attributes.previousBidDetail.finacingAndClosingDetail.isBPFinanced;
              }else{
            	  bidSubmissionModel.bidDetail.finacingAndClosingDetail.isBPFinanced='';
              }
	            if(BidModel.attributes.isRepeatBid && BidModel.attributes.previousBidDetail){
	              bidSubmissionModel.previousBidId = BidModel.attributes.previousBidDetail.previousBidId;
	              bidSubmissionModel.previousBidPaymentType = BidModel.attributes.previousBidDetail.finacingAndClosingDetail.paymentTypeSelected[0];
	              bidSubmissionModel.previousBidAmount = BidModel.attributes.previousBidDetail.previousBidAmount;
	            }
	            
	            if(BidModel.attributes.isRepeatBid && BidModel.attributes.previousBidDetail && $('#afBANotice').length == 0){
	              bidSubmissionModel.afBaAccepted = BidModel.attributes.previousBidDetail.afBaAccepted;
	            } else {
	              bidSubmissionModel.afBaAccepted = $('#afBANotice').is(":visible") && $('#afBANotice').is(':checked');
	            }
	            
	           //date format fix for core db
	            bidSubmissionModel.bidUserDetail = this.get("bidUserDetail");
	            if (bidSubmissionModel.bidUserDetail.agentDetail !== null
	              && bidSubmissionModel.bidUserDetail.agentDetail.licenseExpirationDate !== null ) {
	              bidSubmissionModel.bidUserDetail.agentDetail.licenseExpirationDateString = $("#agentLicenseExpiry").val();
	            }
              bidSubmissionModel.preBidCifId = '';
              if(typeof cifWizardView != 'undefined' && typeof cifWizardView != undefined && cifWizardView.cifResponse != undefined && cifWizardView.cifResponse.cifId != undefined && cifWizardView.cifResponse.cifId !=''){
                bidSubmissionModel.preBidCifId = cifWizardView.cifResponse.cifId;
              }
	            //@todo: autobid scenario
	            if (!bidSubmissionModel.bidDetail.isAutoBidSelected){
	                bidSubmissionModel.bidDetail.autoBidAmountDetail = null;
	            }else{
	                bidSubmissionModel.bidType = 'AUTO_BID';              
	            }
	            
	            //taking email id as ?required? in server side dto for validation
	            //debug tip:deactivate this line to introduce an error scenario
	            
	            bidSubmissionModel.bidDetail.buyerEmailId = bidSubmissionModel.bidUserDetail.buyerDetail.emailId;
              
	
	            //@todo Delete this line of code after UMS Integration
              bidSubmissionModel.userId = this.get("userId");
              
              var bidSubmitOutcomeMessage = "";
	            var bidOutcomeStatus = "";
	            var url = contextPath.indexOf('portal') > -1 ? contextPath + "/submitBid/complete" : contextPath + "/portal/submitBid/complete";
	
	            try {
	                jQuery.ajax ({
	                    context: this,
	                    url: url,
	                    type: "POST",
	                    data: JSON.stringify(bidSubmissionModel),
	                    dataType: "json",
	                    contentType: "application/json; charset=utf-8",
	                    success: function(response){
	                        if (response === null) {
	                            bidOutcomeStatus = "EMPTY_RESPONSE";
	                        } else {
	                          bidOutcomeStatus = response.status;
                          }
	                        switch(bidOutcomeStatus) {
	                            case "BID_SUCCESS": 
	                                bidSubmitOutcomeMessage = "Bid Placed Successfully!";
	                                this.attributes.submitBidResponse = response;
	                                break;
	                            case "BID_UNSUCCESS":
	                                if(response.statusCodes[0] === 'BACKUP_BID_NOT_AVIL_FOR_WINNER' || response.statusCodes[0] === 'BACKUP_BID_NOT_AVIL_FOR_WINNER_AGNT'){
	                                  bidSubmitOutcomeMessage = response.statusCodes[0];
	                                }else{
	                                  bidSubmitOutcomeMessage = "We're sorry! There was a technical issue with your bid submission and it didn't go through. We're addressing the issue as fast as we can. Please try again.";
	                                  this.attributes.submitBidResponse = response;
	                                }
	                                break;
	                            case "BID_FAILURE":
	                                bidSubmitOutcomeMessage = "Oops! Bid Failure?";
                                  break;
                              case "PAYMENT_REQUIRED":
                                  self.showFradulentPopup();
                                  break;
	                            case  "ERROR" :
	                                bidSubmitOutcomeMessage = response.errorMessage;
	                                break;
                              case  "VALIDATION_ERROR" :
                                  if(response.statusCodes[0] === 'BID_DEPOSIT_REQUIRED'){
                                    var validateOptions = {};
                                    validateOptions.amount = response.bidDepositAmount;
                                    validateOptions.status = 'BID_DEPOSIT_REQUIRED';
                                    self.showFradulentPopup(validateOptions);
                                    bidSubmitOutcomeMessage = response.statusCodes[0];
                                  }else if(response.statusCodes[0] === 'FRAUDULENT_BID'){
                                    var validateOptions = {};
                                    validateOptions.amount = response.bidDepositAmount;
                                    validateOptions.status = 'FRAUDULENT_BID';
                                    self.showFradulentPopup(validateOptions);
                                    bidSubmitOutcomeMessage = response.statusCodes[0];
                                  }else{
                                    bidSubmitOutcomeMessage = response.errorMessage;
                                  }
	                                break;
	                            case  "EMPTY_RESPONSE" :
	                                bidSubmitOutcomeMessage = "Empty response from server for submitBid/complete";
	                                break;
	                            default:
	                                bidOutcomeStatus = "UNKNOWN_ERROR";
	                                bidSubmitOutcomeMessage = "Server returned an unrecognized response"
                          }
                        if( bidSubmitOutcomeMessage != 'BID_DEPOSIT_REQUIRED' && response.statusCodes[0] !== 'FRAUDULENT_BID'){
                            if(options) {
                              if(response.statusCodes != undefined && response.statusCodes !=''){
                                options.statusCode =  response.statusCodes[0];
                              }else{
                                options.statusCode =  '';
                              }
                              
                              options.bidSubmitResponse =  response;
                              callback(bidOutcomeStatus, bidSubmitOutcomeMessage, options);
                          } else {
                              callback(bidOutcomeStatus,bidSubmitOutcomeMessage);
                          }
                        }
                          
	                        
	                    },
                      error: function(jqXHR, textStatus, errorThrown){
                          GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bid-model.js|function:confirm|ajax:error|" + errorThrown, null, 1);
                          if(options) {
                              callback(bidOutcomeStatus, bidSubmitOutcomeMessage, options);
                          } else {
                              callback(bidOutcomeStatus,bidSubmitOutcomeMessage);
                          }
                      }
	                });
	            } catch(err) {
	              bidOutcomeStatus = "UNKNOWN_EXCEPTION";
	              bidSubmitOutcomeMessage = "Unknown Exception while calling submitBid/complete !";
            	  GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bid-model.js|function:confirm-ajax|" + err, null, 1);
	              
                if(options) {
                    callback(bidOutcomeStatus, bidSubmitOutcomeMessage, options);
                } else {
                    callback(bidOutcomeStatus,bidSubmitOutcomeMessage);
                }
                var optionVal = {};
                optionVal = {"component" : "Submit_Bid_UI", "failJson" : "Source Page: Bid page | file:bid-model.js | function:confirm | Unknown Exception while calling submitBid/complete !" + err };
                monitoringView.logUIErrors(optionVal);
	            }
        	}catch(e){
            var option = {};
            option = {"component" : "Submit_Bid_UI", "failJson" : "Source Page: Bid page | file:bid-model.js | function:confirm | " + e };
            monitoringView.logUIErrors(option);
        		GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bid-model.js|function:confirm|" + e, null, 1);
        	}
        },
        showFradulentPopup : function(options){
          try{
            var options = options || {};

            var self = this,
                content1 = saveListDrawerContent.globalAlerts.labels || {}, 
                content = JSON.parse(JSON.stringify(content1)), 
                
                warningMessage='',
                
                amount = options.amount || 1000,
                formSelector = options.formSelector || '',
                formDocumentSelector = options.documentFormSubmit || '';
            if(options.status == 'FRAUDULENT_BID'){
              warningMessage = content.fraudulentErrorContent;
              

            }else{
              warningMessage = content.bidDepositErrorContent;
              content.fraudulentErrorHead = content1.bidDepositErrorHead;
              content.fraudulentButtonLabel = content1.bidDepositErrorButton;
            }
            warningMessage = warningMessage.replace('###AMOUNT###', "$"+bidUpdater.formatCurrencyLabel(amount));
            
            GAUtils.eventTracking.trackevent("", "Fraudulent warning modal | " +options.status ,  this.get("userId")+ " | visitorId: "+ visitorId );

            $('.modal-popup-container').html(fraudulentErrorModalTemplate({
                warningMessage: warningMessage,
               
                content: content,
                formSelector: formSelector
            }));

            $('.modal-popup-container .fraudulent-warning-modal').modal('show');
            util.hideLoader();
            $(".fraudulent-warning-modal .fb-action button").unbind('click').bind('click', function(){
              GAUtils.eventTracking.trackevent("", "Fraudulent warning modal - Proceed button click | "+options.status ,   self.get("userId")+ " | visitorId: "+ visitorId );
              var bidAmount = ($('#bidAmount').val()).replaceAll('$','').replaceAll(',','');
              var prevBuyerEmailId = '';
              if(typeof BidModel != 'undefined' && typeof BidModel.attributes != 'undefined' && typeof BidModel.attributes.bidDetail != 'undefined' && typeof BidModel.attributes.bidDetail.buyerEmailId != 'undefined' && BidModel.attributes.bidDetail.buyerEmailId){
                prevBuyerEmailId = BidModel.attributes.bidDetail.buyerEmailId;
              }
              $('.modal-popup-container .fraudulent-warning-modal').modal('hide');
              util.showLoader();
              $('#pdp-bid-form-wizard').remove();
              var $form = $('<form onsubmit="return false;" name="pdp-bid-form-wizard" class="pdp-bid-form-wizard" id="pdp-bid-form-wizard" action="'+contextPathPortal+'/bid" method="post"> </form>');
              $form.append('<input type="hidden" name="bidType" class="bidType" value="PLACE_BID" id="bidType"><input type="hidden" name="bidAmount" class="bidAmount" value="'+bidAmount+'" id="bidAmount"/><input type="hidden" name="tempUserId" value=""/><input type="hidden" name="previousBidEmailId" value="'+prevBuyerEmailId+'"><input type="hidden" name="isEditMode" value="false"/><input type="hidden" name="listingId" value="'+listingId+'"/>	');
              $form.appendTo($('body'));
              document.forms['pdp-bid-form-wizard'].submit()
          });
          $('.modal-popup-container .fraudulent-warning-modal').on('hidden.bs.modal', function () {
             if($('#bidSectionEdit').is(":visible") ){
              $('#bidSectionEdit').click();
             }
             $("html, body").animate({ scrollTop: 0 }, "slow");
          });

          if (self.isMobile) {
            $('.modal-popup-container .fraudulent-warning-modal').on("hidden.bs.modal", function(){
                $(".fraudulent-warning-modal").css("zIndex", "1050");
                $(".modal-backdrop").css("zIndex", "1040");
            });
            $(".fraudulent-warning-modal").css("zIndex", "1051");
            $(".modal-backdrop").css("zIndex", "1050");
        }
          }catch(e){
        		GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bid-model.js|function:showFradulentPopup|" + e, null, 1);

          }
        },
        formatModelForDisplay: function() {
            try{
        		var sellerConcession = BidModel.get("sellerConcession");
	            sellerConcession = _.map(sellerConcession,function(concession) {
	                concession.amount = "$"+bidUpdater.formatCurrencyLabel(concession.amount);
	                concession.note = _.unescape(concession.note);
	                return concession;
	            });
	            BidModel.set("sellerConcession",sellerConcession);
	            var totalSellerContribution = BidModel.get("totalSellerContribution");
	            if (typeof totalSellerContribution !== "undefined" && totalSellerContribution !== 0) {
	                BidModel.set("totalSellerContribution","$"+bidUpdater.formatCurrencyLabel(totalSellerContribution));
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bid-model.js|function:formatModelForDisplay|" + e, null, 1);
            }
        }
    });
  
    //meant to be singleton
    var modelData = typeof initModel === 'undefined' ? {} : initModel;
    var BidModel = new BidModel(modelData);
    
    if (location.href.indexOf("offer")!=-1) {
      _.defaults(BidModel.attributes,offerDefaults);
      BidModel.set("offerMode",true);
    } else {
       _.defaults(BidModel.attributes,bidDefaults);
      BidModel.set("offerMode",false);
    }
   
    return BidModel;
});
