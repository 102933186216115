
define('jUtil',['jquery', 'underscore'], function(jQuery, _) {

    try {

        /**
        *   Check if the object is exists in list with key and value
        *   @params {Object} key, value, list
        *   @retuns {Array} 
                
            Example: 
            var key = 'listingId',
                value = 123,
                list = [{   
                            listingId: 123, 
                            type: 'sample',
                            listingName: 'Testing One', 
                        },
                        {   
                            listingId: 456, 
                            type: 'complex',
                            listingName: 'Testing Two', 
                        }
                        {   
                            listingId: 457, 
                            type: 'sample',
                            listingName: 'Testing three', 
                        }];
                        
            isObjectExists({key: 'lisitingId', value: 123, list: list});

            Update: if groupBy passed, it will perform action on filtered items. 
            isObjectExists({key: 'lisitingId', value: 123, list: list, groupByKey: 'type', groupByValue: 'sample'});
        */  
        jQuery.isObjectExists = function(options) {
            
            options = options || {};

            var list = options.list || [],
                key = options.key || null,
                value = options.value || null,
                groupByKey = options.groupByKey || null,
                groupByValue = options.groupByValue || null,
                index = -1;

            // if data inconsistant return same list
            if (!key || !value || !list.length ) return false ; 

            if (groupByKey && groupByValue ) {
                list = list.filter(function(item) {
                    return item[groupByKey] === groupByValue;
                });
            }

            // get the index of object with key and value 
            index = _.pluck(list, key).indexOf(value);
            
            // remove object with index
            return (index > -1) ? true : false;

        };

        /**
        *   Removes an object form array of objects with key name and key value. 
        *   @params {Object} 
        *       key, value, list
            @retuns {Array} 
                
            Example: 
            var key = 'listingId',
                value = 123,
                list = [{   
                            listingId: 123, 
                            type: 'sample',
                            listingName: 'Testing One', 
                        },
                        {   
                            listingId: 456, 
                            type: 'complex',
                            listingName: 'Testing Two', 
                        }
                        {   
                            listingId: 457, 
                            type: 'sample',
                            listingName: 'Testing three', 
                        }];
                        
            removeObject({key: 'lisitingId', value: 123, list: list});

            Update: if groupBy passed, it will perform action on filtered items. 
            removeObject({key: 'lisitingId', value: 123, list: list, groupByKey: 'type', groupByValue: 'sample'});

        */  
        jQuery.removeObject = function(options) {
            
            options = options || {};

            var list = options.list || [],
                key = options.key || null,
                value = options.value || null,
                groupByKey = options.groupByKey || null,
                groupByValue = options.groupByValue || null,
                index = -1;

            // if data inconsistant return same list
            // if (!key && !value && !list.length ) return list ; 
            list.some(function(item, index) {
                if(list[index][groupByKey] === groupByValue && list[index][key] === value ){
                    // found it!
                    list.splice(index, 1);
                    return true; // stops the loop
                }
                return false;
            });

            return list;

        };
        
        jQuery.removeObjectWithKey = function(options) {
            
            options = options || {};

            var list = options.list || [],
                key = options.key || null,
                value = options.value || null,
                index = -1;

            index = list.map(function(item) { return item[key] }).indexOf(value);

            // remove object
            list.splice(index, 1);
            
            return list;

        };
        
        /** 
        *   Remove duplicate objects from array based on key 
        *   @params {Object}
                key, list
            @retuns {Array}
                
            Example: 
            var key = 'listingId',
                list = [{   
                            listingId: 123, 
                            listingName: 'Testing One', 
                        },
                        {   
                            listingId: 123, 
                            listingName: 'Testing Two', 
                        }];
                        
            removeObject({key: 'lisitingId', array: list});
        */
        jQuery.uniqList = function(options) {
            options = options || {};
            
            var key = options.key || null,
                list = options.list || [],
                newList = [],
                unique = {};
            
            // if data inconsistant return empty array
            if(!key && !list.length) return [];
            
            for(var i = 0; i< list.length; i++){
                if (!unique[list[i][key]]) {
                    newList.push(list[i]);
                    unique[list[i][key]] = list[i];
                }
            }
            
            return newList;
        };
        
    } catch(exception) {
        console.log('Error in jUtil:', exception);
    }
});