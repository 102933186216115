
/**
 *  UI Monitoring View for JS errors 
 */

define('monitoringView',['jquery', 'backbone'], function ($,Backbone) {
    'use strict';
    
    var monitoringView = Backbone.View.extend({
        
        initialize: function () {
            var self = this;            
        },
       
        logUIErrors: function(options) {
            try{
                var self = this,
                    options = options || null,
                    url = contextPath.indexOf('portal') > -1 ? contextPath + "/monitor-dashboard/saveErrors/" : contextPath + "/portal/monitor-dashboard/saveErrors/";
                
                    var UILogData = {
                        category : "Hubzu_UI",
                        component : options.component || "",
                        client : "hubzu",
                        filterField1 : null,
                        filterField2 : null,
                        filterField3 : null,
                        errorMessage : "",
                        exceptionStackTrace : null,
                        failJson : options.failJson || null,
                        hostName : "hubzu-server-1"
                    };

	            jQuery.ajax ({
                    context: this,
                    
	                url: url,
	                type: "POST",
                    data:JSON.stringify(UILogData),
                    //data: UILogData,
	                dataType: "json",
	                contentType: "application/json; charset=utf-8"
	            });
        	}catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:monitoringView.js|function:logUIErrors|" + e, null, 1);
            }
        }
       

    });  
    return new monitoringView();
});