
/* START_TEMPLATE */
define('hbs!tpl/mobileVerification/mobileVeriTemp',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<script type =\"text/x-handlebars-template\" id=\"emailVerTemp\">\n\n<div class=\"phone-text-field\">\n    Enter the verfication code sent to the phone number ending in <span class=\"secure-no\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.values : depth0)) != null ? stack1.phoneNumber : stack1), depth0))
    + "</span>\n    <div class=\"error-ver hide\" id=\"error-ver\"></div>\n</div>\n\n<div class=\"confirm-registration-sec\">\n    <div class=\"confirm-reg-head\">\n        Verify Your Phone Number\n    </div>\n    <div class=\"ev-section-input\">\n        <div class=\"ev-code-input\">\n                <div class=\"ev-input\">\n                    <input type=\"number\" maxlength=\"1\" class=\"otp-input\" >\n                    <input type=\"number\" maxlength=\"1\" class=\"otp-input\" >\n                    <input type=\"number\" maxlength=\"1\" class=\"otp-input\" >\n                    <input type=\"number\" maxlength=\"1\" class=\"otp-input\" >\n                </div>\n                <div class=\"ev-otp-error hide\"></div>\n            </div>\n            <div class=\"ev-code-confirm\">\n                <button class=\"ev-confirm-btn h-disabled\" data-input-element=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].inputElement : depths[1]), depth0))
    + "\">Verify</button>\n            </div>\n        </div>\n    </div>\n    <div class=\"ev-generate-pin\">\n        Didn't get the verification code? <a class=\"ev-regen-btn\">Click here </a> to generate a new one or check your <a class=\"profile-link\">profile</a> to confirm your phone number is correct.\n    </div>\n    \n</div>\n</script>\n\n\n";
},"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/mobileVerification/mobileVeriTemp', t);
return t;
});
/* END_TEMPLATE */
;