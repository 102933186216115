
/* START_TEMPLATE */
define('hbs!tpl/saveDrawer/saveListDrawer',['hbs','hbs/handlebars', 'hbs!templates/globalAlerts/loaderIcon','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, buffer = "\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isLeanRegistrationEnabled : depth0), {"name":"if","hash":{},"fn":this.program(2, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	    \n";
},"2":function(depth0,helpers,partials,data,depths) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.isLeanRegistrationEnabled : depth0), "Y", {"name":"ifEqual","hash":{},"fn":this.program(3, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.isLeanRegistrationEnabled : depth0), "N", {"name":"ifEqual","hash":{},"fn":this.program(5, data, depths),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"3":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "                    	<div class=\"saved-items-actions\">\n                    		";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.nonLoginUserContent : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                    	</div>\n";
},"5":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, buffer = "                		<p class=\"saved-items-overlay--copy\">";
  stack1 = lambda(((stack1 = (depths[1] != null ? depths[1].labels : depths[1])) != null ? stack1.signInContent : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, lambda=this.lambda, functionType="function", helperMissing=helpers.helperMissing, buffer = "<!--<div class=\"divSaveListClose hidden-lg hidden-md hidden-sm\"> <div id=\"saveListCloseIcon\"></div></div>-->\n<div class=\"divSaveListClose hidden-lg hidden-md hidden-sm\"> \n  \n  <div class=\"divSaveListCloseLeft\">\n  </div>\n  \n  <div class=\"divSaveListCloseRight\" id=\"closeDrawerMobile\">\n    	<div id=\"saveListCloseIcon\"></div>\n  </div>\n  \n   \n</div>\n<div class=\"saved-search-wrapper\">\n	<div class=\"notification-content\">\n		\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.loggedIn : depth0), {"name":"unless","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "		<div class=\"js-saved-searches js-accordion watchPropDivBorder\">\n			<h3 class=\"auto_saveSearch saved-items-overlay--title js-save-src-title m-search saved-title-unfixed js-accordion-head\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.watchedTitle : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "  <span class=\"saved-items-overlay--title__toggle\"></span></h3>\n			\n			<div class=\"saved-items-overlay--module add-save m-search\" id=\"unWatchedDiv\">\n				\n				<p class=\"saved-items-overlay--copy indication\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.saveThisHomeDesc : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</p>\n			</div>\n			<div class=\"saved-items-overlay--module add-save m-search \" id=\"watchedDivMain\">	\n				<p class=\"savedSearchContent hide\" id=\"watchedDiv\"></p>\n				<div class=\"hide showMoreWatch\" id=\"watchedPropertyShowMoreDiv\"><a href=\"javascript:void(0)\" id=\"watchPropertyShowMore\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.showAllWatch : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</a></div>\n				<div class=\"searched-loading hide\" id=\"savedWatchLoadingIcon\">\n";
  stack1 = this.invokePartial(partials['templates/globalAlerts/loaderIcon'], '					', 'templates/globalAlerts/loaderIcon', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "				</div>\n			</div>\n			\n			\n		</div>\n		<div class=\"js-saved-listings js-accordion\">\n			<h3 class=\"auto_saveListing saved-items-overlay--title js-save-src-title m-homes saved-title-unfixed js-accordion-head\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.saveHomeHead : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " <span class=\"saved-items-overlay--title__toggle\"></span></h3>\n			\n			<div class=\"saved-items-overlay--module add-save m-homes saveSearchMainDiv\">\n				\n				<div class=\"savedSearch-results\" id=\"savedSearchDiv\"></div>\n				<h5 class=\"searched-suggestion--show-all\" id=\"savedSearchShowMore\"> ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.showAllSaveSearch : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</h5>\n				<div class=\"searched-loading\" id=\"savedSearchLoadingIcon\"><img class=\"drawer-loading-img\" src=\"";
  stack1 = ((helper = (helper = helpers.contextPath || (depth0 != null ? depth0.contextPath : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"contextPath","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "/www/images/loading_circle.gif\" width=\"26\" height=\"26\" alt=\"Save List Loader\"></div>\n				<p class=\"saved-items-overlay--copy indication savedSearchContent\" id=\"savedSearch-default-text\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.saveSearchContent : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</p>\n			</div>\n			\n		</div>\n	</div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/saveDrawer/saveListDrawer', t);
return t;
});
/* END_TEMPLATE */
;