
define('bidUpdater',['jquery'], function($) {
    'use strict';

    var defaults = {
        minBidAmount: 	0,
        maxBidAmount: 	10000000,
        bidIncrementer: 1000,
        id: ''
    };
    var options;
    var finalBidAmount;
    var obj;

    var bidUpdater = {

        setConfig: function(config) {
            options = $.extend(defaults, config);
            if(options.maxBidAmount%options.bidIncrementer != 0){
                options.maxBidAmount = options.maxBidAmount - options.maxBidAmount%options.bidIncrementer; 
            }
            finalBidAmount = $('#'+options.id+ ' input').val();
        	finalBidAmount = this.parseCurrencyFormat();
            checkEligibility();
        },
        
        parseCurrencyFormat : function(val) {
            if (typeof val !== "undefined" && val!== "") {
                if(val.indexOf('$') != -1){
                    return parseInt(val.split('$')[1].replace(/,/g , ""));
                }
                return parseInt(val.replace(/,/g , ""));
            } 
            return 0;
        },
        /*listType is introduced to fix a bug related to offer increment CORE-8906*/
        calculate: function(mode,listType){
        	finalBidAmount = $('#'+options.id+ ' input').val();
        	if(finalBidAmount.indexOf('e') != -1){
        		finalBidAmount = options.maxBidAmount + options.bidIncrementer;
        	}else{
        		if(finalBidAmount == ''){
            		finalBidAmount = 0;
            	}else{
            		finalBidAmount = this.parseCurrencyFormat(finalBidAmount);
            	}
        	}
            switch(mode){
                case "increment":
                    if(finalBidAmount <= options.maxBidAmount && finalBidAmount >= options.minBidAmount){
                    	if(finalBidAmount < options.minBidAmount) {
                    		finalBidAmount = options.minBidAmount;
                    		$('#'+options.id+ ' input').removeClass('inputError');
                    		$('#'+options.id).removeClass('inputError');
                    		$('.bidError').html('');
                    		$('.bidError').hide();
                    	}else if((finalBidAmount-options.minBidAmount)%options.bidIncrementer != 0){
                            if(listType!=undefined && listType=="TRNL"){
                                finalBidAmount = finalBidAmount - finalBidAmount%options.bidIncrementer + options.bidIncrementer;
                            }else{
                    		    finalBidAmount = options.minBidAmount + Math.floor((finalBidAmount-options.minBidAmount)/options.bidIncrementer + 1) * options.bidIncrementer;
                            }
                            $('#'+options.id).removeClass('inputError');
                            $('.bidError').html('');
                            $('.bidError').hide(); 
                    	}else{
                    		if(finalBidAmount == options.maxBidAmount){
                                finalBidAmount = options.maxBidAmount
                            }else{
                                finalBidAmount = finalBidAmount+options.bidIncrementer;
                            }
                    	}
                    	
                    	$('#'+options.id+ ' input').val('$'+this.formatCurrencyLabel(finalBidAmount));
                    }else if(finalBidAmount < options.minBidAmount){
                        finalBidAmount = options.minBidAmount;
                        $('#'+options.id+ ' input').val('$'+this.formatCurrencyLabel(finalBidAmount));
                        if($('#bidSectionMsg').html() != ''){
                            $('#bidSectionMsg').html('');
                            $('#bidSectionError').hide();
                        }
                    }else{
                    	finalBidAmount = options.maxBidAmount;
                        $('#'+options.id+ ' input').val('$'+this.formatCurrencyLabel(options.maxBidAmount));
                    }
                    break;
                case "decrement":
                    if(finalBidAmount > options.minBidAmount && finalBidAmount <= options.maxBidAmount){
                    	if( (finalBidAmount-options.minBidAmount)%options.bidIncrementer != 0){
                            if(listType!=undefined && listType=="TRNL"){
                                finalBidAmount = finalBidAmount - finalBidAmount%options.bidIncrementer;
                            }else{
                    		    finalBidAmount = options.minBidAmount + Math.floor((finalBidAmount-options.minBidAmount)/options.bidIncrementer) * options.bidIncrementer;
                            }
                            $('#'+options.id).removeClass('inputError');
                            $('.bidError').html('');
                            $('.bidError').hide(); 
                    	}else{
	                    	finalBidAmount = finalBidAmount-options.bidIncrementer;	                        
                    	}
                        if (finalBidAmount < options.minBidAmount) {
                            finalBidAmount = options.minBidAmount;
                        }
                    	$('#'+options.id+ ' input').val('$'+this.formatCurrencyLabel(finalBidAmount));
                    }else if(finalBidAmount > options.minBidAmount){
                        finalBidAmount = options.maxBidAmount;
                    	$('#'+options.id+ ' input').val('$'+this.formatCurrencyLabel(options.maxBidAmount));
                    }else if(finalBidAmount < options.minBidAmount){
                        finalBidAmount = options.minBidAmount;
                    	$('#'+options.id+ ' input').val('$'+this.formatCurrencyLabel(options.minBidAmount));
                    }
                    break;
            };
            checkEligibility();
        },
        
        formatCurrencyLabel : function(num){
    		num = num.toString().replace(/\$|\,/g,'');
    		if(isNaN(num)){
    			num = "0";
    			return num;
    		}else{
    			for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
    				num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
    			}
    			return (num);
    		}
    	}
    };
 
    function checkEligibility(){
    	if(finalBidAmount == options.minBidAmount){
    		$('.decrement' , obj).addClass('disabled');
            $('.offerDecrement' , obj).addClass('disabled');
    	}else if(finalBidAmount == options.maxBidAmount){
    		$('.increment' , obj).addClass('disabled');
            $('.offerIncrement' , obj).addClass('disabled');
    	}else{
    		$('.increment' , obj).removeClass('disabled');
    		$('.decrement' , obj).removeClass('disabled');
            $('.offerIncrement' , obj).removeClass('disabled');
            $('.offerDecrement' , obj).removeClass('disabled');
    	}
    }
    return bidUpdater;
});
