
define('templates/helpers/simpleFor',['hbs/handlebars'], function ( Handlebars ) {
    function simpleFor(from, to, incr, block){
        var accum = '';
    	for(var i = from; i <= to; i += incr)
        accum += block.fn(i);
    	return accum;
    }
    Handlebars.registerHelper('simpleFor' , simpleFor);
    return simpleFor;
});