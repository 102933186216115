
define('stickyHeaderView',['require','jquery','backbone','GoogleAnalyticsUtils','commonutils','loginRegisterView','introduceHubzuView','appDownloadBannerView'],function (require) {
    "use strict";

    var $ = require("jquery"),
        Backbone = require("backbone"),
        GAUtils = require("GoogleAnalyticsUtils"),
        Utils = require("commonutils"),
        loginRegisterView = require("loginRegisterView"),
        introduceHubzuView = require("introduceHubzuView"),
        appDownloadBannerView = require('appDownloadBannerView'),
        HeaderView;

    HeaderView = Backbone.View.extend({
        events: {
            'click .buy-a-home-menu-head':'openBuyAHome',
            'click .menu-close' : 'closeBuyAHome',
            'click .buy-a-home-mobile' : "openMobileBuyMenu",
            'click .hm-signin-btn, .hm-register-btn': "openLogin",
            'click .hm-signout': 'logout'
        },
        initialize: function () {
            //some initialization scripts
            try{
                var thisObj = this;
                thisObj.addHeaderOnScroll();
                $('.sell-a-home-link').click(function(e){
                    event.preventDefault();
                    var gaLabel = 'visitorId: ' + $.cookie('visitorId');
                    var userSession = window.userSession || {};
                    var userId = userSession.userId || null;
                    if(userId){
                        gaLabel += '|userId: '+ userId;
                    }
                    
                    GAUtils.eventTracking.trackevent("",'Header - Sell Click',gaLabel);
                    if($('#welcome-drop-down a[hashname="smallSellerDashboard"]').length){
                        window.location = encodeURI(contextPath + $('.sellaHomeRedirect').attr('hrefurllogin'));
                    }else{
                        window.location = encodeURI(contextPath + $('.sellaHomeRedirect').attr('hrefurllogout'));
                    }
                });
                $(".ha-ga-event").click(function(){
                    var gaAction, 
                        refText, 
                        position, 
                        userDetails = " | visitorId: " + $.cookie('visitorId') + " | userId: " + ($.cookie('userId') || 'NA');
                    gaAction = $(this).attr("data-ga-event");
                    refText = $(this).attr("aria-label") + userDetails;
                    if ($(this).attr("data-section-id")) {
                        if (typeof window.htimestamp !== "undefined" && typeof window.htimestamp.home !== "undefined" ) {
                            position = window.htimestamp.home.getComponentPosition({id: $(this).attr("data-section-id") });
                            refText += " | Section position: " + position;
                        }
                    }
                    if ($(this).attr("data-position")) {
                        refText += " | link position: " + $(this).attr("data-position");
                    }
                    GAUtils.eventTracking.trackevent(null, gaAction, refText, "");
                });

                // Global GA event for all LP CTA click
                $(document).on("click", ".buyers-premium-reduction .action-cta, .unbounce-page .action-cta", function(evt){
                    var section = (evt.target) ? $(evt.target).data('section') : '';
                    var redirectURL = (evt.target) ? $(evt.target).data('redirectto') : '';
                    var action = (evt.target) ? $(evt.target).data('action') : '';
                    var eventCategory = (evt.target) ? $(evt.target).data('category') : '';
                    var eventLabel = "visitorId: " + $.cookie('visitorId') + " | userId: " + ($.cookie('userId') || 'NA')+ '| Section:'+section;
                    GAUtils.eventTracking.trackevent( eventCategory, action, eventLabel);
                    window.open(redirectURL, '_blank').focus
                });

                (function(){
                    // GA configs
                    $(".top-header-navigation").attr('data-app-identifier', window.btoa($(".top-header-navigation").attr('data-app-identifier')));
                    $(".top-header-navigation").attr('data-app-domain', window.btoa($(".top-header-navigation").attr('data-app-domain')));
                    $(".top-header-navigation").attr('data-app-url', window.btoa($(".top-header-navigation").attr('data-app-url')));
                    $(".top-header-navigation").attr('data-app-name', window.btoa($(".top-header-navigation").attr('data-app-name')));
                    $(".top-header-navigation").attr('data-app-tnt', window.btoa($(".top-header-navigation").attr('data-app-tnt')));
                    $(".top-header-navigation").attr('data-status', "encoded");
                    var isIntroduceFlag= Utils.getCookieDetails('introduceHubzuFlag') || '';
                    if(typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.introduceHubzuConfig != 'undefined' && saveListDrawerContent.introduceHubzuConfig.enableIntroducePopup == 'Y'){
                        if (typeof introduceHubzuEnabled != "undefined" && introduceHubzuEnabled && isIntroduceFlag  == ''){
                            thisObj.initIntroduceHubzu();
                            Utils.setCookieDetails('introduceHubzuFlag',true);
                        }
                    }

                    var appDownloadBanner= Utils.getCookieDetails('appDownloadBannerShown') || '';
                    if(window.screen.width < 768 && typeof saveListDrawerContent.appDownloadBannerConfig != 'undefined' && saveListDrawerContent.appDownloadBannerConfig.enableAppDownloadBanner == 'Y' ) {
                        if (appDownloadBanner  == ''){
                            thisObj.showAppDownloadOption();
                            Utils.setCookieDetails('appDownloadBannerShown',true);
                        }
                    }
                    
                })($);
                
                /*** Image load once we reach visible position ***/
            var onLoad_yPos = ( $(window).scrollTop() || $("body").scrollTop() );
            var currVideoPosY = (typeof $('#footer').position() !== "undefined") ? $('#footer').position():{};
            if(parseInt(currVideoPosY.top) < 500){
                $.each($('img'), function() {
                    var source = $(this).attr('data-src');
                    $(this).attr('src', source);
                    $(this).removeAttr('data-src');
                });
            }
            if( (currVideoPosY && parseInt(onLoad_yPos) > parseInt(currVideoPosY.top)-500)){
                $.each($('img'), function() {
                    if ( $(this).attr('promotions-data-src')  ) {
                        var source = $(this).attr('promotions-data-src');
                        $(this).attr('src', source);
                        $(this).removeAttr('promotions-data-src');
                    }
                });
            }
            $(window).scroll(function() {
                $.each($('img'), function() {
                    if ( $(this).attr('promotions-data-src')  ) {
                        var source = $(this).attr('promotions-data-src');
                        $(this).attr('src', source);
                        $(this).removeAttr('promotions-data-src');
                    }
                });
            });


            (function(){

                $.each($('.dynamicClassAttr'), function() {
                    if ( $(this).attr('classAttr')  ) {
                        var classSource = $(this).attr('classAttr');
                        $(this).attr('class', classSource);
                        $(this).removeAttr('classAttr');
                    }
                });
                $(window).on('scroll',function() {
                    $.each($('img'), function() {
                        if ( $(this).attr('data-src') && $(this).offset().top < ($(window).scrollTop() + $(window).height() + 100) ) {
                            var source = $(this).attr('data-src');
                            $(this).attr('src', source);
                            $(this).removeAttr('data-src');
                        }
                    });
                    $.each($('.imgBckground'), function() {
                        if ( $(this).attr('data-style') && $(this).offset().top < ($(window).scrollTop() + $(window).height() + 100) ) {
                            var cssStyle =  $(this).attr('data-style');
                            $(this).css('background-image',cssStyle);
                            $(this).removeAttr('data-style');
                        }
                    }); 
                    
                });
                $('.windowScroll').on('scroll',function() {
                    $.each($('img'), function() {
                        if ( $(this).attr('data-src') && $(this).offset().top < ($(window).scrollTop() + $(window).height() + 100) ) {
                            var source = $(this).attr('data-src');
                            $(this).attr('src', source);
                            $(this).removeAttr('data-src');
                        }
                    });
                    $.each($('.imgBckground'), function() {
                        if ( $(this).attr('data-style') && $(this).offset().top < ($(window).scrollTop() + $(window).height() + 100) ) {
                            var cssStyle =  $(this).attr('data-style');
                            $(this).css('background-image',cssStyle);
                            $(this).removeAttr('data-style');
                        }
                    }); 
                    
                });
            })($);
            /***to load image on load for all the pages */

                $('body').on('click', function(e) {
                    thisObj.bodyClick(e);
                });
                var userDetails = ' | visitorId: ' + $.cookie('visitorId');
                
                var userSession = window.userSession || {};
                var userId = userSession.userId || null;
                if(userId){
                     userDetails += '|userId: '+ userId;
                }
                var urlHref = "",
                    refText = "",
                    gaAction = "";

                $('.buy-a-home-link .buy-sub-menu li').find('.h-menu').each(function(){
                    $(this).click(function(){
                        urlHref =   $(this).attr('href');
                        refText =   'Hamburger Menu'+ ' - '+$.trim($(this).text()) + userDetails ;
                        GAUtils.eventTracking.processLinkAndTrackEvent({linkValue:urlHref, appendContextPath:false}, null,'Header Click',refText, ""); 
                        return false;
                    });
                });
    
                $('.buy-a-home-link-new .dropdown-content li').find('.h-menu').each(function(){
                    $(this).click(function(){
                        urlHref =   $(this).attr('href');
                        refText =   'Buy a home menu'+ ' - '+$.trim($(this).text()) + userDetails;
                        GAUtils.eventTracking.processLinkAndTrackEvent({linkValue:urlHref, appendContextPath:false}, null,'Header Click',refText, ""); 
                        return false;
                    });
                });
                
                /*$('#buyAHomeMenu, #welcomeLogout').mouseenter(function(e){
                    e.stopPropagation();
                    var userSession = window.userSession || {};
                    var userId = userSession.userId || null;
                    var userDetails = ' | visitorId: ' + $.cookie('visitorId');
                    if(userId){
                          userDetails += '|userId: '+ userId;
                    }
                    refText = $.trim($(e.target).attr('aria-label')) + userDetails;
                    GAUtils.eventTracking.trackevent('','Header - Buy Mouse Over', refText, "");
                });*/

            }catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Header section - Home Page", "file:HeaderView.js|function:initialize|" + error, null, 1);
            }
            
            if(hUserType == 'NEW_USER'){
                var userVisitedOneTime = Utils.getCookieDetails('userVisitedOneTime') || '';
                if(userVisitedOneTime == 'Y'){
                    $('#headerHeart,#mobFavoriteItemList').removeClass('hide-item');
                }
                Utils.setCookieDetails('userVisitedOneTime', 'Y');
            }else{
                Utils.setCookieDetails('userVisitedOneTime', 'Y'); 
            }

        },
        initIntroduceHubzu: function(){
            var intiHubzuView = new introduceHubzuView();
            window.intiHubzuView = intiHubzuView;
            intiHubzuView.render();

        },

        showAppDownloadOption : function(){
            var appDownloadView = new appDownloadBannerView();
            appDownloadView.render();
        }, 

        bodyClick: function(e) {
            try{
                var target = $(e.target);
                if(!$(e.target).hasClass("homeSearchBtn")){
                    $(".error-box").hide();    
                }
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Header section - Home Page", "file:HeaderView.js|function:bodyClick|" + error, null, 1);
            }
            
        },
       
        openLogin: function(event) {
            try {
                
                var data = {};
                $('.menu-modal').slideUp();

                if ($(event.target).hasClass('hm-register-btn')) {
                    data.openRegistration =  "Y";
                }
                loginRegisterView.openLoginPopup(data);
                
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Header section - Home Page", "file:HeaderView.js|function:openLogin|" + error, null, 1);
            }
        },
        logout: function(event) {
            try {
                $('.menu-modal').slideUp();
                Utils.signout(event);
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Header section - Home Page", "file:HeaderView.js|function:logout|" + error, null, 1);
            }
        },
        openBuyAHome:function(event){
            try {
                 $('.menu-modal').slideDown();
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Header section - Home Page", "file:HeaderView.js|function:openBuyAHome|" + error, null, 1);
            }
        },
        closeBuyAHome:function(event){
            try {
                $('.menu-modal').slideUp();
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Header section - Home Page", "file:HeaderView.js|function:closeBuyAHome|" + error, null, 1);
            }
        },
        openMobileBuyMenu : function(e){
            try {
                var gaText = "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId');
                if(!($('.buy-sub-menu').hasClass('opened'))){
                    $('.buy-sub-menu,.buy-a-home-mobile').addClass('opened');
                    GAUtils.eventTracking.trackevent("", "Buy a home menu click: open", gaText);
                }else{
                    $('.buy-sub-menu,.buy-a-home-mobile').removeClass('opened');
                    GAUtils.eventTracking.trackevent("", "Buy a home menu click: close", gaText);
                }
            
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Header section - Home Page", "file:HeaderView.js|function:openMobileBuyMenu|" + error, null, 1);
            }
        },
        addHeaderOnScroll: function() {
            try{

                if($('.h-header-container').length) {
            
                    var lastScrollTop = 0, currentScrollPos, scrollIndex = 0;
                    $(window).scroll(function(event){

                        event.stopPropagation();
                        scrollIndex = $(this).scrollTop();
                        if(typeof $('.h-search-container .searchDetailsBG').offset() != 'undefined'){
                            var toIndex = ($('.h-search-container .searchDetailsBG').offset().top)+100;
                            if( scrollIndex > parseInt(toIndex)) {
                                $('.h-header-container').addClass('hubzu-sticky-header-new');
                            }else{
                                $('.h-header-container').removeClass('hubzu-sticky-header-new');
                            }
                        }
                           
                    });

                }

            } catch(exception) {
                GAUtils.eventTracking.trackevent("Error", "Header section - Home Page", "file:HeaderView.js|function:addHeaderOnScroll|" + error, null, 1);
            }
        },
        render: function () {
            return this;
        }
        
        
    
    });
    return HeaderView
});