
/* START_TEMPLATE */
define('hbs!tpl/loginRegister/registrationNew',['hbs','hbs/handlebars', 'hbs!templates/loginRegister/registrationHeadCommon', 'hbs!templates/loginRegister/registrationFooterCommon', 'hbs!templates/loginRegister/login', 'hbs!templates/loginRegister/forgotPassword','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "                        <div class=\"form-group formFieldParent   registrationPhoneConsent\" >\n                            <div class=\"col-xs-12 col-md-12 col-lg-12 required  paddingLeftRight10 inputBoxHeight\">\n                                <div class='input-box-container required'>\n                                    <span class=\"avtarImage phoneIcon glyphicon glyphicon-phone\" ></span>\n                                    <input type=\"text\" maxlength=\"10\" class=\"input-box-editor leanPhoneField leanPhoneNo avtarIcon\" name=\"lean-phoneNumber\" maxlength=\"50\"  iconName=\"phoneIcon\" autocomplete=\"new-phone\" error=\"errorLeanPhoneNo\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.phone : stack1), depth0))
    + "\"/>\n                                    <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.phone : stack1), depth0))
    + "</span>\n                                    <span class=\"input-box-active\"></span>\n                                    <span class=\"input-box-error\"></span>\n                                    <span class=\"input-box-success\"></span>\n                                    <div class=\"errorPH errorLeanPhoneNo\"></div> \n                                </div>\n                                \n                            </div>\n                        </div>\n                        <div class=\"form-group formFieldParent registrationPhoneConsent registrationPhoneConsentOption\">\n                            <div class=\"col-xs-12 col-md-12 col-lg-12 registrationPhoneConsent\">\n                                <div class=\"slider-switch \">\n                                    <div class=\"slider-status-text col-xs-12 col-md-12 col-lg-12\">\n                                    <input type=\"checkbox\" class=\"optInsms optInSMsRegistration\" name=\"optInSMsRegistrationNew\" aria-label=\"optInSMsRegistration\" title=\"optInSMsRegistration\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.consentLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                                    </div>\n                                </div>\n                            </div>    \n                        </div>\n                        <div class=\"registrationPhoneBlock\" style=\"display:none\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.consentTerms : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</div>                        \n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"container-fluid loginRegisterForm  ga-login-register-container\">\n\n    <div class=\"ga-register-container\">\n        <form class=\"form-horizontal userRegisterClass userLeanRegistration\" autocomplete=\"off\" onsubmit=\"return false\">\n";
  stack1 = this.invokePartial(partials['templates/loginRegister/registrationHeadCommon'], '            ', 'templates/loginRegister/registrationHeadCommon', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <div class=\"formFieldsParent\">\n                <div class=\"form-group \">\n                    <div class=\"col-xs-12 col-sm-12 col-md-12\">\n                        <div class=\"hide  errorAll\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.errorMessages : depth0)) != null ? stack1.errorCommonTechnical : stack1), depth0))
    + "</div>\n                    </div>\n                </div>\n                <div class=\"form-group formFieldParent container-fluid\">\n                    <div class=\"col-xs-12 col-md-6 col-lg-6 required errorLeanRegFirstNameParent paddingLeftRight10 inputBoxHeight\">\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage registerNameIcon\"></span>\n                            <input type=\"text\" class=\"input-box-editor name registerFirstName avtarIcon\" iconName=\"registerNameIcon\" name=\"lean-firstName\"\n                                maxlength=\"40\" error=\"errorLeanRegFirstName\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "\" />\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.firstName : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH  errorLeanRegFirstName\"></div>\n                        </div>\n                    </div>\n                    <div class=\"col-xs-12 col-md-6 col-lg-6 required errorLeanRegLastNameParent paddingLeftRight10 inputBoxHeight\">\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage registerLastNameIcon\"></span>\n                            <input type=\"text\" class=\"input-box-editor name avtarIcon registerLastName\" iconName=\"registerLastNameIcon\"\n                                name=\"lean-lastName\" maxlength=\"40\" error=\"errorLeanRegLastName\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\"\n                                title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "\" />\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.lastName : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH  errorLeanRegLastName\"></div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"form-group formFieldParent\">\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 required errorLeanRegEmailIdParent paddingLeftRight10 inputBoxHeight\">\n\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage registerEmailIdIcon\"></span>\n                            <input type=\"text\" class=\"input-box-editor avtarIcon registerEmailId\" name=\"lean-emailId\"\n                                iconName=\"registerEmailIdIcon\" maxlength=\"50\" error=\"errorLeanRegEmailId\" value=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.subscribeUser : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "\"\n                                title=\"Email\" />\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH  errorLeanRegEmailId\"></div>\n                            <div class=\"btn btn-default badge infotooltipPop popOverInfo\" data-container=\"body\"\n                                data-toggle=\"popover\" data-content=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.toolTipMessages : depth0)) != null ? stack1.emailToolTip : stack1), depth0))
    + "\"\n                                data-original-title=\"\" title=\"\">i</div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"form-group formFieldParent\">\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 required errorLeanRegPasswordParent paddingLeftRight10 inputBoxHeight\">\n\n                        <div class='input-box-container required'>\n                            <span class=\"avtarImage registerPasswordIcon\"></span>\n                            <input type=\"password\" class=\"input-box-editor avtarIcon registerPassword\" name=\"lean-password\"\n                                maxlength=\"20\" iconName=\"registerPasswordIcon\" autocomplete=\"new-password\" error=\"errorLeanRegPassword\"\n                                title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "\" />\n                            <span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "</span>\n                            <span class=\"input-box-active\"></span>\n                            <span class=\"input-box-error\"></span>\n                            <span class=\"input-box-success\"></span>\n                            <div class=\"errorPH errorLeanRegPassword\"></div>\n                            <div class=\"btn btn-default badge infotooltipPop popOverInfo leanPasswordToolTip lean-info-tooltip\"\n                                data-container=\"body\" data-toggle=\"popover\" data-content=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.toolTipMessages : depth0)) != null ? stack1.passWordToolTip : stack1), depth0))
    + "\"\n                                data-original-title=\"\" title=\"\">i</div>\n                        </div>\n                    </div>\n                </div>\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.phoneConsentSection : depth0)) != null ? stack1.showConsentForm : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "                <div class=\"form-group submitRegisterButton\">\n\n                    <div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n                        <button type=\"submit\" class=\"btn btn-primary \">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.register : stack1), depth0))
    + "</button>\n                    </div>\n                </div>\n";
  stack1 = this.invokePartial(partials['templates/loginRegister/registrationFooterCommon'], '                ', 'templates/loginRegister/registrationFooterCommon', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </div>\n        </form>\n        <div class=\"leanRegTooltip\"></div>\n    </div>\n    \n    <div class=\"ga-user-login-container\">\n\n        <div class=\"ga-login-container\">\n            <div class=\"ga-head\">Sign In</div>\n";
  stack1 = this.invokePartial(partials['templates/loginRegister/login'], '            ', 'templates/loginRegister/login', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "            <div class=\"ga-footer\">New user? <a class=\"open-register-view\" data-view=\"register\">Register</a></div>\n        </div>\n\n        <div class=\"ga-forgot-password-container\">\n";
  stack1 = this.invokePartial(partials['templates/loginRegister/forgotPassword'], '            ', 'templates/loginRegister/forgotPassword', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n    </div>\n    \n</div>";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('tpl/loginRegister/registrationNew', t);
return t;
});
/* END_TEMPLATE */
;