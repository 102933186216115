
/** Introduce Hubzu 
    * @var sourcePageName : the current Module -> used for introduce hubzu
*/
define('introduceHubzuView',['jquery', 'backbone','GoogleAnalyticsUtils', 'hbs!tpl/popups/introduceHubzu','introHubzuLib','loginRegisterView'], function ($,Backbone,GAUtils,introduceHubzu,introHubzuLib,loginRegisterView) {
    'use strict';
    var introduceHubzuView = Backbone.View.extend({
        el: $('#introduceHubzu'),
        events: {
            'click .contentNext':'paginationNextDot',
            'click .indicatorContainer .dot':'openCurrnetView',
            'click .intro-button':'redirectpage',
            'click .introduceHubzu' : 'hideIntroduceBackDrop',
            'click .startMySearch' : 'hidePopupAndScroll',
            'click .showRegisterTour':'showRegistrationTourPopup',
            "click .closeTourPopup" : 'closeTourPopup'
        },
        closeTourPopup : function(e){
            this.hideIntroduceBackDrop();
        },
        redirectpage: function(){
            if(pageName == 'srp'){
                this.loadIntroduceHubzuSRP();
            }else{
                this.loadIntroduceHubzuPDP();
            }
        },
        loadIntroduceHubzuPDP: function(e){
            var self = this;
            $(".introduceHubzuMask").hide();
            $(".introduceHubzu").hide(100);
            $("body").removeClass("no-scroll");
            $.each( introduceHubzuContent.introTour, function( key, value ) {
                if(deviceType == "MOBILE"){
                    introduceHubzuContent.introTour[key].selector = introduceHubzuContent.introTour[key].selector_grid_mobile;
                }else{                  
                    introduceHubzuContent.introTour[key].selector = introduceHubzuContent.introTour[key].selector_grid_desktop;
                }
            });
            if(isForeClosure != undefined && isForeClosure =="Y"){
                var objList = [{
                    "selector": introduceHubzuContent.introTour[0].selector,
                    "text": introduceHubzuContent.introTour[0].text,
                    "subtext": introduceHubzuContent.introTour[0].subtext,
                    "onEnter": function() {
                        if(deviceType == "MOBILE"){
                            //$('html, body').animate({scrollTop: $("#scheduleAskId").offset().top - 350}, 500);
                        } else{
                          $('html, body').animate({scrollTop: 0}, 500);
                          $(".pageintro-tooltip .arrow").addClass("middle");
                        }
                        
                        $(".cookieBanner,#webklipper-publisher-widget-container-content-expand-collapse").addClass("introCookieBannerhide");
                    },
                    "onExit": function() {
                      $(".sticky-nav").addClass("introCookieBannerhide");
                      $(".pageintro-tooltip .arrow").removeClass("middle");
                      GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','1. '+introduceHubzuContent.introTour[0].text+' - Next | visitorId: ' + $.cookie('visitorId'));
                    }
                },{
                      "selector": introduceHubzuContent.introTour[2].selector,
                      "text": introduceHubzuContent.introTour[2].text,
                      "subtext": introduceHubzuContent.introTour[2].subtext,
                      "onEnter": function() {
                        if(deviceType == "MOBILE"){
                              $("#featuresSection .mob-expand").show();
                          }
                          $(".btn-finish").addClass("getStartedBtn");
                          $('html, body').animate({scrollTop: $("#hubFeaturesSection").offset().top - 160}, 500);
                      },
                      "onExit": function() {
                        $('html, body').animate({scrollTop: 0}, 500);    
                        GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','2. '+introduceHubzuContent.introTour[2].text+' - Get Started | visitorId: ' + $.cookie('visitorId'));
                        PageIntro.finish();
                        self.showRegistrationTourPopup();
                        $(".cookieBanner,.sticky-nav").removeClass("introCookieBannerhide");
                      }
                  }]
              }else{
                  var objList = [{
                      "selector": introduceHubzuContent.introTour[1].selector,
                      "text": introduceHubzuContent.introTour[1].text,
                      "subtext": introduceHubzuContent.introTour[1].subtext,
                      "onEnter": function() {
                        if(deviceType == "MOBILE"){
                            $(".pageintro-hole").addClass("bidInfoRadius");
                            $("#introPlaceBid").addClass("active");
                        }else{
                            $('html, body').animate({scrollTop: 0}, 500); 
                            $(".pageintro-tooltip .arrow").addClass("middle");
                        }
                        $(".cookieBanner,#webklipper-publisher-widget-container-content-expand-collapse").addClass("introCookieBannerhide");
                      },
                      "onExit": function() {
                        if(deviceType == "MOBILE"){
                            $(".sticky-nav").addClass("introCookieBannerhide");
                            $(".pageintro-hole").removeClass("bidInfoRadius");
                            $("#introPlaceBid").removeClass("active");
                        }
                        GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','1. '+introduceHubzuContent.introTour[1].text+' - Next | visitorId: ' + $.cookie('visitorId'));
                      }
                  },{
                      "selector": introduceHubzuContent.introTour[2].selector,
                      "text": introduceHubzuContent.introTour[2].text,
                      "subtext": introduceHubzuContent.introTour[2].subtext,
                      "onEnter": function() {
                          if(deviceType == "MOBILE"){
                              $("#featuresSection .mob-expand").show();
                              $('html, body').animate({scrollTop: $("#hubFeaturesSection").offset().top - 300}, 500);
                          }else{
                              $('html, body').animate({scrollTop: $("#hubFeaturesSection").offset().top - 160}, 500);  
                          }
                          $(".btn-finish").addClass("getStartedBtn");
                          
                      },
                      "onExit": function() {
                        $(".pageintro-tooltip .arrow").removeClass("middle");
                        $('html, body').animate({scrollTop: 0}, 500);    
                        GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','2. '+introduceHubzuContent.introTour[2].text+' - Get Started | visitorId: ' + $.cookie('visitorId'));
                        PageIntro.finish();
                        self.showRegistrationTourPopup();
                        $(".cookieBanner,.sticky-nav").removeClass("introCookieBannerhide");
                      }
                  }]
              }
              var options = {
              container: 'body',
              spacing: 20,
              actions: {
                  next: {
                      text: 'Next',
                      class: 'btn btn-default nextBtn'
                  },
                  finish: {
                      text: 'Get Started',
                      class: 'btn btn-success getStartedBtn'
                  }
              },
              entries: objList
              };

              PageIntro.init(options);
              PageIntro.start();

              $('.close-x').on('click', function(){
                    self.closeIntroPopup('Close');
              });
              $(document).on('keyup',function(evt) {
                    if (evt.keyCode == 27) {
                        self.closeIntroPopup('Close');
                    }
              });
              $(document).click(function(event) { 
                  var $target = $(event.target);
                  if(!$target.closest('.pageintro-tooltip').length && 
                  $('.pageintro-tooltip').is(":visible") && !$target.hasClass('intro-button')) {
                    self.closeIntroPopup('Close');
                  }        
              });
              GAUtils.eventTracking.trackevent("",'IntroduceHubzu',this.activeTitle+' - HubzuTour | visitorId: ' + $.cookie('visitorId'));
        },
        loadIntroduceHubzuSRP: function(e){
            var self = this;
            $(".introduceHubzuMask").hide();
            $(".introduceHubzu").hide(100);
            $("body").removeClass("no-scroll");
            

            $.each( introduceHubzuContent.introTour, function( key, value ) {
                if(deviceType == "MOBILE"){
                    introduceHubzuContent.introTour[key].selector = introduceHubzuContent.introTour[key].selector_grid_mobile;
                    console.log(introduceHubzuContent.introTour[key].selector);
                }else{                  
                    introduceHubzuContent.introTour[key].selector = introduceHubzuContent.introTour[key].selector_grid_desktop;
                }
            });
            
            if(deviceType == "MOBILE"){
              var objList = [{
                    selector: introduceHubzuContent.introTour[0].selector,
                    text: introduceHubzuContent.introTour[0].text,
                    subtext: introduceHubzuContent.introTour[0].subtext,
                    onEnter: function() {
                            
                             $(".cookieBanner").addClass("introCookieBannerhide");
                    },
                    onExit: function() {
                            
                            GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','1. '+introduceHubzuContent.introTour[0].text+' - Next | visitorId: ' + $.cookie('visitorId'));

                    }
                }, {
                    selector: introduceHubzuContent.introTour[1].selector,
                    text: introduceHubzuContent.introTour[1].text,
                    subtext: introduceHubzuContent.introTour[1].subtext,
                    onEnter: function() {
                             if(deviceType == "MOBILE"){
                               $(".pageintro-tooltip").addClass("active");
                             }else{
                                $(".pageintro-tooltip .arrow").addClass("middle");
                             }
                    },
                    onExit: function() {
                             if(deviceType == "MOBILE"){
                               $(".pageintro-tooltip").removeClass("active");
                             }else{
                              $(".pageintro-tooltip .arrow").removeClass("middle");
                             }
                        GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','2. '+introduceHubzuContent.introTour[1].text+' - Next | visitorId: ' + $.cookie('visitorId'));
                    }
                }, {
                    selector: introduceHubzuContent.introTour[2].selector,
                    text: introduceHubzuContent.introTour[2].text,
                    subtext: introduceHubzuContent.introTour[2].subtext,
                    onEnter: function() {
                        if(deviceType == "MOBILE"){
                            $(".m-view-container").addClass('hide');
                         }
                    },
                    onExit: function() {
                        if(deviceType == "MOBILE"){
                           $(".m-view-container").removeClass('hide');
                        }
                        GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','3. '+introduceHubzuContent.introTour[2].text+' - Next | visitorId: ' + $.cookie('visitorId'));
                    }
                }, {
                    selector: introduceHubzuContent.introTour[3].selector,
                    text: introduceHubzuContent.introTour[3].text,
                    subtext: introduceHubzuContent.introTour[3].subtext,
                    onEnter: function() {
                        if(deviceType == "MOBILE"){
                           $(".pageintro-tooltip .arrow").addClass("middle");
                        }
                        $(".btn-finish").addClass("getStartedBtn");
                        $(".pageintro-hole").css("border-radius",'50%').css('padding','20px');
                        $(".headerHeartIconDesktop").css('padding-top','8px');

                    },
                    onExit: function() {
                        $(".headerHeartIconDesktop").css('padding-top','0px'); 
                        GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','4. '+introduceHubzuContent.introTour[3].text+' - Get Started | visitorId: ' + $.cookie('visitorId'));
                        PageIntro.finish();
                        self.showRegistrationTourPopup();
                        $(".cookieBanner").removeClass("introCookieBannerhide");
                    }
                }]
            }else{
              var objList = [{
                    selector: introduceHubzuContent.introTour[0].selector,
                    text: introduceHubzuContent.introTour[0].text,
                    subtext: introduceHubzuContent.introTour[0].subtext,
                    onEnter: function() {
                            
                             $(".cookieBanner").addClass("introCookieBannerhide");
                    },
                    onExit: function() {
                            GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','1. '+introduceHubzuContent.introTour[0].text+' - Next | visitorId: ' + $.cookie('visitorId'));

                    }
                }, {
                    selector: introduceHubzuContent.introTour[1].selector,
                    text: introduceHubzuContent.introTour[1].text,
                    subtext: introduceHubzuContent.introTour[1].subtext,
                    onEnter: function() {
                             if(deviceType == "MOBILE"){
                               $(".pageintro-tooltip").addClass("active");
                             }else{
                                $(".pageintro-tooltip .arrow").addClass("middle");
                             }
                    },
                    onExit: function() {
                             if(deviceType == "MOBILE"){
                               $(".pageintro-tooltip").removeClass("active");
                             }else{
                              $(".pageintro-tooltip .arrow").removeClass("middle");
                             }
                        GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','2. '+introduceHubzuContent.introTour[1].text+' - Next | visitorId: ' + $.cookie('visitorId'));
                    }
                }, {
                    selector: introduceHubzuContent.introTour[2].selector,
                    text: introduceHubzuContent.introTour[2].text,
                    subtext: introduceHubzuContent.introTour[2].subtext,
                    onEnter: function() {
                        
                         $(".btn-finish").addClass("getStartedBtn");
                    },
                    onExit: function() {
                        
                          PageIntro.finish();
                          self.showRegistrationTourPopup();
                          $(".cookieBanner").removeClass("introCookieBannerhide");
                        
                        GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour','3. '+introduceHubzuContent.introTour[2].text+' - Next | visitorId: ' + $.cookie('visitorId'));
                    }
                }]
            }




            var options = {
              container: 'body',
              spacing: 20,
              actions: {
                  next: {
                      text: 'Next',
                      class: 'btn btn-default nextBtn'
                  },
                  finish: {
                      text: 'Get Started',
                      class: 'btn btn-success getStartedBtn'
                  }
              },
              entries: objList
          };
          PageIntro.init(options);
          PageIntro.start();

          $('.close-x').on('click', function(){
                self.closeIntroPopup('Close');
          });
          $(document).on('keyup',function(evt) {
                if (evt.keyCode == 27) {
                    self.closeIntroPopup('Close');
                }
          });
           $(document).click(function(event) { 
                  var $target = $(event.target);
                  if(!$target.closest('.pageintro-tooltip').length && 
                  $('.pageintro-tooltip').is(":visible") && !$target.hasClass('intro-button')) {
                    self.closeIntroPopup('Close');
                  }        
             });
           GAUtils.eventTracking.trackevent("",'IntroduceHubzu',this.activeTitle+' - HubzuTour | visitorId: ' + $.cookie('visitorId'));
        },
        closeIntroPopup: function(clickPlace){
          if(deviceType == "MOBILE"){
             $(".m-view-container").removeClass('hide');
          }
          $("#introPlaceBid").removeClass("active");
          $(".cookieBanner,#webklipper-publisher-widget-container-content-expand-collapse").removeClass("introCookieBannerhide");
          PageIntro.finish();

          GAUtils.eventTracking.trackevent("",'IntroduceHubzu -Tour',$(".pageintro-overlay").attr("data-list")+'. '+$(".pageintro-tooltip").find(".pageintro-text").text()+' - '+clickPlace+' | visitorId: ' + $.cookie('visitorId'));
          
        },
        initialize: function() {
            try{
                var self = this;
                this.activeTitle ='';
                $('.introduceHubzuMask').click(function(e){
                    self.hideIntroduceBackDrop();
                });
                $(document).on('keyup',function(evt) {
                    if (evt.keyCode == 27) {
                        self.hideIntroduceBackDrop();
                    }
                });
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:initialize|" + e, null, 1);
            }

        },
        render: function(){
            try{
                var introHubzuContent ={};
                introHubzuContent.content = introduceHubzuContent;
                introHubzuContent.content.contentLength = Object.keys(introduceHubzuContent.introContent).length;
                this.activeTitle = '1.'+introduceHubzuContent.introContent[1].contentHead;
                $('#introduceHubzu').html(introduceHubzu(introHubzuContent));
                $(".indicatorContainer .dot").attr('total-index',introHubzuContent.content.contentLength);
                $('.dot1,.introText1').addClass('active');
                $(".introduceHubzuMask").show();
                $(".introduceHubzu").show(100);
                $("body").addClass("no-scroll");
                GAUtils.eventTracking.trackevent("",'Introduce Hubzu Popup Load','visitorId: ' + $.cookie('visitorId'), null, 1);
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:render|" + e, null, 1);
            }

        },
        hideIntroduceBackDrop:function(isRegister){
            try{
                $(".introduceHubzuMask").hide();
                $(".introduceHubzu").hide(100);
                
                loginRegisterView.addGoogleOneTapLib();

                $("body").removeClass("no-scroll");
                if(!(typeof isRegister != 'undefined' &&  isRegister)){
                    GAUtils.eventTracking.trackevent("",'IntroduceHubzu',this.activeTitle+' - Close | visitorId: ' + $.cookie('visitorId'));
                }

            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:hideIntroduceBackDrop|" + e, null, 1);
            }
        },
        hidePopupAndScroll : function(){
            try{
                this.hideIntroduceBackDrop(true);
                var startMySearch ='GoToHomepage';
                if(pageName == 'pdp'){
                  startMySearch ='GoToPDP';
                }else if(pageName == 'srp'){
                  startMySearch ='GoToSRP';
                }
                GAUtils.eventTracking.trackevent("",'IntroduceHubzu',this.activeTitle+' - '+startMySearch+' | visitorId: ' + $.cookie('visitorId'));

                window.scrollTo(0,0);
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:hidePopupAndScroll|" + e, null, 1);
            }

        },
        showRegistrationTourPopup : function(){
            try{
                localStorage.setItem('isNavFromTourpage', 'Y');
                this.hideIntroduceBackDrop(true);
                GAUtils.eventTracking.trackevent("",'IntroduceHubzu',this.activeTitle+' - Register | visitorId: ' + $.cookie('visitorId'));
                loginRegisterView.openLoginPopup({
                    openRegistration: 'Y'
                })
                
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:showRegistrationPopup|" + e, null, 1);
            }
        },
        openCurrnetView: function(e){
          try{
                var event = e.target;
                if($(event).hasClass("active")){
                  return false;
                }
                var currIndex= $(event).attr('curr-index');
                var totalIndex= $(event).attr('total-index');
                
                if(parseInt(currIndex) <= parseInt(totalIndex)){
                    currIndex = parseInt(currIndex);
                    $(event).attr('curr-index',currIndex);

                    $(".contentNext").attr('curr-index',currIndex);
                    $('.dot').removeClass('active');
                    $('.dot'+currIndex).addClass('active');
                    $('.introText').hide();
                    $('.introText'+currIndex).fadeIn('slow');
                    if(typeof introduceHubzuContent.introContent[currIndex].contentHead != 'undefined'){
                        this.activeTitle = currIndex+'.'+introduceHubzuContent.introContent[currIndex].contentHead;
                    }
                    $(".contentNext").removeClass('hide');
                    $('.closeTourPopup').addClass('hide');
                    if(parseInt(currIndex) == parseInt(totalIndex)){
                        $(".contentNext").addClass('hide');
                        $('.closeTourPopup').removeClass('hide');
                    }

                }else{
                    currIndex = totalIndex;
                    $(".contentNext").attr('curr-index',currIndex);
                    $(".contentNext").addClass('hide');
                    $('.closeTourPopup').removeClass('hide');

                }
                GAUtils.eventTracking.trackevent("",'IntroduceHubzu',this.activeTitle+' - Dot-click | visitorId: ' + $.cookie('visitorId'));
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:openCurrnetView|" + e, null, 1);
            }
        },
        paginationNextDot : function(e){
            try{
                var event = e.target;
                var currIndex= $(event).attr('curr-index');
                var totalIndex= $(event).attr('total-index');
                GAUtils.eventTracking.trackevent("",'IntroduceHubzu',this.activeTitle+' - Next | visitorId: ' + $.cookie('visitorId'));
                if(parseInt(currIndex) <= parseInt(totalIndex)){
                    currIndex = parseInt(currIndex) + 1;
                    $(event).attr('curr-index',currIndex);
                    $('.dot').removeClass('active');
                    $('.dot'+currIndex).addClass('active');
                    $('.introText').hide();
                    $('.introText'+currIndex).fadeIn('slow');
                    if(typeof introduceHubzuContent.introContent[currIndex].contentHead != 'undefined'){
                        this.activeTitle = currIndex+'.'+introduceHubzuContent.introContent[currIndex].contentHead;
                    }
                    
                    if(parseInt(currIndex) == parseInt(totalIndex)){
                        $(event).addClass('hide');
                        $('.closeTourPopup').removeClass('hide');
                    }
                }else{
                    currIndex = totalIndex;
                    $(event).addClass('hide');
                    $('.closeTourPopup').removeClass('hide');

                }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:paginationNextDot|" + e, null, 1);
            }
        },

    });  
    return introduceHubzuView;
});