
define('socialLoginView',[
	'jquery',
	'underscore',
	'backbone',
	'googleSignInView',
	'googleOneTapSignInView',
	'appleSignInView',
	'commonutils',
	'GoogleAnalyticsUtils'
	], function($, _, Backbone,googleSignInView,googleOneTapSignInView, appleSignInView, Utils,GAUtils){
		var gaLabel = 'visitorId: ' + $.cookie('visitorId');
	    var userSession = window.userSession || {};
	    var userId = userSession.userId || null;
	    if(userId){
	         gaLabel += '|userId: '+ userId;
	    }
	    var eventLabel = gaLabel;
		var socialLoginView = Backbone.View.extend({

			initialize: function(param){
				try{
					console.log("param"+param);
					if(param == "google"){
						this.googleSignInView =  new googleSignInView();
					}else if(param == "googleonetap"){
						this.googleOneTapSignInView =  new googleOneTapSignInView();	
					} else if(param == 'apple'){
						if(!this.appleSignInView){
							this.appleSignInView =  new appleSignInView();	
						}
					}
				}catch(e){
		            GAUtils.eventTracking.trackevent("Error", "SocialLogin", "file:socialLoginView.js|function:initialize|" + e, null, 1);
	          	}	
			},

			doSocialLogin: function(options){
				try{
					var self = this;
					self.callback = options.callback;
					self.viewObject = options.viewObject;
					self.funnelStart = options.funnelStart;
					self.srcName = options.srcName;
					self.socialSource = options.socialSource;
					var callBackOptions = {
		              	callback: self.loginWithSocialIdToken,
		              	callbackContextObj: self
		            }
					if(self.socialSource == "google"){
						self.googleSignInView.googleLogin(callBackOptions);
					}else if(self.socialSource == "googleonetap"){
						self.googleOneTapSignInView.googleOneTapLogin(callBackOptions);	
					} else if(self.socialSource == "apple"){
						self.appleSignInView.appleLogin(callBackOptions);	
					}
				}catch(e){
		            GAUtils.eventTracking.trackevent("Error", "SocialLogin", "file:socialLoginView.js|function:doSocialLogin|" + e, null, 1);
	          	}
			},

			loginWithSocialIdToken: function(token,contextObj){
				try{
					var self = contextObj;
					var socialSigninUrl =  (contextPath.indexOf('portal') > -1) ? contextPath + '/socialSignIn' : contextPath + '/portal/socialSignIn';
					var socialSource = self.socialSource;
					if(socialSource == 'googleonetap'){
						socialSource = 'google';
					}
					
					if(socialSource === 'apple'){
						socialSigninUrl = socialSigninUrl + "?service=appleWeb";
						var socialRequestData = {
							jwtToken: token.authorization.id_token,
							code:token.authorization.code,
							firstName:(token.user && token.user.name && token.user.name.firstName) ? token.user.name.firstName : '',
							lastName:(token.user && token.user.name && token.user.name.lastName) ? token.user.name.lastName : '',
							emailId: token.user.email ? token.user.email : '',
							"encAlgVersion" :"V4"
						};
					} else {
						socialSigninUrl = socialSigninUrl + "?service="+socialSource;
						var socialRequestData = {
							token: token,
							service: socialSource,
							"encAlgVersion" :"V4"
						};
					}
					
					var options =  {
						formObject : "#loginRegisterDialog",
						viewObject: self.viewObject || self,
	                    callback: self.callback || null,
						funnelStart: self.funnelStart || false,
						srcName: self.srcName || false
					};

					$("#ajax-loader").show();
					$("#ajax-loader-mask").show();

					if(socialSource === 'apple'){
						$.ajax({ 
							type: "POST",
							url: socialSigninUrl,
							xhrFields: {withCredentials: true},
							data: JSON.stringify(socialRequestData),
							dataType: 'json',
							contentType: 'application/json',
							success: function(response){
								if(response.status=="SUCCESS" && response.data && response.data.isRegistered){
									// FOR APP - firebase logging
									var visitor_id = $.cookie('visitorId') || '';
									if(window.notifyNativeApp){
										window.notifyNativeApp(JSON.stringify({
											message: "logGaEvent",
											category: "event",
											type: "register",
											action: "Register Completed",
											source: visitor_id
										}), '*');
									}
									// If subscribed user completes his registration, then delete his session
									if(Utils.getCookieDetails('subscribeEmail') && window.atob(Utils.getCookieDetails('subscribeEmail')) ===  $.trim($('#emailId').val()) ){
										Utils.deleteCookie('subscribeEmail');
									}
									var callbackContextObj = self.viewObject;
									if(!callbackContextObj.isPlaceBidClicked){
										options.callback = callbackContextObj.afterRegistrationCallBack || null;
										options.viewObject = callbackContextObj || self;
									}
									// GAUtils.eventTracking.trackevent("","Register Completed",eventLabel+"| Source: Social - Google");
									options.trackRegistrationEvent = true;
									options.gaLabel = eventLabel + "| Source: Social - Google";
								}
								$("#ajax-loader").hide();
								$("#ajax-loader-mask").hide();

								if($("body").hasClass("h-srp-page") && ($(".popover").hasClass("in") || $("#commonConfirmPopup").hasClass("in"))){
									$(".popover,#commonConfirmPopup").remove();
									$(".modal-backdrop").removeClass("in");
									if(savedListingPropId != undefined && savedListingPropId != ""){
										var propertyId = savedListingPropId;
										$.post(contextPath + '/portal/watchProperty/'+propertyId+'?notifyType=DAILY', function(res){
											Utils.processAfterSignInSucess(response,options, self.socialSource);
										});												
									}
										
								}else{
									Utils.processAfterSignInSucess(response,options, self.socialSource);
								}
								
								
							},
							error: function(xhrObject, textStatus, errorThrown){
								$("#ajax-loader").hide();
								$("#ajax-loader-mask").hide();
								$('#gobalSignInSubmit').prop('disabled', false);
								if(self.socialSource && self.socialSource == 'googleonetap'){
									$('#errorToastMessage #errorContent .errorMessage').html('Internal server error, please try again later').focus();
									$('#errorToastMessage').fadeIn();
									$('#errorToastMessage').fadeIn('300').delay(5000).fadeOut('slow');
								} else if(self.socialSource && self.socialSource == 'apple'){
									$('#appleSocialLoginSubmit').prop('disabled', false);
									$('#errorToastMessage #errorContent .errorMessage').html('Internal server error, please try again later').focus();
									$('#errorToastMessage').fadeIn();
									$('#errorToastMessage').fadeIn('300').delay(5000).fadeOut('slow');
								} else {
									$(options.formObject + ' .login-form-error.responseError').html('Internal server error, please try again later');
									$(options.formObject + ' .login-form-error.responseError').show();
								}
								GAUtils.eventTracking.trackevent("Error", "SocialLogin", "file:socialLoginView.js|function:loginWithSocialIdToken|Ajax error|" + errorThrown, null, 1);
							}
						});
					} else {
						$.ajax({ 
							type: "POST",
							url: socialSigninUrl,
							xhrFields: {withCredentials: true},
							data: JSON.stringify(socialRequestData),
							// data:socialRequestData,
							dataType: 'json',
							contentType: 'application/json',
							success: function(response){
								if(response.status=="SUCCESS" && response.data && response.data.isRegistered){
									// FOR APP - firebase logging
									var visitor_id = $.cookie('visitorId') || '';
									if(window.notifyNativeApp){
										window.notifyNativeApp(JSON.stringify({
											message: "logGaEvent",
											category: "event",
											type: "register",
											action: "Register Completed",
											source: visitor_id
										}), '*');
									}
									// If subscribed user completes his registration, then delete his session
									if(Utils.getCookieDetails('subscribeEmail') && window.atob(Utils.getCookieDetails('subscribeEmail')) ===  $.trim($('#emailId').val()) ){
										Utils.deleteCookie('subscribeEmail');
									}
									var callbackContextObj = self.viewObject;
									if(!callbackContextObj.isPlaceBidClicked){
										options.callback = callbackContextObj.afterRegistrationCallBack || null;
										options.viewObject = callbackContextObj || self;
									}
									// GAUtils.eventTracking.trackevent("","Register Completed",eventLabel+"| Source: Social - Google");
									options.trackRegistrationEvent = true;
									options.gaLabel = eventLabel + "| Source: Social - Google";
								}
								$("#ajax-loader").hide();
								$("#ajax-loader-mask").hide();

								if($("body").hasClass("h-srp-page") && ($(".popover").hasClass("in") || $("#commonConfirmPopup").hasClass("in"))){
									$(".popover,#commonConfirmPopup").remove();
									$(".modal-backdrop").removeClass("in");
									if(savedListingPropId != undefined && savedListingPropId != ""){
										var propertyId = savedListingPropId;
										$.post(contextPath + '/portal/watchProperty/'+propertyId+'?notifyType=DAILY', function(res){
											Utils.processAfterSignInSucess(response,options, self.socialSource);
										});												
									}
										
								}else{
									Utils.processAfterSignInSucess(response,options, self.socialSource);
								}
								
								
							},
							error: function(xhrObject, textStatus, errorThrown){
								$("#ajax-loader").hide();
								$("#ajax-loader-mask").hide();
								$('#gobalSignInSubmit').prop('disabled', false);
								if(self.socialSource && self.socialSource == 'googleonetap'){
									$('#errorToastMessage #errorContent .errorMessage').html('Internal server error, please try again later').focus();
									$('#errorToastMessage').fadeIn();
									$('#errorToastMessage').fadeIn('300').delay(5000).fadeOut('slow');
								} else if(self.socialSource && self.socialSource == 'apple'){
									$('#appleSocialLoginSubmit').prop('disabled', false);
									$('#errorToastMessage #errorContent .errorMessage').html('Internal server error, please try again later').focus();
									$('#errorToastMessage').fadeIn();
									$('#errorToastMessage').fadeIn('300').delay(5000).fadeOut('slow');
								} else {
									$(options.formObject + ' .login-form-error.responseError').html('Internal server error, please try again later');
									$(options.formObject + ' .login-form-error.responseError').show();
								}
								GAUtils.eventTracking.trackevent("Error", "SocialLogin", "file:socialLoginView.js|function:loginWithSocialIdToken|Ajax error|" + errorThrown, null, 1);
							}
						});
					}
			  	}catch(e){
	            	GAUtils.eventTracking.trackevent("Error", "SocialLogin", "file:socialLoginView.js|function:loginWithSocialIdToken|" + e, null, 1);
	          	}
			}
		});
	return socialLoginView;
});