
/* START_TEMPLATE */
define('hbs!tpl/subscribe/subscribeView',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<div class=\"hz-save-listing\" id=\"hz-save-listing\">\n    <div class=\"save-engage-data\">\n        <div id=\"save-engage-reg\">\n            \n                <div class=\"row\">\n                    <div class=\"col-lg-8 col-md-9 col-xs-8\">\n                        <div class=\"save-engage-title\"> \n                            <svg  version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" enable-background=\"new 0 0 24 24\" xml:space=\"preserve\">\n                            <path  fill=\"#d83e3b\" enable-background=\"new\" d=\"M20.7,6.2c-3-3.9-8.8-1.6-8.8,2.3C11.9,4.6,6,2.3,3,6.2c-3.1,4,0,11,8.8,15\n                            C20.8,17.2,23.8,10.2,20.7,6.2z\"></path>\n                            <path class=\"icon-outer-layer\" fill=\"none\" stroke=\"#d83e3b\" stroke-width=\"2\" stroke-miterlimit=\"10\" d=\"M20.7,6.2c-3-3.9-8.8-1.6-8.8,2.3\n                            C11.9,4.6,6,2.3,3,6.2c-3.1,4,0,11,8.8,15C20.8,17.2,23.8,10.2,20.7,6.2z\"></path>\n                            </svg> \n                            <span class=\"saveHead\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.subscribeHead : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n                        </div>\n                    </div>\n                    <div class=\"col-lg-4  col-md-3 closeButtonDiv col-xs-4\">\n                        <button class=\"closeButtonPopover\" title=\"close\" aria-label=\"close\">\n                            <svg class=\"\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" enable-background=\"new 0 0 24 24\" xml:space=\"preserve\">\n                                <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" x1=\"2.9\" y1=\"21.2\" x2=\"20.7\" y2=\"3.3\"></line>\n                                <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" x1=\"20.7\" y1=\"21.2\" x2=\"2.9\" y2=\"3.3\"></line>\n                            </svg>\n                        </button>\n                    </div>\n                </div>\n                <div class=\"row\">\n                    <div class=\"col-lg-12\">\n                        <p class=\"save-engage-data-text vspace-10\">\n                            ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.subscribeContent : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "                        \n                        </p>\n                    </div>\n                </div>\n                <div class=\"row\">\n                    <div class=\"col-lg-8 col-xs-7 text-box-container\">\n                        <div class=\"h-textbox-container\">\n                            <input type=\"text\"  title=\"Email address\" class=\"hz-subscribe-email save-engage-input-fileds nameField h-textbox\" error=\"errorSaveEmail\" name=\"save-enagage-email\" maxlength=\"80\" id=\"hz-subscribe-email\" data-required=\"yes\">\n                            <label class=\"h-textbox-label emailLabel\" for=\"hz-subscribe-email\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.emailLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</label>\n                            <span class=\"h-textbox-error errorSaveEmail\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.emailError : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n                        </div>\n                    </div>\n                    <div class=\"col-lg-4 col-xs-4 action-container\">\n                        <input title=\"Register\" value=\"Register\" class=\"hz-subscribe-btn h-button-1\" type=\"button\">\n                    </div>\n                </div>\n                <div class=\"row\">\n                    <div class=\"save-engage-footer clearfix vspace-10 with-out-social-login\">\n                        <div class=\"half-width vspace-10\">\n                            <p class=\"save-engage-data-text\">\n                                ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.alreadyregister : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                            </p>\n                            <button class=\"sign-in-link login-opener\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.loginLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</button>\n                        </div>\n                        <div class=\"vspace-10\"></div>\n                    </div>\n                </div>\n        </div>\n    </div>\n</div>\n\n\n";
},"useData":true});
Handlebars.registerPartial('tpl/subscribe/subscribeView', t);
return t;
});
/* END_TEMPLATE */
;