
/* START_TEMPLATE */
define('hbs!templates/globalAlerts/arrow',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  return "<svg version=\"1.1\" class=\"arrow\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" viewBox=\"0 0 9 24\" style=\"enable-background:new 0 0 9 24; height: 20px; width: 10px;\" xml:space=\"preserve\">\n<style type=\"text/css\">\n	.arrow-st0{fill:#000;}\n</style>\n<polygon class=\"arrow-st0\" points=\"1.1,0 8.9,11.9 1.1,24 0,24 7.9,12 0.2,0 \"/>\n</svg>";
  },"useData":true});
Handlebars.registerPartial('templates/globalAlerts/arrow', t);
Handlebars.registerPartial('templates/globalAlerts/arrow', t);
return t;
});
/* END_TEMPLATE */
;