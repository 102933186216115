
define('OutbidAlertsView',['require','jquery','backbone','GoogleAnalyticsUtils','HomeUtils','subscribeView','commonutils','loginRegisterView'],function (require) {
    "use strict";

    var $ = require("jquery"),
        Backbone = require("backbone"),
        GAUtils = require("GoogleAnalyticsUtils"),
        HomeUtils = require("HomeUtils"),
        SubscribeView = require("subscribeView"),
        CommonUtils = require("commonutils"),
        loginRegisterView = require("loginRegisterView"),
        OutbidAlertsView;

    OutbidAlertsView = Backbone.View.extend({
        events: {
            "click .ha-placebid-btn": "goToBidwizard",
            "click .ha-favorite-btn": "updateFavoriteStatus"
        },
        initialize: function () {

            try {
             
                var timeLeft,
                timer,
                self = this;

                var timer = setInterval(function(){
                    $(".h-alert").each(function () {
                        if($(this).data('type') && $(this).data('type') != "BACKUPBID" && $(this).data('type') != "BACKUP_BID_EXPIRY"){
                            timeLeft = $(this).find(".ha-prop-aucn-timeleft").attr("data-timeleft");
                            timeLeft = timeLeft.replace(/\,/g, '');
                            timeLeft = parseInt(timeLeft);
            
                            timeLeft = timeLeft - 1000;
                            if (timeLeft < 0) {
                                // Hide property tuple if auction time is 0
                                $(this).fadeOut("slow", function(){
                                    // Hide entire section if all properties acution ended
                                    if (!$(".h-alerts-list .h-alert").is(":visible")){
                                        $(".h-outbid-section-container").fadeOut("slow");
                                    }
                                });
                                return;
                            }

                            $(this).find(".ha-prop-aucn-timeleft").attr("data-timeleft", timeLeft)
            
                            if (window.globalAlertView) {
                                timer = window.globalAlertView.calculateTimeLeft({ millseconds: timeLeft, sendIndividualUnits: true }) || '';
                                timer = HomeUtils.buildTimer({
                                    formattedTime: timer
                                })
                                if (timer) {
                                    $(this).find('.ha-prop-aucn-timeleft .t-value').html(timer);
                                }
                            }
                        }
                    });
                }, 1000);

                // GA for image and address click - redirec to PDP
                $(".ha-prop-image-link").unbind("click").bind("click", function(){
                    var label = $(this).attr("aria-label"),
                        gaLabel = "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId'),
                        position;
        
                    position = window.htimestamp.home.getComponentPosition({id: $(self.$el).attr("id") })
                    gaLabel += " | Section position: " + (position > -1 ? position : '');
                
                    if ($(event.currentTarget).attr('data-prop-pos')) {
                        gaLabel += " | Property position: " + $(event.currentTarget).attr('data-prop-pos');
                    }
                    
                    GAUtils.eventTracking.trackevent(null,'Outbid section - ' + label + ' click', gaLabel, "");
                });
                
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Outbid section - Home Page", "file:OutbidAlertsView.js|function:initialize|" + error, null, 1);
            }
        },
        goToBidwizard: function (event) {
            try {
                var isFormCrated = false,
                    bidType = $(event.target).attr("data-bidType"),
                    amount = $(event.target).attr("data-amount"),
                    listingId = $(event.target).attr("data-listingId"),
                    cPath = typeof contextPath !== "undefined" ? contextPath : '',
                    url = cPath + '/portal/bid?ref=homePageOutbidAlts',
                    gaLabel = "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId'),
                    position;
    
                position = window.htimestamp.home.getComponentPosition({id: $(this.$el).attr("id") })
                gaLabel += " | Section position: " + (position > -1 ? position : '');
                
                if ($(event.currentTarget).attr('data-prop-pos')) {
                    gaLabel += " | Property position: " + $(event.currentTarget).attr('data-prop-pos');
                }

                
                if(bidType && (bidType == "PLACE_BACKUP_BID" || bidType == "BACKUP_BID_EXPIRY")){
                    var eventAction = (bidType == "PLACE_BACKUP_BID") ? 'Place backup bid click' : 'Update backup bid time limit click'
                    if(!$('.gobal-alerts-drawer-slider').hasClass('is-visible') && window.globalAlertView && window.globalAlertView.outBidData && !window.globalAlertView.outBidData.fromOutBid){
                        GAUtils.eventTracking.trackevent("New-Homepage",eventAction, gaLabel, "");
                        window.globalAlertView.outBidData = {
                            listingId :listingId,
                            fromOutBid : true
                        };
                        $('.globalAlertsSvgIcon').trigger('click');
                    }
                } else {
                    GAUtils.eventTracking.trackevent(null,'Outbid section - place bid click', gaLabel, "");
                    isFormCrated = HomeUtils.createForm({
                        bidType: bidType,
                        amount: amount,
                        url: url,
                        listingId: listingId
                    });
    
                    if (isFormCrated)
                        $("#submitBidForm").submit();
                }

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Outbid section - Home Page", "file:OutbidAlertsView.js|function:goToBidwizard|" + error, null, 1);
            }
        },
        updateFavoriteStatus: function(event) {
            try {
                
                var self = this,
                    targetObject = $(event.currentTarget),
                    isFavorited = targetObject.hasClass('Y') ? true : false,
                    data = {},
                    propertyId = targetObject.attr('data-propertyId'),
                    updateFavoritesStatus,
                    currentUserId;
                
                data = {
                    currentObj: targetObject,
                    propertyId: propertyId
                };

                updateFavoritesStatus = function(params) {
                    if ( isFavorited ) {
                        // Un favorite property 
                        SubscribeView.deleteFavorite(params);
                    } else {
                        // Favorite propety 
                        SubscribeView.addToFavorite(params);
                    }
                }

                currentUserId = window.userSession.userId || '';
                CommonUtils.checkIfLoggedIn({
                    callback: function (response) {
                        response = response || {};
                        if (!response.userEmailId) {
                            loginRegisterView.openLoginPopup({
                                callback: function(userResponse, options){
                                    
                                    if (currentUserId && currentUserId !== userResponse.user.realbidUserId) {
                                        window.location.reload();
                                        return;
                                    }

                                    if (options.data)
                                        updateFavoritesStatus(options.data);

                                },
                                viewObject: {
                                    viewObject: self,
                                    data: data
                                },
                                statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                            });
                            return;
                        } 

                        updateFavoritesStatus(data);
                    }
                });

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Outbid section - Home Page", "file:OutbidAlertsView.js|function:updateFavoriteStatus|" + error, null, 1);
            }
        },
        removeProperty: function(params) {
            try {
                
                params = params || {};

                var propertyId = params.propertyId || null;

                if ( !propertyId ) return;

                if ($(".ha-prop-" + propertyId + " .ha-favorite-btn").length) {
                    $(".ha-prop-" + propertyId + " .ha-favorite-btn").removeClass("Y");
                    if ( $(".ha-prop-" + propertyId).attr('data-type') == "FAVORITES" ) {
                        $(".ha-prop-" + propertyId).fadeOut("slow", function() {
                            if (!$(".h-alerts-list .h-alert").is(":visible")){
                                $(".h-outbid-section-container").fadeOut("slow");
                            }
                        });
                    }
                }
            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Outbid section - Home Page", "file:OutbidAlertsView.js|function:removeProperty|" + error, null, 1);
            }
        },
        removeOutbidTuple: function(params) {
            try {
                params = params || {};

                var listingId = params.listingId || null;

                if ( !listingId ) return;

                if ($(".ha-listing-" + listingId).length) {
                    $(".ha-listing-" + listingId).fadeOut("slow", function() {
                        if (!$(".h-alerts-list .h-alert").is(":visible")){
                            $(".h-outbid-section-container").fadeOut("slow");
                        }
                    });
                }

            } catch (error) {
                GAUtils.eventTracking.trackevent("Error", "Outbid section - Home Page", "file:OutbidAlertsView.js|function:removeOutbidTuple|" + error, null, 1);
            }
        }
    });
    return OutbidAlertsView;
});