
define('dUtil',['jquery'], function($) {
    'use strict';

    var GAUtils = require("GoogleAnalyticsUtils");

    var dUtil = {
		jqueryPost : function (url , inputData , callBack , callBackParam) {
            try{
	            var data = '';
	            if(typeof inputData === 'object'){
	                data = JSON.stringify(inputData);
	            }else{
	                data = inputData;
	            }
				if(callBackParam.setCustomHeader){
					$.ajaxSetup({
						beforeSend: function (xhr) {
							xhr.setRequestHeader ("Authorization", "Bearer " + $.cookie('_fcat'));
						}
					});
				}
				
	            jQuery.ajax ({
	                url: url,
	                type: "POST",
	                data: data,
	                dataType: "json",
	                contentType: "application/json; charset=utf-8",
	                success: function(response){
	                    callBack(response , inputData , callBackParam);
	                },
	                error: function(response, textStatus, errorThrown){
	                    callBack(response , inputData , callBackParam);
	                }
	            });
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:jqueryPost|url:"+url+':'+e, null, 1);
            }
        },
        jqueryPostJson : function (url , inputData , callBack , callBackParam) {
            try{
	            var data = '';
	            jQuery.ajax ({
	                url: url,
	                type: "POST",
	                data: inputData,
	                dataType: "json",
	                success: function(response){
	                    callBack(response , inputData , callBackParam);
	                },
	                error: function(){
	                    callBack(null , inputData , callBackParam);
	                }
	            });
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:jqueryPostJson|url:"+url+':'+e, null, 1);
            }
        },

        postUrlCall : function(url , inputData , callback , callBackParam){
            try{
	        	jQuery.ajax ({
	                url: url,
	                type: "POST",
	                xhrFields: {withCredentials: true},
	                data: inputData,
	                success: function(response){
	                    callback(response , callBackParam);
	                },
	                error: function(){
	                    callback(null , callBackParam);
	                }
	            });
            }catch(e){
				GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:postUrlCall|url:"+url+':'+e, null, 1);
			}
        },

		jqueryGet : function (url , inputData, callBack , callBackParam) {
            try{

            	if(callBackParam==undefined)
            	{
            		callBackParam = {};
            	}

            	if(callBackParam.xhr!=undefined && callBackParam.xhr && callBackParam.xhr.readyState!=4)
            	{
            		try
            		{
	            		callBackParam.xhr.abort();
	            		callBackParam.xhr = '';
	            	}catch(e){
	            		
	            	}
				}
				if(callBackParam.setCustomHeader){
					$.ajaxSetup({
						beforeSend: function (xhr) {
							xhr.setRequestHeader ("Authorization", "Bearer " + $.cookie('_fcat'));
						}
					});
				}
				

	            callBackParam.xhr = jQuery.ajax ({
	                url: url,
	                type: "GET",
	                dataType: "json",
	                contentType: "application/json; charset=utf-8",
	                success: function(response){
	                    callBack(response , inputData, callBackParam);
	                },
	                error: function(){
	                    callBack(null , inputData, callBackParam);
	                }
	            });
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:jqueryGet|url:"+url+':'+e, null, 1);
            }
        },

        timeLeft : function(enddate){
            try{
	            var date_future = new Date(enddate);
	            var date_now = new Date();

	            var seconds = Math.floor((date_future - (date_now))/1000);
	            var minutes = Math.floor(seconds/60);
	            var hours = Math.floor(minutes/60);
	            var days = Math.floor(hours/24);
	        
	            hours = hours-(days*24);
	            minutes = minutes-(days*24*60)-(hours*60);

	            return dashboardContent.watchProperty.labels.timeLeft+': ' +days+':'+hours+':'+minutes;
	        }catch(e){
	        	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:timeLeft|" + e, null, 1);
	        }
        },

        formatTimeCountDown : function(timeLeft , mode , content,bidId){
        	try{
        		//timeLeft = 996527;
	        	var bidLeftTime = timeLeft/1000;
	        	return "<div class='bid-time defaultBidTime' bidId='"+bidId+"' timeSeconds='"+bidLeftTime+"' timeLeft ='"+timeLeft+"'' timeLeftFormat='lableBelow'></div>";
		    }catch(e){
		    	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:formatTimeCountDown|" + e, null, 1);
		    }
        },
        formatTime : function(timeLeft , mode , content){
        	try{	        	
	        	if(timeLeft < 0 ){
	        		timeLeft=0;
	        	}
	        	var bidLeftTime = timeLeft/1000;

	        	var timeLeft = '<div class="bid-time clearfix"><div><span class="bid-time {colorClass} bid-hours">{DHM}</span><span class="bid-time-label bid-hour-label">{DHMC}</span></div><div><span class="bid-time {colorClass}">:</span><span class="bid-time-label">&nbsp;</span></div><div><span class="bid-time {colorClass} bid-mins">{HMS}</span><span class="bid-time-label bid-min-label">{HMSC}</span></div></div>';

	            var secs = Math.floor(bidLeftTime % 60);
	            var mins = Math.floor(bidLeftTime/60) % 60;
	            var hours = Math.floor(bidLeftTime/60/60) % 24;
	            var days = Math.floor(bidLeftTime/60/60/24);

	            days = days < 10 ? '0'+days : days;
	            hours = hours < 10 ? '0'+hours : hours;
	            mins = mins < 10 ? '0'+mins : mins;
	            secs = secs < 10 ? '0'+secs : secs;

	            if(mode === 'Compact'){
	            	return content.timeLeft+': ' +days+':'+hours+':'+mins;
	            }else{
		            if(days !== '00'){
		            	timeLeft = timeLeft.replace('{DHM}' , days);
		            	timeLeft = timeLeft.replace('{DHMC}' , content.days);
		            	timeLeft = timeLeft.replace('{HMS}' , hours);
		            	timeLeft = timeLeft.replace('{HMSC}' , content.hours);
		            	timeLeft = timeLeft.replace(/{colorClass}/g , 'defaultColor');
		            }else if(hours !== '00'){
		            	timeLeft = timeLeft.replace('{DHM}' , hours);
		            	timeLeft = timeLeft.replace('{DHMC}' , content.hours);
		            	timeLeft = timeLeft.replace('{HMS}' , mins);
		            	timeLeft = timeLeft.replace('{HMSC}' , content.mins);
		            	timeLeft = timeLeft.replace(/{colorClass}/g , '');
		            }else{
		            	timeLeft = timeLeft.replace('{DHM}' , mins);
		            	timeLeft = timeLeft.replace('{DHMC}' , content.mins);
		            	timeLeft = timeLeft.replace('{HMS}' , secs);
		            	timeLeft = timeLeft.replace('{HMSC}' , content.seconds);
		            	timeLeft = timeLeft.replace(/{colorClass}/g , '');
		            }
		            return timeLeft;
		        }
		    }catch(e){
		    	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:formatTime|" + e, null, 1);
		    }
        },

        formatDateString : function(num){
        	try{
	        	if(num < 10){
	        		return num = '0'+num; 
	        	}
	        	return num;
	        }catch(e){
	        	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:formatDateString|" + e, null, 1);
	        }
        },

        getCurrentDate : function(){
        	try{
        		/*var d = new Date,
        			dformat = [ this.formatDateString(d.getMonth()+1),
                    	this.formatDateString(d.getDate()),
                    	d.getFullYear()].join('/')+
                    	' ' +
                  		[ this.formatDateString(d.getHours()),
                    	this.formatDateString(d.getMinutes())].join(':');*/
                var monthArray = dashboardContent.bidsOffers.labels.monthArray;
                var monthNames = monthArray.split(',');
                var d = new Date();
                var milliSecCalc = this.formatDateString(d.getMilliseconds());
                milliSecCalc = milliSecCalc.toString().slice(0,2);
                var dformat = [monthNames[d.getMonth()],this.formatDateString(d.getDate()+','),
                    	d.getFullYear()].join(' ')+' '+
                  		[ this.formatDateString(d.getHours()),
                    	this.formatDateString(d.getMinutes()),milliSecCalc].join(':');
     			return dformat;
        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:getCurrentDate|" + e, null, 1);
        	}
        },

        displayPrice : function(obj){
            try{
	            if(obj.propertyCategory && obj.propertyCategory == 'NPL' && obj.listPrice!=undefined && obj.listPrice!='' && obj.listPrice!=null && obj.listPrice!=0){
					return dashboardContent.watchProperty.labels.listingPrice+': ' + this.formatCurrencyLabel(obj.listPrice);
				} else if(obj.listingType === 'TRNL'){
	                if(obj.currentHighestPrice != null && obj.currentHighestPrice!=0){
	                    return dashboardContent.watchProperty.labels.currentHighestOffer+': ' + this.formatCurrencyLabel(obj.currentHighestPrice);
	                }else if(obj.listPrice!=undefined && obj.listPrice!='' && obj.listPrice!=null && obj.listPrice!=0){
	                    return dashboardContent.watchProperty.labels.listingPrice+': ' + this.formatCurrencyLabel(obj.listPrice);
	                }
	            }else if(obj.listingType === 'AUCN'){
	                if(obj.currentHighestPrice != null && obj.currentHighestPrice!=0){
	                    return dashboardContent.watchProperty.labels.currentHighestBid+': ' + this.formatCurrencyLabel(obj.currentHighestPrice);
	                }else if(this.formatCurrencyLabel(obj.startingPrice)!=undefined &&this.formatCurrencyLabel(obj.startingPrice)!=null && this.formatCurrencyLabel(obj.startingPrice)!='' && this.formatCurrencyLabel(obj.startingPrice)!=0){
	                    return dashboardContent.watchProperty.labels.startingBid+': ' + this.formatCurrencyLabel(obj.startingPrice);
	                }
	            } else {
	            	return dashboardContent.watchProperty.labels.startingBid+': NA'	;
	            }
	        }catch(e){
	        	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:displayPrice|" + e, null, 1);
	        }
        },

        formatCurrencyLabel : function(num){
            try{
	            num = num.toString().replace(/\$|\,/g,'');
	            if(isNaN(num)){
	                num = "0";
	                return num;
	            }else{
	                for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
	                    num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
	                }
	                return ('$' + num);
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:formatCurrencyLabel|" + e, null, 1);
            }
        },
        formatAmount : function(num){
            try{
	            num = num.toString().replace(/\$|\,/g,'');
	            if(isNaN(num)){
	                num = "0";
	                return num;
	            }else{
	                for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
	                    num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
	                }
	                return ( num);
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:formatAmount|" + e, null, 1);
            }
        },

        formatSqFt : function(num){
            try{
	            num = num.toString().replace(/\$|\,/g,'');
	            if(isNaN(num)){
	                num = "0";
	                return num;
	            }else{
	                for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++){
	                    num = num.substring(0,num.length-(4*i+3))+','+num.substring(num.length-(4*i+3));
	                }
	                return (num + ' ' + dashboardContent.savedSearches.labels.squareFeet);
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:formatSqFt|" + e, null, 1);
            }
        },

        truncate : function(string , limit) {
        	try{
	        	var initialString = string;
				if(initialString.length>limit)
				{
					return initialString.substr(0,limit)+'<div class="popOverInfo" data-toggle="popover" data-content="'+string+'">...</div>';
				}else{return initialString}
			}catch(e){
				GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:truncate|" + e, null, 1);
			}
		},

        showToast : function (msg) {
        	try{
	        	if(screen.width > 767){
	        		if($(window).scrollTop() > 100){
	        			var top = $(window).scrollTop();
	        			top = top + 'px';
	        			$('#toast').css('top' , top);
	        		}else{
	        			$('#toast').css('top','20px');
	        		}
	        		$(window).bind('scroll' , this.toastOnScroll);
	        	}
	        	$('#toast').html(msg).fadeIn( "slow"  , function(){
					setTimeout(function(){ 
							$('#toast').fadeOut('slow').focus(); 
						}, 3000);
	            });
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:showToast|" + e, null, 1);
            }
        },

        toastOnScroll : function(){
        	try{
	        	if($(window).scrollTop() > 40){
	    			var top = $(window).scrollTop();
	    			top = top + 'px';
	    			$('#toast').css('top' , top);
	    		}else{
	    			$('#toast').css('top','20px');
	    		}
    		}catch(e){
    			GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:toastOnScroll|" + e, null, 1);
    		}
        },

        updateViewPortHeight : function(){
			try{
				$('.mainView').height($('#page-content-wrapper').height() - $('.dashHeader').height());
			}catch(e){
				GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:updateViewPortHeight|" + e, null, 1);
			}     	
        },

        showLoader : function(){
        	try{
        		$('#overlay').show().css('height' , $( document ).height() + 'px');
        		$('#ajax-loader').show();
        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:showLoader|" + e, null, 1);
        	}
        },

        showLoaderOnLoad : function(){
        	try{
        		$('#overlay').show().css('height' , $( document ).height() + 'px');
        		$('#ajax-loader').show();
        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:showLoaderOnLoad|" + e, null, 1);
        	}
        },

        
        hideLoaderOnLoad : function(){
        	try{
        		$('#overlay').hide();
        		$('#ajax-loader').hide();
        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:hideLoaderOnLoad|" + e, null, 1);
        	}
        },

        hideLoader : function(){
        	try{
        		$('#overlay').hide();
        		$('.ajaxLoader').hide();
        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:hideLoader|" + e, null, 1);
        	}
        },

        addLoader : function(target){
        	try{
        		var loader = '<div class="row" id="itemsLoading"><div class="customAjaxLoader " style="display:none"><div  class="cssload-fond"><div class="cssload-container-general"><div class="cssload-internal"><div class="cssload-ballcolor cssload-ball_1"></div></div><div class="cssload-internal"><div class="cssload-ballcolor cssload-ball_2"></div></div><div class="cssload-internal"><div class="cssload-ballcolor cssload-ball_3"></div></div><div class="cssload-internal"><div class="cssload-ballcolor cssload-ball_4"></div></div></div></div></div></div>';
        		if(target.find('.mainView .row').length > 0){
        			target.find('.mainView').append(loader);
        		}
        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:addLoader|" + e, null, 1);
        	}
        },

        removeLoader : function(target){
        	try{
        		if(target.find('#itemsLoading')){
        			$('#itemsLoading').remove();
        		}
        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard", "file:dashboardUtil.js|function:removeLoader|" + e, null, 1);
        	}
        },

        escapeHtml: function(text) {
            return text.replace(/[\"&'\/<>]/g, '');
        },
        resizeWrapper: function(){
        	try{
        		var windowHeight = $(window).height(),
                	pageContentHeight = $('#page-content-wrapper').height(),
					sideWrapperHeight = $('.sidebar-nav').height() + 2,
					headerHeight = $('#hubHeaderSection').height(),
					footer = ( typeof newSkinning !== "undefined" && newSkinning ) ? $('.h-footer-container') : $("#footer"),
					footerHeight = footer.height(),
					contentHight = pageContentHeight + headerHeight + footerHeight + 100;

	            if(contentHight > windowHeight) {
	                footer.removeClass('stickyBottom');

	                if(!window.matchMedia('(max-width: 767px)').matches){
	                	var updatedHeight = windowHeight - (headerHeight + footerHeight + 100);
	                	updatedHeight = sideWrapperHeight >= updatedHeight ? sideWrapperHeight : updatedHeight;
						$('#sidebar-wrapper').css('height', updatedHeight);
	            	} else {
	            		$('#sidebar-wrapper').css('height', '0');
	            	}   
	            } else {

	            	var updatedHeight = windowHeight - (headerHeight + footerHeight + 100);

	            	if(sideWrapperHeight >= updatedHeight) {
	            		updatedHeight = sideWrapperHeight;
	            		footer.removeClass('stickyBottom');
	            	} else {	
	            		footer.addClass('stickyBottom');
	            	}

	            	if(window.matchMedia('(max-width: 767px)').matches){
						updatedHeight -= 20;
					}

	            	$('.dashContainer .panel').css('min-height', updatedHeight);

	            	if(!window.matchMedia('(max-width: 767px)').matches){
						$('#sidebar-wrapper').css('height', updatedHeight);
	            	} else {
	            		$('#sidebar-wrapper').css('height', '0');
	            	}

	            }

	            //console.log('windowHeight ::', windowHeight , ' - updatedHeight ::', sideWrapperHeight + headerHeight);
	            if(windowHeight < sideWrapperHeight + headerHeight + 100){
					$('#sidebar-wrapper').removeClass('fixedPostion').addClass('absolutePostion');
	            } else {
	            	$('#sidebar-wrapper').removeClass('absolutePostion').addClass('fixedPostion');
	            }

        	}catch(e){
        		GAUtils.eventTracking.trackevent("Error", "Buyer Dashboard - Wrapper resizing", "file:dashboardUtil.js|function:resizeWrapper|" + e, null, 1);
        	}
        },
        makeStickyFooter: function(){
			var footer = ( typeof newSkinning !== "undefined" && newSkinning ) ? $('.h-footer-container') : $("#footer");
        	footer.addClass('stickyBottom');
        }

    };
    
    return dUtil;
});
