

define('appDownloadBannerView',['jquery', 'backbone', 'GoogleAnalyticsUtils'], function ($, Backbone, GAUtils) {
    'use strict';
    var appDownloadBannerView = Backbone.View.extend({
        el: $('#appDownloadBanner'),
        events: {
            // 'click .contentNext':'paginationNextDot',
            // 'click .indicatorContainer .dot':'openCurrnetView',
            // 'click .intro-button':'redirectpage',
            // 'click .introduceHubzu' : 'hideIntroduceBackDrop',
            // 'click .startMySearch' : 'hidePopupAndScroll',
            'click .newAppBtn':'openAppOrPlay',
            "click .close_appDownloadBanner": 'closePopup'
        },
        closePopup: function (e) {
            console.log('remove popup ???');

            this.hideIntroduceBackDrop();
        },
        initialize: function () {
            try {
                var self = this;
                console.log('Init App download');
                // this.activeTitle ='';
                // $('.introduceHubzuMask').click(function(e){
                //     self.hideIntroduceBackDrop();
                // });
                // $(document).on('keyup',function(evt) {
                //     if (evt.keyCode == 27) {
                //         self.hideIntroduceBackDrop();
                //     }
                // });
            } catch (e) {
                // GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:initialize|" + e, null, 1);
            }

        },
        render: function () {
            try {
                console.log('render App download');
                // var introHubzuContent ={};
                // introHubzuContent.content = introduceHubzuContent;
                // introHubzuContent.content.contentLength = Object.keys(introduceHubzuContent.introContent).length;
                // this.activeTitle = '1.'+introduceHubzuContent.introContent[1].contentHead;
                // $('#introduceHubzu').html(introduceHubzu(introHubzuContent));
                // $(".indicatorContainer .dot").attr('total-index',introHubzuContent.content.contentLength);
                // $('.dot1,.introText1').addClass('active');
                $(".appDownloadBannerMask").show();
                $(".appDownloadBanner").show(100);
                $("body").addClass("no-scroll");
                // GAUtils.eventTracking.trackevent("",'Introduce Hubzu Popup Load','visitorId: ' + $.cookie('visitorId'), null, 1);
            } catch (e) {
                // GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:render|" + e, null, 1);
            }

        },


        openAppOrPlay : function() {
            if (navigator.userAgent.toLowerCase().indexOf("android") > -1) {
                window.location.href = 'market://details?id=com.hubzu.android';
            }
            if (navigator.userAgent.toLowerCase().indexOf("iphone") > -1) {
                window.location.href = 'https://apps.apple.com/us/app/hubzu-real-estate-auctions/id1468257500?ls=1';
            }
        },

        hideIntroduceBackDrop: function () {
            try {
                console.log('remove popup');
                $(".appDownloadBannerMask").hide();
                $(".appDownloadBanner").hide(100);
                $("body").removeClass("no-scroll");


            } catch (e) {
                // GAUtils.eventTracking.trackevent("Error", "Intro Hubzu", "file:introduceHubzuView.js|function:hideIntroduceBackDrop|" + e, null, 1);
            }
        },

    });
    return appDownloadBannerView;
});