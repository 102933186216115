
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/fraudulentErrorModal',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "<div class=\"modal fade fraudulent-warning-modal\" id=\"\" role=\"dialog\" aria-hidden=\"false\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content \">\n            <div class=\"modal-heading\">\n                <div class=\"fb-head\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.fraudulentErrorHead : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n                <button class=\"fb-close\" data-dismiss=\"modal\">\n                    <svg class=\"\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\"\n                        x=\"0px\" y=\"0px\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" enable-background=\"new 0 0 24 24\"\n                        xml:space=\"preserve\">\n                        <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\"\n                            x1=\"2.9\" y1=\"21.2\" x2=\"20.7\" y2=\"3.3\"></line>\n                        <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\"\n                            x1=\"20.7\" y1=\"21.2\" x2=\"2.9\" y2=\"3.3\"></line>\n                    </svg>\n                </button>\n            </div>\n            <div class=\"modal-body \">\n                <div class=\"warning-message\">\n                    ";
  stack1 = ((helper = (helper = helpers.warningMessage || (depth0 != null ? depth0.warningMessage : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"warningMessage","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                </div>\n                <div class=\"fb-action\">\n                    <button data-form=\""
    + escapeExpression(((helper = (helper = helpers.formSelector || (depth0 != null ? depth0.formSelector : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"formSelector","hash":{},"data":data}) : helper)))
    + "\" class=\"\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.fraudulentButtonLabel : stack1), depth0))
    + "</button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/globalAlerts/fraudulentErrorModal', t);
return t;
});
/* END_TEMPLATE */
;