
/* START_TEMPLATE */
define('hbs!tpl/popups/introduceHubzu',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                        <span class=\"dot dot"
    + escapeExpression(lambda((data && data.key), depth0))
    + "\" curr-index=\""
    + escapeExpression(lambda((data && data.key), depth0))
    + "\"></span>\n";
},"3":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <div class=\"intro-text introText introText"
    + escapeExpression(lambda((data && data.key), depth0))
    + "\">\n                <div class=\"\">\n                    <div class=\"introSubhead\">"
    + escapeExpression(lambda((depth0 != null ? depth0.contentHead : depth0), depth0))
    + "</div>\n                    <div class=\"introContent\">"
    + escapeExpression(lambda((depth0 != null ? depth0.contentData : depth0), depth0))
    + "</div>\n                </div>\n            </div>\n";
},"5":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "                    <input type=\"button\" value=\"";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.takeTour : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" class=\"btn btn-primary intro-button\"/>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, buffer = "<div class=\"introduceHubzu-content\">\n    <div class=\"rel\">    \n        <div class=\"row tourHead\">\n            <div class=\"col-xs-6 col-sm-6 textAlignLeft\">\n                <div class=\"indicatorContainer "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.contentLength : stack1), depth0))
    + "\">\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.introContent : stack1), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "                </div>\n            </div>\n            <div class=\"col-xs-6 col-sm-6 textAlignRight\">\n                <a class=\"contentNext\" curr-index=\"1\" total-index=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.contentLength : stack1), depth0))
    + "\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.nextText : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</a>\n                <a class=\"closeButton closeTourPopup hide\">X</a>\n            </div>\n        </div>\n        <div class=\"introBody\">\n        <div class=\"intro-title\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.welcomeHubzu : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.introContent : stack1), {"name":"each","hash":{},"fn":this.program(3, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        </div>\n        <div class=\"intro-footer\">\n            <div class=\"registerDiv text-left\">\n                \n                <button type=\"button\" class=\"btn btn-primary showRegisterTour\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.register : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</button>  \n                \n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.showTourButton : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(5, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </div>\n            <div class=\"row textAlignLeft\">\n                <div class=\"col-xs-12 popupFooter\">\n                    ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.footerContent : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/popups/introduceHubzu', t);
return t;
});
/* END_TEMPLATE */
;