
define('bidWizardModel',['underscore','backbone','bidUpdater','GoogleAnalyticsUtils','monitoringView' 
,'util'], function (_,Backbone,bidUpdater,GAUtils, monitoringView,util) {
   'use strict';

    var BidWizardModel = Backbone.Model.extend({
        //defaults is superset of all attributes, 
        //but only items necessary to submit the bid/offer are taken to 'BidSubmissionModel' / 'OfferSubmissionModel'
        initialize : function(){
          this.isMobile = $(window).width() < 768 ? true : false;
        },
        defaults: {
          "listingId": "",
          "buyerEmail": "",
          "bidAmountDetail": {
            "bidAmount": '',
            "buyerPremiumAmount": '',
            "emdAmount": ''
          },
          "autoBidAmountDetail": {
            "bidAmount": ''
          }, 
          "isBackupBidSelected": "N",
          "backupBidDaysSelected": null,
          "isAutoBidSelected": null,
          
          "isIntendToOccupy": null,
          "holdActiveLicense": null,
          "isAfBaAccepted": "N",
          "propertyTouredSelected": "Y",
          'isLibertyRequired' : "N",
          "finacingDetail": {
            "paymentType": "CASH",
            "financingOption": null,
            "loanAmount": '',
            "closingOptionDetail": {
              "closingOption": "SELLER_PAYS",
              "closingAgent": null,
              "titleInsuranceProvider": null,
              "closingDays": 45
            },
            "loanPreApprovalDetail": {
              "isPreApprovedForFinance": null,
              "institutionName": null,
              "preApprovedAmount": null,
              "preApprovedAmountFormatted": null,
              
            },
            "bidContingencyMasterList":[],
            "bidContigencies":[],
            "contingentUponFlag": null,
            "isBPFinanced": "N"
          },
          "bidType": "PLACE_BID"
        }       
    });
  
    return new BidWizardModel({});
});
