
/* START_TEMPLATE */
define('hbs!tpl/saveDrawer/savedSearcheTemp',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"savedSearches-container\" id=\"saveSearch";
  stack1 = ((helper = (helper = helpers.saveSearchId || (depth0 != null ? depth0.saveSearchId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchId","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\"\" currentSearchid=\"";
  stack1 = ((helper = (helper = helpers.saveSearchId || (depth0 != null ? depth0.saveSearchId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchId","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" searchParam=\"";
  stack1 = ((helper = (helper = helpers.searchValueGA || (depth0 != null ? depth0.searchValueGA : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchValueGA","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n		<div class=\"savedSearches-item-Location\">\n			<a class=\"searchFields ssd-search-editable ssd-saveSearchNameLink_";
  stack1 = ((helper = (helper = helpers.saveSearchId || (depth0 != null ? depth0.saveSearchId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchId","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\"SSD";
  stack1 = ((helper = (helper = helpers.saveSearchId || (depth0 != null ? depth0.saveSearchId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchId","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" href=\"";
  stack1 = ((helper = (helper = helpers.searchUrl || (depth0 != null ? depth0.searchUrl : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchUrl","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">";
  stack1 = ((helper = (helper = helpers.searchName || (depth0 != null ? depth0.searchName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchName","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</a>\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isLoggedIn : depth0), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "			\n		</div>\n		<div data-currentSearchid=\"";
  stack1 = ((helper = (helper = helpers.saveSearchId || (depth0 != null ? depth0.saveSearchId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchId","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" data-searchParam=\"";
  stack1 = ((helper = (helper = helpers.searchValueGA || (depth0 != null ? depth0.searchValueGA : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchValueGA","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n			<span class=\"searchFields search-filter-values\">";
  stack1 = ((helper = (helper = helpers.searchValues || (depth0 != null ? depth0.searchValues : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchValues","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n			<a class=\"savedSearches-item-delete ";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.isLoggedIn : depth0), {"name":"unless","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" href=\"javascript:void(0)\" aria-label=\"Delete Save Search ";
  stack1 = ((helper = (helper = helpers.searchName || (depth0 != null ? depth0.searchName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchName","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\"></a>\n		</div>\n</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, buffer = "				<a class=\"ssd-name-editable\" href=\"javascript:void(0)\" aria-label=\"Edit Save Search ";
  stack1 = ((helper = (helper = helpers.searchName || (depth0 != null ? depth0.searchName : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"searchName","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\"ssd-editSearchName_";
  stack1 = ((helper = (helper = helpers.saveSearchId || (depth0 != null ? depth0.saveSearchId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchId","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-prop=\""
    + escapeExpression(((helper = (helper = helpers.saveSearchId || (depth0 != null ? depth0.saveSearchId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"saveSearchId","hash":{},"data":data}) : helper)))
    + "\"></a>\n";
},"4":function(depth0,helpers,partials,data) {
  return "topPercentage";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, options, functionType="function", helperMissing=helpers.helperMissing, blockHelperMissing=helpers.blockHelperMissing, buffer = "";
  stack1 = ((helper = (helper = helpers.saveSearchDetails || (depth0 != null ? depth0.saveSearchDetails : depth0)) != null ? helper : helperMissing),(options={"name":"saveSearchDetails","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}),(typeof helper === functionType ? helper.call(depth0, options) : helper));
  if (!helpers.saveSearchDetails) { stack1 = blockHelperMissing.call(depth0, stack1, options); }
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});
Handlebars.registerPartial('tpl/saveDrawer/savedSearcheTemp', t);
return t;
});
/* END_TEMPLATE */
;