
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/cifReminderTuple',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  return "        <div class=\"cif-timer\">\n            <table>\n                <tr>\n                    <td class=\"timer-icon\" rowspan=\"2\"><span class=\"icon-timer_icon\"></span></td>\n                    <td class=\"timer-val hr-value\">0</td>\n                    <td class=\"timer-val\">:</td>\n                    <td class=\"timer-val mn-value\">0</td>\n                    <td class=\"timer-val\">:</td>\n                    <td class=\"timer-val sec-value\">0</td>\n                </tr>\n                <tr>\n                    <td class=\"timer-label hr-label\">Hours</td>\n                    <td class=\"timer-label\">&nbsp;</td>\n                    <td class=\"timer-label mn-label\">Minutes</td>\n                    <td class=\"timer-label\">&nbsp;</td>\n                    <td class=\"timer-label sec-label\">Seconds</td>\n                </tr>\n            </table>\n        </div>\n";
  },"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, lambda=this.lambda, buffer = "<li class=\"row ga-tuple-item ga-cif-reminder-tuple "
    + escapeExpression(((helper = (helper = helpers.messageId || (depth0 != null ? depth0.messageId : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"messageId","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"cif-header\">\n        <svg class=\"cif-alert-icon\" data-toggle=\"tooltip\" title=\"\" data-placement=\"bottom\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" enable-background=\"new 0 0 24 24\" xml:space=\"preserve\" data-original-title=\"The information provided in this form will be used to complete the contract documentation for the property if your bid is selected for this property. An early submission of your contact information will help us to process and close documentation sooner in the event your bid is selected.\"><g><path fill=\"#E2574C\" d=\"M11.9,2.7c-5.2,0-9.4,4.2-9.4,9.4c0,5.2,4.2,9.4,9.4,9.4c5.2,0,9.4-4.2,9.4-9.4C21.3,6.9,17.1,2.7,11.9,2.7L11.9,2.7z M11.9,19.8c-4.2,0-7.7-3.4-7.7-7.7c0-4.2,3.4-7.7,7.7-7.7s7.7,3.4,7.7,7.7C19.5,16.4,16.1,19.8,11.9,19.8L11.9,19.8zM11.9,6.8c-0.7,0-1.2,0.4-1.2,0.9V13c0,0.6,0.5,0.9,1.2,0.9c0.7,0,1.2-0.4,1.2-0.9V7.8C13,7.2,12.5,6.8,11.9,6.8L11.9,6.8zM11.9,15.1c-0.7,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2c0.6,0,1.2-0.5,1.2-1.2C13,15.6,12.5,15.1,11.9,15.1L11.9,15.1zM11.9,15.1\"></path></g>\n        </svg>\n        <span>"
    + escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"title","hash":{},"data":data}) : helper)))
    + "</span>\n    </div>\n    <div class=\"cif-description\">\n        ";
  stack1 = ((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"description","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    </div>\n    <div class=\"cif-actions\">\n        <a href=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.cifDetails : depth0)) != null ? stack1.cifLink : stack1), depth0))
    + "\" target=\"_blank\" class=\"btn btn-primary action-button-blue\">"
    + escapeExpression(((helper = (helper = helpers.actionLabel || (depth0 != null ? depth0.actionLabel : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"actionLabel","hash":{},"data":data}) : helper)))
    + "</a>\n\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.cifDetails : depth0)) != null ? stack1.timeLeft : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n</li>";
},"useData":true});
Handlebars.registerPartial('tpl/globalAlerts/cifReminderTuple', t);
return t;
});
/* END_TEMPLATE */
;