
define('bidSignInView',['require','jquery','underscore','backbone','bidModel','GoogleAnalyticsUtils','hbs!tpl/bidSignInModal','hbs!tpl/bidSigninErrorMessagePopup','loginRegisterView','util'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone");
    var BidModel = require("bidModel");
    var GAUtils = require("GoogleAnalyticsUtils");
    var bidSignInTemplate = require("hbs!tpl/bidSignInModal");
    var bidSignInErrorPopupTemplate = require("hbs!tpl/bidSigninErrorMessagePopup");
    var loginRegisterView = require('loginRegisterView');
    var util         = require("util");
    var callBackMethod;
    var callBackDataObj;
    var redirectTo;
    var redirectToHome = '';
    
    var BidSignInView = Backbone.View.extend({
        
        //el : "#userSignIn",
        //el: "#signinErrorMessagePopup"
        callback: null,
        viewObject: null,
        initialize: function () {
        	this.setElement("#signinErrorMessagePopup");
        },

        signInSubmit : function(){
            try{
	        	if($('#loginBody').is(':visible')){
	                this.checkAndSignIn();
	            }else{
	                if(redirectTo === 'home'){
	                    window.location.href= ctxPath+"/home";
	                }else if(redirectTo === 'pdp'){
	                    window.location.href=ctxPath+'/property/'+BidModel.attributes.propertyDetails.propertyId;
	                }else{
	                    window.location.href=contextPath+'/my-shopper'
	                }
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:signInSubmit|" + e, null, 1);
            }
        },

        checkAndSignIn : function() {
            try{
	        	var email = $("#emailAddress").val();
	            var pswd = $("#password").val();
	            var invalid = false;
	            if(!email) {
	                invalid = true;
	                $('#signInModal .login-form-error.email').text('Email/User Name is required');
	                $('#signInModal .login-form-error.email').show();
	            }
	            if(!pswd) {
	                invalid = true;
	                $('#signInModal .login-form-error.password').text('Password is required');
	                $('#signInModal .login-form-error.password').show();
	            }
	            if(!invalid) {
	                this.signIn();
	            }else{
	                GAUtils.eventTracking.trackevent("New-Sign In","Sign In Click","LoginFailed"); 
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:checkAndSignIn|" + e, null, 1);
            }
        },

        signIn : function() {
            try{
	        	util.showLoader();
	            $('#signInModal .login-form-error').hide();
	            var email = $.trim($("#emailAddress").val());
	            if(email.indexOf('@') == -1){
	                var dataParams = {username : email};
	                util.postUrlCall(contextPath + "/user/isDuplicateUser" , dataParams , this.isDuplicateUser , this);
	            } else {
	                this.doLogin();
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:signIn|" + e, null, 1);
            }
        },

        isDuplicateUser : function(obj , response){
            try{
	        	if(response.isDuplicateUser == 'Y'){
	                $('#signInModal .login-form-error').text(response.message);
	                $('#signInModal .login-form-error').show();
	                util.hideLoader();
	                return false;
	            } else {
	                obj.doLogin();
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:isDuplicateUser|" + e, null, 1);
            }
        },

        doLogin : function(){
            try{
	        	var email = $.trim($("#emailAddress").val());
	            var pswd = $.trim($("#password").val());
	            util.postUrlCall(contextPath + '/signIn' ,  {username:email,password:pswd}, this.onSignIn , this);
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:doLogin|" + e, null, 1);
            }
        },

        onSignIn : function(obj , response){
            try{
	        	if(response.user) { 

	                GAUtils.eventTracking.trackevent("New-Sign In","Sign In Click","Registration-SignIn"); 
					GAUtils.eventTracking.trackevent("", "Registration-SignIn", ' | visitorId :' + $.cookie('visitorId')); 
	                $('.logInLink').removeClass('logInLink').addClass('scrName').html(response.user.userFirstName);
	                $('.scrName').html(response.user.userFirstName);
	                if(_.contains(response.user.roleIdList, 'AUTH_USER') || _.contains(response.user.roleIdList, 'PRIM_AUTH_USER') || _.contains(response.user.roleIdList, 'CUST_CARE') ){
	                    $('#loginBody').hide();
	                    $('#unAuthAccess').show();
	                    $('#signInSubmit').html('Ok');
	                    $('#modalHeader').html('Unauthorized Access');
	                    redirectTo = 'home';
	                    obj.redirectToHome();
	                    util.hideLoader();
	                    return false;
	                }
	                if(response.user.userStatus=='PROFILE'){
	                    $('#loginBody').hide();
	                    $('#profileInc').show();
	                    $('#signInSubmit').html('Ok');
	                    $('#modalHeader').html('Incomplete Profile');
	                    redirectTo = 'dashboard';
	                    obj.redirectToHome('PROFILE');
	                    util.hideLoader();
	                    return false;
	                }
	                util.hideLoader();
	                $('#signInModal').modal('hide');
	                callBackMethod(callBackDataObj);
	            } else if(response.error) {
	                GAUtils.eventTracking.trackevent("New-Sign In","Sign In Click",response.error); 
	                $('#signInModal .globalSignInError').html(response.error);
	                $('#signInModal .globalSignInError').show();
	                util.hideLoader();
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:onSignIn|" + e, null, 1);
            }
        },

        onSigninComplete : function(response, thisObject){
            
            try{

            	util.hideLoader();
	        	if(response.user) { 

	                GAUtils.eventTracking.trackevent("New-Sign In","Sign In Click","Registration-SignIn"); 

	                $('.logInLink').removeClass('logInLink').addClass('scrName').html(response.user.userFirstName);
	                $('.scrName').html(response.user.userFirstName);
					var isSmallSeller = window.userSession.isSmallSeller || false;

	                if((_.contains(response.user.roleIdList, 'AUTH_USER') && !(isSmallSeller))|| (_.contains(response.user.roleIdList, 'PRIM_AUTH_USER') && !(isSmallSeller))|| _.contains(response.user.roleIdList, 'CUST_CARE') ){
	                    thisObject.showErrorMessagePopup();
	                    $('#unAuthAccess').show();
	                    $('#modalHeader').html('Unauthorized Access');
	                    redirectTo = 'home';
	                    thisObject.redirectToHome();
	                    return false;
	                }
	                if(response.user.userStatus=='PROFILE'){
	                	profileStatus=response.user.userStatus;
	                	idologyStatus=response.idologyStatus;
	                    
	                }else if(response.user.userStatus=='ACTIVE' && !(response.idologyStatus)){
	                	profileStatus=response.user.userStatus;
	                	idologyStatus=response.idologyStatus;
	                }
	                $('#signInModal').modal('hide');
	                callBackMethod(callBackDataObj);
	            }  
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:onSigninComplete|" + e, null, 1);
            }
        },
        showErrorMessagePopup: function(){
        	try{
	        	var _this = this;
	        	_this.$el.html(bidSignInErrorPopupTemplate(BidModel.attributes));
	            $('#signInModal').modal({
	                show: true,
	                keyboard: false
	            });
	            $('#goToPage').unbind('click').bind('click' , function(e) { _this.signInSubmit();});
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:showErrorMessagePopup|" + e, null, 1);
            }
        },
        redirectToHome : function(){
            try{
	        	clearTimeout(redirectToHome);
	            if(redirectTo === 'home'){
	                redirectToHome = setTimeout(function(){ window.location.href=ctxPath + "/home"; }, 20000);
	            }else if(redirectTo === 'pdp'){
	                redirectToHome = setTimeout(function(){ window.location.href=ctxPath+'/property/'+BidModel.attributes.propertyDetails.propertyId; }, 20000);
	            }else{
	               // redirectToHome = setTimeout(function(){ window.location.href=contextPath+'/my-shopper'; }, 20000);
	            }
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:redirectToHome|" + e, null, 1);
            }
        },
        

        render : function (callBack , callBackData , unAuthAccess) {
            try{

	        	callBackMethod = callBack;
	            callBackDataObj = callBackData;
	            var thisObject = this;
	            
	            util.hideLoader();

	            if(typeof unAuthAccess != 'undefined'){
	                if(unAuthAccess === 'UNAUTHORIZED_ACCESS'){
	                	thisObject.showErrorMessagePopup();
	                    $('#unAuthAccess').show();
	                    $('#modalHeader').html('Unauthorized Access');
	                    redirectTo = 'home';
	                    thisObject.redirectToHome(redirectTo);
	                    util.hideLoader();
	                    return false;
	                }else if(unAuthAccess === 'BACKUP_BID_NOT_AVIL_FOR_WINNER'){
	                    thisObject.showErrorMessagePopup();
	                    $('#bkupBidByHstBidderByBuyr').show();
	                    $('#modalHeader').html('Good News');
	                    redirectTo = 'pdp';
	                    return false;
	                }else if(unAuthAccess === 'BACKUP_BID_NOT_AVIL_FOR_WINNER_AGNT'){
	                    thisObject.showErrorMessagePopup();
	                    $('#bkupBidByHstBidderByBuyr').show();
	                    $('#modalHeader').html('Good News');
	                    redirectTo = 'pdp';
	                    return false;
	                }else if(unAuthAccess === 'Technical_Error'){
	                	thisObject.showErrorMessagePopup();
	                    $('#technicalError').show();
	                    $('#modalHeader').html("We're Sorry");
	                    redirectTo = 'pdp';
	                    return false;
	                }else if(unAuthAccess === 'OCWN_LIST_AGNT'){
	                    thisObject.showErrorMessagePopup();
	                    $('#listAgentOcwn').show();
	                    $('#modalHeader').html('Unauthorized Access');
	                    redirectTo = 'pdp';
	                    thisObject.redirectToHome(redirectTo);
	                    return false;
	                }else if(unAuthAccess === 'RHSS_OCWN_AGNT'){
	                    thisObject.showErrorMessagePopup();
	                    $('#rhssAgentOcwn').show();
	                    $('#modalHeader').html('Unauthorized Access');
	                    redirectTo = 'pdp';
	                    thisObject.redirectToHome(redirectTo);
	                    return false;
	                }else if(unAuthAccess === 'RHSS_OCWN_BUYER'){
	                    thisObject.showErrorMessagePopup();
	                    $('#rhssOcwnBuyer').show();
	                    $('#modalHeader').html('Unauthorized Access');
	                    redirectTo = 'pdp';
	                    thisObject.redirectToHome(redirectTo);
	                    return false;
	                }else if(unAuthAccess === 'INVALID_LICENSE_FORMAT'){
	                    thisObject.showErrorMessagePopup();
	                    $('#validateLicensePopUp').show();
	                    $('#goToPage').hide();
	                    $('#licenseErrorResponse').html(callBackData);
	                    $('#modalHeader').html('License Format');
	                    return false;
	                }else if(unAuthAccess === 'CIF_ERROR'){
	                    thisObject.showErrorMessagePopup();
	                    $('#validateLicensePopUp').show();
	                    $('#goToPage').hide();
	                    $('#licenseErrorResponse').html(configContent.cifErrorMsg.popupErrorMsg);
						$('#modalHeader').html(configContent.cifErrorMsg.popupHead);
						redirectTo = 'pdp';
	                    thisObject.redirectToHome(redirectTo);
	                    return false;
	                }else{
	                    thisObject.showErrorMessagePopup();
	                    $('#profileInc').show();
	                    $('#modalHeader').html('Incomplete Profile');
	                    redirectTo = 'dashboard';
	                    thisObject.redirectToHome(redirectTo);
	                    return false;
	                }
	            }else{
	            	loginRegisterView.openLoginPopup({
	            		callback: this.onSigninComplete,
	            		viewObject: this,
	            		statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessageForPlaceBid
            		});
	            }

	          

	            util.hideLoader();
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:bidSignInView.js|function:render|" + e, null, 1);
            }
        }
        
    });
    return new BidSignInView();
});