
/* START_TEMPLATE */
define('hbs!tpl/globalIdologyVerification/verifyIdologyQuesDet',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data,depths) {
  var stack1, helper, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", buffer = "    <div class=\"form-group idologyQuestions ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (data && data.index), 0, {"name":"ifEqual","hash":{},"fn":this.program(2, data, depths),"inverse":this.program(4, data, depths),"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" id=\""
    + escapeExpression(lambda(((stack1 = (depths[1] != null ? depths[1].data : depths[1])) != null ? stack1.renderDiv : stack1), depth0))
    + "idologyQues"
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" >\n        <label for=\"question1\" class=\"questionsNumber\" tabindex=\"0\"><b>"
    + escapeExpression(((helper = (helper = helpers.prompt || (depth0 != null ? depth0.prompt : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"prompt","hash":{},"data":data}) : helper)))
    + "</b></label>\n        <input type=\"hidden\" class=\"questionType\" name=\""
    + escapeExpression(lambda(((stack1 = (depths[1] != null ? depths[1].data : depths[1])) != null ? stack1.renderDiv : stack1), depth0))
    + "idologyQuestionAnswers["
    + escapeExpression(lambda((data && data.index), depth0))
    + "].type\" value=\""
    + escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"type","hash":{},"data":data}) : helper)))
    + "\" dataName=\"idologyQuestionAnswers["
    + escapeExpression(lambda((data && data.index), depth0))
    + "].type\"/>\n        <div class=\"answerGroup ie9up\">\n        \n";
  stack1 = helpers.each.call(depth0, (depth0 != null ? depth0.answers : depth0), {"name":"each","hash":{},"fn":this.program(6, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n    </div>    \n";
},"2":function(depth0,helpers,partials,data) {
  return " ";
  },"4":function(depth0,helpers,partials,data) {
  return " hide ";
  },"6":function(depth0,helpers,partials,data,depths) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "            <div class=\"idologyAnswers control-label\">\n              <input class=\"selectIdologyAnswer selectedAnswer control-label selectedAnswer"
    + escapeExpression(lambda((this.data(data, 1) && this.data(data, 1).index), depth0))
    + "\"  name=\""
    + escapeExpression(lambda(((stack1 = (depths[2] != null ? depths[2].data : depths[2])) != null ? stack1.renderDiv : stack1), depth0))
    + "idologyQuestionAnswers["
    + escapeExpression(lambda((this.data(data, 1) && this.data(data, 1).index), depth0))
    + "].answer\" postid=\""
    + escapeExpression(lambda((depths[1] != null ? depths[1].type : depths[1]), depth0))
    + "\" type=\"radio\" value=\""
    + escapeExpression(lambda(depth0, depth0))
    + "\" id=\""
    + escapeExpression(lambda(((stack1 = (depths[2] != null ? depths[2].data : depths[2])) != null ? stack1.renderDiv : stack1), depth0))
    + "idologyQuestionAnswers"
    + escapeExpression(lambda((this.data(data, 1) && this.data(data, 1).index), depth0))
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" attrIdologyQues=\"idologyQues"
    + escapeExpression(lambda((this.data(data, 1) && this.data(data, 1).index), depth0))
    + "\" tabindex=\"0\" dataName = \"idologyQuestionAnswers["
    + escapeExpression(lambda((this.data(data, 1) && this.data(data, 1).index), depth0))
    + "].answer\"/><label for=\""
    + escapeExpression(lambda(((stack1 = (depths[2] != null ? depths[2].data : depths[2])) != null ? stack1.renderDiv : stack1), depth0))
    + "idologyQuestionAnswers"
    + escapeExpression(lambda((this.data(data, 1) && this.data(data, 1).index), depth0))
    + escapeExpression(lambda((data && data.index), depth0))
    + "\" class=\"idologyQuesAnsLabel\">"
    + escapeExpression(lambda(depth0, depth0))
    + "</label>\n            </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data,depths) {
  var stack1;
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.questions : stack1), {"name":"each","hash":{},"fn":this.program(1, data, depths),"inverse":this.noop,"data":data});
  if (stack1 != null) { return stack1; }
  else { return ''; }
  },"useData":true,"useDepths":true});
Handlebars.registerPartial('tpl/globalIdologyVerification/verifyIdologyQuesDet', t);
return t;
});
/* END_TEMPLATE */
;