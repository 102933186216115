
define('util',['require','jquery','GoogleAnalyticsUtils'],function(require) {
//define(['jquery', 'GoogleAnalyticsUtils'], function ($, GAUtils) {    
    'use strict';

    var $ = require("jquery"),
       GAUtils = require("GoogleAnalyticsUtils");

    var errorCallback;
    var errorCallbackObj;

    var util = {

        setCallBack : function(callback , obj){
            try{
	        	errorCallback = callback;
	            errorCallbackObj = obj;
            }catch(e){
            	GAUtils.eventTracking.trackevent("Error", "New Bid", "file:util.js|function:setCallBack|" + e, null, 1);
            }
        },

		getJson : function (url , data , callback , callBackParam){
			try{
				$.getJSON(url , function( data ) {
					callback(callBackParam , data);
				}).error(function() {
					errorCallback(errorCallbackObj);
				});
			}catch(e){
				GAUtils.eventTracking.trackevent("Error", "New Bid", "file:util.js|function:getJson|url:"+url+':'+e, null, 1);
			}
		},
		
		postCall : function(url , data , callback , callBackParam){
			try{
				jQuery.ajax ({
	        	    url: url,
	        	    type: "POST",
	        	    data: JSON.stringify(data),
	        	    dataType: "json",
	        	    contentType: "application/json; charset=utf-8",
	        	    success: function(response){
	        	    	callback(callBackParam,response);
	        	    },
	                error: function(){
	                    errorCallback(errorCallbackObj);
	                }
	        	});
			}catch(e){
				GAUtils.eventTracking.trackevent("Error", "New Bid", "file:util.js|function:postCall|url:"+url+':'+e, null, 1);
			}
		},

        postUrlCall : function(url , data , callback , callBackParam){
            try{
	        	jQuery.ajax ({
	                url: url,
	                type: "POST",
	                data: data,
	                success: function(response){
	                    callback(callBackParam,response);
	                },
	                error: function(){
	                    errorCallback(errorCallbackObj);
	                }
	            });
            }catch(e){
				GAUtils.eventTracking.trackevent("Error", "New Bid", "file:util.js|function:postUrlCall|url:"+url+':'+e, null, 1);
			}
        },
		
		truncate : function(string , limit) {
			if(string.length>limit)
			{
				return string.substr(0,limit)+"...";
			}else{return string}
		},

        hideError : function(el) {
            $('.' + $(el).attr('error')).addClass('hide');
            $(el).removeClass('inputError');
        },

        showError : function(el) {
            $('.' + $(el).attr('error')).removeClass('hide');
            $(el).addClass('inputError');
        },

        showLoader : function() {
            $('#overlay').show().css('height' , $( document ).height() + 'px');
            $("#ajax-loader").show();
        },

        hideLoader : function(){
            $('#overlay').hide();
            $("#ajax-loader").hide();
        },
        
        scrollTo : function(el) {
            $(window).scrollTop($(el).offset().top);
        },

        bidofferTypeGa : function(bidTypeGACode,offerTypeGACode){
            if(bidTypeGACode!=undefined && bidTypeGACode!=''){
                if(bidTypeGACode=='PLACE_BID'){
                    var bidTypeGa='Place Bid';
                } else if(bidTypeGACode=='PLACE_BACKUP_BID'){
                    var bidTypeGa='Place Backup Bid';
                }else if(bidTypeGACode=='OWN_IT_NOW'){
                    var bidTypeGa='Own it now- AUCN';
                }else if(bidTypeGACode=='OWN_IT_NOW'){
                    var bidTypeGa='Own it now- AUCN';
                }
            }else if(offerTypeGACode!=undefined && offerTypeGACode!=''){
                if(offerTypeGACode=='PLACE_OFFER'){
                    var bidTypeGa='Place Offer';
                } else if(offerTypeGACode=='PLACE_BACKUP_OFFER'){
                    var bidTypeGa='Place Backup Offer';
                }
            }
            return bidTypeGa;
        },

    };

    window.onerror = function(message, file, line) {
        GAUtils.eventTracking.trackevent('New JS Error In the Page', window.location.pathname,file + ':' + line + '\n\n' + message,null,1);
    };

    return util;

});
