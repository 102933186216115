
/* START_TEMPLATE */
define('hbs!tpl/popups/userError',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"modal fade user-type-warning\" id=\"userErrorPopupDiv\" role=\"dialog\" aria-hidden=\"false\">\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content \">\n            <div class=\"modal-header popUpTitle\">\n                <div class=\"width85per headerText\">";
  stack1 = ((helper = (helper = helpers.errorHead || (depth0 != null ? depth0.errorHead : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"errorHead","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n                <span class=\"cross\" data-dismiss=\"modal\"></span>\n            </div>\n            <div class=\"modal-body \">\n                <div class=\"warning-message\">\n                    ";
  stack1 = ((helper = (helper = helpers.errorContent || (depth0 != null ? depth0.errorContent : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"errorContent","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                </div>\n                <div class=\"row closeButtonUserError\">\n                    <button class=\"popUpclose btn btn-default textFade userErrorClose\" data-dismiss=\"modal\">";
  stack1 = ((helper = (helper = helpers.closeButton || (depth0 != null ? depth0.closeButton : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"closeButton","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</button>\n                </div>\n            </div>\n        </div>\n    </div>\n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/popups/userError', t);
return t;
});
/* END_TEMPLATE */
;