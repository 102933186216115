
/*jslint browser:true*/
/*global define, root*/
define('newRegistrationView',['require','jquery','underscore','backbone','mobileVerificationView','GoogleAnalyticsUtils','commonutils','forgeCryptoJs','validator','hbs!tpl/loginRegister/registrationNewSkin','hbs!tpl/loginRegister/registrationNew','loginView'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone"),
        MobileVerificationView = require("mobileVerificationView");

    var GAUtils = require("GoogleAnalyticsUtils");
    var Utils = require("commonutils");
    var forge = require("forgeCryptoJs");
    var validator = require("validator");
    if (typeof newSkinning !== "undefined" && newSkinning) {
      var registerTemp  =  require("hbs!tpl/loginRegister/registrationNewSkin");  
    }else{
      var registerTemp  =  require("hbs!tpl/loginRegister/registrationNew");  
    }
    

    var loginView = require('loginView');
    var eventLabel = $.cookie('visitorId');

    var registrationView = Backbone.View.extend({
        
        callback: null,
        viewObject: null,
        componentsCmsNode: {},
        networkCallInProgress: false,
        events: {
            'submit .userLeanRegistration':"submitRegistrationData",
            "blur .name" : "validateNameField",
            "blur .registerEmailId" : "validateEmailField",
            "blur .leanPhoneNo" : "validatePhoneNumber",
            "blur .registerPassword" : "validatePasswordField",
            "click .avtarIcon" : "changeIcon",
            "keyPress .avtarIcon" : "changeIcon",
            "focus .avtarIcon" : "changeIcon",
            "click .open-login-view": "toggleView",
            "click .open-register-view": "toggleView",
            "click .optInSMsRegistration":"showHidePhoneConsentForm"

        },

        initialize: function () {
       	    try{
                window.mobileVerificationView = MobileVerificationView;
                var thisObj=this;
                $('.closeButtonSubsConfirmation').click(function(e){
                    $('#leanRegistrationSuccess').css('display','none');
                });
                $(window).click(function(){
                    $('.anonymousLimitError').fadeOut();
                });
               
                if(typeof dashboardContent === "object" && dashboardContent.registration) {
                    this.componentsCmsNode = dashboardContent;
                } else {
                    this.componentsCmsNode = saveListDrawerContent;
                }
                this.isPlaceBidClicked = false;
                this.globalAlertsCmsNode = typeof saveListDrawerContent !== 'undefined' && saveListDrawerContent.globalAlerts.labels || {};
                this.render();

                
                $('#loginRegisterDialog').on('hidden.bs.modal', function (e) {
                    $('.loginRegisterPopup').find('input[type="text"], input[type="password"]').val('');
                }); 

            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:initialize|" + e, null, 1);
            }
        },
        toggleView: function(e) {
            e.stopPropagation();
            if(typeof golbalPDPView !== "undefined" && golbalPDPView.showAlertsContainer) {
                if($(".ga-login-container").hasClass('active')){
                  $(".title-container .title-text, .mobile-title .title-txt").text(this.globalAlertsCmsNode.lockboxAccessCodeRegister);
                } else {
                  $(".title-container .title-text, .mobile-title .title-txt").text(this.globalAlertsCmsNode.lockBoxAccess);
                }
            }
            $(".ga-login-container").toggleClass('active');
            $(".ga-register-container").toggleClass('active');
            loginView.createCaptch();
            this.clearValidationMessages(e);
        },
        showHidePhoneConsentForm:function(e){
            var focusElement = $(e.target);
            var isOptIn = focusElement.is(":checked");
            var trackText =  'UID : '+window.userSession.userId+ '| Visitor Id :'+$.cookie('visitorId');
            if(isOptIn){
                $('.registrationPhoneBlock').slideDown();
                GAUtils.eventTracking.trackevent("", "Opt in For SMS", trackText +"|" + isOptIn+ " | Referrer: GA Registration");
            }else{
                $('.registrationPhoneBlock').slideUp();
                GAUtils.eventTracking.trackevent("", "Opt in For SMS", trackText +"|" + isOptIn+ " | Referrer: GA Registration");
            }
        },
        validatePhoneNumber : function(event, callback){
            var self = this;
            var errorMessage = this.componentsCmsNode.errorMessages.phoneNumberRequired;
            // var obj = event.target,
            var obj = '#globalRegisrationContainer .leanPhoneNo',
            value = $.trim($(obj).val());
            if(value === ''){
               $('.' + $(obj).attr('error')).html(errorMessage);
                this.addErrorClass(obj, false);
            }else if(!(validator.phone($.trim($(obj).val()) , obj))){
                var errorMessage = this.componentsCmsNode.errorMessages.errorPhoneno;
                $('.' + $(obj).attr('error')).html(errorMessage);

                $('.' + $(obj).attr('error')).removeClass('hide');
                this.addErrorClass(obj, true);

            }else{
                // $('.' + $(obj).attr('error')).addClass('hide');
                // $('.' + $(obj).attr('error')).html('');
                // this.addSuccessClass(obj);
                if(typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                    var params = {};
                    params.callback = callback;
                    params.phoneNumber = value;
                    params.compObj = self;
                    mobileVerificationView.verifyDuplicatePhoneNumber(params);
                } else {
                    self.duplicatePhoneSucessCallback();
                }
                // if(mobileVerificationView && mobileVerificationView.mobileVerificationCheck){
                //     var phoneDuplicateURI = "portal/isPhoneNumberInUse?phoneNumber="+value;
                //     $.ajax({
                //         type: "GET",
                //         url: phoneDuplicateURI,
                //         success: function(result){
                //             if(result.status=="SUCCESS" && !result.data){
                //                 $('.' + $(obj).attr('error')).addClass('hide');
                //                 $('.' + $(obj).attr('error')).html('');
                //                 self.addSuccessClass(obj);
                //             }else{
                //                 $('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.phoneErrorMessages[data.status]);
                //                 self.addErrorClass(obj, true);
                //             }
                //             if(callback) callback();
                //         },
                //         error: function(xhr, ajaxOptions, thrownError){
                //             $('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.phoneErrorMessages['techError']);
                //             self.addErrorClass(obj, true);
                //             $('#ajax-loader-mask').css('display','none');
                //             $('#ajax-loader').css('display','none');
                //             GAUtils.eventTracking.trackevent("Error", "New Registration", "Validate DuplicatePhone|" + xhr.status, null, 1);
                //             if(callback) callback();
                //         }
                //     });
                // } else {
                //     $('.' + $(obj).attr('error')).addClass('hide');
                //     $('.' + $(obj).attr('error')).html('');
                //     this.addSuccessClass(obj);
                // }

            }
        },

        duplicatePhoneSucessCallback : function(callback, options){
            var obj = '#globalRegisrationContainer .leanPhoneNo';
            var self = this;
            $('.' + $(obj).attr('error')).addClass('hide');
            $('.' + $(obj).attr('error')).html('');
            self.addSuccessClass(obj);
            if(callback) callback(options);
        },

        duplicatePhoneErrorCallback : function(data){
            var obj = '#globalRegisrationContainer .leanPhoneNo';
            var self = this;
            $('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.phoneErrorMessages[data.status]);
            self.addErrorClass(obj, true);
        },

        changeDisableIcon:function(){
            try{
                $('.form-control').removeClass('clicked');
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:changeDisableIcon|" + e, null, 1);
            }
        },
        changeIcon:function(e){
          try{
            var iconName = $(e.target).attr('iconName');
            $('.'+iconName).addClass('clicked');
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:changeIcon|" + e, null, 1);
          }
        },
        validateNameField : function(event){
            try {
              var obj = event.target,
                  value = $.trim($(obj).val()),
                  errorMessage = event.target.id === 'leanRegFirstName' 
                                  ?this.componentsCmsNode.errorMessages.firstNameIsRequired
                                  :this.componentsCmsNode.errorMessages.lastNameIsRequired;

                                 
             this.targetId =  $(event.target).parents('.registerCommon').attr('id');
             

              if(value === ''){

                $('#'+this.targetId+' .' + $(obj).attr('error')).html(errorMessage);
                this.addErrorClass(obj, false);

              } else if(!(validator.isValidName(value, obj))){

                $('#'+this.targetId+' .' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.charactersAllowedLabel);
                this.addErrorClass(obj, true);

              }else{
                this.addSuccessClass(obj);
              }
            }catch(e){
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:validateNameField|" + e, null, 1);
            }
        },
        addErrorClass: function(elementObject, hasValue){

          try{
              var closestNode = $(elementObject).closest('.input-box-container');
              $(closestNode).find('.input-box-error').addClass('input-label-error');
              $(closestNode).find('.input-box-success').removeClass('input-label-success');
              $('.' + $(elementObject).attr('error')).addClass('has-input-error');
              $(closestNode).find('.input-box-active').removeClass('input-box-progress');
              
              $('.'+$(elementObject).attr('iconname')).removeClass('clicked');
              $('.'+$(elementObject).attr('avtarImage')).removeClass('active');
              
              $(elementObject).addClass('hasInputError');

              if(hasValue){
                $(closestNode).find('.input-box-label').addClass('input-box-filled');  
              } else {
                $(closestNode).find('.input-box-label').removeClass('input-box-filled');  
              }
              this.networkCallInProgress = false;
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:addErrorClass|" + e, null, 1);
          }

        },  
        addSuccessClass: function(elementObject){
          try{
              var closestNode = $(elementObject).closest('.input-box-container');
              $(closestNode).find('.input-box-success').addClass('input-label-success');
              $(closestNode).find('.input-box-error').removeClass('input-label-error');
              $(closestNode).find('.input-box-label').addClass('input-box-filled');
              $('.' + $(elementObject).attr('error')).removeClass('has-input-error');
              $(closestNode).find('.input-box-active').removeClass('input-box-progress');
              $(elementObject).removeClass('hasInputError');

              $('.'+$(elementObject).attr('iconname')).removeClass('clicked');
              $('.'+$(elementObject).attr('avtarImage')).removeClass('active');

              this.networkCallInProgress = false;
          }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:addSuccessClass|" + e, null, 1);
          }
        },
        addInprogressClass: function(elementObject){
            var closestNode = $(elementObject).closest('.input-box-container');
            $(closestNode).find('.input-box-label').addClass('input-box-filled');
            $(closestNode).find('.input-box-active').addClass('input-box-progress');

            this.networkCallInProgress = true;
        },
        
        validatePasswordField : function(event, callback){
          try{

            var self = this;
            if(typeof event.target !== 'undefined' ){
                var obj = event.target;
            }else if(typeof event !== 'undefined'){
                var obj = event;
            }

            this.targetId =  $(obj).parents('.registerCommon').attr('id');
              /*If element is not visible, then return; If user saved his email/passwords that will prepopulate and trigger focusout event*/
              if(!$(obj).is(":visible")) { $(obj).val(""); return; }
              
              /*if(!($("#loginRegisterDialog").data('bs.modal') && $("#loginRegisterDialog").data('bs.modal').isShown)){
                  if(callback) callback();
                  return;
              }*/
              if($.trim($(obj).val()) ==''){

                  $('#'+this.targetId+' .' + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.fieldRequired);
                  this.addErrorClass(obj, false);
                  if(callback) callback();

              }else if(!(validator.isValidPassword($.trim($(obj).val())))){
                  
                  $('#'+this.targetId+' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.strongErrorLabel);
                  this.addErrorClass(obj, true);
                  if(callback) callback();

              }else{
                  
                  this.addInprogressClass(obj);

                  var pUrl = ctxPath+'/portal/lean-regn/isValidPassword';
                  var leanRegEmailId=$('#'+self.targetId+' .registerEmailId').val();
                  var leanRegPassword=$('#'+self.targetId+' .registerPassword').val();
                  var postData = {'emailId':leanRegEmailId,'passCode':leanRegPassword,'userType':'NEWUSER'};

                  // Get access token 
                  Utils.getAccessToken({viewObject: self, callback: executeTokenCallback});
                            
                  function executeTokenCallback(accessData) {
                  
                    postData.passCode = Utils.encryptPassword({ password: postData.passCode, salt: accessData.salt});
                    postData.requestId = accessData.requestId;

                    $.ajax({
                        type: "POST",
                        url: pUrl,
                        data: postData,
                        dataType: 'json',
                        success: function(data){
                          if(data.status=="SUCCESS"){
                              self.addSuccessClass(obj);
                          }else{
                              self.addErrorClass(obj, true);
                              var errorTmp = $(obj).attr('error'),
                                    elemClass = '.lean-info-tooltip';
                              $('.' + errorTmp).html(_.escape($('#'+self.targetId+' ' + elemClass).attr('data-content')));
                          }
                          if(callback) callback();
                        },
                        error: function(xhr, ajaxOptions, thrownError){
                              $('#ajax-loader-mask').css('display','none');
                              $('#ajax-loader').css('display','none');
                              GAUtils.eventTracking.trackevent("Error", "New Registration", "error:validate Password" + xhr.status, null, 1);
                              if(callback) callback();
                            }
                    }); 
                }

              }
          }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:validatePasswordField|" + e, null, 1);
          }
        },
        
        validateEmailField : function(event, callback){
          try
          {
            if(typeof event.target !== 'undefined' ){
                var obj = event.target;
            }else if(typeof event !== 'undefined'){
                var obj = event;
            }
            
            this.targetId =  $(obj).parents('.registerCommon').attr('id');

            var emailUrl = ctxPath+'/portal/user/isValidEmailAddress',
                self = this;

            /*If element is not visible, then return; If user saved his email/passwords that will prepopulate and trigger focusout event*/
            if(!$(obj).is(":visible")) { $(obj).val(""); return; }
              
            $.trim($(obj).val($.trim($(obj).val()).toLowerCase()));

            if($.trim($(obj).val()) == '') {

                $('#'+this.targetId+' .'  + $(obj).attr('error')).html(this.componentsCmsNode.errorMessages.emailIsRequired);
                this.addErrorClass(obj, false);
                if(callback) callback();

            } else if(!(validator.isEmail($.trim($(obj).val()) , obj))){

                $('#'+this.targetId+' .'  + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.errorEmailId);
                this.addErrorClass(obj, true);
                if(callback) callback();

            }else{

              this.addInprogressClass(obj);

              $.ajax({
                  type: "POST",
                  url: emailUrl,
                  data: {'email':$.trim($(obj).val())},
                  dataType: 'json',
                  success: function(data){

                      if(data.status=="SUCCESS"){
    
                          $('#'+self.targetId+' .leanPasswordToolTip').attr('data-content', self.componentsCmsNode.registration.toolTipMessages.passWordToolTip);
                          self.addSuccessClass(obj);

                          var currentTarget = $('#leanRegPassword'),
                              passwordValue = $.trim($(currentTarget).val()),
                              closestNode = $(currentTarget).closest('.input-box-container');

                          // if(passwordValue && !$(closestNode).find('.passwordIcon').hasClass('clicked')){
                          //     self.validatePasswordField();
                          // }

                      }else{
                        
                          $('.' + $(obj).attr('error')).html(self.componentsCmsNode.registration.errorMessages.emailErrorMessages[data.status]);
                          self.addErrorClass(obj, true);
                      }
                      if(callback) callback();
                  },
                  error: function(xhr, ajaxOptions, thrownError){
                        $('#ajax-loader-mask').css('display','none');
                        $('#ajax-loader').css('display','none');
                        GAUtils.eventTracking.trackevent("Error", "New Registration", "validate Email" + xhr.status, null, 1);
                        if(callback) callback();
                  }
              });
              
            }
          }
           catch(e)
           {
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:validateEmailField|" + e, null, 1);
           }
        },
       
        submitRegistrationData:function(event){
          try{
           
            this.targetId =  $(event.currentTarget).parents('.registerCommon').attr('id');
            var formDataRegisterJson={};
            var userRegister=[];
            var gaData = eventLabel; 
            var obj = event.target;
            var self = this;

            this.targetId =  $(obj).parents('.registerCommon').attr('id');
            
            var targetId = this.targetId;

            if(this.funnelStart) {
              gaData = eventLabel + " | referrer: GlobalAlerts";
            } 
            if(this.srcName){
              gaData = eventLabel + " | referrer: "+this.srcName;
            }
            if(targetId == 'globalRegisrationContainer'){
                gaData = eventLabel + " | referrer: GlobalAlerts";
            } else {
                gaData = eventLabel + " | referrer: Landing page ";
            }

            if (typeof golbalPDPView !== "undefined" && golbalPDPView.showAlertsContainer ) {
                gaData = eventLabel + " | referrer: GlobalAlerts | source: LockboxCode" ;
            }

            $('#confirmLeanRegistration').attr('disabled','disabled');

            GAUtils.eventTracking.trackevent("","Submit Registration Click",gaData);
            var that = this;
            $('#'+this.targetId+' .errorAll').addClass('hide');
            
            $('#'+this.targetId+' .name').blur();
            if($('#'+this.targetId+ " .optInSMsRegistration").is(":visible")){
                $('#'+this.targetId+' .leanPhoneNo').blur();

            }
            
            that.validateEmailField('#'+that.targetId+' .registerEmailId', function(){
                that.validatePasswordField('#'+that.targetId+' .registerPassword', function(){
                    that.validatePhoneNumber('#'+that.targetId+' .leanPhoneNo', function(){
                        var errorItem =  $(obj).find('input.hasInputError');
                        if(errorItem.length > 0){
                            $(obj).find('input.hasInputError').first().focus();
                            $('#confirmLeanRegistration').prop('disabled', false);
                        }else{
                            var data = that.ConvertFormToJSON($(obj));
                            data.notifyBuyerAgent = $('#'+that.targetId+' .notifyBuyerAgent').val();
                            var registrationUrl =contextPath+'/portal/lean-regn/registerUser';
                            var thisObj = that;
                            if($('#'+thisObj.targetId+ " .optInSMsRegistration").is(":visible")){
                                if($('#'+thisObj.targetId+' .optInSMsRegistration').is(":checked")){
                                    data.smsOptInFlag = 'Y';
                
                                }
                
                            }


                            // Start loading after specified time
                            var delayTime = saveListDrawerContent.GlobalConfig.loaderDelay || 3000;
                            var showRegStatusLoader = setTimeout(function() {
                                $('#ajax-loader-mask').css('display','block');
                                $('#ajax-loader').css('display','block');
                            }, delayTime);
                            
                            $('#'+thisObj.targetId+' .errorAll').addClass('hide').removeClass('validationError');
                            
                            var options={};

                            // Get access token 
                            Utils.getAccessToken({viewObject: that, callback: executeTokenCallback});
                                    
                            function executeTokenCallback(accessData) {
                            
                                // data.password = Utils.encryptPassword({ password: data.password, salt: accessData.salt});
                                data.cryptoAlgVersion = 'V1';
                                if(isEnableNewEncryption && forge){
                                    data.cryptoAlgVersion = 'V2';
                                    data.password = Utils.forgeEncryptPassword({ password: data.password, salt: accessData.salt});
                                } else {
                                    data.password = Utils.encryptPassword({ password: data.password, salt: accessData.salt});
                                }
                                data.requestId = accessData.requestId;

                                $.ajax({
                                    type: "POST",
                                    url: registrationUrl,
                                    data: JSON.stringify(data),
                                    dataType: 'json',
                                    contentType: "application/json; charset=utf-8",
                                    success: function(data){
            
                                        // Clear time outs - remove showRegStatusLoader from window
                                        clearTimeout(showRegStatusLoader);
            
                                        if(data.status=="SUCCESS"){
            
                                            // FOR APP - firebase logging
                                            var visitor_id = $.cookie('visitorId') || '';
                                            if (window.notifyNativeApp) {
                                                window.notifyNativeApp(JSON.stringify({
                                                    message: "logGaEvent",
                                                    category: "event",
                                                    type: "register",
                                                    action: "Register Completed",
                                                    source: visitor_id
                                                }), '*');
                                            }

                                            // If subscribed user completes his registration, then delete his session
                                            if ( Utils.getCookieDetails('subscribeEmail') && window.atob(Utils.getCookieDetails('subscribeEmail')) ===  $.trim($('#emailId').val()) ){
                                                Utils.deleteCookie('subscribeEmail');

                                            }
                                            if(Utils.getCookieDetails('subscribeEmail')){
                                                // GAUtils.eventTracking.trackevent("","Register Completed",gaData + " SRC : Subscriber Registration | Source: Direct");
                                                options.gaLabel = gaData + " SRC : Subscriber Registration | Source: Direct";
                                            }
                                            else{
                                                // GAUtils.eventTracking.trackevent("","Register Completed",gaData + "| Source: Direct");
                                                options.gaLabel = gaData + "| Source: Direct";
                                            }

                                            options.trackRegistrationEvent = true;
                                            that.funnelStart = false;
                                            options.email = $.trim($('#'+thisObj.targetId+' .registerEmailId').val());
                                            options.password = $('#'+thisObj.targetId+' .registerPassword').val();
                                            options.referrer = (targetId == 'globalRegisrationContainer') ? 'globalalts' : '';
                                            
                                            if(targetId != 'globalRegisrationContainer') {
                                                options.callback=that.afterRegistrationCallBack;
                                                options.viewObject=that; 
                                            }
                                            Utils.userLogin(options);
                                        }else{
                                            GAUtils.eventTracking.trackevent("","Submit Registration Click Failure",gaData);
                                            $('#ajax-loader-mask').css('display','none');
                                            $('#ajax-loader').css('display','none');
                                            var errorMessages = that.componentsCmsNode.errorMessages.errorCriteria;
                                            if(data && data.errorMessage){
                                                errorMessages = that.componentsCmsNode.errorMessages[data.errorMessage]  || errorMessages;
                                            } 
                                            $('#'+thisObj.targetId+' .errorAll').html(errorMessages).removeClass('hide').addClass('validationError').show();
                                        }
                                        $('#confirmLeanRegistration').prop('disabled', false);
                                    },
                                    error: function(xhr, ajaxOptions, thrownError){
                                        $('#ajax-loader-mask').css('display','none');
                                        $('#ajax-loader').css('display','none');
                                        GAUtils.eventTracking.trackevent("Error", "New Registration", "error:Submit Registration" + xhr.status, null, 1);
                                        $('#confirmLeanRegistration').prop('disabled', false);
                                    }
                                });
                            }    
                            
                        }
                    });
                });
            });
            
           }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:submitRegistrationData|" + e, null, 1);
          } 
        },
        hideGlobalAlertSlider:function(){
            $('.gobal-alerts-drawer-slider,.gobal-alerts-overlay').removeClass('is-visible');
            $('.gobal-alerts-drawer-popup').modal('hide');
        },
        
        afterRegistrationCallBack: function(response,thisObj){
            
            $('.errorAll').addClass('hide').removeClass('validationError');
            $("#globalRegisrationContainerStatic, .registerContainer").hide();
                
            window.location.href = contextPath + "/portal/my-shopper";

            $('#ajax-loader-mask').css('display','none');
            $('#ajax-loader').css('display','none');

        },
        ConvertFormToJSON: function(form){
          try{
              
            var array = jQuery(form).serializeArray();
            var json = {};  
            
            jQuery.each(array, function() {  
                var nameArray =    (this.name).split('-');
                json[nameArray[1]] = (this.value).trim() || '';    
            });
           
            return json;
            }catch(e){
            GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:ConvertFormToJSON|" + e, null, 1);
          }
        },
        
        initPopOver : function(){
            try{
               
              
              
              $('#globalRegisrationContainer .popOverInfo').popover('destroy');
              $('#globalRegisrationContainer .popOverInfo').popover({
                  trigger: 'manual',
                  html: true,
                  placement: 'bottom',
                  container: '#globalRegisrationContainer .leanRegTooltip'
              });

              $('#globalRegisrationContainerStatic .popOverInfo ').popover('destroy');
              $('#globalRegisrationContainerStatic .popOverInfo').popover({
                  trigger: 'manual',
                  html: true,
                  placement: 'bottom',
                  container: '#globalRegisrationContainerStatic .leanRegTooltip'
              });

                $('#globalRegisrationContainerStatic .popOverInfo').on("click mouseover", function() {
                    $('#globalRegisrationContainerStatic .popOverInfo').not(this).popover('hide');
                    $(this).popover('show');
                });

                $('#globalRegisrationContainer .popOverInfo').on("click mouseover", function() {
                    $('#globalRegisrationContainer .popOverInfo').not(this).popover('hide');
                    $(this).popover('show');
                });

                $(window).on('click',function(e) {
                    if($(e.target).hasClass('popOverInfo')){
                        return false;
                    }else{
                      $('#globalRegisrationContainerStatic .popOverInfo, #globalRegisrationContainer .popOverInfo').popover('hide');
                    }
                });


            }catch(e){
              GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:initPopOver|" + e, null, 1);
            }
        },
        loadSubscriberDetails : function(){
            var subscribeUser = JSON.parse(Utils.getCookieDetails('subscribeUserDetails')) || {};
            var renderParentObj = this.targetId;
            if( ($('#'+renderParentObj+' .registerEmailId').val() === "" ) && Utils.getCookieDetails('subscribeUserDetails')){
                subscribeUser = JSON.parse(Utils.getCookieDetails('subscribeUserDetails')) || {};
                if(subscribeUser.lastName && subscribeUser.firstName && subscribeUser.emailId) {
                    $('#'+renderParentObj+' .registerFirstName').val(subscribeUser.lastName);
                    $('#'+renderParentObj+' .registerLastName').val(subscribeUser.firstName);
                    $('#'+renderParentObj+' .registerEmailId').val(subscribeUser.emailId); 
                    this.addSuccessClass($('#'+renderParentObj+' .registerEmailId'));
                    this.addSuccessClass($('#'+renderParentObj+' .registerLastName'));
                    this.addSuccessClass($('#'+renderParentObj+' .registerFirstName'));
                }                        
            }   
        },
        render: function(renderParentObj){

            try{
                // Registration render
                var subscribeUser;
                if(Utils.getCookieDetails('subscribeUserDetails')){
                    subscribeUser = JSON.parse(Utils.getCookieDetails('subscribeUserDetails'));
                }
                var self = this;
                this.componentsCmsNode.registration.socialLoginView="N";
                if(typeof saveListDrawerContent != 'undefined' && typeof saveListDrawerContent.socialLoginConfig != 'undefined' && typeof saveListDrawerContent.socialLoginConfig.isSocialLoginEnable != 'undefined' && saveListDrawerContent.socialLoginConfig.isSocialLoginEnable == "Y"){
                    this.componentsCmsNode.registration.socialLoginView="Y";
                    this.componentsCmsNode.registration.isEnableAppleLogin = (saveListDrawerContent.socialLoginConfig.isEnableAppleLogin && saveListDrawerContent.socialLoginConfig.isEnableAppleLogin == 'Y') ? 'Y' : 'N';
                }
                this.componentsCmsNode.registration['subscribeUser'] = subscribeUser;
                this.componentsCmsNode.registration.registrationLabel.regTermsAndConditions = this.componentsCmsNode.registration.registrationLabel.regTermsAndConditions.replace('###contextPath###',contextPath);
                this.targetId = renderParentObj;
                
                $('#'+renderParentObj).html(registerTemp(this.componentsCmsNode.registration));
               
                if (typeof newSkinning !== "undefined" && newSkinning) {
                    $(".defaultAlertContainer").addClass("h-new-gl-container");
                } else {
                    $(".defaultAlertContainer").addClass("old-global-alerts-drawer");
                }
                
                $('#'+renderParentObj+' .ga-register-container').addClass('active');
                $('#'+renderParentObj+' .input-box-editor').val('');
                if(Utils.getCookieDetails('subscribeUserDetails')){
                    this.loadSubscriberDetails();
                }

                

                if( Utils.getCookieDetails('subscribeEmail') ){
                    $('#'+renderParentObj+' .registerEmailId').val(window.atob(Utils.getCookieDetails('subscribeEmail'))).blur(); 
                }  

                setTimeout(function(){
                    self.initPopOver();
                }, 500);

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:render|" + e, null, 1);
            }

        },
        
        
        clearValidationMessages: function(options) {
            
            try {
                options = options || {};

                var self = this,
                    view = options.target && $(options.target).attr('data-view') || options.view || '',
                    loginContainer = loginView.$el,
                    registrationContainer = $(".ga-register-container");

                var $el = $('.ga-login-register-container'),
                    subscribeUser,
                    loginEmail = Utils.getCookieDetails('loggedInEmail') || null,
                    passwordObject = loginContainer.find('.ga-login-password'),
                    emailObject = loginContainer.find('.ga-login-email');

                if(view === 'registration'){
                    if( ( registrationContainer.find('.registerEmailId').val() === "" ) && Utils.getCookieDetails('subscribeUserDetails')){
                        subscribeUser = JSON.parse(Utils.getCookieDetails('subscribeUserDetails')) || {};
                        if(subscribeUser.lastName && subscribeUser.firstName && subscribeUser.emailId) {
                            registrationContainer.find('input[name="lean-lastName').val(subscribeUser.lastName);
                            registrationContainer.find('input[name="lean-firstName').val(subscribeUser.firstName);
                            registrationContainer.find('.registerEmailId').val(subscribeUser.emailId); 
                        }                        
                    }
                    if(view === 'registration' && (registrationContainer.find('.registerEmailId').val() === "") && Utils.getCookieDetails('subscribeEmail')){
                        registrationContainer.find('.registerEmailId').val(window.atob(Utils.getCookieDetails('subscribeEmail'))); 
                    }    
                }
                
                if(view === 'login' && loginEmail){
                    self.addInprogressClass(emailObject);
                    if( Utils.getCookieDetails('subscribeEmail')){
                        emailObject.val(window.atob(Utils.getCookieDetails('subscribeEmail'))); 
                    } else{
                        emailObject.val(loginEmail);                                      

                    }
                    setTimeout(function(){
                        loginView.validateEmailField(null, function(response){
                            setTimeout(function(){
                                loginView.addInprogressClass(passwordObject);
                                passwordObject.focus();                                             
                            }, 300);
                        });
                    }, 300);
                }

                setTimeout(function(){
                    $el.find('input[type="text"], input[type="password"]').each(function(){
                        if($.trim($(this).val())){
                            $(this).blur();
                        }
                    });
                }, 300);
                
                $el.find('input[type="text"], input[type="password"]').each(function(){

                    $(this).removeClass('hasInputError');

                    var objectClass = $(this).attr('error');
                    $('.' + objectClass + 'Parent').removeClass('validationError');
                    $('.' + objectClass).removeClass('has-input-error')
                });

                $('.input-box-error').removeClass('input-label-error')
                $('.input-box-success').removeClass('input-label-success')
                $('.input-box-label').removeClass('input-box-filled')

                $('.avtarImage').removeClass('clicked');
                $('.responseError').empty();
                $('.errorAll').hide();
                if(loginContainer.find('.loginFormWarning')) loginContainer.find('.loginFormWarning').empty();

                //reset password tooltip content
                registrationContainer.find('.leanPasswordToolTip').attr('data-content', self.componentsCmsNode.registration.toolTipMessages.passWordToolTip);

            } catch(e) {
                GAUtils.eventTracking.trackevent("Error", "New Registration", "file:registrationView.js|function:clearValidationMessages|" + e, null, 1);
            }

        },
        
    });

    return new registrationView;
});



