
/* START_TEMPLATE */
define('hbs!tpl/globalAlerts/defaultUserAlerts',['hbs','hbs/handlebars', 'hbs!templates/globalAlerts/globalAlertsLoginRegistration', 'hbs!templates/globalAlerts/globalAlertsProfileView','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "		<div class=\"row adjustMargin\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "LOGIN", {"name":"ifEqual","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "PROFILE", {"name":"ifEqual","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "			\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "IDOLOGY", {"name":"ifEqual","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "		</div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials['templates/globalAlerts/globalAlertsLoginRegistration'], '				', 'templates/globalAlerts/globalAlertsLoginRegistration', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"4":function(depth0,helpers,partials,data) {
  var stack1, buffer = "";
  stack1 = this.invokePartial(partials['templates/globalAlerts/globalAlertsProfileView'], '				', 'templates/globalAlerts/globalAlertsProfileView', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(depth0,helpers,partials,data) {
  return "			\n				<div class=\"profileProgressBar\">\n					<div class=\"profile-progress-bar\">\n						<div><span class=\"glyphicon glyphicon-ok profile-success-symbol\">&nbsp;</span></div>\n						<div class=\"stage-success\"></div>\n						<div><span class=\"glyphicon glyphicon-ok profile-success-symbol\">&nbsp;</span></div>\n						<div class=\"stage-success\"></div>\n						<div><span class=\"glyphicon profile-progress-symbol\">&nbsp;</span></div>\n					</div>\n					<div class=\"profile-progress-titles\">\n						<div>Registration</div>\n						<div>Profile Completion</div>\n						<div>Idology Verification</div>\n					</div>\n				</div>\n\n	            <div id=\"globalAlertsIdology\" class=\"verifyIdologyLean\">\n				</div>\n";
  },"8":function(depth0,helpers,partials,data) {
  return "  hide ";
  },"10":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "			<div class=\"user-image-container\">\n				<div class=\"sign-register-image\">\n					<svg version=\"1.1\" style=\"height: 30px; width:30px;\" class=\"sign-in-register-icon\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n						viewBox=\"0 0 24 24\" style=\"enable-background:new 0 0 24 24;\" xml:space=\"preserve\">\n					<g>\n						<path class=\"st0\" d=\"M20.1,12.4c-0.3-0.3-0.6-0.4-1-0.4H8.6V7.4c0-1,0.4-1.9,1.1-2.6c0.7-0.7,1.6-1.1,2.6-1.1s1.9,0.4,2.6,1.1\n							c0.7,0.7,1.1,1.6,1.1,2.6c0,0.2,0.1,0.5,0.3,0.6c0.2,0.2,0.4,0.3,0.6,0.3h0.9c0.2,0,0.5-0.1,0.6-0.3s0.3-0.4,0.3-0.6\n							c0-1.8-0.6-3.3-1.9-4.5S14,1,12.3,1S9,1.6,7.7,2.9C6.5,4.1,5.8,5.6,5.8,7.4V12H5.4c-0.4,0-0.7,0.1-1,0.4C4.1,12.7,4,13,4,13.4v8.3\n							c0,0.4,0.1,0.7,0.4,1C4.7,22.9,5,23,5.4,23h13.7c0.4,0,0.7-0.1,1-0.4c0.3-0.3,0.4-0.6,0.4-1v-8.3C20.5,13,20.4,12.7,20.1,12.4\n							L20.1,12.4z M20.1,12.4\" style=\"fill:#B1B1B1;\"/>\n					</g>\n					</svg>\n				</div>\n			</div>\n			<div class=\"heading\">\n				Sign In / Register\n			</div>\n			<div class=\"description\">\n				"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.signInContent : stack1)) != null ? stack1.description : stack1), depth0))
    + "\n			</div>\n			<div class=\"actions\">   \n				<button class=\"btn btn-primary ga-login-button\" id=\"loginNewPopupAlerts\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.signInContent : stack1)) != null ? stack1.signinLabel : stack1), depth0))
    + "</button>\n			</div>\n			<div class=\"link-actions\">\n				<span>New user? </span><a href=\"javascript:void(0)\" id=\"loginRegisterPopupAlerts\" class=\"ga-register-button\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.signInContent : stack1)) != null ? stack1.registerLabel : stack1), depth0))
    + "</a>\n			</div>\n";
},"12":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "			<div class=\"user-image-container\">\n				<div class=\"profile-complete-image\">\n					<svg version=\"1.1\" style=\"height: 30px; width: 30px;\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n						viewBox=\"0 0 24 24\" style=\"enable-background:new 0 0 24 24;\" xml:space=\"preserve\">\n					<g>\n						<path style=\"fill:#B1B1B1;\" class=\"st0\" d=\"M18.9,16.8L15.5,15c-0.3-0.2-0.5-0.5-0.5-0.9v-1.2c0.1-0.1,0.2-0.2,0.3-0.3c0.5-0.6,0.8-1.3,1.1-2.1\n							c0.5-0.2,0.8-0.7,0.8-1.3V7.7c0-0.4-0.1-0.7-0.4-1V4.8c0-0.2,0.1-1.4-0.8-2.4c-0.7-0.9-2-1.3-3.6-1.3c-1.6,0-2.9,0.4-3.6,1.3\n							C7.9,3.4,8,4.6,8,4.8v1.9C7.8,7,7.6,7.3,7.6,7.7v1.5c0,0.4,0.2,0.9,0.5,1.1c0.3,1.3,1,2.3,1.3,2.6v1.2c0,0.4-0.2,0.7-0.5,0.8\n							l-3.3,1.8C4.7,17.3,4,18.4,4,19.6v1.2c0,1.7,5.5,2.2,8.4,2.2c2.9,0,8.4-0.5,8.4-2.2v-1.1C20.8,18.5,20.1,17.3,18.9,16.8L18.9,16.8z\n							M18.9,16.8\"/>\n					</g>\n					</svg>\n				</div>\n			</div>\n			<div class=\"heading\">\n				"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.profileContent : stack1)) != null ? stack1.heading : stack1), depth0))
    + "\n			</div>\n			<div class=\"description\">\n				"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.profileContent : stack1)) != null ? stack1.description : stack1), depth0))
    + "\n			</div>\n			<div class=\"actions\">\n				<a class=\"btn-primary btn ga-profile-button\" href=\""
    + escapeExpression(((helper = (helper = helpers.contextPath || (depth0 != null ? depth0.contextPath : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"contextPath","hash":{},"data":data}) : helper)))
    + "/portal/my-shopper#myProfile\">"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.profileContent : stack1)) != null ? stack1.profileLabel : stack1), depth0))
    + "</a>\n			</div>\n";
},"14":function(depth0,helpers,partials,data) {
  var stack1, helper, lambda=this.lambda, escapeExpression=this.escapeExpression, functionType="function", helperMissing=helpers.helperMissing;
  return "			<div class=\"user-image-container\">\n				<div class=\"verification-image\">\n					<svg version=\"1.1\" class=\"verification-icon\" style=\"height: 30px; width:30px;\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n						viewBox=\"0 0 24 24\" style=\"enable-background:new 0 0 24 24;\" xml:space=\"preserve\">\n					<g>\n						<g>\n							<path style=\"fill:#B1B1B1;\" class=\"st0\" d=\"M8.9,23c-0.7,0-1.3-0.3-1.7-0.9l-5.6-7.4c-0.7-1-0.5-2.3,0.4-3.1c1-0.7,2.3-0.5,3.1,0.4l3.7,4.9L18.1,2\n								c0.6-1,2-1.3,3-0.7c1,0.6,1.3,2,0.7,3L10.8,22C10.4,22.6,9.8,23,8.9,23C9,23,9,23,8.9,23L8.9,23z M8.9,23\"/>\n						</g>\n					</g>\n					</svg>\n				</div>\n			</div>\n			<div class=\"heading\">\n				"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.idologyContent : stack1)) != null ? stack1.heading : stack1), depth0))
    + "\n			</div>\n			<div class=\"description\">\n				"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.idologyContent : stack1)) != null ? stack1.description : stack1), depth0))
    + "\n			</div>\n			<div class=\"actions\">\n				<a class=\"btn-primary btn ga-idology-button\" href=\""
    + escapeExpression(((helper = (helper = helpers.contextPath || (depth0 != null ? depth0.contextPath : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"contextPath","hash":{},"data":data}) : helper)))
    + "/portal/my-shopper#myProfile\">\n				"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.globalAlerts : stack1)) != null ? stack1.defaultAlerts : stack1)) != null ? stack1.idologyContent : stack1)) != null ? stack1.verifyLabel : stack1), depth0))
    + "\n				</a>\n			</div>\n";
},"16":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "	            <div class=\"col-xs-5 col-sm-4 col-md-4 registerHead\" >\n	              ";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.registration : stack1)) != null ? stack1.headStaticContent : stack1)) != null ? stack1.heading1 : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n	            </div>\n	            <div class=\"col-xs-7 col-sm-8 col-md-8 staticContent\">\n	                <ul>\n";
  stack1 = helpers.each.call(depth0, ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.registration : stack1)) != null ? stack1.headStaticContent : stack1)) != null ? stack1.content : stack1), {"name":"each","hash":{},"fn":this.program(17, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	                </ul>\n	            </div>\n";
},"17":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "	                     <li >"
    + escapeExpression(lambda(depth0, depth0))
    + "</li>\n";
},"19":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "	        	<div class=\"heading\">\n	        		"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.myProfilePageContent : stack1)) != null ? stack1.profileAccordionIntroHeading : stack1), depth0))
    + "\n	        	</div>\n	        	<div class=\"description\">\n	        		"
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.myProfilePageContent : stack1)) != null ? stack1.profileAccordionIntroMsg : stack1), depth0))
    + "\n	        	</div>\n";
},"21":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "	            <div class=\"heading\">\n	            	"
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.myProfilePageContent : stack1)) != null ? stack1.idology : stack1)) != null ? stack1.idologyDisplayHeading : stack1), depth0))
    + "\n	            </div>\n	            <div class=\"description\">\n	            	";
  stack1 = lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.componentsCmsNode : depth0)) != null ? stack1.myProfilePageContent : stack1)) != null ? stack1.idology : stack1)) != null ? stack1.idologyDisplayContent : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n				</div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, buffer = "<div class=\"defaultAlertContainer\" >\n	\n\n";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDefaultAlertsEnabled : depth0), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n\n	<div class=\"row ";
  stack1 = helpers['if'].call(depth0, (depth0 != null ? depth0.isDefaultAlertsEnabled : depth0), {"name":"if","hash":{},"fn":this.program(8, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "LOGIN", {"name":"ifEqual","hash":{},"fn":this.program(10, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "PROFILE", {"name":"ifEqual","hash":{},"fn":this.program(12, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "IDOLOGY", {"name":"ifEqual","hash":{},"fn":this.program(14, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n		<div class=\"col-md-12 view-details-expand pull-left\">\n			<div class=\"signin-content\">\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "LOGIN", {"name":"ifEqual","hash":{},"fn":this.program(16, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "	        \n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "PROFILE", {"name":"ifEqual","hash":{},"fn":this.program(19, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "	        \n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, (depth0 != null ? depth0.userStatus : depth0), "IDOLOGY", {"name":"ifEqual","hash":{},"fn":this.program(21, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "	        </div>\n		</div>\n	</div>\n</div>";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('tpl/globalAlerts/defaultUserAlerts', t);
return t;
});
/* END_TEMPLATE */
;