
/* START_TEMPLATE */
define('hbs!templates/loginRegister/loginNewSkin',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "<div class=\"container-fluid\">\n	<form class=\"form-horizontal userRegisterClass ga-login-form\"  onsubmit=\"return false\">\n		<div class=\"login-default-message\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.loginTopContent : stack1), depth0))
    + "</div> \n		<div class=\"login-form-error responseError\" ></div>\n		<div class=\"login-form-Warning responseWarning loginFormWarning\"></div>\n		\n		<div class=\"formFieldsParent\">\n			\n			<div class=\"form-group formFieldParent\">\n				<div class=\"col-xs-12 col-md-12 col-lg-12 inputBoxHeight\">\n\n					<div class='input-box-container required'>\n						<span class=\"avtarImage emailIdIcon\"></span>\n						<input type=\"text\" class=\"input-box-editor ga-login-email\" name=\"emailId\" iconName=\"emailIdIcon\"  maxlength=\"50\" error=\"erroremailAddress\" title=\"Email\" autocomplete=\"off\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "\"/>\n						<span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.emailId : stack1), depth0))
    + "</span>\n						<span class=\"input-box-active\"></span>\n						<span class=\"input-box-error\"></span>\n						<span class=\"input-box-success\"></span>\n						<div class=\"errorPH  erroremailAddress\"></div>\n					</div>\n				</div>\n			</div>\n			<div class=\"form-group formFieldParent\">\n				<div class=\"col-xs-12 col-md-12 col-lg-12 inputBoxHeight\">\n					\n					<div class='input-box-container required'>\n						<span class=\"avtarImage passwordIcon\"></span>\n						<input type=\"password\" class=\"input-box-editor ga-login-password\" name=\"password\" iconName=\"passwordIcon\" maxlength=\"50\" error=\"errorpassword\" title=\"Password\" autocomplete=\"off\" placeholder=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "\"/>\n						<span class=\"input-box-label\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.registrationLabel : depth0)) != null ? stack1.password : stack1), depth0))
    + "</span>\n						<span class=\"input-box-active\"></span>\n						<span class=\"input-box-error\"></span>\n						<span class=\"input-box-success\"></span>\n						<span class=\"forgotPasswordLink\">\n							<a href=\"javascript:void(0);\" class=\"ga-forgot-password-link\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.passwordLabel : depth0)) != null ? stack1.forgotPasswordLink : stack1), depth0))
    + "</a>\n						</span>\n						<div class=\"errorPH errorpassword\"></div>\n					</div>\n					\n				</div>\n			</div>\n			<div class=\"h-input-section\" id=\"recaptchaGlobalPH\"></div>\n			<div class=\"form-group signinActions\">\n				<div class=\"col-xs-12 col-md-12 col-lg-12 registerDefault\">\n					<button type=\"submit\" class=\"btn btn-primary ga-login-submit\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.signin : stack1), depth0))
    + "</button>\n				</div>\n			</div>\n		</div>\n	</form>\n</div>\n";
},"useData":true});
Handlebars.registerPartial('templates/loginRegister/loginNewSkin', t);
Handlebars.registerPartial('templates/loginRegister/loginNewSkin', t);
return t;
});
/* END_TEMPLATE */
;