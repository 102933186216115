
/* START_TEMPLATE */
define('hbs!templates/loginRegister/registrationHeadCommon',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "                <li >"
    + escapeExpression(lambda(depth0, depth0))
    + "</li>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<div class=\"form-group leanHeadParentAnonym hide\">\n        ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.headStaticContent : depth0)) != null ? stack1.headingAnonym : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n    </div>\n    <div class=\"form-group leanHeadParent\">\n        <div class=\"col-xs-3 col-sm-3 col-md-3 registerHead\" >\n            ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.headStaticContent : depth0)) != null ? stack1.heading1 : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </div>\n        <div class=\"col-xs-9 col-sm-9 col-md-9 staticContent\">\n        <ul>\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.headStaticContent : depth0)) != null ? stack1.content : stack1), {"name":"each","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </ul>\n        </div>\n    </div> ";
},"useData":true});
Handlebars.registerPartial('templates/loginRegister/registrationHeadCommon', t);
Handlebars.registerPartial('templates/loginRegister/registrationHeadCommon', t);
return t;
});
/* END_TEMPLATE */
;