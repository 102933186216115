
/* START_TEMPLATE */
define('hbs!tpl/placeBestBidDetailsModal',['hbs','hbs/handlebars','templates/helpers/ifEqual'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.alreadyPlacedBestBid : stack1), {"name":"if","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n       <div class=\"row bestbidhint\">\n          "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.bestbidhint : stack1), depth0))
    + "\n       </div> \n       <div class=\"row minBidAmount\">\n            <div class=\"col-xs-5 col-sm-4 property\">\n            \n              "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.minimumBidAmount : stack1), depth0))
    + "\n            </div>\n            <div class=\"col-xs-7 col-sm-8 propertyValue\" >\n                "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.minimumBidAmount : stack1), depth0))
    + "\n            </div>\n       </div>\n";
},"2":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "          <div class=\"row bestbidhint\">\n            "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.alreadyPlacedHint : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.alreadyPlacedBestBid : stack1), depth0))
    + ", "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.yourMinimumBidAmount : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.minimumBidAmount : stack1), depth0))
    + "\n          </div>\n";
},"4":function(depth0,helpers,partials,data) {
  return "hide";
  },"6":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "     <div class=\"row buyerPremium buyerPremiumRow\">\n          <div class=\"col-xs-6 col-sm-4 property\">\n              "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.buyersPremium : stack1), depth0))
    + " <span class=\"buyerPremiumPercentage\"></span>\n          </div>\n          <div class=\"col-xs-6 col-sm-8 propertyValue\" id=\"buyerPremium\">\n              "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.bidAmountDetail : stack1)) != null ? stack1.buyerPremiumFormatted : stack1), depth0))
    + "\n          </div>\n     </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helperMissing=helpers.helperMissing, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div class=\"popUpInformation\">    \n  <div class=\"bestBidDiv\">          \n    <div id=\"somethingWrong\"></div>\n\n";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.isBackupBidRevampApplicable : stack1), "N", {"name":"ifEqual","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "     <div class=\"row buyerPremiumRow bidAmount\">\n          <div class=\"col-xs-5 col-sm-4 property\">\n              "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.bidLimit : stack1), depth0))
    + "\n          </div>\n          <div class=\"col-xs-7 col-sm-8\">\n              <div class=\"bid-plus-minus\">\n                  <span class=\"bid-minus disable ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.isBackupBidRevampApplicable : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">-</span>\n                  <label class=\"hide\" for=\"bestBid-amount-custom\">Bid Amount</label>\n                  <input type=\"text\" id=\"bestBid-amount-custom\" autocomplete=\"off\" maxlength=\"9\" value=\"\" title=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.bidLimit : stack1), depth0))
    + "\" data-listingId=\""
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.listingId : stack1), depth0))
    + "\">\n                  <span class=\"bid-plus ";
  stack1 = ((helpers.ifEqual || (depth0 && depth0.ifEqual) || helperMissing).call(depth0, ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.isBackupBidRevampApplicable : stack1), "Y", {"name":"ifEqual","hash":{},"fn":this.program(4, data),"inverse":this.noop,"data":data}));
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">+</span>\n              </div>\n              <div class=\"b_bid_amount_msg\"></div>\n              <div class=\"bid-error-msg\"></div>\n          </div>\n     </div>\n     <div class=\"row hidden\" id=\"techFeePlaceBestBidSection\">\n          <div class=\"col-xs-6 col-sm-4 property\">\n              "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.technologyFee : stack1), depth0))
    + "\n          </div>\n          <div class=\"col-xs-6 col-sm-8 propertyValue\" id=\"techFee\">\n              "
    + escapeExpression(lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.bidAmountDetail : stack1)) != null ? stack1.technologyFeeFormatted : stack1), depth0))
    + "\n          </div>\n     </div>\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.nobuyerPremium : depth0), {"name":"unless","hash":{},"fn":this.program(6, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        <div class=\"row totalDue robotoregular\">\n            <div class=\"col-xs-6 col-sm-4 property\">\n                "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.totalDue : stack1), depth0))
    + "\n            </div>\n            <div class=\"col-xs-6 col-sm-8 propertyValue\" id=\"totalDue\"></div>\n        </div>\n        <div class=\"row backUpBids\">\n            <div class=\"row buyerPremiumRow propertyValue\" >\n                Would you like to hold this bid as a backup bid?\n            </div>\n            <div class=\"row radio-container buyerPremiumRow\">\n                <div class=\"radio-wrapper\">\n                    <input type=\"radio\" name=\"backupBidAvailable\" title=\"Yes, hold it as a backup bid\" value=\"yes\" />\n                    <label for=\"backupBidAvailable\">Yes, hold it as a backup bid.</label>\n                </div>\n                <div class=\"radio-wrapper\">\n                    <input type=\"radio\" name=\"backupBidAvailable\" title=\"No, don’t hold it as a backup bid\" value=\"no\" />\n                    <label for=\"backupBidAvailable\">No, don't hold it as a backup bid.</label>\n                </div>  \n            </div>\n            <div class=\"daysDiv\">\n                <div class=\"select-side\">\n                    <i class=\"glyphicon glyphicon-menu-down blue\"></i>\n                </div>\n                <label class=\"hide\" for=\"backupExpireSelect\">Days</label>\n                <select id=\"backupExpireSelect\" class=\"form-control backupExpireSelect styledSelect descSelectBox\">\n                    \n                </select>\n            </div> \n            <div class=\"bid-expiry-error hide\">\n                ";
  stack1 = lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.errorMsg : stack1)) != null ? stack1.expiryDate : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n            </div>\n        </div>\n        <div class=\"row conditions-txt hide\"></div>\n        <div class=\"row submitButtons\">\n            <button class=\"saveAutoBid btn btn-primary textFade\" id=\"submitPlaceBestBid\" type=\"button\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.submit : stack1), depth0))
    + "</button>\n            <button class=\"btn btn-default cancelButton hidden-xs hide\" type=\"button\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.close : stack1), depth0))
    + "</button>\n        </div>\n    </div>\n    <div class=\"thankyou hide\">\n      <div class=\"row borderBottomRemove text-center \">\n          <div class=\"col-xs-12 \">\n             "
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.thankyou : stack1), depth0))
    + "\n          </div>\n      </div>\n      <div class=\"row text-center\">\n        <button class=\" btn btn-primary textFade \" id=\"thankyouOk\" type=\"button\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.close : stack1), depth0))
    + "</button>\n      </div>\n    </div>\n           \n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/placeBestBidDetailsModal', t);
return t;
});
/* END_TEMPLATE */
;