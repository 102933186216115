
/* START_TEMPLATE */
define('hbs!tpl/idologyVerification/idologyVerificationParent',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, helper, functionType="function", helperMissing=helpers.helperMissing, buffer = "<div class=\"modal fade verifyIdologyLean\" id=\"verifyIdologyLean\" tabindex=\"-1\" role=\"dialog\"  aria-hidden=\"true\">\n  <div class=\"modal-dialog modal-login\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <h4 class=\"modal-title idologyHeading\">";
  stack1 = ((helper = (helper = helpers.idologyVerificationHead || (depth0 != null ? depth0.idologyVerificationHead : depth0)) != null ? helper : helperMissing),(typeof helper === functionType ? helper.call(depth0, {"name":"idologyVerificationHead","hash":{},"data":data}) : helper));
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</h4>\n        <div class=\"closeButton\" data-dismiss=\"modal\">&nbsp;</div>\n      </div>\n      <div class=\"modal-body\" id=\"verifyIdologyPopup\">\n      </div>\n    </div>\n  </div>\n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/idologyVerification/idologyVerificationParent', t);
return t;
});
/* END_TEMPLATE */
;