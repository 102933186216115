
define('queueUtil',['jquery'], function(jQuery) {

    try {
        
        var requestList = [],
            isRequestPending = false;

        function executeRequest(options, promise, trigger) {
            var complete, error, options, params, success, that = this;

            if (trigger !== false) trigger = true;
            if (trigger) isRequestPending = true;

            var displayTime = options.displayTime,
                name = options.name,
                callback = options.callback || null; 

            if(callback) {
                delete options.callback;
                callback(options);
            }

            setTimeout(function(){
                if (trigger) depushToQueue();
            }, displayTime)

        };

        function depushToQueue() {
            var args, options, promise;
            if ((args = requestList.shift())) {
                options = args[0], promise = args[1];
                executeRequest(options, promise);
            } else {
                isRequestPending = false;
            }
        };

        function pushToQueue(options) {
            var promise, type, queue;
            promise = $.Deferred();

            if (isRequestPending) {
                requestList.push([options, promise]);
            } else {
                executeRequest(options, promise, true);
            }
            return promise;
        };

        jQuery.queueItem = function(options) {
            pushToQueue(options);
        };
        
    } catch(exception) {
        console.log('Error in Queue Utils :', exception);
    }
});