
/* START_TEMPLATE */
define('hbs!tpl/saveDrawer/favourite-slider',['hbs','hbs/handlebars', 'hbs!templates/globalAlerts/loaderIcon'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "        <div class=\"drawer-register-links hidden-xs\">\n            <div class=\"heading\">\n                <div class=\"heading1\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.regHead1 : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n                <div class=\"heading2\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.regHead2 : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n            </div>\n            <ul class=\"ul-saved-items\">\n                ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.regContent : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n                \n            </ul>\n            <div class=\"register-button\">\n                <button type=\"button\" id=\"loginRegisterPopupDrawer\" class=\"btn btn-primary\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.registerButton : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</button>\n            </div>\n            <div class=\"login-button\">\n                ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.registerLabel : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += " <a href=\"javascript:void(0);\" id=\"loginNewPopupDrawer\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.loginButton : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "</a>\n            </div>\n        </div>\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, buffer = "<div class=\"favorites-drawer-container\">\n    <div class=\"drawer-title hidden-xs\">\n        <div class=\"title-icon\">\n            <svg class=\"savesearch-link-icon\"  version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n                width=\"293.1px\" height=\"253.8px\" viewBox=\"0 0 293.1 253.8\" enable-background=\"new 0 0 293.1 253.8\" xml:space=\"preserve\">\n                <path fill=\"none\" stroke=\"#000\" stroke-width=\"14\" stroke-miterlimit=\"10\" d=\"M269.2,35.6c-41.6-54.2-123.8-22.2-123.8,32.8\n                    c0-55-82.1-86.9-123.8-32.8c-43.2,56-0.6,154,123.8,210.4C269.6,189.6,312.2,91.6,269.2,35.6z\"/>\n            </svg>\n        </div>\n        <div class=\"title-text\">\n            ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.sliderHeading : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n        </div>\n        <div class=\"title-close\">\n            <a href=\"javascript:void(0);\" class=\"drawer-cancel\" aria-label=\"Close Save list drawer\">\n                <svg class=\"close-icon\" version=\"1.1\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\"\n                    width=\"24px\" height=\"24px\" viewBox=\"0 0 24 24\" enable-background=\"new 0 0 24 24\" xml:space=\"preserve\">\n                    <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" x1=\"2.9\" y1=\"21.2\" x2=\"20.7\" y2=\"3.3\"/>\n                    <line fill=\"none\" stroke=\"#231F20\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" x1=\"20.7\" y1=\"21.2\" x2=\"2.9\" y2=\"3.3\"/>\n                </svg>\n            </a>\n        </div>\n    </div>\n    <div class=\"mobile-title hidden-lg hidden-md hidden-sm\">\n        <div>";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.sliderHeading : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n    </div>\n    <div class=\"drawer-content \">\n";
  stack1 = helpers.unless.call(depth0, (depth0 != null ? depth0.loggedIn : depth0), {"name":"unless","hash":{},"fn":this.program(1, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  buffer += "        \n        <div class='favourites-user-data hide'>\n            <ul class=\"nav nav-tabs drawer-links\">\n                <li class=\"active\"><a data-toggle=\"tab\" href=\"#favouritesTab\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.watchedTitle : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</a></li>\n                <li><a data-toggle=\"tab\" href=\"#savedSearchesTab\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.saveSearchTitle : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</a></li>\n            </ul>\n            <div class=\"tab-content\">\n                <div id=\"favouritesTab\" class=\"tab-pane fade in active\">\n                    <div class=\"favourite-default\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.saveThisHomeDesc : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</div>\n                    <div class=\"favourites-count\"></div>\n                    \n                    <div class=\"favourites-list\"></div>\n                    <div class=\"showMoreWatch hide\" id=\"watchedPropertyShowMoreDiv\">\n                        <button type=\"button\" id=\"watchPropertyShowMore\" class=\"btn btn-primary\">";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.showAllWatch : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</button>\n                    </div>\n                    <div class=\"searched-loading hide \" id=\"savedWatchLoadingIcon\">\n";
  stack1 = this.invokePartial(partials['templates/globalAlerts/loaderIcon'], '                        ', 'templates/globalAlerts/loaderIcon', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  buffer += "                    </div>\n                </div>\n                <div id=\"savedSearchesTab\" class=\"tab-pane fade\">\n                    <p class=\"saved-items-overlay--copy indication savedSearchContent savedSearch-default-text\" id=\"savedSearch-default-text\" >";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.saveSearchContent : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</p>\n                    <div class=\"save-searches-count\"></div>\n                    <div class=\"save-searches-list\"></div>\n                    <div class=\"showmore-savesearches\">\n                        <button class=\"searched-suggestion--show-all\" id=\"savedSearchShowMore\"> ";
  stack1 = lambda(((stack1 = (depth0 != null ? depth0.labels : depth0)) != null ? stack1.showAllSaveSearch : stack1), depth0);
  if (stack1 != null) { buffer += stack1; }
  buffer += "</button>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </div>\n    <div class=\"favorites-loader\">\n        <div class=\"favorites-loader-icon\">\n";
  stack1 = this.invokePartial(partials['templates/globalAlerts/loaderIcon'], '            ', 'templates/globalAlerts/loaderIcon', depth0, undefined, helpers, partials, data);
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </div>\n    </div>\n</div>";
},"usePartial":true,"useData":true});
Handlebars.registerPartial('tpl/saveDrawer/favourite-slider', t);
return t;
});
/* END_TEMPLATE */
;