
define('userProfileView',['require','handlebars','jquery','underscore','backbone','GoogleAnalyticsUtils','dUtil','validator','commonutils','licenseView','dashboardSignInView','hbs!tpl/globalAlerts/licenseTemplate','hbs!tpl/buyerDashboard/editLicenseDetails','loginRegisterView','hbs!tpl/globalAlerts/loaderIcon','hbs!tpl/globalAlerts/userProfileView','hbs!tpl/buyerDashboard/confirmationModal'],function (require) {
    'use strict';

    var Handlebars = require("handlebars");
    Handlebars.registerHelper('ifEqual', function (value1, value2, options) {
        var value1 = $.trim(value1);
        var value2 = $.trim(value2);
        if (typeof value1 == 'string') {
            if (value1.toLowerCase() == value2.toLowerCase()) {
                return options.fn(this);
            }
            return options.inverse(this);
        } else {
            if (value1 == value2) {
                return options.fn(this);
            }
            return options.inverse(this);
        }

    });

    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone"),
        GAUtils = require("GoogleAnalyticsUtils"),
        dUtil = require("dUtil"),
        validator = require("validator"),
        enteredFields = [],
        previousFieldName,
        eventNumber=0,
        Utils = require("commonutils"),
        licenseView = require("licenseView"),
        dashboardSignInView = require("dashboardSignInView"),
        agentLicensListTemplate = require("hbs!tpl/globalAlerts/licenseTemplate"),
        licenseDetailsTemplate = require("hbs!tpl/buyerDashboard/editLicenseDetails"),
        loginRegisterView = require('loginRegisterView'),
        loaderIcon = require("hbs!tpl/globalAlerts/loaderIcon"),
        userProfileViewTemplate = require("hbs!tpl/globalAlerts/userProfileView"),
    
        confirmationModal = require("hbs!tpl/buyerDashboard/confirmationModal");

    var userProfileView = Backbone.View.extend({
        //el: $(".top-header-navigation"),
        callback: null,
        viewObject: null,


        events: {
            "blur #userFirstName,#userLastName": "validateNameField",
            "blur #userStreetName": "validateAddress",
            "blur #userStreetNumber": "validateStreetNumber",
            "blur #userState": "validateState",
            "blur #userTcpaSelection": "validateSMS",
            "blur #userCity": "validateCity",
            "blur #userPhone": "validatePhone",
            "keypress #userZip,#userPhone": "validatePhoneInput",
            "blur #userZip": "validatePostalCode",
            "change #userState": "clearCityValue",
            "change #userTcpaSelection": "hideTCPATerms",
            "keydown #userCity": "clearCityAttributes",
            "click #gaUpdateProfile": "updateBuyerAgentProfile",
            "click .add-license": "saveLicense",
            "click .edit-license": "saveLicense",
            "click .delete-license": "deleteLicense",
            'blur #affiliatedCompany' : 'validateAffliateCompany',
            'blur #licenceNumber' : 'validateLicenseNumber',
            'blur #licenceExpiryDate, .ui-datepicker': 'validateExpiryDate',
            'change .agentswitch' : "agentBuyerSwitchConfirmation",
            'change .ga-profile-container .userLeanRegistration, .modal-popup-container #editLicense' :'attachFieldTrack'
        },

        initialize: function () {
            this.stateList = {};
            this.targetId = 'globalProfileContainer';
            this.dashboardSignInView = new dashboardSignInView();
            this.isShowProfile = false;
            this.profileSubmitted = false;
        },

        attachFieldTrack: function (e) {
            var fieldNameId = $(e.target).attr('id');
            this.fieldLevelGAtracking($(e.target).attr('id'), $(e.target).attr('id'));
            $('#' + fieldNameId).val($.trim($('#' + fieldNameId).val()));
            globalParam.config.profileDataEdited = true;
        },
        fieldLevelGAtracking: function (fieldName, formName) {
            try {
                var actionType;
                if ($.inArray(fieldName, enteredFields) !== -1) {
                    actionType = 'edit';
                } else {
                    enteredFields.push(fieldName);
                    actionType = "new";
                }
                eventNumber++;
                if (previousFieldName === undefined) {
                    previousFieldName = '';
                }
                var eventTracking = 'Field Name : ' + fieldName + ' | Event Number : ' + eventNumber + ' | Previously filled Field Names : ' + previousFieldName + ' | user Type:' + this.agentDisplay + '| Action Type : ' + actionType;
                GAUtils.eventTracking.trackevent("Profile-Forms Global Alerts", '', eventTracking);
                if (previousFieldName === '') {
                    previousFieldName = fieldName;
                } else {
                    previousFieldName += ',' + fieldName;
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:fieldLevelGAtracking|" + e, null, 1);
            }
        },
        /**
         *  Name field validation
         * @param {*} event 
         */


        validateNameField: function (event) {
            try {
                var obj = event.target,
                    value = $.trim($(obj).val()),
                    errorMessage = '';

                if (event.target.id === 'userFirstName') {
                    errorMessage = saveListDrawerContent.errorMessages.firstNameIsRequired
                } else if (event.target.id === 'userLastName') {
                    errorMessage = saveListDrawerContent.errorMessages.firstNameIsRequired
                }

                if (value === '') {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(errorMessage);
                    this.addErrorClass(obj, false);

                } else if (!(validator.isValidName(value, obj))) {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.charactersAllowedLabel);
                    this.addErrorClass(obj, true);
                } else {
                    this.addSuccessClass(obj);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:validateNameField|" + e, null, 1);
            }
        },
        /**
         * function validate STreet number
         * @param {*} event 
         */

        validateStreetNumber: function (event) {
            var obj = event.target,
                value = $.trim($(obj).val());

            if (value === '') {
                return;
            } else {
                this.addSuccessClass(obj);
            }

        },
        /**
         *  Validate Address Field
         * @param {*} event 
         */
        validateAddress: function (event) {
            try {

                var obj = event.target,
                    value = $.trim($(obj).val());

                if (value === '') {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.addressRequired);
                    this.addErrorClass(obj, false);
                } else if (!(validator.streetNumber($.trim($(obj).val()), obj))) {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.charactersAllowedLabel);
                    this.addErrorClass(obj, true);
                } else {
                    this.addSuccessClass(obj);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:validateAddress|" + e, null, 1);
            }
        },
        /**
         * VALIDATE CITY FIELD
         * @param {*} event 
         */
        validateCity: function (event) {
            try {
                var obj = event.target,
                    value = $.trim($(obj).val());

                if (value === '') {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.addressRequired);
                    this.addErrorClass(obj, false);
                } else if (!(validator.isValidCity($.trim($(obj).val()), obj))) {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.cityAllowedChars);
                    this.addErrorClass(obj, true);
                } else {
                    this.addSuccessClass(obj);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:validateCity|" + e, null, 1);
            }
        },

        /** validate State
         * 
         * @param {*} event 
         * @param {*} obj 
         */

        validateState: function (event) {
            try {
                var obj = event.target,
                    closestNode,
                    value = $.trim($(obj).val());

                if (value === '' || value == 'select') {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.addressRequired);
                    this.addErrorClass(obj, false);
                    closestNode = $(obj).closest('.input-box-container');
                    $(closestNode).find('.input-box-label').addClass('hide');
                } else {
                    this.addSuccessClass(obj);
                    closestNode = $(obj).closest('.input-box-container');
                    $(closestNode).find('.input-box-label').removeClass('hide');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:validateState|" + e, null, 1);
            }
        },

        /**
         *  Validate if user SMS Opt in selection
         * @param {*} event 
         */
        validateSMS: function (event) {
            try {
                var obj = event.target,
                    value = $.trim($(obj).val());

                if (value === '' || value == 'select') {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.phoneConsentSection.consentError);
                    this.addErrorClass(obj, false);
                } else {
                    this.addSuccessClass(obj);
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:validateSMS|" + e, null, 1);
            }
        },

        /**
         * Validate postal code
         * @param {*} event 
         */
        validatePostalCode: function (event) {
            try {
                var obj = event.target,
                    value = $.trim($(obj).val());

                if (value === '') {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.zipCodeRequired);
                    this.addErrorClass(obj, false);
                } else if (!(validator.zip($.trim($(obj).val()), obj))) {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.isValidZip);
                    this.addErrorClass(obj, true);
                } else if (($.trim(value).length != 5 && $.trim(value).length > 0) || (parseInt($.trim(value)) < 1)) {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.isValidZip);
                    this.addErrorClass(obj, true);
                } else {
                    this.addSuccessClass(obj);
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:validatePostalCode|" + e, null, 1);
            }
        },
        /**
         * Validate phone field
         * @param {*} event 
         */

        validatePhone: function (event) {
            try {
                // var obj = '#' + event.target.id
                    var obj = '.gobal-alerts-drawer-slider .userLeanRegistration #userPhone',
                    value = $.trim($(obj).val()),
                    self = this;

                if (value === '') {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.phoneNumberRequired);
                    this.addErrorClass(obj, false);
                } else if (!(validator.phone(value, obj))) {
                    $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.errorMessages.errorPhoneno);
                    this.addErrorClass(obj, true);
                } else {
                    // this.addSuccessClass(obj);
                    if(!self.isShowProfile && typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                        var params = {}, options = options || null;
                        params.callback = self.profileSubmitted ? self.updateProfileInformation : null;
                        params.phoneNumber = $('.gobal-alerts-drawer-slider .userLeanRegistration #userPhone').val() || null;
                        params.compObj = self;
                        params.options = options || null;
                        mobileVerificationView.verifyDuplicatePhoneNumber(params);
                    } else {
                        self.duplicatePhoneSucessCallback();
                        self.isShowProfile = false;
                    }
                }


            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:validatePhone|" + e, null, 1);
            }
        },

        duplicatePhoneSucessCallback : function(callback, options){
            var obj = '.gobal-alerts-drawer-slider .userLeanRegistration #userPhone';
            this.addSuccessClass(obj);
            this.profileSubmitted = false;
            if(callback) callback.call(this, options);
        },

        duplicatePhoneErrorCallback : function(data){
            var obj = '.gobal-alerts-drawer-slider .userLeanRegistration #userPhone';
            $('#' + this.targetId + ' .' + $(obj).attr('error')).html(saveListDrawerContent.registration.errorMessages.phoneErrorMessages[data.status]);
            this.profileSubmitted = false;
            this.addErrorClass(obj, true);
        },
        /**
         * Allow only numbers to be entered into fields
         * @param {*} event 
         */
        validatePhoneInput: function (event) {
            try {
                var obj = '#' + event.target.id;
                if (validator.phoneField(event, obj)) {
                    return true;
                } else {
                    return false;
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:validatePhoneInput|" + e, null, 1);
            }
        },


        /**
         * Get master list of state to load on the profile page
         */
        getStateList: function (e) {
            var self = this;
            jQuery.httpRequest({
                url: contextPath + "/portal/stateLists",
                type: 'GET',
                success: function (response) {
                    self.stateList = response;
                },
                error: function (error) {
                    GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:getStateList|" + e, null, 1);
                }
            });
        },

        /**
         *  Load Cities 
         * @param {*} options 
         */

        fetchCities: function (options) {
            try {
                var self = this;

                jQuery.httpRequest({
                    url: contextPath + "/portal/lean-regn/cityListForState?state=" + options.stateCode,
                    type: 'GET',
                    success: function (response) {
                        self.updateCityList(response, options);
                    },
                    error: function (e) {
                        GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:fetchCities|" + e, null, 1);
                    }
                });

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:fetchCities|" + e, null, 1);
            }
        },
        refreshLicenseList: function (options) {
            try {
                options = options || {};

                var self = options.viewObject || this,
                    allLicenseDetailsUrl = contextPathPortal + '/lean-regn/allLicenceDetails';

                dUtil.showLoader();
                dUtil.jqueryPost(allLicenseDetailsUrl, {}, self.licenseRefreshCallback, self);

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:refreshLicenseList|" + e, null, 1);
            }
        },
        licenseRefreshCallback: function (outData, inputData, self) {
            try {

                self.$el.find("#userFirstName,#userLastName").blur();
                self.addSuccessClass(self.$el.find("#userEmail"));

                if (outData.status == 'SUCCESS') {
                    $('#buyerAgentLicenseDet').removeClass('hide');
                    var agentLicenseSummaryDtoList = {};
                    var dispData = {};
                    dispData.agentLicenseInfo = outData.response.INDV;
                    dispData.agentCorpLicenseInfo = outData.response.CORP;
                    dispData.dashboardContent = dashboardContent.myProfilePageContent;
                    $.each(dispData.agentLicenseInfo, function (key, value) {
                        dispData.agentLicenseInfo[key].afftCompanyName = value.company;
                        dispData.agentLicenseInfo[key].licenseExpiryDate = value.expiryDate;
                        dispData.agentLicenseInfo[key].agentLicenseId = value.licenseId;
                        dispData.agentLicenseInfo[key].licenseType = 'addLicense';
                    });
                    $.each(dispData.agentCorpLicenseInfo, function (key, value) {
                        dispData.agentCorpLicenseInfo[key].afftCompanyName = value.company;
                        dispData.agentCorpLicenseInfo[key].licenseExpiryDate = value.expiryDate;
                        dispData.agentCorpLicenseInfo[key].agentLicenseId = value.licenseId;
                        dispData.agentCorpLicenseInfo[key].licenseType = 'addCorporateLicense';
                    });

                    if (self.licenseType == 'addLicense') {
                        self.$el.find('.license-list').html(agentLicensListTemplate({
                            dashboardContent: dispData.dashboardContent,
                            licenseList: dispData.agentLicenseInfo
                        }));
                    } else {
                        self.$el.find('.corp-license-list').html(agentLicensListTemplate({
                            dashboardContent: dispData.dashboardContent,
                            licenseList: dispData.agentCorpLicenseInfo
                        }));
                    }

                } else {
                    self.$el.find('.license-list').addClass('hide');
                }
                dUtil.hideLoader();

                $('.agent-licenses-container .add-license').focus();

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:licenseRefreshCallback|" + e, null, 1);
            }
        },
        clearCityAttributes: function (e) {
            try {
                var code = e.keyCode || e.which;
                if (code != 13) {
                    $(e.target).attr('data-city-from-dropdown', 'N');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:clearCityAttributes|" + e, null, 1);
            }
        },

        /**
         * 
         * @param {*} responseData 
         * @param {*} options 
         * @param {*} viewObject 
         */
        updateCityList: function (responseData, options) {
            try {

                var response = responseData || {},
                    cityList = [],
                    elementId,
                    parentElement;

                if (response.status && response.status === 'SUCCESS') {
                    cityList = response.data || [];

                    if(options.sourceObj === "#stateId"){
                        elementId = "#userCity";
                    } else if (options.sourceObj === "#agentLicenseStateId"){
                        elementId = "#agentCity";
                    }   

                    parentElement = $(elementId).parent();

                    $(elementId).autocomplete({
                        appendTo: parentElement,
                        source: cityList,
                        select: function (event, ui) {
                            if (typeof ui.item != 'undefined' && typeof ui.item.value != 'undefined' && ui.item.value != '' && ui.item.value != 'select') {
                                $(this).attr('data-city-from-dropdown', 'Y');
                            }
                        }
                    });
                }

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:updateCityList|" + e, null, 1);
            }
        },
        /**
         *  check if the SMS feature is enabled for the given City
         * @param {*} options 
         */
        checkSMSfeatureEnabled: function (options) {
            try {
                options = options || {};
                var stateCode = options.state || null,
                    self = options.viewObject || this,
                    showSmsOptin,
                    response;

                if (!stateCode) return;

                Utils.fetch({
                    callback: function (options) {
                        options = options || {};
                        response = options.response || {};

                        showSmsOptin = (options.status === 'SUCCESS' && response.status === 'SUCCESS' && response.data === 'Y') ? true : false;
                        self.showTCPAconsentPreferences({
                            status: (showSmsOptin)
                        });
                    },
                    url: contextPathPortal + '/lean-regn/isSmsFeatureEnabled/' + stateCode,
                    viewObject: self,
                    type: 'POST'
                });
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:checkSMSfeatureEnabled|" + exception, null, 1);
            }
        },

        showTCPAconsentPreferences: function (options) {
            options = options || {};
            var status = options.status || null,
                self = this;
            //var status = true; 
            if (status && typeof self.userDetails.data != 'undefined'  && typeof self.userDetails.data.userDetails != 'undefined' && typeof self.userDetails.data.userDetails.smsOptInStatus != 'undefined' && self.userDetails.data.userDetails.smsOptInStatus !='Y' && self.userDetails.data.userDetails.smsOptInStatus !='N') {
                $(".tcpaConsentContainer").removeClass("hide");
            } else {
                $(".tcpaConsentContainer").addClass("hide");
            }

        },

        /**
         * To load the cities based of State and check if SMs feature enabled for the state
         * @param {*} event 
         */

        clearCityValue: function (event) {
            try {
                var obj = '#' + event.target.id,
                    stateCode = $.trim($(obj).val());
                this.fetchCities({
                    stateCode: stateCode,
                    sourceObj: obj
                });

                $('#' + this.targetId + " #userCity").val('');

                // check feature is available for this state or not
                this.checkSMSfeatureEnabled({
                    state: stateCode
                });

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:clearCityValue|" + e, null, 1);
            }
        },

        /**
         *  Function to hide TCPA Terms
         * @param {*} event 
         */

        hideTCPATerms: function (event) {
            var obj = '#' + event.target.id,
                tcpaConsent = $.trim($(obj).val());
            if (tcpaConsent == 'Y') {
                $(".consent-terms").removeClass("hide");
            } else {
                $(".consent-terms").addClass("hide");
            }
        },

        /**
         * Save Buyer Agent Profile 
         * @param {*} options 
         */

        updateBuyerAgentProfile: function (options) {
            var self = this;
            options = options || {};
            self.profileSubmitted = true;
            try {

                $('.userLeanRegistration input').each(function () {
                    $(this).filter(':visible').blur();
                });

                $('.userLeanRegistration select').each(function () {
                    $(this).filter(':visible').blur();
                });
                
                if(typeof mobileVerificationView.mobileVerificationCheckVal != 'undefined' && mobileVerificationView.mobileVerificationCheckVal == 'Y'){
                    var params = {};
                    params.callback = self.updateProfileInformation || null;
                    params.phoneNumber = $('.userLeanRegistration #userPhone').val() || null;
                    params.compObj = self;
                    params.options = options || null;
                    mobileVerificationView.verifyDuplicatePhoneNumber(params);
                } else {
                    self.duplicatePhoneSucessCallback(options);
                }

                // var self = this,
                //     citySelected = $('#userCity').attr('data-city-from-dropdown') === 'Y' ? true : false,
                //     userAddress = {
                //         address1: $.trim($('#userStreetName').val()) || '',
                //         address2: $.trim($('#userStreetNumber').val()) || '',
                //         city: $.trim($('#userCity').val()) || '',
                //         cityFromDropDown: citySelected,
                //         state: $.trim($('#userState').val()) || '',
                //         zip: $.trim($('#userZip').val()) || ''
                //     },
                //     userProfileData = {
                //         "firstName": $.trim($('#userFirstName').val()) || '',
                //         "lastName": $.trim($('#userLastName').val()) || '',
                //         "emailId": $.trim($('#userEmail').val()) || '',
                //         "phoneNumber": $.trim($('#userPhone').val()) || ''
                //     },
                //     userRole = $('.userLeanRegistration input[name=userType]:checked').val() || '',
                //     userRoles = [userRole],
                //     requestData = {},
                //     saveProfileUrl = null,
                //     degaultAgentDetails = null,
                //     alertView = window.globalAlertView || null;

                // userProfileData.address = userAddress;
                // userProfileData.userRoles = userRoles;

                // // validate & update TCPA consent if available 
                // if( !$('#'+self.targetId+" .tcpaConsentContainer").hasClass("hide") ) {
                //     userProfileData.smsOptInStatus = $("#userTcpaSelection").val();
                // } else {
                //     userProfileData.smsOptInStatus = null;
                // }

                // if (userRole === 'AGENT') {
                //     saveProfileUrl = contextPath + '/portal/lean-regn/saveUserDetails';
                //     requestData = userProfileData;
                // } else {
                //     saveProfileUrl = contextPath + '/portal/lean-regn/saveBuyerProfile';
                //     requestData = {
                //         userDetails: userProfileData,
                //         agentDetails: degaultAgentDetails
                //     };
                // }

                // // adding time stamp to utl
                // var currentTimeStamp = new Date().getTime();
                // saveProfileUrl += '?'+currentTimeStamp;

                // if($('.userLeanRegistration input.hasInputError').length > 0){ 
                //     $('.userLeanRegistration input.hasInputError').first().focus();    
                //     return false;
                // } else if($('.userLeanRegistration select.hasInputError').length > 0){ 
                //     $('.userLeanRegistration select.hasInputError').first().focus();    
                //     return false;
                // } else {
                //     self.saveUserProfile({}, {
                //         saveProfileUrl: saveProfileUrl,
                //         requestData: requestData,
                //         viewObject: self
                //     })
                // }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:updateBuyerAgentProfile|" + e, null, 1);
            }
        },

        updateProfileInformation : function(options){
            var self = this,
            citySelected = $('#userCity').attr('data-city-from-dropdown') === 'Y' ? true : false,
            userAddress = {
                address1: $.trim($('#userStreetName').val()) || '',
                address2: $.trim($('#userStreetNumber').val()) || '',
                city: $.trim($('#userCity').val()) || '',
                cityFromDropDown: citySelected,
                state: $.trim($('#userState').val()) || '',
                zip: $.trim($('#userZip').val()) || ''
            },
            userProfileData = {
                "firstName": $.trim($('#userFirstName').val()) || '',
                "lastName": $.trim($('#userLastName').val()) || '',
                "emailId": $.trim($('#userEmail').val()) || '',
                "phoneNumber": $.trim($('#userPhone').val()) || ''
            },
            userRole = $('.userLeanRegistration input[name=userType]:checked').val() || '',
            userRoles = [userRole],
            requestData = {},
            saveProfileUrl = null,
            degaultAgentDetails = null,
            alertView = window.globalAlertView || null;

            userProfileData.address = userAddress;
            userProfileData.userRoles = userRoles;

            // validate & update TCPA consent if available 
            if( !$('#'+self.targetId+" .tcpaConsentContainer").hasClass("hide") ) {
                userProfileData.smsOptInStatus = $("#userTcpaSelection").val();
            } else {
                userProfileData.smsOptInStatus = null;
            }

            if (userRole === 'AGENT') {
                saveProfileUrl = contextPath + '/portal/lean-regn/saveUserDetails';
                requestData = userProfileData;
            } else {
                saveProfileUrl = contextPath + '/portal/lean-regn/saveBuyerProfile';
                requestData = {
                    userDetails: userProfileData,
                    agentDetails: degaultAgentDetails
                };
            }

            // adding time stamp to utl
            var currentTimeStamp = new Date().getTime();
            saveProfileUrl += '?'+currentTimeStamp;

            if($('.userLeanRegistration input.hasInputError').length > 0){ 
                $('.userLeanRegistration input.hasInputError').first().focus();
                return false;
            } else if($('.userLeanRegistration select.hasInputError').length > 0){ 
                $('.userLeanRegistration select.hasInputError').first().focus();
                return false;
            } else {
                self.saveUserProfile({}, {
                    saveProfileUrl: saveProfileUrl,
                    requestData: requestData,
                    viewObject: self
                })
            }
        },

        saveUserProfile: function(userData, options){

            options = options || {};

            var self = options.viewObject,
                saveProfileUrl = options.saveProfileUrl,
                requestData = options.requestData,
                alertView = window.globalAlertView || null;

            self.$el.find(".updateProfile").addClass('btn-disable');
            self.$el.find(".updateProfile").append(loaderIcon());
            self.$el.find(".updateProfile").css('pointer-events', 'none');

            try {
                jQuery.httpRequest({
                    url: saveProfileUrl,
                    type: "POST",
                    data: JSON.stringify(requestData),
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",

                    success: function (response) {

                        self.$el.find(".updateProfile").removeClass('btn-disable');
                        self.$el.find(".updateProfile .lds-spinner").remove();
                        self.$el.find(".updateProfile").css('pointer-events', 'all');

                        $('.userLeanRegistration input').each(function () {
                            $(this).filter(':visible').blur();
                        });
        
                        $('.userLeanRegistration select').each(function () {
                            $(this).filter(':visible').blur();
                        });

                        if ( response.status === 'SESSION_REQUIRED' ) {
                            loginRegisterView.openLoginPopup({
                                callback: self.saveUserProfile,
                                viewObject: options,
                                statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                            });
                        }else if (response.status == 'SUCCESS') {
                            if (response.data.userDetails != undefined) {
                                $('.headerBody .scrName, #welcomeLogout .user-welcome-name').text(response.data.userDetails.firstName);
                            } else {
                                $('.headerBody .scrName, #welcomeLogout .user-welcome-name').text(response.data.firstName);
                            }

                            GAUtils.eventTracking.trackevent("", "Profile Update Sucess", window.userSession.userId + " | Global Alerts Drawer");
                            /** TODO: need to handle it in different way */
                            window.userSession.userStatus = 'ACTIVE';
                            if (alertView) {
                                alertView.renderGlobalAlerts();
                            }
                            if (window.location.href.indexOf('/my-shopper') > -1) {
                                window.location.reload();
                            }

                        } else {
                            var errorMessage = response &&
                                response.errorMessage &&
                                saveListDrawerContent.myProfilePageContent.errorMsgCMS.profileUpdateErrors[response.errorMessage] ||
                                saveListDrawerContent.myProfilePageContent.errorMsgCMS.genericError;

                            var errorCode = response.errorMessage || '';

                            if (errorCode === 'MISSING_LICENSE_ALERT') {
                                $('#' + self.targetId + ' .agent-license-list .common-license-message').html(errorMessage).removeClass('hide').addClass('errorMessageStyle');
                                $('#' + self.targetId + ' .updateFailure').scrollToView();
                            } else if (errorCode === 'VALIDATION_STATE_ZIP_FAILURE') {
                                $('#'+self.targetId+" .errorLeanRegZip").html(errorMessage);
                                self.addErrorClass("#userZip", true);
                                $('#userZip').focus();
                            } else {
                                $('#' + self.targetId + ' .updateFailure').html(errorMessage).removeClass('hide');
                                $('#' + self.targetId + ' .updateFailure').scrollToView();
                            }
                            GAUtils.eventTracking.trackevent("", "Profile Update Failure", +"Error:  " + errorMessage + " | Global Alerts Drawer");

                        }
                    },
                    error: function (error) {
                        GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:saveUserProfile|" + error, null, 1);
                    }
                });
            } catch (exception) {

            }
        },

        /**
         * Function: Load the User profile tempate on the Global alerts container.
         * @param {*} userDetails 
         * @param {*} options 
         * @param {*} self 
         */

        showProfileDetails: function (outputData, options, viewObject) {

            try {
                options = options || {};

                var self = viewObject || this,
                    container = options.container|| 'globalProfileContainer',
                    userDetails = {},
                    phoneConsentTerms,
                    userRoles,
                    phoneConsent = saveListDrawerContent.phoneConsentSection,
                    referrer = (window.globalAlertView.referrer != null) ? true : false || false;

                
                userDetails.data = outputData.data || {};
                self.targetId = container;
                self.userDetails = userDetails;
                userDetails.data.dashboardContent = saveListDrawerContent.myProfilePageContent;
                userDetails.data.stateList = self.stateList || [];
                userDetails.data.contextPath = (typeof contextPath === 'undefined') ? '' : contextPath;
                phoneConsentTerms = phoneConsent.consentTerms.replace('##PATH##', contextPath);
                userDetails.data.phoneConsentTerms = phoneConsentTerms;
                userDetails.data.phoneConsent = phoneConsent;
                userRoles = userDetails.data.userDetails.userRoles || [];

                if(self.agentSwitchFlag) {
                    if(self.agentswitch == "agent") {
                        userDetails.data.isAgent = true;
                    } else {
                        userDetails.data.isAgent = false;
                    }
                } else {
                    if(_.contains(userRoles, "AGENT")) {
                        userDetails.data.isAgent = true;
                    } else {
                        userDetails.data.isAgent = false;
                    }
                }
                userDetails.data.referrer = referrer;
                userDetails.data.welcomeText = saveListDrawerContent.globalAlerts.newUserProfile.profileCompletion.welcomeText;

                
                $('#'+container).html(userProfileViewTemplate(userDetails.data));    
                
                
                $("#userFirstName,#userLastName").blur();
                self.isShowProfile = true;
                if($('#userPhone').val() != undefined && $('#userPhone').val() !=''){
                    $('#userPhone').blur()
                }
                $("input[name='userType']:checked").focus();
                self.addSuccessClass($("#userEmail"));
                // add license section 

                //if(_.contains(userRoles, "AGENT")) { 
                if(userDetails.data.isAgent){

                    self.agentDisplay = "Agent";                  
                    self.$el.find('.agent-licenses-container').removeClass('hide');

                    if(userDetails.data.agentLicenseInfo && userDetails.data.agentLicenseInfo.length){
                        $.each(userDetails.data.agentLicenseInfo, function(key, value) {
                            userDetails.data.agentLicenseInfo[key].stateId = value.stateDto && value.stateDto.stateId || '';
                            userDetails.data.agentLicenseInfo[key].licenseType = 'addLicense';
                        });
                        self.$el.find('.license-list').html(agentLicensListTemplate({
                            dashboardContent: userDetails.data.dashboardContent,
                            licenseList: userDetails.data.agentLicenseInfo
                        }));
                    } 
                    
                    if(userDetails.data.agentCorpLicenseInfo && userDetails.data.agentCorpLicenseInfo.length){
                        $.each(userDetails.data.agentCorpLicenseInfo, function(key, value) {
                            userDetails.data.agentCorpLicenseInfo[key].stateId = value.stateDto && value.stateDto.stateId || '';
                            userDetails.data.agentCorpLicenseInfo[key].licenseType = 'addCorporateLicense';
                        });
                        self.$el.find('.corp-license-list').html(agentLicensListTemplate({
                            dashboardContent: userDetails.data.dashboardContent,
                            licenseList: userDetails.data.agentCorpLicenseInfo
                        }));
                    }       

                } else {
                    self.agentDisplay = "Buyer";
                    $('.agent-licenses-container').addClass('hide');
                }
                window.globalAlertView.hideLoader();
                $("#ajax-loader, #loaderBG").hide();
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:showProfileDetails|" + e, null, 1);
            }
        },

        /**
         * Function: Get users data from the api
         * @param {*} options 
         * Callback: showProfileDetails();
         */
        getUserDetails: function (options) {
            var self = this;
            window.globalAlertView.showLoader();
            jQuery.httpRequest({
                url: contextPath + '/portal/lean-regn/getUserProfile',
                type: 'GET',
                success: function (response) {
                    self.showProfileDetails(response, options, self);
                },
                error: function (error) {
                    GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:getStateList|" + error, null, 1);
                }
            });
        },

        /**
         *  function to add error class to the elements
         * @param {*} elementObject 
         * @param {*} hasValue 
         */
        addErrorClass: function (elementObject, hasValue) {

            try {
                var closestNode = $(elementObject).closest('.input-box-container');
                $(closestNode).find('.input-box-error').addClass('input-label-error');
                $(closestNode).find('.input-box-success').removeClass('input-label-success');
                $('.' + $(elementObject).attr('error')).addClass('has-input-error');
                $(closestNode).find('.input-box-active').removeClass('input-box-progress');

                $('.' + $(elementObject).attr('iconname')).removeClass('clicked');
                $('.' + $(elementObject).attr('avtarImage')).removeClass('active');

                $(elementObject).addClass('hasInputError');

                if (hasValue) {
                    $(closestNode).find('.input-box-label').addClass('input-box-filled');
                } else {
                    $(closestNode).find('.input-box-label').removeClass('input-box-filled');
                }
                this.networkCallInProgress = false;
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:addErrorClass|" + e, null, 1);
            }

        },

        /**
         * Removes unicodes in given element
         * @param {*} obj 
         */
        removeUnicodes: function (obj) {
            var value = $.trim($(obj).val());
            value = validator.removeUnicodeChars(value) || value;
            $(obj).val(value);
        },
        /**
         *  function to add success class to the elements
         * @param {*} elementObject 
         * @param {*} hasValue 
         */
        addSuccessClass: function (elementObject) {
            try {
                var closestNode = $(elementObject).closest('.input-box-container');
                $(closestNode).find('.input-box-success').addClass('input-label-success');
                $(closestNode).find('.input-box-error').removeClass('input-label-error');
                $(closestNode).find('.input-box-label').addClass('input-box-filled');
                $('.' + $(elementObject).attr('error')).removeClass('has-input-error').html('');
                $(closestNode).find('.input-box-active').removeClass('input-box-progress');
                $(elementObject).removeClass('hasInputError');

                $('.' + $(elementObject).attr('iconname')).removeClass('clicked');
                $('.' + $(elementObject).attr('avtarImage')).removeClass('active');

                this.networkCallInProgress = false;
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:addSuccessClass|" + e, null, 1);
            }
        },
        /**
         *  function to add inprogress class to the elements
         * @param {*} elementObject 
         * @param {*} hasValue 
         */
        addInprogressClass: function (elementObject) {
            var closestNode = $(elementObject).closest('.input-box-container');
            $(closestNode).find('.input-box-label').addClass('input-box-filled');
            $(closestNode).find('.input-box-active').addClass('input-box-progress');

            this.networkCallInProgress = true;
        },
        
        deleteLicense: function (event) {

            $('.modal-popup-container').html(confirmationModal(dashboardContent.myProfilePageContent.confirmDeleteLicense));
            var obj = '#' + event.target.id,
                inputData = {},
                self = this;

            inputData.currLicenseId = $(obj).attr('data-license-number');

            self.licenseType = $(obj).attr('license-type');

            GAUtils.eventTracking.trackevent("Profile Forms Global Alerts", "Delete Button Click ", window.userSession.userId + " | " + inputData.currLicenseId + "|Agent");
            $('#confirmationModal').modal('show');
            $('#confirmationModal .watchDeleteConfirm').unbind('click').bind('click', function (e) {
                $('#confirmationModal').modal('hide');
                self.deleteLicenseConfirm({}, {inputData: inputData, self: self});
            });
            $('#confirmationModal .cross, #confirmationModal .cancelButton').unbind('click').bind('click', function (e) {
                $('#confirmationModal').modal('hide');
            });
        },
        deleteLicenseConfirm: function (userData, callBackParam) {
            if (callBackParam.self.licenseType == 'addLicense') {
                var url = contextPathPortal + '/user/deleteLicense?licenseId=' + callBackParam.inputData.currLicenseId;
            } else {
                var url = contextPathPortal + '/lean-regn/deleteCorpLicense?licenseId=' + callBackParam.inputData.currLicenseId;
            }

            dUtil.showLoader();
            dUtil.jqueryPost(url, callBackParam.inputData, callBackParam.self.deleteLicenseConfirmCallback, callBackParam.self);
        },
        deleteLicenseConfirmCallback: function (outData, inputData, self) {

            $('#confirmationModal').modal('hide');
            dUtil.hideLoader();
            var message = '';

            if (outData.status == 'SUCCESS') {

                $('#updateLicense' + inputData.currLicenseId).remove();

                GAUtils.eventTracking.trackevent("Profile Forms - Global Alerts", "Delete Button Click Success", window.userSession.userId + " | " + inputData.currLicenseId + "|Agent");


                message = dashboardContent.myProfilePageContent.confirmDeleteLicense.successMessage;

                parent = self.licenseType == 'addLicense' ? '.agent-license-list' : '.agent-corp-license-list'
                $(parent).find('.common-license-message').removeClass('hide').addClass('successMessageStyle').html(message);    
                self.hideErrorMessage({element: $(parent).find('.common-license-message'), time: 5000});

                if (window.location.href.indexOf('/my-shopper') > -1) {
                    window.location.reload();
                }

            } else if (outData.isLoggedOut || (outData.status === 'FAILURE' && outData.errorMessage === 'SESSION_REQUIRED') ) {

                var callBackParam = {
                    "inputData": inputData,
                    "self": self
                };

                loginRegisterView.openLoginPopup({
                    callback: self.deleteLicenseConfirm,
                    viewObject: callBackParam,
                    statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                });

            } else {

                if (outData.ERROR != '' && outData.ERROR != undefined) {
                    message  = outData.ERROR;
                } else {
                    message = dashboardContent.myProfilePageContent.confirmDeleteLicense.errorMessage;
                }

                parent = self.licenseType == 'addLicense' ? '.agent-license-list' : '.agent-corp-license-list'
                $(parent).find('.common-license-message').removeClass('hide').addClass('errorMessageStyle').html(message);    
                self.hideErrorMessage({element: $(parent).find('.common-license-message'), time: 5000});
            }


        },
        saveLicense: function (event) {
            try {
                var self = this,
                    licenceNumber = $(event.currentTarget).attr('data-license-number');

                self.licenseAction = $(event.currentTarget).attr('data-action');
                self.licenseType = $(event.currentTarget).attr('data-license-type');
                
                if (self.licenseAction === 'edit') {
                    GAUtils.eventTracking.trackevent("Profile Forms Global Alerts","Edit Link Click", window.userSession.userId + " |Agent|"+ self.licenseType);
                    self.getLicenseData({}, {licenceNumber: licenceNumber, viewObject: self});
                } else {
                    GAUtils.eventTracking.trackevent("Profile Forms Global Alerts","Add Link Click", window.userSession.userId + " |Agent|"+ self.licenseType);
                    self.openLicenseModal(null, null, self);
                }                

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:saveLicense|" + exception, null, 1);
            }
        },
        getLicenseData: function(userResponse, options) {
            try {
                options = options || {};
                var licenceNumber = options.licenceNumber || '',
                    self = options.viewObject || this,
                    url = contextPathPortal;

                dUtil.showLoader();

                url += ((self.licenseType == 'addLicense') ? '/user/getLicenseDetails' : '/lean-regn/getCorpLicenseDetails');
                url += '?licenseId=' + licenceNumber;

                dUtil.jqueryGet(url , options , self.openLicenseModal , self);

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:getLicenseData|" + exception, null, 1);
            }
        },
        /**
         * Opens modal popup 
         * @param {*} event 
         */
        openLicenseModal: function (licenseData, options, viewObject) {

            try {
                var self = viewObject || this,
                    stateMan = false,
                    licenseInformation = {};

                dUtil.hideLoader();

                licenseData = licenseData || {};
                licenseData = (self.licenseType == 'addLicense') ? licenseData : (licenseData.data || licenseData);

                licenseInformation.data = licenseData;
                licenseInformation.content = dashboardContent.myProfilePageContent;

                if (self.licenseAction === 'edit' && (licenseData.status === 'SESSION_REQUIRED' || (licenseData.status === 'FAILURE' && licenseData.errorMessage === 'SESSION_REQUIRED'))) {
                    loginRegisterView.openLoginPopup({
                        callback: self.getLicenseData,
                        viewObject: options,
                        statusMessage: saveListDrawerContent.errorMessages.sessionRequiredMessage
                    });   
                    return; 
                }

                if (self.licenseAction === 'edit') {

                    licenseInformation.content.licenseDetailsHeading = 'Edit License';
                    licenseInformation.content.licenseSaveButton = 'Update';

                } else {

                    licenseInformation.content.licenseDetailsHeading = 'Add License';
                    licenseInformation.content.licenseSaveButton = 'Add License';

                }
                
                licenseInformation.stateList = self.stateList;
                
                if ($.inArray(licenseInformation.stateId, self.mandatoryStateArray) !== -1) {
                    stateMan = true;
                }
    
                if (licenseData.state) {
                    self.fetchCities({
                        stateCode: licenseData.state,
                        sourceObj: '#agentLicenseStateId'
                    });
                }
    
                licenseInformation.stateMan = stateMan;
                $(".modal-popup-container").html(licenseDetailsTemplate(licenseInformation));
                self.attachDatePicker({element: '.expirationDate'});

                // Initiate license view events
                licenseView.$el = $('.modal-popup-container');
                licenseView.parentView = self;
                licenseView.render();
                licenseView.delegateEvents();
                
                $(".modal-popup-container").find('#editProfileLicenseModal #editLicense').addClass('form-horizontal');
                $(".modal-popup-container").find('#editProfileLicenseModal').modal("show");

                // we are opening modal on another modal in mobile view, adjust z index of current popup
                if ($(window).width() < 768 ) {
                    $(".modal-popup-container").find('#editProfileLicenseModal').css('z-index', '1052');
                    $($('.modal-backdrop')[0]).css('z-index', '1051');
                }

                $(".modal-popup-container").find('#editProfileLicenseModal').on('hidden.bs.modal', function(){
                    $('.agent-licenses-container .add-license').focus();

                    // we are opening modal on another modal in mobile view, revert z index of current popup
                    if ($(window).width() < 768 ) {
                        $(".modal-popup-container").find('#editProfileLicenseModal').css('z-index', '1050');
                        $('.modal-backdrop').css('z-index', '1040');
                    }
                });

            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:openLicenseModal|" + exception, null, 1);
            }
        },
        hideErrorMessage: function(options){

            options = options || {};

            var time = options.time,
                element = options.element;
            setTimeout(function(){
                element.removeClass('errorMessageStyle successMessageStyle').html('');
            }, time);
        },
        /**
         * 
         * @param {*} event 
         */
        validateAffliateCompany: function (event) {
            try {
                var obj = '#' + event.target.id,
                    value = $.trim($(obj).val());

                if (value === '') {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.issuerRequired);
                    $(obj).addClass('inputError');
                } else if (!(validator.companyName($.trim($(obj).val()), obj))) {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.issuerAllowedChars);
                    $(obj).addClass('inputError');
                } else {
                    $('.' + $(obj).attr('error')).addClass('hide');
                    $(obj).removeClass('inputError');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:validateAffliateCompany|" + e, null, 1);
            }
        },
        validateLicenseNumber: function (event, obj) {
            try {
                var obj = '#' + event.target.id;

                this.removeUnicodes(obj);
                if ($.trim($(obj).val()) === '') {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.licenseRequired);
                    $(obj).addClass('inputError');
                } else if (!(validator.licence($.trim($(obj).val()), obj))) {
                    $('.' + $(obj).attr('error')).removeClass('hide').html(saveListDrawerContent.errorMessages.licenseAllowedChars);
                    $(obj).addClass('inputError');
                } else {
                    $('.' + $(obj).attr('error')).addClass('hide');
                    $(obj).removeClass('inputError');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:validateLicenseNumber|" + e, null, 1);
            }
        },
        validateExpiryDate: function (event) {
            try {

                var obj = '#' + event.target.id

                if ($(obj).val() == '' || $('#licenceExpiryDate').val() == undefined || this.dateDifference($('#licenceExpiryDate').val()) < 0) {
                    $('#licenceExpiryDate').addClass('inputError');
                    $('.errorLicenseExpiry').removeClass('hide').html(saveListDrawerContent.errorMessages.expiryDateRequired);
                } else {
                    $('#licenceExpiryDate').removeClass('inputError');
                    $('.errorLicenseExpiry').addClass('hide');
                }
            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:validateExpiryDate|" + e, null, 1);
            }
        },

        /**
         * Enables date picker for given element
         * @param {*} options 
         */
        attachDatePicker: function(options) {
            try {
                options = options || {};
                var element = options.element || '';

                if(!element) return;

                $(element).datepicker({
                    changeYear: true,
                    changeMonth: true,
                    minDate: 1,
                    defaultDate: new Date(),
                    beforeShow: function (input, inst) {
                        $(document).off('focusin.bs.modal');
                    },
                    onClose: function () {
                        $(document).on('focusin.bs.modal');
                        $(this).blur();
                    },
                }).keydown(function (e) {
                    e.preventDefault();
                    var code = e.keyCode || e.which;
                    // And arrow keys used "for performance on other keys"
                    if (code == '37' || code == '38' || code == '39' || code == '40') {
                        // Get current date
                        var parts = $(this).val().split("/");
                        var currentDate = new Date(parts[2], parts[0] - 1, parts[1]);
                        // Show next/previous day/week 
                        switch (code) {
                            // LEFT, -1 day
                            case 37:
                                currentDate.setDate(currentDate.getDate() - 1);
                                break;
                                // UP, -1 week
                            case 38:
                                currentDate.setDate(currentDate.getDate() - 7);
                                break;
                                // RIGHT, +1 day
                            case 39:
                                currentDate.setDate(currentDate.getDate() + 1);
                                break;
                                // DOWN, +1 week
                            case 40:
                                currentDate.setDate(currentDate.getDate() + 7);
                                break;
                        }
                        // If result is ok then write it
                        if (currentDate != null) {
                            $(this).datepicker("setDate", currentDate);
                        }
                    } else {
                        if (code == '9' || code == '13') {
                            $(this).datepicker("hide");
                            $(this).blur();
                        } else {
                            return false;
                        }

                    } // If other keys pressed.. return false

                });
            } catch (exception) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:attachDatePicker|" + exception, null, 1);
            }
        },

        /**
         * Get mandatory states list
         */
        getMandatoryStatesList: function(options) {
            
            options = options || {};
            var self = this;

            jQuery.httpRequest({
                url: contextPathPortal+'/user/getLicenseAddressRequiredStateList',
                type: 'POST',
                success: function(response) {
                    self.mandatoryStateArray = response || [];
                },
                error: function(xhrObject, status, error){
                    // Log ga
                }
            });

        },
        
        agentBuyerSwitchConfirmation: function (event, options) {

            var self = this;
            self.agentswitch = $(event.currentTarget).attr('rel');

            if (self.agentSwitchFlag) {
                self.agentSwitchFlag = false;
                self.getUserDetails({container: 'globalProfileContainer'});
                return false;
            }

            if (self.agentswitch == 'agent') {
                $('.modal-popup-container').html(confirmationModal(dashboardContent.myProfilePageContent.confirmSwitchToAgent));
                $('#confirmationModal').modal('show');
            } else {
                $('.modal-popup-container').html(confirmationModal(dashboardContent.myProfilePageContent.confirmSwitchToBuyer));
                dUtil.showLoader();
                var url = contextPathPortal + '/user/isAgentHasActiveBidsAndOffers';
                $.ajax({
                    type: "GET",
                    url: url,
                    dataType: 'json',
                    success: function (data) {
                        dUtil.hideLoader();
                        if (data.isAgentHasBidsAndOffers == 'Y') {

                            if (event.target.id === "userRadioBuyer") {
                                $("#userRadioAgent").prop("checked", true)
                            } else if (event.target.id === "userRadioAgent") {
                                $("#userRadioBuyer").prop("checked", true)
                            }

                            dUtil.showToast(dashboardContent.myProfilePageContent.agentbuyswitcherror);
                        } else {
                            $('.modal-popup-container').find('#confirmationModal').modal('show');
                        }

                    }
                });
            }

            // we are opening modal on another modal in mobile view, adjust z index of current popup
            if ($(window).width() < 768 ) {
                $(".modal-popup-container").find('#confirmationModal').css('z-index', '1052');
                $($('.modal-backdrop')[0]).css('z-index', '1051');
            }

            $(".modal-popup-container").find('#confirmationModal').on('hidden.bs.modal', function(){
                // we are opening modal on another modal in mobile view, revert z index of current popup
                if ($(window).width() < 768 ) {
                    $(".modal-popup-container").find('#confirmationModal').css('z-index', '1050');
                    $('.modal-backdrop').css('z-index', '1040');
                }
            });            

            $('#confirmationModal .watchDeleteConfirm').unbind('click').bind('click', function (e) {
                $('#confirmationModal').modal('hide');
                self.switchRole();
            });
            $('#confirmationModal .cross, #confirmationModal .cancelButton').unbind('click').bind('click', function (e) {
                $('#confirmationModal').modal('hide');
                self.agentSwitchFlag = false;

                if (event.target.id === "userRadioBuyer") {
                    $("#userRadioAgent").prop("checked", true)
                } else if (event.target.id === "userRadioAgent") {
                    $("#userRadioBuyer").prop("checked", true)
                }

            });
        },

        switchRole: function () {
            try {

                this.agentSwitchFlag = true;
                this.getUserDetails({container: 'globalProfileContainer'});

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileview.js|function:switchRole|" + e, null, 1);
            }
        },
         /**
         * Fucntion: Render the default container for Profile Users.
         * @param {*} options 
         */
        render: function (options) {
            try {
                options = options || {};
                var self = this;

                // In case of session lost, user login, alerts view will get initialized and hense this render will be called.
                // To prevent this use sesssionRestored flag which will be set at the time of initiating session login
                // if (typeof window.sessionRestored !== 'undefined' && window.sessionRestored === 'Y') return false;

                self.getMandatoryStatesList();
                self.getStateList();
                self.getUserDetails(options);

            } catch (e) {
                GAUtils.eventTracking.trackevent("Error", "Global Alerts User Profile", "file:userProfileView.js|function:render|" + e, null, 1);
            }
        }
    });
    return userProfileView;
});