
/*jslint browser:true*/
/*global define, root*/
define('globalAlertsIdologyView',['require','jquery','underscore','backbone','GoogleAnalyticsUtils','globalIdologyCollectionView'],function(require) {
    'use strict';    
    var $ = require("jquery"),
        _ = require("underscore"),
        Backbone = require("backbone");
    var GAUtils = require("GoogleAnalyticsUtils");
    var globalIdologyCollectionView = require("globalIdologyCollectionView");
    var globalIdologyCollectionViewFunc;
    var globalIdologyView = Backbone.View.extend({
        
        el: $('body'),
        
        events: {
            "click .verifyIdologySubmit" : "submitIdologyQues",
            "submit .userFormIdology":"submitIdologyData",
        },

        initialize: function () {
            globalIdologyCollectionViewFunc = new globalIdologyCollectionView();
        },
        makeTextBoxActive:function(e){
           var obj = '#' + e.target.id;
           $('.'+$(obj).attr('avtarImage')).addClass('active');
        },
        
        submitIdologyQues:function(e){
            var element = $("#"+e.target.id);
            if(!$(e.target).hasClass('maskButtonEvents') ){
                globalIdologyCollectionViewFunc.submitIdologyQuestions();
            }

        },
        submitIdologyData:function(e){
            
            globalIdologyCollectionViewFunc.submitIdologyDetails({'element':e});
        },
        render:function(renderParam){
            globalIdologyCollectionViewFunc.renderDiv = renderParam.renderView;
            globalIdologyCollectionViewFunc.pageNameIdology = renderParam.renderView;
            globalIdologyCollectionViewFunc.getIdologyData({'renderView':'globalAlertsIdology'});
            globalIdologyCollectionViewFunc.$el = this.$('#globalAlertsIdology');
            globalIdologyCollectionViewFunc.delegateEvents();
        }
        
    });

    return globalIdologyView;
});
