
/* START_TEMPLATE */
define('hbs!tpl/placeBestBidPDP',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template({"1":function(depth0,helpers,partials,data) {
  var stack1, buffer = "        <select  class=\"form-control width238 descSelectBox\" id=\"buyerListCountered\">\n          <option value=\"\" selected>Select</option>\n";
  stack1 = helpers.each.call(depth0, ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.counteredBuyerBids : stack1), {"name":"each","hash":{},"fn":this.program(2, data),"inverse":this.noop,"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          <!--<option value=\"2\">buyer1</option>\n          <option value=\"3\">buyer2</option>-->\n          \n        </select>\n";
},"2":function(depth0,helpers,partials,data) {
  var lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "              <option value=\""
    + escapeExpression(lambda((depth0 != null ? depth0.bidId : depth0), depth0))
    + "\" bidPlacedBy=\""
    + escapeExpression(lambda((depth0 != null ? depth0.bidPlacedByAgent : depth0), depth0))
    + "\">"
    + escapeExpression(lambda((depth0 != null ? depth0.firstName : depth0), depth0))
    + " "
    + escapeExpression(lambda((depth0 != null ? depth0.lastName : depth0), depth0))
    + "</option> \n";
},"4":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression;
  return "        "
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.counteredBuyerBids : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.firstName : stack1), depth0))
    + " "
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.counteredBuyerBids : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.lastName : stack1), depth0))
    + "\n\n";
},"compiler":[6,">= 2.0.0-beta.1"],"main":function(depth0,helpers,partials,data) {
  var stack1, lambda=this.lambda, escapeExpression=this.escapeExpression, buffer = "<div id=\"placeBestBidDetailsModal\">\n  <div class=\"row buyerSelectBorderBottom\" id=\"buyerSelect\" >\n    <div class=\"col-xs-5 col-sm-4 property\">\n      Buyer Name\n    </div>\n    <div class=\"col-xs-7 col-sm-7 buyerEmailId\">\n";
  stack1 = helpers['if'].call(depth0, ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.isAgentRole : stack1), {"name":"if","hash":{},"fn":this.program(1, data),"inverse":this.program(4, data),"data":data});
  if (stack1 != null) { buffer += stack1; }
  return buffer + "      <input type=\"hidden\" id=\"bestBuyerDetails\" bidPlacedBy=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.counteredBuyerBids : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.bidPlacedByAgent : stack1), depth0))
    + "\" value=\""
    + escapeExpression(lambda(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.response : depth0)) != null ? stack1.counteredBuyerBids : stack1)) != null ? stack1['0'] : stack1)) != null ? stack1.bidId : stack1), depth0))
    + "\">\n    </div>\n      \n  </div>\n  <div id=\"placeBestBidDetails\" class=\"viewDetails\">\n  </div>\n  <div class=\"row text-center\" id=\"buyerDefaultScreen\">\n      <button class=\" btn btn-primary textFade \" id=\"closeButtonPopup\" type=\"button\">"
    + escapeExpression(lambda(((stack1 = (depth0 != null ? depth0.content : depth0)) != null ? stack1.close : stack1), depth0))
    + "</button>\n  </div>\n</div>";
},"useData":true});
Handlebars.registerPartial('tpl/placeBestBidPDP', t);
return t;
});
/* END_TEMPLATE */
;