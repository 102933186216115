
define('stickyNavigationView',['jquery', 'backbone', 'GoogleAnalyticsUtils', 'loginRegisterView'], function ($, Backbone, GAUtils, loginRegisterView) {
    'use strict';
    var stickyNavigationView = Backbone.View.extend({
        el: $('.mobile-navigation-container'),
        events: {
            'click .navigation-list .saved-icon': "openSavelistDrawer",
            'click .navigation-list .home-icon': "openHomeMenu",
            'click .navigation-list .alerts-icon': "openAlertsDrawer",
            'click .account-icon': "openAccountsMenu",
            "click .my-account-icon" : "openMyAccountsMenu",
            "click .navigation-list .h-home-icon" : "openHomePage",
            "click .navigation-list .h-search-icon" : "openSearchPage",
            "click .closePopup " : "closeMyAccount"
        },
        initialize: function(){
            var self = this,
                status,
                menu;

            // GA for navigation items 
            $('.mobile-navigation-container .nav-icon').click(function(){
                menu = $(this).attr('data-ref');
                status = ($(this).hasClass('active') ? 'close' : 'open');
                GAUtils.eventTracking.trackevent("", "Mobile navigation click: " + menu, "visitorId: " + $.cookie('visitorId') + " | userId: " + $.cookie('userId'));
            });

            if (typeof newSkinning !== "undefined" && newSkinning) {
                $('.nav-icon').removeClass('active');
                if (window.golbalSRPView) {
                    $('.h-search-icon').addClass('active');    
                    this.active = 'h-search-icon';
                } else if (window.location.pathname === '/Hubzu/' || window.location.pathname === '/') {
                    $('.h-home-icon').addClass('active');    
                    this.active = 'h-home-icon';
                }

            } else {
                if ( window.location.href.indexOf('/support') > -1 ) {
                    $('.help-icon').addClass('active');    
                    this.active = 'help-icon';
                } else if (window.location.pathname === '/Hubzu/' 
                    || window.location.pathname === '/' 
                    || window.location.href.indexOf('/searchResult') > -1
                    || window.location.href.indexOf('/auctions') > -1 ) {
                        
                    $('.home-icon').addClass('active');    
                    this.active = 'home-icon';
                }    
            }
            
            this.relativePath = typeof contextPath !== 'undefined' ? contextPath : '';

            // REmove fade effect to smoothen animations
            // $('#loginRegisterDialog').removeClass('fade');
            $('#loginRegisterDialog').attr("data-backdrop", "false");
            
            // since we removed modal default backdrop, adding custom overlay
            $('#loginRegisterDialog').on('show.bs.modal', function (e) {
                $('.mobile-overlay-mask').fadeIn();
            });
            $('#loginRegisterDialog').on('hide.bs.modal', function (e) {
                $('.mobile-overlay-mask').fadeOut();
                $('#registerationDiv').css('pointer-events', 'all');
                $('#registerationDiv').css('color', '#fff');
                $('#mobileRegisterLabel').removeClass('hide');
            });

            // On closing modal, nullify login view scope 
            $('#loginRegisterDialog').on('hidden.bs.modal', function (e) {
                loginRegisterView.callback = null;
                loginRegisterView.viewObject = null;
            });

            // remove icon class on clicking singin close button 
            $(".closebuttonMobile, .loginRegisterPopup .closeButton").unbind('click').bind('click', function(){
                $('.nav-icon').removeClass('active');
                if (self.active) 
                        $("." + self.active).addClass('active');
            });

            // Enable back button only if history lenght greath than 0
            // Disable back button in case of
            //      - SRP
            //      - Support page
            //      - PDP - if user coming from bid wizard.
            if ( window.history.length > 1 && (/iPhone/.test(navigator.userAgent)) 
                    && window.location.href.indexOf('/searchResult') < 0
                    && window.location.href.indexOf('/auctions') < 0
                    && window.location.href.indexOf('/support') < 0 
                    && window.location.href.indexOf('ref=bid') < 0 ) {
                $(".mobile-back-btn").removeClass("hide");
            }

            // to disable page level scrolling
            $('.modal').on('shown.bs.modal', function (e) {
                $("body").addClass('modal-open');
            });

            // hide buy a home menu
            $('.mobile-overlay-mask').unbind('click').bind('click', function(){
                $('.mobileMyaccountTab').removeClass('open');
                $("body").removeClass('modal-open');
               /* $('.mobile-myaccount-menu').slideUp();*/
                $('.mobile-overlay-mask').fadeOut();
                $('.home-icon,.my-account-icon').removeClass('active');
                if (self.active) 
                        $("." + self.active).addClass('active');

                $(".h-signin-container .closeButton").click();
            });

            $(".buy-a-home-menu").unbind('click').bind('click', function(){
                  if (!$(".buyAHomeHeaderMenuSection").hasClass('open')) {
                    self.closeAllTabs();
                    $('.buyAHomeHeaderMenuSection').addClass('open');
                    $("body").addClass('modal-open');
                    $('.mobile-overlay-mask').fadeIn();
                    GAUtils.eventTracking.trackevent("", "Buy a home menu click: open", "visitorId: " + $.cookie('visitorId'));
                }else{
                    self.closeAllTabs();
                }
            });
            
            $(window).on('click touchstart',function(event){
                if(($(event.target).closest(".buyAHomeHeaderMenuSection").length < 1) && ($(event.target).closest('.buy-a-home-menu').length < 1) && $('.buyAHomeHeaderMenuSection').hasClass('open')){
                    $('.buyAHomeHeaderMenuSection').removeClass('open');
                   
                    $.when($('.mobile-overlay-mask').fadeOut())
                    .then(function(){
                        $("body").removeClass('modal-open');
                    });
                }
                
            });

            // upon clicking links, remove popup - for mobile browser history 
            $(".mobile-home-menu .h-menu").unbind('click').bind('click', function() {
                $(".mobile-home-menu").modal('hide');
            });

            (function(){
                /*** highlight the my account drop down in about us pages */
               
                $(".closeBuyerDashboard").unbind('click').bind('click', function(){
                    $('.myAccount').removeClass('active');
                        self.openMyAccountsMenu();
                }); 
            })($);
           

            
            
            // On opening modal, update sticky nav z index
            $('.mobile-home-menu').on('show.bs.modal', function (e) {
                $(".mobile-navigation-container").css('zIndex', '999');
            });
            // On closing modal, update sticky nav z index
            $('.mobile-home-menu').on('hidden.bs.modal', function (e) {
                $(".mobile-navigation-container").css('zIndex', '100001');
            });
        },
        openSavelistDrawer: function (event) {
            try {
                var self = this,
                    count,
                    userSession = window.userSession || {};
                
                $(".cif-popup-container").modal("hide");

                if (!$('.saved-icon').hasClass('active')) {
                    $('.nav-icon').removeClass('active');
                    $('.saved-icon').addClass('active');

                    count = $(".saved-items-count").text();
                    count = count ? Number(count) : 0;

                    // get count of saved items.
                    // if count is 0 && user not logged in, show login else show drawer
                    if (count === 0 && !userSession.userId ) {
                        this.closeAllTabs();
                        setTimeout(function(){
                            loginRegisterView.openLoginPopup({
                                callback: self.showDrawer,
                                viewObject: self
                            });    
                        }, 310);

                    } else {
                        this.closeAllTabs();                    
                        self.showDrawer(null, self);
                    }
                }

            } catch (error) {
                
            }
        },
        showDrawer: function(options, self) {
            try {

                // invalidate callback of loginRegisterView once callback is triggered.
                if (options) {
                    loginRegisterView.callback = null;
                    loginRegisterView.viewObject = null;
                }

                $('#notificationMobilePopup').modal('show');
                $('.nav-icon').removeClass('active');
                $('.saved-icon').addClass('active');
                golbalSaveListDrawerView.loadSaveListDrawer();
                $('#loginRegisterDialog').modal("hide");
                $("#ajax-loader").hide();
                $("#ajax-loader-mask").hide();
                setTimeout(function(){
                    $(".home-latest-version").addClass('modal-open');
                }, 610);
                $(".favorites-drawer-container .back-btn").unbind('click').bind('click', function(){
                    $('#notificationMobilePopup').modal('hide');
                    $('.saved-icon').removeClass('active');
                    if (self.active) 
                        $("." + self.active).addClass('active');
                });
            } catch (error) {
                
            }
        },
        openHomeMenu: function (event) {
            try {
                
                var recentSearches = JSON.parse(localStorage.getItem('RecentSearchList')) || {},
                    cookieElements = recentSearches.cookieElements ? recentSearches.cookieElements : [],                
                    home = this.relativePath ? this.relativePath + '/' : '/';

                this.closeAllTabs();
                $('.nav-icon').removeClass('active');
                $('.home-icon').addClass('active');

                if(cookieElements.length ) {
                    for(var count = 0; count < cookieElements.length; count = count + 1) {
                        if(cookieElements[count].id.indexOf('/property/') < 0 ){
                            home = cookieElements[count].id;
                            break;
                        }
                    }
                } 

                /**
                 *  Don't load / Home if user 
                 *      - already on same page
                 *      - in srp page
                 * */ 
                if ( home !== window.location.pathname + window.location.search 
                    && window.location.pathname.indexOf('/auctions') < 0 
                    && window.location.pathname.indexOf('/searchResult') < 0  ) {
                
                        window.location.href = home;

                }

            } catch (error) {
                
            }
        },
        openMyAccountsMenu: function(event){
            try{
                if($('.myAccount').hasClass('loginProgress') || $('.myAccount').hasClass('active')){
                    return;
                }
            if ($(".mobileMyaccountTab").hasClass('open')) {
                // close
                $('.mobile-overlay-mask').fadeOut();
                //$(".mobile-myaccount-menu").slideUp();
                $('.my-account-icon').removeClass('active');
                $('.mobileMyaccountTab').removeClass('open');
                $("body").removeClass('modal-open');
                if (this.active) 
                    $("." + this.active).addClass('active');                    
            } else {
                // open
                this.closeAllTabs();
                $('.nav-icon').removeClass('active');
                $('.my-account-icon').addClass('active');
                $('.dropdown-menu').show();
                //$('.mobile-myaccount-menu .dropdown-menu').insertBefore(this.appendTermsMenu());
                
                $(this.appendTermsMenu()).insertBefore( $( ".mobile-myaccount-menu .sign-out-btn-li" ) );
                $('.mobile-overlay-mask').fadeIn();
                $('.mobileMyaccountTab').addClass('open');
                $("body").addClass('modal-open');

                $(".menuActive").click(function(){
                    $('.mobileMyaccountTab').removeClass('open');
                    $("body").removeClass('modal-open');
                    $('.mobile-overlay-mask').fadeOut();
                });
                
                $('.help-menu-link a').each(function(){
                    $(this).click(function(){
                        var urlHref =   $(this).attr('href');
                       /* if((window.location.href).indexOf(urlHref) > -1){
                            $('.mobileMyaccountTab').removeClass('open');
                        }*/
                        var refText =   'My Account mobile'+ ' - '+$.trim($(this).text());
                        if(urlHref.indexOf('support') > -1 ){
                            if(window.location.href.indexOf('support') > -1){
                                $('.mobile-overlay-mask').fadeOut();
                                $("body").removeClass('modal-open');
                                $('.mobileMyaccountTab').removeClass('open');
                            }
                            
                            $('.myAccount').removeClass('active');
                            $('.help-icon').addClass('active');
                        }
                        GAUtils.eventTracking.processLinkAndTrackEvent({linkValue:urlHref, appendContextPath:false}, null,'Header Click',refText, ""); 
                        return false;
                    });
                });
                
              //  $(".mobile-myaccount-menu").slideDown();
            }
        } catch (error) {
            
        }
        },
        appendTermsMenu : function(){
            var generateHtml='';
            $('.help-menu-link').remove();
            $.each( saveListDrawerContent.header.Terms.links, function( key, value ) {
                generateHtml +='<li class="my-shopper help-menu-link"><a href="'+contextPath+saveListDrawerContent.header.Terms.links[key]['link']+'" target="'+saveListDrawerContent.header.Terms.links[key]['target']+'">'+saveListDrawerContent.header.Terms.links[key]['display']+'</a></li>';
            });
            return generateHtml;
        },
        openAlertsDrawer: function (event) {
            try {
                var self = this,
                    userSession = window.userSession || {};
                    
                $(".cif-popup-container").modal("hide");
                
                if ( !$('.alerts-icon').hasClass('active') || (typeof golbalPDPView !== "undefined" && golbalPDPView.showAlertsContainer) ) {
                    $('.nav-icon').removeClass('active');
                    $('.alerts-icon').addClass('active');

                    // if count is 0 && user not logged in, show login else show drawer
                    if ( !userSession.userId ) {
                        this.closeAllTabs();
                        setTimeout(function(){
                            loginRegisterView.openLoginPopup({
                                callback: (typeof golbalPDPView !== "undefined" && golbalPDPView.showAlertsContainer)? '' : self.showAlerts,
                                viewObject: self
                            });    
                        }, 310);
                    } else {
                        this.closeAllTabs();
                        self.showAlerts(null, self);
                    }
                }

            } catch (error) {

            }
        },
        showAlerts: function(options, self) {
            try {

                // invalidate callback of loginRegisterView once callback is triggered.
                if (options) {
                    loginRegisterView.callback = null;
                    loginRegisterView.viewObject = null;
                }

                $('.nav-icon').removeClass('active');
                $('.alerts-icon').addClass('active');
                
                globalAlertView.showAlertsDrawer();
                
                $('#loginRegisterDialog').modal("hide");
                $("#ajax-loader").hide();
                $("#ajax-loader-mask").hide();
                setTimeout(function(){
                    $(".home-latest-version").addClass('modal-open');
                }, 610);

                $(".gobal-alerts-drawer-popup .back-btn").unbind('click').bind('click', function(){
                    $('.gobal-alerts-drawer-popup').modal('hide');
                    $('.alerts-icon').removeClass('active');
                    if (self.active) 
                        $("." + self.active).addClass('active');
                });
            } catch (error) {
                
            }
        },
        openAccountsMenu: function (event) {
            try {
                if($('.myAccount').hasClass('loginProgress')|| $('.myAccount').hasClass('active')){
                    return;
                }
                var self = this;
                if( $('.account-icon').hasClass('active') ){
                    $('.account-icon').removeClass('active');
                    $('#loginRegisterDialog').modal('hide');
                    if (self.active) 
                        $("." + self.active).addClass('active'); 
                }else{
                    this.closeAllTabs();
                    setTimeout(function(){
                        $('.nav-icon').removeClass('active');
                        $('.account-icon').addClass('active');
                        if (typeof newSkinning !== "undefined" && newSkinning) {
                            loginRegisterView.openLoginPopup({});
                        } else {
                            loginRegisterView.openLoginRegister(event);
                        }
                    }, 310);
                }
            } catch (error) {
                
            }
        },
        openHomePage: function(event) {
            try {
                var home = this.relativePath ? this.relativePath + '/' : '/';

                this.closeAllTabs();
                $('.nav-icon').removeClass('active');
                $('.h-home-icon').addClass('active');

                if ( window.location.pathname !== home) {
                    window.location.href = home;   
                }
            } catch (error) {
                
            }
        },
        openSearchPage: function(event) {
            try {
                
                var recentSearches = JSON.parse(localStorage.getItem('RecentSearchList')) || {},
                    cookieElements = recentSearches.cookieElements ? recentSearches.cookieElements : [],                
                    home = this.relativePath ? this.relativePath + '/' : '/';

                this.closeAllTabs();
                $('.nav-icon').removeClass('active');
                $('.h-search-icon').addClass('active');

                if(cookieElements.length ) {
                    for(var count = 0; count < cookieElements.length; count = count + 1) {
                        if(cookieElements[count].id.indexOf('/property/') < 0 ){
                            home = cookieElements[count].id;
                            break;
                        }
                    }
                } else {
                    home += "auctions";
                }

                /**
                 *  Don't load / Home if user 
                 *      - already on same page
                 *      - in srp page
                 * */ 
                if ( !window.golbalSRPView ) {
                    window.location.href = home;
                }

            } catch (error) {
                
            }
        },
        closeMyAccount:function(e){
            $('.mobile-overlay-mask').fadeOut();
            $('.mobileMyaccountTab').removeClass('open');
            $('.nav-icon').removeClass('active');

            if (this.active) 
                $("." + this.active).addClass('active');
        },
        closeAllTabs: function(){

            // New home page menu
            $('.menu-modal').slideUp();

            //Hide Login 
            $('#loginRegisterDialog').modal('hide');
            
            // hide Save searches
            $('#notificationMobilePopup').modal('hide');
            $('.mobileMyaccountTab').removeClass('open');

            // hide buy a home menu
            $(".mobile-home-menu").modal('hide');

            //$('.mobile-myaccount-menu').slideUp();
            $('.mobile-overlay-mask').fadeOut();
            $("body").removeClass('modal-open');
            // Hide alerts 
            $('.gobal-alerts-drawer-popup').modal('hide');

            $('#drawerConfirmationModal').modal('hide');
            $('.buyAHomeHeaderMenuSection').removeClass('open');
        },
        render: function () {
            return this;
        }
    });
    return stickyNavigationView;
});